import * as copy from 'copy-to-clipboard';
import * as React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { FaLink } from 'react-icons/fa';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { VenusNotifier } from '../../../../components/helper/VenusNotifier';
import { EngagementLetterStatus, OrganizerClient } from './../../../models/OrganizerComponentModels';

interface OrganizerResendAccessLinkModalProps {
    show: boolean;
    onClose: () => void;
    loading: boolean;
    model: OrganizerClient[];
    resendAccessLink: (client: OrganizerClient) => void;
    engagementLetterStatus: EngagementLetterStatus;
}

const OrganizerResendAccessLinkModal: React.StatelessComponent<OrganizerResendAccessLinkModalProps> = ({ show, onClose, loading, model, resendAccessLink, engagementLetterStatus }) => {

    const CanClientResendAccessLink = (client: OrganizerClient): boolean => {
        if (client.email !== null && client.email !== "" && !client.isDeceased)
            return true;
        else
            return false;
    }

    const copyToClipBoard = (link: string) => {
        copy(link);
        VenusNotifier.Success("Access Link has been copied", null);
    }

    const assignNaToEmailAddressIfNullOrEmpty = (email: string): string => {
        if(email === "" || email === null)
        {
           return email = 'NA';
        }
        return email;
    }

    return <Modal show={show} className="resend-access-link-modal" onHide={() => { onClose() }}>
        <Modal.Header closeButton>
            <Modal.Title>
                <FaLink className='modalIcon' />
                Resend Access Link
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '14px' }}>
            <LoadingOverlay style={{ height: '100%' }}>
                <ul style={{ padding: 10 }}>
                    {
                        model && model.map((i, index) =>
                            (!i.isDeceased) &&
                            <Row key={index}>
                                <Col sm={10} title={i.name + ` (${assignNaToEmailAddressIfNullOrEmpty(i.email)})`} >
                                    {(i.name + ` (${assignNaToEmailAddressIfNullOrEmpty(i.email)})`).length > 70 ? (i.name + ` (${assignNaToEmailAddressIfNullOrEmpty(i.email)})`).substring(0, 70) + "..." : (i.name + ` (${assignNaToEmailAddressIfNullOrEmpty(i.email)})`)}
                                </Col>
                                <Col sm={1}>
                                    <a className={`fas fa-share-square ${CanClientResendAccessLink(i) ? "" : "list-disabled"}`}
                                        onClick={() => resendAccessLink(i)} title="Resend"
                                        style={{ marginLeft: '7px' }} />
                                </Col>
                                <Col sm={1}>
                                    <a title="Copy Link Address"
                                        className={`far fa-copy ${CanClientResendAccessLink(i) ? "" : "list-disabled"}`}
                                        onClick={() => copyToClipBoard(i.accessLink)}
                                        style={{ marginLeft: '7px' }}
                                        href="javascript:void(0)" />
                                </Col>
                            </Row>
                        )
                    }
                </ul>
                <Loader loading={loading} text={"Loading..."} />
            </LoadingOverlay>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onClose} >
                <i className="glyphicon glyphicon-remove" />
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}


export { OrganizerResendAccessLinkModal };

