import * as React from "react";
import { ISectionItems, ISections } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import SectionFooter from "./SectionFooter";
import { CollapseIcon, ExpandIcon } from "src/assets/SvgIconCollection";
import ExpandedSectionItem from "./SectionItem/ExpandedSectionItem";
import CollapsedSectionItem from "./SectionItem/CollapsedSectionItem";
import "./Section.css";
import { ReactSortable } from "react-sortablejs";
import { IQuestionReOrderPayload } from "src/store/settings/CqPreview/ActionTypes";
import { useDispatch } from "react-redux";
import { HandleQuestionReordering } from "src/store/settings/FormBuilder/Actions";
interface SectionProps {
    section: ISections;
    sectionIndex: number;
    setSelectedSection: React.Dispatch<React.SetStateAction<ISections>>;
    setActiveSection: React.Dispatch<React.SetStateAction<number>>;
    handleRenameSection: () => void;
    handleMergeSection: () => void;
    handleMoveSection: () => void;
    handleDeleteSection: (sectionIndex: number) => void;
}

const Section: React.FC<SectionProps> = ({
    section,
    sectionIndex,
    setSelectedSection,
    handleRenameSection,
    handleMergeSection,
    handleMoveSection,
    handleDeleteSection,
    setActiveSection
}) => {
    const dispatch = useDispatch();
    const [isExpandedView, toogleIsExpandedView] = React.useState(true);
    const handleSectionReOrdering = (updatedSectionItems: ISectionItems[]) => {
        const payload: IQuestionReOrderPayload = {
            sectionIndex: sectionIndex,
            sectionItems: updatedSectionItems
        };
        dispatch(HandleQuestionReordering(payload));
    };
   
    return (
        <div className="section-container">
            <span title={section.sectionName} className="ellipsis">
                {section.sectionName}
            </span>
            <div className="toggle-button-wrapper">
                {isExpandedView ? (
                    <span onClick={() => toogleIsExpandedView(!isExpandedView)}>
                        <ExpandIcon />
                    </span>
                ) : (
                    <span onClick={() => toogleIsExpandedView(!isExpandedView)}>
                        <CollapseIcon />
                    </span>
                )}
            </div>
            <ReactSortable
                className="sortable-container"
                list={JSON.parse(JSON.stringify(section.sectionItems))}
                setList={handleSectionReOrdering}
                ghostClass="ghost"
                easing="ease-out"
                handle=".reorder-icon-wrapper"
                makeOptions={{
                    forceAutoScrollFallback: true,
                    scroll: true,
                    scrollSensitivity: 30
                }}
            >
                {section?.sectionItems.length > 0 &&
                    section.sectionItems.map((sectionItem, sectionItemIndex, sectionItems) =>
                        isExpandedView ? (
                            <ExpandedSectionItem
                                key={sectionItem.id}
                                sectionItem={sectionItem}
                                sectionItemIndex={sectionItemIndex}
                                sectionIndex={sectionIndex}
                                totalquestions={sectionItems.length}
                            />
                        ) : (
                            <CollapsedSectionItem
                                key={sectionItem.id}
                                sectionItem={sectionItem}
                                sectionItemIndex={sectionItemIndex}
                            />
                        )
                    )}
            </ReactSortable>
            <SectionFooter
                sectionIndex={sectionIndex}
                section={section}
                handleMergeSection={handleMergeSection}
                handleMoveSection={handleMoveSection}
                handleRenameSection={handleRenameSection}
                setActiveSection={setActiveSection}
                setSelectedSection={setSelectedSection}
                handleDeleteSection={handleDeleteSection}
                isExpandedView={isExpandedView}
            />
        </div>
    );
};

export default Section;
