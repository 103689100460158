import { connect } from 'react-redux';
import { ApplicationState } from './../../../store';
import { RecycledOrganizerReport } from './RecycledOrganizer/RecycledOrganizerReport';
import { actionCreators as RecycledOrganizerStore } from './../../store/RecycledOrganizerStore';
import { ActionCreators as OrganizerCompanySettingsStore } from './../../store/OrganizerCompanySettingsStore';
import { actionCreators as CompanyStore } from './../../../store/company/CompanyStore';
import { actionCreators as UserStore } from '../../../store/UserManagement/UserStore';

export default connect(
    (state: ApplicationState) => ({
        recycledOrganizerReports: state.recycledOrganizerReports,
        companySettingsStore: state.organizerCompanySettings,
        userLocations : state.userLocations,
        companyData: state.companyData
    }),
    {
        ...RecycledOrganizerStore,
        ...OrganizerCompanySettingsStore,
        ...CompanyStore,
        ...UserStore
    })(RecycledOrganizerReport as any)
