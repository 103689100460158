import 'isomorphic-fetch';
import * as React from 'react';
import 'src/Core/Services/FetchInterceptorService';
import Notification from './helper/NotificationContainer';
import { IEExplorerBlocker } from '../components/IEExplorerBlocker';
import { handleResponse } from '../store/Library';
import LastLoginModal from './LastLoginModal';
import SecurityModal from './SecurityModal';
import { TYPES } from './../Startup/types';
import { container } from './../Startup/inversify.config';
import { ISessionLocalStore } from './../Core/Utilities/SessionStore';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as OneTimePasswordStore from '../store/common/OneTimePasswordStore';
import * as CompanyStore from '../store/company/CompanyStore';
import * as UserLoginHistory from '../store/UserManagement/UserLoginHistoryStore';
import * as UserProfileStore from '../store/UserManagement/UserProfileStore';
import * as UserSettingsStore from '../store/UserManagement/UserSettingStore';
import * as AllUsersStore from '../store/UserManagement/UserStore';
import * as ProductMenuStore from '../store/productMenu/ProductMenuStore';
import * as WidgetMenuStore from '../store/productMenu/WidgetMenuStore';
import * as RoleBasedAccessControlStore from './../store/roleBasedAccessControl/RoleBasedAccessControlStore';
import { OrganizerRoute } from '../store/productMenu/model/ProductMenuStoreModel'
import * as RecycledOrganizerStore from '../Organizer/store/RecycledOrganizerStore';
import * as DeliveredOrganizerStore from '../Organizer/store/DeliveredOrganizerStore';
import store from '../configureStore';
import { API_BASE_URL } from 'src/utils/constants';
import { getAccessToken, signoutRedirect } from 'src/oidcClient/userService';
import { RestrictedAccess } from 'src/components/RestrictedAccess';
import { SessionTimeout } from '@sssuite-component-ui/session-timeout';
import { ApplicationState } from 'src/store';
import * as PostAuthStore from 'src/store/PostAuth/PostAuthStore';
import { Forethought } from "@sssuite-component-ui/forethought-ai-chatbot";
import { createForethoughtCookie } from './helper/HelperFunctions';
const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);

declare global {
    interface Window {
        Variables: any;
        Forethought: any;
        pendo: any;
    }
}

const WarningVisibleMinutes = 1;
const DEFAULT_TIMEOUT_MINUTES = 5;

type LayoutProps = {
    children?: React.ReactNode;
    history?: any;
    deviceId: string;
    isLoggedOut: boolean;
}
    & typeof UserProfileStore.actionCreators
    & typeof AllUsersStore.actionCreators
    & typeof UserSettingsStore.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof UserLoginHistory.actionCreators
    & typeof OneTimePasswordStore.actionCreators
    & typeof RecycledOrganizerStore.actionCreators
    & typeof DeliveredOrganizerStore.actionCreators
    & typeof RoleBasedAccessControlStore.actionCreators
    & typeof ProductMenuStore.actionCreators
    & typeof WidgetMenuStore.actionCreators
    & typeof PostAuthStore.actionCreators

interface ILayoutState {
    isVisible: boolean;
    showTimeout: boolean;
    isConcurrentUser: boolean;
    tabIdentifier: any;
    sessionExpiredSecurityModal: boolean;
    sessionIdleMinutes: number;
};

class AppLayout extends React.Component<LayoutProps, ILayoutState> {
    private sessionRenew: any;
    private sessionExpired: any;
    //private tabIdentifier: any;
    constructor(props: LayoutProps) {
        super(props);
        this.state = {
            isVisible: true,
            showTimeout: false,
            isConcurrentUser: false,
            tabIdentifier: '',
            sessionExpiredSecurityModal: false,
            sessionIdleMinutes: 0
        };
    }


    componentDidMount() {
        this.setState({ tabIdentifier: this.getcookie('IdentifierTab') });
        this.props.requestProductStatus(this.loadApplication, this.autoLogout, this.accessForbiddenLoad);
    }

    componentWillUnmount() {
        clearInterval(this.sessionRenew);
        clearInterval(this.sessionExpired);
    }

    private accessForbiddenLoad = () => {
        this.props.requestAllUsers();
        this.props.requestPartners();
        this.props.requestUserProfile();
        this.props.requestCompanyLogo(true);
        this.props.getRbacResourceList();
        this.props.getWidgetMenuItems();
        this.props.fetchSuiteUrl();
        this.props.requestUserAccessibleProducts();
        this.props.getProductLeftMenuItems((routes: OrganizerRoute[]) => { this.redirect(routes) });
        this.setState({ sessionIdleMinutes: DEFAULT_TIMEOUT_MINUTES });
        this.sessionRenew = setInterval(() => {
            this.renewSession();
        }, 1000 * 60 * DEFAULT_TIMEOUT_MINUTES);
    
        this.setIntervalSessionExpiredCheck();
    }
    
    private loadApplication = () => {
        this.props.requestAllUsers();
        this.props.requestPartners();
        this.props.requestUserProfile();
        this.props.requestCompanyLogo(true);
        this.props.requestUserSettings(true);
        this.props.requestCompanyProfile();
        this.props.requestUserLoginHistory();
        this.props.requestMFAOTPLength();
        this.props.requestCompanySettings();
        this.props.requestCommonSettings();
        this.props.requestUserDefaultSettings(true);
        this.props.getRbacResourceList();
        this.props.getWidgetMenuItems();
        this.props.fetchSuiteUrl();
        this.props.requestUserAccessibleProducts();
        this.props.requestUserOfficeLocations();

        this.props.getProductLeftMenuItems((routes: OrganizerRoute[]) => { this.redirect(routes) });
        if (!(window as any)._walkmeConfig?.smartLoad) {
            this.props.getWalkMeScript((script: string) => {
                if (script && script.length > 0) {
                    let scriptElement = document.createElement("script");
                    scriptElement.type = "text/javascript";
                    scriptElement.async = true;
                    scriptElement.text = script;
                    document.head.appendChild(scriptElement);
                    (window as any).loadWalkMe && (window as any).loadWalkMe();
                }
            });
        }
        this.props.getORGSessionTimeOut((timeOutMinit) => {
            this.setState({ sessionIdleMinutes: timeOutMinit });
            this.sessionRenew = setInterval(() => {
                this.renewSession();
            }, 1000 * 60 * (timeOutMinit));
        });
        this.setIntervalSessionExpiredCheck();
    }

    private autoLogout = () => {
        this.props.resetUserCache();
        signoutRedirect();
    }

    private setIntervalSessionExpiredCheck = () => {

        this.sessionExpired = setInterval(() => {
            if (!getAccessToken()) {
                SessionStore.remove("isVisited");
                this.setState({ sessionExpiredSecurityModal: true });
            }
        }, 1000);
    }

    public render() {
        if (navigator.userAgent.indexOf("Trident") > -1) {
            return <IEExplorerBlocker />
        }
        else {
            return (<div>
                <div id="wrapper">
                    <div id="content-wrapper">
                        <div className="container-fluid">
                            <Notification />
                            {
                                (this.props.history.location.pathname !== '/restricted-access') ? this.props.children : <RestrictedAccess />
                            }
                        </div>
                    </div>
                </div>
                <SecurityModal
                    openModal={(this.state.sessionExpiredSecurityModal && !store.getState().userPrivilege.userPrivilegesChanged)}
                />
                <SessionTimeout
                    deviceId={this.props.deviceId}
                    onExternalLogout={() => createForethoughtCookie("isForethoughtWidgetVisible", "false")}
                    logout={signoutRedirect}
                    sessionIdleMinutes={this.state.sessionIdleMinutes}
                    warningVisibleMinutes={WarningVisibleMinutes}
                    currentTabLoggedOutShow={this.props.isLoggedOut}
                    setCurrentTabLoggedOutShow={() => {
                        this.props.loggedOut();
                    }}
                />
                <Forethought
                    offsetY="40px"
                    positionX="right"
                    positionY="bottom"
                    hideWidgetIframeOnLoad="true"
                />
                <LastLoginModal />
            </div>)
        };
    }

    private getcookie(cookiename: string) {
        var cookiestring = document.cookie;
        var cookiearray = cookiestring.split(';');
        for (var i = 0; i < cookiearray.length; ++i) {
            if (cookiearray[i].trim().match('^' + cookiename + '=')) {
                return cookiearray[i].replace(`${cookiename}=`, '').trim();
            }
        } return null;
    }

    private renewSession = () => {
        fetch(`${API_BASE_URL}api/MyAccount/StayAliveAsync`, {
            method: 'GET',
            credentials: 'include'
        }).then(handleResponse);
    }

    private redirect = (routes: OrganizerRoute[]) => {
        const path = this.props.history.location.pathname;
        if (path == "/" || path == "/OrganizerReport") {
            const foundRoute = routes.find(r => r.isEnabled);
            foundRoute && this.props.history.push(foundRoute.route);
        }
    }
}

export default connect(
    ((state: ApplicationState) => ({
        deviceId: state.auth.user.profile.device_id,
        isLoggedOut: state.postAuthDataStore.loggedOut
    })),
    {
        ...AllUsersStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...UserSettingsStore.actionCreators,
        ...CompanyStore.actionCreators,
        ...UserLoginHistory.actionCreators,
        ...OneTimePasswordStore.actionCreators,
        ...RoleBasedAccessControlStore.actionCreators,
        ...ProductMenuStore.actionCreators,
        ...WidgetMenuStore.actionCreators,
        ...DeliveredOrganizerStore.actionCreators,
        ...RecycledOrganizerStore.actionCreators,
        ...PostAuthStore.actionCreators
    }
)(withRouter(AppLayout as any) as any);
