import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { GetFormatedDateTime, insertWhiteSpace, capitaliseString } from '../../../../components/helper/HelperFunctions';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { OverlayLoader } from '../../../../components/helper/OverlayLoader';
import { MailEvent } from '../../../../components/common/TaxReturn';
import {
    OrganizerTransaction, OrganizerEvent,
    OrganizerEventList
} from './../../../models/OrganizerComponentModels';
import { ClientTrackingEvents } from './../../Helper/OrganizerConstants'

interface OrganizerClientTrackingProps {
    show: boolean;
    onClose: () => void;
    clientTracking: OrganizerTransaction[];
    loading: boolean;
}

const OrganizerClientTrackingModal: React.StatelessComponent<OrganizerClientTrackingProps> = ({ clientTracking, show, onClose, loading }) => {

    const defaultFormatter = (cell: any, row: any) => {
        return cell;
    }

    const actedOnFormatter = (cell: any, row: any) => {
        return GetFormatedDateTime(cell);
    }

    const createTotalPages = (start: number, to: number, total: number) => {
        return (
            <p style={{ fontSize: '14px' }}>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    const actedByFormatter = (cell: any, row: any) => {

        if (row.eventId == OrganizerEvent.Emailed && row.eventData
            && row.eventData.recipient && row.eventData.recipient.firstName) {
            return row.eventData.recipient.firstName +
                (row.eventData.recipient.lastName ? (' ' + row.eventData.recipient.lastName) : '');
        }

        if (cell && cell.firstName) {
            return cell.firstName + (cell.lastName ? (' ' + cell.lastName) : '');
        }
        return "";
    }

    const eventFormatter = (cell: any, row: any) => {
        let eventName = '';
        let mailEvent = row.eventData.mailEventId;

        const actedBy = `${((row.actedBy && row.actedBy.firstName) ? row.actedBy.firstName : "")} ${((row.actedBy && row.actedBy.lastName) ? row.actedBy.lastName : "")}`;

        switch (cell) {
            case OrganizerEvent.Emailed:
                eventName = ClientTrackingEvents.InitialEmail +
                    (mailEvent == 0 ? '' : ' - ' + capitaliseString(MailEvent[row.eventData.mailEventId]));
                break;

            case OrganizerEvent.UploadedDocument:
            case OrganizerEvent.DeleteSourceDocument:
                eventName = OrganizerEventList[OrganizerEvent[cell]] + (row.eventData.fileName ? (' - ' + row.eventData.fileName) : '');
                break;
            case OrganizerEvent.ManualSigningReminder:
            case OrganizerEvent.ManualOrganizerReminder:
            case OrganizerEvent.SourceDocumentReminder:
            case OrganizerEvent.ResentAccessLink:
            case OrganizerEvent.AutomaticSigningReminder:
            case OrganizerEvent.AutomaticOrganizerReminder:
                eventName = OrganizerEventList[OrganizerEvent[cell]] +
                    (mailEvent == 0 ?
                        capitaliseString(row.eventData.recipient ? ' -Sent to ' + row.eventData.recipient.firstName : '') : ' - ' + capitaliseString(MailEvent[row.eventData.mailEventId]));
                 break;
            case OrganizerEvent.AccessCodeEmail:
                eventName = OrganizerEventList[OrganizerEvent[cell]] +
                    (mailEvent == 0 ? '' : ' - ' + capitaliseString(MailEvent[row.eventData.mailEventId]));
                break;
            case OrganizerEvent.SourceDocumentUploadMarkedCompleted:
                eventName = `${OrganizerEventList[OrganizerEvent[cell]].toString()} ${actedBy}`;
                break;
            case OrganizerEvent.RestoreRecycledOrganizer:
                eventName = `${OrganizerEventList[OrganizerEvent[cell]].toString()}`;
                break;
            default:
                eventName = OrganizerEventList[OrganizerEvent[cell]];
                break;
        }
        return eventName;
    }

    const columns = [
        {
            header: 'Events',
            key: 'eventId',
            isKey: true,
            dataFormat: eventFormatter,
            columnClassName: 'overflowTextClientTracking',
            dataSort: true,
            toolTip: true,
            hidden: false
        },
        {
            header: 'By',
            key: 'actedBy',
            isKey: false,
            dataFormat: actedByFormatter,
            columnClassName: 'overflowTextClientTracking',
            dataSort: true,
            toolTip: true,
            hidden: false
        },
        {
            header: 'Event Date',
            key: 'actedOn',
            isKey: false,
            dataFormat: actedOnFormatter,
            columnClassName: 'overflowTextClientTracking',
            dataSort: true,
            toolTip: true,
            hidden: false
        },
        {
            header: 'Event Data',
            key: 'eventData',
            isKey: false,
            hidden: true,
            dataFormat: defaultFormatter,
            columnClassName: '',
            dataSort: false,
            toolTip: false
        }
    ];

    const options = {
        sizePerPage: 20,
        paginationShowsTotal: createTotalPages,
    };

    const data = clientTracking.length > 0 ? clientTracking.map((model, index) => {
        return {
            eventId: model.eventId,
            actedBy: model.actedBy,
            actedOn: model.actedOn,
            eventData: model.eventData
        }
    }) : [];

    const modalBody = clientTracking ?
        <BootstrapTable
            data={data}
            options={options}
            striped pagination={true}>
            {columns.map((value, index) => {
                return <TableHeaderColumn key={index} hidden={value.hidden} isKey={value.isKey} dataField={value.key} dataFormat={value.dataFormat}
                    columnClassName={value.columnClassName} dataSort={true} columnTitle={value.toolTip}>{value.header}</TableHeaderColumn>;
            })}
        </BootstrapTable> : (<OverlayLoader
            show={show}
            text={"Loading, please wait..."} />);

    return <Modal className="client-tracking-modal" show={show} onHide={() => { onClose() }}>
        <Modal.Header closeButton>
            <Modal.Title>
                <span className='text-secondary fas fa-user-clock'
                    style={{ color: 'grey', marginRight: '5px' }}>
                </span>
                Client Tracking History
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <LoadingOverlay style={{ height: '100%' }}>
                    {modalBody}
                    <Loader loading={loading} text={"Loading..."} />
                </LoadingOverlay>
            </div>
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onClose} >
                <i className="glyphicon glyphicon-remove" />
                Cancel
            </Button>
        </Modal.Footer>
    </Modal>
}


export { OrganizerClientTrackingModal }

