import { IClientInfoMismatchViewModel, IClientInformationProps, IClientInfoViewModel } from "../clientManagement.types";

export const columnNames = {
    clientInformation: "Client Information",
    organizer: "Organizer"
};

export const newClientModalConstants = {
    headerMessage: "Do you wish to add this data into your Client Management records?",
    title: "Add New Client Information",
    cancelButtonText: "No, Do Not Add it",
    saveButtonText: "Yes, Add it"
};

interface IAddNewClientInformationProps extends IClientInformationProps {
    onAddNewClientInfo: () => void;
};

export type { IAddNewClientInformationProps };

export const createNewClientInfoComparisonResult = (clientInfo: IClientInfoViewModel, callback: (data: IClientInfoMismatchViewModel) => void) => {
    const data: IClientInfoMismatchViewModel = {
            clientId: {
                hadDifference: false,
                taxReturnValue: clientInfo.clientId
            },
            name: {
                hadDifference: false,
                taxReturnValue: clientInfo.name
            },
            emailAddress: {
                hadDifference: false,
                taxReturnValue: clientInfo.emailAddress
            },
            countryCode: {
                hadDifference: false,
                taxReturnValue: clientInfo.countryCode
            },
            mobileNumber: {
                hadDifference: false,
                taxReturnValue: clientInfo.mobileNumber
            },
            isDeceased: {
                hadDifference: false,
                taxReturnValue: `${clientInfo.isDeceased}`
            },
            spouseName: {
                hadDifference: false,
                taxReturnValue: clientInfo.spouseName
            },
            spouseEmailAddress: {
                hadDifference: false,
                taxReturnValue: clientInfo.spouseEmailAddress
            },
            spouseCountryCode: {
                hadDifference: false,
                taxReturnValue: clientInfo.spouseCountryCode
            },
            spouseMobileNumber: {
                hadDifference: false,
                taxReturnValue: clientInfo.spouseMobileNumber
            },
            spouseIsDeceased: {
                hadDifference: false,
                taxReturnValue: `${clientInfo.spouseIsDeceased}`
            },
            locationName: {
                hadDifference: false,
                taxReturnValue: `${clientInfo.locationName}`
            },
            ero: {
                hadDifference: false,
                taxReturnValue: `${clientInfo.eroFirstName} ${clientInfo.eroLastName}`
            }
    };
    callback(data);
};