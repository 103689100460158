import * as React from 'react';
import * as TinyMCE from './../../../../components/settings/SavedMessage/Script/react-tinymce';
var htmlencode = require('htmlencode');

interface TinyMCEComponentProps {
    messageBody: string;
    changeStateTinymceBody: (event: any) => void;
    readOnly: number;
}

export const TinyMCEComponent: React.StatelessComponent<TinyMCEComponentProps> = ({ messageBody, changeStateTinymceBody, readOnly }) => {

    return (<div>
        <TinyMCE
            data-test-auto="CC40EF97-D565-4D3A-B467-91895733A8CA"
            key={messageBody}
            content={htmlencode.htmlDecode(messageBody)}
            config={{
                plugins: 'textcolor autoresize',
                menu: {
                    edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall' },
                    format: { title: 'Format', items: 'bold italic underline | blockformats align ' }
                },
                toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | forecolor backcolor',
                readonly: readOnly,
                block_formats: 'Paragraph=p;Heading 1=h1;Heading 2=h2;Heading 3=h3;Heading 4=h4;Heading 5=h5;Heading 6=h6'
            }}
            onChange={changeStateTinymceBody}
        />
    </div>
    );
}