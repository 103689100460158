import * as React from 'react';
import { Button, Card, Col, FormGroup, FormLabel, Modal, NavLink, Row } from 'react-bootstrap';
import { CheckBoxComponent, CheckBoxSize } from '../../../../components/common/CheckBoxComponent';
import { RadioButtonComponent } from '../../../../components/common/RadioButtonComponent';
import { BatchColumn, BatchStatus, BatchValidationStatus, OrganizerClientType } from './../../../models/OrganizerComponentModels';
import { DeliveryInfo } from './../../Helper/OrganizerConstants';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";
import { isExceedingOneDay } from './../../Helper/OrganizerHelperFunctions';

interface BatchOrganizerActionProps {
    rowIndex: number;
    onProcessBatch: (rowIndex: number) => void;
    onBatchDeleteOpen: (rowIndex: number) => void;
    documentStatus: BatchStatus;
    uploadedOn: Date;
    onEditBatchInfoOpen: (rowIndex: number) => void;
}

const BatchOrganizerAction: React.StatelessComponent<BatchOrganizerActionProps> =
    ({ rowIndex, onProcessBatch, onBatchDeleteOpen, documentStatus, uploadedOn, onEditBatchInfoOpen }) => {

        const processBatchButton = () => {
            return (
                <button title={"Process Batch"} type="button" className="btn-white btn-xs"
                    data-resource-id={ResourceIdConstants.ProcessBatchOrganizers}
                    onClick={() => onProcessBatch(rowIndex)}>
                    <i className='fa fa-rocket text-violet'></i>
                </button>
            );
        }

        const editBatchInfoButton = () => {
            return (
                <button title={"Edit Batch Info"} type="button" className="btn-white btn-xs"
                    data-resource-id={ResourceIdConstants.OrganizerInProcessGirdEditClientBtnBatch}
                    onClick={() => onEditBatchInfoOpen(rowIndex)}>
                    <i className='fas fa-address-card text-success'></i>
                </button>
            );
        }

        const deleteButton = () => {
            return (
                <button title={"Delete"} type="button" className="btn-white btn-xs"
                    data-resource-id={ResourceIdConstants.DeleteOrganizerInProcessGridBatch}
                    onClick={() => onBatchDeleteOpen(rowIndex)}>
                    <i className="text-danger glyphicon glyphicon-remove" />
                </button>
            );
        }


        const createPrimaryActions = () => {

            return (<div>
                {
                    (documentStatus === BatchStatus.Success ||
                        documentStatus === BatchStatus.PartialSuccess ||
                        documentStatus === BatchStatus.Processing)
                    &&
                    processBatchButton()
                }
                {
                    (documentStatus === BatchStatus.Success ||
                        documentStatus === BatchStatus.PartialSuccess ||
                        documentStatus === BatchStatus.Processing)
                    &&
                    editBatchInfoButton()
                }
                {
                    (
                        (documentStatus === BatchStatus.Success ||
                            documentStatus === BatchStatus.PartialSuccess ||
                            documentStatus === BatchStatus.Error ||
                            documentStatus === BatchStatus.ValidationFailed ||
                            documentStatus === BatchStatus.Processing)
                        ||
                        isExceedingOneDay(uploadedOn)
                    ) &&
                    deleteButton()
                }
            </div>);
        }

        return <div className="button-group">{createPrimaryActions()}</div> ;
    }

interface BatchFinishPopoverComponentProps {
    firstSigner: OrganizerClientType;
    updateFirstSigner: (type: OrganizerClientType) => void;
    deliver: () => void;
    batchName: string;
    loading: boolean;
}
const BatchFinishPopoverComponent: React.StatelessComponent<BatchFinishPopoverComponentProps> = ({ firstSigner, updateFirstSigner, deliver, batchName, loading }) => {

    const handleDeliverFirstToChange = (type: OrganizerClientType) => {
        updateFirstSigner(type);
    }

    const cancel = () => {
        document.body.click();
    }

    return (<Card>
        <Card.Body>
            <div>
                <Row style={{ marginLeft: '1px' }}>
                    <p>{DeliveryInfo}</p>
                </Row>
                <Row>
                    <Col sm={3}>
                        <RadioButtonComponent
                            text="Taxpayer"
                            id="taxpayer"
                            checked={firstSigner === OrganizerClientType.Taxpayer}
                            onClick={() => handleDeliverFirstToChange(OrganizerClientType.Taxpayer)}
                        />
                    </Col>
                    <Col sm={3}>
                        <RadioButtonComponent
                            text="Spouse"
                            id="spouse"
                            checked={firstSigner === OrganizerClientType.Spouse}
                            onClick={() => handleDeliverFirstToChange(OrganizerClientType.Spouse)}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: '15px' }}>
                    <div style={{ padding: '0 15px' }}>
                        <FormGroup as={FormLabel}>Batch Name:</FormGroup>
                    </div>
                    <div>
                        {batchName}
                    </div>
                </Row>
            </div>
            <div>
                <Col sm={12}>
                    <Button type="button" variant="info" className="pull-right"
                        data-test-auto="38ADB87A-749A-42F0-9B5C-485CAAB33883"
                        data-resource-id={ResourceIdConstants.DeliverBatchOrganizers}
                        onClick={deliver} disabled={loading}
                    ><i className="fa fa-paper-plane text-secondary" style={{ paddingRight: "10px" }}></i>Send</Button>
                    <Button type="button" className="pull-right marR05 btn-white btn-default"
                        variant="default"
                        data-test-auto="6EB41ED8-DA04-41E0-AFDA-205244BF5002"
                        onClick={cancel}
                    ><i className="glyphicon glyphicon-remove" style={{ paddingRight: "10px" }}></i>Cancel</Button>
                </Col>
            </div>
        </Card.Body>
    </Card>);
}

interface IgnoreErrorsModalProps {
    ignoreErrors: boolean;
    ignoreWarnings: boolean;
    show: boolean;
    csvDataForActions: any;
    onClose: () => void;
    changeIgnoreWarningAndError: (ignoreWarnings: boolean, ignoreErrors: boolean) => void;
}

const IgnoreErrorsModal: React.FC<IgnoreErrorsModalProps> = ({ ignoreErrors, ignoreWarnings, changeIgnoreWarningAndError, onClose, csvDataForActions, show }) => {

    const [ignoreErrorsChecked, setIgnoreErrorsChecked] = React.useState(false);
    const [ignoreWarningsChecked, setIgnoreWarningsChecked] = React.useState(false);

    const csvData = csvDataForActions?.filter((o: any) => !o.modified && o.validationResult?.status !== BatchValidationStatus.Success)

    const uncommitedRecords = csvData?.filter((data: any) => {
        return data?.validationResult?.validationMessages?.find((msg: any) => {
            if (msg.field === BatchColumn.DocumentGuid ||
                msg.field === BatchColumn.TaxpayerEmail ||
                msg.field === BatchColumn.ClientId
            )
                return msg;
        });
    })

    const commitedRecords = csvData.reduce((acc: any, v: any) => {
        const alreadyUncommited = uncommitedRecords.find((o: any) => o.id === v.id);
        if (!alreadyUncommited) acc.push(v);
        return acc;
    }, [])

    React.useEffect(() => {
        if (show) {
            setIgnoreErrorsChecked(ignoreErrors);
            setIgnoreWarningsChecked(ignoreWarnings);
        }
    }, [show])

    return (
        <Modal className="download-organizer-modal" show={show} onHide={onClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Ignore Errors
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CheckBoxComponent
                    size={CheckBoxSize.lg}
                    id={"ignore-errors"}
                    checked={ignoreErrorsChecked}
                    onChange={(e: any) => setIgnoreErrorsChecked((prev) => !prev)}
                    text={"Ignore all committable errors (organizer will still be delivered with this batch)"}
                    disabled={commitedRecords.length === 0}
                    wrapperStyle={{ display: 'inline' }}
                />
                <CheckBoxComponent
                    size={CheckBoxSize.lg}
                    id={"ignore-warnings"}
                    checked={ignoreWarningsChecked}
                    onChange={(e: any) => setIgnoreWarningsChecked((prev) => !prev)}
                    text={"Ignore all uncommittable errors (organizer will be removed from this batch)"}
                    disabled={uncommitedRecords?.length === 0}
                    wrapperStyle={{ display: 'inline' }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="info"
                    onClick={() => {
                        onClose();
                        changeIgnoreWarningAndError(ignoreWarningsChecked, ignoreErrorsChecked)
                    }}>
                    <i className="glyphicon glyphicon-floppy-disk" />Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export { BatchOrganizerAction, BatchFinishPopoverComponent, IgnoreErrorsModal };
