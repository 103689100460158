import * as React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

interface UploadedDocumentTableProps {
    data: any[],
    columns: any[],
    bodyContainerClass?: string,
    noDataText: string
}

export const UploadedDocumentTable: React.StatelessComponent<UploadedDocumentTableProps> = ({ data, bodyContainerClass, columns, noDataText}) => {
    let tableOptions = {
        noDataText: noDataText
    };
    return <BootstrapTable
        data={data}
        options={tableOptions}
        bodyContainerClass={bodyContainerClass}>
        {
            columns.map((value, index) => {
                return (
                    <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        width={value.width}
                        columnClassName={value.columnClassName}
                        hidden={value.isHidden}
                        columnTitle={value.tooltip ? value.tooltip : false}
                    >
                        {value.header}
                    </TableHeaderColumn>
                );
            })
        }
    </BootstrapTable>
}

