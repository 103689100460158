import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { IUserProfile } from '../../../components/navigation/profile/ProfileObjects';
import { OrganizerPageOptions } from '../../models/OrganizerComponentModels';
import * as CompanyStore from './../../../store/company/CompanyStore';
import { ApplicationState } from './../../../store/index';
import * as UserProfileStore from '../../../store/UserManagement/UserProfileStore';
import * as UserSettingStore from '../../../store/UserManagement/UserSettingStore';
import * as UserStore from '../../../store/UserManagement/UserStore';
import {
    BatchStoreState,
    InprocessOrganizerFilterStoreState, OrganizerBlankTemplateStoreState,
    OrganizerPageSelectorStoreState, OrganizerPrepareMessageStoreState,
    OrganizerSettingsStoreState, OrganizerStoreState
} from './../../models/OrganizerStoreModels';
import * as AdditionalEsignDocumentStore from './../../store/AdditionalEsignDocumentStore';
import * as BatchStore from './../../store/BatchStore';
import * as BlankOrganizerTemplateStore from './../../store/BlankOrganizerTemplateStore';
import * as CommonStore from './../../store/CommonStore';
import * as InprocessOrganizerFilterStore from './../../store/InprocessOrganizerFilterStore';
import * as CompanySettingsStore from './../../store/OrganizerCompanySettingsStore';
import * as OrganizerPageSelectorStore from './../../store/OrganizerPageSelectorStore';
import * as PreparerMessages from './../../store/OrganizerPreparerMessageStore';
import * as OrganizerReports from './../../store/OrganizerStore';
import * as OrganizerUpload from './../../store/OrganizerUploadStore';
import * as UserSignatureStore from './../../store/UserSignatureStore';
import * as CustomQuestionsStore from 'src/store/settings/CustomQuestions/CustomQuestionsStore';
import { BatchOrganizerReport } from './AllBatchOrganizer/BatchOrganizerReports';
import { OrganizerReport } from './AllOrganizer/OrganizerReports';

type OrganizerReportSelectorProps = {
    companySettingsStore: OrganizerSettingsStoreState;
    organizerModel: OrganizerStoreState;
    preparerMessageStore: OrganizerPrepareMessageStoreState;
    users: UserStore.ISSRUserData;
    partners: UserStore.IPartnerData;
    companyData: CompanyStore.ICompanyData;
    templateStore: OrganizerBlankTemplateStoreState;
    reportSelector: OrganizerPageSelectorStoreState;
    batchStore: BatchStoreState;
    userSettingStore: UserSettingStore.UserSettings;
    inprocessOrganizerFilter: InprocessOrganizerFilterStoreState;
    userProfile: IUserProfile;
    userSettings: UserSettingStore.UserSettings;
    userSignatures: UserSignatureStore.IUserSignatures;
    additionEsignDocumentStore: AdditionalEsignDocumentStore.IBlobFile;
    userLocations: UserStore.ILocationData;
}
    & typeof OrganizerUpload.actionCreators
    & typeof CompanySettingsStore.ActionCreators
    & typeof OrganizerReports.actionCreator
    & typeof UserStore.actionCreators    
    & typeof PreparerMessages.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof BlankOrganizerTemplateStore.actionCreators
    & typeof OrganizerPageSelectorStore.actionCreators
    & typeof BatchStore.actionCreator
    & typeof UserSettingStore.actionCreators
    & typeof InprocessOrganizerFilterStore.actionCreators
    & typeof CommonStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof UserSignatureStore.actionCreators
    & typeof AdditionalEsignDocumentStore.actionCreators
    & typeof CustomQuestionsStore.actionCreators
    & RouteComponentProps<{}>

export class OrganizerReportSelector extends React.Component<OrganizerReportSelectorProps, {}>{
    constructor(props: any) {
        super(props);
    }
    render() {
        return (
            <div>
                {
                    this.props.reportSelector.selectedOrganizerOption === OrganizerPageOptions.Organizer ?
                        <OrganizerReport
                            {...this.props}
                        /> :
                        <BatchOrganizerReport
                            {...this.props}
                        />
                }
            </div>
        );
    }
}

export default connect(
    (state: ApplicationState) => ({
        companySettingsStore: state.organizerCompanySettings,
        organizerModel: state.organizerReports,
        preparerMessageStore: state.organizerPreparerMessage,
        users: state.ssrUserList,
        partners: state.partners,
        userLocations: state.userLocations,
        companyData: state.companyData,
        templateStore: state.organizerTemplateStore,
        reportSelector: state.organizerReportSelectorStore,
        batchStore: state.batchStore,
        userSettingStore: state.userSettings,
        inprocessOrganizerFilter: state.inprocessOrganizerReportFilter,
        userProfile: state.userProfile,
        userSignatures: state.userSignatures,
        additionalEsignDocumentStore: state.additionalEsignDocumentStore
    }),
    {
        ...OrganizerUpload.actionCreators,
        ...CompanySettingsStore.ActionCreators,
        ...OrganizerReports.actionCreator,
        ...UserStore.actionCreators,
        ...PreparerMessages.actionCreators,
        ...CompanyStore.actionCreators,
        ...BlankOrganizerTemplateStore.actionCreators,
        ...OrganizerPageSelectorStore.actionCreators,
        ...BatchStore.actionCreator,
        ...UserSettingStore.actionCreators,
        ...InprocessOrganizerFilterStore.actionCreators,
        ...CommonStore.actionCreators,
        ...UserProfileStore.actionCreators,
        ...UserSignatureStore.actionCreators,
        ...AdditionalEsignDocumentStore.actionCreators,
        ...CustomQuestionsStore.actionCreators
    }
)(OrganizerReportSelector);