import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes'
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    OrganizerSettingsStoreState,
    unloadedOrganizerSettingsStoreState,
    OrganizerCompanySettingsLoaderAction,
    ReceiveOrganizerCompanySettings,
    ReceiveOrganizerScheduleReminderSettings,
    UpdateOrganizerCompanySettings,
    apiPrefix,
    OrganizerReminderSettingsLoaderAction,
    UpdateGlobalAutoReminderOrganizerAction
} from './../models/OrganizerStoreModels';
import { OrganizerCompanySettings, ReminderSettings, GlobalOperationAutoReminderRequest } from './../models/OrganizerComponentModels'
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();


type KnownActions =
    ReceiveOrganizerCompanySettings |
    UpdateOrganizerCompanySettings |
    OrganizerCompanySettingsLoaderAction |
    OrganizerReminderSettingsLoaderAction |
    ReceiveOrganizerScheduleReminderSettings |
    NotificationAction |
    UpdateGlobalAutoReminderOrganizerAction
const self: any = this;
export const ActionCreators = {
    requestOrganizerCompanySettings: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER,
            loading: true
        })
        const fetchTask = fetch(`${apiPrefix}/CompanySettings`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<OrganizerCompanySettings>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_COMPANY_SETTINGS,
                    data: data
                });
                dispatch({
                    type: actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER,
                    loading: false
                })
            }).catch((error) => {
                return dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.CompanySettings,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`removeOrganizerReportFilter failed for the request  with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestOrganizerReminderSettings: (id: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER,
            loading: true
        })
        const fetchTask = fetch(`${apiPrefix}/ReminderSettings/${id}`, {
            method: 'GET',
            credentials: 'include'
        }).then(response => response.json() as Promise<ReminderSettings>)
        .then((data) => {
            dispatch({
                type: actionTypes.RECEIVE_SCHEDULE_ORGANIZER_REMINDER_SETTINGS,
                data: data
            });
            dispatch({
                type: actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER,
                loading: false
            })
        }).catch((error) => {
            return dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: StoreConstants.Failure.OrganizerReminderSettings,
                statusType: StatusType.Error,
                statusCode: error?.status
            });
        });
        addTask(fetchTask);
    },

    updateOrganizerCompanySettings: (companySettings: OrganizerCompanySettings): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER,
            loading: true
        })
        const fetchTask = fetch(`${apiPrefix}/CompanySettings`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(companySettings),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                // dispatch(self?.ActionCreators?.requestOrganizerCompanySettings());
                dispatch({
                    type: actionTypes.UPDATE_ORGANIZER_COMPANY_SETTINGS,
                    update: companySettings
                });
                dispatch({
                    type: actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER,
                    loading: false
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.UpdateCompanySettings,
                    statusType: StatusType.Success
                });
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.UpdateCompanySettings,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`removeOrganizerReportFilter failed for the request having parameters ${JSON.stringify(companySettings)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateGlobalAutomaticReminder: (globalOperationAutoReminderRequest: GlobalOperationAutoReminderRequest): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}AutomaticReminder/UpdateGlobalAutomaticReminder`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(globalOperationAutoReminderRequest),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.UPDATE_GLOBAL_AUTOMATIC_REMINDER });
            })
            .catch(error => {
                logger?.trackError(`global autodReminder update failed for the request having parameters ${JSON.stringify(globalOperationAutoReminderRequest)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    reset: (actionType: any): AppThunkAction<KnownActions> => (dispatch) => {
        dispatch({ type: actionType });
    }
}

export const reducer: Reducer<OrganizerSettingsStoreState> = (state: OrganizerSettingsStoreState = unloadedOrganizerSettingsStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {

        case actionTypes.RECEIVE_ORGANIZER_COMPANY_SETTINGS:
            const companySettings = { ...state };
            companySettings.companySettings = action.data;
            return companySettings;

        case actionTypes.UPDATE_ORGANIZER_COMPANY_SETTINGS:
            const updateCompanySettings = { ...state };
            updateCompanySettings.companySettings = action.update;
            return updateCompanySettings;

        case actionTypes.ORGANIZER_COMPANY_SETTINGS_LOADER:
            return {
                ...state,
                loading: action.loading
            }
        
        case actionTypes.RECEIVE_SCHEDULE_ORGANIZER_REMINDER_SETTINGS:
            const orgScheduleReminderSettings = { ...state };
            orgScheduleReminderSettings.scheduleReminderSettings = action.data;
            return orgScheduleReminderSettings;
    }
    return state || unloadedOrganizerSettingsStoreState;
};