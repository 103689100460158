import * as React from 'react';
import { BootstrapTable, Options, TableHeaderColumn } from 'react-bootstrap-table';
import { phoneNumberDisplay } from '../../../../../../components/helper/HelperFunctions';
import { BatchColumn, BatchCSVData, BatchError, BatchValidationStatus, PagedCSVData } from '../../../../../models/OrganizerComponentModels';
import {
    TabContentContainer
} from './../../../../../../components/common/ProcessReturnModal/Tabs/TabContentContainer/TabContentContainer';
import {
    TabContentContainerFullScreenPanel
} from './../../../AllOrganizer/ProcessOrganizerModal/Tabs/TabContainers';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { BatchCSVDataModal } from '../../../Modals/BatchCSVDataModal';
import { cloneDeep } from 'lodash';
import { BatchColumnValues, ValidationMessageValues } from '../../../../Helper/OrganizerConstants';
import { Button, Col, Row } from 'react-bootstrap';
import { IgnoreErrorsModal } from '../../BatchOrganizerComponents';

export interface ErrorTabProps {
    pagedCSVData: PagedCSVData;
    updateBatchCSV: (csvData: BatchCSVData[], batchGuid: string, csvWithErrorData: BatchCSVData[], callback?: () => void) => void;
    requestBatchCSV: (page: number, pageSize: number, batchGuid: string) => void;
    loading: boolean;
    batchGuid: string;
    newBatch: any;
    changeIgnoreWarningAndError: (ignoreWarnings: boolean, ignoreErrors: boolean) => void;
}

export interface ErrorTabState {
    page: number;
    csvData: BatchCSVData[];
    showIgnoreErrorPopup: boolean;
    editPopup: {
        show: boolean;
        data: BatchCSVData
    }
}

const PAGESIZE = 10;

export class ErrorTab extends React.Component<ErrorTabProps, ErrorTabState> {

    constructor(props: ErrorTabProps) {
        super(props);
        this.state = {
            page: 1,
            csvData: [],
            showIgnoreErrorPopup: false,
            editPopup: {
                show: false,
                data: {} as BatchCSVData
            }
        }
    }

    componentDidMount() {
        if (!this.props.pagedCSVData ||
            !this.props.pagedCSVData[this.state.page]) {
            this.props.requestBatchCSV(this.state.page, PAGESIZE, this.props.batchGuid);
        } else {
            this.setState({
                csvData: this.props.pagedCSVData[this.state.page]
            });
        }
    }

    componentWillReceiveProps(nextProps: ErrorTabProps) {
        if (this.props.pagedCSVData && nextProps.pagedCSVData &&
            Object.keys(nextProps.pagedCSVData).length &&
            nextProps.pagedCSVData[this.state.page]
        ) {
            this.setState({
                csvData: nextProps.pagedCSVData[this.state.page]
            });
        }

        if (this.state.page > 1 &&
            !nextProps.loading &&
            nextProps.pagedCSVData &&
            nextProps.pagedCSVData[this.state.page] &&
            !nextProps.pagedCSVData[this.state.page].length
        ) {
            this.setState({
                page: 1
            }, () => {
                this.props.requestBatchCSV(this.state.page, PAGESIZE, this.props.batchGuid);
            });
        }

    }

    onOpenEdit = (id: number) => {
        const data = this.state.csvData.find(x => x.id === id);
        if (data) {
            this.setState({
                editPopup: {
                    show: true,
                    data: cloneDeep(data)
                }
            });
        }
    }

    saveEditedData = (data: BatchCSVData) => {
        const { csvData } = this.state;
        const savedData = csvData.map(csv => {
            if (csv.id === data.id)
                return data;
            return csv;
        });

        this.setState({
            csvData: savedData
        }, () => {
            this.props.pagedCSVData[this.state.page] = cloneDeep(this.state.csvData);
            this.onCloseEdit();
        })
    }

    updateCSVState = (data: BatchCSVData) => {
        this.setState({
            editPopup: {
                ...this.state.editPopup,
                data: data
            }
        });
    }

    onCloseEdit = () => {
        this.setState({
            editPopup: {
                show: false,
                data: {} as BatchCSVData
            }
        });
    }

    onPageChange = (page: number, sizePerPage: number) => {
        if (this.state.csvData.some(data => data.modified === true)) {
            this.props.updateBatchCSV(this.state.csvData, this.props.batchGuid, undefined as any,
                () => this.getPagedData(page))
        } else {
            this.getPagedData(page);
        }
    }

    getPagedData = (page: number) => {

        this.setState({
            page: page
        }, () => {

            if (this.props.pagedCSVData &&
                (!this.props.pagedCSVData[page] ||
                    this.props.pagedCSVData[page].length <= 0)) {
                this.props.requestBatchCSV(page, PAGESIZE, this.props.batchGuid);
            } else {
                this.setState({
                    csvData: this.props.pagedCSVData[this.state.page]
                });
            }

        });
    }

    onIgnorePopupOpen = () => {
        this.setState({
            showIgnoreErrorPopup: true
        });
    }

    onIgnorePopupClose = () => {
        this.setState({
            showIgnoreErrorPopup: false
        });
    }

    isIgnoreErrorButtonDisable = () => {
        const { csvData } = this.state;
        const filterData = csvData?.filter((o: any) => !o.modified && o.validationResult?.status !== BatchValidationStatus.Success);
        return filterData.length === 0;
    }

    render() {
        const { csvData, page, editPopup, showIgnoreErrorPopup } = this.state;
        const { loading, changeIgnoreWarningAndError, newBatch } = this.props;
        return (
            <TabContentContainer>
                <TabContentContainerFullScreenPanel disableScroll={false} style={{ backgroundColor: 'white' }}>

                    <Row style={{ marginBottom: '10px' }}>
                        <Col sm={{ size: 2, offset: 10 }}>
                            <Button
                                onClick={this.onIgnorePopupOpen}
                                variant="info"
                                disabled={this.isIgnoreErrorButtonDisable()}
                                className='btn-ignore-errors'>
                                Ignore Errors
                            </Button>
                        </Col>
                    </Row>
                    <LoadingOverlay>
                        <ErrorTable
                            csvData={csvData}
                            loading={false}
                            onEdit={this.onOpenEdit}
                            page={page}
                            totalRows={csvData ? csvData.length ? csvData[0].count : 0 : 0}
                            onPageChange={this.onPageChange}
                        />
                        <Loader loading={loading} />
                    </LoadingOverlay>
                </TabContentContainerFullScreenPanel>
                {
                    //<TabContentContainerRightPanel>
                    //    <DownloadPanel />
                    //</TabContentContainerRightPanel>
                }
                <IgnoreErrorsModal
                    changeIgnoreWarningAndError={changeIgnoreWarningAndError}
                    ignoreErrors={newBatch.settings.ignoreErrors}
                    ignoreWarnings={newBatch.settings.ignoreWarnings}
                    onClose={this.onIgnorePopupClose}
                    csvDataForActions={this.state.csvData}
                    show={showIgnoreErrorPopup}
                />
                {
                    editPopup.show &&
                    <BatchCSVDataModal
                        data={editPopup.data}
                        show={editPopup.show}
                        onClose={this.onCloseEdit}
                        onSave={this.saveEditedData}
                        onUpdate={this.updateCSVState}
                    />
                }
            </TabContentContainer>
        );
    }
}

interface ErrorTableProps {
    csvData: BatchCSVData[];
    loading: boolean;
    page: number;
    totalRows: number;
    onEdit: (id: number) => void;
    onPageChange: any;
}

interface ErrorTableState {

}

export class ErrorTable extends React.Component<ErrorTableProps, ErrorTableState>{


    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p style={{ textAlign: 'initial' }}>
                Showing {start} to {to} of {total} entries
            </p>
        )
    }

    setNoContent = () => {
        if (this.props.loading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.loading} />
            </LoadingOverlay>)
        } else {
            return "No errors"
        }
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    errorTypeFormatter = (cell: any, row: any) => {
        if (row.status !== BatchValidationStatus.Success) {
            const unCommit = (row.validation as BatchError[]).find(message => {
                if (message.field == BatchColumn.DocumentGuid ||
                    message.field == BatchColumn.TaxpayerEmail ||
                    message.field == BatchColumn.ClientId
                )
                    return message
            });

            if (unCommit)
                return <span title={"Uncommittable"} className="ellipsis">{"Uncommittable"}</span>
            else
                return <span title={"Committable"} className="ellipsis">{"Committable"}</span>
        }
        return <span title={""} className="ellipsis">{""}</span>
    }

    errorDescFormatter = (cell: any, row: any) => {
        if (row.status !== BatchValidationStatus.Success) {
            return (<ul>
                {
                    (row.validation as BatchError[]).map((error, index) => {
                        if (error.field === BatchColumn.DocumentGuid && error.message === 4) {
                            return <li key={index}>{ValidationMessageValues[error.message]}</li>
                        }

                        if (error.field === BatchColumn.DocumentGuid || error.field === BatchColumn.HasEngagementLetter) {
                            return <li key={index}>{BatchColumnValues[Number(error.field)]}</li>
                        }
                        return <li key={index}>{`${ValidationMessageValues[error.message]} ${BatchColumnValues[error.field]}`}</li>
                    })
                }
            </ul>);
        }
        return <span title={""} className="ellipsis">{""}</span>
    }

    mobileFormatter = (cell: any, row: any) => {
        return <span title={phoneNumberDisplay(cell)} className="ellipsis">{phoneNumberDisplay(cell)}</span>
    }

    actionFormatter = (cell: any, row: any) => {

        if (row.status !== BatchValidationStatus.Success) {
            const noFile = (row.validation as BatchError[])
                .find(message => {
                    if (message.field == BatchColumn.DocumentGuid ||
                        message.field == BatchColumn.ClientId)
                        return message
                });
            const eFile = (row.validation as BatchError[])
                .find(message => {
                    if (message.field == BatchColumn.HasEngagementLetter)
                        return message
                });
            if (noFile) {
                return "";
            }
            return (
                eFile && row.validation.length == 1 ? "" :
                    <button title={"Edit Client Info"} type="button" className="btn-white btn-xs"
                        onClick={() => this.props.onEdit(row.id)}>
                        <i className="fas fa-user-edit text-success"></i>
                    </button>
            );
        } else {
            return ""
        }
    }

    trClassName = (rowData: any, rowIndex: number) => {
        if (rowData) {
            const validation: BatchError[] = rowData.validation;
            const noFile = validation.find(message => {
                if (message.field == BatchColumn.DocumentGuid)
                    return message
            });
            if (noFile) {
                return "background-danger"
            }
        }
        return ""
    }

    tdStyle = (cell: any, row: any, rowIndex: number, columnIndex: number): React.CSSProperties => {
        if (row) {
            const validation: BatchError[] = row.validation;
            const error = validation.find(message => {
                if (message.field == columnIndex &&
                    columnIndex != 12 && message.field != BatchColumn.DocumentGuid)
                    return message
            });
            if (error) {
                return { backgroundColor: '#ff000059' }
            }
        }
        return {}
    }


    isExpandableRow = (row: any) => {
        if (row) {
            const validation: BatchError[] = row.validation;
            if (validation.length) {
                return true;
            }
        }
        return false;
    }

    expandComponent = (row: any) => {
        if (row) {
            const validation: BatchError[] = row.validation;
            if (validation.length) {
                return (
                    <ul>
                        {
                            validation.map((error, index) => {
                                if (error.field == BatchColumn.DocumentGuid) {
                                    return <li key={index}>{BatchColumnValues[Number(error.field)]}</li>
                                }
                                return <li key={index}>{`${BatchColumnValues[error.field]} is ${ValidationMessageValues[error.message]}`}</li>
                            })
                        }
                    </ul>
                );
            }
        }
        return "";
    }

    render() {

        const pagination = true;
        const { page, csvData, totalRows, onPageChange } = this.props;

        const options: Options = {
            onPageChange: onPageChange,
            page: page,
            paginationShowsTotal: this.renderShowTotal,
            noDataText: this.setNoContent(),
            sizePerPage: PAGESIZE,
        };


        const columns: any[] = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: false,
                toolTip: false,
                isHidden: true,
                width: 'auto'

            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: false,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',

            },
            {
                header: 'Taxpayer Email Address',
                key: 'taxpayerEmail',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: false,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',

            },
            {
                header: 'Error Type',
                key: 'errorType',
                isKey: false,
                dataFormat: this.errorTypeFormatter,
                dataSort: false,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',

            },
            {
                header: 'Error Description',
                key: 'errDesc',
                isKey: false,
                dataFormat: this.errorDescFormatter,
                dataSort: false,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',

            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: "100px"
            },
        ];

        const data: any[] = csvData.filter((v) => v.validationResult.status !== BatchValidationStatus.Success).map((data, index) => {
            return {
                rowIndex: index,
                index: ((page - 1) * PAGESIZE) + index,
                clientId: data.clientId,
                taxpayerFirstName: data.taxpayerFirstName,
                taxpayerLastName: data.taxpayerLastName,
                taxpayerEmail: data.taxpayerEmail,
                taxpayerPhone: data.taxpayerMobileNo,
                taxpayerCountryCode: data.taxpayerCountryCode,
                spouseFirstName: data.spouseFirstName,
                spouseLastName: data.spouseLastName,
                spouseEmail: data.spouseEmail,
                spousePhone: data.spouseMobileNo,
                spouseCountryCode: data.spouseCountryCode,
                id: data.id,
                status: data.validationResult.status,
                validation: data.validationResult.validationMessages
            }
        });

        return (<div>
            {columns.length > 0 &&
                <BootstrapTable
                    ref='organizerReportTable'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: totalRows }}
                    options={options}
                    pagination={pagination}
                >
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                        >{value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            }
        </div>);
    }

}