import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse, handleBlobwithFileName } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes'
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { container } from '../../Startup/inversify.config';
import { IDialogBox } from '../../Core/Utilities/UI/DialogBox';
import { TYPES } from '../../Startup/types';
import { apiPrefix, ReceiveRecycledOrganizerDocumentsAction, RecycledOrganizerStoreState, RecycledTableModel, RequestRecycledOrganizerDocumentsAction, unloadedRecycledOrganizerStoreState } from '../models/OrganizerStoreModels';
import { StoreConstants } from '../components/Helper/OrganizerConstants';
import { RecyleReturnsConstants } from '../../components/helper/Constants';

const logger = new LoggerFactory().getTelemetryLogger();
type KnownActions =
    ReceiveRecycledOrganizerDocumentsAction
    | RequestRecycledOrganizerDocumentsAction
    | NotificationAction;

let recycledOrganizerReportAbortController = new AbortController();

export const actionCreators = {
    requestRecycledOrganizers: (query: string, callback?: any): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.REQUEST_RECYCLED_ORGANIZER_DOCUMENTS,
            loading: true
        });
        recycledOrganizerReportAbortController.abort();
        recycledOrganizerReportAbortController = new AbortController();
        let fetchTask = fetch(`${apiPrefix}RecycledOrganizers/${query}`, {
            method: 'GET',
            credentials: 'include',
            signal: recycledOrganizerReportAbortController.signal
        })
            .then(handleResponse)
            .then(response => response as RecycledTableModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_RECYCLED_ORGANIZER_DOCUMENTS,
                    loading: false,
                    model: data,
                    query: query
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    if (callback) {
                        callback();
                    }
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AllOrganizers,
                    statusType: StatusType.Error
                });
                logger?.trackError(`requestRecycledOrganizers failed for the request having parameters ${query} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    refreshRecycledOrganizers: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch(actionCreators.requestRecycledOrganizers(getState().recycledOrganizerReports.query));
    },
    deleteRecycledOrganizers: (ids: number[], callback?: any): AppThunkAction<KnownActions> => (dispatch, getState) => {

        const fetchTask = fetch(`${apiPrefix}RecycledOrganizers/`, {
            method: 'DELETE',
            credentials: 'include',
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            },
            signal: recycledOrganizerReportAbortController.signal,
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RecyleReturnsConstants.SuccessMessages.Delete,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`deleteDocuments failed for documentid: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    },
    restoreRecycledOrganizer: (ids: number[], callback?: any): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}RecycledOrganizers/Restore`, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(ids)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: RecyleReturnsConstants.SuccessMessages.Restore,
                    statusType: StatusType.Success
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`Restore organizer failed for documentid: ${ids}, with error ${error.message}`);
            });
        addTask(fetchTask);
    }
}
export const reducer: Reducer<RecycledOrganizerStoreState> = (state: RecycledOrganizerStoreState = unloadedRecycledOrganizerStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {

        case actionTypes.REQUEST_RECYCLED_ORGANIZER_DOCUMENTS:
            return {
                ...unloadedRecycledOrganizerStoreState,
                loading: action.loading
            }
        case actionTypes.RECEIVE_RECYCLED_ORGANIZER_DOCUMENTS:
            return {
                ...state,
                model: action.model,
                loading: action.loading,
                query: action.query
            }
    }
    return state || unloadedRecycledOrganizerStoreState;
}