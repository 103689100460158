import * as React from 'react';
import * as Bs from 'react-bootstrap';
import { ReportFilterConstants, Common } from '../helper/Constants'

interface ISaveFilters {
	showState: boolean
	onHide(event: any): void;
	formSubmit(): void;
	onFilterNameChange(event: any): void;
}

export class SaveFilters extends React.Component<ISaveFilters, {}> {
	
	public render() {
		return <Bs.Modal className="report-problem-modal" show={this.props.showState} onHide={this.props.onHide}>
			<Bs.Modal.Header closeButton data-test-auto="5E85737D-305C-4B1F-9791-2EF09CD9C2A6">
				<Bs.Modal.Title>{ReportFilterConstants.ControlsLabel.SaveCurrentFilterBtn}</Bs.Modal.Title>
			</Bs.Modal.Header>
			
            <Bs.Modal.Body style={{ fontSize: '14px' }}>
					<div className="row">
						<Bs.Col sm={12}>
							<div>{ReportFilterConstants.ControlsLabel.PleaseEnterTheFilterName}</div>
							
							<Bs.FormControl className="bootbox-input bootbox-input-text form-control"
								autoComplete="off" type="text" onChange={this.props.onFilterNameChange}
								data-test-auto="4DAFD284-DEAB-4E20-836C-D210419943DE" />
						</Bs.Col>
					</div>
				</Bs.Modal.Body>
				<Bs.Modal.Footer>
					<Bs.Button variant="default" onClick={this.props.onHide} data-test-auto="5B1F43F7-4D0B-45DD-983D-E54B32271615"><i className="fas fa-times"></i>{Common.ButtonLabel.Cancel}</Bs.Button>
					<Bs.Button type="button" variant="info" onClick={() => { this.props.formSubmit() }} data-test-auto="208B8AD6-5D63-410F-A29F-589AC7CDF853"><i className="fas fa-save"></i>{Common.ButtonLabel.Save}</Bs.Button>
				</Bs.Modal.Footer>			
		</Bs.Modal>
	}
}

export default SaveFilters