import * as React from 'react';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";

interface DeliveredBatchOrganizerActionProps {
    rowIndex: number;
    onBatchDeleteOpen: (rowIndex: number) => void;
    onBatchDownloadOpen: (rowIndex: number) => void;
}

const UndeliveredBatchOrganizerAction: React.FC<DeliveredBatchOrganizerActionProps> =
    ({ rowIndex, onBatchDeleteOpen, onBatchDownloadOpen }) => {

        const deleteButton = () => {
            return (
                <button title={"Delete"} type="button" className="btn-white btn-xs"
                    data-resource-id={ResourceIdConstants.DeleteUndeliveredBatchGird}
                    onClick={() => onBatchDeleteOpen(rowIndex)}>
                    <i className="text-danger glyphicon glyphicon-remove" />
                </button>
            );
        }

        const downloadButton = () => {
            return (
                <button title={"Downloads"} type="button" className="btn-white btn-xs"
                    data-resource-id={ResourceIdConstants.DownloadUndeliveredBatchGird}
                    onClick={() => onBatchDownloadOpen(rowIndex)}>
                    <i className="fas fa-file-download" />
                </button>
            );
        }


        const createPrimaryActions = () => {

            return (<div>
                {
                    downloadButton()
                }
                {
                    deleteButton()
                }
            </div>);
        }

        return <div className="button-group">{createPrimaryActions()}</div>;
    }

export { UndeliveredBatchOrganizerAction }