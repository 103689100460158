import { EngagementType } from '../common/TaxReturn';

export const Common = {
	ButtonLabel: {
		Cancel: "Cancel",
		Ok: "Ok",
		Save: "Save",
		Delete: "Delete"
	},
	OverlayMessages: {
		Downloading: "Downloading...Please wait..",
		Processing: "Processing...Please wait",
		Deleting: "Deleting...Please wait"
	}
}

export const UserGroupsConstants = {
	Admin: 'Admin',
	Staff: 'Staff',
	Partner: 'Partner'
}

export const UiTextConstants = {
	FeatureDisabled: 'Feature Disabled',
	AccessDenied: 'Access Denied',
	NoProductAccessMessageLine1: 'We are sorry, you do not have permission to access Safesend Organizers.',
	NoProductAccessMessageLine2: 'Please contact your system administrator to update your user profile',
	FeatureDisabledMessageLine1: 'This product is not supported on your current subscription tier,',
	FeatureDisabledMessageLine2: 'please contact your Customer Success Representative for access.',
	NoPermissionButProductAcessMessageLine1: 'Please consult your system',
	NoPermissionButProductAcessMessageLine2: 'administrator for assistance.',
	ProductDisabledMessageLine1: 'We are sorry, but this feature is currently not available for your use.',
	ProductDisabledMessageLine2: 'Please contact your System Administrator for more information.',
	CommonFeatureDisabledMessageLine1: 'This feature is unavailable due to permission or subscription restrictions.',
	CommonFeatureDisabledMessageLine2: 'Contact your System Admin or Customer Success Representative for details.',
}

export const UserManagementConstants = {
	PleaseNoteText: 'Please Note!',
	SelectUserGroupWarning: 'Please select atleast one user role in Memberships.',
	LoadingUserError: 'An Error occured when loading users.Please Try Again.',
	SavingUserError: 'Saving user details failed, please re-login',
	UserUpdationError: 'Updating user details failed, please re-login',
	EditUserLoadingError: 'User Loading failed, please re-login.',
	DeletingUserError: 'Deleting user failed, please re-login',
	SelectUserWarning: 'Please select a user from the list.',
	SelectGroupWarning: 'Please select a group from the list.',
	SaveUserSuccess: 'User added successfully',
	DeleteUserSuccess: 'Selected user has been deleted',
	UserUpdationSuccess: 'User details has been updated.',
	DeleteUserConfirmation: 'Are you sure you want to delete this user?',
	AddUserDetails: 'Add User Details',
	SetPAssword: 'Set Password',
	PasswordChangeOnNextLogin: 'User must change password on next login',
	ManageMembership: 'User Group',
	UserManagement: 'User Management',
	EditUserDetails: 'Edit User Details',
	ChangePassword: 'Change Password',
	UserExists: 'User already exists.',
	UserPasswordSuccess: 'Password changed successfully',
	PasswordMustBeEightCharacters: 'Your password must be at least 8 characters.',
	DeleteLoggedInUser: 'You cannot delete the logged in user',
	DependentUsers: 'Warning:  You are trying to delete a user that has been selected as the Contact Person for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the deleted user.',
	SignatureFlowDisabledWarning: 'Signature service  is currently disabled for your company. Please enable from company settings.',
	ExportToExcel: 'Export to Excel',
	MobileNumberEmpty: 'Mobile Number can\'t be empty',
	invalidOrganizerDownload: 'Organizers in the following statuses cannot be downloaded.',
	downloadWaitProcessing: 'Download process may take some time , the downloaded forms will be available in "My Downloads"',
	downloadError: 'Download Failed - An error occurred while downloading the selected records. Please try again',
	deleteReturnsError: 'We are unable to delete the selected documents.',
	downloadNowWaitProcessing: 'Please wait as we are preparing your files',
	bulkDownloadMessageTitle: 'Download will initiate only for following organizer statuses:',
	bulkDownloadValidElStatus: 'Engagement Letter : Partially Signed, E-signed.',
	bulkDownloadValidOrganizerStatus: 'Organizer: Partially Completed, Completed.',
	bulkDownloadValidSourceDocumentStatus: 'Source Document: Uploaded.',
	DownloadElStatus: 'Engagement Letter : Awaiting Signature',
	DownloadOrganizerStatus: 'Organizer: Delivered.',
	DownloadSourceDocumentStatus: 'Source Document: Awaiting upload.',
	SigningRelatedStatusNames: '“Awaiting Signature”, “Partially Reviewed”, “Partially Signed”.',
	OrganizerRelatedStatusNames: '“Delivered”, “Partially Completed”, “Questionnaire Completed”.',
	AutoReminderSignWarning: 'Signing Reminder will be sent for statuses: ',
	AutoReminderOrganizerWarning: 'Organizer Reminder will be sent for statuses: ',
	DeliveredReporESignWarning: 'User cannot update reminders for the signature statuses “Reviewed”,“E-signed”, “Downloaded”, “NA”, “Manually Signed"',
	AutoReminderSourceWarning: 'Source Doc. Reminder will be sent for statuses: “Awaiting Upload”, ” Uploaded”, "Downloaded". ',
	DeliveredReportOrganizerWarning: 'User cannot update reminders for the organizer statuses “Completed”, “Downloaded”, “Manually Completed”',
	AutoReminderIconStatus: 'If the status is Downloaded but the TP already Finished the upload step a reminder will not be sent.',
}

export const ValidationContants = {
	FirstNameWarning: 'Please enter first name.',
	LastNameWarning: 'Please enter last name.',
	EmailAdrressWarning: 'Please enter email address.',
	ValidEmailAddressWarning: 'Please enter valid  email address.',
	PhoneNumberWarning: 'Please enter the phone number.',
	PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
	MobileNumberWarning: 'Please enter the mobile number.',
	MobileNumberLengthWarning: 'Please enter a 10 digit mobile number.',
	CountryCodeWarning: 'Please select the country code.',
	PTINWarning: 'PTIN should start with P followed by 8 digit number.',
	EnterPasswordWarning: 'Please enter password and confirm password.',
	PasswordNotMatchedWarning: 'Password and confirmed password did not match.',
	NewPasswordWarning: 'Please enter new password.',
	OldPasswordWarning: 'Please enter old password',
	ConfirmPasswordWarning: 'Please enter confirm password',
	PasswordLengthWarning: 'Password must be 8 characters.',
	PasswordWithoutSpaceWarning: 'Please enter password without space.',
	ZipWarning: 'Please enter zip.',
	ZipLengthWarning: 'Please enter a 5 digit zip.',
	ExtensionWarning: 'Please enter less than 7 digit extension number.',
	FaxWarning: 'Please enter a 10 digit fax number.',
	StateWarning: 'Please select state.',
	CityWarning: 'Please enter city.',
	ValidCityWarning: 'Please enter valid city name.',
	PdfFileValidation: 'Please upload Pdf files only.',
	ZipFileValidation: 'Please upload ZIP files only.',
	ExcelFileValidation: 'Please upload Excel files only.',
	PaymentURLValidation: 'Please enter a valid payment URL.',
	FirmKeyWarning: 'Please enter valid firm key.',
	AzureGroupIdWarning: 'Please enter valid Azure Group Id.',
	PasswordWarning: 'Please enter password.',
	K1InstructionNotAVailable: 'K1 instruction is not available in the uploaded file',
	NoContentAvailable: 'No content available',
	EINWarning: 'Please enter valid EIN.',
	FileFormatNotSupported: 'Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.',
	UpperCaseValidationError: 'Password should contain at least one uppercase character.',
	LowerCaseValidationError: 'Password should contain at least one lowercase character.',
	NumbersValidationError: 'Password should contain at least one number.',
	SpecialCharactersValidationError: 'Password should contain at least one special character.',
	CsvFileValidation: 'Please upload Csv files only.',
	SameEmailValidation: 'Email Already selected.',
	UploadAdditionalSignFileWarning: 'Please upload .doc, .docx or .pdf files only.',
	UploadAdditionalSignSizeWarning: 'Please upload a file size greater than 0kb.',
	PdfDocumentPasswordProtectedError: `{0} \n is an encrypted document and cannot be used. Please remove the encryption and try again.`,
	MultiSelectWarning: 'Action not permitted as organizer is closed.',
	MultiSelectOldCQWarning: 'Action not permitted as Organizer is closed with old Custom Questions.'
}

export const CustomColumnConstants = {
	ValidationMessages: {
		CustomColumnValueWarning: "Please enter column value.",
		CustomColumnValueAlreadyExist: "Custom column value already exists.",
		CustomColumnLength: "Please Enter Maximum of 20 characters.",
		CustomColumnAddValidation: "Unable to add values unless a column name is specified first.",
		CustomColumnSaveValidation: "Unable to add values unless a column name is saved/updated."
	},
	StatusMessage: {
		CustomColumnValueAddSuccess: 'Custom column value added successfully.',
		CustomColumnValueAddError: 'Error occured while adding custom column value.',
		CustomColumnValueLength: "Please Enter Maximum of 100 characters.",
		CustomColumnValueUpdateSuccess: 'Custom column value updated successfully.',
		CustomColumnValueUpdateError: 'Error occured while updating custom column value.',
		CustomColumnValueDeleteSuccess: 'Custom column value deleted successfully.',
		CustomColumnValueDeleteError: 'Error occured while deleting custom column value.',
		CustomColumnNameUpdateSuccess: 'Custom column name updated successfully.',
		CustomColumnNameUpdateError: 'Error occured while updating custom column name.',
		CustomColumnHeaderDeleteSuccess: 'Custom column header deleted successfully.',
		CustomColumnHeaderDeleteError: 'Error occured while deleting custom column header.'
	},
	OverlayMessage: {
		UpdatingCustomColumnName: 'Updating custom column name, please wait...',
		AddingCustomColumnValue: 'Adding custom column value, Please wait...',
		UpdatingCustomColumnValue: 'Updating custom column values, Please wait...',
		DeletingCustomColumnValue: 'Deleting custom column values, Please wait...',
	},
	WarningMessage: {
		DeletingCustomColumnHeader: "The Custom Column you are trying to delete will delete all its corresponding values and it cannot be recovered. Are you sure you want to delete it?",
	}
}

export const DocumentTypeConstants = {
	ValidationMessages: {
		DocumentTypeValueWarning: "Please enter column value.",
		DocumentTypeAlreadyExist: "Document type already exists.",
		DocumentTypeValueLength: "Please Enter Maximum of 40 characters.",
	},
	StatusMessage: {
		DocumentTypeAddSuccess: 'Document type value added successfully.',
		DocumentTypeValueAddError: 'Error occured while adding Document type value.',
		DocumentTypeUpdateSuccess: 'Document type value updated successfully.',
		DocumentTypeValueUpdateError: 'Error occured while updating Document type value.',
		DocumentTypeValueDeleteSuccess: 'Document type value deleted successfully.',
		DocumentTypeValueDeleteError: 'Error occured while deleting Document type value.',
	},
	OverlayMessage: {
		AddingDocumentTypeValue: 'Adding document type value, Please wait...',
		UpdatingDocumentTypeValue: 'Updating document type value, Please wait...',
		DeletingDocumentTypeValue: 'Deleting document type value, Please wait...',
	},
}

export const CompanySettingsConstants = {
	StatusMessage: {
		CompanySettingsError: 'Error occured while fetching company settings',
		CommonSettingsError: 'Error occured while fetching common settings',
		CompanyProfileError: 'Error while loading company profile',
		CompanyLogoError: 'Error while loading company logo',
		UpdateCompanySettingsSuccess: 'Company settings updated successfully.',
		UpdateCompanySettingsError: 'Error occured while updating company settings',
		UpdateRetentionPeriodError: 'Error occured while applying retention period for all documents',
		UpdateCompanyProfileSuccess: 'Company Information saved',
		UpdateRetentionForAllDocuments: 'Retention Settings applied successfully',
		UpdateCompanyLogoError: 'Unable to update logo, Please try again',
		AdminProfileError: 'Error while loading company profile',
		ADAzureExist: 'Group ID has already been added on a different Account. Please enter a different Group ID'
	},
	OverlayMessage: {
		UpdatingCompanySettings: 'Updating company settings...',
		ApplicationLoading: 'Loading, please wait...'
	},
	Validate: {
		CompanyNameWarning: 'Please enter company name.',
		AddressWarning: 'Please enter street address 1.',
		StateWarning: 'Please select state.',
		WebsiteWarning: 'Please enter valid website.',
		ImageWarning: 'Please upload only image.',
		ImageDimensionWarning: 'Max dimensions are 300x100px.',
		ImageValidation: 'Image validation failed! try again...',
		TrustedDeviceExpiryDays: 'Please enter the days between 1 to 60.'
	},
	HeaderName: {
		MyCompany: 'My Company',
		CompanyDetails: 'Company Details',
		PrimaryAdmin: 'Primary Admin',
		CompanyLogo: 'Company Logo',
		EditCompany: 'Edit Company Details',
		ChangePrimaryAdmin: 'Change Primary Admin',
		UploadCompanyLogo: 'Upload Company Logo',
		CompanyLogoMessage: 'Your selected logo will be used on all web pages, emails and notifications',
		PrimaryAdminMessage: 'Primary Admin is always a system administrator'
	}

}

export const ReturnMailingAddressConstants = {
	ValidationMessages: {
		TitleWarning: 'Please enter title',
		NameWarning: 'Please enter name',
		StreetAddress1Warning: 'Please enter street address1.',
		ManualAddressTitlePresentWarning: 'Title is already present, please enter different title',
	},
	StatusMessage: {
		ManualAddressUpdateSuccess: 'Manual address updated successfully.',
		ManualAddressAddSuccess: 'Manual address added successfully.',
		SavingManualAddress: 'Saving manual address, please wait...',
		Loading: 'Loading Manual Address, please wait...',
		ManualAddressDeleteSuccess: 'Manual Address deleted successfully.',
		ManualAddressDeleteFailed: 'You can\'t delete this manual address as it is being used.',
		FecthingManualAddress: "Fetching manual address, please wait...",
		AddingManualAddress: "Adding manual address, please wait...",
		UpdatingManualAddress: "Updating manual address, please wait...",
		DeletingManualAddress: "Deleting manual address, please wait...",
	}
}

export const ReportFilterConstants = {
	StatusMessage: {
		SavedSuccessMessage: "Filter saved successfully.",
		SavedErrorMessage: "Failed to save the filter!",
		UpdateSuccessMessage: "Filter updated successfully.",
		UpdateErrorMessage: "Failed to update the filter!",
		DeleteSuccessMessage: "Filter deleted successfully.",
		DeleteErrorMessage: "Failed to delete the filter!",
		GetAllErrorMessage: "Failed to fetch the filters!",
	},
	ValidationMessage:
	{
		NoRecentFilters: "There is no recent filter to apply!",
	},
	ControlsLabel:
	{
		ApplyRecentFilterBtn: "Apply recent filter",
		SaveCurrentFilterBtn: "Save current filter",
		SetAsDefaultFilter: "Set as Default Filter",
		DeleteFilter: "Delete Filter",
		ExportToExcel: "Export to Excel",
		ClearFilter: "Clear Filter",
		RemoveDefault: "Remove Default",
		PleaseEnterTheFilterName: "Please enter the filter name",
		Apply: "Apply",
		SetDefault: "Set Default",
		Delete: "Delete",
		AppliedFilter: "Applied Filter"
	},
	OtherMessage: {
		DeleteFilter: "Are you sure you want to delete this filter?",
	},
	ControlsTitle: {
		ApplyMostRecentFilter: "Apply Most Recent Filter",
		ToggleDropdownMenu: "Toggle dropdown menu"
	},
	DefaultFilterBuilder: "_D_FILTER"//This suffix is trimmed to maintain total length to 25,
}

export const DeliveredReturnsTableConstants = {

	OtherMessage: {
		NoReturnsFound: "No returns found"
	}
}

export const VoucherReminderManagementConstants = {
	OtherMessage: {
		NoDataFound: "No records found"
	},
	ControlsLabel:
	{
		ExportToExcel: "Export to Excel"
	}
}

export const OrganizerFilterConstants = {
	FilterNameAlreadyExists: "Filter name already exists.",
	FilterNameIsNotValid: "Filter name should not contain special characters: # / ? \\",
	FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
	PleaseEnterFilterName: "Please enter filter name",
	FilterDoesNotExists: "Filter doesn't exists.",
	DeleteFilter: "Are you sure you want to delete this filter?",
	Title: {
		FilterSaveUpdate: "Save/Update Filter",
	},
	OtherMessage: {
		DoYouWantToCreateNewFilterOrUpdateTheCurrent: "Do you want to create New Filter or Update the Current?"
	},
	ControlLabel: {
		YesCreateNew: "Yes, Create New",
		NoUpdateTheCurrent: "No, Update The Current"
	}
}

export const DeliveredReturnsConstants = {
	OtherMessage: {
		SelectCustomColumnValue: "Please select column value"
	},
	StatusMessage: {
		SetDefaultFilterSuccess: "Default filter has been updated successfully.",
		RemoveDefaultFilterSuccess: "Default filter has been removed successfully.",
		UnlockDocumentSuccess: "Document have been unlocked successfully."
	},
	WarningMessage: {
		SendReminderWarning: "For the selected returns, Automatic reminders has been enabled, still do you want to send the reminder?"
	},
	BulkOperationMessage: {
		SendReminderWarning: `Signing Reminder can be sent for the returns which are in 'Awaiting Upload' and 'Awaiting Esign' status. 
                               \nAlso, Automatic reminder has been enabled for the selected returns. 
                               \nDo you still want to continue ?`,
		SendReminderWaitProcessing: "We are processing the reminders for emailing. This may take several minutes",
		SendReminderError: 'We are unable to send reminder for these documents.',

		Processing: "This may take several minutes. Thank you for your patience.",
		CustomColumnError: 'We are unable to change custom column for these documents.',

		ArchiveError: 'We are unable to archive the selected documents.',
		downloadEFileWarning: `E-File forms can be downloaded for the returns which are in "Esigned", "Uploaded", "Signed and Esigned" and "Alternate and Uploaded" Status.
                               \nDo you want to continue?`,
		downloadEFileWaitProcessing: 'Download process may take some time , the downloaded Efile forms will be available in "My Downloads"',
		downloadEFileError: 'Download Failed - An error occurred while downloading the selected records. Please try again',
		DeleteReturnsError: 'We are unable to delete the selected documents.',
		downloadEFileNowWaitProcessing: 'Please wait as we are preparing your files'
	},
	ErrorMessage: {
		UploadedESignedEfileFormsCanOnlyDownloadable: "The E-file forms in the following status - 'Uploaded', 'E-signed', 'Signed & E-signed', 'Alternate & Uploaded', can be downloaded.",
		ChangeStatusError: 'Error : Status cannot be changed.',
	},
	ColumnSelectorMessage: {
		SaveColumnSettingsSuccess: "Column Settings saved successfully!",
		SaveColumnSettingsLoader: "Saving your changes. Please wait...",
		ModalHeader: "Use the toggle option to show or hide the columns in the report."
	}
}

export const InUseReturnsConstants = {
	ValidationMessage: {
		NoDocumentSelectedWarning: "Please select document(s) to Make available!"
	}
}

export const RecyleReturnsConstants = {
	SuccessMessages: {
		Delete: "Deletion in progress, Please refresh after sometime.",
		Restore: "Successfully restored Selected Organizer(s)."
	},
	ConfirmMessages: {
		Delete: `<p>Are you sure you want to delete the selected organizer(s)?</p>
				<p style="margin-top:5px">On Delete, System will permanently delete all the organizer(s) from the SafeSend Organizer application and they will be no longer available to anyone and the Taxpayer's access link will be disabled permanently.<p>`,
		Restore: "<p>Are you sure you want to restore the selected organizer(s)?</p>"
			+ "<p class='please-note-msg'>Note: Any previously scheduled reminders will be turned on for organizer(s) restored to delivered report.</p>"

	},
	Title: {
		RecyleReturns: "<i class='text-danger fas fa-trash-alt'/> Permanent Delete",
		Restore: "<i class='text-danger fas fa-undo'/> Restore"
	},
	SelectOrganizerWarning: "Please select Organizer(s) to delete!",
}

export const SavedMessageConstants = {
	SaveMessageSuccess: 'Message added successfully',
	SaveMessageNameError: 'Name already exists',
	SaveMessageError: "Failed to save message",
	UpdateMessageError: "Failed to update message",
	UpdateMessageSuccess: 'Message updated successfully',
	DeleteMessageSuccess: 'Message deleted successfully',
	ValidateName: 'Please enter a message name.',
	ValidateSubject: 'Please enter a message subject.',
	ValidateBody: 'Please enter a message body.',
	DeleteMessageWarning: 'You cannot delete the default message',
	SavedMessageWarning: "Message details",
	SetDefaultMessageWarning: "This message will be set as default message",
	RemoveDefaultMessageWarning: "This message will be removed as default message",
	SelectDefaultMessageWarning: "Please select a default message first",
}

export const ClientInstructionConstants = {
	SaveMessageSuccess: 'Instruction added successfully',
	UpdateMessageSuccess: 'Instruction updated successfully',
	DeleteMessageSuccess: 'Instruction deleted successfully',
	ValidateName: 'Please enter instruction name.',
	ValidateHeader: 'Please enter instruction header.',
	ValidateText: 'Please enter instruction text.',
	ValidateSubject: 'Please enter instruction subject.',
	DeleteInstructionWarning: 'You cannot delete the default client instruction.',
	SetDefaultMessageWarning: "This message will be set as default message",
	RemoveDefaultMessageWarning: "This message will be removed as default message",
	SaveMessageError: "Failed to save client instruction",
	UpdateMessageError: "Failed to update client instruction",
	DeleteMessageError: "Failed to delete client instruction",
	FetchMessageError: "Failed to fetch client instruction"
}

export const AttachmentInstructionsConstants = {
	Success: 'Attachment instruction added successfully',
	NameError: 'Attachment instruction name already exists',
	SaveError: 'Failed to save attachment instruction',
	UpdateError: "Failed to update attachment instruction",
	UpdateSuccess: 'Attachment instruction updated successfully',
	DeleteSuccess: 'Attachment instruction deleted successfully',
	DeleteError: 'Unable to delete attachment instruction',
	ValidateName: 'Please enter attachment instruction name.',
	ValidateText: 'Please enter attachment instruction text.',
	DeleteWarning: 'You cannot delete the default attachment instruction',
	SetDefaultWarning: "This message will be set as default attachment instruction",
	RemoveDefaultWarning: "This message will be removed as default attachment instruction",
	SelectDefaultWarning: "Please select a default attachment instruction first",
}

export const UserSettings = {
	fetchUserSettings: "Failed to fetch User Settings",
	saveUserSettingsSuccess: "User Settings saved successfully",
	saveUserSettingsError: "Failed to save User Settings",
	saveSignatureDelegations: "Failed to save delegates",
	fetchUserNotificationSettingsError: "Failed to fetch User Notification Settings",
	saveUserNotificationSettingsError: "Failed to save User Notification Settings",
	saveUserNotificationSettingsSuccess: "User Notification Settings saved successfully"
}

export const ArchiveReturnsConstants = {
	downloadEformFilesState: "Could not find downloadable Efile document(s).",
	downloadDocument: "Could not find downloadable document(s).",
	PleaseSelectDocumentToRestore: "Please select document(s) to restore!",
	PleaseSelectOneOrMoreDocuments: "Please select one or more documents",
	UploadedESignedEfileFormsCanOnlyDownloadable: "Error: Only Uploaded & E-Signed e-file forms can be downloaded",
	OneOrMoreItemsWereNotRemovedAsThierValuesDidNotMatchTheSelectedValue: "One or more items were not removed as thier values did not match the selected value.",
	documentRestoreConfirmation: "Are you sure you want to restore selected document(s)?"
}

export const ReportProblemConstants = {
	OverlayMessage: {
		SubmittingIssue: "Submitting your issue, Please wait..."
	},
	SuccessMessage: 'Problem reported successfully.Your Tracking ID is ',
	ErrorMessage: 'Failed to report the problem!'
}

export const AttachmentConstants = {
	FilesizeWarning: 'File size limit should not exceed to 1 GB.',
	validDocument: 'Please select a valid document, currently selected file is empty.',
	AddAttachments: 'Add Attachments',
	UploadedFiles: 'Uploaded Files',
	MaxFileSize: '1091900799',
	UploadingIncomplete: 'Please wait for uploading to complete before adding instructions'
}

export const UploadtaxReturnConstants = {
	SetEROSignerWarning: "Please select a ERO / Signer to submit.",
	SubmitTaxReturnSuccess: "The following return was successfully submitted",
	SubmitTaxReturnFailed: "Failed to submit the following return, please try again",
	AbortingUploadWarning: "Aborting upload cannot be processed in between , please wait.",
	CloseConfirmationMessage: "Closing this will delete the files, do you want to proceed?",
	UploadingFailedError: "Uploading failed, please try again",
	DeleteFileMessage: "Are you sure you want to delete this file?",
	Recall: {
		FileAddingWarning: "one file is already added.",
		MultipleFileAddingWarning: "only one file can be uploaded."
	},
	ReturnSubmitSuccess: "The following return(s) were successfully submitted",
	EmptyFileListMessage: "Files list will appear here.",
	OverlayMessage: {
		ProcessTaxReturn: 'Processing tax return...',
		ApplicationLoading: 'Loading, please wait...'
	},
	StatusMessage: {
		TaxReturnLinkError: 'Error while fetching tax return link.',
		ProcessTaxReturnError: 'Error occured while processing tax return',
		UpdateTaxReturnError: 'Unable to update tax return, Please try again',
	},
}

export const MyAccountConstants = {
	//API response
	APIResponse: {
		UserUpdateSuccess: "User updated successfully."
	},
	UserUpdateFailedMessage: "Updating user details failed, please re-login",
}

export const ChangePasswordConstants =
{
	PasswordChangeLogoutWarning: "Changing your Password will require you to login again. Make the change?",
	PasswordUpdateSuccess: "Password updated successfully.",
	PasswordUpdateFail: "Updating password failed, please re-login",
}

export const SaveandCloseProcessReturn = {
	StatusMessage: {
		Success: "Your changes are saved successfully",
		Fail: "Error occured while saving your changes.",
	}
}

export const FinishProcessReturn = {
	MenuItems: {
		SendForReview: 'Send for Review',
		SendToEROSigner: 'Send to ERO / Signer',
		ApproveForDelivery: 'Approve for Delivery',
		DeliverToClient: 'Deliver to Client',
		DeliverToCompany: 'Deliver to Company',
		ReturnToProcessor: 'Return to Processor',
		DeliverToTaxCaddy: 'Deliver to TaxCaddy',
		DeliverWithTaxCaddy: 'Deliver with TaxCaddy',
		RetentionPeriod: 'Modify Retention Period',
		PaperDelivery: 'Download PDF',
		Empty: "",
	},
	StatusMessage: {
		SendToEROSuccess: "Sent to ERO successfully.",
		SendForReviewSuccess: "Sent for review successfully.",
		ApproveForDeliverySuccess: "Sent to approve for delivery successfully.",
		DeliverToClientSuccess: "Delivered to client successfully.",
		SendToEROError: "Error occured while sending to ERO.",
		SendForReviewError: "Error occured while sending for review.",
		ApproveForDeliveryError: "Error occured while sent to approve for delivery.",
		DeliverToClientError: "Error occured while delivering to client.",
		GenerateTaxpayerViewSuccess: "Loaded taxpayer view successfully.",
		GenerateTaxpayerClientViewSuccess: "Loaded taxpayer client view successfully.",
		GenerateTaxpayerViewError: "Error occured while loading the taxpayer view",
		GenerateTaxpayerClientViewError: "Error occured while loading the taxpayer client view",
		DeliverToClientWithoutInvoiceError: "You are required to include an invoice and amount due with this Tax Return. Please return to the Invoice Step and ensure and invoice is included and the amount is entered in the proper field.",
		SelectApprover: "Please select a user to approve for delivery.",
		SelectDeactivatedDeletedAuthority: "Please Select Alternate Authority for Deleted/DeActivated Authority.",
		TaxCaddyLookupError: "Unable to communicate to TaxCaddy server, please contact support.",
		RetentionValidationMessage: "Please specify a valid retention period.",
	},
	InfoMessage: {
		AttchmentNotDeliverToTaxCaddy: "Attachments will not be delivered to TaxCaddy",
		TaxCaddyApprove: "On Accept, this return will be delivered to TaxCaddy",
		TaxCaddyReject: "On Reject, this return will not be delivered to TaxCaddy",
		NotDeliveredToTaxcaddy: ' This return will not be delivered to TaxCaddy'
	}
}

export const PredefinedFileName = {
	K1InstructionBlob: "6c1525a7-69d4-4ca2-b67b-495049f1d132",
	FileNotFound: "FileNotFound"
}

export const K1InstructionFileName = {
	E1065_2015: "Partner`s Instructions for Schedule K-1 (Form 1065)(2015).pdf",
	E1065_2016: "Partner`s Instructions for Schedule K-1 (Form 1065)(2016).pdf",
	E1065_2017: "Partner`s Instructions for Schedule K-1 (Form 1065)(2017).pdf",
	E1120S_2015: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2015).pdf",
	E1120S_2016: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2016).pdf",
	E1120S_2017: "Shareholder`s Instructions for Schedule K-1 (Form 1120S)(2017).pdf"
}

export const InvoiceReplaceConstants = {
	//API response
	InvoiceAmountWarning: "Please enter a invoice amount.",
	BookmarkTextWarning: "Please enter bookmark text.",
	NonPdfFileWarning: "Please upload PDF file only.",
	MultipleFileWarning: "Please upload one file."
}

export const Assignment = {
	Description: "DESCRIPTION"
}

export const AssignUserConstants = {
	SelectDocumentWarning: "Please select document(s) to assign to another user!",
	RecalledReturnWarning: "Return(s) cannot be assigned when their status is RECALLED."
}

export const SetAccessConstants = {
	SelectDocumentWarning: "Please select document(s) to set access for other users!",
	RecalledReturnWarning: "Access cannot be set when status is RECALLED."
}

export const ChangeStatusConstants = {
	SelectedStatus: "Please select status",
	Title: "Change Status",
	Error: "Cannot change status of the selected document!",
	changeStatusConfirmation: "You are about to change the status of the document manually. Do you want to proceed?",
	ChangeDeliveredReturnStatus: "Warning: This document has not been E-Signed by all parties. Changing the status to \"Signed & ESigned\" indicates that you have obtained the remaining signatures manually (i.e. fax, email, mail etc.). The audit trail for this document will only include those signers who have E-Signed."
}

export const UploadCorrectedReturnWarning = {
	message: "If you upload a different return type from the recalled one, it will count towards your usage."
}

export const DeleteTaxReturnConstants = {
	Title: "Delete Tax Return",
	SelectDocumentWarning: "Please select document(s) to delete!",
	InUseReturnWarning: "Warning: In-use returns are not allowed to be deleted!",
	ConfirmDeletion: 'You are about to delete the selected document(s). This cannot be undone.\nDo you want to proceed?',
}

export const BusinessReturnConstants = {
	WarningNonPdfFile: "Please upload PDF file",
	DocumentReplacedSuccessMessage: "Document replaced successfully",
	ReplaceDocumentLoaderMessage: "Replacing Document...",
	getEngagementTypeDisplayName: function (engagementType: EngagementType): string {
		let type: string = '';
		switch (engagementType) {
			case EngagementType.E1065: type = "1065";
				break;
			case EngagementType.E1120S: type = "1120S";
				break;
			case EngagementType.E1041: type = "1041";
				break;
		}
		return type;
	},
	FetchInstructionsErrorMessage: "Failed to fetch Business Return Instructions.",
	FetchInstructionErrorMessage: "Failed to fetch Business Return Instruction."
}

export const CustomWindowConstants = {
	closePopoverMessage: "Your changes will be discarded, Are you sure you want to close the return?"
}

export const CompanySettingConstants = {
	closePopoverMessage: "Your proposed change will delete %COUNT% delivered returns on %DATE%.  Please confirm you would like to apply this change."
}

export const GeneralSettingConstants = {
	disableEngagementLetterPopoverMessage: "Applicable only for TaxSoftwares GoSystem, UltraTax and Lacerte",
	engagementLetterHeading: "Engagement Letter Default",
	engagementLetterCheckbox: "Allow users to deliver organizers without Engagement Letters",
	sourceDocumentHeading: "Gather Source Document",
	sourceDocumentChecbox: "Enable gathering for taxpayer source documents",
	sourceDocumentNotificationHeading: "Source Document Notification",
	notifyEveryTimeUploadOrDeleteSrcDocRadioBtn: "Send notification every time client Uploads/Deletes a source document",
	schNotification1PerDayUploadOrDeleteSrcDocRadioBtn: "Send scheduled notification once per day, if client Uploaded/Deleted source document(s)",
	sourceDocumentUploadNotificationDisabledRadioBtn: "Source Document Upload Notification Disabled",
	sourceDocNotificationtoolTip: "Enable gather source document to configure email notification.",
	automaticRemaindersHeading: "Automatic Reminders",
	automaticRemaindersCheckbox: "Enable Automatic Reminders",
	promptMessage: "Any changes made to settings will be lost on navigating without saving. Do you wish to continue?"
}

export const ClientInstructionMessageVariables = {
	CompanyName: "The listed Name of your company on your account",
	TaxPayerName: "The listed Taxpayer on the Tax Return",
	SpouseName: "The listed Spouse on the Tax Return",
	HelpContact: "The name of the user designated for customer support",
	HelpPhone: "The phone number of the user designated for customer support",
	HelpEmail: "The email address of the designated help contact",
	PrepareName: "The listed Preparer on the Tax Return",
	DueDate: "The due date of the filing",
	NumberOfays: "Number of days set for voucher reminder",
	Recipient: "The person name for which the email is being sent",
	TaxYear: "The Taxyear on the Tax Return",
	EROName: "The name of the assigned ERO Signer",
	SenderName: "The name of the user selected as the 'Sender' of the return"
}

export const Version = {
	VersionNumber: 'V 4.0'
}

export const ClientInfoConstants = {
	emailUpdatedConfirmation: "You have updated the email address on file, would you like to resend the Return to the new email address?"
}

export const ReGroupConstants = {
	PageMoveSuccess: "Page moved successfully",
	DeletedPageMoveSuccess: "Page moved to Deleted successfully",
	SameSourceAndDestinationError: "Unable to move selected forms as source and destination groups are same",
	PageDeleteNotAllowed: "Action Not Allowed: There must be at least one page existing in this group."
}

export const PageToolBarConstants = {
	moveToDeleteConfirmation: "Are you sure you want to move this page to deleted?"
}

export const ReturnDownloadOptionsConstants = {
	IncludeInTaxReturnPdf: "Include in the Tax Return PDF",
	CreateSeperatePdf: "Create a Separate PDF",
	SeparateByQuarterlyDueDates: "Separate by Quarterly Due Dates",
	GroupInSinglePDF: "Group in Single PDF"
}

export const ProcessReturnConstants = {
	ReturnAlreadyOpenedMessage: "Return already opened !!",
	ReturnInUse: "Return In-Use",
	ReturnNotAssignedToLoggedInUser: "This return is assigned to another user. Please make sure the return is assigned to you in order to make any changes.",
	ProcessReturnOpenMessage: "Opening Return ...",
	DuplicateReturnMessage: '<br/>We have detected that this return has already been delivered. Please review the Delivered Returns or Archived Returns reports to ensure you are not delivering a duplicate tax return <br/><br/><br/> Please press <b>OK</b> to continue and <b>Cancel</b> to go back'
}

export const ReProcessReturnConstants = {
	ReProcessInitiatedModalLoader: "Reprocessing initiated..."
}

export const EsignHelpTextConstant = {
	ClientSignature: "Drag and drop to place client's signature",
	SignatureDate: "Drag and drop to place date of signature",
	NameAndTitle: "Drag and drop to place Name and Title",
	EroSignatureStamp: "Drag and drop to place ERO signature stamp",
	Initial: "Drag and drop to place Initials control",
	Text: "Drag and drop to place Text control",
	Company: "Drag and drop to place Company control",
	Name: "Drag and drop to place Name control",
	Title: "Drag and drop to place Title control"
}

export const PasswordPolicyConstants = {
	NoChanges: 'There are no changes to save.',
	MinimumCharacter: 'Minimum numbers of characters should be greater than 8.',
	MinimumOption: 'Select at least two options in character type.'
}

export const SignatureFlowReportConstants = {
	DocumentNote: {
		SaveSuccess: 'Successfully saved document note.',
		UpdateSuccess: 'Successfully updated document note.',
		DeleteSuccess: 'Successfully deleted document note.',
		Error: 'Error occured while saving document note.'
	},
	CancelDocument: {
		Description: 'By canceling this document, recipients can no longer view it or sign enclosed documents. Recipients will receive an email notification, which includes your reason for canceling the document',
		CanceledTitle: 'Canceled Document',
		CancelTitle: 'Cancel Document',
		DeclinedTitle: 'Declined Document',
		CanceledReasonTilte: 'Reason for canceling this document.',
		DeclinedReasonTitle: 'Reason for declining this document.',
		UpdateSuccess: 'Successfully updated your changes.',
		Error: 'Error occured while saving your changes.',
		EmptyDescriptionWarningMsg: 'Please enter reason for canceling this document'
	},
	SendReminder: {
		Success: 'Reminder has been sent successfully for the selected returns',
		Error: 'Failed to send reminder for the selected document(s)!'
	},
	DeleteReturn: {
		Success: 'Successfully deleted the selected return(s)!',
		Error: 'Failed to delete the selected document(s)!'
	},
	SetAccess: {
		Success: 'Document Access set successfully for all selected returns',
		Error: 'Setting document access for the users failed!'
	},
	ResendAccessLink: {
		Success: 'Access Link has been sent successfully for the selected returns',
		Error: 'Failed to resend access link for the selected document(s)!'
	},
	AccessCode: {
		Success: 'Access Code generated successfully',
		Error: 'Failed to generated OTP for the return'
	},
	CancelDocumentMail: {
		Success: 'Cancel document notification has been sent successfully for the selected document signers',
		Error: 'Failed to send cancel document notification for the selected document(s)!'
	},
	DeleteDocument: {
		Success: 'Successfully deleted the selected signature report',
		Error: 'Failed to deleted the selected signature report'
	},
	DownloadHistory: {
		Error: 'Failed to fetch download history for the selected document.'
	},
	DownloadDocument: {
		Success: 'Document(s) downloaded successfully.',
		Error: 'Failed to download the selected document(s).',
		ModalTitle: 'Download Documents',
		ModalMessage: 'Click the below links to download'
	},
	SignatureSigners: {
		Error: 'Error occured while fetching signature signers'
	}
}

export const SignatureFlowReportCustomColumnConstants = {
	StatusMessage: {
		ErrorFetchingCustomColumns: 'Error occured while fetching signature report custom columns.',
		ErrorUpdatingCustomColumns: 'Error occured while updating signature report custom columns.',
		ErrorSavingCustomColumns: 'Error occured while saving signature report custom columns.',
		UpdatingCustomColumnsSuccess: 'Successfully updated signature report custom columns.',
		SavingCustomColumnsSuccess: 'Successfully saved signature report custom columns.'
	}
}

export const SignatureFlowReportFilterConstants = {
	StatusMessage: {
		SavedSuccessMessage: "Filter saved successfully.",
		SavedErrorMessage: "Failed to save the filter!",
		UpdateSuccessMessage: "Default filter has been updated successfully.",
		UpdateErrorMessage: "Failed to update the filter!",
		DeleteSuccessMessage: "Filter deleted successfully.",
		DeleteErrorMessage: "Failed to delete the filter!",
		GetAllErrorMessage: "Failed to fetch the filters!",
	},
	ValidationMessage:
	{
		NoRecentFilters: "There is no recent filter to apply!",
		EmptyDocumentNote: "Please enter a document note."
	},
	ControlsLabel:
	{
		ApplyRecentFilterBtn: "Apply recent filter",
		SaveCurrentFilterBtn: "Save current filter",
		SetAsDefaultFilter: "Set as Default Filter",
		DeleteFilter: "Delete Filter",
		ExportToExcel: "Export to Excel",
		ClearFilter: "Clear Filter",
		RemoveDefault: "Remove Default",
		PleaseEnterTheFilterName: "Please enter the filter name",
		Apply: "Apply",
		SetDefault: "Set Default",
		Delete: "Delete",
		AppliedFilter: "Applied Filter"
	},
	OtherMessage: {
		FilterNameAlreadyExists: "Filter name already exists.",
		FilterFieldsAreEmpty: "Filter fields are Empty! Please provide at least one.",
		PleaseEnterFilterName: "Please enter filter name",
		FilterDoesNotExists: "Filter doesn't exists.",
		DeleteFilter: "Are you sure you want to delete this filter?",
		CreateOrUpdateFilter: "Do you want to create New Filter or Update the Current?",
		NoReturnsFound: "No returns found"
	},
	FilterSuccessMessage: {
		RemoveDefaultFilterSuccess: "Default filter has been removed successfully.",
	},

	FilterConfirmationModalTitle: {
		FilterSaveUpdate: "Save/Update Filter",
	},
	ControlLabel: {
		YesCreateNew: "Yes, Create New",
		NoUpdateTheCurrent: "No, Update The Current"
	}
}

/////////////////////////////////////////Tab Constants Start/////////////////////////////////////////////////
export const K1TabConstants = {
	K1UploadConfirmation: "Document replaced successfully."
}

export const ClientInfoTab = {
	Page: {
		DeleteConfirm: "Are you sure you want to delete this page?"
	},
	AddedRefund: {
		DeleteConfirm: "Are you sure to delete?",
		AuthorityWarning: "Please select a taxing authority to add.",
		AmountEmptyWarning: "Please enter valid amount.",
		OverpaymentLessThanWarning: "Overpayment amount should be greater than overpayment applied.",
		DOBWarning: "Please enter valid date of birth."
	},
	UpdateRefund: {
		RefundUpdateSuccess: "Refunds & payments Due updated successfully",
	}
}

export const TabEFileConstants = {
	Info: {
		EROStampInfoMessage: "The ERO stamp will be applied in all pages"
	},
	SignatureControlWarning: {
		Taxpayer: "There should be at least one signature control for the taxpayer to sign.",
		Partner: "There should be at least one signature control for the partner to sign.",
		Spouse: "There should be at least one signature control for the spouse to sign.",
		EroStamp: "There should be at least one ERO signature stamp."
	}
}

export const VoucherTabConstants = {
	AddedVoucher: {
		DeleteConfirm: "Are you sure you want to delete this Voucher?",
		AmountEmptyWarning: "Please enter valid amount.",
		AuthorityWarning: "Please select a taxing authority.",
		PaymentTypeWarning: "Please select a payment type.",
		DueDateWarning: "Please select the due date.",
		FormNameWarning: "Please enter form name",
		OnlinePaymentUrlWarning: "Please enter payment url.",
		OnlinePaymentValidUrlWarning: "Please enter valid payment url.",
		CustomTypeWarning: "Please select the watermark option.",
		CustomTextWarning: "Please enter a custom watermark.",
		OneVoucherWarning: "You must create at least one Voucher by entering the related Amount ($)."
	},
	AddVoucher: {
		Authority: "Authority",
		PaymentType: "Payment Type",
		DueDate: "DueDate",
		Amount: "Amount",
		FormName: "Form Name",
		AddVoucher: "Add Payment",
		PaymentUrl: "Payment Url",
		VoucherAmount: "Voucher Amount",
		voucherNumbers: [
			{ value: '0', label: 'Select' },
			{ value: '1', label: 'Payment Voucher' },
			{ value: '2', label: 'Q1 Estimated Payment' },
			{ value: '3', label: 'Q2 Estimated Payment' },
			{ value: '4', label: 'Q3 Estimated Payment' },
			{ value: '5', label: 'Q4 Estimated Payment' }
		],
		paymentMethods: [
			{ value: '1', label: 'Select' },
			{ value: '2', label: 'DO NOT PAY:  Scheduled for automatic withdrawal' },
			{ value: '3', label: 'Online payment required' },
			{ value: '4', label: 'Add Custom' },
		],
		voucherPdfPrepareMessageFailed: "Preparing voucher failed",
		voucherDeleteFailed: "Cannot Delete Vouchers apart from Manually added",
		voucherEditWarning: "No Fields updated",
		btnAddVoucherText: "Add",
		voucherDueDateUpdate: "Voucher DueDate Update Success",
		voucherAddSuccess: "Data updated successfully for "
	},
	DatabaseValues:
	{
		FederalAuthorityName: "Federal"
	},
	VoucherSortBookmarkWordMatchCount: 1,
	DeleteZeroAmountVoucher_1: "Are you sure you want to delete ",
	DeleteZeroAmountVoucher_2: " '$0' vouchers?",
	VoucherUpdateSuccess: "Voucher details updated successfully",
	VoucherDueDateInvalid: "Please update the due date for voucher ",
	PaymentMethods: [
		{ value: '0', label: 'Payment Voucher' },
		{ value: '1', label: 'Q1 Estimated Payment' },
		{ value: '2', label: 'Q2 Estimated Payment' },
		{ value: '3', label: 'Q3 Estimated Payment' },
		{ value: '4', label: 'Q4 Estimated Payment' }
	]
}

export const GroupTabConstants = {
	singlePageDragGhostText: "1 Page.",
	Page: {
		DeleteConfirm: "Are you sure you want to delete this page?",
		WarningValueNotMatch: " One or more items were not removed as thier values did not match the selected value."
	}
}

export const ClientInfoValidation = {
	inCorrectEmail: "Please enter valid email address.",
	inValidSSN: "Please enter valid SSN.",
	inCorrectTaxpayerEmail: 'Please enter valid taxpayer Email Id.',
	inCorrectSpouseEmail: 'Please enter valid spouse Email Id.',
	inCorrectCompanyEmail: 'Please enter valid Company email Id.',
	inValidTaxpayerSSN: "Please enter valid taxpayer SSN.",
	inValidSpouseSSN: "Please enter valid spouse SSN.",
	inCorrectPartner: "Please select ERO / Signer",
	inCorrectERO: "Please select an ERO / Signer from drop-down"
}

export const DeliveryOptionsValidation = {
	InCorrectContactPerson: "Please select a Contact Person.",
	InValidMessageFromPreparer: "Please select a Preparer Message.",
	InValidSenderName: "Please select a Sender Name.",
	InValidNotifyAboutSigningsEvents: "Please select a contact to Notify About Signing Events.",
	InvalidNumberofDaysVoucherReminder: "Please select a number of days for the Voucher Reminders.",
	InvalidNumberofDaysSigningReminder: "Please select a number of days for the Signing Reminder."
}

export enum SignatureStatusIds {
	None,
	ESigned,
	ManuallySigned,
	Uploaded,
	AwaitingESign,
	AwaitingUpload,
	Locked,
	MailOrFax,
	SignedAndESigned
};

export const CustomAuthoritys = {
	DuplicateCustomAuthority: "Dupliate Authority and State",
	AddCustomAuthority: "Authority Saved Successfully.",
	ActivateAuthority: "Status Changed Successfully.",
	DeleteAuthority: "Authority Deleted Successfully.",
	message: "The Authority is in Use So Can't be Deleted",
	deleteConfirmMessage: "Are you sure, you want to delete this Custom Authority?",
	stateWarning: 'Please select state.',
	cityWarning: 'Please enter city.',
	OnlinePaymentValidUrlWarning: "Please enter valid online payment url.",
	InUseWarnig: "This authority you are trying to edit is currently in use. Please remove the Authority from below tax returns and try again. Click",

}

export const FileConstants = {
	MaximumFileSize: '536870912',
	MaximumFileNameCharacter: 200
}

export const FileSizeExceedsLimit = (fileName: string) => `${fileName} cannot be uploaded since the size is greater than 512 MB.`;

export const FileNameCharacterLimitExceeds = (fileCount: number, totalFileCount: number) => `Unable to upload ${fileCount} out of ${totalFileCount} file(s), PDF name character limit exceeded`;
/////////////////////////////////////////Tab Constants End/////////////////////////////////////////////////

export const K1SettingsConstants = {
	K1MailCycleOptions: [
		{ value: 1, label: 'Before' },
		{ value: 2, label: 'After' },
	]
}

export const DownloadEfileZip = (date: string) => `efile_download_${date}.zip`;

export const DownloadReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns_${taxYear}.zip`;

export const DownloadUnsignedReturnsZip = (clientId: string, taxYear: number) => `${clientId}_Tax Returns-Copy_${taxYear}.zip`;

export const DownloadAttachmentsZip = () => `Attachments.zip`;

export const ClientTrackingEvents = {
	InitialEmail: 'Initial Email - ',
	VoucherReminderEmail: 'Voucher Reminder Email - ',
	AccessCodeEmail: 'Access Code Email - ',
	TaxPayerKBASuccess: 'Taxpayer KBA Successful',
	SpouseKBASuccess: 'Spouse KBA Successful ',
	SigningReminderEmail: 'Signing Reminder Email - '
}

export enum DocumentEventValue {
	"None",
	"Created" = 1,
	"Document Assign To" = 2,
	"Document ERO Stamp Decline" = 3,
	"ERO Sign Decline" = 4,
	"Document Delivery Approved" = 5,
	"Emailed" = 6,
	"Document Change Status Manual" = 7,
	"Tax Return Accessed" = 8,
	"Document Authenticated" = 9,
	"Authentication Failed" = 10,
	"Access Locked" = 11,
	"OTP Authentication Failed" = 12,
	"OTP Access Locked" = 13,
	"Tax Return Reviewed" = 14,
	"Viewed" = 15,
	"Efile Forms Downloaded" = 16,
	"Efile Forms Uploaded" = 17,
	"Taxpayer Esigned" = 18,
	"Spouse Esigned" = 19,
	"Vouchers Reviewed" = 20,
	"Voucher Reminder Disabled" = 21,
	"Voucher Reminder Enabled" = 22,
	"Attachment Downloaded" = 23,
	"All Attachment Downloaded" = 24,
	"All K1 Document Downloaded" = 25,
	"Document Attachments Reviewed" = 26,
	"Payment System Accessed" = 27,
	"Invoice Reviewed" = 28,
	"Finished" = 29,
	"Access Link Emailed - " = 30,
	"Transmittal Reviewed" = 31,
	"Document TaxSaved" = 32,
	"K-1 Distributed To" = 33,
	"Document Download All" = 34,
	"Document Filing Instructions Downloaded" = 35,
	"Document Tax Return Downloaded" = 36,
	"Document Vouchers Downloaded" = 37,
	"Document Invoice Downloaded" = 38,
	"Document Signed Efile Downloaded" = 39,
	"KBA Passed" = 40,
	"KBA Failed" = 41,
	"Document KBA Success" = 42,
	"Document KBA Failed" = 43,
	"DOB Changed" = 44,
	"Document Opt Out ESign Option Selected" = 45,
	"Document Signed" = 46,
	"Signed Document Reviewed" = 47,
	"Voucher Reminder Updated" = 48,
	"Deleted" = 49,
	"Efile Forms Deleted" = 50,
	"Shareholder Consent Received" = 51,
	"Shareholder Consent Declined" = 52,
	"K-1 Document Downloaded" = 53,
	"K-1 Instruction Document Downloaded" = 54,
	"Document Mail Status" = 55,
	"Return Is Recalled" = 56,
	"Voucher Remainder Mail Status" = 57,
	"Document Request Recall Return" = 58,
	"Document Request Delete Return" = 59,
	"Shared" = 60,
	"Cancelled" = 61,
	"Filed" = 62,
	"Document Rejected" = 63,
	"Access Code Email" = 64,
	"Partnership Esigned" = 65,
	"Document Signed Efile Downloaded By CPA" = 66,
	"Reminder Sent For Signing" = 67,
	"Download Link Sent" = 68,
	"Payment Completed" = 69,
	"Not Defined" = 75,
	"Delivered To TaxCaddy" = 77,
	"Taxcaddy Delivery Failed" = 78
}

export const DownloadReturnsConstants = {
	downloadReturn: "Click on the links below to download"
}

export const InboxConstants = {
	InboxFetchError: "Unable to fetch notifications.",
	InboxMessageUpdateError: "Unable to update notification.",
	InboxMessageClearError: "Unable to clear notifications."
}

// SF Constants

export const SFDocument = {
	sfUploadFileWarning: 'Please upload .doc, .docx or .pdf files only.',
	sfUploadUnsupportedFileWarning: 'Failed to Upload : File format not supported.',
	deleteConfirmMessage: "Are you sure, you want to delete this document?",
	deleteDocument: "Document deleted successfully.",
	documentRequired: "Please upload document(s).",
	documentTypeRequired: "Please select a document(s) type.",
	emptyFileListMessage: "Files list will appear here.",
	documentExpireOnRequired: "Please select expire(s) on.",
	documentExpireOnLesser: "Document expiry date should be on or after ",
	fetchDocumentError: "Error while fetching document link.",
	uploadInProgress: "File(s) upload in progress",
	signatureControlRequired: "Document(s) must have one signature control placement.",
	BackgroundProcessInProgress: "Background process is in progress, Please wait...",
	outForSignature: "Document(s) successfully out for signature.",
	documentCoversionError: "Error while coverting the document.",
	senderSignatureControlRequired: "Document(s) must have one signature control placement for sender.",
	signerControlRequired: "Document(s) must have atleast one control placement for each recipient."
}

export const SFMessageConstants = {
	MessageRequiredWarning: "Message cannot be empty."
}

export const SigFlowCompanySettingsConstants = {
	DocumentType: {
		DocumentTypeAddSuccess: 'Document Type added successfully.',
		DocumentTypeAddError: 'Error occured while adding Document Type.',
		DocumentTypeUpdateSuccess: 'Document Type updated successfully.',
		DocumentTypeUpdateError: 'Error occured while updating Document Type.',
		DocumentTypeFetchError: 'Error occured while fetching Document Types.',
		DocumentTypeEditAlert: 'The change in the setting will not impact already delivered documents.'
	}
}

export const SignatureFlowConstants = {
	ContactsFetchError: 'Error occured while fetching Contacts.',
	SendForSignatureFailed: 'Unable to Send for Signature, Please try again',
	MailMergeFailed: 'Mail Merge failed, Please try again',
	OutForMailMerge: 'Document successfully out for Mail Merge.',
	MailMergeValidateBtnInfo: 'Click validate to verify your data',
	MailMergeInvalidCsvWarning: 'Columns mismatch, please refer template.',
	MailMergeInvalidEmailAddressText: 'Please enter valid Email Address',
	MailMergeRequiredEmailAddressText: 'This is a required field, Please enter valid Email Address.',
	MailMergeCustomFieldText: 'Value upto 80 characters can be entered.',
	SendingForSignatureOverlayMessage: 'Sending for Signature...',
	MailMergeMaxCharsExceededErrorMsg: 'Value of custom fields cannot exceed 80 characters',
	SignatureFieldNotPlacedWarningMsg: "Signature not placed for <Recipient Mail ID> do you want to proceed any way? ",
	SignatureFieldNotPlacedWarningMsgPlaceholder: "<Recipient Mail ID>",
	SignatureFlowSettingsEnableNote: "Note: You will need to re-login to see the changes take effect."
}

export const MailMergeDocument = {
	CsvUploadFileWarning: 'Please upload .csv file only.',
	FileInfoMessage: "File info will appear here.",
	MailMergeTemplateFileName: 'MailMergeTemplate.csv',
	DownloadSampleTemplate: 'Download Template',
	HeadingUploadCsv: 'Upload CSV',
	HeadingValidationResult: 'Validation Result',
	SendingForMailMergeOverlayMessage: 'Sending for Mail Merge...',
	DownloadExcelBtnText: 'Download'
}

export enum SignatureReportClientTrackingEvents {
	"None" = 0,
	"Created",
	"Document Mailed",
	"Document Viewed",
	"Document E-Signed",
	"Document Signed and Filed ",
	"Shared ",
	"SendForSignature",
	"Reminder Sent For Signing",
	"Document Downloaded By CPA",
	"All Documents Downloaded By CPA",
	"Cancelled Document",
	"Signed Document Downloaded By CPA",
	"All Signed Documents Downloaded By CPA",
	"Access Code Email",
	"Declined Email",
	"Download Email",
	"Signed Document Downloaded",
	"All Signed Documents Downloaded",
	"Access Link Emailed"
}

export const IPAddressConstant = {
	FromIPWarning: 'Please enter from IP address.',
	ToIPWarning: 'Please enter to IP address.',
	ValidFromIPWarning: 'Please enter a valid from IP address.',
	ValidToIPWarning: 'Please enter a valid to IP address.',
	InvalidIPRangeWarning: 'Invalid IP address range!',
	CloseEditingWarning: 'Close current editing.',
	DeleteIpAddress: 'Are you sure, you want to delete?',
	SaveSuccessfully: 'IP Address saved successfully',
	UpdateSuccessfully: 'IP Address updated successfully',
	DeleteSuccessfully: 'IP Address deleted successfully'
}

export const OTPVerify = {
	InvalidOTP: 'Invalid OTP',
	validOTP: 'OTP Verified successfully',
}

export const customAuthority = {
	customAuthorityId: 100001
}

export const watermarkConstants = {
	NameWarning: 'Please enter name',
	TextWarning: 'Please enter text',
	FontSizeWarning: 'Please enter font size',
	FontSizeValueWarning: 'Please enter the font size between 1 to 72',
	FontFamilyWarning: 'Please select font',
	OpacityWarning: 'Please select opacity',
	DeleteWatermark: 'Are you sure, you want to delete?',
	SaveSuccessfully: 'Watermark saved successfully',
	UpdateSuccessfully: 'Watermark updated successfully',
	DeleteSuccessfully: 'Watermark deleted successfully'
}

export const MyDownloadsConstants = {
	StatusMessage: 'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
	DeleteMyDownload: 'Selected File has been deleted',
	ClearAllMyDownloads: 'All Downloaded files has been deleted',
	DeleteSingleFileWarning: 'File cannot be deleted, since Download is in progress.',
	DialogBox: {
		ConfirmDeletionSingle: 'Are you sure you want to delete this file?',
		ConfirmDeletionAll: 'Are you sure you want to clear all files?',
		Confirm: 'Yes',
		Cancel: 'Cancel',
		Title: 'Confirm Deletion'
	}
}
export const EngagementLetterControlDeleteWarning = {
	DialogBox: {
		MessageForTemplate: "There are signature controls placed on the page you are trying to delete. Continuing with this action will remove the page and signature controls applied from all organizers in this batch. Do you want to continue?",
		Message: "There are signature controls placed on the page you are trying to delete. Do you want to continue?",
		Confirm: 'Continue',
		Cancel: 'Cancel',
		Title: 'Page contains signature controls!'
	}
}

export const SectionDeleteWarning = {
	DialogBox: {
		Message: "There are questions added to the section you are trying to delete. Continuing with this action will remove the section and question(s) under the section. Do you want to continue?",
		Confirm: 'Continue',
		Cancel: 'Cancel',
		Title: 'Section contains question(s)!'
	},
	QuestionDelete: {
		Message: "If you delete the last question within the section, the section will be removed. Do you wish to continue?",
		Confirm: 'Continue',
		Cancel: 'Cancel',
		Title: 'Section will be deleted!'
	}
}

export const GeneralSettingsConstants = {
	NoTaxSoftwareMessage: "Please select atleast one Tax Software."
}

export const FileExtensions = {
	Pdf: '.pdf'
}

export const SupportedFilePreviewTypes = ['.pdf', '.xlsx', '.jpeg', '.png', '.docx', '.jpg']

export enum SourceDocumentNotification {
	NotifyEveryTimeUploadOrDeleteSrcDoc = 1,
	SchNotification1PerDayUploadOrDeleteSrcDoc = 2,
	NoAdditionalCPANotificationRequired = 3
}

export const UndeliveredBatchOrganizerContants = {
	DeleteModalHeader: "Confirm Batch Organizer Delete",
	DeleteModalBody: "You are about to delete the selected batch. This cannot be undone. Do you want to proceed?"
}

export const SignatureControlConstants = {
	ControlIdPrefix: "control_"
}

export const SignaturePanelConstants = {
	Signature: {
		header: "Signature Settings",
		title: "To make this signature mandatory, please check box below.",
		tooltipContent: ""
	},
	Default: {
		header: "Tool Tip",
		title: "Add Text",
		tooltipContent: "This text will help the signer to enter the necessary details"
	},
	CheckBoxControl: {
		header: "Checkbox properties",
		title: "How many checkbox(es) would you like to add?",
		tooltipContent: "Mention the count of checkbox(es) to be added. All checkboxes will be selectable by Taxpayer."
	},
	RadioButtonControl: {
		header: "Radio button properties",
		title: "How many radio button(s) would you like to add?",
		tooltipContent: "Mention the count of radio button(s) to be added. For one set Taxpayer can select only one option."
	}
}

export const TemplateModalConstants = {
	//Templates list
	RecentQuestionnaires: "Recent Questionnaires",
	StartFromScratch: "Start from scratch",
	BuildNewQuestionnaire: "Build a new questionnaire",
	WithYourCustomQuestions: "with your custom questions.",
	CreateNew: "Create New",
	// Edit Template
	UntitledQuestionnaire: "Title here",
	QuestionnaireDescription: "Brief description here",
	AddNewSection: "Add New Section",
	//Add Template Modal
	AddTemplateModalHeader: "Create New Template",
	EditTemplateModalHeader: "Rename Template",
	TemplateNamePlaceholder: "Enter template name",
	TemplateNameValidationMessage: "Please enter template name",
	TemplateDesciptionPlaceholder: "Enter template description",
	DeleteDefaultTemplateConfirmation: "This template is set to default , do you still want to delete this template ?",
	DeleteTitle: "Permanent Delete",
	DeleteConfirmation: "Are you sure want to delete the template ?",
	DeleteSuccessMessage: "Template deleted successfully",//TO confirm
	CustomTemplate: "Custom Template",
	DeleteTemplateInUse: "Template In-use",
	DeleteConfirmationForTemplateInUse: "You are trying to delete a questionnaire template that is being used for an organizer in process. Please confirm if you wish to permanently delete the selected questionnaire template.",
	DeleteConfirmationNote: "Uploaded organizer(s) will retain this saved questionnaire until updated manually.",
	// Add Questionaire Modal

	AddModalHeader: "Create New Questionnaire",
	EditModalHeader: "Rename Questionnaire",
	AddModalConfirmButton: "Continue",
	EditModalConfirmButton: "Save",
	NameLabel: "Name",
	NamePlaceholder: "Enter questionnaire name",
	NameValidationMessage: "Please enter questionnaire name",
	DescriptionValidationMessage: "Please enter questionnaire description",
	TemplateNameMaxLength: 100,
	TemplateDescriptionMaxLength: 100,
	NameMaxLength: 150,
	DesciptionLabel: "Description",
	DesciptionPlaceholder: "Enter questionnaire description",
	DesciptionMaxLength: 500,
	EditSuccessMessage: "Your changes are saved successfully",//TO confirm
	AddSuccessMessage: "Template added successfully",//TO confirm
	SetDefaultTemplateSuccess: "Default template set successfully",
	UnsetDefaultTemplateSuccess: "Default template selection removed successfully",

	//Add Section Modal
	AddSectionModalHeader: "Create New Section",
	EditSectionModalHeader: "Rename Section",
	MergeSectionModalHeader: "Merge Section",
	ReorderSections: "Reorder Sections",
	AddSectionModalConfirmButton: "Continue",
	EditSectionModalConfirmButton: "Save",
	SectionMerge: "Merge",
	SectionNameLabel: "Name",
	SectionNamePlaceholder: "Enter section name",
	SectionNameValidationMessage: "Please enter section name",
	SectionNameMaxLength: 100,

}

export const FormPreviewConstants = {
	RequiredValidation: "This field is required",
	ParagraphTypeQuestion: {
		placeholder: "Answer goes here",
		maxLength: 3250
	},
	MultiCoiceTypeQuestion:
	{
		optionAlreadyExists: "Option already exists"
	}
}

export const CustomQuestionsTabConstants = {
	ChooseTemplate: "Choose Template",
	SelectTemplate: "Select Template",
	NoTemplate: "No Template Selected",
	NoQuestionnaiers: "No questionnaire yet?",
	GetStarted: "Let’s get started!",
	DeleteQuestionTitle: "Delete question and section",
	DeleteSectionTitle: "Section contains questions",
	DeleteQuestionConfrimation: "If you delete the last question within the section, the section will be removed. Do you wish to continue?",
	DeleteSectionConfirmation: "There are questions added to the section you are trying to delete. Continuing with this action will remove the section and question(s) under the section. Do you want to continue?",
	AtleastOneSectionRequired: "Template requires at least one section",
	AtleastOneQuestionRequired: "Section requires at least one question",
};

export const FormBuilderConstants = {
	QuestionTitle: {
		placeholder: "Enter Question here",
		maxLength: 2000,
		answerPlaceholder: "Answer goes here",
	},
	SAVE_DISABLED_TOOLTIP: "No content to save",
	PREVIEW_DISABLED_TOOLTIP: "No content to preview",
	DELETE_QUESTION_DISABLED_TOOLTIP: "Section requires at least one question.",
	DUPLICATE_SECTION_LIMIT: "Unable to duplicate. Character limit exceeded for selected questionnaire.",
	DUPLICATE_QUESTION_LIMIT: "Unable to duplicate. Character limit exceeded for selected question.",
	FORM_VALIDATION_ERROR: "Please check the error(s)",
	DELETE_MODAL_HEADER: "Confirm delete section",
	DELETE_MODAL_BODY: "Deleting the section will remove all the questions created under this section. Do you wish to continue?",
	MultiChoiceConstants: {
		placeholder: "Enter Option Here",
		maxLength: 300,
		optionsMaxLimit: 5,
		atLeastOneOptionText: "Question requires at least one option"
	}
}

export const OrganizerClientIdConstants = {
	ClientIdMaxLength: 150,
	ClientIdLengthForEllipsis: 47
}

export const UserOfficeLocationConstant = {
	OfficeLocationError: 'Error occured while fetching user office locations'
}

export const LocationMismatchModal = {
	header: {
		title: 'Attention'
	},
	body: {
		Content: {
			Line1: 'By changing Office Location to',
			Line2: ` {0} `,
			Line3: 'you will no longer have access to it as you are not assigned to that office.',
			Line4: 'Press Continue to change office location or Cancel to keep the current office location.'
		}
	},
	footer: {
		Cancel: 'Cancel',
		Continue: 'Continue'
	}
}