import * as React from 'react';
import { RouteComponentProps } from 'react-router';

interface IRadioButtonComponentProps {
    checked?: boolean;
    onChange?: (event: any) => void;
    dataTestAuto?: string;
    value?: string | string[] | number;
    disabled?: boolean;
    text: string;
    name?: string;
    onClick?: (event: any) => void;
    id: string;
    style?: React.CSSProperties;
    className?: string;
}

export const RadioButtonComponent = (props:IRadioButtonComponentProps) => {
    
        return (
            <div className={'display-checkBox ' + props.className || ""} style={props.style}>
                <input
                    className="magic-radio"
                    type="radio"
                    id={props.id}
                    checked={props.checked}
                    onChange={props.onChange}
                    value={props.value}
                    disabled={props.disabled}
                    name={props.name}
                    data-test-auto={props.dataTestAuto}
                    onClick={props.onClick}
                ></input>
                <label htmlFor={props.id} className={props.checked ? "checked" : ""}>
                    {props.text}
                </label>
            </div>
        )
    
}