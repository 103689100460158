import React from 'react'
import { ISessionLocalStore } from '../Core/Utilities/SessionStore';
import { signoutRedirect } from '../oidcClient/userService';
import { container } from '../Startup/inversify.config';
import { TYPES } from '../Startup/types';
import {  clearAllTemporaryFilter } from './reports/Filters';


const SessionStore = container.get<ISessionLocalStore>(TYPES.ISessionStore);
export const RestrictedAccess: React.FC = () => {

    const onLogin = () => {
        clearAllTemporaryFilter();
        SessionStore.remove("isVisited");
        signoutRedirect();
    }  
    return (
        <div style={{ marginLeft: '10px' }}>
            <div className="marB15">
                <h1 className="fontfamily-inital" style={{ fontSize: '2em', fontWeight: 'bolder' }}>
                    Restricted access
                </h1>
            </div>

            <div className="marB15">
                <h4 className="fontfamily-inital" style={{ fontSize: '1em', fontWeight: 'bolder' }}>
                    This Client's IP address is not allowed to access the server.
                </h4>
            </div>

            <div>
                <a
                    className="fontfamily-inital"
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    onClick={onLogin}
                >
                    Click here to login.
                </a>
            </div>
        </div>
    )
}
