import { Reducer, Action } from "redux";
import { DispatchActions, actionTypes } from "./ActionTypes";
import { IQuestionnaireState, intialQuestionnaireState } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { produce } from "immer";

export const reducer: Reducer<IQuestionnaireState> = produce(
    (draft: IQuestionnaireState = intialQuestionnaireState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.HANDLE_PARAGRAPH_ON_CHANGE: {
                const { sectionIndex, sectionItemIndex, value } = action.payload;
                if (draft.formData.sections[sectionIndex]) {
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionDetails.answer = value;
                }
                return draft;
            }
            case actionTypes.INTIALISE_CQ_PREVIEW_FORM:
                draft.formData = action.payload;
                return draft;

            case actionTypes.HANDLE_SUB_QUESTION_MCQ_ON_CHANGE: {
                const { choiceIndex, sectionIndex, sectionItemIndex, subQuestionChoiceIndex } = action.payload;
                const sectionItem = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex];
                const selectedSubQuestionChoice =
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[0].subQuestions[
                        subQuestionChoiceIndex
                    ].choices;
                if (selectedSubQuestionChoice.length > 0) {
                    selectedSubQuestionChoice.forEach((choice, currentIndex) => {
                        if (choiceIndex === currentIndex) {
                            choice.isSelected = !choice.isSelected;
                        } else {
                            choice.isSelected = false;
                        }
                    });

                    if (sectionItem.isRequired) {
                        let isAnySelected = false;
                        isAnySelected = selectedSubQuestionChoice.some((choice) => choice.isSelected === true);
                        sectionItem.isValid = isAnySelected;
                    }
                }

                return draft;
            }

            default:
                return draft;
        }
    }
);
