import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Loader, LoadingOverlay } from 'react-overlay-loader';

interface BatchDownloadModalProps {
    show: boolean;
    onClose: () => void;
    downloadUndeliveredCSV: () => void;
    loading: boolean;
}

const BatchDownloadModal: React.StatelessComponent<BatchDownloadModalProps> = ({ show, onClose, loading, downloadUndeliveredCSV }) => {
    return <Modal className="download-organizer-modal" show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                <FaFileDownload className='modalIcon' />
                Downloads
                </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <LoadingOverlay style={{ height: '100%' }}>
                <ul style={{ padding: 10 }}>
                    <Link
                        to={'#'}
                        onClick={downloadUndeliveredCSV}
                        className={`delivered-return-download-link`}
                    >
                        Undelivered Organizers
                        </Link>
                </ul>
                <Loader loading={loading} text={"Loading..."} />
            </LoadingOverlay>
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onClose} >
                <i className="glyphicon glyphicon-remove" />Cancel
                    </Button>
        </Modal.Footer>
    </Modal>
}


export { BatchDownloadModal }

