import store from "../../configureStore";
import { validateError } from "../../components/helper/HelperFunctions";
import { ProductType } from "src/components/LayoutWrapper";
import { LoggerFactory } from "../../Logger/LoggerFactory";
import { SSSUITE_API_BASE_URL } from "../../utils/constants";
import { setCacheResetApiState } from "./actions";

const logger = new LoggerFactory().getTelemetryLogger();
logger?.trackTrace("Initialize");

export const resetUserCache = async () => {
    await fetch(SSSUITE_API_BASE_URL + `/api/user-management/reset-user-status-cache/${ProductType.Organizers}`, {
        credentials: 'include',
        method: 'GET',
    }).then((handleResponse) => {
        store.dispatch(setCacheResetApiState());
    })
        .catch(error => {
            logger?.trackTrace(`SSSuite reset-user-status-cache API failed with error ${validateError(error)}`);
            store.dispatch(setCacheResetApiState());
        });
}
