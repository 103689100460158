import * as React from 'react';
import { Button, Modal, Tabs, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { Loader } from 'react-overlay-loader';
import { ReminderSettings } from './../../../models/OrganizerComponentModels'
import { ApplicationState } from '../../../../store/index';
import { CheckBoxComponent, CheckBoxSize } from '../../../../components/common/CheckBoxComponent';
import { getDaysOptions } from '../../../../components/common/utils';
import { UserManagementConstants } from '../../../../components/helper/Constants';
import { ActionCreators } from '../../../store/OrganizerCompanySettingsStore'
import { actionTypes } from '../../../../store/ActionTypes';


interface ReminderOptionProps {
    show: boolean,
    onCancel: () => void;
    hideLoader: boolean;
    onReminderSend(selectedValue: any): void;
    OnChangeSourceReminder: (sourceReminder: boolean) => void;
    OnChangeEngagementReminder: (engagementReminder: boolean) => void;
    OnChangeOrganizerReminder: (organizerReminder: boolean) => void;
    isSourceDocumentReminder: boolean;
    isOrganizerReminder: boolean;
    isEngagementReminder: boolean;
    disableEngagementReminder: boolean;
    disableOrganizerReminder: boolean;
    disableSourceReminder: boolean;
    selectedRows: any;
    documentList?: any;
    isBatchOrganizer: boolean;
    selectedRowCount?: number;
    scheduleReminderSettings?: any,
    loading?: boolean,
    onUpdateScheduleReminder: (reminderOptions: any) => void;
    requestOrganizerReminderSettings?: (docID: any) => void;
}

export enum ReminderTabs {
    SendReminder,
    ScheduleReminder,
}

const disableEsignCodes = [23, 24, 25, 26, 28]; //“E-signed”, “Downloaded”, “NA”, “Manually Signed”,"Review"

const disableOrgCodes = [3, 4, 6]; //“Completed”, “Downloaded”, “Manually Completed”

let reminderOptionsCache: any = undefined;

const ReminderOption: React.FC<ReminderOptionProps> = (props) => {
    const dispatch = useDispatch();

    const scheduleReminderSettings = useSelector((state: ApplicationState) => state.organizerCompanySettings?.scheduleReminderSettings);
    const loading = useSelector((state: ApplicationState) => state.organizerCompanySettings.loading);
    const isMultiSelected = () => props?.selectedRows?.length > 1;

    const [activeKey, setActiveKey] = React.useState(ReminderTabs.SendReminder);
    const [reminderOptions, setReminderOptions] = React.useState({} as ReminderSettings);
    const [isSaveDisabled, setIsSaveDisabled] = React.useState(true);
    const [isESignDisableOnLoading, setIsESignDisableOnLoading] = React.useState(isMultiSelected());
    const [isOrgDisableOnLoading, setIsOrgDisableOnLoading] = React.useState(isMultiSelected());

    React.useEffect(() => {
        const { selectedRows, documentList, isBatchOrganizer } = props;
        if (!isBatchOrganizer && selectedRows.length === 1) {
            const docID = documentList?.[selectedRows[0]]?.id;
            dispatch(ActionCreators.requestOrganizerReminderSettings(docID));
        }
        if (selectedRows.length > 1) {
            setIsSaveDisabled(false)
        }
        return () => {
            dispatch(ActionCreators.reset(actionTypes.RECEIVE_SCHEDULE_ORGANIZER_REMINDER_SETTINGS))
        }
    }, []);

    React.useEffect(() => {
        if (scheduleReminderSettings) {
            setReminderOptions(scheduleReminderSettings);
            reminderOptionsCache = JSON.parse(JSON.stringify(scheduleReminderSettings));
        } else {
            const defaultOpts: ReminderSettings = {
                esignReminder: {
                    reminderDays: 7,
                    enabled: false,
                    isReadOnly: false
                },
                organizerReminder: {
                    reminderDays: 7,
                    enabled: false,
                    isReadOnly: false
                }
            };
            setReminderOptions(defaultOpts);
            reminderOptionsCache = undefined;
        }
    }, [scheduleReminderSettings]);

    const isAutoReminderSelected = (rs: any) => (!!rs?.esignReminder?.enabled || rs?.organizerReminder?.enabled);

    const handleTabSelect = (index: any) => setActiveKey(parseInt(index.toString()));

    const updateReminders = (key1: string, key2: string, value: number) => {
        reminderOptions[key1][key2] = value;
        setReminderOptions({ ...reminderOptions });
        setIsSaveDisabled(JSON.stringify(reminderOptionsCache) === JSON.stringify(reminderOptions));
        key1 === "esignReminder" ? setIsESignDisableOnLoading(false) : setIsOrgDisableOnLoading(false);
    }

    const getTabTitle = (title: string, icon: string) => (
        <div className="overflowText">
            <i className={`fas ${icon}`} style={{ paddingRight: '12px' }}></i>
            {title}
        </div >
    )

    const getMultiSelectHelpText = () => {
        return (
            <div style={{ fontSize: '12px' }}>
                <div className='please-note-msg'>{UserManagementConstants.PleaseNoteText} </div>
                {UserManagementConstants.AutoReminderSignWarning}{UserManagementConstants.SigningRelatedStatusNames}<br />
                {UserManagementConstants.AutoReminderOrganizerWarning}{UserManagementConstants.OrganizerRelatedStatusNames}<br />
                {activeKey === ReminderTabs.SendReminder &&
                    <div>
                        {UserManagementConstants.AutoReminderSourceWarning}
                        <i className="fa fa-question-circle" style={{ color: '#DAA520', cursor: 'pointer' }} title={UserManagementConstants.AutoReminderIconStatus}></i>
                    </div>
                }
            </div>
        );
    }

    const getBanner = () => {
        const { selectedRowCount } = props;
        if (selectedRowCount && selectedRowCount > 1) {
            return (<div className='reminders-option-banner'>
                <i className=" fas fa-info-circle  "></i>
                {`${selectedRowCount} Organizers selected.`}
            </div>);
        }
        return null;
    }

    const getDisplayIfo = () => {
        const isMultiple = isMultiSelected();
        if (!isMultiple && isAutoReminderSelected(reminderOptions)) {
            return (
                <div className='please-note-msg'>
                    For Selected Organizer, Automated Reminder enabled!
                </div>
            );
        }
        if (isMultiple) {
            return getMultiSelectHelpText();
        }
    }

    const onClickSave = () => {
        setIsSaveDisabled(true);
        props.onUpdateScheduleReminder(reminderOptions)
    }

    const getSendReminderNowBody = () => (
        <div style={{ position: 'relative', padding: '15px', background: "#fff" }}>
            {getBanner()}
            <div className="padR30" style={{ marginLeft: '15px' }}>
                <CheckBoxComponent
                    size={CheckBoxSize.lg}
                    id={"Engagement-Reminder"}
                    checked={props.isEngagementReminder}
                    onChange={(e: any) => props.OnChangeEngagementReminder(e.target.checked)}
                    text={"Engagement letter reminder"}
                    wrapperStyle={{ display: 'inline' }}
                    disabled={props.disableEngagementReminder}
                />
            </div>
            <div className="padR30" style={{ marginLeft: '15px' }}>
                <CheckBoxComponent
                    size={CheckBoxSize.lg}
                    id={"Organizer-reminder"}
                    checked={props.isOrganizerReminder}
                    onChange={(e: any) => props.OnChangeOrganizerReminder(e.target.checked)}
                    text={"Organizer reminder"}
                    wrapperStyle={{ display: 'inline' }}
                    disabled={props.disableOrganizerReminder}
                />
            </div>
            <div className="padR30" style={{ marginLeft: '15px' }}>
                <CheckBoxComponent
                    size={CheckBoxSize.lg}
                    id={"Source-reminder"}
                    checked={props.isSourceDocumentReminder}
                    onChange={(e: any) => props.OnChangeSourceReminder(e.target.checked)}
                    text={"Source document reminder"}
                    wrapperStyle={{ display: 'inline' }}
                    disabled={props.disableSourceReminder}
                />
            </div>
            {!props?.isBatchOrganizer && getDisplayIfo()}
        </div >
    );

    const isEsignOptionDisabled = () => {
        const { selectedRows, documentList } = props;

        if (selectedRows.length === 1) {
            const engStatus = documentList?.[selectedRows[0]]?.engagementLetterStatus;
            return engStatus === 26 ? props.disableEngagementReminder : disableEsignCodes.includes(engStatus);
        }
    }

    const isOrgOptionDisabled = () => {
        const { selectedRows, documentList } = props;

        if (selectedRows.length === 1) {
            const orgStatus = documentList?.[selectedRows[0]]?.organizerStatus;
            return disableOrgCodes.includes(orgStatus);
        }
    }

    const getScheduleReminderBody = () => {
        const daysOpions = getDaysOptions();
        const isEsignDisabled = !!isEsignOptionDisabled();
        const isEsignOn = isEsignDisabled || isESignDisableOnLoading ? false : reminderOptions?.esignReminder?.enabled;
        const isOrgDisabled = !!isOrgOptionDisabled();
        const isOrgOn = isOrgDisabled || isOrgDisableOnLoading ? false : reminderOptions?.organizerReminder?.enabled;

        return (
            <div className='sr-popup-body'>
                {getBanner()}
                <div className="sign-orgs-reminder-panel">
                    <div>
                        <Row style={{ fontWeight: 700 }}>
                            Signing
                        </Row>
                        <Row className="sign-orgs-reminder-panel-row">
                            <div>
                                Send a reminder every
                            </div>
                            <Select className='select-dropdown-small'
                                id="esignRemindDaysPopup"
                                options={daysOpions}
                                value={reminderOptions?.esignReminder?.reminderDays}
                                onChange={(e: any) => updateReminders("esignReminder", "reminderDays", e.value)}
                                clearable={false}
                                searchable={false}
                                disabled={!isEsignOn}
                            />
                            <div>
                                since last reminder / initial mail
                            </div>
                            <div >
                                <Col xs lg="2" style={{ paddingBottom: '10px' }} title={isEsignDisabled && UserManagementConstants.DeliveredReporESignWarning} >
                                    <label className="toggleSwitch">
                                        <input type="checkbox"
                                            checked={isEsignOn}
                                            disabled={isEsignDisabled}
                                            onChange={(e: any) => updateReminders("esignReminder", "enabled", e.target.checked)}

                                        ></input>
                                        <span className="columnFilterSlider round"></span>
                                    </label>
                                </Col>
                            </div>
                        </Row>
                    </div>
                    <div>
                        <Row style={{ fontWeight: 700 }}>
                            Organizers
                        </Row>
                        <Row className="sign-orgs-reminder-panel-row">
                            <div>
                                Send a reminder every
                            </div>
                            <Select className='select-dropdown-small'
                                id="orgRemindDays"
                                options={daysOpions}
                                value={reminderOptions?.organizerReminder?.reminderDays}
                                onChange={(e: any) => updateReminders("organizerReminder", "reminderDays", e.value)}
                                clearable={false}
                                searchable={false}
                                disabled={!isOrgOn} />
                            <div>
                                since last reminder / initial mail
                            </div>
                            <div>
                                <Col xs lg="2" style={{ paddingBottom: '10px' }} title={isOrgDisabled && UserManagementConstants.DeliveredReportOrganizerWarning}>
                                    <label className="toggleSwitch ">
                                        <input type="checkbox"
                                            checked={isOrgOn}
                                            disabled={isOrgDisabled}
                                            data-toggle={isOrgDisabled && UserManagementConstants.DeliveredReportOrganizerWarning}
                                            onChange={(e: any) => updateReminders("organizerReminder", "enabled", e.target.checked)}
                                        ></input>
                                        <span className="columnFilterSlider round"></span>
                                    </label>
                                </Col>
                            </div>
                        </Row>
                    </div>
                </div>
                {isMultiSelected() && getMultiSelectHelpText()}
            </div>)
    }
    const modalClass = props?.isBatchOrganizer ? 'Reminder-Option' : 'reminder-multi-tab';

    return (
        <Modal className={modalClass} show={props.show} onHide={props.onCancel}>
            <Modal.Header closeButton data-test-auto="E9FF957A-1DE3-4FBA-8DD9-987ADBB404BA">
                <Modal.Title>
                    Send Reminder
                </Modal.Title>
            </Modal.Header>
            {loading &&
                <div style={{ height: '200px' }}>
                    <Loader loading={loading} text={"Loading..."} />
                </div>}
            {!loading &&
                <Modal.Body className='send-reminder-popup-modal-body'>
                    {!props?.isBatchOrganizer &&
                        <Tabs
                            id="send-schedule-reminders"
                            className='send-reminder-popup-tabs'
                            activeKey={activeKey}
                            onSelect={handleTabSelect}
                            fill>
                            <Tabs eventKey={ReminderTabs.SendReminder}
                                title={getTabTitle("Send Reminder Now", "fa-paper-plane")}
                                className="zIndex10">
                                {getSendReminderNowBody()}
                            </Tabs>
                            <Tabs
                                eventKey={ReminderTabs.ScheduleReminder}
                                title={getTabTitle("Schedule Automated Reminder", "fas fa-calendar-check")}
                                className="zIndex10" style={{ overflow: 'overlay' }}>
                                {getScheduleReminderBody()}
                            </Tabs>
                        </Tabs>
                    }
                    {props?.isBatchOrganizer && getSendReminderNowBody()}
                </Modal.Body>
            }
            <Modal.Footer>
                <Button
                    variant="default"
                    data-test-auto='28A5846C-E7CE-420B-9E9F-D1BE18DE1C90'
                    onClick={props.onCancel}
                    className="btn-white" >
                    <i className='glyphicon glyphicon-remove'></i>Cancel
                </Button>
                {activeKey === ReminderTabs.SendReminder &&
                    <Button
                        disabled={!(props.isOrganizerReminder || props.isEngagementReminder || props.isSourceDocumentReminder)}
                        data-test-auto='056DD1B3-F56D-439C-B5E0-8998A4BDDAC7'
                        onClick={props.onReminderSend}
                        variant="info">
                        <i className='fa fa-paper-plane text-secondary'></i>Send
                    </Button>
                }
                {activeKey === ReminderTabs.ScheduleReminder &&
                    <Button
                        disabled={isSaveDisabled}
                        data-test-auto='056DD1B3-F56D-439C-B5E0-8998A4BDDAC7'
                        onClick={onClickSave}
                        variant="info">
                        <i className='fa fa-paper-plane text-secondary'></i>Save
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ReminderOption;
