import * as React from 'react';
import { Route } from 'react-router-dom';
import OrganizerSettings from './Organizer/components/Settings/OrganizerSettingsContainer';
import General from './Organizer/components/Settings/General/General';
import CustomQuestionsTemplateList from './Organizer/components/Settings/CustomQuestions/CustomQuestionsTemplateList';
import OrganizerReportSelector from './Organizer/components/Reports/OrganizerReportSelector';
import ArchivedOrganizer from './Organizer/components/Reports/ArchivedOrganizerContainer';
import UndeliveredBatchOrganizer from './Organizer/components/Reports/UndeliverdBatchOrganizerContainer';
import DeliveredOrganizer from './Organizer/components/Reports/DeliveredOrganizerContainer';
import BatchExcelDownload from './Organizer/components/Reports/Common/BatchExcelDownload';
import SavedMessages from './Organizer/components/Settings/SavedMessage/SavedMessagesContainer';
import RecycleOrganizerContainer from './Organizer/components/Reports/RecycleOrganizerContainer';
import RBACRoute from './components/RBACRoute';
import { ApplicationState } from './store';
import { useSelector } from 'react-redux';
import LoadingScreen from './components/common/LoadingScreen';
import CommonFeatureDisabled from './components/Authorization/CommonFeatureDisabled';

const OrganizerRoutes: React.FC = () => {

    const routes = useSelector((state: ApplicationState) => state.productMenuControl.routeList);
    const isLoading = useSelector((state: ApplicationState) => state.productMenuControl.isLoading);

    return isLoading ? <LoadingScreen /> : <>
        {
            <Route exact path='/FeatureDisabled' component={CommonFeatureDisabled} />
        }
        {
            <RBACRoute path='/SavedMessages' routes={routes} component={SavedMessages} />
        }
        {
            <RBACRoute path='/OrganizerSettings' routes={routes} component={OrganizerSettings} />
        }
        {
            <RBACRoute path='/General' routes={routes} component={General} />
        }
        {
            <RBACRoute path='/DeliveredOrganizerReport' routes={routes} component={DeliveredOrganizer} />
        }
        {
            <RBACRoute path='/ArchivedOrganizerReport' routes={routes} component={ArchivedOrganizer} />
        }
        {
            <RBACRoute path='/UndeliveredBatchOrganizerReport' routes={routes} component={UndeliveredBatchOrganizer} />
        }
        {
            <RBACRoute path='/OrganizerReport' routes={routes} component={OrganizerReportSelector} />
        }
        {/* {
            isCompanyOrganizerProductEnabled && isCompanyOrganizerSubscriptionEnabled ?
            <RBACRoute path='/OrganizerTemplates' component={OrganizerTemplate} />
            : <Route exact path='/OrganizerTemplates' component={FeatureDisabled} />
            } */
        }
        {
            <RBACRoute path='/CustomQuestions' routes={routes} component={CustomQuestionsTemplateList} />
        }
        {
            <RBACRoute path='/BatchExportExcelDownload/:id' routes={routes} component={BatchExcelDownload} skipAuthorization={true} />
        }
        {
            <RBACRoute path='/' routes={routes} checkPath='/OrganizerReport' component={OrganizerReportSelector} />
        }
        {
            <RBACRoute path='/RecycledOrganizer' routes={routes} component={RecycleOrganizerContainer} />
        }
    </>
}

export default OrganizerRoutes;