import { Reducer, Action } from "redux";
import { DispatchActions, actionTypes } from "./ActionTypes";
import {
    IQuestionnaireState,
    ISections,
    QuestionType,
    initialYesNoChoiceData,
    initialMCQOptions,
    intialFormData,
    intialQuestionnaireState,
    intialSectionItemData,
    initialChoiceData,
    GetAdditionalQuestion
} from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { produce } from "immer";
import {
    generateRandomNumber,
    generateRandomString,
    insertRandomIds,
    updateSectionAndQuestionnaireOrder,
    removeUnwantedWhitespacesFromText
} from "src/components/helper/HelperFunctions";

export const reducer: Reducer<IQuestionnaireState> = produce(
    (draft: IQuestionnaireState = intialQuestionnaireState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case actionTypes.ADD_TEMPLATE_MODAL_DATA:
                draft.formData.template = action.payload;
                return draft;
            case actionTypes.ADD_SECTION_MODAL_DATA:
                const order: number = draft.formData.sections.length;
                draft.formData.sections.push({
                    id: generateRandomNumber(),
                    sectionId: generateRandomNumber(),
                    order: order + 1,
                    sectionName: action.payload,
                    sectionItems: [intialSectionItemData]
                });
                return draft;
            case actionTypes.RENAME_SECTION_MODAL_DATA:
                draft.formData.sections[action.sectionIndex].sectionName = action.sectionName;
                return draft;
            case actionTypes.CLEAR_FORM_DATA:
                draft.formData = intialFormData;
                return draft;
            case actionTypes.DELTE_SECTION:
                let sections = draft.formData.sections;
                sections.splice(action.sectionIndex, 1);
                sections = sections.map((section: ISections, index: number) => {
                    return { ...section, order: index + 1 };
                });
                draft.formData.sections = sections;
                return draft;
            case actionTypes.START_FORM_LOADER:
                draft.isLoading = true;
                return draft;
            case actionTypes.STOP_FORM_LOADER:
                draft.isLoading = false;
                return draft;
            case actionTypes.GET_FORM_DATA:
                draft.formData = insertRandomIds(action.payload);
                return draft;
            case actionTypes.HANDLE_QUESTION_TITLE_ON_CHANGE:
                {
                    const { sectionIndex, sectionItemIndex, value } = action.payload;
                    if (value !== "") {
                        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isValid = true;
                    } else {
                        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isValid = false;
                    }
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionTitle = value;
                }
                return draft;
            case actionTypes.ADD_NEW_QUESTION:
                {
                    draft.formData.sections[action.payload].sectionItems.push({
                        ...intialSectionItemData,
                        id: generateRandomString()
                    });
                }
                return draft;
            case actionTypes.HANDLE_MERGE_SECTION:
                {
                    draft.formData = updateSectionAndQuestionnaireOrder(action.payload);
                }
                return draft;
            case actionTypes.HANDLE_DUPLICATE_SECTION:
                {
                    const { sectionIndex, section } = action.payload;
                    let sections = draft.formData.sections;
                    sections.splice(sectionIndex + 1, 0, { ...section, id: generateRandomNumber() });
                    sections = sections.map((section, index) => {
                        return { ...section, order: index + 1 };
                    });
                    draft.formData.sections = sections;
                }
                return draft;

            case actionTypes.HANDLE_DUPLICATE_QUESTION:
                {
                    const { sectionIndex, sectionItemIndex, sectionItem } = action.payload;
                    draft.formData.sections[sectionIndex].sectionItems.splice(sectionItemIndex + 1, 0, {
                        ...sectionItem,
                        id: generateRandomString()
                    });
                }
                return draft;
            case actionTypes.HANDLE_DELETE_QUESTION:
                {
                    const { sectionIndex, sectionItemIndex } = action.payload;
                    draft.formData.sections[sectionIndex].sectionItems.splice(sectionItemIndex, 1);
                }
                return draft;
            case actionTypes.HANDLE_CHANGE_REQUIRED_QUESTION:
                {
                    const { sectionIndex, sectionItemIndex } = action.payload;
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isRequired =
                        !draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isRequired;
                    if(draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionTypeId==QuestionType.YESNO){
                        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices.map(choice=>{
                            choice.subQuestions.map(subQuestion=>{
                                subQuestion.isRequired = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].isRequired;
                            })
                        })
                    }
                }
                return draft;
            case actionTypes.HANDLE_TITLE_ON_CHANGE:
                {
                    const { value } = action.payload;
                    if (value !== "") {
                        draft.formData.isTitleValid = true;
                    } else {
                        draft.formData.isTitleValid = false;
                    }
                    draft.formData.title = value;
                }
                return draft;
            case actionTypes.HANDLE_DESCRIPTION_ON_CHANGE:
                {
                    const { value } = action.payload;
                    if (value !== "") {
                        draft.formData.isDescriptionValid = true;
                    } else {
                        draft.formData.isDescriptionValid = false;
                    }
                    draft.formData.description = value;
                }
                return draft;
            case actionTypes.CHECK_IS_FORM_VALID:
                {
                    draft.formData.sections.forEach((section) => {
                        section.sectionItems.forEach((sectionItem) => {
                            if (sectionItem.questionTitle === "") {
                                sectionItem.isValid = false;
                            }
                        });
                    });
                    if (draft.formData.title === "") {
                        draft.formData.isTitleValid = false;
                    }
                    if (draft.formData.description === "") {
                        draft.formData.isDescriptionValid = false;
                    }
                    draft.formData.sections.forEach((section) => {
                        section.sectionItems.forEach((sectionItem) => {
                            let choiceTexts : string[] = []; 
                            sectionItem.choices.forEach((choice) => {
                                if (choice.text === "") {
                                    choice.isValid = false;
                                }
                                else {
                                    let choiceText = removeUnwantedWhitespacesFromText(choice.text.toLowerCase());
                                    if (choiceTexts.includes(choiceText)) {
                                        choice.isDuplicate = true;
                                    }
                                    choiceTexts.push(choiceText);
                                }
                            });
                        });
                    });
                    draft.formData.sections.forEach((section) => {
                        section.sectionItems.forEach((sectionItem) => {
                            sectionItem.choices.forEach((choice) => {
                                if (choice.subQuestions && choice.subQuestions.length > 0) {
                                    choice.subQuestions.forEach((subQuestion) => {
                                        if (subQuestion.questionTitle === "") {
                                            subQuestion.isValid = false;
                                        }
                                    });
                                }
                            });
                        });
                    });
                    draft.formData.sections.forEach((section) => {
                        section.sectionItems.forEach((sectionItem) => {
                            sectionItem.choices.forEach((choice) => {
                                if (choice.subQuestions && choice.subQuestions.length > 0) {
                                    choice.subQuestions.forEach((subQuestion) => {
                                        if (subQuestion.questionTypeId === QuestionType.MULTICHOICE) {
                                            let choiceTexts : string[] = []; 
                                            subQuestion.choices.forEach((subQuestionChoice) => {
                                                if (subQuestionChoice.text === "") {
                                                    subQuestionChoice.isValid = false;
                                                }
                                                else {
                                                    let subQuestionChoiceText = removeUnwantedWhitespacesFromText(subQuestionChoice.text.toLowerCase());
                                                    if (choiceTexts.includes(subQuestionChoiceText)) {
                                                        subQuestionChoice.isDuplicate = true;
                                                    }
                                                    choiceTexts.push(subQuestionChoiceText);
                                                }
                                            });
                                        }
                                    });
                                }
                            });
                        });
                    });
                }
                return draft;
            case actionTypes.RECIEVE_CUSTOM_QUESTION_SETTINGS:
                draft.customQuestionSettings = action.payload;
                return draft;
            case actionTypes.HANDLE_QUESTION_REORDERING:
                draft.formData.sections[action.payload.sectionIndex].sectionItems = action.payload.sectionItems;
                return draft;
            case actionTypes.HANDLE_SECTION_REORDERING:
                draft.formData = action.payload;
                return draft;

            case actionTypes.HANDLE_QUESTION_TYPE_CHANGE: {
                const { questionType, sectionIndex, sectionItemIndex } = action.payload;
                draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].questionTypeId = questionType;
                if (questionType === QuestionType.YESNO) {
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices = initialYesNoChoiceData;
                }
                if (questionType === QuestionType.MULTICHOICE) {
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices = initialMCQOptions;
                }
                return draft;
            }
            case actionTypes.HANDLE_CHOICE_TEXT_ONCHANGE: {
                const { sectionIndex, sectionItemIndex, choiceIndex, choiceText } = action.payload;
                if (draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices) {
                    if (choiceText !== "") {
                        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].isValid = true;
                    } else {
                        draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].isValid = false;
                    }
                    
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].isDuplicate = false;
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].text = choiceText;
                }

                return draft;
            }
            case actionTypes.HANDLE_ADD_CHOICE: {
                const { sectionIndex, sectionItemIndex } = action.payload;

                draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices.push({
                    ...initialChoiceData,
                    id: generateRandomNumber()
                });

                return draft;
            }
            case actionTypes.HANDLE_REMOVE_CHOICE: {
                const { sectionIndex, sectionItemIndex, choiceIndex } = action.payload;
                draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices.splice(choiceIndex, 1);

                return draft;
            }
            case actionTypes.ON_CLICK_YES_NO_CHECKBOX: {
                const { sectionIndex, sectionItemIndex, choiceIndex } = action.payload;
                const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];
                if (selectedChoice.subQuestions && selectedChoice.subQuestions.length === 0) {
                    selectedChoice.subQuestions.push(intialSectionItemData);
                } else {
                    selectedChoice.subQuestions.length=0;
                }
                return draft;
            }
            case actionTypes.ON_ADD_SUB_QUESTION: {
                const { sectionIndex, sectionItemIndex, choiceIndex, questionType } = action.payload;
                const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];
                if (selectedChoice.subQuestions && selectedChoice.subQuestions.length > 0) {
                    selectedChoice.subQuestions.push(GetAdditionalQuestion(questionType));
                    if(questionType==QuestionType.YESNO){
                        selectedChoice.subQuestions[selectedChoice.subQuestions.length-1].choices = initialYesNoChoiceData;
                    }
                    if(questionType==QuestionType.MULTICHOICE){
                        selectedChoice.subQuestions[selectedChoice.subQuestions.length-1].choices = initialMCQOptions;
                    }
                }
                return draft;
            }
            case actionTypes.ON_REMOVE_SUB_QUESTION: {
                const { sectionIndex, sectionItemIndex, choiceIndex, questionIndex } = action.payload;
                draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex].subQuestions.splice(questionIndex,1);
                return draft;
            }
            case actionTypes.ON_SUB_QUESTION_TYPE_CHANGE: {
                const { sectionIndex, sectionItemIndex, choiceIndex, questionType, subQuestionIndex } = action.payload;
                const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];
                for(let selectedSubQuestion of selectedChoice.subQuestions){
                    selectedSubQuestion.questionTypeId = questionType;
                    if (questionType === QuestionType.YESNO) {
                        selectedSubQuestion.choices = initialYesNoChoiceData;
                    }
                    if (questionType === QuestionType.MULTICHOICE) {
                        selectedSubQuestion.choices = initialMCQOptions;
                    }
                }
                return draft;
            }
            case actionTypes.ON_CHANGE_FOLLOW_UP_QUESTION_TITLE: {
                const { sectionIndex, sectionItemIndex, choiceIndex, value, questionIndex } = action.payload;
                const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];

                const selectedSubQuestion = selectedChoice.subQuestions[questionIndex];

                if (value !== "") {
                    selectedSubQuestion.isValid = true;
                } else {
                    selectedSubQuestion.isValid = false;
                }
                selectedSubQuestion.questionTitle = value;
                selectedSubQuestion.SectionItemId=generateRandomNumber();
                return draft;
            }

            case actionTypes.HANDLE_SUB_QUESTION_CHOICE_TEXT_ONCHANGE: {
                const { sectionIndex, sectionItemIndex, choiceIndex, questionIndex, choiceText, subQuestionChoiceIndex } = action.payload;
                const selectedChoice = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[choiceIndex];

                const selectedSubQuestion = selectedChoice.subQuestions[questionIndex];
                if (choiceText !== "") {
                    selectedSubQuestion.choices[subQuestionChoiceIndex].isValid = true;
                } else {
                    selectedSubQuestion.choices[subQuestionChoiceIndex].isValid = false;
                }

                selectedSubQuestion.choices[subQuestionChoiceIndex].isDuplicate = false;
                selectedSubQuestion.choices[subQuestionChoiceIndex].text = choiceText;

                return draft;
            }

            case actionTypes.HANDLE_SUB_QUESTION_ADD_CHOICE: {
                const { sectionIndex, sectionItemIndex, choiceIndex, questionIndex } = action.payload;

                draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[
                    choiceIndex
                ].subQuestions[questionIndex].choices.push({
                    ...initialChoiceData,
                    id: generateRandomNumber()
                });

                return draft;
            }
            case actionTypes.HANDLE_SUB_QUESTION_REMOVE_CHOICE: {
                const { sectionIndex, sectionItemIndex, choiceIndex, subQuestionChoiceIndex, questionIndex } = action.payload;
                draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices[
                    choiceIndex
                ].subQuestions[questionIndex].choices.splice(subQuestionChoiceIndex, 1);

                return draft;
            }
            case actionTypes.RESET_SUB_QUESTIONS: {
                const { sectionIndex, sectionItemIndex, isChecked } = action.payload;
                if (isChecked) {
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].hasFollowUpQuestion = true;
                } else {
                    draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].hasFollowUpQuestion = false;
                    let choices = draft.formData.sections[sectionIndex].sectionItems[sectionItemIndex].choices;
                    if (choices?.length > 0) {
                        choices.forEach((choice) => {
                            choice.isValid = true;
                            choice.subQuestions = [];
                        });
                    }
                }
                return draft;
            }
            default:
                return draft;
        }
    }
);
