import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FaFolderOpen } from 'react-icons/fa';

interface ReopenSouceDocumentUploadProps {
    show: boolean,
    onClose: () => void,
    reopenSourceDocumentUpload: (documentId: number,) => void,
}

const ReopenSourceDocumentModal: React.FC<ReopenSouceDocumentUploadProps> = ({ show, onClose, reopenSourceDocumentUpload }) => {
    return (
        <Modal className="reopen-sourcedocumentupload-modal" show={show} onHide={onClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    <FaFolderOpen className='modalIcon' />
                    Confirm Reopen Source Document Upload
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bootbox-body'>
                <p>
                    The source document upload is completed for this organizer. Do you wish to reopen the Source Document upload?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="default"
                    className="btn-white"
                    onClick={onClose} >
                    <i className="glyphicon glyphicon-remove" />
                    Cancel
                </Button>
                <Button
                    className="btn-info"
                    onClick={reopenSourceDocumentUpload}
                    variant="info">
                    <i className="glyphicon glyphicon-ok" />
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export { ReopenSourceDocumentModal };