import * as React from 'react';
import { EmailMessage } from './../../../models/OrganizerComponentModels';

interface EmailMessageListProps {
    emailMessages: EmailMessage[];
    selectedMessage: number;
    defaultMessage: number;
    onListItemClick: (messageId: number) => void;
}

export const EmailMessageList: React.StatelessComponent<EmailMessageListProps> = ({ selectedMessage, defaultMessage, emailMessages, onListItemClick }) => {

    const messageId: number = selectedMessage == 0 ? defaultMessage : selectedMessage;

    return (
        <div>
            <ul className="list-group" >
                {
                    emailMessages.map((message, index) => (
                        <li
                            className={message.id == messageId ? "custom-list-group-item-singleline custom-active" : "custom-list-group-item-singleline"}
                            title={message.name}
                            onClick={() => onListItemClick(message.id)}
                        >
                            <div className="width100">
                                <div style={{ display: 'inline-block' }}
                                    className={message.id == defaultMessage ? "col-md-10 nopadding overflowHidden" : "overflowHidden"}>
                                    {message.name.length > 60 ? message.name.substring(0, 60) + '...' : message.name}
                                </div>
                                {
                                    message.id == defaultMessage ?
                                        <div className="col-md-2" style={{ float: 'right' }}>
                                            <span className={"badge"}
                                                style={{ marginLeft: 10, float: 'initial', backgroundColor: '#777', color: 'white' }}>Default</span>
                                        </div>
                                        : <span></span>
                                }
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}