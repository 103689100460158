import * as React from 'react';
import * as moment from 'moment';
import MaskedInput from "react-text-mask"
import 'rc-datepicker/lib/style.css';
import { DatePicker } from 'rc-datepicker';

const isEqual = require("react-fast-compare");

export interface CustomDatePickerProps {
	value?: Date;
	onChange?: (date?: any) => void;
	disabled?: boolean;
	calendarContainer?: any;
	calendarPlacement?: string;
	// todo: Minimum and maximum date validation is not working while entering manually
	minDate?: Date;
	maxDate?: Date;
	clearFilter?: any;
	isDateFilterCleared?: boolean;
}

export interface CustomDatePickerState {
	value: Date | string | undefined;
	inputDate: string;
	calendarState: boolean;
	cachedPrevProp: CustomDatePickerProps;
}

const DATE_FORMAT: string = "MM/DD/YYYY";
export class CustomDatePicker extends React.Component<CustomDatePickerProps, CustomDatePickerState> {

	constructor(props: any) {
		super(props);

		this.state = {
			value: undefined,
			inputDate: "",
			calendarState: false,
			cachedPrevProp: {} as CustomDatePickerProps
		}

		this.calendarRender = this.calendarRender.bind(this);
		this.onChangeCustom = this.onChangeCustom.bind(this);
		this.changeInput = this.changeInput.bind(this);
		this.clearDate = this.clearDate.bind(this);
		this.handleBlur = this.handleBlur.bind(this);
		this.clearDateAsync = this.clearDateAsync.bind(this);

	}

	static getDerivedStateFromProps(nextProps: CustomDatePickerProps, prevState: CustomDatePickerState) {
		const date = nextProps.value && moment(nextProps.value);
		const value = nextProps.value && date && date.isValid() ? date.format('YYYY-MM-DD[T]HH:mm:ss.SSS') : undefined;
		let tempdate = moment(value).format(DATE_FORMAT);
		let prevProp = prevState.cachedPrevProp;
		if (!isEqual(prevProp, nextProps)) {
			return {
				value: value != prevState.value ? value : prevState.value,
				inputDate: value != prevState.value ? tempdate : prevState.inputDate,
				cachedPrevProp: nextProps
			}
		}

		return null;
	}

	private handleChange = (value: any, formatedValue: any) => {
		this.setState({ value: value }, () => {
			if (this.props.onChange) {
				this.props.onChange(value && new Date(value));
			}
		});
	}

	componentDidUpdate() {
		if (this.props.isDateFilterCleared) {
			this.clearDateAsync();
		}
	}

	changeInput(e: React.ChangeEvent<HTMLInputElement>) {
		let dateVal = e.target.value;

		if (!this.state.calendarState) {
			this.setState({ calendarState: true });
		}
		if (dateVal.length == 0) {
			this.clearDateAsync();
		}

		if (dateVal.length === 10 && moment(dateVal).isValid()) {
			dateVal = dateVal.replace(/\//g, '-');

			let formattedDate = moment(dateVal).format(DATE_FORMAT);

			this.setState({ value: formattedDate, inputDate: dateVal, calendarState: false }, () => {
				if (this.props.onChange) {
					this.props.onChange(formattedDate);

				}
			});
		}
	}

	onChangeCustom(date: Date) {
		let formattedDate = moment(date.toString()).format(DATE_FORMAT);

		date.setHours(0, 0, 0, 0);
		this.setState({ value: date, inputDate: formattedDate, calendarState: false }, () => {
			if (this.props.onChange) {
				this.props.onChange(formattedDate);
			}
		});
	};

	clearDate() {
		this.setState({ value: undefined, inputDate: "" },
			() => {
				this.props.clearFilter ? this.props.clearFilter() : "";
				if (this.props.onChange) {
					this.props.onChange(undefined);
				}
			});
	}

	clearDateAsync() {
		setTimeout(this.clearDate, 0);
	}

	handleBlur() {
		this.setState({ calendarState: false });
	}

	private calendarRender = (DatePickerComp: any) => {
		let MaskComp = MaskedInput as any;
		return (<div className="calendarContainer" onBlur={this.handleBlur}>
			<div className="calendarClass">
				<MaskComp
					mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
					placeholder={DATE_FORMAT}
					type="text"
					value={this.state.inputDate}
					onClick={() => {
						this.setState({ calendarState: true })
					}}
					className="inputClass form-control"
					onKeyUp={null}
					onChange={this.changeInput}
				/>
				<span
					className="minusClass"
					onClick={this.clearDate}
				>
					x
				</span>
			</div>
			{this.state.calendarState ? (
				<div className="calendarInput" id="dateId"
					onMouseDown={(e) => {
						e && e.preventDefault();
					}}>
					<DatePickerComp
						value={this.state.value}
						disabled={this.props.disabled}
						onChange={this.onChangeCustom}
						className='group-input dateClass'
						minDate={this.props.minDate}
						maxDate={this.props.maxDate}
					/>
				</div>
			) : (
				""
			)}
		</div>)
	}

	public render() {

		const DatePickerPart = DatePicker as any;
		return (

			<div className='control-pane' >
				<div className='control-section'>
					<div className='datepicker-control-section' id="datepicker_input">
						{this.calendarRender(DatePickerPart)}
					</div>
				</div>
			</div>
		);
	}
}

export default CustomDatePicker;
