import 'bootstrap';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import store from './configureStore';
import Routes from './routes/index';
import 'react-overlay-loader/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/custom/index.css';
import './assets/scripts/polyfill.min.js';
import './assets/scripts/tinymce.min.js';
import './assets/custom/index.css';
import AuthProvider from './oidcClient/AuthProvider';
import '../node_modules/awesome-pdf-viewer/dist/index.css'
import history from './routes/history'

function renderApp() {
    // This code starts up the React app when it runs in a browser. It sets up the routing configuration
    // and injects the app into a DOM element.
    ReactDOM.render(
        <Provider store={store}>
            <AuthProvider store={store}>
                <ConnectedRouter history={history}>
                    <Routes history={history} />
                </ConnectedRouter>
            </AuthProvider>
        </Provider>,
        document.getElementById('root')
    );
}
renderApp();
