import React from 'react'
import { useSelector } from 'react-redux'
import { ILogger } from 'src/Logger/Logger';
import { ApplicationState } from '../store'
import { TelemetryLogger } from 'src/Logger/Telemetry/TelemetryLogger';

export let logger: ILogger;
const LoggedIn: React.FC = ({ children }) => {
    const user = useSelector((state: ApplicationState) => state.auth?.user)
    if (user) {
        document.cookie = "id_Token=token";
    }
    logger = user && TelemetryLogger.getInstance(user);
    return user ? <>{children}</> : <></>
}

LoggedIn.displayName = 'LoggedIn';

export default LoggedIn;
