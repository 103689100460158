import { PdfViewer } from 'awesome-pdf-viewer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { Main } from 'awesome-pdf-viewer/dist/layout/Main';
import { RightPanel } from 'awesome-pdf-viewer/dist/layout/RightPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import * as React from 'react';
import {
    Accordion, Card, Col, FormControl,
    FormLabel, Row
} from 'react-bootstrap';
import Select from 'react-select';
import { GetCountryCode, getOrganizersTaxYearList } from '../../../../Helper/OrganizerHelperFunctions';
import { CheckBoxComponent } from './../../../../../../components/common/CheckBoxComponent';
import { PhoneNumberComponent } from './../../../../../../components/common/PhoneNumberComponent';
import { INVALID_PAGE } from './../../../../../../components/common/ProcessReturnModal/CustomPagination/CustomPagination';
import { VenusNotifier } from './../../../../../../components/helper/VenusNotifier';
import { ICountryState } from './../../../../../../Core/ViewModels/Company/CompanyViewModel';
import { DisplayEngagementListLabelValue, OrganizerClient, OrganizerClientType, OrganizerDocument } from './../../../../../models/OrganizerComponentModels';
import { PdfProperties } from '../../../../Helper/PdfHelper'
import { ClientInfoProperty, IPrefilledClientInfoDetails } from '../../../../../../modules/clientManagement/clientManagement.types';
import { OrganizerClientIdConstants } from '../../../../../../components/helper/Constants';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { ClientInfoTabConstants } from 'src/Organizer/components/Helper/OrganizerConstants';

let dateMoment = require('moment');

export interface ClientInfoTabProps {
    model: OrganizerDocument;
    states: ICountryState[];
    updateDocument: (document: OrganizerDocument) => void;
    pages: number[];
    readOnlyView?: boolean;
    pdfURL: string;
    preFillInfoDetails: IPrefilledClientInfoDetails;
    onResetPrefillInfoDetails: (propertyName: ClientInfoProperty) => void;
    locations: IDropdown[];
    eros: IDropdown[];
}

export interface ClientInfoTabState {
    expandTaxpayer: boolean;
    expandSpouse: boolean;
    expandInfo: boolean;
    currentPage: number;
    zoomEnabled: boolean;
    currentPageNo: number;
    pageHeight: number;
    scale: number;
}


export default class ClientInfoTab extends React.Component<ClientInfoTabProps, ClientInfoTabState> {
    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;
    private _bookmarkPanel: any;
    private _controlDisplayPanel: any;
    constructor(props: ClientInfoTabProps) {
        super(props);
        this.state = {
            expandTaxpayer: true,
            expandSpouse: true,
            expandInfo: true,
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            currentPageNo: 1,
            pageHeight: PdfProperties.PageHeight,
            scale: PdfProperties.DefaultScale
        }
    }

    componentDidMount() {
        this.setReferences();
    }
  
    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
        this._controlLayer && this._controlLayer.setcontrolDisplayPanelReference(this._controlDisplayPanel);
        this._controlDisplayPanel && this._controlDisplayPanel.setControlsReference(this._controlList);
        this._controlDisplayPanel && this._controlDisplayPanel.setViewerReference(this._viewPanel);
        this._viewPanel.setBookmarkPanelReference(this._bookmarkPanel);
    }

    componentWillReceiveProps(nextProps: ClientInfoTabProps) {
        if (nextProps.pdfURL != '') {
            this.getCurrentPageHeight();
        }
        if (nextProps && nextProps.locations.length !== this.props.locations.length) {
            if(nextProps.locations && nextProps.locations.length === 1){
                this.props.model.locationId = nextProps.locations[0].value ;
                this.props.onResetPrefillInfoDetails(ClientInfoProperty.LocationId);
                this.props.updateDocument(this.props.model);
            }
        }
    }

    onPageSelect = (pageNo: number) => {
        this.setState({ currentPage: pageNo });
        this._viewPanel.gotoPage(pageNo);
    }

    onChange = (event: any, type: OrganizerClientType) => {
        const target: string = event.target.name;
        this.props.model.organizerClients.map(client => {
            if (client.clientType === type) {
                (client as any)[target] = event.target.value;
                return client;
            }
            return client;
        })

        if (target == "name") {
            this.props.onResetPrefillInfoDetails(type == OrganizerClientType.Taxpayer ?
                ClientInfoProperty.Name :
                ClientInfoProperty.SpouseName);
        }
        else if (target == "email") {
            this.props.onResetPrefillInfoDetails(type == OrganizerClientType.Taxpayer ?
                ClientInfoProperty.EmailAddress :
                ClientInfoProperty.SpouseEmailAddress);
        }
        this.props.updateDocument(this.props.model);
    }

    handleChangeDoB = (value: any, type: OrganizerClientType) => {
        const now = new Date();
        if (dateMoment(value) > now) {
            VenusNotifier.Warning("Please enter valid date of birth.", null);
        }
        else {
            this.props.model.organizerClients.map(client => {
                if (client.clientType === type) {
                    client.dob = value;
                    return client;
                }
                return client;
            })
            this.props.updateDocument(this.props.model);
        }
    }

    onClientIdChange = (e: any) => {
        this.props.model.clientId = e.target.value;
        this.props.updateDocument(this.props.model);
    }

    onStateChange = (e: any, type: OrganizerClientType) => {
        this.props.model.organizerClients.map(client => {
            if (client.clientType === type) {
                client.state = e.value;
                return client;
            }
            return client;
        })
        this.props.updateDocument(this.props.model);
    }

    onDeceasedChange = (e: any, type: OrganizerClientType) => {
        this.props.model.organizerClients.map(client => {
            if (client.clientType === type) {
                client.isDeceased = e.target.checked;
                return client;
            }
            return client;
        });
        this.props.updateDocument(this.props.model);
    }


    onCountryCodeChange = (e: any, type: OrganizerClientType) => {
        this.props.model.organizerClients.map(client => {
            if (client.clientType === type) {
                client.countryCode = e ? e.value : "";
                return client;
            }
            return client;
        })
        this.props.onResetPrefillInfoDetails(type == OrganizerClientType.Taxpayer ?
            ClientInfoProperty.CountryCode :
            ClientInfoProperty.SpouseCountryCode);
        this.props.updateDocument(this.props.model);
    }

    onLocationChange = (e: any) => {

        this.props.model.locationId =  e ? e.value : 0;
        this.props.onResetPrefillInfoDetails(ClientInfoProperty.LocationId);
        this.props.updateDocument(this.props.model);
    }

    onEROChange = (e: any) => {

        this.props.model.ero =  e ? e.value : 0;
        this.props.onResetPrefillInfoDetails(ClientInfoProperty.EROId);
        this.props.updateDocument(this.props.model);
    }

    onMobileNumberChange = (value: any, type: OrganizerClientType) => {
        this.props.model.organizerClients.map(client => {
            if (client.clientType === type) {
                client.mobileNumber = value;
                return client;
            }
            return client;
        })

        this.props.onResetPrefillInfoDetails(type == OrganizerClientType.Taxpayer ?
            ClientInfoProperty.MobileNumber :
            ClientInfoProperty.SpouseMobileNumber);

        this.props.updateDocument(this.props.model);
    }

    toggleExpand = (type?: OrganizerClientType) => {
        if (type) {
            switch (type) {
                case OrganizerClientType.Taxpayer:
                    this.setState({
                        expandTaxpayer: !this.state.expandTaxpayer
                    });
                    break;

                case OrganizerClientType.Spouse:
                    this.setState({
                        expandSpouse: !this.state.expandSpouse
                    });
                    break;
            }
        }
        else {
            this.setState({
                expandInfo: !this.state.expandInfo
            });
        }
    }

    onPaginationClick = (pageNo: number) => {
        this.setState({ currentPage: this.props.pages[pageNo - 1] });
    }

    onPageChange = (pageProps: PageProperties) => {
        this._toolbar.handleZoomChange(this.state.scale);
        this.setState({ currentPage: this.props.pages[pageProps.page - 1] });
    }

    onZoom = (enable: boolean) => {
        this.setState({ zoomEnabled: enable });
    }


    onFirstPage = () => {
        this._viewPanel.gotoPage(this.props.pages[0]);
        this.setState({
            currentPage: this.props.pages[0],
            currentPageNo: 1
        });
    }

    onLastPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.length - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.length - 1],
            currentPageNo: this.props.pages.length
        });
    }

    onNextPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage) + 2
        });
    }

    onPreviousPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage)
        });
    }

    onGotoPage = (pageNo: number) => {
        this._viewPanel.gotoPage(this.props.pages[pageNo - 1]);
        this.setState({
            currentPage: this.props.pages[pageNo - 1],
            currentPageNo: pageNo
        }, () => {
            this.getCurrentPageHeight();
        });
    }

    getCurrentPageHeight = () => {
        if (this._viewPanel) {
            const document = this._viewPanel.getDocument();
            document && document.getPage(this.state.currentPage ? this.state.currentPage : this.state.currentPageNo)
                .then((page: any) => {
                    const pageHeight = page.view[3];
                    this.setState({
                        pageHeight: pageHeight,
                        currentPage: this.props.pages[0],
                        currentPageNo: 1
                    })
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    }

    render() {
        const { states, pages, readOnlyView, model, locations, eros } = this.props;
        const { currentPageNo, expandInfo } = this.state;
        const readOnly = readOnlyView ? true : false;
        return <PdfViewer>
            <Header>
                <Toolbar
                    ref={(ref: any) => this._toolbar = ref}
                    customToolbarPosition={'left'}
                    hideLeftPanel={true}
                    hideReadOnly={true}
                >

                    <Pagination
                        currentPage={currentPageNo}
                        onFirstPage={this.onFirstPage}
                        onNextPage={this.onNextPage}
                        onPreviousPage={this.onPreviousPage}
                        onLastPage={this.onLastPage}
                        onGotoPage={this.onGotoPage}
                        totalPages={pages.length}
                    >

                    </Pagination>
                    <Zoom>
                    </Zoom>

                </Toolbar>
            </Header>
            <Main>
                <ViewPanel onDocumentLoad={() => {
                    this.setState({
                        scale: 1
                    })
                }}
                    ref={(ref: any) => this._viewPanel = ref}
                    onPageChanged={this.onPageChange}
                    pageMode={PageMode.SinglePage}
                    pdfSource={PdfSource.createFromUrl(this.props.pdfURL)}
                    onScaleChanged={(scale: number) => {
                        this.setState({
                            scale: scale
                        })
                    }}
                    defaultPage={this.props.pages[0]}
                    disableTextLayer={true}
                >
                </ViewPanel>
                <RightPanel>
                    <OrganizerInfoPanel 
                        clientId={model.clientId}
                        engagementType={model.engagementType}
                        expanded={expandInfo}
                        onClientIdChange={this.onClientIdChange}
                        taxYear={model.taxYear}
                        toggleExpand={this.toggleExpand}
                        readOnly={readOnly}                        
                        locations={locations}
                        preFillInfoDetails={this.props.preFillInfoDetails}
                        locationId={model.locationId}
                        onLocationChangeProp={this.onLocationChange}                        
                        eros={eros}
                        eroId={model.ero}
                        onEROChangeProp={this.onEROChange}
                    />
                    {
                        this.props.model.organizerClients
                            .sort((a: OrganizerClient, b: OrganizerClient) => {
                                if (a.clientType === OrganizerClientType.Taxpayer)
                                    return -1;
                                else
                                    return 1;
                            })
                            .map(client => {
                                const expanded = client.clientType == OrganizerClientType.Taxpayer ? this.state.expandTaxpayer : this.state.expandSpouse;
                                const icon = expanded ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";
                                const defaultKey = expanded ? "0" : "1";
                                return (<Accordion defaultActiveKey={defaultKey} style={{ paddingBottom: '5px', paddingRight: '8px' }}>
                                    <Card key={client.id} className='organizer-info-card'>
                                        <Accordion.Toggle
                                            style={{ padding: "10px" }}
                                            as={Card.Header}
                                            variant="link"
                                            eventKey="0"
                                            onClick={() => this.toggleExpand(client.clientType)}>

                                            <Card.Title as="h4">
                                                <i className={icon} style={{ marginRight: '10px' }}></i>
                                                {OrganizerClientType[client.clientType].toString()}
                                            </Card.Title>
                                        </Accordion.Toggle>

                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <OrganizerClientDetails
                                                    model={client}
                                                    type={client.clientType}
                                                    onDOBChange={this.handleChangeDoB}
                                                    onFieldChange={this.onChange}
                                                    states={states}
                                                    onStateChange={this.onStateChange}
                                                    onCountryCodeChange={this.onCountryCodeChange}
                                                    onMobileNumberChange={this.onMobileNumberChange}
                                                    onDeceasedChange={this.onDeceasedChange}
                                                    readOnly={readOnly}
                                                    preFillInfoDetails={this.props.preFillInfoDetails}
                                                />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card></Accordion>);
                            })
                    }
                </RightPanel>
            </Main>
        </PdfViewer>
    }
}

interface OrganizerClientProps {
    type: OrganizerClientType;
    model: OrganizerClient;
    onFieldChange: (e: any, type: OrganizerClientType) => void;
    onDOBChange: (value: any, type: OrganizerClientType) => void;
    states: ICountryState[];
    onStateChange: (e: any, type: OrganizerClientType) => void;
    onMobileNumberChange: (e: any, type: OrganizerClientType) => void;
    onCountryCodeChange: (e: any, type: OrganizerClientType) => void;
    onDeceasedChange: (e: any, type: OrganizerClientType) => void;
    readOnly: boolean;
    preFillInfoDetails: IPrefilledClientInfoDetails;
}

const OrganizerClientDetails: React.StatelessComponent<OrganizerClientProps> = ({ onFieldChange,
    model: { address, city, dob, email, name, ssn, state, zip, mobileNumber, countryCode, isDeceased, clientType },
    onDOBChange, type, states, onStateChange, onCountryCodeChange, onMobileNumberChange,
    onDeceasedChange, readOnly, preFillInfoDetails
}) => {

    const onChange = (e: any) => {
        onFieldChange(e, type);
    }

    const onChangeState = (e: any) => {
        onStateChange(e, type);
    }

    const onChangeDoB = (value: any) => {
        onDOBChange(value, type);
    }

    const onChangeMobileNumber = (value: any) => {
        onMobileNumberChange(value, type);
    }

    const onChangeCountryCode = (value: any) => {
        onCountryCodeChange(value, type);
    }

    const getCssClassName = (propertyName: ClientInfoProperty) => {
        return preFillInfoDetails[propertyName] ? 'backgroudBlue' : '';
    }


    return (<div>

        <Row>
            <Col className="text-left padT07" sm={3} as={FormLabel}>
                Name
            </Col>
            <Col sm={9}>
                <FormControl
                    name="name"
                    type="text"
                    value={name}
                    onChange={onChange}
                    placeholder="Name"
                    style={{ marginBottom: '10px' }}
                    disabled={readOnly}
                    className={getCssClassName(clientType == OrganizerClientType.Taxpayer ? ClientInfoProperty.Name : ClientInfoProperty.SpouseName)}
                />
            </Col>
        </Row>

        <Row>
            <Col sm={3} className="text-left padT07" as={FormLabel}>
                Email
            </Col>
            <Col sm={9}>
                <FormControl
                    name="email"
                    type="email"
                    value={email}
                    onChange={onChange}
                    placeholder="Email"
                    style={{ marginBottom: '10px' }}
                    disabled={readOnly}
                    className={getCssClassName(clientType == OrganizerClientType.Taxpayer ? ClientInfoProperty.EmailAddress : ClientInfoProperty.SpouseEmailAddress)}
                />
            </Col>
        </Row>
        <Row>
            <Col className="text-left padT07" sm={3} as={FormLabel}>
                Country Code
            </Col>
            <Col sm={9}>
                <Select 
                    name="countryCode"
                    value={countryCode}
                    onChange={onChangeCountryCode}
                    options={GetCountryCode()}
                    style={{ marginBottom: '10px', fontSize: '14px' }}
                    clearable={true}
                    disabled={readOnly}
                    className={clientType == OrganizerClientType.Spouse ? getCssClassName(ClientInfoProperty.SpouseCountryCode) : getCssClassName(ClientInfoProperty.CountryCode)}
                >                  
                </Select>
            </Col>
        </Row>
        <Row>
            <Col className="text-left padT07" sm={3} as={FormLabel}>
                Mobile Phone
            </Col>
            <Col sm={9} className={"zeroborderRadius"}>
                <PhoneNumberComponent
                    phoneNumber={mobileNumber ? mobileNumber : ""}
                    handleChangePhoneNumber={onChangeMobileNumber}
                    disabled={readOnly}
                    className={clientType == OrganizerClientType.Spouse ? getCssClassName(ClientInfoProperty.SpouseMobileNumber) : getCssClassName(ClientInfoProperty.MobileNumber)}
                >
                </PhoneNumberComponent>
            </Col>
        </Row>
        <Row>
            <Col className="text-left padT08" style={{ paddingRight: '0px' }}
                sm={3} as={FormLabel}>
                Deceased
            </Col>
            <Col sm={9} className={"marTNeg03"}>
                <CheckBoxComponent
                    id={`${clientType}-deceased-checkBox`}
                    text={""}
                    checked={isDeceased}
                    datatestAuto={"1189D6C6-93FB-4846-8D37-859AC9D833E5"}
                    onChange={(e) => onDeceasedChange(e, type)}
                    disabled={readOnly}
                />
            </Col>
        </Row>
    </div>);
}

interface OrganizerInfoPanelProps {
    expanded: boolean;
    toggleExpand: () => void;
    clientId: string;
    onClientIdChange: (e: any) => void;
    engagementType: number;
    taxYear: number;
    readOnly: boolean;
    locations: IDropdown[];
    locationId: number;
    preFillInfoDetails: IPrefilledClientInfoDetails;
    onLocationChangeProp: (e: any) => void;    
    eros: IDropdown[];
    eroId: number;
    onEROChangeProp: (e: any) => void;
}

const OrganizerInfoPanel: React.FC<OrganizerInfoPanelProps> = ({ expanded, toggleExpand, clientId, onClientIdChange, engagementType, taxYear, readOnly, locations, locationId, preFillInfoDetails, onLocationChangeProp, onEROChangeProp, eros, eroId }) => {
    const icon = expanded ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";
    const defaultKey = expanded ? "0" : "1";
    const taxYearList = getOrganizersTaxYearList();
    
    const onLocationChange = (value: any) => {
        onLocationChangeProp(value);
    }
    
    const onEROChange = (value: any) => {
        onEROChangeProp(value);
    }

    const getCssClassName = (propertyName: ClientInfoProperty) => {
        return preFillInfoDetails[propertyName] ? 'backgroudBlue' : '';
    }

    return (
        <Accordion defaultActiveKey={defaultKey} style={{ paddingBottom: '5px', paddingRight: '8px' }}>
            <Card className='organizer-info-card'>
                <Accordion.Toggle
                    style={{ padding: "10px" }}
                    as={Card.Header}
                    variant="link" eventKey="0"
                    onClick={() => toggleExpand()}>
                    <Card.Title as="h4">
                        <i className={icon} style={{ marginRight: '10px' }}></i>                        
                        {ClientInfoTabConstants.OrganizerInformation.Label.OrganizerInformation}
                    </Card.Title>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <Row>
                            <Col className="text-left padT07" sm={3} as={FormLabel}>                                
                                {ClientInfoTabConstants.OrganizerInformation.Label.ClientID}
                            </Col>
                            <Col sm={9}>
                                <FormControl
                                    name="clientId"
                                    type="text"
                                    value={clientId}
                                    onChange={onClientIdChange}
                                    placeholder="Client ID"
                                    disabled={readOnly}
                                    maxLength={OrganizerClientIdConstants.ClientIdMaxLength}
                                    className={'margin-bottom-10'}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-left padT07" sm={3} as={FormLabel} style={{ padding: '10px 0 0 15px' }}>
                                {ClientInfoTabConstants.OrganizerInformation.Label.EROSigner}
                            </Col>
                            <Col sm={9}>
                                <Select
                                    name="ero"
                                    onChange={onEROChange}
                                    value={eroId}
                                    options={eros}
                                    clearable={true}                                    
                                    disabled={readOnly}
                                    className={"select-value-fontSize " + getCssClassName(ClientInfoProperty.EROId)}
                                >
                                </Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-left padT07" sm={3} as={FormLabel}>                                
                                {ClientInfoTabConstants.OrganizerInformation.Label.EngagementType}
                            </Col>
                            <Col sm={9}>
                                <Select
                                    name="engagementType"
                                    value={engagementType}
                                    disabled={true}
                                    options={DisplayEngagementListLabelValue}
                                    clearable={false}
                                    className={'margin-bottom-10'}
                                >
                                </Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-left padT07" sm={3} as={FormLabel}>                                
                                {ClientInfoTabConstants.OrganizerInformation.Label.TaxYear}
                            </Col>
                            <Col sm={9}>
                                <Select
                                    name="taxYear"
                                    disabled={true}
                                    value={taxYear}
                                    options={taxYearList}                                    
                                    clearable={false}
                                    className={'margin-bottom-10'}
                                >
                                </Select>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-left padT07" sm={3} as={FormLabel} style={{ padding: '10px 0 0 15px' }}>
                                {ClientInfoTabConstants.OrganizerInformation.Label.OfficeLocation}
                            </Col>
                            <Col sm={9}>
                                <Select
                                    name="officeLocations"
                                    onChange={onLocationChange}
                                    value={locationId}
                                    options={locations}                                    
                                    clearable={true}
                                    disabled={readOnly || locations.length === 1}
                                    className={"select-value-fontSize " + getCssClassName(ClientInfoProperty.LocationId)}
                                >
                                </Select>
                            </Col>
                        </Row>

                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
