import { Action, Reducer } from 'redux';
import { addTask } from 'domain-task';
import { AppThunkAction } from './../../store/index';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import {
    OrganizerBatchExcelDownloadLoaderAction,
    OrganizerBatchExcelDownloadStoreState,
    initialOrganizerBatchExcelDownloadStoreState,
    apiPrefix
} from './../models/OrganizerStoreModels';
import { StoreConstants } from '../components/Helper/OrganizerConstants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { handleBlobwithFileName } from "./../../store/Library";
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';

const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    OrganizerBatchExcelDownloadLoaderAction |
    NotificationAction;
 
export const actionCreators = {
    downloadExcel: (id: string): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.ORGANIZER_BATCH_EXCEL_DOWNLOAD_LOADER,
            loading: true
        });
        let fetchTask = fetch(`${apiPrefix}BatchExcelDownload/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
        .then(response => handleBlobwithFileName(response))
            .then(data => {
                
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob,data.filename);  

                dispatch({
                    type: actionTypes.ORGANIZER_BATCH_EXCEL_DOWNLOAD_LOADER,
                    loading: false
                });
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Export,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`downloadExcel failed for Id: ${id}, with error ${error.message}`)
            });
        addTask(fetchTask);
    }
};
export const reducer: Reducer<OrganizerBatchExcelDownloadStoreState> = (state: OrganizerBatchExcelDownloadStoreState = initialOrganizerBatchExcelDownloadStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.ORGANIZER_BATCH_EXCEL_DOWNLOAD_LOADER: {
            const loader = { ...state };
            loader.loading = action.loading;
            return loader;
        }
    }
    return state || initialOrganizerBatchExcelDownloadStoreState;
}
