import { handleResponse } from "../../../store/Library";
import { API_BASE_URL } from "../../../utils/constants";
import { IClientInfoViewModel } from "../clientManagement.types";

export const updateExistingClientInfo = (clientInfo: IClientInfoViewModel, resourceId: string, callback?: () => void,
    failedCallback?: () => void) => {
    fetch(API_BASE_URL + `/api/organizer/clients/EditClientInfoAsync`, {
        method: "PUT",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            Accept: "application/json, */*",
            "Content-Type": "application/json",
            'X-Resource-Id': resourceId,
        }
    })
        .then(handleResponse)
        .then((response: any) => {
            if (callback)
                callback();
        })
        .catch((error: any) => {
            if (failedCallback)
                failedCallback();

        });
};
