import { AuthenticationProvider } from '../../../Core/ViewModels/Company/CompanySettingsViewModel';

export interface IModalProps {
	caption?: string;
	route?: string;
	showState: boolean;
	onHide(): void;
};

export interface IUserProfile {
	firstName: string;
	lastName: string;
	phoneNumber: string;
	extension: string;
	faxNumber: string;
	emailAddress: string;
	oldPassword: string;
	password: string;
	confirmPassword: string;
	userId: number;
	authenticationProviders: AuthenticationProvider[];
	metadata: string;
	countryCode: string;
	mobileNumber: string;
	isMobileVerify: boolean;
	readonlyFields: string[];
}



export interface IUserLoginHistory {
	userEmail: string;
	loggedInOn: string;
	loggedOutOn: string;
	IPAddress: string;
	clientApp: string;
	deviceId: string;
	customData: string;
}

export const initialUserProfile: IUserProfile = {
	firstName: '',
	lastName: '',
	phoneNumber: '',
	extension: '',
	faxNumber: '',
	emailAddress: '',
	oldPassword: '',
	password: '',
	confirmPassword: '',
	userId: 0,	
	authenticationProviders: [],
	metadata: "",
	countryCode: "",
	mobileNumber: "",
	isMobileVerify: false,
	readonlyFields: []
}

export interface IUserPassword {
	password: string,
	newPassword: string
}

export interface IOneTimePassword {
	id: number;
	otp: string;
	retryCount: number;
	otpType: OTPType;
	address: string;
	createdOn: Date;
	otpData: IOTPData;
}

export enum OTPType {
	MFA = 1,
	MobileVerification = 2
}

export interface IOTPData {
	userId: number;
}

export const initialOneTimePassword: IOneTimePassword = {
	id: 0,
	otp: '',
	retryCount: 0,
	otpType: OTPType.MobileVerification,
	address: '',
	createdOn: new Date(),
	otpData: {
		userId: 0
	}
}

export interface IOTPState {
	mfaOTPLength: number;
	validOTP: boolean;
}