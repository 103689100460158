import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    DeleteOrganizerPreparerMessageAction,
    OrganizerPrepareMessageStoreState,
    ReceiveOrganizerPreparerMessageAction,
    OrganizerPreparerMessageLoader,
    SaveOrganizerPreparerMessageAction,
    UpdateOrganizerPreparerMessageAction,
    unloadedOrganizerPreparerMessageStore,
    apiPrefix
} from './../models/OrganizerStoreModels';
import { PreparerMessage, OrganizerCompanySettings } from './../models/OrganizerComponentModels';
import { ActionCreators } from './OrganizerCompanySettingsStore';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();



type KnownActions =
    DeleteOrganizerPreparerMessageAction |
    ReceiveOrganizerPreparerMessageAction |
    OrganizerPreparerMessageLoader |
    SaveOrganizerPreparerMessageAction |
    UpdateOrganizerPreparerMessageAction |
    NotificationAction

export const actionCreators = {

    requestPreparerMessages: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}SavedMessage/`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<PreparerMessage[]>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_PREPARER_MESSAGES, data: data,
                    loading: false
                });
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.FetchPreparerMessage, statusType: StatusType.Error, statusCode: error?.status
                });
                logger?.trackError(`requestPreparerMessages failed for the request with error ${error.message}`)
            }); addTask(fetchTask);

        dispatch({ type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER, loading: true });
    },

    savePreparerMessage: (message: PreparerMessage, companySettings: OrganizerCompanySettings, isSetDefault: boolean)
        : AppThunkAction<KnownActions> => (dispatch, getState) => {
            dispatch({ type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER, loading: true });

            let fetchTask = fetch(`${apiPrefix}SavedMessage/`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(message),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(handleResponse)
                .then((newId) => {
                    message.id = newId;
                    if (isSetDefault) {
                        companySettings.defaultSettings.savedMessage = newId;
                        const action: any = ActionCreators.updateOrganizerCompanySettings(companySettings);
                        dispatch(action);
                    }
                    dispatch({
                        type: actionTypes.SAVE_ORGANIZER_PREPARER_MESSAGE, save: message
                    });
                    dispatch({ type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER, loading: false });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Success.SavePreparerMessage,
                        statusType: StatusType.Success
                    });
                }).catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.SavePreparerMessage,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`savePreparerMessage failed for the request having parameters ${JSON.stringify(message)} with error ${error.message}`)
                });
            addTask(fetchTask);
        },

    updatePreparerMessage: (message: PreparerMessage): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER, loading: true });

        let fetchTask = fetch(`${apiPrefix}SavedMessage/`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.UPDATE_ORGANIZER_PREPARER_MESSAGE, update: message
                });
                dispatch({ type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER, loading: false });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.UpdatePreparerMessage,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.UpdatePreparerMessage, statusType: StatusType.Error, statusCode: error?.status
                });
                logger?.trackError(`updatePreparerMessage failed for the request having parameters ${JSON.stringify(message)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    deletePreparerMessage: (message: PreparerMessage): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER, loading: true });

        let fetchTask = fetch(`${apiPrefix}SavedMessage/`, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(message),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.DELETE_ORGANIZER_PREPARER_MESSAGE, delete: message
                });
                dispatch({ type: actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER, loading: false });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.DeletePreparerMessage,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DeletePreparerMessage,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`deletePreparerMessage failed for the request having parameters ${JSON.stringify(message)} with error ${error.message}`)
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<OrganizerPrepareMessageStoreState> = (state: OrganizerPrepareMessageStoreState = unloadedOrganizerPreparerMessageStore, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.ORGANIZER_PREPARER_MESSAGE_LOADER:
            return {
                ...state,
                loading: action.loading
            };

        case actionTypes.RECEIVE_ORGANIZER_PREPARER_MESSAGES:
            return {
                preparerMessages: action.data,
                loading: action.loading
            };

        case actionTypes.DELETE_ORGANIZER_PREPARER_MESSAGE:
            let deleteMessage = { ...state }
            deleteMessage.preparerMessages = deleteMessage.preparerMessages.filter(message => message.id !== action.delete.id);
            return deleteMessage;

        case actionTypes.UPDATE_ORGANIZER_PREPARER_MESSAGE:
            let updateMessage = { ...state };
            updateMessage.preparerMessages = updateMessage.preparerMessages.map(message => {
                if (message.id === action.update.id)
                    return {
                        ...action.update
                    }
                return message;
            });
            return updateMessage;
        case actionTypes.SAVE_ORGANIZER_PREPARER_MESSAGE:
            let addedMessage = { ...state };
            addedMessage.preparerMessages.push(action.save);
            return addedMessage;
    }
    return state || unloadedOrganizerPreparerMessageStore;
};