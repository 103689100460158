import { addTask } from 'domain-task';
import { Reducer, Action } from "redux";
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { handleResponse } from './../../store/Library';
import { actionTypes as menuActionTypes } from "./../../store/productMenu/ActionTypes/ActionTypes"
import {
    IntialWidgetMenuState, apiPrefix, RequestWidgetMenuItems, ReceivedWidgetMenuItems,
    CompletedwidgetMenuItems,
    RequestSuiteUrl, ReceivedSuiteUrl, CompletedSuiteUrl,
    IWidgetMenuState
} from './model/ProductMenuStoreModel';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import { Product } from 'src/Core/ViewModels/User/UserViewModel';

export type DispatchActions = RequestWidgetMenuItems | ReceivedWidgetMenuItems |
    CompletedwidgetMenuItems | RequestSuiteUrl |
    ReceivedSuiteUrl | CompletedSuiteUrl;

type KnownActions = DispatchActions | NotificationAction;

export const actionCreators = {

    getWidgetMenuItems: (): AppThunkAction<KnownActions> =>

        (dispatch, getState) => {
            dispatch({
                type: menuActionTypes.REQUEST_WIDGET_MENU_ITEMS,
            });

            let fetchTask = fetch(`${apiPrefix}menu/widget-menu/${Product.Organizers}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response)
                .then((data) => {
                    dispatch({
                        type: menuActionTypes.RECEIVED_WIDGET_MENU_ITEMS,
                        payload: data
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                })
                .finally(() => {
                    dispatch({
                        type: menuActionTypes.COMPLETED_WIDGET_MENU_ITEMS,
                    });
                });
            addTask(fetchTask);
        },
    fetchSuiteUrl: (): AppThunkAction<KnownActions> =>

        (dispatch, getState) => {
            dispatch({
                type: menuActionTypes.REQUEST_SUITE_URL,
            });

            let fetchTask = fetch(`${apiPrefix}helper/suite-url`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(response => response.text())
                .then((data: string) => {
                    dispatch({
                        type: menuActionTypes.RECEIVED_SUITE_URL,
                        payload: data
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                })
                .finally(() => {
                    dispatch({
                        type: menuActionTypes.COMPLETED_SUITE_URL,
                    });
                });
            addTask(fetchTask);
        }

}

export const reducer: Reducer<IWidgetMenuState> = (
    (widgetMenuState: IWidgetMenuState = IntialWidgetMenuState, incomingAction: Action) => {
        let newWidgetMenuState = { ...widgetMenuState }
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case menuActionTypes.REQUEST_WIDGET_MENU_ITEMS:
                newWidgetMenuState.isLoading = true;
                return newWidgetMenuState;
            case menuActionTypes.RECEIVED_WIDGET_MENU_ITEMS:
                newWidgetMenuState.widgetMenu = action.payload;
                return newWidgetMenuState;
            case menuActionTypes.COMPLETED_WIDGET_MENU_ITEMS:
                newWidgetMenuState.isLoading = false;
                return newWidgetMenuState;
            case menuActionTypes.REQUEST_SUITE_URL:
                newWidgetMenuState.isLoading = true;
                return newWidgetMenuState;
            case menuActionTypes.RECEIVED_SUITE_URL:
                newWidgetMenuState.suiteUrl = action.payload;
                return newWidgetMenuState;
            case menuActionTypes.COMPLETED_SUITE_URL:
                newWidgetMenuState.isLoading = false;
                return newWidgetMenuState;
            default:
                return newWidgetMenuState;
        }
    }
);