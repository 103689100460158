import { ApplicationState } from "../store";
import React from 'react'
import { useSelector } from "react-redux";
import { Route } from "react-router-dom"
import LoadingScreen from "./common/LoadingScreen";
import { OrganizerRoute } from '../store/productMenu/model/ProductMenuStoreModel'
import NoPermissionButProductAcess from "./Authorization/NoPermissionButProductAccess";
import CommonFeatureDisabled from "./Authorization/CommonFeatureDisabled";
interface RouteProps {
    component: React.ComponentType<any>
    path: string
    routes: OrganizerRoute[]
    checkPath?: string
    skipAuthorization?: boolean
}

const RBACRoute: React.FC<RouteProps> = ({ component, path, checkPath, routes, skipAuthorization, ...rest }): any => {
    const isLoading = useSelector((state: ApplicationState) => state.productMenuControl.isLoading);
    const isRoutesEmpty = useSelector((state: ApplicationState) => state.productMenuControl.isRoutesEmpty);
    const isHideLeftMenu = useSelector((state: ApplicationState) => state.productMenuControl.hideLeftmenu);
    const isCompanyOrganizerProductEnabled = useSelector((state: ApplicationState) => state.companyData.isCompanyOrganizerProductEnabled);
    const isCompanyOrganizerSubscriptionEnabled = useSelector((state: ApplicationState) => state.companyData.isCompanyOrganizerSubscriptionEnabled);
    const isSkip = skipAuthorization !== undefined && skipAuthorization;
    const accessibleProducts = useSelector((state: ApplicationState) => state.AccessibleProductData);
    const checkRouteAuthorization = (): boolean => {
        if (routes.length > 0) {
            const route = checkPath !== null && checkPath !== undefined ? checkPath : path;
            return routes.some(x => x.route === route && x.isEnabled)
        }
        return false;
    }

    if (isLoading || (!isRoutesEmpty && routes.length == 0)) {
        return isHideLeftMenu ? <></> : <LoadingScreen />
    }
    else if (!isCompanyOrganizerSubscriptionEnabled || !isCompanyOrganizerProductEnabled || !accessibleProducts.isInitialState && !accessibleProducts.isAccessible) {
        return <Route exact path={path} component={CommonFeatureDisabled} {...rest} />
    }
    else if (isSkip || checkRouteAuthorization()) {
        return <Route exact path={path} component={component} {...rest} />
    }
    else {
        return <Route exact path={path} component={NoPermissionButProductAcess} {...rest} />
    }
}

export default RBACRoute;