import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse, handleBlobwithFileName } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes'
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    ReceiveDeliveredOrganizerDocumentsAction,
    RequestDeliveredOrganizerDocumentsAction,
    OrganizerTableModel,
    DeliveredOrganizerStoreState,
    unloadedDeliveredOrganizerStoreState,
    SendReminderOrganizerAction,
    ResendAccessLinkOrganizerAction,
    apiPrefix,
    ReceiveOrganizerClient,
    RequestOrganizerClient,
    OrganizerPopupLoaderClose,
    OrganizerPopupLoaderShow,
    OrganizerClientsSaved,
    ReceiveOrganizerClientTracking,
    DownloadFilledOrganizerAction,
    DownloadEngagementLetterAction,
    DownloadSourceFileAction,
    ReceiveSourceFilesAction,
    ReceiveOrganizerAccessCodeAction,
    GenerateOTPAction,
    RequestOrganizerAccessCodeAction,
    ReceiveDownloadableDocumentsAction,
    UpdateOrganizerDownloadDetailsAction,
    UpdateEngagementDownloadDetailsAction,
    UpdateSourceDownloadDetailsAction,
    UnlockOrganizerAction,
    SetSourceDocumentMetaData,
    UpdateAutoReminderOrganizerAction,
    UpdateBulkAutoReminderOrganizerAction
} from './../models/OrganizerStoreModels';
import {
    BulkOperationAutoReminderRequest,
    DownloadableDocuments,
    DownloadDocumentType,
    EngagementLetterStatus,
    OrganizerClient, OrganizerClientType,
    OrganizerDocument,
    OrganizerStatus,
    OrganizerTransaction,
    SourceDocumentStatus
} from './../models/OrganizerComponentModels';
import { ApiActionName, ControllerName, StoreConstants } from './../components/Helper/OrganizerConstants';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { IAccessViewModel, ClientTypes } from '../../components/common/TaxReturn';
import * as moment from 'moment';
import { container } from '../../Startup/inversify.config';
import { IDialogBox } from '../../Core/Utilities/UI/DialogBox';
import { TYPES } from '../../Startup/types';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import * as  OrganizerStore from '../../Organizer/store/OrganizerStore';
import { BaseDocumentViewModel } from '../viewmodels/DocumentViewModel';
import { AutomaticReminderViewModel } from '../viewmodels/AutomaticReminderViewModel';

const logger = new LoggerFactory().getTelemetryLogger();
type KnownActions =
    UpdateOrganizerDownloadDetailsAction |
    UpdateEngagementDownloadDetailsAction |
    UpdateSourceDownloadDetailsAction |
    ReceiveDownloadableDocumentsAction |
    ReceiveOrganizerClient |
    RequestOrganizerClient |
    ReceiveDeliveredOrganizerDocumentsAction |
    RequestDeliveredOrganizerDocumentsAction |
    SendReminderOrganizerAction |
    ResendAccessLinkOrganizerAction |
    OrganizerPopupLoaderClose |
    OrganizerPopupLoaderShow |
    OrganizerClientsSaved |
    ReceiveOrganizerClientTracking |
    DownloadFilledOrganizerAction |
    DownloadEngagementLetterAction |
    DownloadSourceFileAction |
    ReceiveSourceFilesAction |
    RequestOrganizerAccessCodeAction |
    ReceiveOrganizerAccessCodeAction |
    GenerateOTPAction |
    UnlockOrganizerAction |
    NotificationAction |
    SetSourceDocumentMetaData |
    UpdateAutoReminderOrganizerAction |
    UpdateBulkAutoReminderOrganizerAction;

let deliveredOrganizerReportAbortController = new AbortController();

const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);


export const actionCreators = {
    requestDeliveredOrganizers: (query: string, callback?: any): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.REQUEST_DELIVERED_ORGANIZER_DOCUMENTS,
            loading: true
        });
        deliveredOrganizerReportAbortController.abort();
        deliveredOrganizerReportAbortController = new AbortController();
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/${query}`, {
            method: 'GET',
            credentials: 'include',
            signal: deliveredOrganizerReportAbortController.signal
        })
            .then(handleResponse)
            .then(response => response as OrganizerTableModel)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_DELIVERED_ORGANIZER_DOCUMENTS,
                    loading: false,
                    model: data,
                    query: query
                });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                if (error.name === 'AbortError') {
                    if (callback) {
                        callback();
                    }
                    return;
                }
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AllOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestDeliveredOrganizers failed for the request having parameters ${query} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    exportOrganizersAsExcel: (query: string, url: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${url}/${query}`,
            { method: 'GET', credentials: 'include' })
            .then(response => handleBlobwithFileName(response))
            .then(data => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, data.filename);
                if (callback) callback();
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Export, statusType: StatusType.Error, statusCode: error?.status });
                if (callback) {
                    callback();
                }
                logger?.trackError(`exportOrganizersAsExcel failed for the request having parameters ${query} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    //deleteOrganizer: (id: number, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
    //    let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/${id}`, {
    //        method: 'DELETE',
    //        credentials: 'include'
    //    })
    //        .then(response => {
    //            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.Delete, statusType: StatusType.Success });
    //            callback();
    //        }).catch((error) => {
    //            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Delete, statusType: StatusType.Error });
    //            logger?.trackError(`deleteOrganizer failed for the request having parameters ${id} with error ${error.message}`)

    //        });
    //    addTask(fetchTask);
    //},

    unlockDocument: (documentid: number, url: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${url}/${documentid}`, {
            method: 'PUT',
            credentials: 'include',
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.UnlockOrganizer, statusType: StatusType.Success });
                dispatch({ type: actionTypes.UNLOCK_LOCKED_DOCUMENT, id: documentid });

                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`unlockDocument failed for the request having parameters${documentid} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    generateClientView: (doucment: OrganizerDocument, clientType: OrganizerClientType, url: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let action: any = OrganizerStore.actionCreator.generateTaxpayerClientView(doucment, clientType, url, callback);
        dispatch(action);
    },

    resendAccessLink: (id: number, url: string, model: OrganizerClient): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let fetchTask = fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(model),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.ResendAccessLink, statusType: StatusType.Success });
                dispatch({ type: actionTypes.RESEND_ACCESS_LINK_ORGANIZER, id: id });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.ResendAccessLink, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`resendAccessLink failed for the request having parameters${id} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    onReopenOrganizerModalConfirmClick: (documentId: number, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/ReopenOrganizer/${documentId}`, {
            method: 'PUT',
            credentials: 'include',
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.ReopenOrganizer, statusType: StatusType.Success });
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.ReopenOrganizer, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`Reopen Organizer failed for the request having parameters${documentId} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    onCloseOrganizerModalConfirmClick: (processId: number, isArchived: boolean, isClosed: boolean, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let controllerName = isArchived ? ControllerName.ArchivedOrganizerController : ControllerName.DeliveredOrganizerController;
        let actionName = isClosed ? ApiActionName.ReopenIndividualOrganizerByFirm : ApiActionName.CloseIndividualOrganizerByFirm;
        let url = `${apiPrefix}${controllerName}/${actionName}/${processId}`;
        let fetchTask = fetch(url, {
            method: 'PUT',
            credentials: 'include',
        })
            .then(handleResponse)
            .then((response) => {
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: isClosed ? StoreConstants.Success.ReopenOrganizerByFirm : StoreConstants.Success.CloseOrganizerByFirm, statusType: StatusType.Success
                });
                callback();
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: isClosed ? StoreConstants.Failure.ReopenOrganizerByFirm : StoreConstants.Failure.CloseOrganizerByFirm, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`Close Organizer failed for the request having parameters${processId} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    sendReminder: (ids: number[], callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}Mail/SendReminder`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(ids),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then((response) => {
                callback();
                dispatch({ type: actionTypes.SEND_REMINDER_ORGANIZER, ids: ids });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.SendReminder, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SendReminder, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`sendReminder failed for the request having parameters ${JSON.stringify(ids)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestOrganizerClients: (id: number, callback?: (clients: OrganizerClient[]) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_ORGANIZER_DOCUMENT_CLIENTS, loading: true });
        let fetchTask = fetch(`${apiPrefix}Details/Clients/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerClient[])
            .then((data) => {
                dispatch({ type: actionTypes.RECEIVE_ORGANIZER_DOCUMENT_CLIENTS, id: id, clients: data, loading: false });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.OrganizerClients, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`requestOrganizerClients failed for the request having parameters ${id} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestDeliveryStatus: (id: number, callback?: (clients: OrganizerClient[]) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.REQUEST_ORGANIZER_DOCUMENT_CLIENTS, loading: true });
        let fetchTask = fetch(`${apiPrefix}Details/GetDeliveryStatus/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerClient[])
            .then((data) => {
                dispatch({ type: actionTypes.RECEIVE_ORGANIZER_DOCUMENT_CLIENTS, id: id, clients: data, loading: false });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.OrganizerClients, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`requestDeliveryStatus failed for the request having parameters ${id} with error ${error.message}`)
                dispatch({
                    type: actionTypes.REQUEST_ORGANIZER_DOCUMENT_CLIENTS,
                    loading: false
                });
            });
        addTask(fetchTask);
    },

    saveClientInfo: (url: string, clientInfo: OrganizerClient[], clientId: string, documentId: number, callback: () => void, resendEmail: boolean | false, isTpEmailupdated?: boolean | false, isSpouseEmailupdated?: boolean | false):
        AppThunkAction<KnownActions> => (dispatch, getState) => {
            dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
            const formData = JSON.stringify({
                documentId: documentId,
                clientId: clientId,
                resendEmail: resendEmail,
                isTpEmailupdated: isTpEmailupdated,
                isSpouseEmailupdated: isSpouseEmailupdated,
                clientInfo: clientInfo
            });
            let fetchTask = fetch(url, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleResponse)
                .then((data) => {
                    const taxpayer = clientInfo.find(client => client.clientType == OrganizerClientType.Taxpayer);
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.ClientInfo, statusType: StatusType.Success });
                    dispatch({ type: actionTypes.ORGANIZER_DOCUMENT_CLIENTS_SAVED, clientId: clientId, name: taxpayer ? taxpayer.name : "", documentId: documentId, clients: clientInfo })
                    dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                    callback();
                })
                .catch(error => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.ClientInfo, statusType: StatusType.Error, statusCode: error?.status });
                    logger?.trackError(`saveClientInfo failed for the request having parameters ${documentId}/${clientId}/${resendEmail} with error ${error.message}`)
                });
            addTask(fetchTask);
        },

    requestOrganizerClientTracking: (documentId: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/GetOrganizerClientTracking/${documentId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as OrganizerTransaction[])
            .then((data) => {
                dispatch({ type: actionTypes.RECEIVE_ORGANIZER_CLIENT_TRACKING, id: documentId, data: data });
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.ClientTracking,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestOrganizerClientTracking failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    downloadFilledOrganizer: (documentGuid: string, year: number, clientId: string, log: boolean, callback?: (data: any) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        const appState = getState();
        let fetchTask = fetch(`${apiPrefix}Document/GetFilledOrganizer/${documentGuid}/${year}/${log}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleBlobwithFileName)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, `${year}_${clientId}_Organizer.pdf`);
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                dispatch({ type: actionTypes.FILLED_ORGANIZER_DOWNLOAD, id: documentGuid });
                if (log) {
                    dispatch({
                        type: actionTypes.UPDATE_ORGANIZER_DOWNLOAD_DETAILS,
                        id: documentGuid,
                        userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                    });
                }
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.FilledOrganizer,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`downloadFilledOrganizer failed for the request having parameters ${documentGuid}/${year}/${log} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    downloadSignedEngagementLetter: (documentGuid: string, year: number, clientId: string, log: boolean, callback?: (data: any) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        const appState = getState();
        let fileName: string;

        let fetchTask = fetch(`${apiPrefix}Document/GetSignedEngagementLetter/${documentGuid}/${year}/${log}`, {
            method: 'GET',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json;utf-8' }
        })
            .then(handleBlobwithFileName)
            .then((data: any) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, data.filename);
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                dispatch({ type: actionTypes.SIGNED_ENGAGEMENTLETTER_DOWNLOAD, id: documentGuid });
                if (log) {
                    dispatch({
                        type: actionTypes.UPDATE_ENGAGEMENT_DOWNLOAD_DETAILS,
                        id: documentGuid,
                        userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                    });
                }
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.SignedEngagementLetter,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`downloadSignedEngagementLetter failed for the request having parameters ${documentGuid}/${year}/${log} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    downloadSourceDocument: (documentId: number, fileName: string, documentGuid: string, year: number, clientId: string, callback?: (data: any) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        const appState = getState();
        let fetchTask = fetch(`${apiPrefix}Document/GetSourceDocument/${documentId}/${fileName}/${documentGuid}/${year}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleBlobwithFileName)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, `${year}_${clientId}_${data.filename}`);
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                dispatch({
                    type: actionTypes.UPDATE_SOURCE_DOWNLOAD_DETAILS,
                    fileName: fileName,
                    id: documentId,
                    userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                });
                dispatch({ type: actionTypes.SOURCE_FILE_DOWNLOAD, id: documentGuid });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SourceDocument, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`downloadSourceDocument failed for the request having parameters ${documentId}/${fileName}/${documentGuid}/${year} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    downloadAll: (
        apiUrl: string,
        documentId: number, documentGuid: string, year: number,
        clientId: string, logOrganizer: boolean,
        logEngage: boolean, fileNames: string[],
        callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
            dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
            const appState = getState();
            const formData = JSON.stringify({
                documentId: documentId,
                documentGuid: documentGuid,
                year: year,
                clientId: clientId,
                logOrganizer: logOrganizer,
                logEngage: logEngage,
                fileNames: fileNames
            });

            let fetchTask = fetch(apiPrefix + apiUrl, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    if (data.blob.type.indexOf("application/json") !== -1) {
                        dialogBox.alert(StoreConstants.Success.DownloadAllNotification);
                    }
                    else {
                        let displayDownloadFile = new DisplayDownloadFile();
                        displayDownloadFile.showFile(data.blob, `${clientId}_${year}_${data.filename}`);
                        if (logOrganizer) {
                            dispatch({ type: actionTypes.FILLED_ORGANIZER_DOWNLOAD, id: documentGuid });
                            dispatch({
                                type: actionTypes.UPDATE_ORGANIZER_DOWNLOAD_DETAILS,
                                id: documentGuid,
                                userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                            });
                        }

                        if (logEngage) {
                            dispatch({ type: actionTypes.SIGNED_ENGAGEMENTLETTER_DOWNLOAD, id: documentGuid });
                            dispatch({
                                type: actionTypes.UPDATE_ENGAGEMENT_DOWNLOAD_DETAILS,
                                id: documentGuid,
                                userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                            });
                        }

                        if (fileNames && fileNames.length) {
                            fileNames.forEach(fileName => {
                                dispatch({
                                    type: actionTypes.UPDATE_SOURCE_DOWNLOAD_DETAILS,
                                    fileName: fileName,
                                    id: documentId,
                                    userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                                });
                            });
                            dispatch({ type: actionTypes.SOURCE_FILE_DOWNLOAD, id: documentGuid });
                        }
                    }
                    dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.DownloadAll,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`downloadAll failed for the request having parameters ${documentId}/${documentGuid}/${year}/${encodeURI(clientId)}/${logOrganizer}/${logEngage} with error ${error.message}`)
                });
            addTask(fetchTask);
        },

    downloadSourceDocuments: (
        apiUrl: string,
        documentId: number,
        year: number,
        clientId: string,
        fileNames: string[], documentGuid: string,
        isDownloadAllSourceDocuments: boolean): AppThunkAction<KnownActions> => (dispatch, getState) => {
            dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
            const appState = getState();
            const payloadData = JSON.stringify({
                "documentId": documentId,
                "isDownloadAllSourceDocuments": isDownloadAllSourceDocuments
            });
            let fetchTask = fetch(apiPrefix + apiUrl, {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body: payloadData
            }).then(handleBlobwithFileName)
                .then((data) => {
                    if (data.blob.type.indexOf("application/json") !== -1) {
                        dialogBox.alert(StoreConstants.Success.DownloadAllNotification);
                    }
                    else {
                        let displayDownloadFile = new DisplayDownloadFile();
                        displayDownloadFile.showFile(data.blob, data.filename);
                        if (fileNames && fileNames.length) {
                            fileNames.forEach(fileName => {
                                dispatch({
                                    type: actionTypes.UPDATE_SOURCE_DOWNLOAD_DETAILS,
                                    fileName: fileName,
                                    id: documentId,
                                    userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                                });
                            });
                            dispatch({ type: actionTypes.SOURCE_FILE_DOWNLOAD, id: documentGuid });
                        }
                    }
                    dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });

                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.DownloadAll,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`Download Source Documents failed for the request having parameters ${documentId}/${documentGuid}/${year}/${encodeURI(clientId)} with error ${error.message}`);
                });
            addTask(fetchTask);
        },
    globalDownloadAll: (
        apiUrl: string,
        documentId: number, documentGuid: string, year: number,
        clientId: string, logOrganizer: boolean,
        logEngage: boolean, fileNames: string[],
        callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
            dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
            const appState = getState();

            const formData = JSON.stringify({
                documentId: documentId,
                documentGuid: documentGuid,
                taxyear: year,
                clientId: clientId,
                isLogOrganizer: logOrganizer,
                isLogEngagementLetter: logEngage
            });

            let fetchTask = fetch(apiPrefix + apiUrl, {
                method: 'POST',
                credentials: 'include',
                body: formData,
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleBlobwithFileName)
                .then((data) => {
                    if (data.blob.type.indexOf("application/json") !== -1) {
                        dialogBox.alert(StoreConstants.Success.DownloadAllNotification);
                    }
                    else {
                        let displayDownloadFile = new DisplayDownloadFile();
                        displayDownloadFile.showFile(data.blob, `${clientId}_${year}_${data.filename}`);
                        if (logOrganizer) {
                            dispatch({ type: actionTypes.FILLED_ORGANIZER_DOWNLOAD, id: documentGuid });
                            dispatch({
                                type: actionTypes.UPDATE_ORGANIZER_DOWNLOAD_DETAILS,
                                id: documentGuid,
                                userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                            });
                        }

                        if (logEngage) {
                            dispatch({ type: actionTypes.SIGNED_ENGAGEMENTLETTER_DOWNLOAD, id: documentGuid });
                            dispatch({
                                type: actionTypes.UPDATE_ENGAGEMENT_DOWNLOAD_DETAILS,
                                id: documentGuid,
                                userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                            });
                        }

                        if (fileNames && fileNames.length) {
                            dispatch({ type: actionTypes.SOURCE_FILE_DOWNLOAD, id: documentGuid });
                            fileNames.forEach(fileName => {
                                dispatch({
                                    type: actionTypes.UPDATE_SOURCE_DOWNLOAD_DETAILS,
                                    fileName: fileName,
                                    id: documentId,
                                    userName: `${appState.userProfile.firstName} ${appState.userProfile.lastName}`
                                });
                            });
                        }

                    }
                    dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.DownloadAll,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`downloadAll failed for the request having parameters ${documentId}/${documentGuid}/${year}/${encodeURI(clientId)}/${logOrganizer}/${logEngage} with error ${error.message}`)
                });
            addTask(fetchTask);
        },

    getSourceFiles: (documentId: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/GetSourceFiles/${documentId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as string[])
            .then((data) => {
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
                dispatch({ type: actionTypes.RECEIVE_SOURCE_FILES, id: documentId, files: data });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.SourceFiles,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`getSourceFiles failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    getDownloadableDocuments: (documentId: number, clientId: string, batchId: string, batchName: string, taxYear: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/GetDownloadableDocuments/${documentId}/${encodeURIComponent(clientId)}/${batchId}/${encodeURIComponent(batchName)}/${taxYear}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as DownloadableDocuments[])
            .then((data) => {
                dispatch({
                    type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE,
                    loading: false
                });
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_DOWNLOADABLE_DOCUMENTS,
                    id: documentId,
                    documents: data
                });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DownloadDocuments,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`getDownloadableDocuments failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    requestOrganizerAccessCode: (documentId: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let fetchTask = fetch(`${apiPrefix}Document/AccessCode/${documentId}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as Promise<IAccessViewModel>)
            .then(data => {
                dispatch({ type: actionTypes.RECIEVE_ORGANIZER_ACCESSCODE, id: documentId, accessCodeViewModel: data, loading: false });
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.AccessCode, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`requestOrganizerAccessCode failed for the request having parameters ${documentId} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    generateOTP: (documentId: number, clientGUID: string, url: string, callBack?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(url, {
            method: 'PUT',
            credentials: 'include'
        })
            .then(handleResponse)
            .then((response) => {
                let action: any = actionCreators.requestOrganizerAccessCode(documentId);
                dispatch(action);
                dispatch({ type: actionTypes.GENERATE_ORGANIZER_ACCESSCODE, id: documentId });
                if (callBack) {
                    callBack();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SaveAccessCode, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`generateOTP failed for the request having parameters ${documentId}/${clientGUID} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    getSourceDocumentMetadataAsync: (fileName: string, documentGuid: string, year: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_SHOW, loading: true });
        let fetchTask = fetch(`${apiPrefix}Document/GetSourceDocumentMetadataAsync/${fileName}/${documentGuid}/${year}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as any)
            .then((data) => {
                dispatch({
                    type: actionTypes.SET_SOURCE_DOCUMENT_META_DATA,
                    sourceDocumentMetaData: data
                })
                dispatch({ type: actionTypes.ORGANIZER_POPUP_LOADER_CLOSE, loading: false });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.ClientTracking,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`getSourceDocumentMetadataAsync failed for the request having parameters ${fileName},${documentGuid} and ${year} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateStatusToManual: (selectedDocuments: BaseDocumentViewModel[], updateEngagementLetter: boolean, updateOrganizer: boolean, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const formData = JSON.stringify({
            ProcessList: selectedDocuments,
            updateEngagementLetter: updateEngagementLetter,
            updateOrganizer: updateOrganizer
        });
        let fetchTask = fetch(`${apiPrefix}Document/UpdateStatusToManual`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(() => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.ManualCompletion, statusType: StatusType.Success });
                callback();
            }).catch((error) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.ManualCompletionFailed, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`UpdateStatusToManual failed for the request having parameters ${JSON.stringify(selectedDocuments.map(d => d.id))} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    archiveOrganizers: (documentIds: number[], callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/ArchiveOrganizer`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(documentIds),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(response => response)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.ArchiveOrganizers,
                    statusType: StatusType.Success
                });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.ArchiveOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`archiveOrganizers failed for the request having parameters ${documentIds} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    closeOpenOrganizers: (documentIds: number[], isArchived: boolean, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let controllerName = isArchived ? ControllerName.ArchivedOrganizerController : ControllerName.DeliveredOrganizerController;
        let url = `${apiPrefix}${controllerName}/${ApiActionName.CloseOpenOrganizersByFirmInBulk}`
        let fetchTask = fetch(url, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(documentIds),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(response => response)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.CloseOpenOrganizersByFirm,
                    statusType: StatusType.Success
                });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.CloseOpenOrganizersByFirm,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`CloseOpenOrganizersByFirm failed for the request having parameters ${documentIds} with error ${error.message}`)
                callback();
            });
        addTask(fetchTask);
    },

    restoreArchivedOrganizers: (documentIds: number[], callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/RestoreArchivedOrganizers`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(documentIds),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(response => response)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.RestoreOrganizers,
                    statusType: StatusType.Success
                });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.RestoreOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`restoreArchivedOrganizers failed for the request having parameters ${documentIds} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    reopenSourceDocumentUpload: (documentid: number, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}DeliveredOrganizer/ReopenSourceDocumentUpload/${documentid}`, {
            method: 'PUT',
            credentials: 'include',
        })
            .then(handleResponse)
            .then(() => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.ReopenCompletedDocument, statusType: StatusType.Success });
                if (callback) {
                    callback();
                }
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.ReopenCompletedDocument, statusType: StatusType.Error, statusCode: error?.status
                });
                logger?.trackError(`ReopenCompletedDocument failed for the request having parameters${documentid} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    refreshDeliveredOrganizers: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch(actionCreators.requestDeliveredOrganizers(getState().deliveredOrganizerReports.query));
    },

    updateAutoReminder: (automaticReminder: AutomaticReminderViewModel, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        if (automaticReminder.reminderOrganizerList.length) {
            let fetchTask = fetch(`${apiPrefix}AutomaticReminder/UpdateAutomaticReminder`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(automaticReminder),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then(handleResponse)
                .then((response) => {
                    callback();
                    dispatch({ type: actionTypes.UPDATE_AUTOMATIC_REMINDER });
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.UpdateAutoReminder, statusType: StatusType.Success });
                })
                .catch(error => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.UpdateAutoReminderFailed, statusType: StatusType.Error, statusCode: error?.status });
                    logger?.trackError(`autodReminder update failed for the request having parameters ${JSON.stringify(automaticReminder)} with error ${error.message}`)
                });
            addTask(fetchTask);
        } else {
            dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.UpdateAutoReminder, statusType: StatusType.Success });
        }
    },
    updateBulkAutoReminder: (bulkAutoReminderRequest: BulkOperationAutoReminderRequest, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}AutomaticReminder/UpdateBulkAutomaticReminder`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(bulkAutoReminderRequest),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then((response) => {
                callback();
                dispatch({ type: actionTypes.UPDATE_BULK_AUTOMATIC_REMINDER });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.UpdateAutoReminder, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.UpdateAutoReminderFailed, statusType: StatusType.Error, statusCode: error?.status });
                logger?.trackError(`bulk autodReminder update failed for the request having parameters ${JSON.stringify(bulkAutoReminderRequest)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
    recycleOrganizers: (apiUrl: string, documentIds: number[], callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(apiPrefix + apiUrl, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(documentIds),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(response => response)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.RecycleOrganizers,
                    statusType: StatusType.Success
                });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.RecycleOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`recycleOrganizers failed for the request having parameters ${documentIds} with error ${error.message}`)
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<DeliveredOrganizerStoreState> = (state: DeliveredOrganizerStoreState = unloadedDeliveredOrganizerStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {

        case actionTypes.REQUEST_DELIVERED_ORGANIZER_DOCUMENTS:
            return {
                ...unloadedDeliveredOrganizerStoreState,
                loading: action.loading
            }
        case actionTypes.RECEIVE_DELIVERED_ORGANIZER_DOCUMENTS:
            return {
                ...state,
                model: action.model,
                loading: action.loading,
                query: action.query
            }
        case actionTypes.SEND_REMINDER_ORGANIZER: {
            const lastReminderState = { ...state };
            lastReminderState.model.documents = lastReminderState.model.documents.map(org => {
                if (action.ids.indexOf(org.id) < 0)
                    return org
                return {
                    ...org,
                    lastReminder: new Date()
                }
            });
            return lastReminderState;
        }
        case actionTypes.FILLED_ORGANIZER_DOWNLOAD: {
            const downloadState = { ...state };
            downloadState.model.documents = downloadState.model.documents.map(org => {
                if (action.id === org.processInfoGuid &&
                    org.organizerStatus === OrganizerStatus.Completed) {
                    return {
                        ...org,
                        organizerStatus: OrganizerStatus.OrganizerDownloaded
                    }
                }
                return org;
            });
            return downloadState;
        }

        case actionTypes.SIGNED_ENGAGEMENTLETTER_DOWNLOAD: {
            const downloadState = { ...state };
            downloadState.model.documents = downloadState.model.documents.map(org => {
                if (action.id === org.processInfoGuid &&
                    org.engagementLetterStatus === EngagementLetterStatus.ESigned) {
                    return {
                        ...org,
                        engagementLetterStatus: EngagementLetterStatus.EngagementLetterDownloaded
                    }
                }
                return org;
            });
            return downloadState;
        }

        case actionTypes.SOURCE_FILE_DOWNLOAD: {
            const downloadState = { ...state };
            downloadState.model.documents = downloadState.model.documents.map(org => {
                if (action.id !== org.processInfoGuid) {
                    return org;
                }
                else if (org.uploadedSourceDocumentsCount > 0
                    && org.uploadedSourceDocumentsCount == org.downloadedSourceDocumentsCount) {
                    return {
                        ...org,
                        sourceDocumentStatus: SourceDocumentStatus.Downloaded
                    }
                } else {
                    return org;
                }
            });
            return downloadState;
        }

        case actionTypes.RECEIVE_SOURCE_FILES: {
            const downloadState = { ...state };
            downloadState.model.documents = downloadState.model.documents.map(org => {
                if (action.id !== org.id)
                    return org;
                return {
                    ...org,
                    uploadedFileNames: action.files
                }
            });
            return downloadState;
        }

        case actionTypes.RESEND_ACCESS_LINK_ORGANIZER:
            const resendAccessLink = { ...state };
            resendAccessLink.model.documents = resendAccessLink.model.documents.map(org => {
                if (action.id !== org.id)
                    return { ...org }
                return {
                    ...org
                }
            });
            return resendAccessLink;

        case actionTypes.UNLOCK_LOCKED_DOCUMENT:
            const UnlockOrganizer = { ...state };
            UnlockOrganizer.model.documents = UnlockOrganizer.model.documents.map(org => {
                if (action.id !== org.id)
                    return { ...org }
                return {
                    ...org
                }
            });
            return UnlockOrganizer;

        case actionTypes.RECEIVE_ORGANIZER_DOCUMENT_CLIENTS:
            const clients = { ...state };
            clients.model.documents = clients.model.documents.map(org => {
                if (org.id === action.id) {
                    return {
                        ...org,
                        organizerClients: action.clients
                    }
                }
                return org;
            });
            clients.popupLoading = false;
            return clients;

        case actionTypes.REQUEST_ORGANIZER_DOCUMENT_CLIENTS:
            return {
                ...state,
                popupLoading: action.loading
            }

        case actionTypes.ORGANIZER_POPUP_LOADER_SHOW:
            return {
                ...state,
                popupLoading: action.loading
            }

        case actionTypes.ORGANIZER_POPUP_LOADER_CLOSE:
            return {
                ...state,
                popupLoading: action.loading
            }

        case actionTypes.ORGANIZER_DOCUMENT_CLIENTS_SAVED:
            const clientSaved = { ...state };
            clientSaved.model.documents = clientSaved.model.documents.map(org => {
                if (org.id === action.documentId) {
                    return {
                        ...org,
                        clientId: action.clientId,
                        taxpayerName: action.name,
                        taxpayerEmail: action.clients[0]?.email,
                        spouseEmail: action.clients[1]?.email,
                        organizerClients: action.clients

                    }
                }
                return org;
            });
            return clientSaved;

        case actionTypes.RECEIVE_ORGANIZER_CLIENT_TRACKING:
            const clientTracking = { ...state };
            clientTracking.model.documents = clientTracking.model.documents.map(org => {
                if (org.id === action.id) {
                    return {
                        ...org,
                        clientTracking: action.data
                    }
                }
                return org;
            });
            return clientTracking;


        case actionTypes.RECIEVE_ORGANIZER_ACCESSCODE:
            const accessCode = { ...state };
            accessCode.model.documents = accessCode.model.documents.map(org => {
                if (org.id === action.id) {
                    return {
                        ...org,
                        accessCode: action.accessCodeViewModel
                    }
                }
                return org;
            });
            return accessCode;

        case actionTypes.GENERATE_ORGANIZER_ACCESSCODE:
            const newAccessCode = { ...state };
            newAccessCode.model.documents = newAccessCode.model.documents.map(org => {
                if (action.id !== org.id)
                    return { ...org }
                return {
                    ...org
                }
            });
            return newAccessCode;

        case actionTypes.RECEIVE_ORGANIZER_DOWNLOADABLE_DOCUMENTS: {
            const documents = { ...state };
            documents.model.documents = documents.model.documents.map(org => {
                if (org.id === action.id)
                    return { ...org, downloadDocuments: action.documents }
                return { ...org };
            });
            return documents;
        }

        case actionTypes.UPDATE_ORGANIZER_DOWNLOAD_DETAILS: {
            const orgDetails = { ...state };
            orgDetails.model.documents = orgDetails.model.documents.map(org => {
                if (org.processInfoGuid === action.id) {
                    if (!org.downloadDocuments ||
                        !org.downloadDocuments.length) {
                        org.downloadDocuments = [];
                    }
                    const found = org.downloadDocuments.find(doc => doc.type === DownloadDocumentType.Organizer);
                    if (!found) {
                        const downloadDoc: DownloadableDocuments = {
                            downloadBy: action.userName,
                            downloadDate: moment.utc(new Date()).format() as any,
                            fileName: 'Organizer',
                            type: DownloadDocumentType.Organizer,
                            uploadedOn: undefined
                        };
                        org.downloadDocuments.push(downloadDoc);
                    } else {
                        org.downloadDocuments = org.downloadDocuments.map(doc => {
                            if (doc.type === DownloadDocumentType.Organizer) {
                                doc.downloadBy = action.userName;
                                doc.downloadDate = moment.utc(new Date()).format() as any;
                            }
                            return { ...doc };
                        })
                    }
                    return { ...org };
                }
                return { ...org };
            });
            return orgDetails;
        }

        case actionTypes.UPDATE_ENGAGEMENT_DOWNLOAD_DETAILS: {
            const engDetails = { ...state };
            engDetails.model.documents = engDetails.model.documents.map(org => {
                if (org.processInfoGuid === action.id) {
                    if (!org.downloadDocuments ||
                        !org.downloadDocuments.length) {
                        org.downloadDocuments = [];
                    }
                    const found = org.downloadDocuments.find(doc => doc.type === DownloadDocumentType.EngagementLetter);
                    if (!found) {
                        const downloadDoc: DownloadableDocuments = {
                            downloadBy: action.userName,
                            downloadDate: moment.utc(new Date()).format() as any,
                            fileName: 'EnagagementLetter',
                            type: DownloadDocumentType.EngagementLetter,
                            uploadedOn: undefined
                        };
                        org.downloadDocuments.push(downloadDoc);
                    } else {
                        org.downloadDocuments = org.downloadDocuments.map(doc => {
                            if (doc.type === DownloadDocumentType.EngagementLetter) {
                                doc.downloadBy = action.userName;
                                doc.downloadDate = moment.utc(new Date()).format() as any;
                            }
                            return { ...doc };
                        })
                    }
                    return { ...org };
                }
                return { ...org };
            });
            return engDetails;
        }

        case actionTypes.UPDATE_SOURCE_DOWNLOAD_DETAILS: {
            const sourceDetails = { ...state };
            sourceDetails.model.documents = sourceDetails.model.documents.map(org => {
                if (org.id === action.id) {
                    if (!org.downloadDocuments ||
                        !org.downloadDocuments.length) {
                        org.downloadDocuments = [];
                    }
                    const found = org.downloadDocuments.find(doc => doc.type === DownloadDocumentType.SourceDocument &&
                        doc.fileName === action.fileName);
                    if (!found) {
                        const downloadDoc: DownloadableDocuments = {
                            downloadBy: action.userName,
                            downloadDate: moment.utc(new Date()).format() as any,
                            fileName: action.fileName,
                            type: DownloadDocumentType.SourceDocument,
                            uploadedOn: undefined
                        };
                        org.downloadDocuments.push(downloadDoc);
                    } else {
                        org.downloadDocuments = org.downloadDocuments.map(doc => {
                            if (doc.type === DownloadDocumentType.SourceDocument &&
                                doc.fileName === action.fileName) {
                                doc.downloadBy = action.userName;
                                doc.downloadDate = moment.utc(new Date()).format() as any;
                            }
                            return { ...doc };
                        })
                    }

                    org.downloadedSourceDocumentsCount = org.downloadDocuments.filter(x => x.type == DownloadDocumentType.SourceDocument && x.downloadDate != null).length;
                    org.uploadedSourceDocumentsCount = org.downloadDocuments.filter(x => x.type == DownloadDocumentType.SourceDocument).length;

                    return { ...org };
                }
                return { ...org };
            });
            return sourceDetails;
        }

        case actionTypes.SET_SOURCE_DOCUMENT_META_DATA: {
            return {
                ...state,
                sourceDocumentMetaData: action.sourceDocumentMetaData
            }
        }

        case actionTypes.UPDATE_AUTOMATIC_REMINDER: {

        }

    }
    return state || unloadedDeliveredOrganizerStoreState;
}
