import * as React from 'react'
import { Card, Accordion } from 'react-bootstrap';
import { OrganizerBookmarks } from './../../../../../../models/OrganizerComponentModels';
import { icons } from 'react-icons/lib';
import { BookMarkTitleConstants } from '../../../../../Helper/OrganizerConstants';
import { SignatureSmallIcon, SourceDocumentIcon } from 'src/assets/Icon';

interface OrganizerBookmarkPanelProps {
    expanded: boolean;
    toggleExpand: () => void;
    bookmarks: OrganizerBookmarks;
    onPageSelect: (pageNo: number) => void;
    isBatchOrganizer: boolean;
}

const OrganizerTabBookmarkPanel: React.StatelessComponent<OrganizerBookmarkPanelProps> = ({ expanded, toggleExpand, bookmarks: { heading, pages }, onPageSelect, isBatchOrganizer }) => {

    const GetIcon = (enabledSourceDocument, enableSignature, isBatchOrganizer) => {

        if (enabledSourceDocument) {
            if (enableSignature) {
                return <img width="18px" height="18px" src={SignatureSmallIcon}
                    alt="" title={BookMarkTitleConstants.OrganizerTab.RequireSignature} className="svg-shadow" />
            }
            return <img width="15px" height="15px" src={SourceDocumentIcon}
                alt="" title={BookMarkTitleConstants.OrganizerTab.RequiresourceDocument} className="svg-shadow" />
        }
        else if (enableSignature && !isBatchOrganizer) {
            return <img width="18px" height="18px" src={SignatureSmallIcon}
                alt="" title={BookMarkTitleConstants.OrganizerTab.RequireSignature} className="svg-shadow" />
        }
    }


    return (
        <Accordion defaultActiveKey="0">
            <Card onToggle={() => toggleExpand()}>
                <Accordion.Toggle style={{ padding: "10px" }} as={Card.Header} variant="link" eventKey="0">
                    <Card.Title as="h4">
                        {heading ? heading : "Bookmarks"}
                    </Card.Title>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ padding: 0 }}>
                        {
                            pages.length === 0 ?
                                <div className="textalign-center">No Content</div> :
                                pages.map((page, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="custom-list-group-item bookmark-item" >
                                            <div className="bookmark-row" id={`page-${index}`}>
                                                <i className={"bookmark-icon fas fa-bookmark"} />
                                                <div
                                                    className={"bookmark-name wordbreak ellipsis cursor-pointer"}
                                                    onClick={() => onPageSelect(page.pageNo)}>
                                                    {page.pageTitle}
                                                </div>
                                                {GetIcon(page.enabledSourceDocument, page.enableSignature, isBatchOrganizer)}
                                            </div>
                                        </div>
                                    );

                                })

                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion >
    );
}
export { OrganizerTabBookmarkPanel }
