import { Guid } from 'src/Core/Utilities/Guid';
import { IUserModel, initialUserModel } from 'src/Core/ViewModels/User/UserViewModel';
import { initialMessageDetails, MessageDetails } from 'src/Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { IAccessCode, IAccessViewModel, IDocumentTransactionUser, MailEvent } from 'src/components/common/TaxReturn';
import { DefaultSenderInfo } from 'src/components/navigation/profile/MySettings';
import { UploadStatus } from 'src/Core/ViewModels/Common/UploadDocumentViewModel';
import { TaxSoftwareType } from 'src/Core/ViewModels/Company/CompanySettingsViewModel';
import { BatchTableModel, OrganizerTableModel } from './OrganizerStoreModels';
import { IClientInfoViewModel } from '../../modules/clientManagement/clientManagement.types';

export interface FileUploadData {
    name: string;
    progressBar: number;
    size: string;
    status: UploadStatus;
    file: any;
    uuid: string;
    sasGuid: string;
    disable: boolean;
}

export const initialUploadData: FileUploadData = {
    file: {},
    name: "",
    progressBar: 0,
    sasGuid: "",
    size: "",
    status: UploadStatus.Initiating,
    uuid: "",
    disable: false
}

export interface SingleFileOrganizerUploadData {
    number?: number;
    name?: string;
    progressBar?: number;
    size?: string;
    status: UploadStatus;
    clientId?: string;
    uuid?: string;
    sasGuid?: string;
    gridRowDisable: boolean;
    rowIndex?: number;
    file?: any;
    taxSoftware: TaxSoftwareType;
    engagementType: EngagementType;
    taxYear: number;
    uploadMethod: OrganizerDocumentUploadType;
}

export enum DocumentType {
    ConsentForm,
    EngagementLetter,
    Other,
    None
}

export const DocumentTypeLabels = {
    [DocumentType.ConsentForm]: "Consent Form",
    [DocumentType.EngagementLetter]: "Engagement Letter",
    [DocumentType.Other]: "Other",
}

export interface AdditionalESignDocumentsUploadData {
    id: number;
    name: string;
    progressBar?: number;
    size?: string;
    status: UploadStatus;
    uuid: string;
    sasGuid: string;
    gridRowDisable: boolean;
    rowIndex?: number;
    file?: any;
    documentType: DocumentType;
    isPDFloaded: boolean;
    sasUrl: string;
    isDeleted?: boolean;
}

export const initialAdditionalEsignUploadData: AdditionalESignDocumentsUploadData = {
    status: UploadStatus.Initiating,
    uuid: '',
    sasGuid: '',
    documentType: DocumentType.ConsentForm,
    isPDFloaded: false,
    sasUrl: '',
    name: '',
    gridRowDisable: false,
    id: 0
}

export interface OrganizerWithEnagagementLetterUploadData {
    clientId: string,
    taxYear: number;
    taxSoftware: TaxSoftwareType;
    enableEngagementLetter: boolean;
    sasGuid: string
    engagementType: EngagementType;
    organizerUploadFileData: SingleFileOrganizerUploadData[],
    elUploadFileData: SingleFileOrganizerUploadData[],
    totalUploadedFilesSize: string
}


export interface MultiFileOrganizerUploadData {
    clientId: string;
    sasGuid: string;
    subDocumentsUploadData: OrganizerSubDocumentUploadData[];
}

export interface OrganizerSubDocumentUploadData {
    number?: number;
    name?: string;
    progressBar?: number;
    size?: string;
    subDocumentGuid: string;
    status: UploadStatus;
    file?: any;
}

export enum OrganizerTabs {
    ClientInfo,
    EngagementLetter,
    Organizer,
    AdditionalESign,
    AdditionalQuestions,
    Removed,
    DeliveryOptions
}

export enum BatchTabs {
    Error,
    EngagementLetter,
    Organizer,
    AdditionalESign,
    AdditionalQuestions,
    Removed,
    DeliveryOptions
}

export enum ProcessStatus {
    None = 0,
    Uploaded = 1,
    Ready = 2,
    Error = 3,
    Processing = 4,
    PreparingForDelivery = 5,
    Delivered = 6,
    Completed = 7,
    DeliveryFailed = 8
}

export enum EngagementType {
    None = 0,
    E1040 = 1,
    E1041 = 5
}

export enum EngagementLetterStatus {
    NA = 25,
    AwaitingSignature = 21,
    PartiallySigned = 22,
    ESigned = 23,
    EngagementLetterDownloaded = 24,
    Reviewed = 26,
    ManuallySigned = 28,
    PartiallyReviewed = 29,
}

export const EngagementLetterStatusList = {
    "NA": "N/A",
    "AwaitingSignature": "Awaiting Signature",
    "PartiallySigned": "Partially Signed",
    "ESigned": "E-Signed",
    "EngagementLetterDownloaded": "Downloaded",
    "Reviewed": "Reviewed",
    "ManuallySigned": "Manually Signed",
    "PartiallyReviewed": "Partially Reviewed",
}

export enum OrganizerStatus {
    None = 0,
    Delivered = 1,
    PartiallyCompleted = 2,
    Completed = 3,
    OrganizerDownloaded = 4,
    Dropped = 5,
    ManuallyCompleted = 6,
    ClosedByFirm = 7,
    QuestionnaireCompleted = 8
}

export enum DownloadType {
    DownloadNow = "DownloadNow",
    DownloadToMyDownloads = "DownloadToMyDownloads",
    PromptMeEveryTime = "PromptMeEveryTime"
}

export const OrganizerStatusList = {
    "None": "None",
    "Delivered": "Delivered",
    "PartiallyCompleted": "Partially Completed",
    "Completed": "Completed",
    "OrganizerDownloaded": "Downloaded",
    "Dropped": "Dropped",
    "ManuallyCompleted": "Manually Completed",
    "ClosedByFirm": "Closed By Firm",
    "QuestionnaireCompleted": "Questionnaire Completed"
}

export enum SourceDocumentStatus {
    None = 0,
    AwaitingUpload = 1,
    Uploaded = 2,
    Downloaded = 3,
    Completed = 4,
    NA = 5
}

export const SourceDocumentStatusList = {
    "None": "None",
    "AwaitingUpload": "Awaiting Upload",
    "Uploaded": "Uploaded",
    "Downloaded": "Downloaded",
    "Completed": "Finished",
    "NA": "N/A"
}

export enum ModelTaxSoftwareType {
    None = -1,
    ProSystems = 0,
    UltraTax = 1,
    GoSystem = 2,
    UltraTaxM18 = 3,
    Lacerte = 4,
    Drake = 5
}

export enum OrganizerClientType {
    Taxpayer = 1,
    Spouse
}

export interface OrganizerDocument {
    id: number;
    processInfoGuid: string;
    clientId: string;
    customQuestionTemplateId: number;
    hasCustomQuestion: any;
    clientType: OrganizerClientType
    fileName: string;
    processStatus: ProcessStatus;
    createdOn: Date;
    createdBy?: number;
    deliveredOn: Date;
    deliveredBy: number;
    taxYear: number;
    engagementLetterStatus: EngagementLetterStatus;
    organizerStatus: OrganizerStatus;
    previousOrganizerStatus: OrganizerStatus;
    sourceDocumentStatus: SourceDocumentStatus;
    taxSoftware: ModelTaxSoftwareType;
    engagementType: EngagementType;
    uploadType: OrganizerDocumentUploadType;
    organizerClients: OrganizerClient[];
    formGroups: FormGroup[];
    documentSettings: OrganizerDocumentSettings;
    ero: number;
    eroUserName: string;
    sentByUserName: string;
    taxpayerName: string;
    deliveredByUser: any;
    lastReminder: Date;
    downloadedDate: Date;
    clientTracking: OrganizerTransaction[];
    downloadDocuments: DownloadableDocuments[];
    document: any;
    questionsDocument: any;
    uploadedFileNames: string[];
    accessCode: IAccessViewModel;
    assignedToUser: UserModel;
    isBatchTemplate: boolean;
    additionalEsignDocuments: AdditionalEsignModel[];
    isDocumentLocked: boolean;
    signerCount: number;
    isTPOrganizerMailDropped: boolean;
    isSpouseOrganizerMailDropped: boolean;
    taxpayerEmail: string;
    spouseEmail: string;
    uploadedSourceDocumentsCount: number;
    downloadedSourceDocumentsCount: number;
    isSourceDocumentUploadCompleted: boolean;
    batchId: string;
    batchName: string;
    archivedByUserName: string;
    organizerReminder: Date;
    signingReminder: Date;
    SpouseSignerStatus: SignatureStatus;
    TPSignerStatus: SignatureStatus;
    hasOrganizerWithOldCustomQuestion: boolean;
    locationId: number;
    locationName: string;
    isUpdateUserId: boolean;
};


export interface RecycledOrganizerDocument extends OrganizerDocument {
    recycledByUserName: string,
    recycledDate: Date;
}
export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
};

export const initialOrganizerDocumentDeliverySettings: OrganizerDocumentDeliverySettings = {
    contactPersonId: 0,
    preparerMessage: initialMessageDetails,
    sender: {
        senderId: 0,
        senderType: DefaultSenderInfoType.None
    }
}

export const initialOrganizerReminderOptions: OrganizerReminderOptions = {
    enabled: false,
    reminderDays: 0
}


export const organizerInitialDocumentSettings: OrganizerDocumentSettings = {
    deliverySettings: initialOrganizerDocumentDeliverySettings,
    notificationSettings: {
        notifyELCompletedUser: 0,
        notifyOrganizerCompletedUser: 0,
        notifyUploadedFilesUser: 0
    },
    signatureSettings: {
        signatureStampUser: 0
    },
    reminderSettings: {
        esignReminder: initialOrganizerReminderOptions,
        organizerReminder: initialOrganizerReminderOptions
    }
}


export enum OrganizerDocumentUploadType {
    None = 0,
    Proforma = 1,
    Blank,
    Batch
}
export const OrganizerDocumentUploadTypeLabelValue = [
    { label: "One-off", value: OrganizerDocumentUploadType.Proforma },
    //{ label: "Blank Organizer", value: OrganizerDocumentUploadType.Blank },
    { label: "Batch Organizer", value: OrganizerDocumentUploadType.Batch }
];
export enum SignatureStatus {
    None = 0,
    Esigned = 1,
    Reviewed = 2,
    NA = 3
}
export const initialOrganizerDocument: OrganizerDocument = {
    id: 0,
    fileName: "",
    taxpayerName: "",
    clientId: "",
    clientType: OrganizerClientType.Taxpayer,
    processStatus: ProcessStatus.None,
    createdOn: new Date(),
    deliveredByUser: null,
    createdBy: 0,
    taxYear: 2020,
    deliveredOn: new Date(),
    processInfoGuid: "",
    eroUserName: "",
    sentByUserName: "",
    taxSoftware: ModelTaxSoftwareType.None,
    deliveredBy: 0,
    lastReminder: new Date(),
    downloadedDate: new Date(),
    organizerReminder: new Date,
    signingReminder: new Date,
    organizerClients: [],
    clientTracking: [],
    downloadDocuments: [],
    documentSettings: organizerInitialDocumentSettings,
    engagementType: EngagementType.None,
    document: null,
    formGroups: [],
    questionsDocument: null,
    uploadType: OrganizerDocumentUploadType.None,
    ero: 0,
    engagementLetterStatus: EngagementLetterStatus.NA,
    organizerStatus: OrganizerStatus.None,
    sourceDocumentStatus: SourceDocumentStatus.None,
    uploadedFileNames: [],
    accessCode: {
        accessCodeDetails: [],
        clientEvents: []
    },
    assignedToUser: {
        userId: 0,
        userName: ''
    },
    isBatchTemplate: false,
    additionalEsignDocuments: [],
    isDocumentLocked: false,
    signerCount: 0,
    isTPOrganizerMailDropped: false,
    isSpouseOrganizerMailDropped: false,
    taxpayerEmail: "",
    spouseEmail: "",
    uploadedSourceDocumentsCount: 0,
    downloadedSourceDocumentsCount: 0,
    isSourceDocumentUploadCompleted: false,
    batchId: "",
    batchName: "",
    archivedByUserName: '',
    SpouseSignerStatus: SignatureStatus.None,
    TPSignerStatus: SignatureStatus.None,
    hasOrganizerWithOldCustomQuestion: false,
    locationId: 0,
    locationName: "",
    customQuestionTemplateId: 0,
    hasCustomQuestion: undefined,
    previousOrganizerStatus: OrganizerStatus.None
}


export interface OrganizerDocumentSettings {
    deliverySettings: OrganizerDocumentDeliverySettings;
    notificationSettings: OrganizerDocumentNotificationSettings;
    signatureSettings: OrganizerDocumentSignatureSettings;
    reminderSettings: OrganizerReminderSettings;
}


export interface OrganizerDocumentNotificationSettings {
    notifyELCompletedUser: number;
    notifyOrganizerCompletedUser: number;
    notifyUploadedFilesUser: number;
}

export interface OrganizerDocumentDeliverySettings {
    contactPersonId: number;
    preparerMessage: MessageDetails;
    sender: DefaultSenderInfo;
}

export interface OrganizerReminderSettings {
    esignReminder: OrganizerReminderOptions,
    organizerReminder: OrganizerReminderOptions
}

export interface OrganizerReminderOptions {
    enabled: boolean,
    reminderDays: number
}

export interface OrganizerDocumentSignatureSettings {
    signatureStampUser: number;
}
export interface OrganizerTransaction {
    documentId: number;
    eventId: OrganizerEvent;
    actedOn: Date;
    actedBy: IDocumentTransactionUser;
    eventData: OrganizerEventData;
}

export interface OrganizerEventData {
    fileName: string;
    ipAddress: string;
    mailEventId: MailEvent;
    recipient: any;
}

export enum OrganizerEvent {
    Created = 1,
    Emailed = 2,
    OTPAuthenticationFailed = 3,
    Reminder = 4,
    ResentAccessLink = 5,
    EnagementLetterEsigned = 6,
    FilledOrganizer = 7,
    CompletedOrganizer = 8,
    UploadedDocument = 9,
    CpaOrganizerDownloaded = 10,
    CpaEngagementDownloaded = 11,
    CpaSourceDocumentDownloaded = 12,
    ClientOrganizerDownloaded = 13,
    ClientEngagementLetterDownloaded = 14,
    DeleteSourceDocument = 15,
    OTPEmailed = 16,
    OTPTexted = 17,
    OTPAuthenticated = 18,
    EngagementLetterViewed = 19,
    EngagementLetterCompleted = 20,
    EngagementLetterReviewed = 21,
    ManualSigningReminder = 22,
    ManualOrganizerReminder = 23,
    SourceDocumentReminder = 24,
    AccessCodeEmail = 25,
    OTPAccesslocked = 26,
    OrganizerSendForReview = 27,
    OrganizerReviewed = 28,
    OrganizerReviewIgnored = 29,
    AcessLinkSelected = 30,
    CpaEngagementLetterManuallySigned = 31,
    CpaOrganizerManuallyCompleted = 32,
    SourceDocumentUploadMarkedCompleted = 33,
    SourceDocumentUploadReopened = 34,
    ArchivedOrganizer = 35,
    RestoreArchivedOrganizer = 36,
    RecycleOrganizer = 37,
    RestoreRecycledOrganizer = 38,
    AutomaticSigningReminder = 39,
    AutomaticOrganizerReminder = 40,
    ReopenOrganizer = 41,
    ClosedOrganizerByFirm = 42,
    ReopenedOrganizerByFirm = 43,
    CustomQuestionFilled = 44,
    CustomQuestionCompleted = 45,
    CpaDownloadedOrganizerWithCustomQuestions = 46
}

export const OrganizerEventList = {
    'Emailed': 'Initial email',
    'Reminder': 'Reminder email sent',
    'Created': 'Created',
    'ResentAccessLink': 'Access link resent',
    'EnagementLetterEsigned': 'Engagement letter eSigned',
    'FilledOrganizer': 'Filled organizer',
    'CompletedOrganizer': 'Completed organizer',
    'UploadedDocument': 'Uploaded source document',
    'CpaOrganizerDownloaded': 'Organizer downloaded by CPA',
    'CpaEngagementDownloaded': 'Engagement letter downloaded by CPA',
    'CpaSourceDocumentDownloaded': 'Source document downloaded by CPA',
    'ClientOrganizerDownloaded': 'Organizer downloaded',
    'ClientEngagementLetterDownloaded': 'Engagement letter downloaded',
    'DeleteSourceDocument': 'Deleted document',
    'OTPEmailed': 'OTP emailed',
    'OTPTexted': 'OTP texted',
    'OTPAuthenticated': 'OTP authenticated',
    'OTPAuthenticationFailed': 'OTP authentication failed',
    'EngagementLetterViewed': 'Engagement letter viewed',
    'EngagementLetterCompleted': 'Engagement letter eSign completed',
    'EngagementLetterReviewed': 'Signature document marked as reviewed',
    'ManualSigningReminder': 'Manual Signing Reminder email',
    'ManualOrganizerReminder': 'Manual Organizer Reminder email',
    'SourceDocumentReminder': 'Source Document Reminder email',
    'AccessCodeEmail': 'Access Code Email',
    'OrganizerSendForReview': 'Organizer Sent for Review',
    'OrganizerReviewed': 'Organizer Reviewed',
    'OrganizerReviewIgnored': 'Organizer Review Ignored',
    'CpaEngagementLetterManuallySigned': 'Engagement letter changed to manually signed',
    'CpaOrganizerManuallyCompleted': 'Organizer changed to manually completed',
    'SourceDocumentUploadMarkedCompleted': 'Source Document upload completed by',
    'SourceDocumentUploadReopened': 'Source Document upload reopened for organizer',
    'ArchivedOrganizer': 'Organizer Archived',
    'RestoreArchivedOrganizer': 'Restored From Archived Organizers',
    'RestoreRecycledOrganizer': 'Organizer Restored From Recycle Bin',
    'AutomaticSigningReminder': 'Automatic Signing Reminder email',
    'AutomaticOrganizerReminder': 'Automatic Organizer Reminder email',
    'ReopenOrganizer': 'Organizer has been Reopened',
    'RecycleOrganizer': 'Organizer Moved To Recycle Bin',
    'ClosedOrganizerByFirm': 'Organizer closed',
    'ReopenedOrganizerByFirm': 'Closed organizer reopened',    
    'CustomQuestionFilled': 'Custom Question Filled',
    'CustomQuestionCompleted': 'Custom Question Completed',
    'CpaDownloadedOrganizerWithCustomQuestions': 'Organizer with Custom Questionnaire Downloaded by CPA'
}

export interface AuthenticationSettings {
    authenticationOption: AuthenticationOption;
}

export enum AuthenticationOption {
    None,
    Text,
    Email
}

export interface OrganizerCompanySettings {
    defaultSettings: OrganizerDefaultSettings;
    authenticationSettings: AuthenticationSettings;
    sourceDocumentSettings: SourceDocumentSettings;
    reminderSettings: ReminderSettings;
}

export interface OrganizerDefaultSettings {
    savedMessage: number;
    initialEmail: number;
    engLetterReminderEmail: number;
    organizerReminderEmail: number;
    sourceDocReminderEmail: number;
    downloadEngagementLetterEmail: number;
    enableEngagementLetter: boolean;
}

export interface SourceDocumentSettings {
    enabledSourceDocuments: boolean;
    notifyEveryTimeUploadOrDeleteSrcDoc: boolean;
    schNotification1PerDayUploadOrDeleteSrcDoc: boolean;
    noAdditionalCPANotificationRequired: boolean;
}

export interface ReminderSettings {
    esignReminder: GeneralRemainder;
    organizerReminder: GeneralRemainder;
}

export interface GeneralRemainder {
    enabled: boolean;
    reminderDays: number;
    isReadOnly: boolean;
}

export interface GlobalOperationAutoReminderRequest {
    emailMessageType: EmailMessageType;
}

export interface OrganizerClient {
    clientGuid: string;
    id: number;
    name: string;
    ssn: string;
    email: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    dob: Date;
    mobileNumber: string;
    countryCode: string;
    clientType: OrganizerClientType;
    isDeceased: boolean;
    signingOrder: number;
    signatureStatus: SignatureStatus;
    signedOn: Date | undefined;
    deliveredOn: Date | undefined;
    accessLink: string;
    documentSigner: boolean;
    organizerStatus: number;
    locationId: number;
    ero: number;
}



export const SignatureStatusList = {
    'None': 'Not Signed',
    'Esigned': 'Signed',
    'Reviewed': 'Reviewed',
    'NA': 'NA'
}

export const initialOrganizerClient: OrganizerClient = {
    clientGuid: "",
    id: 0,
    name: "",
    ssn: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    dob: new Date(),
    mobileNumber: "",
    countryCode: "",
    clientType: OrganizerClientType.Taxpayer,
    isDeceased: false,
    signatureStatus: SignatureStatus.None,
    signingOrder: 0,
    signedOn: undefined,
    deliveredOn: undefined,
    accessLink: '',
    documentSigner: false,
    organizerStatus: 0,
    locationId: 0,
    ero: 0
}

export const DocumentStatusList = {
    'None': 'None',
    'Uploaded': 'Uploaded',
    'Ready': 'Ready',
    'Error': 'Error',
    'Processing': 'Processing',
    'PreparingForDelivery': 'Preparing For Delivery',
    'Delivered': 'Delivered',
    'PartiallyCompleted': 'Partially Completed',
    'Completed': 'Completed',
    'Downloaded': 'Downloaded',
    "DeliveryFailed": "Delivery Failed"
}

export interface OrganizerFilter {
    name: string;
    sort: Sort;
    fields: { [id: string]: string };
    searchText: string;
    isDefaultFilter: boolean;
    filterType: OrganizerFilterType;
}

export interface Sort {
    column: string;
    direction: SortDirections;
}

export enum SortDirections {
    None = 0,
    Ascending = 1,
    Descending = 2
}

export enum OrganizerFilterType {
    None = 0,
    Organizers = 1,
    DeliveredOrganizers = 2,
    ArchivedOrganizers = 3
}

export const EngagementList = {
    1: "1040",
    //5: "1041"
}

export const EngagementListLabelValue = [
    { label: "1040", value: 1 },
    //{ label: "1041", value: 5 }
];

export const OrganizerTypeListLabelValue = [
    { label: "Organizer Short", value: 1 },
    { label: "Organizer Long", value: 2 }
];

export const DisplayEngagementListLabelValue = [
    { label: "1040", value: "E1040" },
    //{ label: "1041", value: "E1041" }
];

export const DisplayOrganizerTypeListLabelValue = [
    { label: "Organizer Short", value: "OrganizerShort" },
    { label: "Organizer Long", value: "OrganizerLong" }
];

export interface FormGroup {
    id: number;
    processInfoId: number;
    name: string;
    type: GroupType;
    status: FormGroupStatus;
    uploadedBy?: number;
    uploadedOn?: Date;
    forms: Form[];
}

export enum GroupType {
    None = 0,
    Organizer = 1,
    Engagement = 2,
    Removed = 3,
    AdditionalQuestions = 4,
    AdditionalEFile = 5,
    OrganizerWithSignature = 6,
}

export enum FormGroupStatus {
    None = 0,

    // organizer
    Delievered = 1,
    PartiallyCompleted = 2,
    Completed = 3,
    OrganizerDownloaded = 4,
    Dropped = 5,
    ManuallyCompleted = 6,
    QuestionnaireCompleted = 8,

    // engagement 
    AwaitingESign = 21,
    PartiallySigned = 22,
    ESigned = 23,
    EngagementLetterDownloaded = 24,
    NA = 25,
    Reviewed = 26,
    Declined = 27,
    ManuallySigned = 28
}

export interface Form {
    formId: number;
    formGuid: string;
    formGroupId: number;
    formType: FormType;
    pageNo: number;
    formName: string;
    bookmark: string;
    rotationDegree?: number;
    individualFilePageNo?: number;
    enableSignature: boolean;
}

export interface OrganizerForm extends Form {
    enabledSourceDocument: boolean;
    formData: OrganizerFormData;
}

export interface OrganizerFormData {
    controlList: Control[];
}
export interface EngagementLetterForm extends Form {
    formData: EngagementLetterFormData;
}

export interface EngagementLetterFormData {
    controlList: Control[];
}

export interface AdditionalQuestionsForm extends Form {
    formData: AdditionalQuestionFormData;
}

export interface AdditionalQuestionFormData {
    controlList: Control[];
}

export enum FormType {
    None = 0,
    OrganizerClient = 1,
    Organizer = 2,
    EngagementLetter = 3,
    Removed = 4,
    AdditionalQuestions = 5,
    OrganizerWithSign = 6,
}

export enum ControlType {
    None = 0,
    Textbox = 1,
    Checkbox = 2,
    Date = 3,
    Dropdown = 4,
    Grid = 5,
    Signature = 6,
    RadioButton = 7,
    RadioButtonGroup = 8,
    QuestionControl = 9,
    Section = 10,
    SignatureCheckBoxControl = 11,
    SignatureRadioButtonControl = 12
}

export interface BoundingRectangle {
    left: number;
    top: number;
    width: number;
    height: number;
}

export enum DataType {
    None = 0,
    AlphaNumeric = 1,
    Numeric = 2,
    SSN = 3,
    TextArea = 4,
    Label = 5,
    Amount = 6,
    Name = 7,
    Initial = 8,
    Email = 9,
    Text = 10
}

export enum ControlRole {
    None = 0,
    Taxpayer = 1,
    Spouse = 2,
    Ero = 3
}

export interface Control {
    id: string;
    signerId: number;
    name: string;
    controlType: ControlType;
    dataType: DataType;
    tooltip: string;
    readonly: boolean;
    required: boolean;
    boundingRectangle: BoundingRectangle;
    customData: string;
    controlRole: ControlRole;
}

export const initialControl: Control = {
    controlType: ControlType.None,
    id: "",
    name: '',
    signerId: 0,
    dataType: DataType.None,
    tooltip: '',
    readonly: false,
    required: false,
    boundingRectangle: {
        left: 0,
        top: 0,
        width: 0,
        height: 0
    } as BoundingRectangle,
    customData: '',
    controlRole: ControlRole.None
}

export interface Textbox extends Control {
    maxLength: number;
    placeholder: string;
    textPlacement: TextPlacement;
    controlData: TextboxData;
}

export interface SignatureCheckBoxControl extends Control {
    page: number;
    disabled: boolean;
    items: ChoosableControlData[];
}

export interface SignatureRadioButtonControl extends Control {
    page: number;
    disabled: boolean;
    items: ChoosableControlData[];
}

export class ChoosableControlData {

    id: string = '';
    name: string = '';
    top: number = 0;
    left: number = 0;
    width: number = 0;
    height: number = 0;
    value: any;

    public static create(
        id: string,
        name: string,
        top: number,
        left: number,
        width: number,
        height: number,
        value: any
    ) {

        var data = new ChoosableControlData();
        data.id = id;
        data.name = name;
        data.top = top;
        data.left = left;
        data.width = width;
        data.height = height;
        data.value = value;

        return data;
    }

    public static createNullObject() {

        var data = new ChoosableControlData();
        data.id = "";
        data.name = "";
        data.top = 0;
        data.left = 0;
        data.width = 0;
        data.height = 0;
        data.value = { checked: false, value: '' };
        return data;
    }
}

export interface TextboxData {
    value: string;
}

export interface Signature extends Control {
    controlData: SignatureData;
}

export interface SignatureData {
    name: string;
    fileGuid: string;
}

export interface DateControl extends Control {
    placeholder: string;
    textPlacement: TextPlacement;
    controlData: DateControlData;
}

export interface DateControlData {
    value: Date;
}

export enum TextPlacement {
    Left = 1,
    Right = 2,
    Center = 3
}

export interface RadioButton extends Control {
    controlData: RadioButtonControlData;
}

export interface RadioButtonControlData {
    selected: boolean;
}

export interface RadioButtonGroup extends Control {
    controlData: RadioButtonGroupControlData;
}

export interface RadioButtonGroupControlData {
    items: RadioButton[];
    selectedId: string;
}

export interface QuestionControl extends Control {
    questionNo: string;
    textbox: Textbox;
    radioButtonGroup: RadioButtonGroup;
    sectionId: string,
    subQuestions: SubQuestionMapping[],
    parentQuestionId: string,
    type: QuestionType;
    required: boolean;
    inputTextbox: Textbox;
}

export interface SectionControl extends Control {
    section: Textbox;
}

export interface SubQuestionMapping {
    subQuestionIds: string[];
    condition: boolean;
}

export interface SignatureControlsDictionary {
    [pageNo: number]: Control[];
}

export interface PdfPageSignatureControl {
    signatureControls: Control[];
    signerId: number;
    focusGroup: UserGroup;
}

export enum UserGroup {
    None,
    Sender,
    Reciever
}

export interface BookmarkPage {
    pageNo: number;
    pageTitle: string;
}

export interface Bookmarks {
    heading: string;
    pages: BookmarkPage[];
}

export interface OrganizerBookmarks {
    heading: string;
    pages: OrganizerBookmarkPage[];
}

export interface OrganizerBookmarkPage extends BookmarkPage {
    enabledSourceDocument: boolean;
    enableSignature: boolean;
}


export interface SignerDropDown {
    value: number;
    label: string;
    role: ControlRole;
    disabled?: boolean;
}
export const defaultSigner: SignerDropDown = {
    value: 0,
    label: "",
    disabled: false,
    role: ControlRole.None
}

export interface EmailMessage {
    id: number;
    name: string;
    text: string;
    subject: string;
    type: EmailMessageType;
}

export enum EmailMessageType {
    None = 0,
    InitialEmail = 1,
    EngagementReminderEmail = 2,
    DownloadEngagementLetterEmail = 9,
    OrganizerReminderEmail = 17,
    SourceDocReminderEmail = 18
}

export interface PreparerMessage {
    id: number;
    name: string;
    body: string;
    isAllowToEdit: boolean;
}

export const initialEmailMessage: EmailMessage = {
    id: 0,
    name: "",
    text: "",
    subject: "",
    type: EmailMessageType.None
}

export const initialPreparerMessage: PreparerMessage = {
    id: 0,
    name: "",
    body: "",
    isAllowToEdit: false
}

export interface VariableListData {
    variable: string;
    helpText: string;
}

export enum BlankOrganizerTemplateStatus {
    None = 0,
    Uploaded,
    Enabled,
    Disabled
}

export interface BlankOrganizerTemplateDocument {
    id: number;
    name: string;
    documentName: string;
    status: BlankOrganizerTemplateStatus;
    createdOn: Date;
    updatedOn: Date | undefined;
    createdBy: number;
    taxYear: number;
    documentGuid: string;
    taxSoftware: TaxSoftwareType;
    engagementType: EngagementType;
    document: any;
    formGroups: BlankOrganizerTemplateFormGroup[];
    questionDocument: any;
};

export const initialBlankOrganizerTemplateDocument: BlankOrganizerTemplateDocument = {
    createdBy: 0,
    createdOn: new Date(),
    document: null,
    documentGuid: "",
    documentName: "",
    status: BlankOrganizerTemplateStatus.None,
    engagementType: EngagementType.None,
    formGroups: [],
    id: 0,
    name: "",
    taxSoftware: TaxSoftwareType.None,
    taxYear: 0,
    updatedOn: new Date(),
    questionDocument: null
}

export interface BlankOrganizerUploadModel extends BlankOrganizerTemplateDocument {
    number?: number;
    progressBar?: number;
    size?: string;
    uploadStatus?: UploadStatus;
    gridRowDisable: boolean;
    rowIndex?: number;
    file?: any;
}

export enum TemplateGroupType {
    None = 0,
    EngagementLetter,
    AdditionalQuestions
}

export interface BlankOrganizerTemplateFormGroup {
    id: number;
    templateId: number;
    groupType: TemplateGroupType;
    forms: Form[];
}


export const BlankOrganizerTemplateStatusList = {
    'None': 'None',
    'Uploaded': 'Uploaded',
    'Enabled': 'Enabled',
    'Disabled': 'Disabled'
}

export enum BlankOrganizerTabs {
    SignaturePlacements
}

export enum AdditionalQuestionsTabs {
    AdditionalQuestions
}


export interface BatchInfo {
    id: string;
    batchName: string;
    uploadedOn: Date;
    uploadedBy: number;
    status: BatchStatus;
    ero: number;
    deliveredOn: Date | undefined;
    deliveredBy: number;
    taxYear: number;
    engagementType: EngagementType;
    taxSoftware: TaxSoftwareType;
    enableEngagementLetter: boolean;
    locationId : number;
}

export interface BatchOrganizerUploadModel extends BatchInfo {
    batchSize: string;
}


export enum BatchStatus {
    None = 0,
    Uploaded = 1,
    Error = 2,
    Success = 3,
    PartialSuccess = 4,
    InProgress = 5,
    DeliveryInProgress = 6,
    PartialDeliver = 7,
    Delivered = 8,
    ReadyForValidation = 9,
    ValidationInprogress = 10,
    ValidationSuccess = 11,
    ValidationPartiallySucceeded = 12,
    ValidationFailed = 13,
    ReadyForRecognition = 14,
    RecognitionInprogress = 15,
    RecognitionCompleted = 16,
    PreparingForDelivery = 17,
    Processing = 18,
    Delayed = 19,
    DeliveryFailed = 20
}

export interface BatchSettings {
    documentSettings: OrganizerDocumentSettings;
    firstSigner: OrganizerClientType;
    ignoreErrors: boolean;
    ignoreWarnings: boolean;
}

export const initialBatchSettings: BatchSettings = {
    documentSettings: organizerInitialDocumentSettings,
    firstSigner: OrganizerClientType.Taxpayer,
    ignoreErrors: false,
    ignoreWarnings: false
}

export const initialBatchModel: BatchModel = {
    batchName: "",
    deliveredBy: 0,
    deliveredOn: undefined,
    engagementType: EngagementType.None,
    ero: 0,
    id: "",
    status: BatchStatus.None,
    taxYear: 0,
    uploadedBy: 0,
    uploadedOn: new Date(),
    organizers: {
        count: 0,
        documents: []
    },
    taxSoftware: TaxSoftwareType.None,
    additionalQuestionsPdf: null,
    organizerPdf: null,
    settings: initialBatchSettings,
    formGroups: [],
    csvData: {},
    processInfoGuid: '',
    processInfoId: 0,
    eroUserName: '',
    assignedToUser: {
        userId: 0,
        userName: ''
    },
    additionalEsignDocuments: [],
    enableEngagementLetter: false,
    customQuestionTemplateId: 0,
    hasCustomQuestion: undefined,
    locationId: 0
}

export interface BatchModel extends BatchInfo {
    organizers: OrganizerTableModel;
    settings: BatchSettings;
    organizerPdf: any;
    additionalQuestionsPdf: any;
    formGroups: FormGroup[];
    csvData: PagedCSVData;
    processInfoGuid: string;
    processInfoId: number;
    customQuestionTemplateId: number;
    hasCustomQuestion: any;
    eroUserName: string;
    locationName: string;
    assignedToUser: UserModel;
    additionalEsignDocuments: AdditionalEsignModel[];
}


export interface PagedBatchOrganizerDocumentDictionary {
    [query: string]: OrganizerTableModel;
}

export interface PagedBatchModelDictionary {
    [query: string]: BatchTableModel;
}

export enum OrganizerPageOptions {
    Organizer = 1,
    BatchOrganizer
}

export const OrganizerPageOptionsList = [
    { label: "One-off Organizers", value: 1 },
    { label: "Batch Organizers", value: 2 },
];

export const BatchStatusList = {
    'None': 'None',
    'Uploaded': 'Uploaded',
    'Error': 'Error',
    'Success': 'Success',
    'PartialSuccess': 'Partially Succeeded',
    'Delivered': 'Delivered',
    'InProgress': 'In Progress',
    'PartialDeliver': 'Partially Delivered',
    'DeliveryInProgress': 'Delivery In Progress',
    'ReadyForValidation': 'In Progress',
    'ValidationInprogress': 'In Progress',
    'ValidationSuccess': 'In Progress',
    'ValidationPartiallySucceeded': 'In Progress',
    'ValidationFailed': 'Error',
    'ReadyForRecognition': 'In Progress',
    'RecognitionInprogress': 'In Progress',
    'RecognitionCompleted': 'In Progress',
    'PreparingForDelivery': 'Preparing For Delivery',
    'Processing': 'Processing',
    'Delayed': 'Delayed',
    'DeliveryFailed': 'Delivery Failed'
}

export interface AdditionalQuestionModel {
    id: number;
    name: string;
    forms: AdditionalQuestionsForm[];
    createdOn: Date;
    createdBy: number;
    updatedOn: Date | undefined;
    disabled: boolean;
    documentPDF: any;
}

export const initialAdditionalQuestionModel: AdditionalQuestionModel = {
    createdBy: 0,
    createdOn: new Date(),
    disabled: false,
    documentPDF: null,
    forms: [],
    id: 0,
    name: "",
    updatedOn: undefined
}

export interface AdditionalQuestionsListItem {
    order: number;
    value: string;
    id: string;
    height: number;
}


export enum QuestionControlType {
    Default = 0,
    SubQuestion = 1,
    Section = 2,
    Custom = 3,
    CustomSubQuestion = 4
}


export interface BatchCSVData {
    clientId: string;
    taxpayerFirstName: string;
    taxpayerLastName: string;
    taxpayerEmail: string;
    taxpayerMobileNo: string;
    taxpayerCountryCode: string;
    spouseFirstName: string;
    spouseLastName: string;
    spouseEmail: string;
    spouseMobileNo: string;
    spouseCountryCode: string;
    modified: boolean;
    id: number;
    count: number;
    validationResult: BatchValidationResult;
}


export enum BatchValidationStatus {
    Success = 1,
    Failed = 2,
    Warning = 3
}

export interface BatchValidationResult {
    id: number;
    batchDetailId: number;
    status: BatchValidationStatus;
    validationMessages: BatchError[];
}

export interface BatchError {
    field: BatchColumn;
    message: ValidationMessage;
    severity: Severity;
}

export enum Severity {
    Error = 0,
    Warning = 1
}

export enum BatchColumn {
    None = 0,
    ClientId = 1,
    TaxpayerEmail = 2,
    TaxpayerFirstName = 3,
    TaxpayerLastName = 4,
    TaxpayerCountryCode = 5,
    TaxpayerMobileNo = 6,
    SpouseEmail = 7,
    SpouseFirstName = 8,
    SpouseLastName = 9,
    SpouseCountryCode = 10,
    SpouseMobileNo = 11,
    DocumentGuid = 12,
    HasEngagementLetter = 13
}

export enum ValidationMessage {
    None = 0,
    Empty = 1,
    Invalid = 2,
    Duplicate = 3
}

export interface PagedCSVData {
    [pageNo: number]: BatchCSVData[];
}

export interface UserModel {
    userId: number;
    userName: string;
}

export interface DownloadableDocuments {
    fileName: string;
    downloadDate: Date | undefined;
    uploadedOn: Date | undefined;
    downloadBy: string;
    type: DownloadDocumentType;
}

export enum DownloadDocumentType {
    None = 0,
    Organizer = 1,
    EngagementLetter = 2,
    SourceDocument = 3
}

export const ReminderTypesNames = {
    [EmailMessageType.EngagementReminderEmail]: "Engagement Letter Reminder",
    [EmailMessageType.OrganizerReminderEmail]: "Tax Organizer Reminder",
    [EmailMessageType.SourceDocReminderEmail]: "Source Document Reminder"
}

export interface BulkOperationFilters {
    clientId: string;
    deliveredOn?: Date;
    engLetterStatus: string;
    organizerStatus: string;
    sourceDocStatus: string;
    taxPayerName: string;
    taxYear: string;
    ero: string;
    sentBy: string;
    deselectedIds: number[];
    batchName: string;
}

export interface BulkOperationReminderRequest extends BulkOperationFilters {
    engLetterReminder: boolean;
    organizerReminder: boolean;
    sourceDocReminder: boolean;
}

export interface BulkOperationAutoReminderRequest extends BulkOperationFilters {
    reminderSettings: ReminderSettings;
}


//TODO: Need to refactor BulkOperationReminderRequest after batch send reminder PRs
export interface SendReminderOption {
    engagementLetterReminder: boolean;
    organizerReminder: boolean;
    sourceDocumentReminder: boolean;
}

export enum QuestionType {
    YesNo,
    ShortAnswer
}

export const QuestionTypeName = {
    [QuestionType.YesNo]: "Yes / No Answer",
    [QuestionType.ShortAnswer]: "Short Answer"
}

export class Question {
    sectionId: string;
    type: QuestionType;
    required: boolean;
    dynamic: boolean;
    subQuestions: SubQuestion[];
    id: string;
    value: string;
    height: number;
    constructor(sectionId: string,
        type: QuestionType,
        required: boolean,
        dynamic: boolean,
        subQuestions: SubQuestion[],
        id: string,
        value: string,
        height: number) {
        this.sectionId = sectionId;
        this.type = type;
        this.required = required;
        this.dynamic = dynamic;
        this.subQuestions = subQuestions;
        this.id = id;
        this.value = value;
        this.height = height;
    }

    public static create() {
        return new Question(Guid.empty, QuestionType.YesNo, false, false, [], Guid.newGuid().toString(), "", 0);
    }
}

export class SubQuestion extends Question {
    condition: boolean;
    constructor(condition: boolean,
        sectionId: string,
        type: QuestionType,
        required: boolean,
        dynamic: boolean,
        subQuestions: SubQuestion[],
        id: string,
        value: string,
        height: number) {
        super(sectionId, type, required, dynamic, subQuestions, id, value, height);
        this.condition = condition;
    }

    public static create() {
        return new SubQuestion(true, Guid.empty, QuestionType.YesNo, false, false, [], Guid.newGuid().toString(), "", 0);
    }
}

export interface AdditionalEsignModel {
    id: number;
    fileGuid: string;
    documentType: DocumentType;
    fileName: string;
    sasURL: string;
    documentControls: AdditionalEsignDocumentControls;
    uploadedBy: number;
    uploadedOn: Date
}

export interface ProformaAdditionalEsignModel extends AdditionalEsignModel {
    processInfoGuid: string;
}

export interface AdditionalEsignDocumentControls {
    formData: AdditionalEsignDocumentControlData[];
}

export interface AdditionalEsignDocumentControlData {
    pageNo: number;
    controlList: Control[];
}

export interface BatchAdditionalEsignModel extends AdditionalEsignModel {
    batchId: string;
}

export interface IColumnSettings {
    columnName: string;
    columnOrder: number;
    isActive: boolean;
}
export const EngagementLetterStatusOptionsList = [
    { label: "AWAITING SIGNATURE", value: 21 },
    { label: "REVIEWED", value: 26 },
    { label: "PARTIALLY SIGNED", value: 22 },
    { label: "E-SIGNED", value: 23 },
    { label: "DOWNLOADED", value: 24 },
    { label: "MANUALLY SIGNED", value: 28 },
    { label: "PARTIALLY REVIEWED", value: 29 },
    { label: "N/A", value: 25 }
]

export const OrganizerStatusOptionsList = [
    { label: "DELIVERED", value: 1 },
    { label: "PARTIALLY COMPLETED", value: 2 },
    { label: "COMPLETED", value: 3 },
    { label: "DOWNLOADED", value: 4 },
    { label: "DROPPED", value: 5 },
    { label: "MANUALLY COMPLETED", value: 6 },
    { label: "CLOSED BY FIRM", value: 7 },
    { label: "QUESTIONNAIRE COMPLETED", value: 8 }
]
