import * as bootbox from 'bootbox';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { RouteComponentProps } from 'react-router-dom';
import { logger } from 'src/routes';
import { IUserProfile } from '../../../../components/navigation/profile/ProfileObjects';
import { IPartnerModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { ReportProblem } from '../../../../ReportProblem/ReportProblem';
import {
    initialReportProblemOrganizerData, IReportProblemDetails,
    IReportProblemOrganizerData, ReportedStep, ReportProblemTypes
} from '../../../../ReportProblem/ReportProblemModel';
import * as AdditionalEsignDocumentStore from '../../../store/AdditionalEsignDocumentStore';
import { formattedClientName, getTaxSoftwareType } from '../../Helper/OrganizerHelperFunctions';
import ClientInfoModal from '../Modals/ClientInfoModal';
import { GetNumberOfDays } from './../../../../components/helper/HelperFunctions';
import { HideLoader, ShowLoader } from './../../../../components/helper/Loader';
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import { SortDirections } from './../../../../components/reports/Filters';
import { TaxSoftwareType } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IFilters } from './../../../../SignatureFlow/components/Report/SignatureFlowReportFilter';
import * as CompanyStore from './../../../../store/company/CompanyStore';
import * as UserProfileStore from '../../../../store/UserManagement/UserProfileStore';
import * as UserSettingStore from '../../../../store/UserManagement/UserSettingStore';
import * as UserStoreSettings from '../../../../store/UserManagement/UserSettingStore';
import * as UserStore from '../../../../store/UserManagement/UserStore';
import * as CustomQuestionsStore from 'src/store/settings/CustomQuestions/CustomQuestionsStore';
import {
    BatchModel, EngagementType, GroupType, initialBatchModel, initialOrganizerDocument, OrganizerClient, OrganizerDocument, OrganizerDocumentUploadType
} from './../../../models/OrganizerComponentModels';
import {
    apiPrefix, BatchStoreState, OrganizerBlankTemplateStoreState, OrganizerPageSelectorStoreState, OrganizerPrepareMessageStoreState,
    OrganizerSettingsStoreState
} from './../../../models/OrganizerStoreModels';
import * as BatchStore from './../../../store/BatchStore';
import * as BlankOrganizerTemplateStore from './../../../store/BlankOrganizerTemplateStore';
import * as CommonStore from './../../../store/CommonStore';
import * as CompanySettingsStore from './../../../store/OrganizerCompanySettingsStore';
import * as OrganizerPageSelectorStore from './../../../store/OrganizerPageSelectorStore';
import * as PreparerMessages from './../../../store/OrganizerPreparerMessageStore';
import * as OrganizerUpload from './../../../store/OrganizerUploadStore';
import * as UserSignatureStore from './../../../store/UserSignatureStore';
import { DeleteInProcessConstants, DeleteOrganizerFromBatchConstants } from './../../Helper/OrganizerConstants';
import OrganizerReportToolbar from './../AllOrganizer/OrganizerReportToolbar';
import ProcessOrganizerModal from './../AllOrganizer/ProcessOrganizerModal/ProcessOrganizerDocumentModal';
import { BatchExportExcelModal } from './../Modals/BatchExportExcelModal';
import { BatchInfoModal } from './../Modals/BatchInfoModal';
import { OrganizerUploadModalSelector } from './../UploadDocumentsModal/OrganizerUploadDocumentModal';
import { BatchOrganizerReportTable } from './BatchOrganizerReportTable';
import { InProcessInfoModal } from './InProcessInfoModal';
import { ProcessBatchModal } from './ProcessBatchModalComponents/ProcessBatchModal';
import { DeleteModal as OrganizerDeleteModal } from '../Modals/DeleteModal'
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { ILocations } from 'src/Core/ViewModels/Company/CompanyViewModel';

type BatchOrganizerReportProps = {
    companySettingsStore: OrganizerSettingsStoreState;
    batchStore: BatchStoreState;
    preparerMessageStore: OrganizerPrepareMessageStoreState;
    users: UserStore.ISSRUserData;
    partners: UserStore.IPartnerData;
    companyData: CompanyStore.ICompanyData;
    reportSelector: OrganizerPageSelectorStoreState;
    templateStore: OrganizerBlankTemplateStoreState;
    userSettingStore: UserStoreSettings.UserSettings;
    userProfile: IUserProfile;
    userSettings: UserSettingStore.UserSettings;
    userSignatures: UserSignatureStore.IUserSignatures;
    additionEsignDocumentStore: AdditionalEsignDocumentStore.IBlobFile;
    userLocations: UserStore.ILocationData;
}
    & typeof OrganizerUpload.actionCreators
    & typeof CompanySettingsStore.ActionCreators
    & typeof BatchStore.actionCreator
    & typeof UserStore.actionCreators
    & typeof PreparerMessages.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof BlankOrganizerTemplateStore.actionCreators
    & typeof OrganizerPageSelectorStore.actionCreators
    & typeof UserStoreSettings.actionCreators
    & typeof CommonStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof UserSettingStore.actionCreators
    & typeof UserSignatureStore.actionCreators
    & typeof AdditionalEsignDocumentStore.actionCreators
    & typeof CustomQuestionsStore.actionCreators
    & RouteComponentProps<{}>

interface BatchOrganizerReportState {
    taxSoftware: TaxSoftwareType;
    showUpload: boolean;
    page: number;
    selectedRows: number[];
    filter: IFilters;
    sortName: string;
    sortOrder: string;
    filterBatchName: string;
    filterUploadedOn?: Date;
    filterDocumentStatus: any;
    filterTaxYear: any;
    filterEngagementType: EngagementType;
    filterEro: string;
    filterLocation: string;
    filterAssignedTo: string;
    refreshDelay?: boolean;
    processOrganizer: {
        documentId: number;
        document: OrganizerDocument;
        show: boolean;
        batchGuid: string;
    };
    processBatch: {
        batchId: string;
        batch: BatchModel;
        show: boolean;
    };
    batchInfo: {
        batchId: string;
        batch: BatchModel;
        show: boolean;
    };
    clientInfoModal: {
        documentId: number;
        model: OrganizerClient[];
        show: boolean;
        clientId: string;
        batchGuid: string;
    };
    reportProblemModal: {
        documentId: number;
        batchGuid: string;
        organizerData: IReportProblemOrganizerData;
        show: boolean;
    };
    deleteOrganizerModal: {
        documentId: number;
        batchGuid: string;
        pageNo: number;
        searchString: string;
        show: boolean;
    };
    processLoader: boolean;
    uploadType: OrganizerDocumentUploadType;
    expandedRows: number[];
    eroUsers: IPartnerModel[];
    defaultERO: number;
    exportExcelShow: boolean;
    inProcessInfoModal: {
        batchName: string;
        show: boolean;
    };
    batchOrganizersCountList: Array<any>;
    userLocations: UserStore.ILocationData;
}

const PAGESIZE = 20;
const NO_INDEX = -1;
const BATCH_ORGANIZER_PAGE_NAME = 'Batch Organizers Report';

export class BatchOrganizerReport extends React.Component<BatchOrganizerReportProps, BatchOrganizerReportState>{
    private reportTableRef;
    constructor(props: any) {
        super(props);
        this.state = {
            showUpload: false,
            filter: {
                fields: {},
                name: "",
                searchText: "",
                sort: {
                    column: "",
                    direction: SortDirections.Descending
                }
            },
            page: 1,
            selectedRows: [],
            sortName: "",
            taxSoftware: TaxSoftwareType.None,
            sortOrder: "desc",
            filterBatchName: '',
            filterUploadedOn: undefined,
            filterDocumentStatus: '',
            filterEngagementType: EngagementType.None,
            filterTaxYear: '',
            filterEro: '',
            filterLocation: '',
            filterAssignedTo: '',
            processOrganizer: {
                document: initialOrganizerDocument,
                documentId: 0,
                show: false,
                batchGuid: ""
            },
            processBatch: {
                batchId: "",
                show: false,
                batch: initialBatchModel
            },
            batchInfo: {
                batchId: "",
                show: false,
                batch: initialBatchModel
            },
            clientInfoModal: {
                documentId: 0,
                model: [],
                show: false,
                clientId: '',
                batchGuid: ''
            },
            reportProblemModal: {
                documentId: 0,
                batchGuid: '',
                organizerData: initialReportProblemOrganizerData,
                show: false
            },
            deleteOrganizerModal: {
                documentId: 0,
                batchGuid: '',
                pageNo: 0,
                searchString: '',
                show: false
            },
            processLoader: false,
            uploadType: OrganizerDocumentUploadType.Batch,
            expandedRows: [],
            eroUsers: this.props.partners?.partners || [],
            defaultERO: 0,
            exportExcelShow: false,
            inProcessInfoModal: {
                batchName: "",
                show: false
            },
            batchOrganizersCountList: [],
            userLocations: this.props.userLocations || [],
        }
    }

    componentDidMount() {
        this.props.requestBatches(this.buildQuery(this.state.page), true);
        this.props.requestOrganizerCompanySettings();
        this.props.requestAllSSRUsers(true);
        this.props.requestPreparerMessages();
        this.props.requestUserSettings(true);
        this.props.requestUserProfile();
        this.props.requestCompanySignatureUploadLink();
        this.props.requestDownloadPath(this.props.userProfile.userId);
        this.props.requestDelegatedSigners(this.props.userProfile.userId);
    }

    componentWillReceiveProps(nextProps: BatchOrganizerReportProps) {
        if (this.state.processBatch.batchId) {
            const batch = nextProps.batchStore.batchModel.batches.find(x => x.id === this.state.processBatch.batchId);
            if (batch) {
                this.setState({
                    processBatch: {
                        ...this.state.processBatch,
                        batch: {
                            ...this.state.processBatch.batch,
                            organizerPdf: batch.organizerPdf,
                            csvData: batch.csvData,
                            formGroups: batch.formGroups,
                            additionalEsignDocuments: ((batch.additionalEsignDocuments && batch.additionalEsignDocuments.length > 0) ? batch.additionalEsignDocuments : this.state.processBatch?.batch?.additionalEsignDocuments)
                        }
                    }
                });
            }
        }


        if (nextProps.userSettingStore.settings && nextProps.userSettingStore.settings.defaultUserSettings) {
            this.setState({
                defaultERO: nextProps.userSettingStore.settings.defaultUserSettings.eroUser
            });
        }

        if (this.props.userLocations !== nextProps.userLocations) {
            this.setState({
                userLocations: nextProps.userLocations
            });
        }

        if (this.state.processOrganizer.documentId) {
            const batch = nextProps.batchStore.batchModel.batches.find(x => x.id === this.state.processOrganizer.batchGuid);
            if (batch) {
                let organizer = batch.organizers.documents.find(x => x.id === this.state.processOrganizer.documentId);
                if (organizer) {
                    if (organizer.id === this.state.processOrganizer.document.id) {
                        organizer = {
                            ...this.state.processOrganizer.document,
                            processStatus: organizer.processStatus,
                            document: organizer.document
                        }
                    }
                    this.setState({
                        processOrganizer: {
                            ...this.state.processOrganizer,
                            document: organizer
                        }
                    });
                }
            }
        }

        if (this.state.clientInfoModal.documentId) {
            const batch = nextProps.batchStore.batchModel.batches.find(x => x.id === this.state.clientInfoModal.batchGuid);
            if (batch) {
                const organizer = batch.organizers.documents.find(x => x.id === this.state.clientInfoModal.documentId);
                if (organizer && organizer.organizerClients) {
                    this.setState({
                        clientInfoModal: {
                            ...this.state.clientInfoModal,
                            model: organizer.organizerClients
                        }
                    });
                }
            }
        }

        if (this.state.reportProblemModal.documentId) {
            const batch = nextProps.batchStore.batchModel.batches.find(x => x.id === this.state.reportProblemModal.batchGuid);
            if (batch) {
                const organizer = batch.organizers.documents.find(x => x.id === this.state.reportProblemModal.documentId);
                if (organizer && organizer.organizerClients) {
                    this.setState({
                        reportProblemModal: {
                            ...this.state.reportProblemModal,
                            organizerData: { ...this.state.reportProblemModal.organizerData, clients: organizer.organizerClients }
                        }
                    });
                }
            }
        }

        if (this.props.partners?.partners !== nextProps.partners?.partners && nextProps.partners.partners.length > 0) {
            this.setState({
                eroUsers: nextProps.partners.partners
            });
        }
    }

    buildQuery = (page: number) => {
        this.changeExpandedRows([]);
        const { filterBatchName, filterUploadedOn,
            filterDocumentStatus, filterEngagementType,
            filterTaxYear,
            sortName, sortOrder, filterEro, filterAssignedTo, filterLocation
        } = this.state;

        return `?pageNo=${page}&pageSize=${PAGESIZE}&sortBy=${sortName}&sortOrder=${sortOrder}&filterBatchName=${filterBatchName}` +
            `${filterUploadedOn ? `&filterUploadedOn=${filterUploadedOn}` : ''}` +
            `&filterDocumentStatus=${filterDocumentStatus}&filterEngagementType=${filterEngagementType}&filterTaxYear=${filterTaxYear}&filterERO=${filterEro}&filterAssignedTo=${filterAssignedTo}&filterLocationId=${filterLocation}`
    }

    onRowSelect = (row: any, isSelected: any, e: any) => {
        const newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                const index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            const count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }

    onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            selectedRows: []
        }, this.loadDocuments);
    }

    onSortChange = (sortName: string, sortOrder: string, column: number) => {
        const temp: IFilters = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder === "asc" ? SortDirections.Ascending : SortDirections.Descending;
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder === "asc" ? "asc" : "desc",
            selectedRows: []
        }, () => this.fetchDocuments(1, PAGESIZE));
    }

    onFilterNameChange = (event: any) => {
        const temp: IFilters = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }

    onPageReload = () => {
        this.setState(
            { refreshDelay: true }, () => {
                const query = this.buildQuery(this.state.page);
                this.props.requestBatches(query, true, () => {
                    this.setState({
                        refreshDelay: false,
                        selectedRows: []
                    });
                });
            });
    }

    onFilterChange = (dataField: any) => {

        const newState = {
            filterBatchName: '',
            filterUploadedOn: undefined,
            filterDocumentStatus: '',
            filterEngagementType: EngagementType.None,
            filterTaxYear: '',
            filterEro: '',
            filterLocation : '',
            filterAssignedTo: ''
        } as BatchOrganizerReportState;

        let isClearFilter = true;
        let filterStatus: any;
        let filterTaxYear: any;
        let filterLocationState: any;

        for (const field of Object.keys(dataField)) {
            const data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }

            switch (field) {

                case 'name':
                    newState.filterBatchName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'uploadedOn':
                    newState.filterUploadedOn = GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'engagementType':
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'ero':
                    newState.filterEro = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'locationName':
                    filterLocationState = dataField[field].value ? dataField[field].value : dataField[field];
                    filterLocationState = (filterLocationState === "-1") ? "" : filterLocationState;
                    this.setState({ filterLocation: filterLocationState });
                    newState.filterLocation = filterLocationState;
                    break;
                case 'assignedTo':
                    newState.filterAssignedTo = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'status':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = (filterStatus === "-1") ? "" : filterStatus;
                    this.setState({ filterDocumentStatus: filterStatus });
                    newState.filterDocumentStatus = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = (filterTaxYear === "-1") ? "" : filterTaxYear;
                    this.setState({ filterTaxYear: filterTaxYear });
                    newState.filterTaxYear = ((dataField[field].value) && (dataField[field].value !== "-1")) ? dataField[field].value : "";
                    break;
            }
        }

        if (this.isFilterChanged(newState)) {
            this.setState({ ...newState, page: 1, selectedRows: [] },
                () => {
                    const organizerReportTable: any = this.reportTableRef;
                    if (!organizerReportTable.isAppliedFilter)
                        this.props.requestBatches(this.buildQuery(this.state.page))

                })
        }
    }

    isFilterChanged = (newState: BatchOrganizerReportState): boolean => {
        return (
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterDocumentStatus !== this.state.filterDocumentStatus ||
            newState.filterUploadedOn !== this.state.filterUploadedOn ||
            newState.filterTaxYear !== this.state.filterTaxYear ||
            newState.filterBatchName !== this.state.filterBatchName ||
            newState.filterEro !== this.state.filterEro ||
            newState.filterAssignedTo !== this.state.filterAssignedTo ||
            newState.filterLocation != this.state.filterLocation
        )
    }

    loadDocuments = () => {
        const queryString = this.buildQuery(this.state.page);
        this.props.requestBatches(queryString);
    }

    fetchDocuments = (page: number, sizePerPage: number) => {
        const queryString = this.buildQuery(page);
        this.setState({ page: page }, () => { this.props.requestBatches(queryString) });
    }

    batchDelete = (index: any) => {
        const ids = this.onPopupOpen(index);
        const obj = this;
        bootbox.confirm({
            title: DeleteInProcessConstants.Title,
            message: DeleteInProcessConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader("Deleting...Please wait");
                    obj.props.deleteBatch(ids[0],
                        this.onDeleteCompletion,
                        () => { HideLoader(); });
                }
            }
        });
    }

    onDeleteCompletion = () => {
        HideLoader();
        this.unSelectRows();
        this.onPageReload();
    }

    onUploadReturnsOpen = (taxSoftware: TaxSoftwareType) => {
        if (this.state.uploadType === OrganizerDocumentUploadType.Proforma) {
            logger?.trackPageView("Upload proforma document");
        }
        else {
            logger?.trackPageView("Upload batch document");
        }
        this.setState({
            showUpload: true,
            taxSoftware: taxSoftware
        });
    }

    onUploadDocumentHide = () => {
        logger?.trackPageView(BATCH_ORGANIZER_PAGE_NAME);
        this.setState({
            showUpload: false,
            taxSoftware: TaxSoftwareType.None
        })
    }

    onBatchInfoOpen = (rowIndex: number) => {
        logger?.trackPageView("Edit BatchInfo Modal");
        const batch = this.props.batchStore.batchModel.batches[rowIndex];
        this.setState({
            batchInfo: {
                batch: cloneDeep(batch),
                batchId: batch.id,
                show: true
            }
        })
    }

    onBatchInfoClose = () => {
        logger?.trackPageView(BATCH_ORGANIZER_PAGE_NAME);
        this.setState({
            batchInfo: {
                batch: initialBatchModel,
                batchId: "",
                show: false
            }
        })
    }

    onBatchInfoSave = () => {
        const { batch } = this.state.batchInfo;
        this.props.updateBatchInfo(batch.batchName, batch.id, this.onBatchInfoClose);
    }

    onProcessBatch = (rowIndex: number) => {
        logger?.trackPageView("Process batch organizer modal");
        const batch = this.props.batchStore.batchModel.batches[rowIndex];
        this.setState({
            processLoader: true
        });
        this.props.requestDelegatedSigners(this.props.userProfile.userId);
        this.props.requestBatch(batch.id, this.processBatchCallback, () => {
            this.setState({
                processLoader: false
            })
        });
    }

    processBatchCallback = (batch: BatchModel) => {

        this.props.requestBatchPdf(batch.processInfoGuid, batch.id, batch.taxYear);
        //update status
        //to-do
        //Lock org to the user
        const processBatch = this.props.batchStore.batchModel.batches.find(doc => doc.id === batch.id);
        if (processBatch) {
            this.props.requestBatchError(1, 10, batch.id, () => {
                this.setState(preState => ({
                    processLoader: false,
                    processBatch: {
                        ...preState.processBatch,
                        show: true
                    }
                }));
            });

            this.setState(preState => ({
                processBatch: {
                    ...preState.processBatch,
                    batch: cloneDeep(batch),
                    batchId: batch.id,
                }
            }));
        } else {
            VenusNotifier.Warning("Something went wrong! Please refresh the page and try again!", null);
        }
    }

    processBatchClose = () => {
        logger?.trackPageView(BATCH_ORGANIZER_PAGE_NAME);
        this.setState({
            processBatch: {
                batch: initialBatchModel,
                batchId: "",
                show: false
            }
        })
    }

    onProcessOrganizer = (batchIndex: number, rowIndex: number) => {
        logger?.trackPageView("View Batch Organizer");
        const batch = this.props.batchStore.batchModel.batches[batchIndex];
        const organizer = batch.organizers.documents[rowIndex];
        this.setState(prevState => ({
            processLoader: true,
            processOrganizer: {
                ...prevState.processOrganizer,
                documentId: organizer.id,
                batchGuid: batch.id
            }
        }));
        this.props.requestBatchOrganizer(organizer.id, batch.id, this.processOrganizerCallback, () => {
            this.setState({
                processLoader: false
            });
        });
    }

    processOrganizerCallback = (document: OrganizerDocument) => {
        const { batchGuid, documentId } = this.state.processOrganizer;
        this.props.requestBatchOrganizerPdfDocument(document.processInfoGuid, batchGuid, document.id, document.taxYear);
        //to-do
        //Lock org to the user

        const batch = this.props.batchStore.batchModel.batches.find(x => x.id === batchGuid);
        if (batch) {
            const organizer = batch.organizers.documents.find(x => x.id === documentId);
            organizer &&
                this.setState({
                    processLoader: false,
                    processOrganizer: {
                        ...this.state.processOrganizer,
                        document: cloneDeep(organizer),
                        show: true
                    }
                });
        } else {
            VenusNotifier.Warning("Something went wrong! Please refresh the page and try again!", null);
        }
    }

    onCloseProcessOrganizer = (documentId: number) => {
        //unlock user of organizer-Need to check whether unlock user of organizer is required here.
        logger?.trackPageView(BATCH_ORGANIZER_PAGE_NAME);
        this.setState({
            processOrganizer: {
                documentId: 0,
                show: false,
                document: initialOrganizerDocument,
                batchGuid: ""
            }
        })
    }

    updateDocument = (document: OrganizerDocument) => {
        this.setState(preState => ({
            processOrganizer: {
                ...preState.processOrganizer,
                document: document
            }
        }));
    }

    onPopupOpen = (rowIndex: number) => {
        if (rowIndex !== NO_INDEX) {
            return [this.props.batchStore.batchModel.batches[rowIndex].id];
        }
        if (this.state.selectedRows.length === 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    selectedDocumentIds = () => {
        const ids: string[] = [];
        this.state.selectedRows.map((row, i) => {
            const selectedDocument = this.props.batchStore.batchModel.batches[row];
            if (selectedDocument) {
                ids.push(selectedDocument.id);
            }
        });
        return ids;
    }

    unSelectRows = () => {
        this.setState({
            selectedRows: []
        });
    }

    onChangeUploadType = (option: any) => {
        this.setState({
            uploadType: option.value
        });
    }

    changeExpandedRows = (rows: number[]) => {
        this.setState({
            expandedRows: rows
        });
    }

    onClientInfoOpen = (batchIndex: number, rowIndex: number) => {
        logger?.trackPageView("Batch Organizer ClientInfo Modal");
        const batch = this.props.batchStore.batchModel.batches[batchIndex];
        const organizer = batch.organizers.documents[rowIndex];
        if (organizer) {

            this.setState({
                clientInfoModal: {
                    documentId: organizer.id,
                    model: organizer.organizerClients,
                    show: true,
                    clientId: organizer.clientId,
                    batchGuid: batch.id
                }
            }, () => this.props.requestBatchOrganizerClients(organizer.id, batch.id))
        }
    }

    onReportProblemOpen = (batchIndex: number, rowIndex: number) => {
        const batch = this.props.batchStore.batchModel.batches[batchIndex];
        const organizer = batch.organizers.documents[rowIndex];
        if (organizer) {
            this.setState({
                reportProblemModal: {
                    ...this.state.reportProblemModal,
                    documentId: organizer.id,
                    batchGuid: batch.id,
                    show: true,
                    organizerData: {
                        ...this.state.reportProblemModal.organizerData,
                        returnType: organizer.engagementType.toString(),
                        taxYear: organizer.taxYear,
                        clientId: organizer.clientId,
                        processStatus: organizer.processStatus.toString(),
                        taxpayerName: formattedClientName(organizer.taxpayerName),
                        clients: organizer.organizerClients,
                        documentId: organizer.id,
                        taxSoftware: getTaxSoftwareType(organizer.taxSoftware)
                    }
                }
            }, () => this.props.requestBatchOrganizerClients(organizer.id, batch.id))
        }
    }

    onReportProblemCancel = () => {
        this.setState({ reportProblemModal: { ...this.state.reportProblemModal, organizerData: initialReportProblemOrganizerData, batchGuid: '', documentId: 0, show: false } })
    }

    onReportProblemSave = (problemDetails: IReportProblemDetails) => {

        let url = `${apiPrefix}Batch/SaveHelpReportProblem`;

        this.props.reportProblemSave(
            problemDetails,
            url,
            (data) => {
                this.setState({
                    reportProblemModal: {
                        ...this.state.reportProblemModal,
                        organizerData: initialReportProblemOrganizerData,
                        batchGuid: '',
                        documentId: 0,
                        show: false
                    }
                });
                VenusNotifier.Success("Problem reported successfully. Your Tracking ID is " + data + ".", "Report a Problem");
            });
    }

    onClientInfoClose = () => {
        logger?.trackPageView(BATCH_ORGANIZER_PAGE_NAME);
        this.setState({
            clientInfoModal: {
                documentId: 0,
                model: [],
                show: false,
                clientId: '',
                batchGuid: ''
            }
        })
    }

    updateBatch = (batch: BatchModel) => {
        this.setState(preState => ({
            processBatch: {
                ...preState.processBatch,
                batch: batch
            }
        }));
    }

    onBatchNameChange = (e: any) => {
        this.setState({
            batchInfo: {
                ...this.state.batchInfo,
                batch: {
                    ...this.state.batchInfo.batch,
                    batchName: e.target.value
                }
            }
        })
    }

    onBatchExportExcelToggle = (show: boolean) => {
        this.setState({
            exportExcelShow: show
        });
    }

    onExportToExcel = (callback: () => void) => {
        this.props.exportBatchOrganizersAsExcel(this.buildQuery(this.state.page), () => {
            callback();
            this.onBatchExportExcelToggle(true);
        });
    }

    generateBatchIndividualTaxPayerView = (document: OrganizerDocument, callback: () => void) => {
        const { processOrganizer: { batchGuid } } = this.state;
       
        this.props.generateBatchIndividualTaxPayerView(document, batchGuid, callback);
    }

    updateOrganizerInBatch = (document: OrganizerDocument, callback: (id: number) => void) => {
        this.props.updateOrganizerInBatch(this.removeAdditionalQuestions(document), callback);
    }

    private removeAdditionalQuestions = (document: OrganizerDocument) => {
        let formGroup = document.formGroups.filter(a => a.type != GroupType.AdditionalQuestions);
        return {
            ...document,
            formGroups: formGroup
        };
    }

    getUploadLinkForAdditionalEsign = (callback: (data?: any) => void) => {
        this.props.getUploadLink(`${apiPrefix}Document/GetBatchAdditionalESignUploadLinkAsync/?documentGuid=${this.state.processBatch.batch.id}&year=${this.state.processBatch.batch.taxYear}`,
            (data) => { callback(data); });
    }

    deleteEsignDocument = (fileGuid: string, callback?: () => void) => {
        this.props.deleteDocument(`${apiPrefix}Document/DeleteBatchAdditionalEsignDocumentAsync/?documentGuid=${this.state.processBatch.batch.id}&fileGuid=${fileGuid}&year=${this.state.processBatch.batch.taxYear}`, () => {
            callback && callback();
        })
    }

    onDeliverCallback = () => {
        logger?.trackPageView(BATCH_ORGANIZER_PAGE_NAME);
        const batchName = this.state.processBatch.batch.batchName;
        this.setState({
            inProcessInfoModal: {
                batchName: batchName,
                show: true
            },
            processBatch: {
                batch: initialBatchModel,
                batchId: "",
                show: false
            }
        });
    }

    onInProcessInfoModalCancel = () => {
        this.setState({ inProcessInfoModal: { ...this.state.inProcessInfoModal, batchName: "", show: false } })
    }

    onFinish = (batch: any) => {
        var companyLocations = this.props.companyData.companyProfile.locations;
        var locationName = companyLocations.find(r=> r.locationId == batch.batchInfo.locationId)?.name;
        
        let batchOrganizerDocument: any = {
            batchInfo: batch.batchInfo,
            questionnaireVM: batch.questionnaireVM,
            locationName: locationName
        }

        this.props.deliverBatch(batchOrganizerDocument, this.onDeliverCallback);
    }

    onDeleteOrganizerFromBatchOpen = (batchIndex: number, rowIndex: number, pageNo: number, searchString: string) => {
        const batch = this.props.batchStore.batchModel.batches[batchIndex];
        const organizer = batch.organizers.documents[rowIndex];
        logger?.trackPageView(`Delete organizer from batch modal. BatchId: ${batch.id}, OrganizerId: ${organizer.id}.`);
        if (organizer) {
            this.setState({
                deleteOrganizerModal: {
                    batchGuid: batch.id,
                    documentId: organizer.id,
                    pageNo: pageNo,
                    searchString: searchString,
                    show: true
                }
            });
        }
    }

    deleteOrganizerFromBacth = () => {
        const { deleteOrganizerModal } = this.state;
        this.props.deleteOrganizerFromBatch(deleteOrganizerModal.batchGuid, deleteOrganizerModal.documentId, () => {
            this.reportTableRef.updatePageNumber(deleteOrganizerModal.pageNo);
            var query = `?pageNo=${deleteOrganizerModal.pageNo}&pageSize=10&searchString=${deleteOrganizerModal.searchString}&batchGuid=${deleteOrganizerModal.batchGuid}`
            this.props.requestBatchOrganizers(deleteOrganizerModal.batchGuid, query, true);
            const batchCount = this.state.batchOrganizersCountList.find(x => x.batchId === deleteOrganizerModal.batchGuid);
            batchCount && this.updateOrganizerCount(deleteOrganizerModal.batchGuid, batchCount.count - 1);
        }, () => { });
        this.closeOrganizerDeleteModal();
    }

    closeOrganizerDeleteModal = () => {
        this.setState({
            deleteOrganizerModal: {
                batchGuid: '',
                documentId: 0,
                pageNo: 0,
                searchString: '',
                show: false
            }
        });
    }

    updateOrganizerCount = (batchGuid: string, organizerCount: number) => {
        var filteredBatchList = this.state.batchOrganizersCountList.filter(x => x.batchId !== batchGuid);
        filteredBatchList.push({ batchId: batchGuid, count: organizerCount });
        this.setState({ batchOrganizersCountList: [...filteredBatchList] });
    }

    //Used for office location dropdown on grid
    getUserLocationData = () => {
        var companyLocations = this.props.companyData.companyProfile.locations;
        var userLocations = this.props.userLocations.locations;
        let locationList: IDropdown[] = [];

        if (companyLocations && userLocations) {
            const commonUserLocations = companyLocations.reduce(
                (result, item) =>
                    userLocations.some(el => el === item.locationId)
                        ? [...result, item]
                        : result,
                []
            );
            if (commonUserLocations) {
                commonUserLocations?.forEach((location: ILocations) => {
                    const locationVal: IDropdown = {
                        label: location.name,
                        value: location.locationId
                    }
                    locationList.push(locationVal);
                })
            }

            locationList.push({
                label: 'Blanks',
                value: -2
            });
        }

        return locationList;
    }

    render() {
        const { showUpload, selectedRows, page, taxSoftware, processOrganizer,
            processLoader, uploadType, expandedRows, eroUsers,
            batchInfo, processBatch, clientInfoModal, defaultERO, exportExcelShow, inProcessInfoModal, deleteOrganizerModal } = this.state;
        const { companySettingsStore, getUploadLink,
            deleteUploadedDocument, submitOrganizer, submitOrganizerWithSeperateEL,
            companyData, users: { users }, preparerMessageStore: { preparerMessages },
            batchStore: { loading, batchModel: { count, batches }, organizerTableLoading, popupLoader, csvLoader },
            reportSelector: { selectedOrganizerOption }, changeOrganizerOptions,
            deleteDocument, requestBatchOrganizers, updateBatch, requestBatchError, updateBatchError,
            checkErrors, downloadCSVTemplate, checkWarnings, updateOrganizerInBatch, generateBatchTaxPayerView,
            deleteBatchAdditionalEsignDocs, requestAdditionalEsignDocsBatch, updateAdditionalEsignDocuments,
            getCustomQuestions, getCustomQuestionTemplate, getCustomQuestion
        } = this.props;
        return (
            <div>
                {
                    <OrganizerReportToolbar
                        pageTitle="Batch Organizers In-Process"
                        onDelete={this.batchDelete}
                        selectedDocumentCount={selectedRows.length}
                        onUploadClick={this.onUploadReturnsOpen}
                        taxSoftwares={companyData.commonSettings?.taxSoftwareSetting ? companyData.commonSettings.taxSoftwareSetting.taxSoftware : []}
                        onChangeUploadType={this.onChangeUploadType}
                        selectedUploadTypeId={uploadType}
                        batchView={true}
                    />
                }
                <br />
                <LoadingOverlay style={{ height: '100%' }}>
                    <BatchOrganizerReportTable
                        ref={(instance) => (this.reportTableRef = instance)}
                        batches={batches}
                        isLoading={false}
                        onFilterChange={this.onFilterChange}
                        onPageChange={this.onPageChange}
                        onRowSelect={this.onRowSelect}
                        onSelectAll={this.onSelectAll}
                        onSortChange={this.onSortChange}
                        pageNo={page}
                        pageSize={PAGESIZE}
                        selectedRows={selectedRows}
                        totalRows={count}
                        onProcessOrganizer={this.onProcessOrganizer}
                        onBatchDeleteOpen={this.batchDelete}
                        pageReload={this.onPageReload}
                        requestBatchOrganizers={requestBatchOrganizers}
                        tableLoader={organizerTableLoading}
                        expandedRows={expandedRows}
                        changeExpandedRows={this.changeExpandedRows}
                        changeOrganizerOptions={changeOrganizerOptions}
                        selectedOrganizerOption={selectedOrganizerOption}
                        onEditBatchInfoOpen={this.onBatchInfoOpen}
                        onProcessBatch={this.onProcessBatch}
                        onClientInfoOpen={this.onClientInfoOpen}
                        onReportProblemOpen={this.onReportProblemOpen}
                        onOrganizerDeleteOpen={this.onDeleteOrganizerFromBatchOpen}
                        onExportToExcel={this.onExportToExcel}
                        refreshDelay={this.state.refreshDelay}
                        batchOrganizersCountList={this.state.batchOrganizersCountList}
                        updatebatchOrganizersCountList={this.updateOrganizerCount}
                        userLocationList={this.getUserLocationData()}
                    />
                    <Loader loading={processLoader || loading} text={"Loading..."} />
                </LoadingOverlay>

                <BatchExportExcelModal
                    show={exportExcelShow}
                    onClose={() => this.onBatchExportExcelToggle(false)}
                />

                <BatchInfoModal
                    batchName={batchInfo.batch.batchName}
                    onClose={this.onBatchInfoClose}
                    onSave={this.onBatchInfoSave}
                    returnType={batchInfo.batch.engagementType}
                    taxYear={batchInfo.batch.taxYear}
                    show={batchInfo.show}
                    onBatchNameChange={this.onBatchNameChange}
                />
                <OrganizerUploadModalSelector
                    show={showUpload}
                    getUploadLink={getUploadLink}
                    onHide={this.onUploadDocumentHide}
                    pageReload={this.onPageReload}
                    submitDocuments={submitOrganizer}
                    taxSoftware={taxSoftware}
                    deleteDocument={deleteUploadedDocument}
                    uploadType={uploadType}
                    deleteBatchDocument={deleteDocument}
                    defaultERO={defaultERO}
                    eroUsers={eroUsers}
                    downloadCSVTemplate={downloadCSVTemplate}
                    submitSingleDocuments={submitOrganizerWithSeperateEL}
                    enableEngagementLetter={companySettingsStore.companySettings.defaultSettings.enableEngagementLetter}
                    userLocations={this.getUserLocationData().filter(x=> x.value != -2 )}
                />
                {
                    processOrganizer.show &&
                    <ProcessOrganizerModal
                        document={processOrganizer.document}
                        onClose={this.onCloseProcessOrganizer}
                        order={1}
                        company={companyData}
                        preparerMessages={preparerMessages}
                        users={users}
                        updateDocument={this.updateDocument}
                        companySettings={companySettingsStore.companySettings}
                        readOnlyView={true}
                        showSignaturePanel={false}
                        getCustomQuestionTemplates={getCustomQuestions}
                        getCustomQuestionTemplate={getCustomQuestionTemplate}
                        getCustomQuestion={getCustomQuestion}
                        isCustomQuestionEditable={false}
                        additionalQuestionsReadonlyMode={processOrganizer.show}
                        onSave={this.updateOrganizerInBatch}
                        generateTaxpayerView={this.generateBatchIndividualTaxPayerView}
                        sourceDocumentEnabled={this.props.companySettingsStore.companySettings?.sourceDocumentSettings?.enabledSourceDocuments}
                        getUploadLink={this.getUploadLinkForAdditionalEsign}
                        requestDocuments={requestAdditionalEsignDocsBatch}
                        deleteBlobAdditionalEsign={this.deleteEsignDocument}
                        deleteEsignDocuments={deleteBatchAdditionalEsignDocs}
                        updateAdditionalEsignDocument={updateAdditionalEsignDocuments}
                        userSettings={this.props.userSettings}
                        userBasicProfile={this.props.userProfile}
                        userSignatures={this.props.userSignatures}
                        requestDownloadPath={this.props.requestDownloadPath}
                        additionalEsignReadOnly={true}
                        batchId={processOrganizer.batchGuid}
                        convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                        batchView={true}
                        isBatchOrganizer={true}
                        partners={this.props.partners}
                    />
                }
                {
                    processBatch.show &&
                    <ProcessBatchModal
                        batch={processBatch.batch}
                        onClose={this.processBatchClose}
                        updateBatch={this.updateBatch}
                        company={companyData}
                        companySettings={companySettingsStore.companySettings}
                        users={users}
                        onFinish={this.onFinish}
                        onSave={updateBatch}
                        csvLoader={csvLoader}
                        requestBatchError={requestBatchError}
                        updateBatchError={updateBatchError}
                        checkErrors={checkErrors}
                        checkWarnings={checkWarnings}
                        documentUrl={processBatch.batch.organizerPdf && processBatch.batch.organizerPdf.parts[0].fileName}
                        showSignaturePanel={true}
                        getCustomQuestionTemplates={getCustomQuestions}
                        getCustomQuestionTemplate={getCustomQuestionTemplate}
                        getCustomQuestion={getCustomQuestion}
                        isCustomQuestionEditable={true}
                        generateTaxPayerView={generateBatchTaxPayerView}
                        sourceDocumentEnabled={this.props.companySettingsStore.companySettings?.sourceDocumentSettings?.enabledSourceDocuments}
                        getUploadLink={this.getUploadLinkForAdditionalEsign}
                        requestEsignDocuments={requestAdditionalEsignDocsBatch}
                        deleteBlobAdditionalEsign={this.deleteEsignDocument}
                        deleteEsignDocuments={deleteBatchAdditionalEsignDocs}
                        updateAdditionalEsignDocument={updateAdditionalEsignDocuments}
                        userSettings={this.props.userSettingStore}
                        userBasicProfile={this.props.userProfile}
                        userSignatures={this.props.userSignatures}
                        requestDownloadPath={this.props.requestDownloadPath}
                        additionalEsignReadOnly={false}
                        convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                        preparerMessages={preparerMessages}
                        partners={this.props.partners}
                    />
                }

                <ClientInfoModal
                    clientId={clientInfoModal.clientId}
                    isDelivered={false}
                    model={clientInfoModal.model}
                    onClose={this.onClientInfoClose}
                    onSave={() => { }}
                    states={[]}
                    show={clientInfoModal.show}
                    loading={popupLoader}
                    readOnly={true}
                    unDeliveredReport={true}
                    companyLocations={this.props.companyData?.companyProfile?.locations}
                    userLocations={this.props.userLocations}                    
                    onCloseLocationMismatchActionModal={() => { }}
                    onContinueLocationMismatchActionModal={() => { }}
                    onClientManagementComparisonFailed={() => {}}
                    dcoumentId={clientInfoModal.documentId}
                    onLocationChange={() => { }}  
                    showOfficeLocation={false}
                />

                <ReportProblem
                    onCancel={this.onReportProblemCancel}
                    onReportProblemSave={this.onReportProblemSave}
                    show={this.state.reportProblemModal.show}
                    loggedInCPA={this.props.userProfile}
                    companyName={this.props.companyData.companyProfile.companyInfo.companyName}
                    problemStep={ReportedStep.InProcessOrganizer}
                    reportProblemType={ReportProblemTypes.ReturnSpecific}
                    organizerData={this.state.reportProblemModal.organizerData}
                    taxSoftwares={this.props.companyData.commonSettings?.taxSoftwareSetting ? this.props.companyData.commonSettings.taxSoftwareSetting.taxSoftware : []}
                    disableTaxSoftware={true}
                />
                <InProcessInfoModal
                    batchName={inProcessInfoModal.batchName}
                    openModal={inProcessInfoModal.show}
                    onCancel={this.onInProcessInfoModalCancel}
                />
                <OrganizerDeleteModal
                    show={deleteOrganizerModal.show}
                    modalHeader={DeleteOrganizerFromBatchConstants.ModalTitle}
                    modalBody={DeleteOrganizerFromBatchConstants.ModalBody}
                    onClose={this.closeOrganizerDeleteModal}
                    onConfirm={this.deleteOrganizerFromBacth}
                />
            </div>
        );
    }
}
