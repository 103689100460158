export enum actionTypes {
    REQUEST_PRODUCT_LEFTMENU_ITEMS = "request_product_leftmenu_items",
    RECEIVED_PRODUCT_LEFTMENU_ITEMS = "received_product_leftmenu_items",
    COMPLETED_PRODUCT_LEFTMENU_ITEMS = "completed_product_leftmenu_items",
    REQUEST_WIDGET_MENU_ITEMS = "request_widget_menu_items",
    RECEIVED_WIDGET_MENU_ITEMS = "received_widget_menu_items",
    COMPLETED_WIDGET_MENU_ITEMS = "completed_widget_menu_items",
    REQUEST_SUITE_URL = "request_suite_url",
    RECEIVED_SUITE_URL = "received_suite_url",
    COMPLETED_SUITE_URL = "completed_suite_url",
    REQUEST_DISABLE_LEFTMENU = "disable_leftmenu"
}