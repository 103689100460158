import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TinyMCEComponent } from './../../Settings/common/TinyMCEComponent';
import { VariableList } from './../../Settings/common/VariableList';
import { VariableListData } from './../../../models/OrganizerComponentModels';

interface DeliveryPreparerMessageModalProps {
    showState: boolean;
    onHide: (event: any) => void;
    changeStateTinymceBody: (event: any) => void;
    messageBody: string;
    isAllowEdit: boolean;
    updateButtonClick: (event: any) => void;
    saveButtonClick: (event: any) => void;
    buttonText: string;
    variableList: VariableListData[];
    onVariableListExpandToggle: () => void;
    isVariableListExpand: boolean;
}

export const DeliveryPreparerMessageModal: React.StatelessComponent<DeliveryPreparerMessageModalProps> =
    ({ variableList, buttonText, changeStateTinymceBody, isAllowEdit,
        messageBody, onHide, saveButtonClick, showState, updateButtonClick,
        isVariableListExpand, onVariableListExpandToggle
    }) => {
        return (
            <Modal className="preparer-message" show={showState} onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{isAllowEdit == true ? buttonText == "Save" ? "Add" : "Edit":""} Message from Tax Preparer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="padLR-2">
                        <TinyMCEComponent
                            changeStateTinymceBody={changeStateTinymceBody}
                            messageBody={messageBody}
                            readOnly={isAllowEdit == true ? 0 : 1}
                        ></TinyMCEComponent>
                    </div>
                    {
                        isAllowEdit && <div className="marT10">
                            <VariableList variableList={variableList}
                                toggleListExpand={onVariableListExpandToggle}
                                isListExpand={isVariableListExpand}
                            />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer style={{ display: 'initial' }}>
                    {buttonText == "Save" ?
                        <Button
                            variant='info'
                            data-test-auto="3DAECC5E-5429-4508-8B1B-C150C07E57A8"
                            onClick={saveButtonClick}
                            className={isAllowEdit == true ? "pull-right" : "display-button pull-right"}
                        >
                            <i className='glyphicon glyphicon-floppy-disk'></i> {buttonText}
                        </Button>
                        :
                        <Button
                            variant='info'
                            data-test-auto="B3DB7147-F811-43C1-B326-9438DA5B64DF"
                            onClick={updateButtonClick}
                            className={isAllowEdit == true ? "pull-right" : "display-button pull-right"}
                        >
                            <i className='glyphicon glyphicon-floppy-disk'></i> {buttonText}
                        </Button>
                    }
                    <Button
                        className="btn-default pull-right"
                        variant='default'
                        data-test-auto="A9187363-A116-49F9-AF59-4D6E635A2B72"
                        onClick={onHide} ><i className="fas fa-times"></i>
                        Cancel
                </Button>
                </Modal.Footer>
            </Modal>
        )
}