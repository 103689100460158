import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IModalProps } from './ProfileObjects';
import { MyLoginHistoryTable } from './MyLoginHistoryTable';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import * as UserLoginHistory from '../../../store/UserManagement/UserLoginHistoryStore';
import { CheckBoxComponent } from '../../common/CheckBoxComponent';
import * as UserSettingStore from '../../../store/UserManagement/UserSettingStore';
import { IUserDefaultSettingsStates } from 'src/components/navigation/profile/MySettings';


interface MyLoginHistoryProps extends IModalProps {
    requestUserLoginHistory: (pageNo: number) => void;
    userLoginHistory: UserLoginHistory.IUserLoginHistoryState;
    userSettings: UserSettingStore.UserSettings;
    saveSettings: (settings: IUserDefaultSettingsStates) => void;
}

interface MyLoginHistoryState {
    pageNo: number;
    enableLastLogin: boolean;
}
export class MyLoginHistory extends React.Component<MyLoginHistoryProps, MyLoginHistoryState> {
    constructor(props: MyLoginHistoryProps) {
        super(props);
        this.state = {
            pageNo: 1,
            enableLastLogin: true
        }
    }

    componentWillReceiveProps(nextProps: MyLoginHistoryProps) {
        this.setState({
            enableLastLogin: this.props.userSettings.settings.defaultSettings !== undefined ? this.props.userSettings.settings.defaultSettings.enableLastLogin : true
        });
    }

    onPopupClose = () => {
        this.props.onHide();
    }
    onPageChange = (page: number, pageSize: number) => {
        this.setState({
            pageNo:page
        },this.fetchUserLoginHistory)
    }
    fetchUserLoginHistory() {
        const { pageNo } = this.state;
        this.props.requestUserLoginHistory(pageNo);
    }

    private changeLastLogin = (event: any) => {
        let settings = this.props.userSettings.settings;
        settings.defaultSettings.enableLastLogin = event.currentTarget.checked;
        this.props.saveSettings(settings);
        this.setState({
            enableLastLogin: event.currentTarget.checked
        });
    }

    render() { 
        return (
            <Modal show={this.props.showState} onHide={this.onPopupClose} className="my-login-history">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='fa fa-history' style={{ color: 'grey', marginRight: '5px' }}></span>
                        My Login History
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(() => {

                        if (!this.props.userLoginHistory) {
                            return (<div>
                                <h3>Loading...</h3>
                            </div>)
                        }
                        else
                            if (this.props.userLoginHistory.deviceId == "")//For logged in users before this feature, there exists no device_id.
                                return (<div>
                                    <h3>Please Logout and Login again to see your Login History</h3>
                                </div>
                                );
                            else
                                return (
                                    <div>
                                        <div className="textalign-right">
                                            <Button
                                                onClick={() => this.props.requestUserLoginHistory(1)}
                                                className="btn-white btn-only marR10"
                                                style={{ paddingLeft: '12px' }}>
                                                <span className='fas fa-redo-alt' style={{ padding: '4px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}></span>
                                            </Button>
                                        </div>
                                        <LoadingOverlay>
                                            <MyLoginHistoryTable
                                                userLoginHistory={this.props.userLoginHistory}
                                                requestUserLoginHistory={this.props.requestUserLoginHistory}
                                                pageNo={this.state.pageNo}
                                                onPageChange={this.onPageChange} />
                                            <Loader loading={this.props.userLoginHistory.isLoginHistoryLoading} text={"Loading"} />
                                        </LoadingOverlay>
                                    </div>
                                );
                    })()}

                </Modal.Body>
                <Modal.Footer style={{ display: 'initial'}}>
                    <div className="pull-left padT07 div-flex">
                        <CheckBoxComponent
                            id={"lastLoginEnable"}
                            text={"Enable Last Login Prompt"}
                            checked={this.state.enableLastLogin}
                            onChange={this.changeLastLogin}
                            datatestAuto={"989E572E-F52B-466D-B675-D99B6193D66B"}
                        />
                        <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="If enabled, you will see the last login notification every time you login">
                            <i className="glyphicon glyphicon-question-sign"></i>
                        </Link>
                    </div>
                    <Button
                        variant='default'
                        onClick={this.onPopupClose}
                        style={{ float: 'right' }}>
                        <i className='glyphicon glyphicon-remove'></i>
                        Close
                </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}
export default MyLoginHistory;