import React, { FC, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IAddNewClientInformationProps, columnNames, newClientModalConstants } from './newClientModal.model';
import CloseIcon from 'src/components/svg/CloseIcon';
import { rowNames } from "../clientManagement.types";
import { phoneNumberDisplay } from 'src/components/helper/HelperFunctions'
import "../clientManagement.styles.scss";
import { toLower } from 'lodash';

const NewClientModal: FC<IAddNewClientInformationProps> = props => {
    const {
        onClose,
        onCancel,
        onAddNewClientInfo,
        clientInfoComparisonResult
    } = props;

    const [saving, setSaving] = useState<boolean>(false);

    const handleClientInfoSave = () => {
        setSaving(true);
        onAddNewClientInfo();
    }
    const handleClientInfoCancel = () => onCancel();

    const handleClose = () => onClose();

    return (
        <Modal show={true} onHide={handleClose} className="modalClass clientManagementModal">
            <Modal.Header>
                <div>
                    <Modal.Title>{newClientModalConstants.title}</Modal.Title>
                </div>
                <div className="closeIcon" onClick={handleClose}>
                    <CloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <div className="mulishFont">
                    {newClientModalConstants.headerMessage}
                </div>
                <div>
                    <table className="table table-striped clientsListTable mulishFont">
                        <thead className="table-header">
                            <tr>
                                <th className="width70">{columnNames.clientInformation}</th>
                                <th className="widthAuto">
                                    {columnNames.organizer}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="clientRow">
                                <td className="grayColor">{rowNames.clientId}</td>
                                <td>{clientInfoComparisonResult.clientInfoResultData.clientId.taxReturnValue}</td>
                            </tr>
                            <tr className="clientRow">
                                <td className="grayColor">{rowNames.name}</td>
                                <td>{clientInfoComparisonResult.clientInfoResultData.name.taxReturnValue}</td>
                            </tr>
                            {
                                clientInfoComparisonResult.clientInfoResultData.locationName?.taxReturnValue &&
                                <tr className="clientRow">
                                    <td className="grayColor">{rowNames.locationName}</td>
                                    <td>{clientInfoComparisonResult.clientInfoResultData.locationName?.taxReturnValue}</td>
                                </tr>
                            }
                            {
                                clientInfoComparisonResult.clientInfoResultData.ero?.taxReturnValue &&
                                <tr className="clientRow">
                                    <td className="grayColor">{rowNames.ero}</td>
                                    <td>{clientInfoComparisonResult.clientInfoResultData.ero?.taxReturnValue}</td>
                                </tr>
                            }
                            {
                                clientInfoComparisonResult.clientInfoResultData.emailAddress?.taxReturnValue &&
                                <tr className="clientRow">
                                    <td className="grayColor">{rowNames.emailAddress}</td>
                                    <td>{clientInfoComparisonResult.clientInfoResultData.emailAddress?.taxReturnValue}</td>
                                </tr>
                            }
                            {
                                clientInfoComparisonResult.clientInfoResultData.countryCode?.taxReturnValue &&
                                <tr className="clientRow">
                                    <td className="grayColor">{rowNames.countryCode}</td>
                                    <td>{clientInfoComparisonResult.clientInfoResultData.countryCode?.taxReturnValue}</td>
                                </tr>
                            }
                            {
                                clientInfoComparisonResult.clientInfoResultData.mobileNumber?.taxReturnValue &&
                                <tr className="clientRow">
                                    <td className="grayColor">{rowNames.mobileNumber}</td>
                                    <td>{clientInfoComparisonResult.clientInfoResultData.mobileNumber?.taxReturnValue ? phoneNumberDisplay(clientInfoComparisonResult.clientInfoResultData.mobileNumber.taxReturnValue) : ""}</td>
                                </tr>
                            }
                            {
                                clientInfoComparisonResult.clientInfoResultData.isDeceased &&
                                <tr className="clientRow">
                                    <td className="grayColor">{rowNames.isDeceased}</td>
                                    <td>{
                                        clientInfoComparisonResult.clientInfoResultData.isDeceased ?
                                            (toLower(clientInfoComparisonResult.clientInfoResultData.isDeceased.taxReturnValue) == 'true' ? 'Yes' : 'No') : ''}
                                    </td>
                                </tr>
                            }
                            {
                                props.isMarriedFileJointly &&
                                <>
                                    {
                                        clientInfoComparisonResult.clientInfoResultData.spouseName?.taxReturnValue &&
                                        <tr className="clientRow">
                                            <td className="grayColor">{rowNames.spouseName}</td>
                                            <td>{clientInfoComparisonResult.clientInfoResultData.spouseName?.taxReturnValue}</td>
                                        </tr>
                                    }
                                    {
                                        clientInfoComparisonResult.clientInfoResultData.spouseEmailAddress?.taxReturnValue &&
                                        <tr className="clientRow">
                                            <td className="grayColor">{rowNames.spouseEmailAddress}</td>
                                            <td>{clientInfoComparisonResult.clientInfoResultData.spouseEmailAddress?.taxReturnValue}</td>
                                        </tr>
                                    }
                                    {
                                        clientInfoComparisonResult.clientInfoResultData.spouseCountryCode?.taxReturnValue &&
                                        <tr className="clientRow">
                                            <td className="grayColor">{rowNames.countryCode}</td>
                                            <td>{clientInfoComparisonResult.clientInfoResultData.spouseCountryCode?.taxReturnValue}</td>
                                        </tr>
                                    }
                                    {
                                        clientInfoComparisonResult.clientInfoResultData.spouseMobileNumber?.taxReturnValue &&
                                        <tr className="clientRow">
                                            <td className="grayColor">{rowNames.spouseMobileNumber}</td>
                                            <td>{clientInfoComparisonResult.clientInfoResultData.spouseMobileNumber?.taxReturnValue}</td>
                                        </tr>
                                    }
                                    {
                                        clientInfoComparisonResult.clientInfoResultData.spouseIsDeceased?.taxReturnValue &&
                                        <tr className="clientRow">
                                            <td className="grayColor">{rowNames.spouseIsDeceased}</td>
                                            <td>{
                                                clientInfoComparisonResult.clientInfoResultData.spouseIsDeceased ?
                                                    (toLower(clientInfoComparisonResult.clientInfoResultData.spouseIsDeceased.taxReturnValue) == 'true' ? 'Yes' : 'No') : ''}
                                            </td>
                                        </tr>
                                    }
                                </>
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClientInfoCancel}
                    data-test-auto='2D308D0F-1774-4913-8CB7-303259830BCE'
                >
                    {newClientModalConstants.cancelButtonText}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleClientInfoSave}
                    data-test-auto='FED6A288-E247-4A9C-92A5-A375087B7343'
                    disabled={saving}
                >
                    {newClientModalConstants.saveButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default NewClientModal;
