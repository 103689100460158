import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface BatchExportExcelModalProps {
    show: boolean;
    onClose: () => void;
}

const BatchExportExcelModal: React.StatelessComponent<BatchExportExcelModalProps> = ({ show, onClose }) => {

    return <Modal className="download-organizer-modal" show={show} onHide={onClose}>
        <Modal.Header closeButton>
            <Modal.Title>
                <span className='fas fa-file-export modalIcon' ></span>
                Export Report to Excel
                </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{ fontSize: '14px' }}>
                Export process may take some time, you will recieve an email notification when the excel file is available.
                </div>
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onClose} >
                <i className="glyphicon glyphicon-remove" />Close
                    </Button>
        </Modal.Footer>
    </Modal>
}


export { BatchExportExcelModal }

