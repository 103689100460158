import * as React from 'react';
import { Card, Row, Col, Accordion } from 'react-bootstrap';
import { SignatureControlTypes, SignatureGroupType } from '../../../../../../../components/common/TaxReturn';
import { IEROSigner } from '../../../../../../../components/common/ProcessReturnModal/ProcessReturnModels';
import { EsignHelpTextConstant } from '../../../../../Helper/OrganizerConstants';
import { ControlRole, ControlType, DataType, UserGroup } from '../../../../../../models/OrganizerComponentModels';

export interface EROSignatureCardProps {
    title: string;
    selectedEROSigner: IEROSigner;
    eroSigner: IEROSigner[];
    onEROSignerChange(selectedOption: any): void,
    isAssignedToLoggedinUser?: boolean;
    onSignatureControlFocus(focusedGroup: SignatureGroupType): void;
    dragStart(event: any, controlType: ControlType, role: ControlRole, dataType: DataType, signerId:number) : void;
    dragEnd?: (event: any) => void;
    signerId: number;
    toggleExpand: () => void;
    group: UserGroup;
    onFocusChange: (focusGroup: UserGroup) => void;
}

export interface EROSignatureSidebarState {
    open: boolean;
}

export class EROSignatureCard extends React.Component<EROSignatureCardProps, EROSignatureSidebarState>{
    state = {
        open: true,
    };

    private onEROSignerChange=(event:any)=> {
        let selectedEROSigner = this.props.eroSigner.find(x=>x.value==event.target.value);
        this.props.onEROSignerChange(selectedEROSigner);
    }

    private createEROOptions = () => {
        let controlCollection: any[] = [];

        this.props.eroSigner.map((signer, index) => {
            if (signer.eroImage || signer.eroImage != "") {
                controlCollection.push(<option key={index} value={signer.value}>
                    {signer.label}
                </option>);
            }
            else {
                controlCollection.push(<option className={'text-danger'}
                    key={index} value={signer.value} disabled={true}>
                    {signer.label + " (Signature not found)"} </option>);
            }
        });
        return controlCollection;
    }

    public render() {
        return (
            <Accordion defaultActiveKey="0">
                <Card onToggle={() => this.props.toggleExpand()}>
                    <Accordion.Toggle style={{ padding: "10px" }} as={Card.Header} variant="link" eventKey="0">
                        <Card.Title as="h4">
                            {this.props.title}
                        </Card.Title>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body onMouseEnter={() => this.props.onFocusChange(this.props.group)}>
                            <div className="form-horizontal">
                                <Row className="marB15">
                                    <Col sm={12}>
                                        <select
                                            id="ero"
                                            name="form-field-name"
                                            className="form-control"
                                            value={this.props.selectedEROSigner.value}
                                            onChange={this.onEROSignerChange}
                                            data-test-auto="32C60802-06ED-42F6-80E2-244747E4F8F9"
                                            onFocus={() => { this.props.onSignatureControlFocus(SignatureGroupType.Stamp) }}>
                                            {this.createEROOptions()}
                                        </select>
                                    </Col>

                                </Row>
                            </div>
                            <div className="form-horizontal">
                                <Row className="marB15">
                                    <Col sm={9}>
                                        <div>
                                            {
                                                <div
                                                    className="icon-div col-sm-12"
                                                    data-test-auto="dd8fe022-8c43-45b3-a7c1-efd55bc97814"
                                                    draggable={true}
                                                    onDragStart={(event) => { this.props.dragStart(event, ControlType.Signature, ControlRole.Ero, DataType.None,this.props.signerId) }}
                                                    onDragEnd={(event) => { this.props.dragEnd && this.props.dragEnd(event) }}
                                                >
                                                    <i className="fas fa-pen"
                                                        style={{ backgroundColor: '#008000', color: '#FFFFFF', width: '28px', height: '100%' }}>
                                                    </i>
                                                    Add Signature Stamp
                                                </div>
                                            }
                                        </div>
                                    </Col>
                                    <i className="fa fa-question-circle" style={{ color: '#DAA520' }} title={EsignHelpTextConstant.EroSignatureStamp}></i>
                                </Row>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    };
}