import { IDocumentAccess } from '../components/common/TaxReturn';

export function handleResponse(response: any) {
    if (response?.ok) {
        return parseJSON(response.clone());
    }
    else {
        return response?.json().catch((error: any) => {
            // the status was not ok and there was not custom error provided
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
            });
        }).then((json: any) => {
            // the status was not ok but custom error provided
            return Promise.reject({
                status: response.status,
                statusText: json,
            });
        });
    }
}

export function parseJSON(response: any) {
    return response.text()
        .then((text: string) => {
            return text ? JSON.parse(text) : {}
        });
}

export function handleBlob(response: any) {
    if (response.ok) {
        return response.blob();
    }
    else {
        return response.json().catch((error: any) => {
            // the status was not ok and there was not custom error provided
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
            });
        }).then((json: any) => {
            // the status was not ok but custom error provided
            return Promise.reject({
                status: response.status,
                statusText: json,
            });
        });
    }
}

export async function handleBlobwithFileName(response: any) {
    if (response.ok) {
        const contentDisposition = response.headers.get("content-disposition");
        const fileNameMatch = contentDisposition ? /filename="?([^"]*)"?;/g.exec(contentDisposition) : undefined;
        let fileName = '';
        if (fileNameMatch && fileNameMatch.length > 1) {
            fileName = fileNameMatch[1];
        }
        return { filename: fileName, blob: await response.blob() };
    }
    else {
        return response.json().catch((error: any) => {
            // the status was not ok and there was not custom error provided
            return Promise.reject({
                status: response.status,
                statusText: response.statusText,
            });
        }).then((json: any) => {
            // the status was not ok but custom error provided
            return Promise.reject({
                status: response.status,
                statusText: json,
            });
        });
    }
}

export function extractDocumentIds(accessMaps: IDocumentAccess[]) {
    return accessMaps.map((item, i) => {
        return item.documentId;
    })
}