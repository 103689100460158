import * as React from "react";
import { Form } from "react-bootstrap";
import { ISectionItems } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { FormBuilderConstants } from "src/components/helper/Constants";

interface IParagraphQuestionProps {
}

const ParagraphQuestion: React.FC<IParagraphQuestionProps> = () => {
    return (
        <div className="question-type-wrapper">
            <Form.Control placeholder={FormBuilderConstants.QuestionTitle.answerPlaceholder} as="textarea" disabled />
        </div>
    );
};

export default ParagraphQuestion;
