import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ISectionItems } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { CopyIcon, DeleteIcon } from "src/assets/SvgIconCollection";
import { FormBuilderConstants } from "src/components/helper/Constants";
import { getQuestionsLimitErrorMessage, getTotalQuestions } from "src/components/helper/HelperFunctions";
import { IDupicateQuestionPayload } from "src/store/settings/FormBuilder/ActionTypes";
import {
    ChangeRequiredQuestionHandler,
    DeleteQuestionHandler,
    DuplicateQuestionHandler
} from "src/store/settings/FormBuilder/Actions";
import { actionTypes as NotificationAction } from "./../../../../../../../../store/ActionTypes";
import { StatusType } from "src/store/common/NotificationStore";
import { ApplicationState } from "src/store";
import { CqAutomation } from "src/components/helper/AutomationLocators";
interface SectionItemFooterProps {
    sectionItem: ISectionItems;
    sectionItemIndex: number;
    sectionIndex: number;
    totalquestions: number;
}

const SectionItemFooter: React.FC<SectionItemFooterProps> = ({ sectionItem, sectionIndex, sectionItemIndex, totalquestions }) => {
    const dispatch = useDispatch();
    const { formData, customQuestionSettings } = useSelector((state: ApplicationState) => state.formBuilder);
    const onClickDuplicateQuestion = () => {
        if (isQuestionLimitValid()) {
            if (`${sectionItem.questionTitle}_copy`.length > FormBuilderConstants.QuestionTitle.maxLength) {
                dispatch({
                    type: NotificationAction.NOTIFICATION,
                    statusMessage: FormBuilderConstants.DUPLICATE_QUESTION_LIMIT,
                    statusType: StatusType.Error
                });
            } else {
                dispatch(DuplicateQuestionHandler(getDuplicateQuestionPayload()));
            }
        }
    };
    const onClickDeleteQuestion = () => {
        if (totalquestions > 1) {
            dispatch(DeleteQuestionHandler({ sectionIndex, sectionItemIndex }));
        }
    };
    const onClickIsRequiredQuestion = () => {
        dispatch(ChangeRequiredQuestionHandler({ sectionIndex, sectionItemIndex }));
    };
    const isQuestionLimitValid = () => {
        if (getTotalQuestions(formData) + 1 > customQuestionSettings.questionLimit) {
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "duplicate"),
                statusType: StatusType.Error
            });
            return false;
        }
        return true;
    };
    const getDuplicateQuestionPayload = () => {
        const sectionItemPayload: ISectionItems = JSON.parse(JSON.stringify(sectionItem));
        sectionItemPayload.questionTitle = getQuestionTitle(sectionItem.questionTitle);
        sectionItemPayload.isValid = true;
        if (sectionItemPayload.choices.length > 0) {
            sectionItemPayload.choices.forEach((choice) => (choice.isValid = true));
        }
        const payload: IDupicateQuestionPayload = {
            sectionItem: sectionItemPayload,
            sectionIndex,
            sectionItemIndex
        };
        return payload;
    };
    const getQuestionTitle = (questionTitle: string) => {
        if (questionTitle !== "") {
            return `${questionTitle}_copy`;
        }
        return questionTitle;
    };
    return (
        <div className="section-item-footer">
            <Button
                data-test-auto={CqAutomation.Section.duplicate}
                onClick={onClickDuplicateQuestion}
                variant="outline-secondary"
                className="btn-with-icon"
            >
                <CopyIcon />
                Duplicate
            </Button>
            <Button
                data-test-auto={CqAutomation.Section.delete}
                disabled={totalquestions === 1}
                title={totalquestions === 1 ? FormBuilderConstants.DELETE_QUESTION_DISABLED_TOOLTIP : ""}
                className="btn-with-icon"
                onClick={onClickDeleteQuestion}
                variant="outline-secondary"
            >
                <DeleteIcon />
                Delete
            </Button>
            <div className="required-wrapper">
                <label>Required</label>
                <label className="switch">
                    <input
                        data-test-auto={CqAutomation.Section.required}
                        type="checkbox"
                        checked={sectionItem.isRequired}
                        onChange={onClickIsRequiredQuestion}
                    ></input>
                    <span className="slider round"></span>
                </label>
            </div>
        </div>
    );
};

export default SectionItemFooter;
