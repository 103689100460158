import { handleResponse } from "../../store/Library";
import { API_BASE_URL } from "../../utils/constants";
import { IClientInfoComparisonResult, ClientInfoMismatchType, IClientInfoViewModel, initialClientInfoComparisonResult } from "./clientManagement.types";

export const compareClientInfo = (
    clientInfo: IClientInfoViewModel,
    resourceId: string,
    setResult?: (result: IClientInfoComparisonResult) => void,
    callbackOnNoDataMismach?: () => void,
    failedCallback?: () => void
) => {

    fetch(API_BASE_URL + "/api/organizer/clients/CompareClientInfoAsync", {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(clientInfo),
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'X-Resource-Id': resourceId,
        }
    })
        .then(handleResponse)
        .then((response: IClientInfoComparisonResult) => {
            if (response.comparisonStatus === ClientInfoMismatchType.NoDataMismatch && callbackOnNoDataMismach) {
                callbackOnNoDataMismach();
            } else {
                if (setResult)
                    setResult(response);
            }
        })
        .catch((error: any) => {
            if (failedCallback)
                failedCallback();
        });
};

export const getClientInfo = (
    clientId: string,
    resourceId: string,
    callback: (result: IClientInfoViewModel) => void,
    failedCallback?: () => void
) => {

    fetch(API_BASE_URL + `/api/organizer/clients/GetClientInfoAsync?clientId=${encodeURIComponent(clientId)}`, {
        method: "GET",
        credentials: "include",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            'X-Resource-Id': resourceId,
        }
    })
        .then(handleResponse)
        .then((response: IClientInfoViewModel) => {
            callback(response);
        })
        .catch((error: any) => {
            if (failedCallback)
                failedCallback();
        });
};

