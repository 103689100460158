import * as React from 'react';
import { ToastContainer,toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.min.css";
export class VenusNotifier extends React.Component<any, any> {

    static Error = (message: any, title: any, className: string= 'toaster_message') =>
        toast.error(message, { position: toast.POSITION.TOP_CENTER, className: className });


    static Warning = (message: any, title: any, className: string = 'toaster_message') =>
        toast.warn(message, { position: toast.POSITION.TOP_CENTER, className: className });
    

    static Success = (message: any, title: any, className: string = 'toaster_message') =>
        toast.success(message, { position: toast.POSITION.TOP_CENTER, className: className });
    

    static Info = (message: any, title: any, className: string = 'toaster_message') =>
        toast.info(message, { position: toast.POSITION.TOP_CENTER, className: className });
    
}

export class Toaster extends React.Component<any, any> {

    render() {
        return (
            <div>
                <ToastContainer 
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick={true}
                    rtl={false}
                    pauseOnHover
                    style={{ fontSize: '14px'}}
                />
            </div>
        );
    }
}