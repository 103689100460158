import * as React from 'react';
import { CommonFeatureDisabledIcon } from 'src/assets/SvgIconCollection';
import { clearAllTemporaryFilter } from '../reports/Filters';
import { RouteComponentProps } from 'react-router';
import { signoutRedirect } from 'src/oidcClient/userService';
import { Button } from 'react-bootstrap';
import { UiTextConstants } from '../helper/Constants';

export function onLogout(e: React.SyntheticEvent<EventTarget>) {
    clearAllTemporaryFilter();
    e?.preventDefault();
    signoutRedirect();
}

const CommonFeatureDisabled: React.FC<RouteComponentProps> = () => {
    return <div className="product-access-denied">
        <div>
            <CommonFeatureDisabledIcon width={200} height={200} />
        </div>
        <br />
        <div className="product-access-denied-text">
            <h3><b>{UiTextConstants.FeatureDisabled}</b></h3>
            <div>
                <div>
                    {UiTextConstants.CommonFeatureDisabledMessageLine1}<br/>
                    {UiTextConstants.CommonFeatureDisabledMessageLine2}
                </div>
                <br />
                <Button onClick={onLogout}
                    id="product-access-denied-btn"
                    data-test-auto="3CDE45EA-64A3-49F4-B6F4-A122088BA80B"
                >Sign in as a Different User
                </Button>
            </div>
        </div>
    </div>
}
export default CommonFeatureDisabled;