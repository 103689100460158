import {
    FollowUpQuestionIdentifer,
    ICustomQuestionSettings,
    IFormData,
    ISectionItems,
    ISections,
    ITemplateModalData,
    QuestionType
} from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { NotificationAction } from "src/store/common/NotificationStore";
import {
    IItemIdentifier,
    IOnChangeHandlerPayload,
    IQuestionReOrderPayload,
    ITitleOnChangeHandlerPayload
} from "../CqPreview/ActionTypes";

export enum actionTypes {
    ADD_TEMPLATE_MODAL_DATA = "add_template_modal_data",
    ADD_SECTION_MODAL_DATA = "add_section_modal_data",
    ADD_NEW_QUESTION = "add_new_question",
    RENAME_SECTION_MODAL_DATA = "rename_section_modal_data",
    CLEAR_FORM_DATA = "clear_form_data",
    DELTE_SECTION = "delete_section",
    START_FORM_LOADER = "start_form_loader",
    STOP_FORM_LOADER = "stop_form_loader",
    GET_FORM_DATA = "get_form_data",
    EDIT_FORM_DATA = "edit_form_data",
    HANDLE_QUESTION_TITLE_ON_CHANGE = "handle_question_title_on_change",
    HANDLE_MERGE_SECTION = "handle_merge_section",
    HANDLE_DUPLICATE_SECTION = "handle_duplicate_section",
    HANDLE_DUPLICATE_QUESTION = "handle_duplicate_question",
    HANDLE_DELETE_QUESTION = "handle_delete_question",
    HANDLE_CHANGE_REQUIRED_QUESTION = "handle_change_required_question",
    CHECK_IS_FORM_VALID = "check_is_form_valid",
    RECIEVE_CUSTOM_QUESTION_SETTINGS = "recieve_custom_question_settings",
    HANDLE_TITLE_ON_CHANGE = "handle_title_on_change",
    HANDLE_DESCRIPTION_ON_CHANGE = "handle_description_on_change",
    HANDLE_SECTION_REORDERING = "handle_section_reordering",
    HANDLE_QUESTION_REORDERING = "handle_question_re_ordering",

    HANDLE_QUESTION_TYPE_CHANGE = "handle_question_type_change",

    HANDLE_CHOICE_TEXT_ONCHANGE = "handle_choices_text_on_change",
    HANDLE_ADD_CHOICE = "handle_add_choices",
    HANDLE_REMOVE_CHOICE = "handle_remove_choices",

    ON_CLICK_YES_NO_CHECKBOX = "on_click_yes_no_checkbox",
    ON_ADD_SUB_QUESTION = "on_add_sub_question",
    ON_REMOVE_SUB_QUESTION = "on_remove_sub_question",
    ON_SUB_QUESTION_TYPE_CHANGE = "on_sub_question_type_change",
    ON_CHANGE_FOLLOW_UP_QUESTION_TITLE = "on_change_follow_up_question_title",

    HANDLE_SUB_QUESTION_CHOICE_TEXT_ONCHANGE = "handle_sub_question_choices_text_on_change",
    HANDLE_SUB_QUESTION_ADD_CHOICE = "handle_sub_question_add_choices",
    HANDLE_SUB_QUESTION_REMOVE_CHOICE = "handle_sub_question_remove_choices",
    RESET_SUB_QUESTIONS = "reset_sub_questions",
}
export interface IDupicateQuestionPayload extends IItemIdentifier {
    sectionItem: ISectionItems;
}
export interface IDupicateSectionPayload extends IItemIdentifier {
    section: ISections;
}
export interface IHandleQuestionTypeChangePayload extends IItemIdentifier {
    questionType: QuestionType;
}
export interface IHandleSubQuestionTypeChangePayload extends IHandleChoiceIdentifierPayload {
    questionType: QuestionType;
    subQuestionIndex: number;
}
export interface IHandleChoiceTextOnChangePayload extends IHandleChoiceIdentifierPayload {
    choiceText: string;
}

export interface ISubQuestionChoiceIdentifierPayload extends IHandleChoiceIdentifierPayload {
    subQuestionChoiceIndex: number;
    questionIndex: number;
}
export interface ISubQuestionChoiceTextOnChangePayload extends ISubQuestionChoiceIdentifierPayload {
    choiceText: string;
}
export interface ISubQuestionTitlePayload extends IHandleChoiceIdentifierPayload {
    value: string;
    questionIndex: number;
}
export interface IHandleChoiceIdentifierPayload extends IItemIdentifier {
    choiceIndex: number;
}
export interface IHandleAddChoiceIdentifierPayload extends IHandleChoiceIdentifierPayload{
    questionIndex: number;
}
export interface IAddSubQuestionPayload extends IHandleChoiceIdentifierPayload{
    questionType: number;
}
export interface IRemoveSubQuestionPayload extends IHandleChoiceIdentifierPayload{
    questionIndex: number;
}
export interface IHandleResetSubQuestionsPayload extends IItemIdentifier {
    isChecked: boolean;
}
export interface ISaveTemplateModalData {
    type: actionTypes.ADD_TEMPLATE_MODAL_DATA;
    payload: ITemplateModalData;
}

export interface ISaveSectionModalData {
    type: actionTypes.ADD_SECTION_MODAL_DATA;
    payload: string;
}
export interface IRenameSectionModalData {
    type: actionTypes.RENAME_SECTION_MODAL_DATA;
    sectionName: string;
    sectionIndex: number;
}
export interface IClearFormData {
    type: actionTypes.CLEAR_FORM_DATA;
}
export interface IDeleteSectionData {
    type: actionTypes.DELTE_SECTION;
    sectionIndex: number;
}
export interface IStartFormLoader {
    type: actionTypes.START_FORM_LOADER;
}
export interface IStopFormLoader {
    type: actionTypes.STOP_FORM_LOADER;
}

export interface IGetFormData {
    type: actionTypes.GET_FORM_DATA;
    payload: IFormData;
}
export interface IHandleParagraphOnChange {
    type: actionTypes.HANDLE_QUESTION_TITLE_ON_CHANGE;
    payload: IOnChangeHandlerPayload;
}

export interface IAddNewQuestionHandler {
    type: actionTypes.ADD_NEW_QUESTION;
    payload: number;
}
export interface IMergeSectionHandler {
    type: actionTypes.HANDLE_MERGE_SECTION;
    payload: IFormData;
}
export interface IDuplicateSectionHandler {
    type: actionTypes.HANDLE_DUPLICATE_SECTION;
    payload: IDupicateSectionPayload;
}
export interface IDuplicateQuestionHandler {
    type: actionTypes.HANDLE_DUPLICATE_QUESTION;
    payload: IDupicateQuestionPayload;
}
export interface IDeleteQuestionHandler {
    type: actionTypes.HANDLE_DELETE_QUESTION;
    payload: IItemIdentifier;
}
export interface IChangeRequiredQuestionHandler {
    type: actionTypes.HANDLE_CHANGE_REQUIRED_QUESTION;
    payload: IItemIdentifier;
}
export interface IRecieveCustomeQuestionSettings {
    type: actionTypes.RECIEVE_CUSTOM_QUESTION_SETTINGS;
    payload: ICustomQuestionSettings;
}
export interface ISetIsInvalidFlag {
    type: actionTypes.CHECK_IS_FORM_VALID;
}
export interface IHandleTitleOnChange {
    type: actionTypes.HANDLE_TITLE_ON_CHANGE;
    payload: ITitleOnChangeHandlerPayload;
}
export interface IHandleDescriptionOnChange {
    type: actionTypes.HANDLE_DESCRIPTION_ON_CHANGE;
    payload: ITitleOnChangeHandlerPayload;
}
export interface IHandleSectionReOrdering {
    type: actionTypes.HANDLE_SECTION_REORDERING;
    payload: IFormData;
}
export interface IHandleQuestionReOrdering {
    type: actionTypes.HANDLE_QUESTION_REORDERING;
    payload: IQuestionReOrderPayload;
}

export interface IHandleQuestionTypeChange {
    type: actionTypes.HANDLE_QUESTION_TYPE_CHANGE;
    payload: IHandleQuestionTypeChangePayload;
}
export interface IHandleChoiceTextOnChange {
    type: actionTypes.HANDLE_CHOICE_TEXT_ONCHANGE;
    payload: IHandleChoiceTextOnChangePayload;
}
export interface IHandleAddChoice {
    type: actionTypes.HANDLE_ADD_CHOICE;
    payload: IItemIdentifier;
}
export interface IHandleRemoveChoice {
    type: actionTypes.HANDLE_REMOVE_CHOICE;
    payload: IHandleChoiceIdentifierPayload;
}
export interface IOnClickYesNoCheckbox {
    type: actionTypes.ON_CLICK_YES_NO_CHECKBOX;
    payload: IHandleChoiceIdentifierPayload;
}
export interface IAddSubQuestion {
    type: actionTypes.ON_ADD_SUB_QUESTION;
    payload: IAddSubQuestionPayload;
}
export interface IRemoveSubQuestion {
    type: actionTypes.ON_REMOVE_SUB_QUESTION;
    payload: IRemoveSubQuestionPayload;
}
export interface IOnSubQuestionTypeChange {
    type: actionTypes.ON_SUB_QUESTION_TYPE_CHANGE;
    payload: IHandleSubQuestionTypeChangePayload;
}
export interface IOnChangeSubQuestionTitle {
    type: actionTypes.ON_CHANGE_FOLLOW_UP_QUESTION_TITLE;
    payload: ISubQuestionTitlePayload;
}

export interface IHandleSubQuestionChoiceTextOnChange {
    type: actionTypes.HANDLE_SUB_QUESTION_CHOICE_TEXT_ONCHANGE;
    payload: ISubQuestionChoiceTextOnChangePayload;
}
export interface IHandleSubQuestionAddChoice {
    type: actionTypes.HANDLE_SUB_QUESTION_ADD_CHOICE;
    payload: IHandleAddChoiceIdentifierPayload;
}
export interface IHandleSubQuestionRemoveChoice {
    type: actionTypes.HANDLE_SUB_QUESTION_REMOVE_CHOICE;
    payload: ISubQuestionChoiceIdentifierPayload;
}
export interface IHandleResetSubQuestions {
    type: actionTypes.RESET_SUB_QUESTIONS;
    payload:  IHandleResetSubQuestionsPayload;
}


export type DispatchActions =
    | ISaveTemplateModalData
    | ISaveSectionModalData
    | IRenameSectionModalData
    | IClearFormData
    | IDeleteSectionData
    | IStartFormLoader
    | IStopFormLoader
    | IGetFormData
    | IHandleParagraphOnChange
    | IAddNewQuestionHandler
    | IMergeSectionHandler
    | IDuplicateSectionHandler
    | IDuplicateQuestionHandler
    | IDeleteQuestionHandler
    | IChangeRequiredQuestionHandler
    | ISetIsInvalidFlag
    | IRecieveCustomeQuestionSettings
    | IHandleTitleOnChange
    | IHandleDescriptionOnChange
    | IHandleSectionReOrdering
    | IHandleQuestionReOrdering
    | IHandleQuestionTypeChange
    | IHandleChoiceTextOnChange
    | IHandleAddChoice
    | IHandleRemoveChoice
    | IOnClickYesNoCheckbox
    | IAddSubQuestion
    | IRemoveSubQuestion
    | IOnSubQuestionTypeChange
    | IOnChangeSubQuestionTitle
    | IHandleSubQuestionChoiceTextOnChange
    | IHandleSubQuestionAddChoice
    | IHandleSubQuestionRemoveChoice
    | IHandleResetSubQuestions;

export type KnownActions = DispatchActions | NotificationAction;
