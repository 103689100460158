import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
    CustomSelectProps, ToolBarProps, Options
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Button, Col, FormLabel } from 'react-bootstrap';
import { CheckBoxComponent, CheckBoxSize } from "./../../../../components/common/CheckBoxComponent";
import { DaysRangeOptionsList } from './../../../../components/common/TaxReturn';
import { CustomMultiSelect } from "./../../../../components/common/MultipleSelectComponent";
import {
    ProcessStatus, DocumentStatusList, OrganizerFilter,
    OrganizerDocument, EngagementList, BatchModel,
    BatchStatus, BatchStatusList,
    OrganizerPageOptionsList, OrganizerPageOptions
} from './../../../models/OrganizerComponentModels';
import { getEngagementType, getOrganizersTaxYearList } from './../../Helper/OrganizerHelperFunctions';
import { OrganizerAction } from '../AllOrganizer/OrganizerActions';
let moment = require('moment');
import Select from 'react-select';
import { BatchOrganizerAction } from './BatchOrganizerComponents';
import { ReportFilterConstants } from '../../../../components/helper/Constants';
import { ResourceIdConstants } from '../../../../components/helper/Rbac/RbacConstants';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';

export interface BatchOrganizerReportTableProps {
    batches: BatchModel[];
    isLoading: boolean;
    onPageChange: any;
    onSortChange: any;
    onFilterChange: any;
    pageNo: number;
    totalRows: number;
    pageSize: number;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    onBatchDeleteOpen: (rowIndex: number) => void;
    onOrganizerDeleteOpen: (batchIndex: number, rowIndex: number, pageNo: number, searchString: string) => void;
    onClientInfoOpen: (batchIndex: number, rowIndex: number) => void;
    onReportProblemOpen: (batchIndex: number, rowIndex: number) => void;
    pageReload: () => void;
    onProcessOrganizer: (batchIndex: number, rowIndex: number) => void;
    requestBatchOrganizers: (batchGuid: string, query: string, isDeleteRequest?: boolean, countCallback?: (count: number) => void) => void;
    tableLoader: boolean;
    expandedRows: number[];
    changeExpandedRows: (rows: number[]) => void;
    selectedOrganizerOption: OrganizerPageOptions;
    changeOrganizerOptions: (option: OrganizerPageOptions) => void;
    onEditBatchInfoOpen: (rowIndex: number) => void;
    onProcessBatch: (rowIndex: number) => void;
    onExportToExcel(onExportToComplete: () => void): void;
    refreshDelay?: boolean;
    batchOrganizersCountList: Array<any>;
    updatebatchOrganizersCountList: (batchId: string, count: number) => void;
    userLocationList? : IDropdown[];
}


export interface BatchOrganizerReportTableState {
    expandedBatchIndex: number;
    pageNo: number;
    searchString: string;
    exportingState: boolean;
}

const PAGESIZE = 10;

export class BatchOrganizerReportTable extends React.Component<BatchOrganizerReportTableProps, BatchOrganizerReportTableState>{
    public customDocumentStatusMultiSelect: any;
    public customLocationNameMultiSelect: any;
    public customTaxYearMultiSelect: any;
    public isAppiedFilter = false;
    public taxYearList: any;
    constructor(props: any) {

        super(props);
        this.state = {
            expandedBatchIndex: 0,
            pageNo: 1,
            searchString: "",
            exportingState: false
        }
    }

    componentWillMount() {
        this.taxYearList = getOrganizersTaxYearList();
    }

    buildQuery = (page: number, batchGuid: string) => {
        const {
            searchString
        } = this.state;

        return `?pageNo=${page}&pageSize=${PAGESIZE}&searchString=${searchString}&batchGuid=${batchGuid}`
    }

    onChangeSubTableSearch = (searchString: string) => {
        this.setState({
            searchString: searchString,
            pageNo: 1
        }, this.loadDocuments);
    }

    onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            pageNo: page
        }, this.loadDocuments);
    }

    loadDocuments = () => {
        const guid = this.props.batches[this.state.expandedBatchIndex].id;
        const queryString = this.buildQuery(this.state.pageNo, guid);
        this.props.requestBatchOrganizers(guid, queryString);
    }

    createCustomToolBar = (props: ToolBarProps) => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15" style={{ height: 33 }}>
                <div className="filters" style={{ zIndex: 10, fontSize: '14px' }}>
                    <div className="btn-group marR05" style={{ width: '300px' }}>

                        <Col className="text-left padT07" sm={2} as={FormLabel} style={{ fontWeight: 'bold' }}>
                            View:
                        </Col>
                        <Col sm={10}>
                            <Select
                                options={OrganizerPageOptionsList}
                                value={this.props.selectedOrganizerOption}
                                onChange={(option: any) => { this.props.changeOrganizerOptions(option.value) }}
                                clearable={false}
                                searchable={false}
                            />
                        </Col>
                    </div>
                    <Bootstrap.Button
                        onClick={this.props.pageReload}
                        disabled={this.props.refreshDelay}
                        title="Refresh"
                        className="Refresh-button btn-only marR05"
                        variant="default"
                        data-test-auto="11BC25B0-F325-4170-0000-598384364972">
                        <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                            style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                        </span>
                    </Bootstrap.Button>
                    <Button
                        variant="default"
                        className="btn-only marR05"
                        title={ReportFilterConstants.ControlsLabel.ExportToExcel}
                        onClick={() => { this.setState({ exportingState: true }, () => { this.props.onExportToExcel(this.onExportToComplete); }) }}
                        disabled={this.state.exportingState || this.props.batches.length === 0}
                        data-resource-id = {ResourceIdConstants.ExportToExcelOrganizerInProcessBatchToolBar}
                        data-test-auto="D04B9868-CF8B-4832-B0F3-E36BE8F836F4">
                        <span className={this.state.exportingState ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
                    </Button>
                </div>
            </div>
        );
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"signatureflow-reports-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }

    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p style={{ fontSize: '14px' }}>
                Showing {start} to {to} of {total} entries
            </p>
        )
    }

    setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    documentStatusFormatter = (cell: any, row: any) => {
        const status: any = row.status;
        const statusType: "None" | "Uploaded" | "Delivered" | "PartialSuccess" | "InProgress" | "PartialDeliver" | "Error" | "DeliveryInProgress" | "Delayed" =
            (typeof status === "number") ? BatchStatus[status] : status;
        return <span title={BatchStatusList[statusType].toUpperCase()} className="ellipsis">{BatchStatusList[statusType].toUpperCase()}</span>;
    }



    engagementTypeFormatter = (cell: any, row: any) => {
        const type: string = row.engagementType;
        const typeString = getEngagementType(type);
        return <span title={typeString} className="ellipsis">{typeString}</span>;
    }

    getTaxYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    getLocationNameMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customLocationNameMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    getDocumentStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customDocumentStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    actionFormatter = (cell: any, row: any) => {
        const { onBatchDeleteOpen, onEditBatchInfoOpen, onProcessBatch } = this.props;
        return <BatchOrganizerAction
            documentStatus={row.status}
            onBatchDeleteOpen={onBatchDeleteOpen}
            onEditBatchInfoOpen={onEditBatchInfoOpen}
            onProcessBatch={onProcessBatch}
            rowIndex={row.rowIndex}
            uploadedOn={row.createdOn}
        />;
    }

    isExpandableRow = (row: any) => {
        const status: BatchStatus = row.status;
        if (status === BatchStatus.Processing ||
            status === BatchStatus.Success ||
            status === BatchStatus.PartialSuccess)
            return true;
        return false;
    }

    expandComponent = (row: any) => {
        const { onProcessOrganizer, batches, tableLoader, onOrganizerDeleteOpen, onClientInfoOpen, onReportProblemOpen } = this.props;
        const { expandedBatchIndex, pageNo, searchString } = this.state;
        if (batches[expandedBatchIndex]) {
            const organizerModel = batches[expandedBatchIndex].organizers;
            const batchId = batches[expandedBatchIndex].id;
            const batchCount = this.props.batchOrganizersCountList.find(x => x.batchId === batchId);
            const organizerDocuments = organizerModel ? organizerModel.documents : [];
            const newPageNumber = organizerDocuments.length === 1 && pageNo > 1 ?  pageNo - 1 : pageNo;
            return (
                <LoadingOverlay>
                    <ExpandedOrganizerReportTable
                        documents={organizerDocuments}
                        isLoading={false}
                        onPageChange={this.onPageChange}
                        onProcessOrganizer={(rowIndex: number) => { onProcessOrganizer(expandedBatchIndex, rowIndex) }}
                        onDeleteOpen={(rowIndex: number) => { onOrganizerDeleteOpen(expandedBatchIndex, rowIndex, newPageNumber, searchString); }}
                        pageNo={pageNo}
                        pageSize={PAGESIZE}
                        totalRows={organizerModel ? organizerModel.count : 0}
                        onClientInfoOpen={(rowIndex: number) => onClientInfoOpen(expandedBatchIndex, rowIndex)}
                        onReportProblemOpen={(rowIndex: number) => onReportProblemOpen(expandedBatchIndex, rowIndex)}
                        onSearchChange={this.onChangeSubTableSearch}
                        disableDelete={ batchCount && batchCount.count === 1 }
                    />
                    <Loader loading={tableLoader} />
                </LoadingOverlay>);
        } else return "";
    }

    onExpand = (rowKey: number | string, isExpand: boolean, event: React.MouseEvent<any>) => {
        const { pageSize, pageNo } = this.props;
        const rowIndex = Number(rowKey) - ((pageNo - 1) * pageSize);
        if (isExpand) {
            this.setState({
                expandedBatchIndex: rowIndex,
                pageNo: 1,
                searchString: ''
            }, () => {
                const batch = this.props.batches[this.state.expandedBatchIndex];
                this.props.changeExpandedRows([rowKey as number]);
                this.props.requestBatchOrganizers(batch.id, this.buildQuery(this.state.pageNo, batch.id), false, (count: number) => {
                    this.props.updatebatchOrganizersCountList(batch.id, count);
                });
            });

        } else {
            this.props.changeExpandedRows([]);
            this.setState({
                expandedBatchIndex: 0,
                pageNo: 1,
                searchString: ''
            });
        }
    }

    onExportToComplete = () => {
        this.setState({ exportingState: false });
    }

    updatePageNumber = (newPageNumber: number) => {
        this.setState({ pageNo: newPageNumber })
    }

    public render() {
        const { onPageChange, onRowSelect,
            onSelectAll, onSortChange, pageNo, pageSize,
            selectedRows, totalRows,
            batches, onFilterChange, expandedRows,
            selectedOrganizerOption, changeOrganizerOptions
        } = this.props;
        const selected: number[] = [];
        if (selectedRows.length > 0) {
            for (const i in selectedRows) {
                const rowIndex = selectedRows[i];
                selected.push(((pageNo - 1) * pageSize) + rowIndex);
            }
        }

        const options: Options = {
            onSortChange: onSortChange,
            onPageChange: onPageChange,
            sizePerPage: pageSize,
            page: pageNo,
            paginationShowsTotal: this.renderShowTotal,
            onFilterChange: onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            onlyOneExpanding: true,
            onExpand: this.onExpand,
            expandRowBgColor: 'rgb(202,237,255)',
            expandBy: "column",
            expanding: expandedRows
        };

        const selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            clickToExpand: true,
            onSelect: onRowSelect,
            onSelectAll: onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };
        const searchEnable = true;

        const pagination = true;

        const DocumentStatusOptionsList = [
            { label: 'UPLOADED', value: 1 },
            { label: 'ERROR', value: 2 },
            { label: 'SUCCESS', value: 3 },
            { label: 'PARTIALLY SUCCEEDED', value: 4 },
            { label: 'IN PROGRESS', value: 5 },
            { label: 'DELIVERY IN PROGRESS', value: 6 },
            { label: 'DELAYED', value: 19 }
        ]


        const Columns: any[] = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                expandable: false,
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Batch Name',
                key: 'name',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                expandable: false,
                filter: { type: 'TextFilter', placeholder: 'Batch Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataSort: true,
                dataFormat: this.documentStatusFormatter,
                width: 'auto',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                expandable: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getDocumentStatusMultiSelectDropDown,
                    customFilterParameters: { options: DocumentStatusOptionsList, enableAllFilter: true, placeholder: 'Select Document Status...' } as any
                } as CustomFilter
            },
            {
                header: 'ERO / Signer',
                key: 'ero',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: {
                    type: 'CustomFilter', getElement: this.getLocationNameMultiSelectDropDown,
                    customFilterParameters: { options: this.props.userLocationList, enableAllFilter: true, placeholder: 'Select Office Location...' } as any
                } as CustomFilter
            },
            {
                header: 'Assigned To',
                key: 'assignedTo',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Assigned To', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Date',
                key: 'uploadedOn',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                expandable: false,
                filter: { type: 'SelectFilter', placeholder: 'Select Uploaded Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Return Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.engagementTypeFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                expandable: false,
                filter: { type: 'SelectFilter', options: EngagementList } as SelectFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataSort: true,
                dataFormat: this.defaultFormatter,
                width: '130px',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                expandable: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getTaxYearMultiSelectDropDown, customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Tax Year...' } as any
                } as CustomFilter
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '155px',
                expandable: false,
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];

        const data: any[] = batches.map((document, index) => {
            return {
                rowIndex: index,
                index: ((pageNo - 1) * pageSize) + index,
                name: document.batchName,
                uploadedOn: document.uploadedOn ? moment(document.uploadedOn).format('MM/DD/YYYY') : '',
                createdOn: document.uploadedOn ? document.uploadedOn : '',
                status: document.status,
                id: document.id,
                taxYear: document.taxYear,
                engagementType: document.engagementType,
                ero: document.eroUserName,
                assignedTo: document.assignedToUser ? document.assignedToUser.userName : '',
                locationName : document.locationName
            }
        });

        return <div className="pos-relative">
           
            <div>
                {Columns.length > 0 &&
                    <BootstrapTable
                        ref='organizerReportTable'
                        data={data}
                        remote={true}
                        fetchInfo={{ dataTotalSize: totalRows }}
                        options={options}
                        hover={true}
                        pagination={pagination}
                        selectRow={selectRowProp}
                        search={searchEnable}
                        expandableRow={this.isExpandableRow}
                        expandComponent={this.expandComponent}
                        expandColumnOptions={{ expandColumnVisible: true, expandColumnBeforeSelectColumn: false }}
                    >
                        {Columns.map((value, index) => {
                            return <TableHeaderColumn
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                                filter={value.filter}
                                dataSort={value.dataSort}
                                expandable={value.expandable}
                            >{value.header}
                            </TableHeaderColumn>;
                        })}
                    </BootstrapTable>
                }
            </div>

        </div>
    }
}

interface ExpandedOrganizerReportTableProps {
    documents: OrganizerDocument[];
    isLoading: boolean;
    onPageChange: any;
    onSearchChange: any;
    onProcessOrganizer: (rowIndex: number) => void;
    onDeleteOpen: (rowIndex: number) => void;
    onClientInfoOpen: (rowIndex: number) => void;
    onReportProblemOpen: (index: number) => void;
    pageNo: number;
    totalRows: number;
    pageSize: number;
    disableDelete: boolean;
}

class ExpandedOrganizerReportTable extends React.Component<ExpandedOrganizerReportTableProps, {}> {

    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p style={{ fontSize: '14px' }}>
                Showing {start} to {to} of {total} entries
            </p>
        )
    }

    setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    documentStatusFormatter = (cell: any, row: any) => {
        const status: any = row.documentStatus;
        const statusType: "None" | "Uploaded" | "Ready" | "Delivered" | "PartiallyCompleted" | "Completed" | "Downloaded" | "PreparingForDelivery" | "Error" | "Processing" =
            (typeof status == "number") ? ProcessStatus[status] : status;
        return <span title={DocumentStatusList[statusType].toUpperCase()} className="ellipsis">{DocumentStatusList[statusType].toUpperCase()}</span>;
    }



    engagementTypeFormatter = (cell: any, row: any) => {
        const type: string = row.engagementType;
        const typeString = getEngagementType(type);
        return <span title={typeString} className="ellipsis">{typeString}</span>;
    }

    actionFormatter = (cell: any, row: any) => {
        return <OrganizerAction
            documentStatus={row.documentStatus}
            onDeleteOpen={this.props.onDeleteOpen}
            onProcessOrganizer={this.props.onProcessOrganizer}
            rowIndex={row.rowIndex}
            uploadedOn={row.uploadedOn}
            onEditClientInfoOpen={this.props.onClientInfoOpen}
            onReportProblemOpen={this.props.onReportProblemOpen}
            batchView={true}
            disableDelete={this.props.disableDelete}
        />;
    }


    createCustomToolBar = (props: ToolBarProps) => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div id="div-Assignment-Table-search" className="col-xs-6 col-sm-6 col-md-6 col-lg-4 nopadding">
                    {props.components.searchPanel}
                </div >
            </div>
        );
    }

    render() {
        const {
            onPageChange, documents, pageNo, pageSize, totalRows, onSearchChange
        } = this.props;


        const searchEnable = true;

        const pagination = true;

        const options = {
            onPageChange: onPageChange,
            sizePerPage: pageSize,
            page: pageNo,
            paginationShowsTotal: this.renderShowTotal,
            noDataText: this.setNoContent(),
            onSearchChange: onSearchChange,
            toolBar: this.createCustomToolBar,
        };

        const columns: any[] = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: false,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                //filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Client Name',
                key: 'taxpayerName',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: false,
                isHidden: false,
                toolTip: false,
                columnClassName: 'overflowHidden minW50',
                width: 'auto',
                //filter: { type: 'TextFilter', placeholder: 'Client', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: false,
                isHidden: false,
                toolTip: false,
                columnClassName: 'overflowHidden minW50',
                width: 'auto',
                //filter: { type: 'TextFilter', placeholder: 'Client Id', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Status',
                key: 'documentStatus',
                isKey: false,
                dataSort: false,
                dataFormat: this.documentStatusFormatter,
                width: 'auto',
                columnClassName: 'overflowHidden minW50',
                toolTip: false,
                isHidden: false,
                //filter: {
                //    type: 'CustomFilter', getElement: this.getDocumentStatusMultiSelectDropDown, customFilterParameters: { options: DocumentStatusOptionsList, enableAllFilter: true, placeholder: 'Select Document Status...' } as any
                //} as CustomFilter
            },
            {
                header: 'Date',
                key: 'uploadedOn',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: false,
                isHidden: false,
                toolTip: false,
                columnClassName: 'overflowHidden minW50',
                width: 'auto',
                //filter: { type: 'SelectFilter', placeholder: 'Select Uploaded Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Tax Return Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.engagementTypeFormatter,
                columnClassName: 'overflowHidden minW50',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                //filter: { type: 'SelectFilter', options: EngagementList } as SelectFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataSort: false,
                dataFormat: this.defaultFormatter,
                width: '130px',
                columnClassName: 'overflowHidden',
                toolTip: false,
                isHidden: false,
                //filter: {
                //    type: 'CustomFilter', getElement: this.getTaxYearMultiSelectDropDown, customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Tax Year...' } as any
                //} as CustomFilter
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                columnClassName: 'button-cell overflowHidden',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '155px',
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];

        const data: any[] = documents.map((document, index) => {
            return {
                rowIndex: index,
                index: ((pageNo - 1) * pageSize) + index,
                taxpayerName: document.taxpayerName,
                clientId: document.clientId,
                uploadedOn: document.createdOn ? moment(document.createdOn).format('MM/DD/YYYY') : '',
                documentStatus: document.processStatus,
                id: document.id,
                taxYear: document.taxYear,
                engagementType: document.engagementType
            }
        });

        return (<div data-test-auto="83f59cf1-e8c7-478d-9c72-843fdf52a438">
            {columns.length > 0 &&
                <BootstrapTable
                    ref='organizerReportTable'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    search={searchEnable}>
                    {columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}
                        >{value.header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            }
        </div>);
    }
}