import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { NavLink } from 'react-bootstrap';
import { SaveFilters } from './SaveFilterModal';
import { VenusNotifier } from '../helper/VenusNotifier';
import { ReportFilterConstants, Common } from '../helper/Constants'
import * as bootbox from 'bootbox';
import { SessionStore }  from '../helper/HelperFunctions';


interface IFilter {
    show: boolean
    onFilterNameChange: any
    onFilterSave(onApplyFilter: (filter: IFilters) => void): void;
    onExportToExcel(onExportToComplete: () => void): void;
    onClearFilter(): void;
    filterList: IFilters[];
    onSetDefaultFilter(name: string, filterType: ReportFilterType): void;
    onRemoveDefaultFilter(name: string, filterType: ReportFilterType): void;
    onFilterDelete(filterName: string, filterType: ReportFilterType): any;
    onFilterSaveClick(filterName: string): void;
    onSaveFilterHide(): void;
    onLoadSelectedFilter(filter?: IFilters): void;
    defaultFilter: string | undefined;
    loadGrid(): void;
    showExportExcel: boolean;
    OnOpenColumnSettings?: () => void;
	proxyFilter: string
}

export interface IFilters {
    name: string,
    sort: ISort,
    fields: { [id: string]: string; }
    searchText: string
    isDefaultFilter: boolean,
    filterType: ReportFilterType,
    isMasterFilter: boolean
}

export interface ISort {
    column: string,
    direction: SortDirections
}
export enum SortDirections {
    None = 0,
    Ascending = 1,
    Descending = 2
}

export interface FilterState {
    activeFilter: string,
    appliedFilters: string[],
    exportingState: boolean
}
export enum ReportFilterType {
    None = 0,
    MyAssignments = 1,
    AllAssignments = 2,
    Delivered = 3,
    InUseReturn = 4,
    Archived = 5,
    K1Delivery = 6,
    RecycleBin = 7,
    LinkNotAccessed = 11,
}
export enum QuickReportType {
    None = 0,
    LinkNotAccessed = 1,
    KBAFailed = 2
}
export function clearAllTemporaryFilter() {
    for (let item in ReportFilterType) {
        if (isNaN(Number(item))) {
            SessionStore.remove(item.toUpperCase() + ReportFilterConstants.DefaultFilterBuilder); 
            SessionStore.remove(item.toUpperCase() + ReportFilterConstants.DefaultFilterBuilder + "RECENT");
        }
    }
}

export class Filters extends React.Component<IFilter, FilterState> {
    private proxyRecentFilter: any;
    constructor(props: IFilter) {
        super(props);
        this.state = {
            activeFilter: '',
            appliedFilters: [],
            exportingState: false
        }
        this.onApplyFilter = this.onApplyFilter.bind(this);
        this.onClearFilter = this.onClearFilter.bind(this);
        this.onDeleteFilter = this.onDeleteFilter.bind(this);
        this.onApplyRecentFilter = this.onApplyRecentFilter.bind(this);
        this.getFilter = this.getFilter.bind(this);
        this.onApplySavedFilter = this.onApplySavedFilter.bind(this);
        this.onExportToComplete = this.onExportToComplete.bind(this);
    }
    componentWillReceiveProps(nextProps: IFilter) {
        {            
            if (typeof nextProps.defaultFilter !== 'undefined' && nextProps.filterList.length > 0 &&
                nextProps.defaultFilter.length > 0 && this.state.appliedFilters.findIndex(x => x == nextProps.defaultFilter) == -1) {
                this.setState({ activeFilter: nextProps.defaultFilter, appliedFilters: [nextProps.defaultFilter] }, () => { if (nextProps.defaultFilter) { this.props.onLoadSelectedFilter(this.getFilter(nextProps.defaultFilter)) } });
            }
            if (this.props.defaultFilter != nextProps.defaultFilter) {
                this.addRecentFilter(nextProps.defaultFilter as string );
            }
        }
    }

    onApplySavedFilter(filter: IFilters) {
        let filterName = filter.name;
        this.addRecentFilter(filterName);
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters });
        }
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(filterName)));
    }

    //Validation
    private isProxyFilter = (name: string): boolean => {
        return name === this.props.proxyFilter;
    }
    private addRecentFilter = (filterName: string): void => {
        if (filterName && filterName !== ""  && ! this.isProxyFilter(filterName)) {
            SessionStore.set(this.proxyRecentFilter, filterName);
        }
    }
    //Validation End

    onApplyFilter(filter: IFilters) {

        let filterName = filter.name;
        this.addRecentFilter(filterName);
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters }, () => { this.props.onLoadSelectedFilter(filter) });
        }
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(filterName)));
    }

    getFilter(filterName: string) {
        return this.props.filterList.find(x => x.name == filterName);
    }

    onDeleteFilter(filterName: string, filterType: ReportFilterType) {
        let temp = this;
        bootbox.confirm({
            title: ReportFilterConstants.ControlsLabel.DeleteFilter,
            message: ReportFilterConstants.OtherMessage.DeleteFilter,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i>' + Common.ButtonLabel.Cancel,
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-remove"></i>' + Common.ButtonLabel.Delete,
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let index = temp.state.appliedFilters.findIndex(x => x == filterName);
                    if (index != -1) {
                        let appliedFilters: string[] = temp.state.appliedFilters.splice(index, 1);
                        let activeFilter = temp.state.activeFilter == filterName ? '' : temp.state.activeFilter;
                        temp.setState({ activeFilter: activeFilter, appliedFilters: appliedFilters }, () => { temp.props.onFilterDelete(filterName, filterType) });

                        let recentappliedFilter: string[] = this.state.appliedFilters;
                        let recentfilterNames = recentappliedFilter[recentappliedFilter.length - 1];
                        if (filterName === recentfilterNames) {
                            let index = recentappliedFilter.indexOf(filterName);
                            recentappliedFilter.splice(index, 1);
                        }
                    }
                    else {
                        temp.props.onFilterDelete(filterName, filterType);
                    }
                }
            }
        });
    }

    onClearFilter() {
        this.setState({ activeFilter: '' }, () => { this.props.onClearFilter() });
    }
    onSetDefaultFilter(name: string, filterType: ReportFilterType) {
        this.addRecentFilter(name);
        SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(name)));

        this.props.onSetDefaultFilter(name, filterType);
    }

    onApplyRecentFilter() {
        if (!SessionStore.isExists(this.proxyRecentFilter)
            || this.props.filterList.findIndex(x => x.name == SessionStore.get(this.proxyRecentFilter)) == -1) {
            VenusNotifier.Error(ReportFilterConstants.ValidationMessage.NoRecentFilters, null);
        }
        else {
            let tempRecentFilterName = SessionStore.get(this.proxyRecentFilter);
            SessionStore.set(this.props.proxyFilter, JSON.stringify(this.getFilter(tempRecentFilterName)));

            let appliedFilters: string[] = [tempRecentFilterName];
            if (appliedFilters.length > 0) {
                let filterName = appliedFilters[appliedFilters.length - 1];
                if (filterName != this.state.activeFilter) {
                    this.setState({ activeFilter: filterName }, () => { this.props.onLoadSelectedFilter(this.getFilter(filterName)) });
                }
            }
        }
    }
    onExportToComplete = () => {
        this.setState({ exportingState: false });
    }
    public render() {
        this.proxyRecentFilter = this.props.proxyFilter + "RECENT";

        let appliedFilter = this.state.activeFilter !== this.props.proxyFilter && this.state.activeFilter.length > 0 ? <div className="dis-inline applied-filter">
            <span>{ReportFilterConstants.ControlsLabel.AppliedFilter}:</span> <strong> {this.state.activeFilter}</strong>
        </div > : null;
        return <div className="filters">

            {
                this.props.OnOpenColumnSettings &&
                <Bootstrap.Button className="btn-only marR05" type="button" title="Column Options" onClick={this.props.OnOpenColumnSettings}>
                    <span className="fas fa-sliders-h fa-lg"></span>
                </Bootstrap.Button>
            }

            {appliedFilter}
            <div className="btn-group marR05">
                <button className="btn btn-default btn-filter" type="button" title={ReportFilterConstants.ControlsTitle.ApplyMostRecentFilter} onClick={this.onApplyRecentFilter} >
                    <span className="glyphicon glyphicon-filter"></span>
                </button>
                <button className="btn btn-default btn-filter" type="button" data-toggle="dropdown" data-test-auto="E1EC4A09-E72E-4E71-AA68-D9A596B28309">
                    <span className="glyphicon glyphicon-menu-down" title={ReportFilterConstants.ControlsTitle.ToggleDropdownMenu}></span>
                </button>
                <ul className="dropdown-menu dropdown-menu-right" style={{ width: '250px' }} role="menu" aria-labelledby="dropdownMenu">
                    <li><NavLink href='javascript:' onClick={this.onApplyRecentFilter} data-test-auto="86505833-BF5A-494C-A6A0-16784C18D96E">
                        <i className="fas fa-check-circle marR05"></i> <span>{ReportFilterConstants.ControlsLabel.ApplyRecentFilterBtn}</span>
                    </NavLink>
                    </li>
                    <li><NavLink href='javascript:' onClick={() => { this.props.onFilterSaveClick(this.state.activeFilter) }} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                        <i className="fas fa-save marR05" ></i> <span>{ReportFilterConstants.ControlsLabel.SaveCurrentFilterBtn}</span>
                    </NavLink>
                    </li>
                    <li><NavLink href='javascript:' onClick={this.onClearFilter} data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                        <i className="fas fa-times-circle marR05" ></i> <span>{ReportFilterConstants.ControlsLabel.ClearFilter}</span>
                    </NavLink></li>
                    <li className="divider"></li>
                    {this.props.filterList.map((value, index) => {
                        let filterName = value.name.length <= 25 ? value.name : value.name.substring(0, 25) + '...';
                        let activeFilter = value.isDefaultFilter ? <i className="fas fa-check marL05" ></i> : null;
                        let setAsDefaultFilter = value.isDefaultFilter ?
                            <li key={"filter-sub-menu" + index + "_2"}>
                                <NavLink href='javascript:' onClick={() => this.props.onRemoveDefaultFilter(value.name, value.filterType)} data-test-auto="44FCD3DE-6611-4128-BC78-B0EFBB1DEC63">
                                    <span>{ReportFilterConstants.ControlsLabel.RemoveDefault}</span>
                                </NavLink>
                            </li> :
                            <li key={"filter-sub-menu" + index + "_2"}>
                                <NavLink href='javascript:' onClick={() => this.onSetDefaultFilter(value.name, value.filterType)} data-test-auto="3b3651e2-4499-4530-b605-a0fa262f093a">
                                    <span>{ReportFilterConstants.ControlsLabel.SetDefault}</span>
                                </NavLink>
                            </li>;
                        let deleteFilter = value.isDefaultFilter ? <li key={"filter-sub-menu" + index + "_3"}>
                            <span className="defaul-filter-lable">{ReportFilterConstants.ControlsLabel.Delete}</span>
                        </li> :
                            <li key={"filter-sub-menu" + index + "_3"}>
                                <NavLink href='javascript:' onClick={() => this.onDeleteFilter(value.name, value.filterType)} data-test-auto="0ded7caf-ab98-4029-a802-c12f63154f2d">
                                    <span>{ReportFilterConstants.ControlsLabel.Delete}</span>
                                </NavLink>
                            </li>;
                        if (value.name !== this.props.proxyFilter)
                            return <li key={"filter-menu" + index} className="dropdown-submenu dropdown-menu-right">
                                <a title={value.name}>{filterName} {activeFilter}</a>
                                <ul className="dropdown-menu" style={{ left: "-160px" }}>
                                    <li key={"filter-sub-menu" + index + "_1"}>
                                        <NavLink href='javascript:' onClick={() => this.onApplyFilter(value)} data-test-auto="0c5eeba6-5010-4ac2-b6dd-3a7f5ba210cb">
                                            <span>{ReportFilterConstants.ControlsLabel.Apply}</span>
                                        </NavLink>
                                    </li>
                                    {setAsDefaultFilter}
                                    {deleteFilter}
                                </ul>
                            </li>
                    })}
                </ul>

                <SaveFilters
                    showState={this.props.show}
                    onHide={this.props.onSaveFilterHide}
                    data-test-auto="8822BA2D-A81A-4FE9-ABDB-929D5E1F236C"
                    formSubmit={() => { this.props.onFilterSave(this.onApplySavedFilter) }}
                    onFilterNameChange={this.props.onFilterNameChange}>
                </SaveFilters>
            </div>
            {this.props.showExportExcel == false ?
                <Bootstrap.Button
                    variant="default"
                    className="btn-only marR05"
                    title={ReportFilterConstants.ControlsLabel.ExportToExcel}
                    onClick={() => { this.setState({ exportingState: true }, () => { this.props.onExportToExcel(this.onExportToComplete); }) }}
                    disabled={this.state.exportingState}
                    data-test-auto="D04B9868-CF8B-4832-B0F3-E36BE8F836F4">
                    <span className={this.state.exportingState ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
                </Bootstrap.Button> : true
            }
        </div >
    }
}
export default Filters;