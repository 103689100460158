import { OrganizerRoute } from "../../../store/productMenu/model/ProductMenuStoreModel";

export const GetLeftMenuRouteData = (data: any): OrganizerRoute[] => {
  let organizerRoutedata: OrganizerRoute[] = [];
  for (let section = 0; section < data.length; section++) {
    const menuItems: any[] = data[section].items;
    var routes = getPagePath(menuItems);
    if (routes.length > 0) {
      organizerRoutedata = [...organizerRoutedata, ...routes];
    }
  }
  return organizerRoutedata;
};

const getPagePath = (menuItems: any[]): OrganizerRoute[] => {
  let routes: OrganizerRoute[] = [];
  for (let menuIndex = 0; menuIndex < menuItems.length; menuIndex++) {
    let menu: any = menuItems[menuIndex];
    if (!menu.isAtBottomOfLeftMenu) {
      const route: OrganizerRoute[] =
        menu.items !== null && menu.items !== undefined && menu.items.length > 0
          ? getPagePath(menu.items)
          : [{ route: menu.route, isEnabled: !menu.disabled }];
      route.forEach((ele) => {
        if (ele.route !== "") {
          routes.push(ele);
        }
      });
    }
  }
  return routes;
};