import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store/index';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    apiPrefix,
    AdditionalQuestionStoreState,
    unloadedAdditionalQuestionModelStoreState,
    AdditionalQuestionLoaderAction,
    ReceiveAdditionalQuestionAction,
    ReceiveAdditionalQuestionsAction,
    SaveAdditionalQuestionAction
} from './../models/OrganizerStoreModels';
import { AdditionalQuestionModel, initialAdditionalQuestionModel } from './../models/OrganizerComponentModels'
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    AdditionalQuestionLoaderAction |
    ReceiveAdditionalQuestionAction |
    SaveAdditionalQuestionAction |
    ReceiveAdditionalQuestionsAction |
    NotificationAction;


export const actionCreators = {
    requestAdditionalQuestions: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}AdditionalQuestions`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<AdditionalQuestionModel[]>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ADDITIONAL_QUESTIONS,
                    questions: data
                });
                dispatch({ type: actionTypes.ADDITIONAL_QUESTIONS_LOADER, loading: false });
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionQuestions,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.ADDITIONAL_QUESTIONS_LOADER, loading: true });
    },

    requestAdditionalQuestion: (id: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}AdditionalQuestions/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<AdditionalQuestionModel>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ADDITIONAL_QUESTION,
                    question: data
                });
                dispatch({ type: actionTypes.ADDITIONAL_QUESTIONS_LOADER, loading: false });
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionQuestions,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`requestAdditionalQuestion failed for Id: ${id}, with error ${error.message}`)
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.ADDITIONAL_QUESTIONS_LOADER, loading: true });
    },

    updateAdditionalQuestion: (question: AdditionalQuestionModel): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ADDITIONAL_QUESTIONS_LOADER, loading: true });
        const fetchTask = fetch(`${apiPrefix}AdditionalQuestions`, {
                method: 'PUT',
                credentials: 'include',
            body: JSON.stringify({ ...question, documentPDF: null }),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(handleResponse)
                .then(() => {
                    dispatch({
                        type: actionTypes.SAVE_ADDITIONAL_QUESTIONS, question: question
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Success.UpdateAdditionalQuestion,
                        statusType: StatusType.Success
                    });
                    dispatch({ type: actionTypes.ADDITIONAL_QUESTIONS_LOADER, loading: false });
                }).catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.SaveAdditionQuestions,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    logger?.trackError(`updateAdditionalQuestion failed to push the data having the parameters: ${JSON.stringify({ ...question, documentPDF: null })} with error ${error.message}`)
                });
            addTask(fetchTask);
        }
}

export const reducer: Reducer<AdditionalQuestionStoreState> = (state: AdditionalQuestionStoreState = unloadedAdditionalQuestionModelStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.ADDITIONAL_QUESTIONS_LOADER:
            return {
                ...state,
                loading: action.loading
            };

        case actionTypes.RECEIVE_ADDITIONAL_QUESTIONS:
            return {
                ...state,
                questions: action.questions
            };

        case actionTypes.SAVE_ADDITIONAL_QUESTIONS: {
            const updateQuestion = { ...state };
            updateQuestion.questions = updateQuestion.questions.map(question => {
                if (question.id === action.question.id)
                    return {
                        ...action.question
                    }
                return question;
            });
            return updateQuestion;
        }

        case actionTypes.RECEIVE_ADDITIONAL_QUESTION: {
            const question = { ...state };
            question.questions = question.questions.map((ques) => {
                if (ques.id === action.question.id) {
                    return {
                        ...action.question
                    }
                }
                return ques;
            })
        }
    }
    return state || unloadedAdditionalQuestionModelStoreState;
};