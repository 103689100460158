import * as jsPDF from 'jspdf';
const Header = "CUSTOM QUESTIONS";
const HeaderLeft = 210;
const HeaderTop = 810;
const DescHeaderTop = 780;
const CheckboxHeaderBorder = 0.7;
const FontSize = 12;
const FontSizeSmall = 10;

export const createAdditionalQuestionPdf = () => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF('p', 'pt', 'a4');
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(FontSize);
            doc.text(Header, HeaderLeft, pageHeight - HeaderTop);
            doc.setFontSize(FontSizeSmall);
            doc.setLineWidth(CheckboxHeaderBorder);
            resolve(doc.output('blob'));
        }
        catch (error) {
            reject(error);
        }
    });
}
export const createBlankPdf = () => {
    return new Promise((resolve, reject) => {
        try {
            const doc = new jsPDF('p', 'pt', 'a4');
            resolve(doc.output('blob'));
        }
        catch (error) {
            reject(error);
        }
    });
}

export const createAdditionalQuestionPdfPages = (totalPages: number) => {
    return new Promise((resolve, reject) => {
        try {
            let doc = new jsPDF('p', 'pt', 'a4');
            const pageHeight = doc.internal.pageSize.getHeight();
            doc.setFontSize(FontSize);
            doc.text(Header, HeaderLeft, pageHeight - HeaderTop);
            doc.setFontSize(FontSizeSmall);
            doc.text('To better assist us with the preparation of your individual tax return please answer the list of questions below.', 10, pageHeight - DescHeaderTop);
            doc.setFontSize(FontSizeSmall);
            doc.setFontType("bold"); 
            doc.text('Yes', CustomQuestions.Header.Yes.jsPDFleft, pageHeight - DescHeaderTop);
            doc.setFontSize(FontSizeSmall);
            doc.text('No', CustomQuestions.Header.No.jsPDFleft, pageHeight - DescHeaderTop);
            for (let i = 1; i <= totalPages; i++) {
                doc.addPage();
            }
            resolve(doc.output('blob'));
        }
        catch (error) {
            reject(error);
        }
    });
}
export const PdfProperties = {
    PageHeight: 792,
    PageWidth: 612,
    DefaultScale: 1
}

export const AdditionalQuestion = {
    HeaderHeight: 25,
    FooterHeight: 100
}

export const RadioButtonProperties = {
    Height: 25,
    Width: 30,
    Top: 0,
    Left: 670
}

export const TextBoxProperties = {
    Left: 20,
    Width: 600
}

export const CustomQuestions = {
    BookMark: 'Custom Questions',
    DefaultTop: 800,
    ReducedTop: 745,
    InitialCapacity: 0,
    defaultQuestion: {
        Left: 20,
        Width: 540
    },
    defaultSubQuestion: {
        Left: 40,
        Width: 506
    },
    subQuestionRadio: {
        YesButtonLeft: 643,
        NoButtonLeft: 683
    },
    customQuestion: {
        Left: 40,
        Width: 515
    },
    customSubQuestion: {
        Left: 60,
        Width: 490
    },
    customSubQuestionRadio: {
        YesButtonLeft: 617,
        NoButtonLeft: 657
    },
    Header: {
        Yes: {
            jsPDFleft: 525,
            jsPDFtop: 820,
            width: 20,
            height: 20,
            left:700,
            top:29
        },
        No: {
            jsPDFleft: 556,
            jsPDFtop: 820,
            width: 20,
            height:20,
            left:741,
            top:29
        }
    },
    QuestionStyle: 'font-size:13.333px;font-family:sans-serif',
    YesNoStyle: 'font-size:13.333px;font-family:sans-serif;font-weight:bold',
    SectionStyle: 'font-size:15.333px;font-family:sans-serif;font-weight:bold;color:#0973ba',
    SubQuestionSeries: 'abcdefghijklmnopqrstuvwxyz',
    RequiredPlaceholder:' *Required',
    NextPageTopAdjustment:73.5,
    jsPDFCustomTop:128.5,
    PreviousSectionTopSpace:10,
    PreviousControlTopSpace:21.5
}