import * as bootbox from 'bootbox';
import * as React from 'react';
import { Button, FormControl, Modal, ProgressBar, Table, Tabs } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { EngagementListLabelValue, EngagementType, FileUploadData, OrganizerDocumentUploadType, OrganizerWithEnagagementLetterUploadData, SingleFileOrganizerUploadData } from '../../../../models/OrganizerComponentModels';
import { IUserModel, IPartnerModel } from '../../../../../Core/ViewModels/User/UserViewModel';
import { getOrganizersTaxYearList, getUserList } from '../../../Helper/OrganizerHelperFunctions';
import DropdownComponent from '../../../../../components/common/controls/DropdownComponent';
import { UploadFunctions } from '../../../../../components/common/UploadDocument/UploadFunctions';
import { UploadStatus } from '../../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { checkIfAnyFileIsEncrypted, getFileExtension, validateFileSize, validateFileType, validatePdfFileContent } from '../../../../../components/helper/Validations';
import { GetFileMagicNumber, getFileSize } from '../../../../../components/helper/HelperFunctions';
import { VenusNotifier } from '../../../../../components/helper/VenusNotifier';
import { UploadtaxReturnConstants, ValidationContants } from '../../../../../components/helper/Constants';
import { apiPrefix, OrganizerUploadState } from '../../../../models/OrganizerStoreModels';
import { TaxSoftwareType } from '../../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import DocumentUploadDropzoneComponent from '../../../../../components/common/UploadDocument/DocumentUploadDropzoneComponent';
import { UploadedDocumentTable } from '../UploadedDocumentTable';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { HideLoader, ShowLoader } from '../../../../../components/helper/Loader';
import { logger } from '../../../../../routes';
import { CustomEvents, organizerSubmitSuccess, PreparerMessageConstants } from '../../../Helper/OrganizerConstants';
import { ButtonFormatter, DropDownFormatter } from '../../../../../components/common/UploadDocument/TaxSoftwareTypeUpload/UploadCommonFormatters';
import { CheckBoxComponent } from '../../../../../components/common/CheckBoxComponent';
import { UploadTabs } from '../OrganizerUploadDocumentModal';
import { Link } from 'react-router-dom';
import { OrganizerClientIdConstants } from '../../../../../components/helper/Constants';
import { FileModel } from 'src/Core/ViewModels/Common/FileModel';
import { Guid } from 'guid-typescript';
const MAXFILELIMIT = 1;
interface SingleDocumentUploadModalProps {
    onHide: () => void;
    pageReload: () => void;
    show: boolean;
    submitDocuments: (url: string, OrganizerUploadData: any, callback: () => void) => void;
    getUploadLink: (url: string, callback: (data?: any) => void) => void;
    deleteDocument: (guid: string, taxSoftware: TaxSoftwareType, isOrganizerFile: boolean, organizerGuid: string, callback: () => void) => void;
    taxSoftware: TaxSoftwareType;
    enableEngagementLetter: boolean;
}

interface SingleDocumentUploadModalState {
    disableSubmit: boolean;
    organizerUploadData: OrganizerWithEnagagementLetterUploadData;
    IsEngagementLetterRequired: boolean;
    activeKey: UploadTabs;
    isOrganizerUploaded: boolean;
    isEngagementLetterUploaded: boolean;
    returnType: EngagementType
}



export class SingleDocumentUploadModal extends React.Component<SingleDocumentUploadModalProps, SingleDocumentUploadModalState> {
    private taxYearList: any[];
    constructor(props: SingleDocumentUploadModalProps) {
        super(props);
        this.state = {
            disableSubmit: false,
            organizerUploadData: {
                clientId: "",
                taxYear: 0,
                organizerUploadFileData: [],
                elUploadFileData: [],
                enableEngagementLetter: true,
                taxSoftware: this.props.taxSoftware,
                sasGuid: Guid.create().toString(),
                engagementType: EngagementType.E1040,
                totalUploadedFilesSize: ""
            },
            IsEngagementLetterRequired: false,
            isOrganizerUploaded: false,
            isEngagementLetterUploaded: false,
            activeKey: UploadTabs.Organizer,
            returnType: EngagementType.E1040,
            //sasGuid: ""
        }
        this.taxYearList = getOrganizersTaxYearList();
    }
    componentDidMount() {
        this.setState({
            IsEngagementLetterRequired: this.props.enableEngagementLetter,
            organizerUploadData: {
                ...this.state.organizerUploadData
            }
        });
    }
    componentWillReceiveProps(nextProps: SingleDocumentUploadModalProps) {
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData
            },
            IsEngagementLetterRequired: nextProps.enableEngagementLetter
        });
    }

    componentConfig = {
        dropzoneSelector: 'div.filepicker',
        iconFiletypes: ['.pdf'],
        showFiletypeIcon: true,
        postUrl: "/api/",
        uploadMultiple: false
    }

    djsConfig = {
        uploadMultiple: false,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false,
        maxFiles: 1
    }

    organizerUploadEventHandlers = {

        init: () => {
            this.getDocumentGuid();
        },
        addedfiles: (files: any) => {
            try {
                const _self = this;
                _self.validateFileCount(files);
                let uploadData: SingleFileOrganizerUploadData[] = _self.convertToModel(files);
                uploadData = _self.handleDuplicates(uploadData);
                if (uploadData.length > 0) {
                    _self.validateFileContent(uploadData).then((result) => {
                        const fileModels: FileModel[] = [];
                        uploadData.map(obj => fileModels.push({ name: obj.name, file: obj.file } as FileModel));
                        checkIfAnyFileIsEncrypted(fileModels).then((fileModels) => {
                            result = result.filter(m => fileModels.some(x => x.name == m.name));
                            _self.setState({
                                organizerUploadData: {
                                    ...this.state.organizerUploadData,
                                    organizerUploadFileData: _self.state.organizerUploadData.organizerUploadFileData.concat(result),
                                    clientId: result[0]?.clientId
                                },
                                disableSubmit: true,
                            }, () => {
                                _self.getUploadLink();
                            });
                        });
                    });
                }
            }
            catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    }

    elUploadeventHandlers = {

        init: () => {
            this.getDocumentGuid();
        },

        addedfiles: (files: any) => {
            try {
                const _self = this;
                _self.validateElFileCount(files);
                let uploadData: SingleFileOrganizerUploadData[] = _self.convertToELModel(files);
                uploadData = _self.handleElDuplicates(uploadData);
                if (uploadData.length > 0) {
                    _self.validateFileContent(uploadData).then((result) => {
                        const fileModels: FileModel[] = [];
                        uploadData.map(obj => fileModels.push({ name: obj.name, file: obj.file } as FileModel));
                        checkIfAnyFileIsEncrypted(fileModels).then((fileModels) => {
                            result = result.filter(m => fileModels.some(x => x.name == m.name));
                            let clientid = _self.state.organizerUploadData.clientId
                            _self.setState({
                                organizerUploadData: {
                                    ...this.state.organizerUploadData,
                                    elUploadFileData: _self.state.organizerUploadData.elUploadFileData.concat(result),
                                    clientId: clientid == "" ? result[0]?.clientId : clientid

                                },
                                disableSubmit: true,

                            }, () => {
                                _self.getElUploadLink();
                            });
                        });
                    });
                }

            }
            catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    }

    getDocumentGuid() {
        let sasGuid = this.state.organizerUploadData.sasGuid == "" ? Guid.create().toString() : this.state.organizerUploadData.sasGuid;
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
                sasGuid: sasGuid
            }
        });
    }

    private getUploadedFilesSize() {
        var organizerUploadFileData = this.state.organizerUploadData.organizerUploadFileData[0];
        var engagementLetterUploadFileData = this.state.organizerUploadData.elUploadFileData[0];
        var organizerUploadFileDataInKB = 0;
        var engagementLetterUploadFileDataInKB = 0;

        var splittedOrganizerUploadFileData = new String(organizerUploadFileData.size).split(" ");
        if (splittedOrganizerUploadFileData[1] == "GB") {
            organizerUploadFileDataInKB = Math.round(Number(splittedOrganizerUploadFileData[0])) * 1024 * 1024;
        }
        else if (splittedOrganizerUploadFileData[1] == "MB") {
            organizerUploadFileDataInKB = Math.round(Number(splittedOrganizerUploadFileData[0])) * 1024;
        }
        else {
            organizerUploadFileDataInKB = Math.round(Number(splittedOrganizerUploadFileData[0]));
        }

        if (engagementLetterUploadFileData) {
            var splittedEngagementLetterUploadFileData = new String(engagementLetterUploadFileData.size).split(" ");
            if (splittedEngagementLetterUploadFileData[1] == "GB") {
                engagementLetterUploadFileDataInKB = Math.round(Number(splittedEngagementLetterUploadFileData[0])) * 1024 * 1024;
            }
            else if (splittedEngagementLetterUploadFileData[1] == "MB") {
                engagementLetterUploadFileDataInKB = Math.round(Number(splittedEngagementLetterUploadFileData[0])) * 1024;
            }
            else {
                engagementLetterUploadFileDataInKB = Math.round(Number(splittedEngagementLetterUploadFileData[0]));
            }
        }

        var totalFilesSize = organizerUploadFileDataInKB + engagementLetterUploadFileDataInKB;
        return totalFilesSize + " " + "KB";
    }

    validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.organizerUploadData.organizerUploadFileData.length > MAXFILELIMIT) {
            throw new Error(`You cannot upload more than ${MAXFILELIMIT} files.`);
        }
    }
    validateElFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.organizerUploadData.elUploadFileData.length > MAXFILELIMIT) {
            throw new Error(`You cannot upload more than ${MAXFILELIMIT} files.`);
        }
    }

    handleDuplicates = (uploadData: SingleFileOrganizerUploadData[]): SingleFileOrganizerUploadData[] => {
        const uploadHelperFunctions = new UploadFunctions();
        for (let i = 0; i < uploadData.length; i++) {
            const file = uploadData[i];
            let fileName: string = file.name || "";
            const fileExtension = getFileExtension(fileName);
            let filecount = 1;
            while (uploadHelperFunctions.isFileExist(fileName, this.state.organizerUploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace(`.${fileExtension}`, '');
                fileName = fileName + ` (${filecount}).` + fileExtension;
                filecount++;
            }
            uploadData[i].name = fileName;
        }
        return uploadData;
    }
    handleElDuplicates = (uploadData: SingleFileOrganizerUploadData[]): SingleFileOrganizerUploadData[] => {
        const uploadHelperFunctions = new UploadFunctions();
        for (let i = 0; i < uploadData.length; i++) {
            const file = uploadData[i];
            let fileName: string = file.name || "";
            const fileExtension = getFileExtension(fileName);
            let filecount = 1;
            while (uploadHelperFunctions.isFileExist(fileName, this.state.organizerUploadData.elUploadFileData)) {
                fileName = file.name || "";
                fileName = fileName.replace(`.${fileExtension}`, '');
                fileName = fileName + ` (${filecount}).` + fileExtension;
                filecount++;
            }
            uploadData[i].name = fileName;
        }
        return uploadData;
    }

    validateFileContent = (uploadData: SingleFileOrganizerUploadData[]): Promise<any> => {
        let promise: any = null;
        const _uploadData: SingleFileOrganizerUploadData[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    if (!validatePdfFileContent(result)) {
                        const index = uploadData.findIndex(x => x.name == _uploadData[i].name)
                        uploadData.splice(index, 1)
                        VenusNotifier.Warning(ValidationContants.PdfFileValidation, null);
                    }
                    resolve(result)
                });
            });
        }
        return promise.then(() => { return uploadData })
    }

    convertToModel = (uploadedFiles: any): SingleFileOrganizerUploadData[] => {
        const { taxSoftware } = this.props;
        const uploadData: SingleFileOrganizerUploadData[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            const uploadedFile = uploadedFiles[i];
            if (validateFileSize(uploadedFile)) {
                const tmpFileName = uploadedFiles[i].name;
                const fileExtension = getFileExtension(tmpFileName);
                if (validateFileType(fileExtension)) {
                    uploadData.push({
                        name: tmpFileName,
                        clientId: tmpFileName.replace(`.${fileExtension}`, ''),
                        progressBar: 0,
                        size: getFileSize(uploadedFile.size),
                        status: UploadStatus.Wait,
                        sasGuid: "",
                        gridRowDisable: true,
                        rowIndex: (uploadData.length +
                            this.state.organizerUploadData.organizerUploadFileData.length) + 1,
                        file: uploadedFile,
                        taxSoftware: taxSoftware,
                        engagementType: EngagementType.E1040,
                        taxYear: this.state.organizerUploadData.taxYear,
                        uploadMethod: OrganizerDocumentUploadType.Proforma,
                    });
                }
            }
        }
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData
            }
        });
        return uploadData;
    }


    convertToELModel = (uploadedFiles: any): SingleFileOrganizerUploadData[] => {
        const { taxSoftware } = this.props;
        const uploadData: SingleFileOrganizerUploadData[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            const uploadedFile = uploadedFiles[i];
            if (validateFileSize(uploadedFile)) {
                const tmpFileName = uploadedFiles[i].name;
                const fileExtension = getFileExtension(tmpFileName);
                if (validateFileType(fileExtension)) {
                    uploadData.push({
                        name: tmpFileName,
                        clientId: tmpFileName.replace(`.${fileExtension}`, ''),
                        progressBar: 0,
                        size: getFileSize(uploadedFile.size),
                        status: UploadStatus.Wait,
                        sasGuid: "",
                        gridRowDisable: true,
                        rowIndex: (uploadData.length +
                            this.state.organizerUploadData.elUploadFileData.length) + 1,
                        file: uploadedFile,
                        taxSoftware: taxSoftware,
                        engagementType: EngagementType.E1040,
                        taxYear: this.state.organizerUploadData.taxYear,
                        uploadMethod: OrganizerDocumentUploadType.Proforma,
                    });
                }
            }
        }
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
            }
        });
        return uploadData;
    }

    getUploadLink = () => {

        const _self = this;
        const uploadHelperFunctions = new UploadFunctions();
        this.state.organizerUploadData.organizerUploadFileData.filter(x => x.status == UploadStatus.Wait).forEach((file: SingleFileOrganizerUploadData, index: number) => {

            file.status = UploadStatus.Initiating;

            this.props.getUploadLink(`${apiPrefix}Document/GetOrganizerUploadLinkAsync?documentGuid=${this.state.organizerUploadData.sasGuid}`, (data?: OrganizerUploadState) => {
                try {
                    if (data) {

                        file.sasGuid = data ? data.guid : "";
                        file.status = UploadStatus.Uploading;

                        _self.setState({
                            organizerUploadData:
                            {
                                ...this.state.organizerUploadData,
                                organizerUploadFileData: _self.state.organizerUploadData.organizerUploadFileData
                            }
                        },
                            () => uploadHelperFunctions.uploadedFile(file.file, data, file.name ? file.name : "",
                                _self.uploadProgressCallback, _self.uploadCommitCallBack));
                    } else {
                        throw new Error("Upload link not found !!");
                    }

                } catch (error) {
                    _self.state.organizerUploadData.organizerUploadFileData.splice(index, 1);
                    _self.setState({ organizerUploadData: _self.state.organizerUploadData });
                }

            });

        });

    }

    getElUploadLink = () => {

        const _self = this;
        const uploadHelperFunctions = new UploadFunctions();
        this.state.organizerUploadData.elUploadFileData.filter(x => x.status == UploadStatus.Wait).forEach((file: SingleFileOrganizerUploadData, index: number) => {

            file.status = UploadStatus.Initiating;
            this.props.getUploadLink(`${apiPrefix}Document/GetElUploadLinkAsync?documentGuid=${this.state.organizerUploadData.sasGuid}`, (data?: OrganizerUploadState) => {

                try {

                    if (data) {

                        file.sasGuid = data ? data.guid : "";
                        file.status = UploadStatus.Uploading;

                        _self.setState({
                            organizerUploadData:
                            {
                                ...this.state.organizerUploadData,
                                elUploadFileData: _self.state.organizerUploadData.elUploadFileData
                            }
                        },
                            () => uploadHelperFunctions.uploadedFile(file.file, data, file.name ? file.name : "",
                                _self.uploadELProgressCallback, _self.uploadElCommitCallBack));
                    } else {
                        throw new Error("Upload link not found !!");
                    }

                } catch (error) {
                    _self.state.organizerUploadData.elUploadFileData.splice(index, 1);
                    _self.setState({ organizerUploadData: _self.state.organizerUploadData });
                }

            });

        });

    }

    onEngagementTypeChange = (eventValue: any) => {
        const _gridData = [...this.state.organizerUploadData.organizerUploadFileData];
        _gridData.map((value, index) => {
            value.engagementType = eventValue
        });
        const _elGridData = [...this.state.organizerUploadData.elUploadFileData];
        _elGridData.map((value, index) => {
            value.engagementType = eventValue
        });
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
                organizerUploadFileData: _gridData,
                taxSoftware: eventValue
            }
        });
    }

    engagementTypeFormatter = (cell: any, row: any) => {
        return <DropdownComponent
            options={EngagementListLabelValue}
            disabled={true}
            id={"engagementType"}
            selectedValue={cell}
            onChange={(value) => { this.onEngagementTypeChange(value) }}
        />
    }

    taxYearFormatter = (cell: any, row: any) => {
        return <DropdownComponent
            options={this.taxYearList}
            disabled={row.gridRowDisable}
            id={"taxYear"}
            selectedValue={cell}
            clearable={false}
            onChange={(value) => { this.onTaxYearChange(value) }}
        />
    }


    onTaxYearChange = (eventValue: any) => {
        const _gridData = [...this.state.organizerUploadData.organizerUploadFileData];
        _gridData.map((value) => {
            value.taxYear = eventValue
        });
        const _engagementgridData = [...this.state.organizerUploadData.elUploadFileData];
        _engagementgridData.map((value) => {
            value.taxYear = eventValue
        });
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
                organizerUploadFileData: _gridData,
                taxYear: eventValue
            },

        });
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    fileNameFormatter = (cell: any, row: any) => {
        return <FormControl type="text" disabled={row.gridRowDisable}
            value={cell}
            onChange={(e: any) => this.onFileNameChange(e, row)}
            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
    }

    onFileNameChange = (event: any, row: any) => {
        const _gridData = [...this.state.organizerUploadData.organizerUploadFileData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.name = event.target.value
            }
        });

        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
                organizerUploadFileData: _gridData
            }
        });
    }

    progressbarFormatter = (cell: any, row: any) => {
        return <ProgressBar striped
            variant={(cell != undefined) && (cell != 100) ? "warning" : "success"}
            now={cell} />
    }

    actionFormatter = (cell: any, row: any) => {
        return (
            <button type="button" className="btn-white btn-xs"
                disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false} onClick={() => this.deleteOrganizer(row)}>
                <i className="text-danger glyphicon glyphicon-remove"></i>
            </button>
        );
    }
    elActionFormatter = (cell: any, row: any) => {
        return (
            <button type="button" className="btn-white btn-xs"
                disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false} onClick={() => this.deleteEl(row)}>
                <i className="text-danger glyphicon glyphicon-remove"></i>
            </button>
        );
    }

    deleteOrganizer = (row: any) => {

        const _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.deleteDocument(row.sasGuid, this.props.taxSoftware, true, _self.state.organizerUploadData.sasGuid, () => {
                        var _gridData = [..._self.state.organizerUploadData.organizerUploadFileData];
                        _gridData = _gridData.filter(i => i.rowIndex != row.rowIndex);
                        for (let i = 0; i < _gridData.length; i++) {
                            _gridData[i].rowIndex = i;
                            _gridData[i].number = i + 1;
                        }


                        if (_gridData.length == 0) {
                            _self.setState({ disableSubmit: true, isOrganizerUploaded: false });
                        }
                        _self.setState({
                            organizerUploadData: {
                                ...this.state.organizerUploadData,
                                organizerUploadFileData: _gridData,
                                clientId: ""
                            },
                        }, () => _self.forceUpdate());
                    });
                }
            }
        });

    }
    deleteEl = (row: any) => {

        const _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.deleteDocument(row.sasGuid, this.props.taxSoftware, false, _self.state.organizerUploadData.sasGuid, () => {
                        var _gridData = [..._self.state.organizerUploadData.elUploadFileData];
                        _gridData = _gridData.filter(i => i.rowIndex != row.rowIndex);
                        for (let i = 0; i < _gridData.length; i++) {
                            _gridData[i].rowIndex = i;
                            _gridData[i].number = i + 1;
                        }
                        if (_gridData.length == 0) {
                            _self.setState({ disableSubmit: true, isEngagementLetterUploaded: false });
                        }
                        _self.setState({
                            organizerUploadData: {
                                ...this.state.organizerUploadData,
                                elUploadFileData: _gridData
                            }
                        }, () => _self.forceUpdate());
                    });
                }
            }
        });

    }

    handleUploaderClose = () => {
        if (this.state.organizerUploadData.organizerUploadFileData.length === 0) {
            this.handleClose();
            return;
        }
        if (this.isUploadOnGoing()) {
            bootbox.alert({
                message: UploadtaxReturnConstants.AbortingUploadWarning,
                buttons: {
                    ok: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                }
            });
        }
        else {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                size: 'small',
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.handleClose();
                        this.forceUpdate();
                    }
                }
            });
        }
    }

    reset = (callback: any = null) => {
        this.setState({
            organizerUploadData: {
                clientId: "",
                taxYear: 0,
                organizerUploadFileData: [],
                elUploadFileData: [],
                enableEngagementLetter: true,
                taxSoftware: this.props.taxSoftware,
                sasGuid: "",
                engagementType: EngagementType.E1040,
                totalUploadedFilesSize: ""
            },
            disableSubmit: true,
            isEngagementLetterUploaded: false,
            isOrganizerUploaded: false,
            activeKey: 0,
            IsEngagementLetterRequired: this.props.enableEngagementLetter,
        }, () => callback && callback());
    }

    handleClose = () => {
        this.reset(this.props.onHide);
    }

    isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.organizerUploadData.organizerUploadFileData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    }

    isELUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.organizerUploadData.elUploadFileData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true;
                return;
            }
        });
        return isuploading;
    }

    onSubmit = () => {
        if (this.state.organizerUploadData.organizerUploadFileData.length == 0) {
            VenusNotifier.Warning("Please upload Organizer Document", "");
        }
        else if (this.state.organizerUploadData.elUploadFileData.length == 0 && !this.state.IsEngagementLetterRequired) {
            VenusNotifier.Warning("Please upload Engagement Letter", "");
        }
        else {
            let isELValidUploadData = false;
            let isELFileUploaded = this.state.organizerUploadData.elUploadFileData.length > 0;
            if (isELFileUploaded) {
                isELValidUploadData = this.validateUploadData(this.state.organizerUploadData.elUploadFileData);
            }
            if (isELFileUploaded && isELValidUploadData) {
                const TraceEvent: IEventTelemetry = {
                    name: `${CustomEvents.ProformaUpload}${TaxSoftwareType[this.props.taxSoftware].toString()}`,
                    properties:
                    {
                        organizerCount: this.state.organizerUploadData.organizerUploadFileData.length,
                        engagementLetterCount: this.state.organizerUploadData.elUploadFileData.length
                    }
                };
                logger?.trackEvent(TraceEvent);
                ShowLoader();
                let totalUploadedFilesSize = this.getUploadedFilesSize();
                this.state.organizerUploadData.totalUploadedFilesSize = totalUploadedFilesSize;
                this.props.submitDocuments(`${apiPrefix}Document/SubmitOrganizer`,
                    JSON.stringify(this.state.organizerUploadData),
                    () => {
                        this.uploadConfirmation();
                        this.props.pageReload();
                        this.handleClose();
                        HideLoader();
                    });
            }
            else {
                let hasErrorInELTab = isELFileUploaded && !isELValidUploadData;
                if (!hasErrorInELTab && this.validateUploadData(this.state.organizerUploadData.organizerUploadFileData)) {
                    const TraceEvent: IEventTelemetry = {
                        name: `${CustomEvents.ProformaUpload}${TaxSoftwareType[this.props.taxSoftware].toString()}`,
                        properties: { count: this.state.organizerUploadData.organizerUploadFileData.length }
                    };
                    logger?.trackEvent(TraceEvent);
                    ShowLoader();
                    let totalUploadedFilesSize = this.getUploadedFilesSize();
                    this.state.organizerUploadData.totalUploadedFilesSize = totalUploadedFilesSize;
                    this.props.submitDocuments(`${apiPrefix}Document/SubmitOrganizer`,
                        JSON.stringify(this.state.organizerUploadData),
                        () => {
                            this.uploadConfirmation();
                            this.props.pageReload();
                            this.handleClose();
                            HideLoader();
                        });
                }
            }
        }
    }

    uploadConfirmation = () => {
        let message: string = `<h5 class='marT0'>${organizerSubmitSuccess} :</h5><ul class='marL30 wordWrap-breakWord'>`;
        this.state.organizerUploadData.organizerUploadFileData.map((value) => {
            message = message + `<li>${value.clientId}</li>`;
        });
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            size: 'small',
            buttons: {
                ok: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback() { }
        });
    }

    validateUploadData = (uploadData: SingleFileOrganizerUploadData[]): boolean => {
        let valid = true;
        for (let i = 0; i < uploadData.length; i++) {
            if (!uploadData[i].clientId || uploadData[i].clientId!.trim() === "") {
                VenusNotifier.Warning("Please enter client Id to submit", "");
                valid = false;
                break;
            }

            if (!uploadData[i].name || uploadData[i].name!.trim() === "") {
                VenusNotifier.Warning("Please enter file name to submit", "");
                valid = false;
                break;
            }

            if (!uploadData[i].engagementType) {
                VenusNotifier.Warning("Please select engagement type to submit", "");
                valid = false;
                break;
            }
        }

        if (!this.state.organizerUploadData.taxYear) {
            VenusNotifier.Warning("Please select tax year to submit", "");
            valid = false;
        }
        return valid;
    }

    uploadProgressCallback = (percent: number, fileToUpload: any) => {
        const tempGridData = this.state.organizerUploadData.organizerUploadFileData;
        tempGridData.map((tempGridDataValue) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
                organizerUploadFileData: tempGridData
            }
        });
    }
    uploadELProgressCallback = (percent: number, fileToUpload: any) => {
        const tempGridData = this.state.organizerUploadData.elUploadFileData;
        tempGridData.map((tempGridDataValue) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
                elUploadFileData: tempGridData
            }
        });
    }

    uploadCommitCallBack = (fileToUpload: any) => {
        if (this.state.organizerUploadData.organizerUploadFileData.length > 0) {
            const tempGridData = this.state.organizerUploadData.organizerUploadFileData;
            tempGridData.map((tempGridDataValue) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                }
            });
            this.setState({
                organizerUploadData: {
                    ...this.state.organizerUploadData,
                    organizerUploadFileData: tempGridData
                }
            }, () => {
                if (!this.isUploadOnGoing()) {
                    if (!this.state.IsEngagementLetterRequired) {
                        if (this.state.isEngagementLetterUploaded) {
                            this.setState({ disableSubmit: false, isOrganizerUploaded: true });
                        }
                        else {
                            this.setState({ isOrganizerUploaded: true });
                        }
                    } else {
                        this.setState({ disableSubmit: false, isOrganizerUploaded: true });
                    }

                }
            });
        }
    }
    uploadElCommitCallBack = (fileToUpload: any) => {
        if (this.state.organizerUploadData.elUploadFileData.length > 0) {
            const tempGridData = this.state.organizerUploadData.elUploadFileData;
            tempGridData.map((tempGridDataValue) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                }
            });
            this.setState({
                organizerUploadData: {
                    ...this.state.organizerUploadData,
                    elUploadFileData: tempGridData
                }
            }, () => {
                if (!this.isELUploadOnGoing()) {
                    if (this.state.isOrganizerUploaded) {
                        this.setState({ disableSubmit: false, isEngagementLetterUploaded: true });
                    }
                    else {
                        this.setState({ isEngagementLetterUploaded: true });
                    }
                }
            });
        }
    }

    clientIdFormatter = (cell: any, row: any) => {
        return <FormControl type="text" disabled={row.gridRowDisable}
            value={cell}
            onChange={(e: any) => this.onClientIdChange(e, row)}
            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
    }
    engamentLetterRequiredChange = (event: any) => {
        const _self = this;
        if (event.currentTarget.checked) {
            this.state.organizerUploadData.elUploadFileData.forEach((file: SingleFileOrganizerUploadData, index: number) => {
                if (file.sasGuid != undefined) {
                    _self.props.deleteDocument(file.sasGuid, this.props.taxSoftware, false, _self.state.organizerUploadData.sasGuid, () => {
                        var _gridData = [..._self.state.organizerUploadData.elUploadFileData];
                        _gridData = _gridData.filter(i => i.rowIndex != file.rowIndex);
                        for (let i = 0; i < _gridData.length; i++) {
                            _gridData[i].rowIndex = i;
                            _gridData[i].number = i + 1;
                        }

                        var _gridOrganizerData = [..._self.state.organizerUploadData.organizerUploadFileData];
                        if (_gridData.length == 0 && _gridOrganizerData.length == 0) {
                            _self.setState({ disableSubmit: true });
                        }
                        _self.setState({
                            organizerUploadData: {
                                ...this.state.organizerUploadData,
                                elUploadFileData: _gridData
                            }
                        }, () => _self.forceUpdate());
                    });
                }
            });
        }
        this.setState({
            IsEngagementLetterRequired: event.currentTarget.checked,
            isEngagementLetterUploaded: false,
            disableSubmit: this.checkisSubmitDisable(event.currentTarget.checked),
            organizerUploadData: {
                ...this.state.organizerUploadData,
                elUploadFileData: event.currentTarget.checked ? [] : this.state.organizerUploadData.elUploadFileData
            }
        });
    }
    onClientIdChange = (event: any, row: any) => {
        const _gridData = [...this.state.organizerUploadData.organizerUploadFileData];
        _gridData.map((value) => {
            value.clientId = event.target.value
        });
        const _engagementgridData = [...this.state.organizerUploadData.elUploadFileData];
        _engagementgridData.map((value) => {
            value.clientId = event.target.value
        });
        this.setState({
            organizerUploadData: {
                ...this.state.organizerUploadData,
                clientId: event.target.value
            }
        });
    }
    statusFormatter(cell: any, row: any) {
        return (<span>{UploadStatus[cell].toString()}</span>);
    }
    engagementTypeTooltip = (cell: any, row: any) => {
        return "Supporting 1040 Form Only";
    }
    handleTabSelect = (index: any) => {
        this.setState({ activeKey: parseInt(index.toString()) });
    }

    checkisSubmitDisable = (checked: any): boolean => {
        var isdisable = false;
        var _OrgnaizerGridData = this.state.organizerUploadData.organizerUploadFileData;
        var ElGridDate = this.state.organizerUploadData.elUploadFileData;
        if (checked) {
            if (_OrgnaizerGridData.length == 0) {
                isdisable = true;
            }
            else if (_OrgnaizerGridData.length > 0) {
                _OrgnaizerGridData.map((value) => {
                    if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                        isdisable = true;
                    }
                });
            }
        }
        else {
            if (_OrgnaizerGridData.length == 0) {
                isdisable = true;
            }
            else if (_OrgnaizerGridData.length > 0) {
                _OrgnaizerGridData.map((value) => {
                    if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                        isdisable = true;
                    }
                });
            }
            if (ElGridDate.length == 0) {
                return true;
            }
            else if (ElGridDate.length > 0) {
                ElGridDate.map((value) => {
                    if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                        isdisable = true;
                    }
                });
            }
        }
        return isdisable;
    }
    render() {
        const { activeKey } = this.state;
        let organizerUploadDataLength = this.state.organizerUploadData.organizerUploadFileData.length;
        let elUploadDataLength = this.state.organizerUploadData.elUploadFileData.length;

        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                isHidden: true,
                width: 'auto',

            },
            {
                header: 'Name',
                key: 'name',
                isKey: false,
                dataFormat: this.fileNameFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: 'word-Visible text-left-align'
            },
            {
                header: 'Upload Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbarFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.statusFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            }
        ];

        const elColumns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                isHidden: true,
                width: 'auto',

            },
            {
                header: 'Name',
                key: 'name',
                isKey: false,
                dataFormat: this.fileNameFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: 'word-Visible text-left-align'
            },
            {
                header: 'Upload Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbarFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.statusFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.elActionFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            }
        ];


        return (

            <div>
                < Modal className="upload-doc-modal" show={this.props.show} onHide={this.handleUploaderClose} enforceFocus={false} >
                    <Modal.Header closeButton data-test-auto="9DFD99E6-5A5C-4A9A-B40F-975AB2D109B5">
                        <Modal.Title>{` Upload ${this.props.taxSoftware == TaxSoftwareType.ProSystems ? "CCH" : TaxSoftwareType[this.props.taxSoftware].toString()} Document `}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        <div className="upload-doc-modal-body" >
                            <div className='batch-note-info'>
                                <i className="fas fa-info-circle"></i>
                                {PreparerMessageConstants.UploadFileWarning}
                            </div>
                            <Tabs
                                activeKey={activeKey}
                                onSelect={this.handleTabSelect}
                                id="process-return-groups"
                                fill>
                                <Tabs eventKey={UploadTabs.Organizer}
                                    title={<div className="overflowText uploadTabHeader">Organizer Document</div>}
                                    className="zIndex10 upload-doc-Tab">
                                    {(activeKey == UploadTabs.Organizer) &&

                                        <div className="row" style={{ paddingTop: "20px" }}>
                                            <div className="col-sm-2 text-center">
                                                <DocumentUploadDropzoneComponent
                                                    componentConfig={this.componentConfig}
                                                    djsConfig={this.djsConfig}
                                                    eventHandlers={this.organizerUploadEventHandlers}
                                                    autoTestId={"10EC7F82-DAC2-4AF3-8378-8B0CAE514CC7"}
                                                />
                                            </div>
                                            <div className="col-sm-10 uploaded-documents-table-container">
                                                <div className="upload-document-row">
                                                    <div className="padT07 text-bold upload-document-text">Client ID *</div>
                                                    <div className="pos_rel upload-document-item" style={{ paddingTop: "9px" }}>
                                                        <FormControl type="text"
                                                            disabled={organizerUploadDataLength < 1 && elUploadDataLength < 1}
                                                            value={this.state.organizerUploadData.clientId}
                                                            onChange={this.onClientIdChange.bind(this)}
                                                            maxLength={OrganizerClientIdConstants.ClientIdMaxLength}
                                                            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
                                                    </div>


                                                    <div className="padT07 text-bold upload-document-text">Tax Year *</div>
                                                    <div className="pos_rel padT07 upload-document-item">
                                                        <DropdownComponent
                                                            options={this.taxYearList}
                                                            disabled={organizerUploadDataLength < 1 && elUploadDataLength < 1}
                                                            id={"taxYear"}
                                                            selectedValue={this.state.organizerUploadData.taxYear}
                                                            clearable={false}
                                                            onChange={this.onTaxYearChange.bind(this)}
                                                        />
                                                    </div>


                                                    <div className="padT07 text-bold upload-document-text">Tax Return Type *</div>
                                                    <div className="pos_rel padT07 upload-document-item" title="Supporting 1040 Form Only">
                                                        <DropdownComponent
                                                            options={EngagementListLabelValue}
                                                            disabled={true}
                                                            id={"orgReturnType"}
                                                            selectedValue={this.state.returnType}
                                                            onChange={(value) => { this.onEngagementTypeChange(value) }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="padT07">
                                                    <UploadedDocumentTable
                                                        columns={columns}
                                                        data={this.state.organizerUploadData.organizerUploadFileData}
                                                        bodyContainerClass={"overflowVisible"}
                                                        noDataText={"There is no data to display"}
                                                    />
                                                </div>
                                                <div className="padT07">
                                                    <CheckBoxComponent
                                                        id={"IsEngagementLetterRequired"}
                                                        text={"No Engagement Letter"}
                                                        checked={this.state.IsEngagementLetterRequired}
                                                        onChange={this.engamentLetterRequiredChange}
                                                        datatestAuto={"D2745378-A4BB-45C3-A695-E22CA0F59F67"}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </Tabs>
                                {
                                    !this.state.IsEngagementLetterRequired ?
                                        <Tabs eventKey={UploadTabs.Enagagementletter}
                                            title={<div className="overflowText uploadTabHeader">Engagement Letter</div>}
                                            className="zIndex10">
                                            {(activeKey == UploadTabs.Enagagementletter) &&
                                                <div className="row" style={{ paddingTop: "20px" }}>
                                                    <div className="col-sm-2 text-center">
                                                        <DocumentUploadDropzoneComponent
                                                            componentConfig={this.componentConfig}
                                                            djsConfig={this.djsConfig}
                                                            eventHandlers={this.elUploadeventHandlers}
                                                            autoTestId={"10EC7F82-DAC2-4AF3-8378-8B0CAE514CC7"}

                                                        />
                                                    </div>
                                                    <div className="col-sm-10 uploaded-documents-table-container">
                                                        <div className="upload-document-row">
                                                            <div className="padT07 text-bold upload-document-text">Client ID *</div>
                                                            <div className="pos_rel upload-document-item" style={{ paddingTop: "9px" }}>
                                                                <FormControl type="text"
                                                                    disabled={organizerUploadDataLength < 1 && elUploadDataLength < 1}
                                                                    value={this.state.organizerUploadData.clientId}
                                                                    onChange={this.onClientIdChange.bind(this)}
                                                                    data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
                                                            </div>


                                                            <div className="padT07 text-bold upload-document-text">Tax Year *</div>
                                                            <div className="pos_rel padT07 upload-document-item">
                                                                <DropdownComponent
                                                                    options={this.taxYearList}
                                                                    disabled={organizerUploadDataLength < 1 && elUploadDataLength < 1}
                                                                    id={"taxYear"}
                                                                    selectedValue={this.state.organizerUploadData.taxYear}
                                                                    clearable={false}
                                                                    onChange={this.onTaxYearChange.bind(this)}
                                                                />
                                                            </div>


                                                            <div className="padT07 text-bold upload-document-text">Tax Return Type *</div>
                                                            <div className="pos_rel padT07 upload-document-item" title="Supporting 1040 Form Only">
                                                                <DropdownComponent
                                                                    options={EngagementListLabelValue}
                                                                    disabled={true}
                                                                    id={"elReturnType"}
                                                                    selectedValue={this.state.returnType}
                                                                    onChange={(value) => { this.onEngagementTypeChange(value) }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="padT07">
                                                            <UploadedDocumentTable
                                                                columns={elColumns}
                                                                data={this.state.organizerUploadData.elUploadFileData}
                                                                bodyContainerClass={"overflowVisible"}
                                                                noDataText={"There is no data to display"}
                                                            />
                                                        </div>

                                                    </div>
                                                </div>
                                            }

                                        </Tabs>
                                        : <Tabs style={{ pointerevents: "none" }}

                                            title={<div className="overflowText uploadTabHeader" style={{
                                                opacity: "0.65", cursor: "not-allowed"
                                            }}>Engagement Letter</div>}
                                            className="zIndex10" />

                                }
                            </Tabs>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" onClick={this.handleUploaderClose}>
                            <i className='glyphicon glyphicon-remove'></i>Close</Button>
                        <Button variant="info" onClick={this.onSubmit}
                            disabled={this.state.disableSubmit}>
                            <i className='glyphicon glyphicon-floppy-disk'></i>Submit</Button>
                    </Modal.Footer>
                </Modal >

            </div >
        );
    }
}
