import * as React from 'react';
import Pagination from "react-js-pagination";

export interface CustomPaginationProps {
    totalPages: number;
    pageSize?: number;
    enablePageSearch?: boolean;
    activePage?: number;
    paginationLabelSeparator?: string;
    paginationLabelColor?: string;
    onPageChange: (page: number) => void;
    enablePagination?: boolean;
}

export interface CustomPaginationState {
    currentPage: number;
    tempActivePage: number;
    enablePagination: boolean;
}
export const INVALID_PAGE = -100;
export class CustomPagination extends React.Component<CustomPaginationProps, CustomPaginationState> {

    constructor(props: any) {
        super(props);
        this.state = {
            currentPage: 1,
            tempActivePage: 1,
            enablePagination: false
        }
    }

    componentDidMount() {
        this.setPropsToState(this.props);
        window.setTimeout(() => this.onRenderComplete(true), 100);
    }

    componentWillReceiveProps(props: CustomPaginationProps) {
        this.setPropsToState(props);
    }

    private setPropsToState = (props: CustomPaginationProps) => {
        this.setState({
            currentPage: props.activePage ? props.activePage : 0,
            tempActivePage: props.activePage ? props.activePage : 0
        });
    }

    private onPageChange = (pageNumber: any) => {
        this.changePage(Number(pageNumber));
        window.setTimeout(() => this.onRenderComplete(true), 100);
    }

    private handleChange = (event: any) => {

        if (event.key == 'Enter') {
            this.changePage(Number(event.target.value));
            event.target.focus();
        }

        event.target.validity.valid && this.setState({ tempActivePage: Number(event.target.value) });
    }

    private changePage = (pageNumber: number) => {
        switch (true) {
            case pageNumber > this.props.totalPages:
                pageNumber = this.props.totalPages;
                break;
            case pageNumber <= 0:
                pageNumber = 1;
                break;
        }
        this.setState({ currentPage: pageNumber, tempActivePage: pageNumber, enablePagination: false });
        this.props.onPageChange(pageNumber);
    }

    private onRenderComplete = (success: boolean) => {
        this.setState({ enablePagination: success });
    }

    public render() {
        let isDisabled = !this.state.enablePagination;
        return <div className="custom-pagination-container">
            <Pagination innerClass={isDisabled ? "custom-pagination isDisabled" : "custom-pagination"}
                firstPageText={<i className="fas fa-angle-double-left fa-lg"></i>}
                lastPageText={<i className="fas fa-angle-double-right fa-lg"></i>}
                prevPageText={<i className="fas fa-angle-left fa-lg"></i>}
                nextPageText={<i className="fas fa-angle-right fa-lg"></i>}
                activePage={this.state.currentPage}
                itemsCountPerPage={this.props.pageSize || 1}
                totalItemsCount={this.props.totalPages}
                pageRangeDisplayed={5}
                onChange={this.onPageChange}
            />
            <div className="pagination-label-container">
                <input disabled={!this.props.enablePageSearch} pattern="[0-9]*" type="text"
                    value={Math.ceil(this.props.totalPages / (this.props.pageSize || 1)) != 0 ? this.state.tempActivePage : 0}
                    onChange={this.handleChange} onKeyPress={this.handleChange}
                    className="search-field" />
                <span style={{ color: this.props.paginationLabelColor }}> {this.props.paginationLabelSeparator ?
                    this.props.paginationLabelSeparator : " / "}
                    {Math.ceil(this.props.totalPages / (this.props.pageSize || 1))}</span>
            </div>
        </div>;
    }
}