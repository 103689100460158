import React from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';

export const LoadingScreen : React.FC = () => {

    return <LoadingOverlay style={{ height: '100%' }}>
                <Loader loading={true} text={"Loading Organizer."} />
            </LoadingOverlay>
}

export default LoadingScreen;