import * as React from 'react';
import { NotificationState, StatusType } from "../../store/common/NotificationStore";
import { VenusNotifier } from './VenusNotifier';

type NotificationProps =
    {
        notification: NotificationState
    }

export class Notification extends React.Component<NotificationProps, any> {

    componentWillReceiveProps(nextProps: NotificationProps) {
        if (nextProps.notification.type == StatusType.Success) {
            VenusNotifier.Success(nextProps.notification.message, null);
        }
        else if (nextProps.notification.type == StatusType.Error &&
            ((nextProps.notification.statusCode && nextProps.notification.statusCode !== 419) || !nextProps.notification.statusCode)) {
            VenusNotifier.Error(nextProps.notification.message, null);
        }
        else if (nextProps.notification.type == StatusType.Warning) {
            VenusNotifier.Warning(nextProps.notification.message, null);
        }
        else if ((nextProps.notification.statusCode && nextProps.notification.statusCode !== 419) || !nextProps.notification.statusCode) {
            VenusNotifier.Info(nextProps.notification.message, null);
        }
    }
    render() {
        return (<div />);
    }
}