import { connect } from 'react-redux';
import { ApplicationState } from './../../../store';
import { actionCreators as UndeliveredOrganizerStore } from './../../store/UndeliveredBatchStore';
import UndeliveredBatchOrganizerReport from './UndeliveredBatchOrganizer/UndeliveredBatchOrganizerReport';

export default connect(
    (state: ApplicationState) => ({
        batchStoreState: state.undeliveredBatchStoreState,
        pageTitle: "Undelivered Batch Report"
    }),
    {
        ...UndeliveredOrganizerStore
    }
)(UndeliveredBatchOrganizerReport);