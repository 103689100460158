import * as React from 'react';
import * as copy from 'copy-to-clipboard';
import { Card, Accordion } from 'react-bootstrap';
import { VariableListData } from './../../../models/OrganizerComponentModels';


interface VariableListProps {
    variableList: VariableListData[];
    isListExpand: boolean;
    toggleListExpand: () => void;
}


export const VariableList: React.StatelessComponent<VariableListProps> = ({ isListExpand, variableList, toggleListExpand }) => {
    const icon = isListExpand ? "glyphicon glyphicon-minus" : "glyphicon glyphicon-plus";
    const text = isListExpand ? "Variables List" : "Show Variables List";
    const defaultKey = isListExpand ? "0" : "1";
    return (
        <div>
            <Accordion defaultActiveKey={defaultKey}>
                <Card>
                    <Accordion.Toggle
                        style={{ padding: "10px" }}
                        as={Card.Header}
                        className="panel-title"
                        onClick={toggleListExpand}
                        eventKey="0">
                        <i className={icon}></i> <a>{text}</a>
                    </Accordion.Toggle>
                
                    <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        <ul>{
                            variableList.map((item) => {
                                var value = `<${item.variable}>`;
                                return <div className="row">
                                    <div className="col-md-4">
                                        <li className="copy" style={{ marginLeft: "5px" }}>{value}
                                            <span onClick={() => copy('<' + item.variable + '>', { format: "text/plain" })}
                                                title="Copy" className="far fa-copy copyIcon"
                                                style={{ fontSize: "16px", paddingLeft: "14px" }}>
                                            </span>
                                        </li>
                                    </div>
                                    <div className="col-md-8">{item.helpText}</div>
                                </div>
                            })
                        }
                        </ul>

                    </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    )
}
