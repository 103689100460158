import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Login from '../components/Login';
import SigninOidc from '../oidcClient/SigninOidc'
import silentSigninOidc from '../oidcClient/silentSigninOidc'
import SignoutOidc from '../oidcClient/SignoutOidc'
import LoggedIn from './LoggedIn'
import LoggedOut from './LoggedOut'
import OrganizerRoutes from 'src/OrganizerRoutes'
import PreLogin from 'src/components/PreLogin';
import { LoggerFactory } from 'src/Logger/LoggerFactory';
import AppLayout from 'src/components/AppLayout';
import LayoutWrapper from 'src/components/LayoutWrapper';
import { Toaster } from 'src/components/helper/VenusNotifier';

export const logger = new LoggerFactory().getTelemetryLogger();
logger?.trackTrace("Initialize");

const Routes: React.FC<{ history: any }> = ({ history }) => {
    return <>
        <LoggedIn>
            <AppLayout>
                <LayoutWrapper history={history}>
                    <OrganizerRoutes />
                    <Toaster />
                </LayoutWrapper>
            </AppLayout>
        </LoggedIn>

        <LoggedOut>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/signin-oidc" component={SigninOidc} />
                <Route path="/silent-signin-oidc" component={silentSigninOidc} />
                <Route path="/signout-callback-oidc" component={SignoutOidc} />
                <Route path="*" component={PreLogin} />
            </Switch>
        </LoggedOut>
    </>
}
export default Routes;