import * as React from 'react';
import {
    SingleFileOrganizerUploadData,
} from './../../../models/OrganizerComponentModels'
import { TaxSoftwareType } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { IPartnerModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { ProSystemUploadModal } from './ProSystem/ProSystemUploadModal';
import { SingleDocumentUploadModal } from './SingleDocumentUpload/SingleDocumentUploadModal';

interface ProformaUploadModalProps {
    onHide: () => void;
    pageReload: () => void;
    show: boolean;
    submitDocuments: (url: string, data: any, callback: () => void) => void;
    getUploadLink: (url: string, callback: (data?: any) => void) => void;
    deleteDocument: (guid: string, taxSoftware: TaxSoftwareType, isOrganizerFile: boolean, organizerGuid: string, callback: () => void) => void;
    taxSoftware: TaxSoftwareType;
    submitSingleDocuments: (url: string, OrganizerUploadData: any, callback: () => void) => void;
    enableEngagementLetter: boolean;
}
interface ProformaUploadModalState {
    disableSubmit: boolean;
    uploadData: SingleFileOrganizerUploadData[];
    sasContainer: any[];
}

export default class ProformaUploadModal extends React.Component<ProformaUploadModalProps, ProformaUploadModalState> {
    constructor(props: ProformaUploadModalProps) {
        super(props);
        this.state = {
            disableSubmit: false,
            uploadData: [],
            sasContainer: []
        }
    }

    render() {

        return (<div>
            <div>
                <ProSystemUploadModal
                    taxSoftware={this.props.taxSoftware}
                    show={this.props.show
                        && this.props.taxSoftware == TaxSoftwareType.ProSystems
                    }
                    onHide={this.props.onHide}
                    pageReload={this.props.pageReload}
                    submitDocuments={this.props.submitDocuments}
                    deleteDocument={this.props.deleteDocument}
                    getUploadLink={this.props.getUploadLink}
                />
                <SingleDocumentUploadModal
                    taxSoftware={this.props.taxSoftware}
                    show={this.props.show
                        && (this.props.taxSoftware == TaxSoftwareType.UltraTax || this.props.taxSoftware == TaxSoftwareType.GoSystem || this.props.taxSoftware == TaxSoftwareType.Lacerte)
                    }
                    onHide={this.props.onHide}
                    pageReload={this.props.pageReload}
                    submitDocuments={this.props.submitSingleDocuments}
                    deleteDocument={this.props.deleteDocument}
                    getUploadLink={this.props.getUploadLink}
                    enableEngagementLetter={this.props.enableEngagementLetter}
                />

            </div >
        </div>
        );
    }
}
