import { addTask } from 'domain-task';
import { Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import { handleResponse } from './../../store/Library';
import { apiPrefix } from 'src/components/helper/HelperFunctions';

export interface IUserSignatures {
    [index: number]: {
        signatureUploadPath: string,
        signatureDownloadPath: string,
    }
}

interface ReceiveSignatureDownloadPath {
    type: actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE;
    userId: number,
    path: string,
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction = ReceiveSignatureDownloadPath;

export const actionCreators = {
    requestDownloadPath: (userId: number, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (!state.userSignatures[userId] || (state.userSignatures[userId] && state.userSignatures[userId].signatureDownloadPath == "")) {
            const fetchTask = fetch(`${apiPrefix}/UserSettings/GetUserSignLinkAsync/` + userId, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then((data) => {
                    if (callback) {
                        callback(data.sas);
                    }
                    dispatch({ type: actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE, path: data.sas, userId: userId });
                }).catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                });
            addTask(fetchTask);
        }
        else {
            if (callback) {
                callback(state.userSignatures[userId].signatureDownloadPath);
            }
        }
    }
}

const unloadedState: IUserSignatures = {
    0: {
        signatureDownloadPath: "",
        signatureUploadPath: ""
    }
};

export const reducer: Reducer<IUserSignatures> = (state = unloadedState, incomingAction) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_DOWNLOAD_SIGNATURE:
            let newState: IUserSignatures = { ...state };
            if (newState[action.userId]) {
                newState[action.userId].signatureDownloadPath = action.path;
            }
            else {
                newState[action.userId] = {
                    signatureDownloadPath: action.path,
                    signatureUploadPath: "",
                };
            }
            return newState;
        default:
        //const exhaustiveCheck: never = action;
    }
    return state;
}