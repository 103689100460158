import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import { apiPrefix } from '../models/OrganizerStoreModels';

export interface IBlobFile {
    sas: string;
    guid: string;
}

export interface ReceiveAdditionalEsignDocumentUploadLinkAction {
    type: actionTypes.RECEIVE_ADDITIONAL_ESIGN_DOCUMENT_LINK;
    sasUrl: string;
}

const unloadedState: IBlobFile =
    {
        sas: '',
        guid: '',
    };

type DispatchAction = ReceiveAdditionalEsignDocumentUploadLinkAction;

type KnownAction = DispatchAction | NotificationAction;

export const actionCreators = {
    convertDocToPdfArtifactAsync: (documentGuid: string, taxYear: number, fileName:string,isBatch:boolean=false, callback?: (data?: any) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const fetchTask = fetch(`${apiPrefix}Organizer/ConvertDocToPdfDocumentLink?documentGuid=${documentGuid}&taxYear=${taxYear}&fileName=${fileName}&isBatch=${isBatch}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((resp) => resp.json())
            .then(data => {
                
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionalEsignUploadFailed,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
            });
        addTask(fetchTask);
    }
};

