import * as React from 'react';
import * as Bootstrap from 'react-bootstrap';
import { NavLink } from 'react-bootstrap';
import { SaveFilters } from './../../../../components/reports/SaveFilterModal';
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import { ReportFilterConstants, Common } from './../../../../components/helper/Constants';
import { OrganizerFilter, OrganizerFilterType, OrganizerPageOptions, OrganizerPageOptionsList } from './../../../models/OrganizerComponentModels';
import * as bootbox from 'bootbox';
import Select from 'react-select';
import { Col, FormLabel } from 'react-bootstrap';
import { ResourceIdConstants } from '../../../../components/helper/Rbac/RbacConstants';

interface OrganizerReportFilterProps {
    filterType: OrganizerFilterType;
    show: boolean;
    onFilterNameChange: (event: any) => void;
    onFilterSave(onApplyFilter: (filter: OrganizerFilter) => void): void;
    onExportToExcel(onExportToComplete: () => void): void;
    onClearFilter(): void;
    filterList: OrganizerFilter[];
    onSetDefaultFilter(name: string, actionName: string): void;
    onRemoveDefaultFilter(name: string, actionName: string): void;
    onFilterDelete(filterName: string): any;
    onFilterSaveClick(filterName: string): void;
    onSaveFilterHide(): void;
    onLoadSelectedFilter(filter?: OrganizerFilter): void;
    defaultFilter: string | undefined;
    selectedOrganizerOption: OrganizerPageOptions;
    changeOrganizerOptions: (option: OrganizerPageOptions) => void;
    pageReload: () => void;
    refreshDelay?: boolean;
    hideReportSelector?: boolean;
    OnOpenColumnSettings?: () => void;
    isArchived: boolean;
    exportToExcelEnabled: boolean;
}

export interface OrganizerReportFilterState {
    activeFilter: string;
    appliedFilters: string[];
    exportingState: boolean;
    showCustomColumn: boolean;
}

export class OrganizerReportFilter extends React.Component<OrganizerReportFilterProps, OrganizerReportFilterState> {
    constructor(props: OrganizerReportFilterProps) {
        super(props);
        this.state = {
            activeFilter: '',
            appliedFilters: [],
            exportingState: false,
            showCustomColumn: false
        }
    }

    componentWillReceiveProps(nextProps: OrganizerReportFilterProps) {

        if (typeof nextProps.defaultFilter !== 'undefined' && nextProps.filterList.length > 0 &&
            nextProps.defaultFilter.length > 0 && this.state.appliedFilters.findIndex(x => x == nextProps.defaultFilter) === -1) {
            this.setState({
                activeFilter: nextProps.defaultFilter,
                appliedFilters: [nextProps.defaultFilter]
            },
                () => {
                    //Quick fix Bug - 49434
                    setTimeout(this.onSetDefaultFilterTest, 1000);
                });
        }
    }

    onSetDefaultFilterTest = () => {
        const defaultOrganizerFilter = this.getFilter(this.state.activeFilter);
        this.props.onLoadSelectedFilter(defaultOrganizerFilter);
    }

    onApplySavedFilter = (filter: OrganizerFilter) => {
        let filterName = filter.name;
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters });
        }
    }

    onApplyFilter = (filter: OrganizerFilter) => {
        let filterName = filter.name;
        if (this.state.activeFilter != filterName) {
            let appliedFilters: string[] = this.state.appliedFilters;
            let index = appliedFilters.indexOf(filterName);
            if (index != -1) {
                appliedFilters.splice(index, 1);
            }
            appliedFilters.push(filterName);
            this.setState({ activeFilter: filterName, appliedFilters: appliedFilters },
                () => { this.props.onLoadSelectedFilter(filter) });
        }
    }

    getFilter = (filterName: string) => {
        return this.props.filterList.find(x => x.name == filterName);
    }

    onDeleteFilter = (filterName: string) => {
        let temp = this;
        bootbox.confirm({
            title: ReportFilterConstants.ControlsLabel.DeleteFilter,
            message: ReportFilterConstants.OtherMessage.DeleteFilter,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i>' + Common.ButtonLabel.Cancel,
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-remove"></i>' + Common.ButtonLabel.Delete,
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    let index = temp.state.appliedFilters.findIndex(x => x == filterName);
                    if (index != -1) {
                        let appliedFilters: string[] = temp.state.appliedFilters.splice(index, 1);
                        let activeFilter = temp.state.activeFilter == filterName ? '' : temp.state.activeFilter;
                        temp.setState({ activeFilter: activeFilter, appliedFilters: appliedFilters }, () => {
                            temp.props.onFilterDelete(filterName)
                        });

                        let recentappliedFilter: string[] = this.state.appliedFilters;
                        let recentfilterNames = recentappliedFilter[recentappliedFilter.length - 1];
                        if (filterName === recentfilterNames) {
                            let index = recentappliedFilter.indexOf(filterName);
                            recentappliedFilter.splice(index, 1);
                        }
                    }
                    else {
                        temp.props.onFilterDelete(filterName);
                    }
                }
            }
        });
    }

    onClearFilter = () => {
        this.setState({ activeFilter: '' }, () => {
            this.props.onClearFilter()
        });
    }

    onApplyRecentFilter = () => {

        let appliedFilters: string[] = this.state.appliedFilters;
        if (appliedFilters.length > 0) {
            let filterName = appliedFilters[appliedFilters.length - 1];
            if (filterName != this.state.activeFilter) {
                this.setState({ activeFilter: filterName }, () => { this.props.onLoadSelectedFilter(this.getFilter(filterName)) });
            }
        }
        else {
            VenusNotifier.Error(ReportFilterConstants.ValidationMessage.NoRecentFilters, null);
        }
    }
    onExportToComplete = () => {
        this.setState({ exportingState: false });
    }

    onCustomColumnClick = () => {
        this.setState({ showCustomColumn: true });
    }

    OnCancelCustomColumn = () => {
        this.setState({ showCustomColumn: false });
    }
    public render() {

        const { selectedOrganizerOption, changeOrganizerOptions, hideReportSelector, OnOpenColumnSettings, isArchived } = this.props;

        let appliedFilter = this.state.activeFilter.length > 0 ? <div className="dis-inline applied-filter">
            <span>{ReportFilterConstants.ControlsLabel.AppliedFilter}:</span> <strong> {this.state.activeFilter}</strong>
        </div > : null;
        return (

            <div className="filters" style={{ zIndex: 10, fontSize: '14px' }}>
                {
                    !hideReportSelector &&

                    <div className="btn-group marR05" style={{ width: '300px' }}>

                        <Col className="text-left padT07" sm={2} as={FormLabel}>
                            View:
                        </Col>
                        <Col sm={10}>
                            <Select
                                options={OrganizerPageOptionsList}
                                value={selectedOrganizerOption}
                                onChange={(option: any) => { changeOrganizerOptions(option.value) }}
                                clearable={false}
                                searchable={false}
                            />
                        </Col>
                    </div>

                }
                {appliedFilter}
                <Bootstrap.Button
                    onClick={this.props.pageReload}
                    title="Refresh"
                    disabled={this.props.refreshDelay}
                    className="Refresh-button btn-only marR05"
                    style={{ paddingLeft: '12px', marginLeft: '8px' }}
                    data-test-auto="11BC25B0-F325-4170-0000-598384364972">
                    <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon'}
                        style={{ padding: '2px 0', textShadow: '0px 1px 0px rgba(255,255,255,.5)', fontSize: '12px' }}>
                    </span>
                </Bootstrap.Button>

                <div className="btn-group marR05 ">

                    <button data-test-auto="bc775bb7-0d4f-49f1-b2b5-498ce1e8bea3" className="btn btn-default btn-filter" type="button"
                        title={ReportFilterConstants.ControlsTitle.ApplyMostRecentFilter}
                        onClick={this.onApplyRecentFilter} >
                        <span className="glyphicon glyphicon-filter"></span>
                    </button>
                    <button data-test-auto="63aa54cc-5d29-4489-8abd-ba31d71a77a5" className="btn btn-default btn-filter" type="button" data-toggle="dropdown">
                        <span className="glyphicon glyphicon-menu-down" title={ReportFilterConstants.ControlsTitle.ToggleDropdownMenu}></span>
                    </button>
                    <ul className="dropdown-menu dropdown-menu-right" style={{ width: '250px', fontSize: '14px' }} role="menu" aria-labelledby="dropdownMenu">
                        <li><NavLink href='javascript:'
                            onClick={this.onApplyRecentFilter}
                            data-test-auto="86505833-BF5A-494C-A6A0-16784C18D96E">
                            <i className="fas fa-check-circle marR05"></i> <span>{ReportFilterConstants.ControlsLabel.ApplyRecentFilterBtn}</span>
                        </NavLink></li>
                        <li><NavLink href='javascript:'
                            onClick={() => { this.props.onFilterSaveClick(this.state.activeFilter) }}
                            data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                            <i className="fas fa-save marR05" ></i> <span>{ReportFilterConstants.ControlsLabel.SaveCurrentFilterBtn}</span>
                        </NavLink></li>
                        <li><NavLink href='javascript:'
                            onClick={this.onClearFilter}
                            data-test-auto="18C06943-6C1E-4EBF-9CEB-34840CB572D7">
                            <i className="fas fa-times-circle marR05" ></i> <span>{ReportFilterConstants.ControlsLabel.ClearFilter}</span>
                        </NavLink></li>
                        <li className="divider"></li>
                        {
                            this.props.filterList
                                .filter(filter => filter.filterType === this.props.filterType)
                                .map((value, index) => {
                                    let filterName = value.name.length <= 25 ? value.name : value.name.substring(0, 25) + '...';
                                    let activeFilter = value.name == this.props.defaultFilter ? <i className="fas fa-check marL05" ></i> : null;
                                    let setAsDefaultFilter = value.name == this.props.defaultFilter ?
                                        <li key={"filter-sub-menu" + index + "_2"}>
                                            <NavLink href='javascript:'
                                                onClick={() => this.props.onRemoveDefaultFilter(value.name, ReportFilterConstants.ControlsLabel.RemoveDefault)}
                                                data-test-auto="44FCD3DE-6611-4128-BC78-B0EFBB1DEC63">
                                                <span>{ReportFilterConstants.ControlsLabel.RemoveDefault}</span>
                                            </NavLink>
                                        </li> :
                                        <li key={"filter-sub-menu" + index + "_2"}>
                                            <NavLink href='javascript:'
                                                onClick={() => this.props.onSetDefaultFilter(value.name, ReportFilterConstants.ControlsLabel.SetDefault)}
                                                data-test-auto="3b3651e2-4499-4530-b605-a0fa262f093a">
                                                <span>{ReportFilterConstants.ControlsLabel.SetDefault}</span>
                                            </NavLink>
                                        </li>;
                                    let deleteFilter = value.name == this.props.defaultFilter ? <li key={"filter-sub-menu" + index + "_3"}>
                                        <span className="defaul-filter-lable">{ReportFilterConstants.ControlsLabel.Delete}</span>
                                    </li> :
                                        <li key={"filter-sub-menu" + index + "_3"}>
                                            <NavLink href='javascript:'
                                                onClick={() => this.onDeleteFilter(value.name)}
                                                data-test-auto="0ded7caf-ab98-4029-a802-c12f63154f2d">
                                                <span>{ReportFilterConstants.ControlsLabel.Delete}</span>
                                            </NavLink>
                                        </li>;
                                    return <li key={"filter-menu" + index} className="dropdown-submenu dropdown-menu-right">
                                        <a title={value.name}>{filterName} {activeFilter}</a>
                                        <ul className="dropdown-menu" style={{ left: "-40%", fontSize: '14px' }}>
                                            <li key={"filter-sub-menu" + index + "_1"}><NavLink href='javascript:'
                                                onClick={() => this.onApplyFilter(value)}
                                                data-test-auto="0c5eeba6-5010-4ac2-b6dd-3a7f5ba210cb">
                                                <span>{ReportFilterConstants.ControlsLabel.Apply}</span>
                                            </NavLink>
                                            </li>
                                            {
                                                setAsDefaultFilter
                                            }
                                            {deleteFilter}
                                        </ul>
                                    </li>
                                })}
                    </ul>

                    <SaveFilters
                        showState={this.props.show}
                        onHide={this.props.onSaveFilterHide}
                        data-test-auto="8822BA2D-A81A-4FE9-ABDB-929D5E1F236C"
                        formSubmit={() => { this.props.onFilterSave(this.onApplySavedFilter) }}
                        onFilterNameChange={this.props.onFilterNameChange}>
                    </SaveFilters>
                </div>


                {
                    OnOpenColumnSettings && !isArchived &&
                    <button className="Refresh-button btn btn-only marR05" type="button" title="Column Options" onClick={OnOpenColumnSettings}>
                        <span className="fas fa-sliders-h fa-lg"></span>
                    </button>
                }
                <Bootstrap.Button
                    variant="default"
                    className="btn-only marR05"
                    title={ReportFilterConstants.ControlsLabel.ExportToExcel}
                    onClick={() => { this.setState({ exportingState: true }, () => { this.props.onExportToExcel(this.onExportToComplete); }) }}
                    disabled={this.state.exportingState || this.props.exportToExcelEnabled}
                    data-resource-id={this.props.filterType === 1 ? ResourceIdConstants.ExportToExcelOrganizerInProcessProformaToolBar : (isArchived ? ResourceIdConstants.ExportToExcelArchivedOrganizerToolBar : ResourceIdConstants.ExportToExcelDeliveredOrganizerToolBar)}
                    data-test-auto="D04B9868-CF8B-4832-B0F3-E36BE8F836F4">
                    <span className={this.state.exportingState ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
                </Bootstrap.Button>

            </div >
        )
    }
}
