import * as bootbox from 'bootbox';
import * as React from 'react';
import { Button, FormControl, Modal, ProgressBar } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { EngagementListLabelValue, EngagementType, OrganizerDocumentUploadType, SingleFileOrganizerUploadData } from '../../../../models/OrganizerComponentModels';
import { IUserModel, IPartnerModel } from '../../../../../Core/ViewModels/User/UserViewModel';
import { getOrganizersTaxYearList, getUserList } from '../../../Helper/OrganizerHelperFunctions';
import DropdownComponent from '../../../../../components/common/controls/DropdownComponent';
import { UploadFunctions } from '../../../../../components/common/UploadDocument/UploadFunctions';
import { UploadStatus } from '../../../../../Core/ViewModels/Common/UploadDocumentViewModel';
import { checkIfAnyFileIsEncrypted, getFileExtension, validateFileSize, validateFileType, validatePdfFileContent } from '../../../../../components/helper/Validations';
import { GetFileMagicNumber, getFileSize } from '../../../../../components/helper/HelperFunctions';
import { VenusNotifier } from '../../../../../components/helper/VenusNotifier';
import { UploadtaxReturnConstants, ValidationContants, OrganizerClientIdConstants } from '../../../../../components/helper/Constants';
import { apiPrefix, OrganizerUploadState } from '../../../../models/OrganizerStoreModels';
import { TaxSoftwareType } from '../../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import { FileModel } from '../../../../../Core/ViewModels/Common/FileModel';
import DocumentUploadDropzoneComponent from '../../../../../components/common/UploadDocument/DocumentUploadDropzoneComponent';
import { UploadedDocumentTable } from '../UploadedDocumentTable';
import { IEventTelemetry } from '@microsoft/applicationinsights-web';
import { HideLoader, ShowLoader } from '../../../../../components/helper/Loader';
import { logger } from '../../../../../routes';
import { CustomEvents, organizerSubmitSuccess,PreparerMessageConstants} from '../../../Helper/OrganizerConstants';

const MAXFILELIMIT = 30;
interface ProSystemUploadModalProps {
    onHide: () => void;
    pageReload: () => void;
    show: boolean;
    submitDocuments: (url: string, data: any, callback: () => void) => void;
    getUploadLink: (url: string, callback: (data?: any) => void) => void;
    deleteDocument: (guid: string, taxSoftware: TaxSoftwareType, isOrganizerFile: boolean, organizerGuid: string, callback: () => void) => void;
    taxSoftware: TaxSoftwareType;
}

interface ProSystemUploadModalState {
    disableSubmit: boolean;
    uploadData: SingleFileOrganizerUploadData[];
    sasContainer: any[];
}

export class ProSystemUploadModal extends React.Component<ProSystemUploadModalProps, ProSystemUploadModalState> {
    private taxYearList: any[];
    constructor(props: ProSystemUploadModalProps) {
        super(props);
        this.state = {
            disableSubmit: false,
            uploadData: [],
            sasContainer: []
        }
        this.taxYearList = getOrganizersTaxYearList();
    }

    componentConfig = {
        dropzoneSelector: 'div.filepicker',
        iconFiletypes: ['.pdf'],
        showFiletypeIcon: true,
        postUrl: "/api/"
    }

    djsConfig = {
        uploadMultiple: true,
        addRemoveLinks: true,
        acceptedFiles: "application/pdf",
        headers: { 'Access-Control-Allow-Origin': '*', "x-ms-blob-type": "BlockBlob" },
        previewsContainer: false,
        autoProcessQueue: false
    }

    eventHandlers = {
        init: () => {
            this.reset();
        },
        addedfiles: (files: any) => {
            try {
                const _self = this;
                _self.validateFileCount(files);
                let uploadData: SingleFileOrganizerUploadData[] = _self.convertToModel(files);
                uploadData = _self.handleDuplicates(uploadData);
                if (uploadData.length > 0) {
                    _self.validateFileContent(uploadData).then((result) => {
                        const fileModels: FileModel[] = [];
                        uploadData.map(obj => fileModels.push({ name: obj.name, file: obj.file } as FileModel));
                        checkIfAnyFileIsEncrypted(fileModels).then((fileModels) => {
                            const anyFileEncrypted = fileModels.length != uploadData.length;
                            const uploadedFilesCount = _self.state.uploadData.length;
                            let disableSubmit = true;
                            if (anyFileEncrypted && uploadedFilesCount > 0) {
                                disableSubmit = false;
                            }

                            result = result.filter(m => fileModels.some(x => x.name == m.name));
                            _self.setState({
                                uploadData: _self.state.uploadData.concat(result),
                                disableSubmit: disableSubmit
                            }, () => {
                                _self.getUploadLink();
                            });
                        });
                    });
                }
            }
            catch (error) {
                VenusNotifier.Warning(error.message, null);
            }
        }
    }

    validateFileCount = (uploadedFiles: any) => {
        if (uploadedFiles.length + this.state.uploadData.length > MAXFILELIMIT) {
            throw new Error(`You cannot upload more than ${MAXFILELIMIT} files.`);
        }
    }

    handleDuplicates = (uploadData: SingleFileOrganizerUploadData[]): SingleFileOrganizerUploadData[] => {
        const uploadHelperFunctions = new UploadFunctions();
        for (let i = 0; i < uploadData.length; i++) {
            const file = uploadData[i];
            let fileName: string = file.name || "";
            const fileExtension = getFileExtension(fileName);
            let filecount = 1;
            while (uploadHelperFunctions.isFileExist(fileName, this.state.uploadData)) {
                fileName = file.name || "";
                fileName = fileName.replace(`.${fileExtension}`, '');
                fileName = fileName + ` (${filecount}).` + fileExtension;
                filecount++;
            }
            uploadData[i].name = fileName;
        }
        return uploadData;
    }

    validateFileContent = (uploadData: SingleFileOrganizerUploadData[]): Promise<any> => {
        let promise: any = null;
        const _uploadData: SingleFileOrganizerUploadData[] = Object.assign({}, uploadData);
        for (let i = 0; i < uploadData.length; i++) {
            let uploadedFile = uploadData[i].file;
            promise = new Promise((resolve) => {
                GetFileMagicNumber(uploadedFile).then((result) => {
                    if (!validatePdfFileContent(result)) {
                        const index = uploadData.findIndex(x => x.name == _uploadData[i].name)
                        uploadData.splice(index, 1)
                        VenusNotifier.Warning(ValidationContants.PdfFileValidation, null);
                    }
                    resolve(result)
                });
            });
        }
        return promise.then(() => { return uploadData })
    }

    convertToModel = (uploadedFiles: any): SingleFileOrganizerUploadData[] => {
        const { taxSoftware } = this.props;
        const uploadData: SingleFileOrganizerUploadData[] = [];
        for (let i = 0; i < uploadedFiles.length; i++) {
            const uploadedFile = uploadedFiles[i];
            if (validateFileSize(uploadedFile)) {
                const tmpFileName = uploadedFiles[i].name;
                const fileExtension = getFileExtension(tmpFileName);
                if (validateFileType(fileExtension)) {
                    uploadData.push({
                        name: tmpFileName,
                        clientId: tmpFileName.replace(`.${fileExtension}`, ''),
                        progressBar: 0,
                        size: getFileSize(uploadedFile.size),
                        status: UploadStatus.Wait,
                        sasGuid: "",
                        gridRowDisable: true,
                        rowIndex: (uploadData.length +
                            this.state.uploadData.length) + 1,
                        file: uploadedFile,
                        taxSoftware: taxSoftware,
                        engagementType: EngagementType.E1040,
                        taxYear: 0,
                        uploadMethod: OrganizerDocumentUploadType.Proforma
                    });
                }
            }
        }
        return uploadData;
    }

    getUploadLink = () => {

        const _self = this;
        const uploadHelperFunctions = new UploadFunctions();
        this.state.uploadData.filter(x => x.status == UploadStatus.Wait).forEach((file: SingleFileOrganizerUploadData, index: number) => {

            file.status = UploadStatus.Initiating;

            this.props.getUploadLink(`${apiPrefix}Document`, (data?: OrganizerUploadState) => {

                try {

                    if (data) {

                        file.sasGuid = data ? data.guid : "";
                        file.status = UploadStatus.Uploading;

                        _self.setState({ uploadData: _self.state.uploadData },
                            () => uploadHelperFunctions.uploadedFile(file.file, data, file.name ? file.name : "",
                                _self.uploadProgressCallback, _self.uploadCommitCallBack));
                    } else {
                        throw new Error("Upload link not found !!");
                    }

                } catch (error) {
                    _self.state.uploadData.splice(index, 1);
                    _self.setState({ uploadData: _self.state.uploadData });
                }

            });

        });

    }

    onEngagementTypeChange = (eventValue: any, row: any) => {
        const _gridData = [...this.state.uploadData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.engagementType = eventValue
            }
        });

        this.setState({ uploadData: _gridData });
    }

    engagementTypeFormatter = (cell: any, row: any) => {
        return <DropdownComponent
            options={EngagementListLabelValue}
            disabled={true}
            id={"engagementType"}
            selectedValue={cell}
            onChange={(value) => { this.onEngagementTypeChange(value, row) }}
        />
    }

    taxYearFormatter = (cell: any, row: any) => {
        return <DropdownComponent
            options={this.taxYearList}
            disabled={row.gridRowDisable}
            id={"taxYear"}
            selectedValue={cell}
            clearable={false}
            onChange={(value) => { this.onTaxYearChange(value, row) }}
        />
    }

    onTaxYearChange = (eventValue: any, row: any) => {
        const _gridData = [...this.state.uploadData];
        _gridData.map((value) => {
            if (value.rowIndex === row.rowIndex) {
                value.taxYear = eventValue
            }
        });

        this.setState({ uploadData: _gridData });
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    fileNameFormatter = (cell: any, row: any) => {
        return <FormControl type="text" disabled={row.gridRowDisable}
            value={cell}
            onChange={(e: any) => this.onFileNameChange(e, row)}
            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB" />
    }

    onFileNameChange = (event: any, row: any) => {
        const _gridData = [...this.state.uploadData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.name = event.target.value
            }
        });

        this.setState({ uploadData: _gridData });
    }

    progressbarFormatter = (cell: any, row: any) => {
        return <ProgressBar striped
            variant={(cell != undefined) && (cell != 100) ? "warning" : "success"}
            now={cell} />
    }

    actionFormatter = (cell: any, row: any) => {
        return (
            <button type="button" className="btn-white btn-xs"
                disabled={(row.progressBar != undefined) && (row.progressBar != 100) ? true : false} onClick={() => this.deleteOrganizer(row)}>
                <i className="text-danger glyphicon glyphicon-remove"></i>
            </button>
        );
    }

    deleteOrganizer = (row: any) => {

        const _self = this;
        bootbox.confirm({
            message: UploadtaxReturnConstants.DeleteFileMessage,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    _self.props.deleteDocument(row.sasGuid, this.props.taxSoftware, true,"", () => {
                        var _gridData = [..._self.state.uploadData];
                        _gridData = _gridData.filter(i => i.rowIndex != row.rowIndex);
                        for (let i = 0; i < _gridData.length; i++) {
                            _gridData[i].rowIndex = i;
                            _gridData[i].number = i + 1;
                        }
                        const _tempgridData = _gridData;

                        let sasContainer = [..._self.state.sasContainer];
                        sasContainer = sasContainer.filter(i => i.sasGuid != row.sasGuid);

                        if (_gridData.length == 0) {
                            _self.setState({ disableSubmit: true });
                        }
                        _self.setState({ uploadData: _tempgridData, sasContainer: sasContainer }, () => _self.forceUpdate());
                    });
                }
            }
        });

    }



    handleUploaderClose = () => {
        if (this.state.uploadData.length === 0) {
            this.handleClose();
            return;
        }
        if (this.isUploadOnGoing()) {
            bootbox.alert({
                message: UploadtaxReturnConstants.AbortingUploadWarning,
                buttons: {
                    ok: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                }
            });
        }
        else {
            bootbox.confirm({
                message: UploadtaxReturnConstants.CloseConfirmationMessage,
                size: 'small',
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                        className: 'btn-white btn-default'
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i> OK',
                        className: 'btn-info'
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        this.handleClose();
                        this.forceUpdate();
                    }
                }
            });
        }
    }

    reset = () => {
        this.setState({ sasContainer: [], uploadData: [], disableSubmit: true });
    }

    handleClose = () => {
        this.reset();
        this.props.onHide();
    }

    isUploadOnGoing = (): boolean => {
        let isuploading: boolean = false;
        this.state.uploadData.map((value) => {
            if (value.progressBar == undefined ? 0 : value.progressBar < 100) {
                isuploading = true; return;
            }
        });
        return isuploading;
    }

    onSubmit = () => {
        if (this.validateUploadData(this.state.uploadData)) {
            const TraceEvent: IEventTelemetry = {
                name: `${CustomEvents.ProformaUpload}CCH`,
                properties: { count: this.state.uploadData.length }
            };
            logger?.trackEvent(TraceEvent);
            ShowLoader();
            this.props.submitDocuments(`${apiPrefix}Document`,
                JSON.stringify(this.state.uploadData), () => {
                    this.uploadConfirmation();
                    this.props.pageReload();
                    this.handleClose();
                    HideLoader();
                });
        }
    }

    uploadConfirmation = () => {
        let message: string = `<h5 class='marT0'>${organizerSubmitSuccess} :</h5><ul class='marL30 wordWrap-breakWord'>`;
        this.state.uploadData.map((value) => {
            message = message + `<li>${value.clientId}</li>`;
        });
        message = message + "</ul>";
        bootbox.alert({
            message: message,
            size: 'small',
            buttons: {
                ok: {
                    label: '<i class="glyphicon glyphicon-ok"></i> OK',
                    className: 'btn-info'
                }
            },
            callback() { }
        });
    }

    validateUploadData = (uploadData: SingleFileOrganizerUploadData[]): boolean => {
        let valid = true;
        for (let i = 0; i < uploadData.length; i++) {
            if (!uploadData[i].clientId || uploadData[i].clientId!.trim() === "") {
                VenusNotifier.Warning("Please enter client Id to submit", "");
                valid = false;
                break;
            }

            if (!uploadData[i].name || uploadData[i].name!.trim() === "") {
                VenusNotifier.Warning("Please enter file name to submit", "");
                valid = false;
                break;
            }

            if (!uploadData[i].engagementType) {
                VenusNotifier.Warning("Please select engagement type to submit", "");
                valid = false;
                break;
            }

            if (!uploadData[i].taxYear) {
                VenusNotifier.Warning("Please select tax year to submit", "");
                valid = false;
                break;
            }
        }
        return valid;
    }

    uploadProgressCallback = (percent: number, fileToUpload: any) => {
        const tempGridData = this.state.uploadData;
        tempGridData.map((tempGridDataValue) => {
            if (tempGridDataValue.sasGuid == fileToUpload.fileGUID) {
                tempGridDataValue.progressBar = percent - 10;
            }
        });
        this.setState({
            uploadData: tempGridData
        });
    }

    uploadCommitCallBack = (fileToUpload: any) => {
        if (this.state.uploadData.length > 0) {
            const tempGridData = this.state.uploadData;
            tempGridData.map((tempGridDataValue) => {
                if (tempGridDataValue.name == fileToUpload.fileName) {
                    tempGridDataValue.progressBar = 100;
                    tempGridDataValue.status = UploadStatus.Uploaded;
                    tempGridDataValue.gridRowDisable = false;
                }
            });
            this.setState({
                uploadData: tempGridData
            }, () => {
                if (!this.isUploadOnGoing()) {
                    this.setState({ disableSubmit: false });
                }
            });
        }
    }

    clientIdFormatter = (cell: any, row: any) => {
        return <FormControl type="text" disabled={row.gridRowDisable}
            value={cell}
            onChange={(e: any) => this.onClientIdChange(e, row)}
            data-test-auto="8E584D37-7EF3-4D2A-8CC8-E21FA295F6EB"
            maxLength={OrganizerClientIdConstants.ClientIdMaxLength} />
    }


    onClientIdChange = (event: any, row: any) => {
        const _gridData = [...this.state.uploadData];
        _gridData.map((value, index) => {
            if (value.rowIndex == row.rowIndex) {
                value.clientId = event.target.value
            }
        });

        this.setState({ uploadData: _gridData });
    }

    engagementTypeTooltip = (cell: any, row: any) => {
        return "Supporting 1040 Form Only";
    }

    render() {
        const columns = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                isHidden: true,
                width: 'auto',

            },
            {
                header: 'Name',
                key: 'name',
                isKey: false,
                dataFormat: this.fileNameFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: 'word-Visible text-left-align'
            },
            {
                header: 'Client Id',
                key: 'clientId',
                isKey: false,
                dataFormat: this.clientIdFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Tax Return Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.engagementTypeFormatter,
                tooltip: this.engagementTypeTooltip,
                width: 'auto',
                isHidden: false,
                columnClassName: 'overflowVisible'
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataFormat: this.taxYearFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: 'overflowVisible'
            },
            {
                header: 'Upload Progress',
                key: 'progressBar',
                isKey: false,
                dataFormat: this.progressbarFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Size',
                key: 'size',
                isKey: false,
                dataFormat: this.defaultFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                width: 'auto',
                isHidden: false,
                columnClassName: ''
            }
        ];

        return (<div>
            <Modal className="upload-doc-modal"
                show={this.props.show}
                onHide={this.handleUploaderClose} enforceFocus={false}>
                <Modal.Header closeButton >
                    <Modal.Title>{` Upload ${this.props.taxSoftware == TaxSoftwareType.ProSystems ? "CCH" : TaxSoftwareType[this.props.taxSoftware].toString()} Document `}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="upload-doc-body">
                    <div className='batch-note-info'>
                        <i className="fas fa-info-circle"></i>
                        {PreparerMessageConstants.UploadFileWarning}
                    </div>
                    <div className="row">
                        <div className="col-sm-2 text-center">
                            <DocumentUploadDropzoneComponent
                                componentConfig={this.componentConfig}
                                djsConfig={this.djsConfig}
                                eventHandlers={this.eventHandlers}
                                autoTestId={"2D99CF95-2A80-4173-BE02-6FDBC87A226B"}
                            />
                        </div>
                        <div className="col-sm-10 uploaded-documents-table-container" id="uploadDocument">
                            <UploadedDocumentTable
                                columns={columns}
                                data={this.state.uploadData}
                                bodyContainerClass={"overflowVisible"}
                                noDataText={"Files list will appear here."}
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={this.handleUploaderClose}>
                        <i className='glyphicon glyphicon-remove'></i>Close</Button>
                    <Button variant="info" onClick={this.onSubmit}
                        disabled={this.state.disableSubmit}>
                        <i className='glyphicon glyphicon-floppy-disk'></i>Submit</Button>
                </Modal.Footer>
            </Modal>
        </div>
        );
    }
}
