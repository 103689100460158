import * as React from "react";
import { Form } from "react-bootstrap";
import { useDispatch} from "react-redux";
import { IParagraphQuestion } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { CqAutomation } from "src/components/helper/AutomationLocators";
import { FormPreviewConstants } from "src/components/helper/Constants";
import { IOnChangeHandlerPayload } from "src/store/settings/CqPreview/ActionTypes";
import { HandleParagraphOnChange } from "src/store/settings/CqPreview/Actions";

interface ParagraphQuestionProps {
    questionDetails: IParagraphQuestion;
    isRequired: boolean;
    resetValidations: boolean;
    sectionIndex: number;
    sectionItemIndex: number;
    isEditMode?: boolean;
    followupQuestionId?:number;
}

const ParagraphQuestion: React.FC<ParagraphQuestionProps> = ({
    questionDetails,
    isRequired,
    sectionIndex,
    sectionItemIndex,
    resetValidations,
    isEditMode,
    followupQuestionId
}) => {
    const dispatch = useDispatch();
    const [answerState, setAnswerState] = React.useState(questionDetails.answer);
    const [isValid, setIsValid] = React.useState(true);
 
    React.useEffect(() => {
        setIsValid(true);
        setAnswerState("");
    }, [resetValidations,followupQuestionId]);
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.trimStart();
        setAnswerState(input);
        const payload: IOnChangeHandlerPayload = {
            value: input,
            sectionIndex,
            sectionItemIndex
        };
        setAnswerState(input);
        dispatch(HandleParagraphOnChange(payload));
        if (input === "") {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    };

    return (
        <>
            <Form.Control
                onChange={onChangeHandler}
                as="textarea"
                data-test-auto={CqAutomation.PreviewAnswer}
                placeholder={FormPreviewConstants.ParagraphTypeQuestion.placeholder}
                value={answerState}
                required={isRequired}
                maxLength={FormPreviewConstants.ParagraphTypeQuestion.maxLength}
                className={`non-resizable ${
                    !isValid && isRequired ? "invalid-input" : isEditMode ? "non-editable" : ""
                }`}
            />
            {!isValid && isRequired && (
                <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
            )}
        </>
    );
};

export default ParagraphQuestion;
