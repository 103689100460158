import { LogoutCause } from "../../Organizer/components/SignalR/SignalRWebSocket"
import { actionTypes } from '../ActionTypes';
import { Reducer, Action } from "redux";

export interface IUserPrivilege {
	logoutCause: LogoutCause;
	userPrivilegesChanged: boolean;
}

const initialUserPreviligeState: IUserPrivilege = {
	logoutCause: LogoutCause.None,
	userPrivilegesChanged: false
}

export interface UserPrivilegeChanged {
	type: actionTypes.USER_PRIVILEGE_CHANGED;
	payload: LogoutCause
}


export const reducer: Reducer<IUserPrivilege> = (userPrivilege: IUserPrivilege = initialUserPreviligeState, incomingAction: Action) => {
	const action = incomingAction as UserPrivilegeChanged;
	switch (action.type) {
		case actionTypes.USER_PRIVILEGE_CHANGED:
			let previlage = {...userPrivilege};
			previlage.logoutCause = action.payload ? action.payload : LogoutCause.None;
			previlage.userPrivilegesChanged = action.payload === LogoutCause.None ? false : true;
			return previlage;
		default:
			return userPrivilege;
		}
	}
