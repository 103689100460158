import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { CqAutomation } from "src/components/helper/AutomationLocators";

interface DeleteModalProps {
    show: boolean;
    modalHeader: string;
    modalBody: any;
    onClose: () => void;
    onConfirm: () => void;
    additionalConfirmButtonLabel?: string;
}

const DeleteModal: React.FC<DeleteModalProps> = ({
    modalHeader,
    modalBody,
    show,
    onClose,
    onConfirm,
    additionalConfirmButtonLabel
}) => {
    return (
        <Modal className="delete-modal" show={show} onHide={onClose} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{modalHeader}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bootbox-body">
                <p>{modalBody}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    data-test-auto={CqAutomation.SectionDeleteCancel}
                    variant="default"
                    className="btn-white"
                    onClick={onClose}
                >
                    <i className="glyphicon glyphicon-remove" />
                    Cancel
                </Button>
                <Button
                    data-test-auto={CqAutomation.SectionDeleteConfirm}
                    className="btn-info"
                    onClick={onConfirm}
                    variant="info"
                >
                    <i className="glyphicon glyphicon-ok" />
                    {additionalConfirmButtonLabel ?? "Confirm"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export { DeleteModal };
