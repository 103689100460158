import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { ISections } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { TemplateModalConstants } from "src/components/helper/Constants";
import Select from "react-select";


interface SectionMergeModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: (source, target) => void;
    sourceSection: ISections;
    sections: ISections[];
}

const SectionMergeModal: React.FC<SectionMergeModalProps> = ({ show, onClose, onConfirm, sourceSection, sections }) => {
    const [mergeSourceSection, setMergeSourceSection] = React.useState<any>({});
    const [targetSection, setTargetSection] = React.useState<any>();
    const [sectionsList, setSectionsList] = React.useState(sections || []);
    React.useEffect(() => {
        reConstructSections();
    }, [sections]);

    const reConstructSections = () => {
        sections = sections?.map((item) => {
            return { ...item, label: item.sectionName, value: item.sectionId };
        })
        sections = sections.filter((section) => section.sectionId !== sourceSection.sectionId);
        setSectionsList(sections);
        let section = { ...sourceSection, label: sourceSection.sectionName, value: sourceSection.sectionId };
        setMergeSourceSection(section);
    }

    const onTargetSectionChange = (selectedSection) => {
        setTargetSection(selectedSection);
    }

    const handleOnSubmit = () => {
        onConfirm(sourceSection, targetSection);
    };
    const hideModalHandler = () => {
        onClose();
    };

    const createCustomOption = (props: any) => {
        return (
            <div style={{ width: '100%', height: '30px', alignItems: 'center', paddingLeft: '5px', display: 'flex', flexDirection: 'row' }}
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.onSelect(props.option, e);
                }}
                onMouseEnter={(e) => { props.onFocus(props.option, e); }}
                onMouseMove={(e) => {
                    if (props.isFocused) return;
                    props.onFocus(props.option, e);
                }}>
                <input type="checkbox" className="cq-merge-sections-dropdown-checkbox" checked={props.isSelected} />
                <label className="cq-merge-sections-dropdown-label ellipsis" title={props.children}>{props.children}</label>
            </div>
        )
    }
    const createCustomTag = (props: any) => {
        let numberOfSectionSelected = targetSection?.length || 0;
        return (
            <div className="cq-merge-sections-dropdown-control">
                <label className="cq-merge-sections-dropdown-label">
                    {numberOfSectionSelected > 1 ? numberOfSectionSelected + " sections selected" : numberOfSectionSelected + " section selected"}
                </label>

            </div>
        );
    }

    return (
        <Modal className="template-modal-container" show={show} onHide={hideModalHandler} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {TemplateModalConstants.MergeSectionModalHeader}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bootbox-body">
                <div className="merge-section-modal-content">
                    <div>
                        <label>Merge</label>
                        <Select className="cq-merge-sections-dropdown"
                            options={[mergeSourceSection]}
                            value={mergeSourceSection}
                            clearable={false}
                            searchable={false}
                        />
                    </div>
                    <div>
                        <label>with</label>
                        <Select
                            className="cq-merge-sections-dropdown"
                            options={sectionsList}
                            onChange={onTargetSectionChange}
                            value={targetSection}
                            optionComponent={createCustomOption}
                            valueComponent={createCustomTag}
                            removeSelected={false}
                            multi
                            searchable={false}
                            backspaceRemoves={false}
                            placeholder={<label className="filter select-filter placeholder-selected">Select</label>}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" className="btn-white" onClick={hideModalHandler}>
                    <i className="glyphicon glyphicon-remove" />
                    Cancel
                </Button>
                <Button className="btn-info" onClick={handleOnSubmit} variant="info" disabled={!targetSection}>
                    <i className="glyphicon glyphicon-ok" />
                    {TemplateModalConstants.SectionMerge}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default SectionMergeModal;
