import * as React from "react";
import { Button } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import { RestartIcon } from "src/assets/SvgIconCollection";
import { ApplicationState } from "src/store";
import SectionPreview from "./Parts/SectionPreview";
import "./Preview.css";
import { IntialiseCqPreview } from "src/store/settings/CqPreview/Actions";
import { CqAutomation } from "src/components/helper/AutomationLocators";
import { Prompt } from "react-router";
import { CustomQuestionConstants } from "src/Organizer/components/Helper/OrganizerConstants";
interface IPreviewProps {
    onClickPreviewClose: (formData: any) => void;
}

const Preview: React.FC<IPreviewProps> = ({ onClickPreviewClose }) => {
    const dispatch = useDispatch();
    const [resetValidations, setResetValidations] = React.useState(false);
    const { isLoading, formData: intialFormData } = useSelector((state: ApplicationState) => state.formBuilder);
    const { formData } = useSelector((state: ApplicationState) => state.cqPreviewFormData);
    const [showPrompt, setShowPropmt] = React.useState<boolean>(false);

    React.useEffect(() => {
        window.addEventListener("beforeunload", alertUser);
        if (localStorage.getItem('updatedFormData') !== localStorage.getItem('formData')) {
            setShowPropmt(true);
        }
        else {
            setShowPropmt(false);
        }
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    const alertUser = (e) => {
        if (localStorage.getItem('updatedFormData') !== localStorage.getItem('formData')) {
            e.preventDefault();
            e.returnValue = "";
            return "";
        }
    }


    const handleOnClickRestart = () => {
        dispatch(IntialiseCqPreview(intialFormData));
        setResetValidations(!resetValidations);
    };

    const handleOnClickClose = () => {
        onClickPreviewClose({ title: formData.title, description: formData.description });
    };

    return (
        <LoadingOverlay style={{ height: "100%" }}>
            <div className="form-builder-container">
                <Prompt
                    when={showPrompt}
                    message={CustomQuestionConstants.PromptMessage}
                />
                <header>
                    <div className="header-bottom-container">
                        <span title={formData.template.name} className="ellipsis">
                            {formData.template.name}
                        </span>
                        <div>
                            <Button className="restart-button"
                                data-test-auto={CqAutomation.PreviewRestart}
                                onClick={handleOnClickRestart}
                                variant="outline-secondary btn-with-icon header-sec-button"
                            >
                                <RestartIcon />
                                Restart
                            </Button>

                            <Button data-test-auto={CqAutomation.PreviewClose} onClick={handleOnClickClose} variant="primary">
                                Close
                            </Button>
                        </div>
                    </div>
                </header>

                <main>
                    <div className="questionnaire-header-container-with-more-padding">
                        <div className="preview-title">
                            {formData.title}
                        </div>
                        <div className="description-container">
                            <div className="preview-description">
                                {formData.description}
                            </div>
                        </div>
                    </div>

                    {formData &&
                        formData.sections.length > 0 &&
                        formData.sections.map((section, sectionIndex) => (
                            <SectionPreview resetValidations={resetValidations} sectionIndex={sectionIndex} section={section} />
                        ))}
                </main>
            </div>

            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default Preview;
