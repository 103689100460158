import React, { useEffect } from 'react'
import { signinRedirectCallback } from '../oidcClient/userService'
import LoadingView from '../components/LoadingView'
import queryString from 'query-string';
import history from '../routes/history';

function SigninOidc() {
    useEffect(() => {
        signinRedirectCallback()
        return () => {
            if (history?.location?.search) {
                const params = queryString.parse(history.location.search);
                if (params.callback_args) {
                    history.push(params.callback_args)
                } else {
                    history.push('/');
                }
            } else {
                history.push('/');
            }
        }
    }, [])

    return <LoadingView/>
}

export default SigninOidc;
