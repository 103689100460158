import { IFormData } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { IOnChangeHandlerPayload, actionTypes } from "./ActionTypes";

export const HandleParagraphOnChange = (data: IOnChangeHandlerPayload) => {
    return {
        type: actionTypes.HANDLE_PARAGRAPH_ON_CHANGE,
        payload: data
    };
};
export const IntialiseCqPreview = (data: IFormData) => {
    return {
        type: actionTypes.INTIALISE_CQ_PREVIEW_FORM,
        payload: data
    };
};
