import {
    ICustomQuestionSettings,
    IFormData,
    ISections,
    ITemplateModalData
} from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import {
    IAddSubQuestionPayload,
    IRemoveSubQuestionPayload,
    IDupicateQuestionPayload,
    IDupicateSectionPayload,
    IHandleChoiceIdentifierPayload,
    IHandleAddChoiceIdentifierPayload,
    IHandleChoiceTextOnChangePayload,
    IHandleQuestionTypeChangePayload,
    IHandleSubQuestionTypeChangePayload,
    ISubQuestionChoiceIdentifierPayload,
    ISubQuestionChoiceTextOnChangePayload,
    ISubQuestionTitlePayload,
    KnownActions,
    actionTypes
} from "./ActionTypes";
import { actionTypes as NotificationAction } from "./../../ActionTypes";
import { addTask } from "domain-task";
import { API_BASE_URL } from "src/utils/constants";
import { handleResponse } from "src/store/Library";
import { AppThunkAction } from "src/store";
import { StatusType } from "src/store/common/NotificationStore";
import { TemplateModalConstants } from "src/components/helper/Constants";
import {
    IItemIdentifier,
    IOnChangeHandlerPayload,
    IQuestionReOrderPayload,
    ITitleOnChangeHandlerPayload
} from "../CqPreview/ActionTypes";

export const SaveTemplateModalData = (data: ITemplateModalData) => {
    return {
        type: actionTypes.ADD_TEMPLATE_MODAL_DATA,
        payload: data
    };
};
export const SaveSectionModalData = (data: string) => {
    return {
        type: actionTypes.ADD_SECTION_MODAL_DATA,
        payload: data
    };
};
export const RenameSectionModalData = (sectionName: string, sectionIndex: number) => {
    return {
        type: actionTypes.RENAME_SECTION_MODAL_DATA,
        sectionName,
        sectionIndex
    };
};
export const DeleteSectionData = (sectionIndex: number) => {
    return {
        type: actionTypes.DELTE_SECTION,
        sectionIndex
    };
};

export const ClearFormData = () => {
    return {
        type: actionTypes.CLEAR_FORM_DATA
    };
};

export const HandleQuestionTitleOnChange = (data: IOnChangeHandlerPayload) => {
    return {
        type: actionTypes.HANDLE_QUESTION_TITLE_ON_CHANGE,
        payload: data
    };
};

export const AddNewQuestionHandler = (data: number) => {
    return {
        type: actionTypes.ADD_NEW_QUESTION,
        payload: data
    };
};
export const MergeSectionHandler = (data: IFormData) => {
    return { type: actionTypes.HANDLE_MERGE_SECTION, payload: data };
};
export const DuplicateSectionHandler = (data: IDupicateSectionPayload) => {
    return { type: actionTypes.HANDLE_DUPLICATE_SECTION, payload: data };
};

export const DuplicateQuestionHandler = (data: IDupicateQuestionPayload) => {
    return { type: actionTypes.HANDLE_DUPLICATE_QUESTION, payload: data };
};
export const DeleteQuestionHandler = (data: IItemIdentifier) => {
    return {
        type: actionTypes.HANDLE_DELETE_QUESTION,
        payload: data
    };
};
export const ChangeRequiredQuestionHandler = (data: IItemIdentifier) => {
    return {
        type: actionTypes.HANDLE_CHANGE_REQUIRED_QUESTION,
        payload: data
    };
};
export const HandleTitleOnChange = (data: ITitleOnChangeHandlerPayload) => {
    return {
        type: actionTypes.HANDLE_TITLE_ON_CHANGE,
        payload: data
    };
};
export const HandleDescriptionOnChange = (data: ITitleOnChangeHandlerPayload) => {
    return {
        type: actionTypes.HANDLE_DESCRIPTION_ON_CHANGE,
        payload: data
    };
};

export const SetIsInvalidFlag = () => {
    return {
        type: actionTypes.CHECK_IS_FORM_VALID
    };
};

export const HandleQuestionReordering = (data: IQuestionReOrderPayload) => {
    return {
        type: actionTypes.HANDLE_QUESTION_REORDERING,
        payload: data
    };
};

export const HandleSectionReordering = (data: IFormData) => {
    return {
        type: actionTypes.HANDLE_SECTION_REORDERING,
        payload: data
    };
};
export const HandleQuestionTypeChange = (data: IHandleQuestionTypeChangePayload) => {
    return {
        type: actionTypes.HANDLE_QUESTION_TYPE_CHANGE,
        payload: data
    };
};

export const HandleChoiceTextOnChange = (data: IHandleChoiceTextOnChangePayload) => {
    return {
        type: actionTypes.HANDLE_CHOICE_TEXT_ONCHANGE,
        payload: data
    };
};
export const HandleAddChoice = (data: IItemIdentifier) => {
    return {
        type: actionTypes.HANDLE_ADD_CHOICE,
        payload: data
    };
};
export const HandleRemoveChoice = (data: IHandleChoiceIdentifierPayload) => {
    return {
        type: actionTypes.HANDLE_REMOVE_CHOICE,
        payload: data
    };
};
export const OnClickYesNoCheckbox = (data: IHandleChoiceIdentifierPayload) => {
    return {
        type: actionTypes.ON_CLICK_YES_NO_CHECKBOX,
        payload: data
    };
};
export const OnAddAdditionalQuestion = (data: IAddSubQuestionPayload) => {
    return {
        type: actionTypes.ON_ADD_SUB_QUESTION,
        payload: data
    };
};
export const OnRemoveAdditionalQuestion = (data: IRemoveSubQuestionPayload) =>{
    return {
        type: actionTypes.ON_REMOVE_SUB_QUESTION,
        payload: data
    };
};
export const OnSubQuestionTypeChange = (data: IHandleSubQuestionTypeChangePayload) => {
    return {
        type: actionTypes.ON_SUB_QUESTION_TYPE_CHANGE,
        payload: data
    };
};
export const OnChangeSubQuestionTitle = (data: ISubQuestionTitlePayload) => {
    return {
        type: actionTypes.ON_CHANGE_FOLLOW_UP_QUESTION_TITLE,
        payload: data
    };
};
export const HandleSubQuestionChoiceTextOnChange = (data: ISubQuestionChoiceTextOnChangePayload) => {
    return {
        type: actionTypes.HANDLE_SUB_QUESTION_CHOICE_TEXT_ONCHANGE,
        payload: data
    };
};
export const HandleSubQuestionAddChoice = (data: IHandleAddChoiceIdentifierPayload) => {
    return {
        type: actionTypes.HANDLE_SUB_QUESTION_ADD_CHOICE,
        payload: data
    };
};
export const HandleSubQuestionRemoveChoice = (data: ISubQuestionChoiceIdentifierPayload) => {
    return {
        type: actionTypes.HANDLE_SUB_QUESTION_REMOVE_CHOICE,
        payload: data
    };
};
export const HandleResetSubQuestions = (data: IItemIdentifier, isChecked: boolean) => {
    return {
        type: actionTypes.RESET_SUB_QUESTIONS,
        payload: { ...data, isChecked }
    };
};

export const SaveTemplate =
    (formData: IFormData, callback?: () => void): AppThunkAction<KnownActions> =>
    (dispatch) => {
        dispatch({
            type: actionTypes.START_FORM_LOADER
        });
        let fetchTask = fetch(`${API_BASE_URL}/api/organizer/questionnaire-forms`, {
            method: "POST",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: NotificationAction.NOTIFICATION,
                    statusMessage: TemplateModalConstants.AddSuccessMessage,
                    statusType: StatusType.Success
                });
                if (callback) callback();
            })
            .catch((error) => {
                dispatch({
                    type: NotificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.STOP_FORM_LOADER });
            });
        addTask(fetchTask);
    };

export const getFormData =
    (templateId: number, callback: () => void): AppThunkAction<KnownActions> =>
    (dispatch) => {
        dispatch({
            type: actionTypes.START_FORM_LOADER
        });

        let fetchTask = fetch(`${API_BASE_URL}/api/organizer/questionnaire-forms/${templateId}`, {
            method: "GET",
            credentials: "include"
        })
            .then(handleResponse)
            .then((response) => response)
            .then((data: IFormData) => {
                dispatch({
                    type: actionTypes.GET_FORM_DATA,
                    payload: data
                });
                callback();
            })
            .catch((error) => {
                dispatch({
                    type: NotificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.STOP_FORM_LOADER });
            });
        addTask(fetchTask);
    };

export const EditTemplate =
    (formData: IFormData, callback?: () => void): AppThunkAction<KnownActions> =>
    (dispatch) => {
        dispatch({
            type: actionTypes.START_FORM_LOADER
        });
        let fetchTask = fetch(`${API_BASE_URL}/api/organizer/questionnaire-forms/${formData.template.templateId}`, {
            method: "PUT",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: NotificationAction.NOTIFICATION,
                    statusMessage: TemplateModalConstants.EditSuccessMessage,
                    statusType: StatusType.Success
                });
                if (callback) callback();
            })
            .catch((error) => {
                dispatch({
                    type: NotificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.STOP_FORM_LOADER });
            });
        addTask(fetchTask);
    };
export const getCustomQuestionSettings = (): AppThunkAction<KnownActions> => (dispatch) => {
    dispatch({
        type: actionTypes.START_FORM_LOADER
    });

    let fetchTask = fetch(`${API_BASE_URL}/api/organizer/questionnaire-forms/settings`, {
        method: "GET",
        credentials: "include"
    })
        .then(handleResponse)
        .then((response) => response)
        .then((data: ICustomQuestionSettings) => {
            dispatch({
                type: actionTypes.RECIEVE_CUSTOM_QUESTION_SETTINGS,
                payload: data
            });
        })
        .catch((error) => {
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
                statusCode: error?.status
            });
        })
        .finally(() => {
            dispatch({ type: actionTypes.STOP_FORM_LOADER });
        });
    addTask(fetchTask);
};
