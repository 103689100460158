import * as React from 'react';
import { Button } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { PreparerMessage, initialPreparerMessage } from './../../../models/OrganizerComponentModels';
import { PreparerMessageList } from './PreparerMessageList';
import { PreparerMessageView } from './PreparerMessageView';

interface PreparerMessageComponentProps {
    heading: string;
    description: string;
    preparerMessages: PreparerMessage[];
    defaultMessage: number;
    selectedMessage: number;
    onListItemClick: (messageId: number) => void;
    onAddButtonClick: () => void;
    onEditButtonClick: () => void;
    onDeleteButtonClick: () => void;
    showLoader: boolean;
}


export const PreparerMessageComponent: React.StatelessComponent<PreparerMessageComponentProps> = ({
    heading, defaultMessage, description, preparerMessages, onAddButtonClick, onDeleteButtonClick, onEditButtonClick,
    onListItemClick, selectedMessage, showLoader
}) => {
    let selectedPreparerMessage = preparerMessages.find(message => message.id === selectedMessage);
    selectedPreparerMessage = selectedPreparerMessage ? selectedPreparerMessage : preparerMessages.find(message => message.id === defaultMessage);
    return (<LoadingOverlay style={{ height: '100%' }}>
        <div className="row">
            <div style={{ marginLeft: 16, marginBottom: 10, fontSize: '14px' }}>
                <h3 className="text-primary">{heading}</h3>
            </div>
        </div>
        <div className="row" style={{ position: "relative" }}>
            <div className="col-lg-5 col-sm-6" style={{ marginLeft: 17 }}>
                <div className="row" style={{ display: 'flow-root' }}>
                    <div className="pull-left preparer-message-info-div preparer-message-font-size">
                        <i className="fas fa-info-circle"></i>
                        <span>{description}</span>
                    </div>
                    <div className="pull-right">
                        <Button
                            data-test-auto="a8027bdc-92ad-4a57-afa5-2e987dc9141d"
                            variant='info'
                            className="btn btn-info btn-lg glyphicon glyphicon-plus"
                            style={{ padding: 12, minWidth: 40 }}
                            title="Add Message"
                            onClick={onAddButtonClick}>
                        </Button>
                    </div>
                </div>
                {preparerMessages.length > 0 &&
                    <div className="row" style={{ marginTop: 10, overflowY: 'auto', height: 245, border: 'rgb(221, 221, 221) 1px solid', fontSize: '14px' }}>
                        <div style={{ width: '100%' }}>
                            <PreparerMessageList
                                preparerMessages={preparerMessages}
                                onListItemClick={onListItemClick}
                                selectedMessageId={selectedMessage}
                                defaultMessageId={defaultMessage}
                            />
                        </div>
                    </div>
                }
            </div>

            {preparerMessages.length > 0 &&
                <div className="col-lg-5 col-sm-6" style={{ marginLeft: 32 }}>
                    <div className="row" style={{ display: 'flow-root' }}>
                        <div className="pull-right">
                            <Button
                                data-test-auto="f804076f-b878-41e6-bfcd-2c567eca59ef"
                                variant="default"
                                className="btn btn-secondary btn-lg fas fa-edit"
                                style={{ padding: 12, minWidth: 40 }}
                                title="Edit Message"
                                onClick={onEditButtonClick}>
                            </Button>
                            <Button
                                variant="default"
                                className="btn btn-secondary btn-lg fas fa-trash-alt"
                                title="Delete Message"
                                style={{ marginLeft: 5, padding: 12, minWidth: 40 }}
                                onClick={onDeleteButtonClick}>
                            </Button>
                        </div>
                    </div>


                    <div className="row" style={{ marginTop: 10, height: 245, border: '1px solid rgb(221, 221, 221)', padding: 5, overflow: 'auto' }}>
                        <PreparerMessageView
                            selectedPreparerMessage={selectedPreparerMessage ? selectedPreparerMessage : initialPreparerMessage}
                            defaultMessageId={defaultMessage}
                        />
                    </div>
                </div>
            }
        </div>

        <Loader loading={showLoader} />
    </LoadingOverlay>)
}