import * as React from 'react';
import { ButtonToolbar, DropdownButton } from 'react-bootstrap';
import 'isomorphic-fetch';
import { Link } from 'react-router-dom';
import { OrganizerClient, OrganizerClientType, OrganizerStatus } from '../../../models/OrganizerComponentModels';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";
import { ReopenOrganizerBlueIcon } from 'src/assets/SvgIconCollection';
import { OrganizerDocumentConstants } from '../../Helper/OrganizerConstants';

interface OrganizerActionProps {
    title: string;
    rowIndex: number;
    onRestore: (rowIndex: number) => void;
    onEditClientInfoOpen: (rowIndex: number) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    onDownloadOpen: (rowIndex: number) => void;
    onClientTrackingOpen: (rowIndex: number) => void;
    onDeleteOpen: (rowIndex: number) => void;
    onResendAccessLinkOpen: (rowIndex: number) => void;
    onReopenOrganizerLinkOpen: (rowIndex: number) => void;
    onCloseOrganizerLinkOpen: (rowIndex: number) => void;
    onViewAccessCodeOpen: (rowIndex: number) => void;
    onUnlockOrganizer: (rowIndex: number) => void;
    onClientViewLinkClick: (rowIndex: number, clientType: OrganizerClientType, callback: () => void) => void;
    onRequestOrganizerClients: (rowIndex: number) => void;
    onCancelOrganizerClients: () => void;
    onSendReminderActionClick: (rowIndex: number) => void;
    batchView?: boolean;
    data: any;
    organizerClients: OrganizerClient[];
    isArchived: boolean;
    isSourceDocumentUploadCompleted: boolean;
    onSourceDocumentsUploadOpen: (rowIndex: number) => void;
    isGatheringSourceDocumentsEnabled: boolean;
    resourcesList: any;
}

const OrganizerDeliveredAction: React.StatelessComponent<OrganizerActionProps> =
    ({
        onEditClientInfoOpen, onReportProblemOpen, rowIndex, onClientTrackingOpen,
        onResendAccessLinkOpen, onReopenOrganizerLinkOpen, onCloseOrganizerLinkOpen, onDownloadOpen, onDeleteOpen,
        onViewAccessCodeOpen, onUnlockOrganizer, batchView, data,
        onClientViewLinkClick, onRequestOrganizerClients, onCancelOrganizerClients,
        organizerClients, isArchived, onRestore,
        onSourceDocumentsUploadOpen, isSourceDocumentUploadCompleted,
        isGatheringSourceDocumentsEnabled,
        onSendReminderActionClick,
        resourcesList
    }) => {
        let isClientViewEnabled: boolean = false;
        let isReopenSourceDocumentUploadEnabled: boolean = false;
        if (resourcesList && Array.isArray(resourcesList)) {
            resourcesList.forEach((resource: any) => {
                if (resource === ResourceIdConstants.ArchivedOrganizerGirdClientViewBtn || resource === ResourceIdConstants.DeliveredOrganizerGirdClientViewBtn) {
                    isClientViewEnabled = true;
                }
                if (resource === ResourceIdConstants.ReopenSourceDocumentUpload) {
                    isReopenSourceDocumentUploadEnabled = true;
                }
            });
        }

        const editClientInfoButton = () => {
            let title = OrganizerDocumentConstants.EditClientInfo;
            if (data.organizerStatus === OrganizerStatus.ClosedByFirm) {
                title = OrganizerDocumentConstants.NotAvailableForClosed;
            }
            return (
                <button data-test-auto="c879c08b-679d-41d8-8e61-9a6e43f7437d" title={title} type="button" className="btn-white btn-xs"
                    onClick={() => onEditClientInfoOpen(rowIndex)}
                    disabled={data.organizerStatus === OrganizerStatus.ClosedByFirm}
                    data-resource-id={ResourceIdConstants.DeliveredOrganizerGirdEditClientBtn}>
                    <i className={`fas fa-address-card text-success ${data.organizerStatus === OrganizerStatus.ClosedByFirm ? "disabled" : ""}`}></i>
                </button>
            );
        }

        const restoreButton = () => {
            return (
                <button style={{ color: '#17a2b8' }} data-test-auto="c879c08b-679d-41d8-8e61-9a6e43f7446d" title={"Restore Organizer"} type="button" className="btn-white btn-xs"
                    data-resource-id={ResourceIdConstants.RestoreArchivedGrid}
                    onClick={() => onRestore(rowIndex)}>
                    <i className='fas fa-sync-alt'></i>
                </button>
            );
        }

        const downloadButton = () => {
            return (
                <button data-test-auto="055fb114-8f73-45f2-b533-9ea73ff966ba" title={"Download"} type="button" className="btn-white btn-xs"
                    data-resource-id={isArchived ? ResourceIdConstants.DownloadArchivedOrganizerGird : ResourceIdConstants.DownloadDeliveredOrganizerGird}
                    onClick={() => onDownloadOpen(rowIndex)}>
                    <i className='fas fa-file-download'></i>
                </button>
            );
        }

        const deleteButton = () => {
            return (
                <button data-test-auto="cc725078-593a-4909-9de1-4046d90b8b61" title={"Delete"} type="button" className="btn-white btn-xs"
                    data-resource-id={isArchived ? ResourceIdConstants.DeleteArchivedOrganizerGrid : ResourceIdConstants.DeleteDeliveredOrganizerGird}
                    onClick={() => { onDeleteOpen(rowIndex) }}>
                    <i className="text-danger glyphicon glyphicon-remove" />
                </button>
            );
        }

        const createPrimaryActions = () => {

            return (<div>
                {
                    isArchived ? restoreButton() : editClientInfoButton()
                }
                {
                    downloadButton()
                }
                {
                    !batchView && deleteButton()
                }
                {
                    createSecondaryActions()
                }</div>);
        }

        const createSecondaryActions = () => {

            let ClientInfoModelsli = <div className="marL10">Loading...</div>;
            organizerClients && organizerClients.length > 0 ?
                ClientInfoModelsli = <div>
                    {
                        organizerClients.map((orgClient) => {
                            if (!orgClient.isDeceased) {
                                return (<li>
                                    <Link to={"#"}
                                        onClick={() => onClientViewLinkClick(rowIndex, orgClient.clientType, () => { })} data-test-auto="DF4D23E8-206A-4A2C-8D99-A6E872025BEC">
                                        <span className="marL05 marR05">{orgClient.name + (orgClient.email ? " (" + orgClient.email + ") " : "")}</span>
                                    </Link>
                                </li>)
                            }
                        })
                    }
                </div>
                : null

            let isExcludedOption: boolean = data.organizerStatus == OrganizerStatus.ClosedByFirm || data.hasOrganizerWithOldCustomQuestion;
            return (
                <ButtonToolbar className="btn-group-vertical" title={"More.."} style={{ display: 'inline-flex' }} >
                    <DropdownButton
                        title={<span className="fa fa-ellipsis-h" style={{ verticalAlign: "inherit" }}></span>}
                        id="organizer-delivered">

                        {data.isDocumentLocked && data.organizerStatus != OrganizerStatus.ClosedByFirm &&
                            <li title="Unlock Organizer">
                                <Link data-test-auto="25b7950d-9821-48d8-ad08-1726981811af" to={"#"}
                                    onClick={() => onUnlockOrganizer(rowIndex)} >
                                    <i className='fas fa-unlock ddl-icon'></i>{OrganizerDocumentConstants.Menu.UnlockOrganizer}</Link>
                            </li>
                        }

                        {!isExcludedOption &&
                            <li title={OrganizerDocumentConstants.Menu.ResendAccessLink}>
                                <Link
                                    data-test-auto="25b7950d-9821-48d8-ad08-1726981811af"
                                    to={"#"}
                                    data-resource-id={isArchived ? ResourceIdConstants.ResendAccessLinkInArchivedOrganizer : ResourceIdConstants.ResendAccessLinkInDeliveredOrganizer}
                                    onClick={() => onResendAccessLinkOpen(rowIndex)} >
                                    <i className='fas fa-link ddl-icon'></i>{OrganizerDocumentConstants.Menu.ResendAccessLink}</Link>
                            </li>
                        }
                        {!isExcludedOption &&
                            <li className="dropdown-submenu dropdown-menu-right dropdown-submenu-right" title={OrganizerDocumentConstants.Menu.ClientView}
                                onMouseEnter={() => onRequestOrganizerClients(rowIndex)} onMouseLeave={() => onCancelOrganizerClients()}>
                                <Link to={"#"}
                                    data-test-auto="2498F069-A899-4392-B96A-AD8DAFAF0D99"
                                    data-resource-id={isArchived ? ResourceIdConstants.ArchivedOrganizerGirdClientViewBtn : ResourceIdConstants.DeliveredOrganizerGirdClientViewBtn}>
                                    <i className='fas fa-eye ddl-icon'></i>{OrganizerDocumentConstants.Menu.ClientView}</Link>

                                {isClientViewEnabled && <ul className="dropdown-menu dropdown-menu-ul" onMouseLeave={() => onCancelOrganizerClients()} ref={(el) => {
                                    if (el) {
                                        el.style.setProperty('position', 'absolute', 'important');
                                    }
                                }}>
                                    {ClientInfoModelsli}
                                </ul>
                                }
                            </li>
                        }
                        <li title={OrganizerDocumentConstants.Menu.ClientTracking}>
                            <Link data-test-auto="253d56f4-137b-4e68-b10f-be81fb5cae99" to={"#"}
                                data-resource-id={isArchived ? ResourceIdConstants.ArchivedOrganizerGirdClientTrackingBtn : ResourceIdConstants.DeliveredOrganizerGirdClientTrackingBtn}
                                onClick={() => onClientTrackingOpen(rowIndex)}>
                                <i className='fas fa-user-clock ddl-icon'></i>{OrganizerDocumentConstants.Menu.ClientTracking}</Link>
                        </li>
                        {(!isArchived) && !isExcludedOption &&
                            <li title={OrganizerDocumentConstants.Menu.SendReminder}>
                                <Link data-test-auto="5c70220b-a403-4ae4-9540-317dd15e540c" to={"#"}
                                    data-resource-id={ResourceIdConstants.SendReminderGrid}
                                    onClick={() => onSendReminderActionClick(rowIndex)}>
                                    <i className='fas fa-paper-plane ddl-icon'></i>
                                    {OrganizerDocumentConstants.Menu.SendReminder}
                                </Link>
                            </li>
                        }
                        {!isExcludedOption &&
                            <li title={OrganizerDocumentConstants.Menu.ViewAccessCode}>
                                <Link
                                    data-test-auto="ef5513ca-5ee4-4061-96d5-d603937e21a3"
                                    to={"#"}
                                    data-resource-id={isArchived ? ResourceIdConstants.ViewAccessCodeInArchivedOrganizer : ResourceIdConstants.ViewAccessCodeInDeliveredOrganizer}
                                    onClick={() => onViewAccessCodeOpen(rowIndex)} >
                                    <i className='fas fa-user-lock ddl-icon'></i>{OrganizerDocumentConstants.Menu.ViewAccessCode}</Link>
                            </li>
                        }
                        {
                            (data.organizerStatus == OrganizerStatus.Completed || data.organizerStatus == OrganizerStatus.ManuallyCompleted
                                || data.organizerStatus == OrganizerStatus.OrganizerDownloaded)
                            && !isArchived && !data.hasOrganizerWithOldCustomQuestion &&
                            <li title={OrganizerDocumentConstants.Menu.ReopenOrganizer} className="reopen-organizer">
                                <Link
                                    data-test-auto="a4d8298b-7c1b-491f-b535-3bed900859c5"
                                    to={"#"}
                                    data-resource-id={ResourceIdConstants.ReopenOrganizer}
                                    onClick={() => onReopenOrganizerLinkOpen(rowIndex)} >
                                    <ReopenOrganizerBlueIcon />
                                    {OrganizerDocumentConstants.Menu.ReopenOrganizer}
                                </Link>
                            </li>
                        }
                        {
                            (isSourceDocumentUploadCompleted && !isArchived) && !data.hasOrganizerWithOldCustomQuestion && data.organizerStatus != OrganizerStatus.ClosedByFirm &&
                            <li title={isGatheringSourceDocumentsEnabled ? OrganizerDocumentConstants.ReopenSourceDocUpload : OrganizerDocumentConstants.DocumentUploadDisabled} style={{ opacity: isReopenSourceDocumentUploadEnabled ? 1 : 0.65 }}>
                                <Link
                                    data-test-auto="ef5513ca-5ee4-4061-96d5-d605637e21r5"
                                    to={"#"}
                                    data-resource-id={ResourceIdConstants.ReopenSourceDocumentUpload}
                                    onClick={isGatheringSourceDocumentsEnabled ? () => onSourceDocumentsUploadOpen(rowIndex) : () => { }} style={{ opacity: isGatheringSourceDocumentsEnabled ? 1 : 0.4 }}>
                                    <i className='fas fa-folder-open ddl-icon'></i>{OrganizerDocumentConstants.Menu.ReopenDocUpload}</Link>
                            </li>
                        }
                        <li title={OrganizerDocumentConstants.Menu.ReportAProblem}>
                            <Link data-test-auto="86de263e-936b-4ba0-a208-7b64ab264854" to={"#"}
                                data-resource-id={isArchived ? ResourceIdConstants.ReportProblemArchivedOrganizer : ResourceIdConstants.ReportProblemDeliveredOrganizer}
                                onClick={() => onReportProblemOpen(rowIndex)}>
                                <i className='fas fa-bullhorn ddl-icon'></i>{OrganizerDocumentConstants.Menu.ReportAProblem}</Link>
                        </li>
                        {
                            (!data.hasOrganizerWithOldCustomQuestion && <li title={data.organizerStatus === OrganizerStatus.ClosedByFirm ? OrganizerDocumentConstants.Menu.ReopenByFirm : OrganizerDocumentConstants.Menu.CloseByFirm}
                                className="reopen-organizer">
                                <Link
                                    data-test-auto="a4d8298b-7c1b-491f-b535-3bed900859c5"
                                    data-resource-id={isArchived ? ResourceIdConstants.ClosedByFirmArchiveGrid : ResourceIdConstants.ClosedByFirmDeliveredGrid}
                                    to={"#"}
                                    onClick={() => onCloseOrganizerLinkOpen(rowIndex)} >
                                    <ReopenOrganizerBlueIcon />
                                    {data.organizerStatus === OrganizerStatus.ClosedByFirm ? OrganizerDocumentConstants.Menu.ReopenByFirm : OrganizerDocumentConstants.Menu.CloseByFirm}
                                </Link>
                            </li>)
                        }
                    </DropdownButton>
                </ButtonToolbar>);
        }

        return <div className="button-group">{createPrimaryActions()}</div>;
    }

export { OrganizerDeliveredAction }
