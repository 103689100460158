export interface SasContainer {
    sasGuid: string
}

export enum UploadStatus {
    Wait = 0,
    Initiating = 1,
    Uploading = 2,
    Uploaded = 3,
    Error = 4,
    Converting = 5
}

export interface TaxReturnUploadData {
    number?: number,
    clientName?: string,
    name?: string,
    progressBar?: number,
    size?: string,
    status?: UploadStatus,
    clientId?: string,
    partnerId?: number,
    setAccess?: string,
    uuid?: string
    sasGuid?: string,
    gridRowDisable: boolean,
    documentAccess: IDocumentAccess,
    rowIndex?: number,
    file?: any
}

export interface UltraTaxReturnUploadData {
    clientId: string,
    sasGuid: string,
    setAccess: string,
    clientName: string,
    partnerId: number,
    documentAccess: IDocumentAccess,
    subDocumentsUploadData: subDocumentUploadData[],   
}


export interface GoSystemReturnUploadData {
    clientId: string,
    sasGuid: string,
    setAccess: string,
    clientName: string,
    partnerId: number,
    documentAccess: IDocumentAccess,
    subDocumentsUploadData: subDocumentUploadData[],
}

export interface LacerteReturnUploadData {
    clientId: string,
    sasGuid: string,
    setAccess: string,
    clientName: string,
    partnerId: number,
    documentAccess: IDocumentAccess,
    subDocumentsUploadData: subDocumentUploadData[],
}



export interface subDocumentUploadData {
    number?: number,
    name?: string,
    progressBar?: number,
    size?: string,
    subDocumentGuid: string,
    status: UploadStatus,
    file?: any
}

interface IDocumentAccess {
    documentId: number;
    userId: number[];
}