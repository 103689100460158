import * as React from 'react';
import {
    Row, FormControl, Col,
    FormLabel, Modal, Button
} from 'react-bootstrap';
import { DisplayEngagementListLabelValue, EngagementType } from './../../../models/OrganizerComponentModels';
import Select from 'react-select';
import { VenusNotifier } from '../../../../components/helper/VenusNotifier';
import { getOrganizersTaxYearList } from './../../Helper/OrganizerHelperFunctions';

interface BatchInfoModalProps {
    batchName: string;
    taxYear: number;
    returnType: EngagementType;
    onClose: () => void;
    show: boolean;
    onSave: () => void;
    onBatchNameChange: (e: any) => void;
}

const BatchInfoModal: React.StatelessComponent<BatchInfoModalProps> = ({ show, onClose, batchName, taxYear, returnType, onSave, onBatchNameChange }) => {
    const validate = () => {
        if (!batchName || !batchName.trim()) {
            VenusNotifier.Warning("Please enter batch name", null);
            return;
        }
        onSave();
    }

    const taxYearList = getOrganizersTaxYearList();

    return (
        <Modal show={show} onHide={onClose} className="edit-batch-info-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='modalIcon text-secondary fa fa-address-card'>
                    </span>Edit Batch Info
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col className="text-left padT07" sm={3} as={FormLabel}>
                        Batch Name
                        <span className="glyphicon glyphicon-asterisk compulsory-star1" />
                    </Col>
                    <Col sm={9}>
                        <FormControl
                            type="text"
                            value={batchName}
                            placeholder="Batch Name"
                            style={{ marginBottom: '10px' }}
                            onChange={onBatchNameChange}
                        />
                    </Col>
                </Row>


                <Row>
                    <Col className="text-left padT07" sm={3} as={FormLabel}>
                        Tax Return Type
                    </Col>
                    <Col sm={9}>
                        <Select
                            name="engagementType"
                            value={returnType}
                            disabled={true}
                            options={DisplayEngagementListLabelValue}
                            style={{ marginBottom: '10px', fontSize: '14px' }}
                            clearable={false}
                        >
                        </Select>
                    </Col>
                </Row>

                <Row>
                    <Col className="text-left padT07" sm={3} as={FormLabel}>
                        Tax Year
                    </Col>
                    <Col sm={9}>
                        <Select
                            name="taxYear"
                            disabled={true}
                            value={taxYear}
                            options={taxYearList}
                            style={{ marginBottom: '10px', fontSize: '14px' }}
                            clearable={false}
                        >
                        </Select>
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer style={{ display: 'initial' }} >
                <span className="pull-left padT07" style={{ fontSize: '14px' }}>
                    <span className="glyphicon glyphicon-asterisk compulsory-star-only padR05"></span>
                    <span>Required</span>
                </span>
                <Button
                    style={{ float: 'right' }}
                    variant="default"
                    className="btn-white"
                    onClick={onClose} >
                    <i className="glyphicon glyphicon-remove" />Cancel
                </Button>
                <Button
                    style={{ float: 'right' }}
                    variant='info'
                    onClick={validate} >
                    <i className='glyphicon glyphicon-floppy-disk'></i>Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export { BatchInfoModal };