import * as React from "react";
import { Modal, Button } from "react-bootstrap";
import { ISectionItems, ISections } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { TemplateModalConstants } from "src/components/helper/Constants";
import { ReactSortable } from "react-sortablejs";
import { GrabIcon } from "src/assets/SvgIconCollection";
interface SectionMoveModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: (source: ISections[]) => void;
    sections: ISections[];
}

const SectionMoveModal: React.FC<SectionMoveModalProps> = ({ show, onClose, onConfirm, sections }) => {
    const [sectionsList, setSectionsList] = React.useState<ISections[]>([]);
    const handleOnSubmit = () => {
        onConfirm(sectionsList);
    };
    const hideModalHandler = () => {
        onClose();
    };
    const addIdForEverySection = () => {
        if (sections) {
            let newSections = sections?.map((section) => {
                return { ...section, id: section.sectionId };
            })
            setSectionsList(newSections);
        }
    }
    React.useEffect(() => {
        addIdForEverySection();
    }, [sections]);
    return (
        <Modal className="template-modal-container" show={show} onHide={hideModalHandler} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {TemplateModalConstants.ReorderSections}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bootbox-body no-horizontal-padding">
                <ReactSortable
                    filter=".addImageButtonContainer"
                    dragClass="sortableDrag"
                    list={sectionsList}
                    setList={setSectionsList}
                    animation={200}
                    easing="ease-out"
                >
                    {sectionsList?.map((item, index) => (
                        <div className="draggableItem" key={item.id}>
                            <GrabIcon />
                            <div className="section-details">
                                <label className="section-details-sectionName ellipsis" title={item.sectionName}>{item.sectionName}</label>
                                <label className="section-details-sectionCount">{`Section ${index + 1} of ${sectionsList.length}`}</label>
                            </div>
                        </div>
                    ))}
                </ReactSortable>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" className="btn-white" onClick={hideModalHandler}>
                    <i className="glyphicon glyphicon-remove" />
                    Cancel
                </Button>
                <Button className="btn-info" onClick={handleOnSubmit} variant="info">
                    <i className="glyphicon glyphicon-ok" />
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default SectionMoveModal;