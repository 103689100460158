import { addTask } from 'domain-task';
import { AppThunkAction } from './../../store';
import { handleBlobwithFileName } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    apiPrefix
} from './../models/OrganizerStoreModels';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { DisplayDownloadFile } from '../../components/common/DisplayDownloadFile';
import { IReportProblemDetails } from './../../ReportProblem/ReportProblemModel';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    NotificationAction;

export const actionCreators = {
    downloadCSVTemplate: (callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}Document/GetCSVTemplate/`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleBlobwithFileName)
            .then((data) => {
                let displayDownloadFile = new DisplayDownloadFile();
                displayDownloadFile.showFile(data.blob, `Batch_CSV_Template.csv`);
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.CSVTemplate,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        addTask(fetchTask);
    },

    reportProblemSave: (problemDetails: IReportProblemDetails, url: string, callback?: (data: string) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {        
        const fetchTask = fetch(url, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(problemDetails),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(function (response) {
                return response.json();
            }).then(function (data) {
                if (callback) { callback(data); }
            })
            .catch(error => {
                logger?.trackError(`reportProblemSave failed for the request having parameters ${JSON.stringify(problemDetails)} with error ${error.message}`)});
        addTask(fetchTask);
    }
};