import * as React from 'react';
import { Modal, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Link } from 'react-router-dom';
import { ClientTypes, IAccessViewModel, IDocumentTransactionUser } from '../../../../components/common/TaxReturn'

let moment = require('moment');
import { OverlayLoader } from '../../../../components/helper/OverlayLoader';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { OrganizerEvent } from '../../../models/OrganizerComponentModels';

interface IViewAccessCodeProps {
    model: IAccessViewModel;
    onCancel(): void;
    show: boolean;
    onGenerateOTP(documentId: number, clientGUID: string, clientType: ClientTypes, callback: () => void): void;
    loading: boolean;
}

interface ColumnNames {
    header: string;
    key: string;
    isKey: boolean;
    dataFormat: any;
    width: string,
    toolTip: boolean,
}
interface IColumnValues {
    signersName: string,
    createdOn: Date,
    OTP: string,
    status: string,
    email: string,
    isDeceased: boolean,
    documentId: number,
    clientType: ClientTypes,
    clientGUID: string,
    date: Date,
}

const msg = {
    saving: 'Generating OTP...',
    loading: 'Loading, please wait...'
}

interface IViewAccessCodeState {
    message: string;
    isOtpGenerated: boolean;
    alert: string;
    hideAlert: boolean;
    alertMessage: string;
}

const successMsg = {
    generatedAccessCode: "Access code generated successfully"
}

export class ViewAccessCode extends React.Component<IViewAccessCodeProps, IViewAccessCodeState>{
    constructor(props: IViewAccessCodeProps) {
        super(props);
        this.state = {
            message: msg.loading,
            isOtpGenerated: false,
            alert: "success",
            hideAlert: true,
            alertMessage: "",
        }
        this.defaultType = this.defaultType.bind(this);
        this.generateOTPButton = this.generateOTPButton.bind(this);
        this.onGenerateOTP = this.onGenerateOTP.bind(this);
        this.getDifferenceInDate = this.getDifferenceInDate.bind(this);
        this.statusDataFormatType = this.statusDataFormatType.bind(this);
    }
    componentWillReceiveProps(props: IViewAccessCodeProps) {
        if (!props.show) {
            this.setState({ message: msg.loading });
        }
    }
    componentWillUnmount() {
        this.setState({ isOtpGenerated: false, hideAlert: true })
    }

    private defaultType(cell: any, row: IColumnValues) {
        return cell;
    }


    private generateOTPButton(cell: any, row: IColumnValues) {
        // style added for fixing the bug-92275
        let style: string = (row.email === null || row.email?.trim().length === 0 || row.isDeceased) ? 'generate_link_disabled' : 'generate_link_enabled';
        return <Link className={style} to={"#"} onClick={(event) => { this.onGenerateOTP(row) }}> {"Generate Access Code"}</Link >;
    }
    _toolTip = (cell: any, row: any) => {
        let accessAction;
        this.props.model.accessCodeDetails.map((value, index) => {
            accessAction = ((this.getDifferenceInDate(value.createdOn) > 20) ? "Generate Access Code" : "");
        });
        return `${accessAction}`;
    }
    private onGenerateOTP(row: IColumnValues) {
        this.setState({ message: msg.saving, isOtpGenerated: true, hideAlert: true },
            () => {
                this.props.onGenerateOTP(row.documentId, row.clientGUID, row.clientType, this.triggerAlert);
            }
        );
    }

    private getDifferenceInDate(createdDate: Date) {
        let date1: any = new Date();
        let date2: any = moment.utc(createdDate).toDate();
        let minute = (date1.getTime() - date2.getTime()) / 60000;
        return minute
    }
    private onCancel = () => {
        this.setState({ isOtpGenerated: false, hideAlert: true })
        this.props.onCancel()
    }
    private triggerAlert = () => {
        this.setState({ hideAlert: false, alertMessage: successMsg.generatedAccessCode, alert: "success" })
    }
    private statusDataFormatType = (cell: any, row: IColumnValues) => {

        if (row.OTP != "NA") {
            return this.getDifferenceInDate(row.createdOn) > 20 ? "Expired" : "Active";
        }
        else if (row.OTP == "NA" &&
            this.props.model.clientEvents.find(x => x.eventId == OrganizerEvent.OTPAuthenticated) != undefined &&
            this.props.model.clientEvents.some(x => x.eventId == OrganizerEvent.OTPAuthenticated &&
                x.actedBy.firstName == row.signersName)) {
            return "Authenticated"
        }
        else if (row.OTP == "NA" && (this.props.model.clientEvents.length == 0 ||
            this.props.model.clientEvents.find(x => x.eventId == OrganizerEvent.AccessCodeEmail) == undefined)
            || this.props.model.clientEvents.find(x => x.eventId == OrganizerEvent.AccessCodeEmail &&
                x.actedBy.firstName == row.signersName) == undefined) {
            return "Not Requested"
        }

    }
    private getAccessCodeDataType = (cell: any, row: IColumnValues) => {
        if (row.OTP != "NA") {
            return this.getDifferenceInDate(row.createdOn) > 20 ? "NA" : row.OTP;
        }
        else {
            return "NA";
        }
    }
    private createdOnDataFormat = (cell: any, row: IColumnValues) => {
        return (!row.createdOn || this.getDifferenceInDate(row.createdOn) > 20)
            ? "NA" : moment.utc(row.createdOn).local().format('MM/DD/YYYY hh:mm:ss A')
    }
    public render() {
        let viewAccessCodeData: any[] = [];
        const viewAccessCodeColumn = [
            {
                header: "Signer's Name",
                key: 'signersName',
                isKey: false,
                dataFormat: this.defaultType,
                toolTip: true,
                columnClassName: 'word-Visible',

            },
            {
                header: 'Date',
                key: 'createdOn',
                isKey: true,
                dataFormat: this.createdOnDataFormat,
                toolTip: true,
                columnClassName: '',
            },
            {
                header: 'Access Code',
                key: 'OTP',
                isKey: false,
                dataFormat: this.getAccessCodeDataType,
                toolTip: true,
                columnClassName: '',
            },
            {
                header: 'Status',
                key: 'status',
                isKey: false,
                dataFormat: this.statusDataFormatType,
                toolTip: true,
                columnClassName: '',
            },
            {
                header: 'Action',
                key: 'action',
                isKey: false,
                dataFormat: this.generateOTPButton.bind(this),
                toolTip: this._toolTip,
                columnClassName: '',
            }
        ];
        let minDate = new Date();
        minDate.setFullYear(1, 0, 1);
        minDate.setHours(0, 0, 0, 0);
        if (this.props.model.accessCodeDetails) {
            viewAccessCodeData = this.props.model.accessCodeDetails.map((value, index) => {
                return {
                    signersName: value.name,
                    createdOn: Date.parse(new Date(value.createdOn).toString()) == Date.parse(minDate.toString()) ? null : value.createdOn,
                    OTP: !value.otp ? "NA" : value.otp,
                    status: this.getDifferenceInDate(value.createdOn) > 20 ? "EXPIRED" : "ACTIVE", //"EXPIRED",
                    documentId: value.documentId,
                    email: value.email,
                    isDeceased: value.isDeceased,
                    clientType: value.clientType,
                    clientGUID: value.clientGuid,
                    date: value.createdOn,
                }
            })
        }
        let modalBody = (this.props.model.accessCodeDetails) ?
            (<BootstrapTable data={viewAccessCodeData}>
                {viewAccessCodeColumn.map((value, index) => {
                    return <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        columnClassName={value.columnClassName}
                        columnTitle={value.toolTip}>
                        {value.header}
                    </TableHeaderColumn>;
                })}
            </BootstrapTable>) : (<OverlayLoader
                show={this.props.show}
                text={this.state.message}
                width='94%' />);
        return <div>
            <Modal className="view-access-code-modal" show={this.state.isOtpGenerated ? true : this.props.show} onHide={this.onCancel}>
                <Modal.Header closeButton onClick={this.onCancel} data-test-auto="C98D7824-8C63-4A74-95E6-4D3C73B08677">
                    <Modal.Title><span className='text-secondary fas fa-clock' style={{ color: 'grey', marginRight: '5px' }}>
                    </span>Access Code</Modal.Title>
                </Modal.Header>
                <Form horizontal>
                    <Modal.Body>
                        <div>
                            <LoadingOverlay style={{ height: '100%' }}>
                                <div style={{ marginLeft: '5px', marginRight: '5px' }}>
                                    <Alert variant={this.state.alert} hidden={this.state.hideAlert} >
                                        <i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                                        </i>{this.state.alertMessage}
                                    </Alert>
                                </div>
                                {modalBody}
                                <Loader loading={this.props.loading} text={"Loading..."} />
                            </LoadingOverlay>
                        </div>
                    </Modal.Body>
                </Form>
                <Modal.Footer style={{ display: 'initial' }}>
                    <Row>
                        <Col className="access-code-note" sm={8} style={{ fontSize: '12px' }}>
                            Note: Generating a new code will not send a new email
                        </Col>
                        <Col sm={4}>
                            <Button
                                variant="default"
                                data-test-auto="DAE73B5C-9114-46EB-BDE7-0EC8BA0D4B0D"
                                onClick={this.onCancel}
                                style={{ float: 'right' }}>
                                <i className='glyphicon glyphicon-remove'></i>Cancel
                            </Button>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
        </div>
    }
}

export default ViewAccessCode;