import { IClientInformationProps } from "../clientManagement.types";

export const existingClientDataComparisonModalConstants = {
    headerMessage: "You have changed some information that differs from Client Management. Would you like us to update Client Management with this new data ?",
    title: "Review and Confirm Client Information",
    cancelButtonText: "No, Do Not Update",
    saveButtonText: "Yes, Update"
};

interface IExistingClientManagementModalProps extends IClientInformationProps {
    onUpdate: () => void;
}

export type { IExistingClientManagementModalProps }
