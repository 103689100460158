import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReopenOrganizerGreyIcon } from 'src/assets/SvgIconCollection';

interface CloseOrganizerModalProps {
    show: boolean,
    processId?: number,
    isClosed?: boolean,
    onCancel: () => void;
    onCloseOrganizerModalConfirmClick: (processId: number) => void;
}
const CloseOrganizerModal: React.StatelessComponent<CloseOrganizerModalProps> = ({ show, processId, isClosed, onCancel, onCloseOrganizerModalConfirmClick }) => {
    return <Modal show={show} className="reopen-organizer-modal" onHide={() => { onCancel() }}>
        <Modal.Header closeButton style={{ alignItems: 'center'}}>
            <Modal.Title>
                <ReopenOrganizerGreyIcon />
                {isClosed ? "Confirm Open Organizer" : "Confirm Close Organizer"}
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '14px' }}>
            <p>
                {isClosed ? "The organizer(s) will be open. The recipient(s) will be able to access the same organizer link sent before. Reminders will be turned back on."
                    : "The organizer(s) will be closed. The recipient will no longer be able to access the organizer via the access links. All reminders will be stopped for this organizer. You can reopen the organizer at anytime to resume access and reminders."}
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onCancel} >
                <i className="glyphicon glyphicon-remove" />
                Cancel
            </Button>
            <Button
                variant="info"
                className="btn-info"
                onClick={onCloseOrganizerModalConfirmClick} >
                <i className="glyphicon glyphicon-ok" />
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
}
export { CloseOrganizerModal };
