import * as React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { GetFormatedDateTime } from '../../../../components/helper/HelperFunctions';
import { OverlayLoader } from '../../../../components/helper/OverlayLoader';
import { OrganizerClient, OrganizerStatus, OrganizerStatusList} from '../../../models/OrganizerComponentModels';
import { formattedClientName } from '../../Helper/OrganizerHelperFunctions';

interface DeliveryStatusModalProps {
    show: boolean;
    onClose: () => void;
    model: OrganizerClient[];
}
interface DeliveryStatusModalColumnValues {
    name: string;
    email: string;
    status: string;
    deliveredOn: Date;
}

const DeliveryStatusModal: React.StatelessComponent<DeliveryStatusModalProps> = ({ model, onClose, show }) => {

    const defaultType = (cell: any, row: any) => {
        return cell;
    }

    const deliveredDateType = (cell: any, row: any) => {
        if (cell === "N/A")
            return "N/A";
        else
            return cell;
    }

    const deliveryStatusColumn = [
        {
            header: 'Name',
            key: 'name',
            isKey: false,
            dataFormat: defaultType,
            width: 'auto',
            toolTip: true,
        },
        {
            header: 'Email',
            key: 'email',
            isKey: false,
            dataFormat: defaultType,
            width: 'auto',
            toolTip: true,
        },
        {
            header: 'Status',
            key: 'status',
            isKey: true,
            dataFormat: defaultType,
            width: 'auto',
            toolTip: true,
        },
        {
            header: 'Delivered Date',
            key: 'deliveredOn',
            isKey: false,
            dataFormat: deliveredDateType,
            width: 'auto',
            toolTip: true,
        }
    ];

    const deliveryStatusData: DeliveryStatusModalColumnValues[] = [];

    if (model.length) {
        model.map((value) => {
            console.log("Dropped model Delivery Status Data: ", value)
            deliveryStatusData.push({
                name: formattedClientName(value.name),
                email: value.email,
                status: value.organizerStatus == 0 ? "N/A" : OrganizerStatusList[OrganizerStatus[value.organizerStatus]].toUpperCase(),
                deliveredOn: value.organizerStatus == 5 ? "N/A" : value.deliveredOn ? GetFormatedDateTime(value.deliveredOn) : "N/A"
            });
        });
    }

    const modalBody = model.length ?
        (
            <BootstrapTable data={deliveryStatusData}>
                {deliveryStatusColumn.map((value, index) => {
                    return <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        width={value.width}
                        columnTitle={value.toolTip}>
                        {value.header}
                    </TableHeaderColumn>;
                })}
            </BootstrapTable>) :
        (<OverlayLoader
            show={show}
            text={"Loading, please wait..."} />);

    return <Modal className="delivery-status-modal" show={show} onHide={onClose}>
        <Modal.Header closeButton onClick={onClose} data-test-auto="3E48309B-CE0F-40FC-9AAD-A3C673A7A5A0">
            <Modal.Title>
                <span
                    className='fas fa-user'
                    style={{ color: 'grey', marginRight: '5px' }}>
                </span>Delivery Status
                   </Modal.Title>
        </Modal.Header>
        <Form horizontal>
            <Modal.Body>
                {modalBody}
            </Modal.Body>
        </Form>
        <Modal.Footer>
            <Button
                variant="default"
                data-test-auto="2791EE4F-F1F6-424A-9471-222568102B29"
                onClick={onClose}>
                <i className='glyphicon glyphicon-remove'></i>Cancel
                </Button>
        </Modal.Footer>
    </Modal>

};

export { DeliveryStatusModal };