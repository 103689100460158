import { OrganizerClientType, ProcessStatus } from "../models/OrganizerComponentModels";

export class BaseDocumentViewModel {
    id: number;
    processInfoGuid: string;
    clientId: string;    
    processStatus: ProcessStatus;
    taxYear: number;

    constructor(id: number,
        processInfoGuid: string,
        clientId: string,
        processStatus: ProcessStatus,
        taxYear: number) {
        this.id = id;
        this.processInfoGuid = processInfoGuid;
        this.clientId = clientId;
        this.processStatus = processStatus;
        this.taxYear = taxYear;
    }

}