import { TaxSoftwareType } from '../Core/ViewModels/Company/CompanySettingsViewModel';
import { EngagementType, OrganizerClient } from './../Organizer/models/OrganizerComponentModels';
import { getOrganizersCurrentTaxYear } from '../Organizer/components/Helper/OrganizerHelperFunctions';

export enum ReportProblemTypes {
    Generic = 1,
    ReturnSpecific = 2
}

export enum ReportedStep {
    None = 0,
    InProcessOrganizer = 1,
    DeliveredOrganizer = 2
}

interface SelectOptions {
    value: any,
    label: string
}

export const InProcessStatusOptionsList: SelectOptions[] = [
    { label: 'UPLOADED', value: "Uploaded" },
    { label: 'READY', value: "Ready" },
    { label: 'PROCESSING', value: "Processing" }
];

export const SignatureStatusOptionsList: SelectOptions[] = [
    { label: 'AWAITING SIGNATURE', value: "AwaitingSignature" },
    { label: 'PARTIALLY SIGNED', value: "PartiallySigned" },
    { label: 'PARTIALLY REVIEWED', value: "PartiallyReviewed" },
    { label: 'E-SIGNED', value: "ESigned" },
    { label: 'DOWNLOADED', value: "EngagementLetterDownloaded" },
    { label: 'REVIEWED', value: "Reviewed" },
    { label: 'MANUALLY SIGNED', value: "ManuallySigned" },
    { label: 'N/A', value: "NA" }
];

export const OrganizerStatusOptionsList: SelectOptions[] = [
    { label: 'DELIVERED', value: "Delivered" },
    { label: 'PARTIALLY COMPLETED', value: "PartiallyCompleted" },
    { label: 'COMPLETED', value: "Completed" },
    { label: 'DOWNLOADED', value: "OrganizerDownloaded" },
    { label: "DROPPED", value: "Dropped" },
    { label: 'MANUALLY COMPLETED', value: "ManuallyCompleted" },
    { label: 'CLOSED BY FIRM', value: "ClosedByFirm" },
    { label: "QUESTIONNAIRE COMPLETED", value: 'QuestionnaireCompleted' }
];

export const SourceDocumentStatusOptionsList: SelectOptions[] = [
    { label: 'AWAITING UPLOAD', value: "AwaitingUpload" },
    { label: 'UPLOADED', value: "Uploaded" },
    { label: 'DOWNLOADED', value: "Downloaded" },
    { label: 'N/A', value: "NA" }
];

export enum TaxreturnType {
    None = 0,
    IndividualTaxReturn = 1,
    MarriedJointTaxReturn = 2,
    CorporateTaxReturn = 3,
}

export interface IReportProblemDetails {
    productType: string;
    taxSoftware: string;
    cpaFirm: string;
    cpaName: string;
    cpaPhoneNumber: string;
    cpaEmail: string;
    returnType: string;
    taxYear: number;
    clientId: string;
    processStatus: string;
    signatureStatus: string;
    organizerStatus: string;
    sourceDocumentStatus: string;
    taxpayerName: string;
    taxpayerEmail: string;
    spouseName: string;
    spouseEmail: string;
    description: string;
    documentId: number;
}

export const initialState: IReportProblemDetails = {
    productType: '',
    taxSoftware: '',
    cpaFirm: '',
    cpaName: '',
    cpaPhoneNumber: '',
    cpaEmail: '',
    returnType: '',
    taxYear: 0,
    clientId: '',
    processStatus: '',
    signatureStatus: '',
    organizerStatus: '',
    sourceDocumentStatus: '',
    taxpayerName: '',
    taxpayerEmail: '',
    spouseName: '',
    spouseEmail: '',
    description: '',
    documentId: 0,
}

export enum ReportProblemProperty {
    productType,
    clientId,
    returnType,
    taxYear,
    cpaEmail,
    cpaPhoneNumber,
    taxSoftware,
    status,
    signatureStatus,
    organizerStatus,
    sourceDocumentStatus,
    taxpayerName,
    taxpayerEmail,
    spouseName,
    spouseEmail,
    description,
}

export interface IReportProblemOrganizerData {
    productType: string,
    taxSoftware: TaxSoftwareType,
    returnType: string,
    taxYear: number,
    clientId: string,
    processStatus: string,
    signatureStatus: string,
    organizerStatus: string,
    sourceDocumentStatus: string,
    taxpayerName: string;
    clients: OrganizerClient[],
    documentId: number
}

export const initialReportProblemOrganizerData: IReportProblemOrganizerData = {
    productType: 'SafeSend Organizers',
    taxSoftware: TaxSoftwareType.ProSystems,
    returnType: EngagementType[EngagementType.E1040],
    taxYear: getOrganizersCurrentTaxYear(),
    clientId: '',
    processStatus: '',
    signatureStatus: '',
    organizerStatus: '',
    sourceDocumentStatus: '',
    taxpayerName: '',
    clients: [],
    documentId: 0
}