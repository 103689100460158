import { ITaxReturn } from "../common/TaxReturn";
import { IGroupSettingsModel, IGroupInfo, IGroupedReturnsModel } from '../../store/groupedreturns/GroupedReturnProcessState';
import { IInboxMessage, MessageCategory, MessageType } from '../../Core/ViewModels/User/InboxMessage';

//////////////////////////////////////////////// ENCODE STARTS ////////////////////////////////////////////////////////////////////
export function encodeTaxReturn(taxreturn: ITaxReturn) {
    
    taxreturn.formGroups.forEach(fg => fg.forms.forEach(form => {
        if (form?.bookmark)
            form.bookmark = encodeSpecialCharactersOnly(form.bookmark);
    }));

    if ((taxreturn.documentSettings?.deliverySettings?.paperFilePreparerMessage?.name != null) &&
          (taxreturn.documentSettings?.deliverySettings?.paperFilePreparerMessage?.name != undefined)) {
              taxreturn.documentSettings.deliverySettings.paperFilePreparerMessage.name = encodeSpecialCharactersOnly(taxreturn.documentSettings.deliverySettings.paperFilePreparerMessage.name);
          }
    
    if ((taxreturn.documentSettings?.deliverySettings?.paperFilePreparerMessage?.body != null) &&
          (taxreturn.documentSettings?.deliverySettings?.paperFilePreparerMessage?.body != undefined)) {
              taxreturn.documentSettings.deliverySettings.paperFilePreparerMessage.body = encodeSpecialCharactersOnly(taxreturn.documentSettings.deliverySettings.paperFilePreparerMessage.body);
          }

    if ((taxreturn.documentSettings?.deliverySettings?.preparerMessage?.name != null) &&
          (taxreturn.documentSettings?.deliverySettings?.preparerMessage?.name != undefined)) {
              taxreturn.documentSettings.deliverySettings.preparerMessage.name = encodeSpecialCharactersOnly(taxreturn.documentSettings.deliverySettings.preparerMessage.name);
          }

    if ((taxreturn.documentSettings?.deliverySettings?.preparerMessage?.body != null) &&
            (taxreturn.documentSettings?.deliverySettings?.preparerMessage?.body != undefined)) {
               taxreturn.documentSettings.deliverySettings.preparerMessage.body = encodeSpecialCharactersOnly(taxreturn.documentSettings.deliverySettings.preparerMessage.body);
           }
    
}

export function encodeGroupReturnData(groupInfo: IGroupInfo) {
    if ((groupInfo.groupSettings?.groupDeliverySettings?.paperFilePreparerMessage?.body != null) &&
          (groupInfo.groupSettings?.groupDeliverySettings?.paperFilePreparerMessage?.body != undefined)){
        groupInfo.groupSettings.groupDeliverySettings.paperFilePreparerMessage.body = encodeSpecialCharactersOnly(groupInfo.groupSettings.groupDeliverySettings.paperFilePreparerMessage.body);
    }

    if ((groupInfo.groupSettings?.groupDeliverySettings?.paperFilePreparerMessage?.name != null) &&
          (groupInfo.groupSettings?.groupDeliverySettings?.paperFilePreparerMessage?.name != undefined)){
        groupInfo.groupSettings.groupDeliverySettings.paperFilePreparerMessage.name = encodeSpecialCharactersOnly(groupInfo.groupSettings.groupDeliverySettings.paperFilePreparerMessage.name);
    }

    if ((groupInfo.groupSettings?.groupDeliverySettings?.preparerMessage?.name != null) &&
          (groupInfo.groupSettings?.groupDeliverySettings?.preparerMessage?.name != undefined)){
        groupInfo.groupSettings.groupDeliverySettings.preparerMessage.name = encodeSpecialCharactersOnly(groupInfo.groupSettings.groupDeliverySettings.preparerMessage.name);
    }

    if ((groupInfo.groupSettings?.groupDeliverySettings?.preparerMessage?.body != null) &&
          (groupInfo.groupSettings?.groupDeliverySettings?.preparerMessage?.body != undefined)){
        groupInfo.groupSettings.groupDeliverySettings.preparerMessage.body = encodeSpecialCharactersOnly(groupInfo.groupSettings.groupDeliverySettings.preparerMessage.body);
    }
}

export function encodeInboxMessage(inboxMessage: IInboxMessage)
{
    if ((inboxMessage.title != null) && (inboxMessage.title != undefined)){
        inboxMessage.title = encodeSpecialCharactersOnly(inboxMessage.title);
    }
    if ((inboxMessage.message != null) && (inboxMessage.message != undefined)){
        inboxMessage.message = encodeSpecialCharactersOnly(inboxMessage.message);
    }
}

export function encode(value: string): string
{
    let newlyEncodedString = "";
    for (let i = 0; i < value.length; i++) {
        if(value.charCodeAt(i)>31 && value.charCodeAt(i)<127){
            newlyEncodedString += "{STARTASCII}" + value.charCodeAt(i) + "{ENDASCII}";
        } else {
            newlyEncodedString += value[i];
        }
    }
    return newlyEncodedString;
}

export function encodeSpecialCharactersOnly(value: string): string
{
    let specialCharactersCodes=[33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 58, 59, 60, 61, 62, 63, 64, 91, 92, 93, 94, 95, 96, 123, 124, 125, 126];
    let newlyEncodedString = "";
    for (let i = 0; i < value.length; i++) {
        if(value.charCodeAt(i)>31 && value.charCodeAt(i)<127 && specialCharactersCodes.includes(value.charCodeAt(i))){
            newlyEncodedString += "{STARTASCII}" + value.charCodeAt(i) + "{ENDASCII}";
        } else {
            newlyEncodedString += value[i];
        }
    }
    return newlyEncodedString;
}
//////////////////////////////////////////////// ENCODE ENDS ////////////////////////////////////////////////////////////////////


