//import
import AccessDenied from '../images/AccessDenied.svg';
import Support from '../images/support.svg';
import LastLogin from '../images/last-login.svg';
import SessionTimeout from '../images/SessionTimeout.svg';
import VoucherPayment from '../images/voucher-payment.svg';
import VoucherEstimatedPayment from '../images/voucher-estimated-payment.svg';
import SourceDocument from '../images/source document - small@1x.svg';
import SignatureSmall from '../images/Signature-small-1x.svg';

import AddSectionSmall from '../images/CustomQuestion/AddSectionSmall.svg';
import QuestionSmall from '../images/CustomQuestion/QuestionSmall.svg';
import PreviewSmall from '../images/CustomQuestion/PreviewSmall.svg';
import SaveSmall from '../images/CustomQuestion/SaveSmall.png';

import Add from '../images/CustomQuestion/add-new.svg';
import { ReactComponent as Template } from '../images/CustomQuestion/notes.svg';
import { ReactComponent as MoreOptions } from '../images/CustomQuestion/more-options.svg';
import { ReactComponent as Star } from '../images/CustomQuestion/star.svg';
import { ReactComponent as StarFilled } from '../images/CustomQuestion/star-filled.svg';
import Close from '../images/close.png';
//export
export const AccessDeniedIcon = AccessDenied;
export const SupportIcon = Support;
export const LastLoginIcon = LastLogin;
export const SessionTimeoutIcon = SessionTimeout;
export const VoucherPaymentIcon = VoucherPayment;
export const VoucherEstimatedPaymentIcon = VoucherEstimatedPayment;
export const SourceDocumentIcon = SourceDocument;
export const SignatureSmallIcon = SignatureSmall;
export const AddSectionSmallIcon = AddSectionSmall;
export const QuestionSmallIcon = QuestionSmall;
export const PreviewSmallIcon = PreviewSmall;
export const SaveSmallIcon = SaveSmall;

export const TemplateIcon = Template;
export const AddIcon = Add;
export const MoreOptionsIcon = MoreOptions;
export const StarIcon = Star;
export const StarFilledIcon = StarFilled;
export const CloseIcon = Close;



