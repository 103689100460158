import * as React from "react";
import { Col, Row, FormLabel } from 'react-bootstrap';
import Select from "react-select";
import ToggleSwitch from '../../../../../../../components/common/ToggleSwitch';
import { BookmarkPage, Bookmarks, FormGroup, GroupType } from '../../../../../../models/OrganizerComponentModels';
import { getDaysOptions } from '../../../../../../../components/common/utils';

interface DeliveryRemindersPanelProps {
    reminderSettings: any,
    updateReminders: (key1: string, key2: string, value: number) => void,
    formGroups: any,
    additionalFileExists: boolean,
    readOnlyView: boolean
}

const getBookmarks = (formGroups: FormGroup[], type: GroupType, heading: string): Bookmarks => {
    const index = formGroups?.findIndex(formGroup => formGroup.type === type);
    if (index === undefined || index === -1) {
        return {
            heading: "",
            pages: []
        }
    }
    const bookmarkPages = formGroups[index].forms?.map(form => {
        return {
            pageNo: form.pageNo,
            pageTitle: form.bookmark
        } as BookmarkPage
    })
    return {
        heading: heading,
        pages: bookmarkPages
    }
}

const getPages = (bookmarks: Bookmarks): number[] => {
    return bookmarks?.pages?.map(page => page.pageNo);
}

const DeliveryRemindersPanel: React.FC<DeliveryRemindersPanelProps> = ({ reminderSettings, updateReminders, formGroups, additionalFileExists, readOnlyView }) => {
    const daysOpions = getDaysOptions();
    const isOrganizerEnabled = reminderSettings?.organizerReminder?.enabled;
    const engagementLetterBookmarks = getBookmarks(formGroups, GroupType.Engagement, "Bookmarks");
    const engagementLetterPages = getPages(engagementLetterBookmarks);
    const engagementExists = engagementLetterPages.length > 0;

    const eSignOrganizerBookmarks = getBookmarks(formGroups, GroupType.OrganizerWithSignature, "Bookmarks");
    const eSignOrganizerPages = getPages(eSignOrganizerBookmarks);
    const eSignOrganizerExists = eSignOrganizerPages.length > 0;

    const isAddedCheck = !engagementExists && !additionalFileExists && !eSignOrganizerExists;
    const isEsignEnabled = isAddedCheck ? false : reminderSettings?.esignReminder?.enabled;
    const toolText = isAddedCheck ? "No signature documents associated with the organizer" : undefined;

    return (
        <>
            <Row className="marB15">
                <Col sm={12} className="new-main-content">
                    <h4><i className="modalIcon fas fa-bell" /> Reminders </h4>
                    <hr style={{ marginTop: "-2px" }} />
                </Col>
            </Row>
            <div className="sign-orgs-reminder-panel">
                <div>
                    <Row style={{ fontWeight: 700 }}>
                        Signing
                    </Row>
                    <Row className="sign-orgs-reminder-panel-row">
                        <div>
                            Send a reminder every
                        </div>
                        <Select style={{ width: '96px' }}
                            id="esignRemindDays"
                            options={daysOpions}
                            value={reminderSettings?.esignReminder?.reminderDays}
                            onChange={(e: any) => updateReminders("esignReminder", "reminderDays", e.value)}
                            clearable={false}
                            searchable={false}
                            disabled={readOnlyView || !isEsignEnabled}
                        />
                        <div>
                            since last reminder / initial mail
                        </div>
                        <Col xs lg="2" style={{ alignSelf: 'flex-start' }}>
                            <label className="toggleSwitch" title={toolText}>
                                <input type="checkbox"
                                    checked={isEsignEnabled}
                                    disabled={readOnlyView || isAddedCheck}
                                    onChange={(e: any) => updateReminders("esignReminder", "enabled", e.target.checked)}

                                ></input>
                                <span className="columnFilterSlider round"></span>
                            </label>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row style={{ fontWeight: 700 }}>
                        Organizers
                    </Row>
                    <Row className="sign-orgs-reminder-panel-row">
                        <div>
                            Send a reminder every
                        </div>
                        <Select style={{ width: '96px' }}
                            id="orgRemindDays"
                            options={daysOpions}
                            value={reminderSettings?.organizerReminder?.reminderDays}
                            onChange={(e: any) => updateReminders("organizerReminder", "reminderDays", e.value)}
                            clearable={false}
                            searchable={false}
                            disabled={readOnlyView || !isOrganizerEnabled}
                        />
                        <div>
                            since last reminder / initial mail
                        </div>
                        <Col xs lg="2" style={{ alignSelf: 'flex-start' }}>
                            <label className="toggleSwitch ">
                                <input type="checkbox"
                                    checked={isOrganizerEnabled}
                                    disabled={readOnlyView}
                                    onChange={(e: any) => updateReminders("organizerReminder", "enabled", e.target.checked)}
                                ></input>
                                <span className="columnFilterSlider round"></span>
                            </label>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};
export default DeliveryRemindersPanel;
