import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { RecycledOrganizerReportToolbar } from './RecycledReportToolbar';
import { ActionCreators as OrganizerCompanySettingsStore } from './../../../store/OrganizerCompanySettingsStore';
import { actionCreators as RecycledOrganizerStore } from './../../../store/RecycledOrganizerStore';
import { OrganizerSettingsStoreState, RecycledOrganizerStoreState } from '../../../models/OrganizerStoreModels';
import { OrganizerRecycledReportTable } from './OrganizerRecycledReportTable';
import { GetNumberOfDays } from './../../../../components/helper/HelperFunctions';
import { VenusNotifier } from '../../../../components/helper/VenusNotifier';
import { RecyleReturnsConstants } from '../../../../components/helper/Constants';
import * as bootbox from 'bootbox';
import { HideLoader, ShowLoader } from '../../../../components/helper/Loader';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { actionCreators as UserStore, ILocationData } from '../../../../store/UserManagement/UserStore';
import { ILocations } from 'src/Core/ViewModels/Company/CompanyViewModel';
import { actionCreators as CompanyStore, ICompanyData } from './../../../../store/company/CompanyStore';


type RecycledOrganizerProps = {
    recycledOrganizerReports: RecycledOrganizerStoreState;
    companySettingsStore: OrganizerSettingsStoreState;
    companyData: ICompanyData;
    userLocations: ILocationData;
}
    & typeof RecycledOrganizerStore
    & typeof OrganizerCompanySettingsStore
    & typeof CompanyStore
    & typeof UserStore
    & RouteComponentProps<{}>

interface RecycledOrganizerState {
    selectedRows: number[],
    page: number;
    isDeleteAllIdFromCurrentPage: boolean;
    filterTaxpayerName: string,
    filterClientId: string,
    filterTaxYear: any,
    filterEro: string,
    filterLocation: string;
    filterBatchName: string,
    filterSentBy: string,
    filterDeliveredOn?: Date,
    filterEngagementLetterStatus: any,
    filterOrganizerStatus: any,
    filterSourceDocumentStatus: any,
    recycledBy: string,
    recycledDate?: Date,
    sortName: string,
    sortOrder: string
}

const PAGESIZE = 20;
export class RecycledOrganizerReport extends React.Component<RecycledOrganizerProps, RecycledOrganizerState> {
    constructor(props: RecycledOrganizerProps) {
        super(props);

        this.state = {
            selectedRows: [],
            page: 1,
            isDeleteAllIdFromCurrentPage: false,
            filterTaxpayerName: '',
            filterClientId: '',
            filterTaxYear: '',
            filterEro: '',
            filterLocation: '',
            filterBatchName: '',
            filterSentBy: '',
            filterDeliveredOn: undefined,
            filterEngagementLetterStatus: '',
            filterOrganizerStatus: '',
            filterSourceDocumentStatus: '',
            recycledBy: '',
            recycledDate: undefined,
            sortName: 'recycledOn',
            sortOrder: 'desc'
        }
    }
    
    componentDidMount() {
        const page = this.state.page;
        this.props.requestRecycledOrganizers(this.buildQuery(page));
        this.props.requestOrganizerCompanySettings();
        this.props.requestUserOfficeLocations();
    }

    componentWillReceiveProps(nextProps: RecycledOrganizerProps) {
        if (nextProps.recycledOrganizerReports.model.count > 0) {
            this.setState({ selectedRows: [] });
        }
    }

    buildQuery = (page: number = 1) => {
        const {
            filterTaxpayerName,
            filterClientId,
            filterTaxYear,
            filterEro,
            filterLocation,
            filterBatchName,
            filterSentBy,
            filterDeliveredOn,
            filterEngagementLetterStatus,
            filterOrganizerStatus,
            filterSourceDocumentStatus,
            recycledBy,
            recycledDate,
            sortName,
            sortOrder
        } = this.state;

        return `?pageNo=${page}&pageSize=${PAGESIZE}&sortBy=${sortName}&sortOrder=${sortOrder}&filterClientId=${filterClientId}` +
            `${filterDeliveredOn ? `&filterDeliveredOn=${filterDeliveredOn}` : ''}` +
            `&filterTaxpayerName=${filterTaxpayerName}&filterTaxYear=${filterTaxYear}&filterSentBy=${filterSentBy}&filterEro=${filterEro}&filterEngagementLetterStatus=${filterEngagementLetterStatus}` +
            `&filterOrganizerStatus=${filterOrganizerStatus}&filterSourceDocumentStatus=${filterSourceDocumentStatus}&filterBatchName=${filterBatchName}&recycledBy=${recycledBy}` +
            `${recycledDate ? `&recycledDate=${recycledDate}` : ''}&filterLocationId=${filterLocation}`;
    }

    private getSelectedDocumentIds() {

        if (this.state.selectedRows.length == 0) {
            return [];
        }
        let ids: number[] = [];
        for (var i in this.state.selectedRows) {
            let row = this.state.selectedRows[i];
            let selectedDocument = this.props.recycledOrganizerReports.model.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.id);
            }
        }
        return ids;
    }

    onDeleteModalOpen = () => {
        let ids = this.getSelectedDocumentIds();
        if (ids.length == 0) {
            VenusNotifier.Warning(RecyleReturnsConstants.SelectOrganizerWarning, null);
            return;
        }
        const { recycledOrganizerReports: {
            model: { count: totalOrganizers,
                documents: { length: currentPageOrganizers }
            } }
        } = this.props;
        const { page } = this.state;
        const totalPages = Math.ceil(totalOrganizers / PAGESIZE)
        if (page === totalPages &&
            totalPages !== 1 &&
            ids.length === currentPageOrganizers) {
            this.setState({ isDeleteAllIdFromCurrentPage: true });
        }
        bootbox.confirm({
            title: RecyleReturnsConstants.Title.RecyleReturns,
            message: RecyleReturnsConstants.ConfirmMessages.Delete,
            buttons: {
                cancel: {
                    label: '<i data-test-auto="ce75f59f-99fc-48b9-b058-068367b76996" class="fas fa-times"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i data-test-auto="1b2945ce-1cbd-4ffe-9520-6d89cd179822" class="fas fa-check"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader();
                    this.props.deleteRecycledOrganizers(ids, this.onDeleteCompletion );
                }
            }
        });

    };

    onDeleteCompletion = () => {
        if (this.state.isDeleteAllIdFromCurrentPage) {
            this.setState({ page: this.state.page - 1, isDeleteAllIdFromCurrentPage: false });
        }
        HideLoader();
        this.onRefresh();
    }

    onRefresh = () => {
        this.setState({ selectedRows: [] });
        this.props.requestRecycledOrganizers(this.buildQuery(this.state.page));
    }

    onRestore = () => {
        let ids = this.getSelectedDocumentIds();
        if (ids.length == 0) {
            VenusNotifier.Warning(RecyleReturnsConstants.SelectOrganizerWarning, null);
            return;
        }

        bootbox.confirm({
            title: RecyleReturnsConstants.Title.Restore,
            message: RecyleReturnsConstants.ConfirmMessages.Restore,
            buttons: {
                cancel: {
                    label: '<i class="fas fa-times" data-test-auto="15a329b5-45d8-4daf-9ce9-85e473f6db3b"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="fas fa-check" data-test-auto="b366a890-76db-4667-bb5a-a43b447ae47c"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    this.props.restoreRecycledOrganizer(ids, () => { this.onRefresh(); });
                }
            }
        });
    }

    onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            selectedRows: [],
        }, this.onRefresh);
    }

    onRowSelect = (row: any, isSelected: any, e: any) => {
        let newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                var index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            let count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({
            selectedRows: selectedRows,
        });
    }

    onSortChange = (sortName: string, sortOrder: string, column: number) => {
        this.setState({
            sortName: sortName,
            sortOrder: sortOrder == "asc" ? "asc" : "desc",
            selectedRows: [],
        }, () => this.fetchDocuments(1));
    }

    fetchDocuments = (page: number) => {
        let queryString = this.buildQuery(page);
        this.setState({ page: page }, () => { this.props.requestRecycledOrganizers(queryString) });
    }

    selectedDocumentIds = () => {
        const ids: number[] = [];
        const { selectedRows } = this.state;
        const { recycledOrganizerReports: { model: { documents } } } = this.props;
        selectedRows.map((row, i) => {
            let selectedDocument = documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.id);
            }
        });
        return ids;
    }

    onFilterChange = (dataField: any) => {
        const newState = {
            filterTaxpayerName: '',
            filterClientId: '',
            filterTaxYear: '',
            filterEro: '',
            filterLocation:'',
            filterBatchName: '',
            filterSentBy: '',
            filterDeliveredOn: undefined,
            filterEngagementLetterStatus: '',
            filterOrganizerStatus: '',
            filterSourceDocumentStatus: '',
            recycledBy: '',
            recycledDate: undefined
        } as RecycledOrganizerState;

        let isClearFilter = true;
        let filterSourceStatus: any;
        let filterEngagementStatus: any;
        let filterOrganizerStatus: any;
        let filterTaxYear: any;
        let filterLocationState: any;
        let updated = false;
        const dict: { [columnName: string]: string } = {};

        for (const field of Object.keys(dataField)) {
            const data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            if (data !== "-1") {
                dict[field.valueOf().toString()] = data;
            }
            const value = dataField[field].value ? dataField[field].value : dataField[field];
            switch (field) {

                case 'taxpayerName':
                    newState.filterTaxpayerName = value;
                    break;
                case 'clientId':
                    newState.filterClientId = value;
                    break;
                case 'ero':
                    newState.filterEro = value;
                    break;
                case 'locationName':
                    filterLocationState = value;
                    filterLocationState = (filterLocationState === "-1") ? "" : filterLocationState;
                    this.setState({ filterLocation: filterLocationState });
                    newState.filterLocation = filterLocationState;
                    updated = true;
                    break;
                case 'batchName':
                    newState.filterBatchName = value;
                    break;
                case 'sentBy':
                    newState.filterSentBy = value;
                    break;
                case 'deliveredOn':
                    newState.filterDeliveredOn = GetNumberOfDays(value);
                    break;

                case 'engagementLetterStatus':
                    filterEngagementStatus = value;
                    filterEngagementStatus = (filterEngagementStatus === "-1") ? "" : filterEngagementStatus;
                    this.setState({ filterEngagementLetterStatus: filterEngagementStatus });
                    newState.filterEngagementLetterStatus = filterEngagementStatus;
                    updated = true;
                    break;

                case 'organizerStatus':
                    filterOrganizerStatus = value;
                    filterOrganizerStatus = (filterOrganizerStatus === "-1") ? "" : filterOrganizerStatus;
                    this.setState({ filterOrganizerStatus: filterOrganizerStatus });
                    newState.filterOrganizerStatus = filterOrganizerStatus;
                    updated = true;
                    break;

                case 'sourceDocumentStatus':
                    filterSourceStatus = value;
                    filterSourceStatus = (filterSourceStatus === "-1") ? "" : filterSourceStatus;
                    this.setState({ filterSourceDocumentStatus: filterSourceStatus });
                    newState.filterSourceDocumentStatus = filterSourceStatus;
                    updated = true;
                    break;

                case 'taxYear':
                    filterTaxYear = value;
                    filterTaxYear = (filterTaxYear === "-1") ? "" : filterTaxYear;
                    this.setState({ filterTaxYear: filterTaxYear });
                    newState.filterTaxYear = filterTaxYear;
                    updated = true;
                    break;

                case 'recycledUser':
                    newState.recycledBy = value;
                    break;
                case 'recycledOn':
                    newState.recycledDate = GetNumberOfDays(value);
                    break;
            }
        }
        if (this.isFilterChanged(newState) || updated) {
            this.setState({ ...newState, page: 1, selectedRows: [] },
                () => {
                    this.props.requestRecycledOrganizers(this.buildQuery(this.state.page));
                })
        }
    }

    private isFilterChanged(newState: RecycledOrganizerState): boolean {
        return (
            newState.filterSentBy !== this.state.filterSentBy ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterDeliveredOn !== this.state.filterDeliveredOn ||
            newState.filterEro !== this.state.filterEro ||
            newState.filterTaxpayerName !== this.state.filterTaxpayerName ||
            newState.filterBatchName !== this.state.filterBatchName ||
            newState.recycledDate !== this.state.recycledDate ||
            newState.recycledBy !== this.state.recycledBy ||
            newState.filterLocation != this.state.filterLocation
        )
    }

    //Used for office location dropdown on grid
    getUserLocationData = () => {
        var companyLocations = this.props.companyData.companyProfile.locations;
        var userLocations = this.props.userLocations?.locations;
        if (companyLocations && userLocations) {
            const commonUserLocations = companyLocations.reduce(
                (result, item) =>
                    userLocations.some(el => el === item.locationId)
                        ? [...result, item]
                        : result,
                []
            );
            let locationList: IDropdown[] = [];
            if (commonUserLocations) {
                commonUserLocations?.forEach((location: ILocations) => {
                    const locationVal: IDropdown = {
                        label: location.name,
                        value: location.locationId
                    }
                    locationList.push(locationVal);
                })
            }
            
            locationList.push({
                label: 'Blanks',
                value: -2
            });
            return locationList;
        }
    }

    render() {

        const { companySettingsStore, recycledOrganizerReports: { loading, model, popupLoading } } = this.props
        const { selectedRows: { length }, page, selectedRows } = this.state;

        return (<div className='organizer-delivered-content'>
            <RecycledOrganizerReportToolbar
                onDelete={this.onDeleteModalOpen}
                onRefresh={this.onRefresh}
                selectedDocumentCount={length}
                onRestore={this.onRestore}
            />

            <br />
            <OrganizerRecycledReportTable
                allowGatheringSourceDocuments={companySettingsStore ? companySettingsStore.companySettings.sourceDocumentSettings ? companySettingsStore.companySettings.sourceDocumentSettings.enabledSourceDocuments : false : false}
                ref={'organizerReportTable'}
                documents={model.documents}
                isLoading={loading}
                onFilterChange={this.onFilterChange}
                onPageChange={this.onPageChange}
                onRowSelect={this.onRowSelect}
                onSelectAll={this.onSelectAll}
                onSortChange={this.onSortChange}
                pageNo={page}
                pageSize={PAGESIZE}
                selectedRows={selectedRows}
                totalRows={model.count}
                loadDocuments={this.onRefresh}
                userLocationList={this.getUserLocationData()} 
            />

        </div>);
    }
}
