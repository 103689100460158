import * as React from 'react';
import { Button } from 'react-bootstrap';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";

interface RecycledReportToolbarProps {
    selectedDocumentCount: number;
    onDelete: (index: number) => void;
    onRefresh: () => void;
    onRestore: () => void;
}
var btnStyle = {
    minWidth: '104px',
    marginRight: '5px'
};
const RecycledOrganizerReportToolbar: React.FC<RecycledReportToolbarProps> = ({
    selectedDocumentCount, onDelete, onRefresh, onRestore
}) => {
    return (
            <div>
                <div className="row marB10">
                    <div className="col-md-8" style={{ marginTop: '5px' }}>
                        <h3>Recycle Bin</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Button
                            variant='default'
                            className='btn btn-white'
                            style={btnStyle}
                            data-test-auto="b5099b7e-818f-4be8-bf42-e4106682b234"
                            disabled={selectedDocumentCount === 0}
                            data-resource-id={ResourceIdConstants.DeleteRecycleBinToolBar}
                            onClick={onDelete}
                        >
                            <i className='fas fa-trash-alt'></i>Delete
                        </Button>

                        <Button
                            variant='default'
                            className='btn btn-white'
                            style={btnStyle}
                            data-test-auto="60410359-3867-4752-92b4-601ced1214fa"
                            disabled={selectedDocumentCount === 0}
                            data-resource-id={ResourceIdConstants.RestoreRecycleBinToolbar}
                            onClick={onRestore}
                        >
                            <i className='fas fa-undo'></i>Restore
                        </Button>
                        <button
                            title="Refresh"
                            data-test-auto="11BC25B0-F325-4170-AFA9-598384364972"
                            type="button"
                            className="btn-white btn-only marR10 btn btn-default btn recycle-report-refresh-btn"
                            onClick={onRefresh}
                        >
                            <span
                                className="fas fa-redo-alt fa-btn-icon"
                                style={{ padding: "4px 0px", textShadow: "rgba(255, 255, 255, 0.5) 0px 1px 0px", fontSize: "12px" }} >
                            </span>
                        </button>
                    </div>
                </div>
            </div>
    );
};
export { RecycledOrganizerReportToolbar };