import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import {  IUserLoginHistory } from '../../components/navigation/profile/ProfileObjects';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import { API_BASE_URL } from 'src/utils/constants';

 export interface IUserLoginHistoryState {
    loginHistoryCount: number;
    loginHistories: IUserLoginHistory[];
    isLoginHistoryLoading: boolean;
     loginHistoryPageNo: number;
     deviceId: string;
}

interface ReceiveUserLoginHistoryAction {
    type: actionTypes.RECEIVE_USER_LOGIN_HISTORY;
    userLoginHistory: IUserLoginHistory[];
    loginHistoryCount: number;
    loginHistoryPageNo: number;
    deviceId: string;
}
interface RequestUserLoginHistoryAction {
    type: actionTypes.REQUEST_USER_LOGIN_HISTORY;
}
type KnownAction = RequestUserLoginHistoryAction | ReceiveUserLoginHistoryAction |NotificationAction;
type DispatchActions = RequestUserLoginHistoryAction | ReceiveUserLoginHistoryAction;

export const actionCreators = {

    requestUserLoginHistory: (pageNo: number = 1,pageSize:number = 15): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(`${API_BASE_URL}/api/LoginHistory/${pageNo}/${pageSize}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then((response: any) => {
                var data = handleResponse(response);
                dispatch({ type: actionTypes.REQUEST_USER_LOGIN_HISTORY })
                return data;
            })
            .then(response => response as Promise<{ count: number, loginHistory: IUserLoginHistory[], deviceId: string }>)
            .then(data => {
                //dispatch
                dispatch({ type: actionTypes.RECEIVE_USER_LOGIN_HISTORY, userLoginHistory: data.loginHistory, loginHistoryCount: data.count, loginHistoryPageNo: pageNo, deviceId:data.deviceId });
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error, statusType: StatusType.Error,statusCode: error?.status });
            });
        addTask(fetchTask);
    }
}

const initialLoginHistoryState: IUserLoginHistoryState=
{
    loginHistoryCount: 0,
    loginHistories: [] as IUserLoginHistory[],
    isLoginHistoryLoading: true,
    loginHistoryPageNo: 0,
    deviceId: ""
}

export const reducer: Reducer<IUserLoginHistoryState> = (state: IUserLoginHistoryState = initialLoginHistoryState, incomingAction: Action) => {
    const action = incomingAction as DispatchActions;
    switch (action.type) {
        case actionTypes.RECEIVE_USER_LOGIN_HISTORY:
            let loginHistory = { ...state };
            loginHistory.loginHistories = [...action.userLoginHistory];
            loginHistory.loginHistoryCount = action.loginHistoryCount;
            loginHistory.loginHistoryPageNo = action.loginHistoryPageNo;
            loginHistory.isLoginHistoryLoading = false;
            loginHistory.deviceId = action.deviceId;
            return loginHistory;
        case actionTypes.REQUEST_USER_LOGIN_HISTORY:
            let initailLoginHistory = { ...state };
            initailLoginHistory.isLoginHistoryLoading = true;
            return initailLoginHistory;
        default:
            const exhaustiveCheck: any = action;
    }
    return state || initialLoginHistoryState;
};