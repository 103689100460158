import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes'
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    ReceiveOrganizerUploadLinkAction,
    unloadedOrganizerUploadState,
    OrganizerUploadState,
    apiPrefix
} from './../models/OrganizerStoreModels';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { LoggerFactory } from '../../Logger/LoggerFactory';
import { HideLoader } from '../../components/helper/Loader';
import { AdditionalEsignModel } from '../models/OrganizerComponentModels';
import { TaxSoftwareType } from '../../Core/ViewModels/Company/CompanySettingsViewModel';
const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    ReceiveOrganizerUploadLinkAction |
    NotificationAction;

export const actionCreators = {

    submitOrganizer: (url: string, data: string, callback: () => void):
        AppThunkAction<KnownActions> => (dispatch, getState) => {
            let fetchTask = fetch(url, {
                method: 'POST',
                credentials: 'include',
                body: data,
                headers: {
                    'Accept': 'application/json, text/plain,*/*',
                    'Content-Type': 'application/json',
                    'traditional': 'true',
                }
            }).then(handleResponse)
                .then(() => {
                    callback();
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.SubmitOrganizer,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    })
                    HideLoader();
                    logger?.trackError(`submitOrganizer failed for the request having parameters ${data} with error ${error.message}`)
                });
            addTask(fetchTask);
        },

    submitOrganizerWithSeperateEL: (url: string, OrganizerUploadData: string, callback: () => void):
        AppThunkAction<KnownActions> => (dispatch, getState) => {
            let fetchTask = fetch(url,
                {
                    method: 'POST',
                    credentials: 'include',
                    body: OrganizerUploadData,
                    headers: {
                        'Accept': 'application/json, text/plain,*/*',
                        'Content-Type': 'application/json',
                        'traditional': 'true',
                }
            }).then(handleResponse)
                .then(() => {
                    callback();
                })
                .catch(error => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.SubmitOrganizer,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    })
                    HideLoader()
                    logger?.trackError(`submitOrganizerWithSeperateEL failed for the request having parameters ${OrganizerUploadData} with error ${error.message}`)
                });
            addTask(fetchTask);
        },


    getUploadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_ORGANIZER_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerLinkError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
            });
        addTask(fetchTask);
    },

    getELUploadLink: (url: string, callback?: (data?: any) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(url, { credentials: 'include' }).then((resp) => resp.json())
            .then(function (data) {
                if (callback) {
                    data ? callback(data) : callback();
                }
                dispatch({ type: actionTypes.RECEIVE_ORGANIZER_LINK, sasUrl: data.sas, documentGuid: data.guid });
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerLinkError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
            });
        addTask(fetchTask);
    },

    deleteUploadedDocument: (documentGuid: string, taxSoftware: TaxSoftwareType, isOrganizerFile: boolean, organizerGuid: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        var url = '';
        if (taxSoftware == TaxSoftwareType.ProSystems) {
            url = `${apiPrefix}Document/${documentGuid}`
        }
        else {
            if (isOrganizerFile) {
                url = `${apiPrefix}Document/DeleteOrganizerFile/${documentGuid}/${organizerGuid}`
            }
            else {
                url = `${apiPrefix}Document/DeleteEngagementFile/${documentGuid}/${organizerGuid}`
            }
        }
        let fetchTask = fetch(url,
            {
                method: 'DELETE',
                credentials: 'include'
            })
            .then((data) => {
                callback();
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerLinkError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
                logger?.trackError(`deleteUploadedDocument failed for the request having parameters ${documentGuid} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    deleteDocument: (url: string, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const fetchTask = fetch(`${url}`,
            {
                method: 'DELETE',
                credentials: 'include'
            })
            .then((data) => {
                callback && callback();
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerLinkError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
                logger?.trackError(`deleteEmailMessage failed for the request with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    deleteOrganizerTemplateUploadedDocument: (documentGuid: string, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}BlankOrganizerTemplate/DeleteBlankOrganizerDocument/${documentGuid}`,
            {
                method: 'DELETE',
                credentials: 'include'
            })
            .then((data) => {
                callback();
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.OrganizerLinkError,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
                logger?.trackError(`deleteOrganizerTemplateUploadedDocument failed for the request having parameters ${documentGuid} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    submitAdditionalEsignDocuments: (data: AdditionalEsignModel[], callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}/SaveUploadedAdditionalEsignDocuments`,
            {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json, text/plain,*/*',
                    'Content-Type': 'application/json',
                    'traditional': 'true',
                }
            })
            .then((data) => {
                callback();
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionalEsignUploadFailed,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
                logger?.trackError(`submitAdditionalEsignDocuments failed for the request having parameters with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    updateAdditionalEsignDocuments: (data: AdditionalEsignModel, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}/UpdateAdditionalEsignDocument/${data.fileGuid}`,
            {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify(data),
                headers: {
                    'Accept': 'application/json, text/plain,*/*',
                    'Content-Type': 'application/json',
                    'traditional': 'true',
                }
            })
            .then((data) => {
                callback();
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.AdditionalEsignUploadFailed,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
                logger?.trackError(`updateAdditionalEsignDocuments failed for the request having parameters with error ${error.message}`)
            });
        addTask(fetchTask);
    },
}


export const reducer: Reducer<OrganizerUploadState> = (state: OrganizerUploadState = unloadedOrganizerUploadState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_ORGANIZER_LINK:
            var received = { ...state };
            received.sas = action.sasUrl;
            received.guid = action.documentGuid;
            return received;
    }
    return state || unloadedOrganizerUploadState;
};