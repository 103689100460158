
export enum actionTypes {

    REQUEST_CUSTOM_QUESTIONS = "request_custom_questions",
    RECEIVED_CUSTOM_QUESTIONS = "received_custom_questions",
    COMPLETED_CUSTOM_QUESTIONS = "completed__custom_questions",

    REQUEST_CUSTOM_QUESTION = "request_custom_question",
    RECEIVED_CUSTOM_QUESTION = "received_custom_question",
    COMPLETED_CUSTOM_QUESTION = "completed__custom_question",

    REQUEST_UPDATE_CUSTOM_QUESTION = "request_update_custom_question",
    RECEIVED_UPDATE_CUSTOM_QUESTION = "received_update_custom_question",
    COMPLETED_UPDATE_CUSTOM_QUESTION = "completed_update_custom_question",

    REQUEST_DELETE_CUSTOM_QUSETION = "request_delete_custom_question",
    RECEIVED_DELETE_CUSTOM_QUSETION = "received_delete_custom_question",
    COMPLETED_DELETE_CUSTOM_QUSETION = "completed_delete_custom_question",

    REQUEST_SET_DEFAULT_CUSTOM_QUESTION = "request_set_default_custom_question",
    RECEIVED_SET_DEFAULT_CUSTOM_QUESTION = "received_set_default_custom_question",
    COMPLETED_SET_DEFAULT_CUSTOM_QUESTION = "completed_set_default_custom_question",

    REQUEST_GET_TEMPLATE_IN_USE = "request_get_template_in_use",
    RECEIVED_GET_TEMPLATE_IN_USE = "received_get_template_in_use",
    COMPLETED_GET_TEMPLATE_IN_USE = "completed_get_template_in_use",

}

