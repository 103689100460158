import * as React from "react";
import { Row } from "reactstrap";
import * as BootStrap from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import { Prompt } from 'react-router';
import { ApplicationState } from "../../../../store/index";
import * as OranizerCompanySettingsStore from "../../../store/OrganizerCompanySettingsStore";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { GeneralSettingConstants, SourceDocumentNotification } from "../../../../components/helper/Constants";
import AutomaticRemainders from "./AutomaticRemainders";
import { isAutoReminderSelected } from '../../../../components/common/utils';

import { actionTypes } from 'src/store/ActionTypes';
import { StoreConstants } from '../../Helper/OrganizerConstants'
import { StatusType, NotificationAction } from '../../../../store/common/NotificationStore'

const initialSettings = {
    enableEngagementLetter: false,
    enabledSourceDocuments: false,
    enableAutoReminder: false,
    esignReminderDays: 7,
    orgReminderDays: 7,

    noAdditionalCPANotificationRequired: false,
    notifyEveryTimeUploadOrDeleteSrcDoc: false,
    schNotification1PerDayUploadOrDeleteSrcDoc: false
};

const General: React.FC = () => {
    const dispatch = useDispatch();

    const companySettings = useSelector(
        (state: ApplicationState) => state.organizerCompanySettings.companySettings
    );

    const isOrganizerCompanySettingsLoading = useSelector(
        (state: ApplicationState) => state.organizerCompanySettings.loading
    );

    const [generalSettings, setGeneralSettings] = React.useState(initialSettings);
    const [showPrompt, setShowPrompt] = React.useState(false);

    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);

    var toolTipForsrcDocSettings = companySettings?.sourceDocumentSettings?.enabledSourceDocuments ? "" : GeneralSettingConstants.sourceDocNotificationtoolTip;

    const [toolTip, setTooltip] = React.useState(toolTipForsrcDocSettings);
    const refToolTip = React.useRef(toolTipForsrcDocSettings);

    const refSettings = React.useRef(generalSettings); //Without Ref window beforeunload event is always taking stale state

    const getStateMapObject = () => {
        return {
            enableEngagementLetter: companySettings?.defaultSettings?.enableEngagementLetter,
            enabledSourceDocuments: companySettings?.sourceDocumentSettings?.enabledSourceDocuments,
            enableAutoReminder: isAutoReminderSelected(companySettings?.reminderSettings),
            esignReminderDays: companySettings?.reminderSettings?.esignReminder?.reminderDays,
            orgReminderDays: companySettings?.reminderSettings?.organizerReminder?.reminderDays,
            noAdditionalCPANotificationRequired: companySettings?.sourceDocumentSettings?.noAdditionalCPANotificationRequired,
            notifyEveryTimeUploadOrDeleteSrcDoc: companySettings?.sourceDocumentSettings?.notifyEveryTimeUploadOrDeleteSrcDoc,
            schNotification1PerDayUploadOrDeleteSrcDoc: companySettings?.sourceDocumentSettings?.schNotification1PerDayUploadOrDeleteSrcDoc
        };
    }

    React.useEffect(() => {
        dispatch(OranizerCompanySettingsStore.ActionCreators.requestOrganizerCompanySettings());
    }, []);

    React.useEffect(() => {
        const mappedObject = { ...getStateMapObject() };
        setGeneralSettings(mappedObject);
        refSettings.current = mappedObject;
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, [companySettings]);

    React.useEffect(() => {
        let toolTip = "";

        if (!generalSettings?.enabledSourceDocuments) {
            toolTip = GeneralSettingConstants.sourceDocNotificationtoolTip;
        }

        refToolTip.current = toolTip;

        setTooltip(toolTip);

        toolTipForsrcDocSettings = toolTip;
    })

    const getUpdatedSettings = () => {
        const {
            enableEngagementLetter,
            enabledSourceDocuments,
            enableAutoReminder,
            esignReminderDays,
            orgReminderDays,
            noAdditionalCPANotificationRequired,
            notifyEveryTimeUploadOrDeleteSrcDoc,
            schNotification1PerDayUploadOrDeleteSrcDoc
        } = generalSettings;

        const settings = companySettings;

        settings.defaultSettings.enableEngagementLetter = enableEngagementLetter;
        settings.sourceDocumentSettings.enabledSourceDocuments = enabledSourceDocuments;
        settings.reminderSettings.esignReminder.enabled = enableAutoReminder;
        settings.reminderSettings.esignReminder.reminderDays = esignReminderDays;
        settings.reminderSettings.organizerReminder.enabled = enableAutoReminder;
        settings.reminderSettings.organizerReminder.reminderDays = orgReminderDays;

        settings.sourceDocumentSettings.noAdditionalCPANotificationRequired = noAdditionalCPANotificationRequired;
        settings.sourceDocumentSettings.notifyEveryTimeUploadOrDeleteSrcDoc = notifyEveryTimeUploadOrDeleteSrcDoc;
        settings.sourceDocumentSettings.schNotification1PerDayUploadOrDeleteSrcDoc = schNotification1PerDayUploadOrDeleteSrcDoc;

        return settings;
    }

    const isDataChanged = (localSettings: any) => {
        return JSON.stringify(localSettings) !== JSON.stringify(getStateMapObject());
    }

    const alertUser = (event: any) => {
        if (isDataChanged(refSettings.current)) {
            event.returnValue = "";
        }
        event.return = "";
    }

    const onApplyClick = () => {
        dispatch(
            OranizerCompanySettingsStore.ActionCreators.updateOrganizerCompanySettings(getUpdatedSettings())
        );
        setShowPrompt(false);
    };

    const updateSettings = (key: string, value: any) => {
        generalSettings[key] = value;
        setGeneralSettings({ ...generalSettings });
        refSettings.current = { ...generalSettings };
        setShowPrompt(isDataChanged({ ...generalSettings }));
    }

    const enabledSourceDocumentsChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        updateSettings("enabledSourceDocuments", e.target.checked);

        changeSrcDocNotificationSettings(e.target.checked, SourceDocumentNotification.NoAdditionalCPANotificationRequired);

        if (!generalSettings?.enabledSourceDocuments) {
            toolTipForsrcDocSettings = GeneralSettingConstants.sourceDocNotificationtoolTip;
        } else {
            dispatch({
                type: actionTypes.NOTIFICATION,
                statusMessage: StoreConstants.Success.SourceDocumentNotificationDefault,
                statusType: StatusType.Warning
            });
            toolTipForsrcDocSettings = GeneralSettingConstants.sourceDocNotificationtoolTip;
        }
    }

    const srcDocNotificationChangeHandler = (
        e: React.ChangeEvent<HTMLInputElement>,
        propName: SourceDocumentNotification
    ) => {
        if (generalSettings?.enabledSourceDocuments) {
            changeSrcDocNotificationSettings(e.target.checked, propName);
        }
    };

    const changeSrcDocNotificationSettings = (isChecked: boolean, propName: SourceDocumentNotification) => {

        if (propName === SourceDocumentNotification.NoAdditionalCPANotificationRequired) {

            updateSettings("noAdditionalCPANotificationRequired", isChecked);
            updateSettings("notifyEveryTimeUploadOrDeleteSrcDoc", false);
            updateSettings("schNotification1PerDayUploadOrDeleteSrcDoc", false);
        }
        else if (propName === SourceDocumentNotification.NotifyEveryTimeUploadOrDeleteSrcDoc) {

            updateSettings("notifyEveryTimeUploadOrDeleteSrcDoc", isChecked);
            updateSettings("noAdditionalCPANotificationRequired", false);
            updateSettings("schNotification1PerDayUploadOrDeleteSrcDoc", false);
        }
        else if (propName === SourceDocumentNotification.SchNotification1PerDayUploadOrDeleteSrcDoc) {

            updateSettings("schNotification1PerDayUploadOrDeleteSrcDoc", isChecked);
            updateSettings("notifyEveryTimeUploadOrDeleteSrcDoc", false);
            updateSettings("noAdditionalCPANotificationRequired", false);
        }
    };


    return (
        <LoadingOverlay style={{ height: '100%' }}>
            <div className="row row-override">
                <Prompt
                    when={showPrompt}
                    message={GeneralSettingConstants.promptMessage}
                />
                <div style={{ marginLeft: 16, height: "85vh", marginBottom: 10, fontSize: "14px" }}>
                    <div className="general-settings-header">
                        <h3>General</h3>
                        <div className="general-apply-button">
                            <BootStrap.Button
                                variant="info"
                                className=" marR05"
                                onClick={onApplyClick}>
                                <i className="fas fa-check"></i>
                                Apply
                            </BootStrap.Button>
                        </div>
                    </div>
                    <hr className="full-width" />
                    <h3 className="text-primary">{GeneralSettingConstants.engagementLetterHeading}</h3>
                    <Row className="general-settings-row">
                        <input
                            type="checkbox"
                            className={`general-settings-checkbox ${generalSettings.enableEngagementLetter ? ' checked' : ''}`}
                            checked={generalSettings.enableEngagementLetter}
                            onChange={(e) => updateSettings("enableEngagementLetter", e.target.checked)}
                        />
                        <div>{GeneralSettingConstants?.engagementLetterCheckbox}</div>
                        <i className="fa fa-question-circle" style={{ color: '#DAA520' }} title={GeneralSettingConstants.disableEngagementLetterPopoverMessage}></i>
                    </Row>
                    <hr className="full-width" />

                    <h3 className="text-primary">{GeneralSettingConstants.sourceDocumentHeading}</h3>
                    <Row className="general-settings-row">
                        <input
                            type="checkbox"
                            className={`general-settings-checkbox ${generalSettings.enabledSourceDocuments ? ' checked' : ''}`}
                            checked={generalSettings?.enabledSourceDocuments}
                            onChange={(e) => enabledSourceDocumentsChangeHandler(e)}
                        />
                        <div>{GeneralSettingConstants.sourceDocumentChecbox}</div>
                    </Row>

                    <Row className="general-settings-row">
                        <div id="divSrcDocNotification" className="new-main-content">
                            <h3 className="text-primary">{GeneralSettingConstants.sourceDocumentNotificationHeading}</h3>
                            <Row className="general-settings-row">
                                <input
                                    data-toggle="tooltip" data-placement="bottom" title={toolTip}
                                    type="radio"
                                    disabled={!generalSettings?.enabledSourceDocuments}
                                    name="srcDocNotification"
                                    className={`general-settings-checkbox ${generalSettings.notifyEveryTimeUploadOrDeleteSrcDoc ? ' checked' : ''}`}
                                    checked={generalSettings?.notifyEveryTimeUploadOrDeleteSrcDoc}
                                    onChange={(e) => srcDocNotificationChangeHandler(e, SourceDocumentNotification.NotifyEveryTimeUploadOrDeleteSrcDoc)}
                                />
                                <div data-toggle="tooltip" data-placement="bottom" title={toolTip}>
                                    {GeneralSettingConstants.notifyEveryTimeUploadOrDeleteSrcDocRadioBtn}
                                </div>
                            </Row>
                            <Row className="general-settings-row">
                                <input
                                    data-toggle="tooltip" data-placement="bottom" title={toolTip}
                                    type="radio"
                                    disabled={!generalSettings?.enabledSourceDocuments}
                                    name="srcDocNotification"
                                    className={`general-settings-checkbox ${generalSettings.schNotification1PerDayUploadOrDeleteSrcDoc ? ' checked' : ''}`}
                                    checked={generalSettings?.schNotification1PerDayUploadOrDeleteSrcDoc}
                                    onChange={(e) => srcDocNotificationChangeHandler(e, SourceDocumentNotification.SchNotification1PerDayUploadOrDeleteSrcDoc)}
                                />
                                <div data-toggle="tooltip" data-placement="bottom" title={toolTip}>
                                    {GeneralSettingConstants.schNotification1PerDayUploadOrDeleteSrcDocRadioBtn}
                                </div>
                            </Row>
                            <Row className="general-settings-row">
                                <input
                                    data-toggle="tooltip" data-placement="bottom" title={toolTip}
                                    type="radio"
                                    disabled={!generalSettings?.enabledSourceDocuments}
                                    name="srcDocNotification"
                                    className={`general-settings-checkbox ${generalSettings.noAdditionalCPANotificationRequired ? ' checked' : ''}`}
                                    checked={generalSettings?.noAdditionalCPANotificationRequired}
                                    onChange={(e) => srcDocNotificationChangeHandler(e, SourceDocumentNotification.NoAdditionalCPANotificationRequired)}
                                />
                                <div data-toggle="tooltip" data-placement="bottom" title={toolTip}>
                                     {GeneralSettingConstants.sourceDocumentUploadNotificationDisabledRadioBtn}
                                </div>
                            </Row>
                        </div>
                    </Row>

                    <hr className="full-width" />
                    <AutomaticRemainders generalSettings={generalSettings} updateSettings={updateSettings} />
                    <hr className="full-width" />
                </div>
            </div>
            <Loader loading={isOrganizerCompanySettingsLoading} text="Please wait..." />
        </LoadingOverlay>
    );
};

export default General;
