interface String {
  format(...args: string[]): string
}

String.prototype.format = function(...args) {
    let formatted = this;
    for (let i = 0; i < args.length; i++) {
      let regexp = new RegExp('\\{'+i+'\\}', 'gi');
      formatted = formatted.replace(regexp, args[i]);
    }
    return formatted;
};
