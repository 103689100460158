import * as React from "react";
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export interface IAccordionProps {
    uniqueId: any;
    header: any;
    onHeaderClick?: () => void;
    onContentClick?: (pageNo: number) => void;
    content: any;
    loading?: boolean;
}

export interface IAccordionStates {
    toggle: boolean;
}

export class Accordion extends React.PureComponent<IAccordionProps, IAccordionStates> {
    constructor(props: IAccordionProps) {
        super(props);
        this.state = { toggle: false }
    }

    private renderHeading = (key: any) => {
        return <div className="panel-heading bookmark-row bookmark-item" onClick={() => { this.onHeaderClick() }} style={{ background: "transparent", borderBottom: '1px solid #ddd' }}>
            <h4 className="panel-title col-md-12" style={{ display: "flex", width: "100%", padding: "0px" }}>
                <div className="col-md-10" style={{ padding: "0px", lineHeight: 'initial' }}>
                    <a className="bookmark-name wordbreak ellipsis collapsed" title={this.props.header} style={{ display: "block" }}
                        data-toggle="collapse" data-parent="#accordion" href={"#" + key + ""}>
                        {this.props.header}
                    </a>
                </div>
                <div className="col-md-2" style={{ padding: "0px", lineHeight: 'initial', paddingTop: "2px" }}>
                    <a href={"#" + key + ""} className={"accordion-toggle " + (this.state.toggle === true ? "" : "collapsed")} data-toggle="collapse"
                        data-parent="#accordion">
                    </a>
                </div>
            </h4>
        </div>;
    }

    private onHeaderClick = () => {
        this.setState({ toggle: !this.state.toggle });
        this.props.onHeaderClick && this.props.onHeaderClick();
    }

    private renderContent = (key: any) => {
        return <div id={key} className={"panel-collapse " + (this.state.toggle ? "" : "collapse")}>
            <div className="panel-body" style={{ padding: 0 }}>
                <LoadingOverlay style={{ height: '100%' }}>
                    <ul key={key}>
                        {
                            this.props.content
                        }
                    </ul>
                    <Loader loading={this.props.loading} />
                </LoadingOverlay>
            </div>
        </div>
    }

    public render() {
        let _self = this;
        return (<div className="accordian-panel panel-group" id="accordion" key={_self.props.uniqueId} style={{ margin: "0px" }}>
            <div className="panel panel-default">
                {
                    this.renderHeading(_self.props.uniqueId)
                }
                {
                    this.renderContent(_self.props.uniqueId)
                }
            </div>
        </div>);
    }
}