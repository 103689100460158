import * as React from 'react';
import { Button, Col, Modal } from 'react-bootstrap';
import DropdownComponent from '../../../../../../../components/common/controls/DropdownComponent';
import { DocumentType } from '../../../../../../models/OrganizerComponentModels';

interface EditAdditionalESignModalProps {
    fileGuid: string;
    onChangeDropdown(value: any): void;
    selectedValue: DocumentType;
    dropDownOptions: any;
    show: boolean;
    onHide: () => void;
    onDelete: (fileGuid: string) => void;
    onSave: (fileGuid: string) => void;
}


export const EditAdditionalESignModal: React.StatelessComponent<EditAdditionalESignModalProps> = (props) => {
    return <Modal className="edit-esign-modal" show={props.show} onHide={props.onHide}>
        <Modal.Header closeButton>
            <Modal.Title>
                <span className='text-secondary fas fa-edit' style={{ color: 'grey', marginRight: '5px',marginTop:'5px' }}></span>
                    Modify Added Document
                </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <div className="row">
                <Col sm={1} />
                <Col sm={3} className="text-right padT07">
                    <h4>Document Type:</h4>
                        </Col>
                <Col sm={7} className="text-left dropdown-component">
                    <DropdownComponent
                        id="ddlDocumentType"
                        selectedValue={props.selectedValue}
                        onChange={props.onChangeDropdown}
                        options={props.dropDownOptions}
                        clearable={false}
                    />
                </Col>
                <Col sm={1} />
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="info" data-test-auto="53AEB091-8D93-4CCF-8D3D-291A4B9F0B44"
                onClick={() => { props.onDelete(props.fileGuid) }}>
                <i className='fas fa-trash-alt'></i>Delete
                    </Button>
            <Button variant="default" data-test-auto="1EF69BC9-F8E8-496A-B5C8-24A8D2D3CC33" onClick={props.onHide}>
                <i className='fas fa-times'></i>Cancel
                    </Button>
            <Button variant="info" data-test-auto="F6D28C0A-BB16-4254-8B45-24905270330A"
                onClick={() => { props.onSave(props.fileGuid) }}>
                <i className='fas fa-save'></i>Save
                    </Button>
        </Modal.Footer>
    </Modal>
}