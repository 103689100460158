import { addTask } from 'domain-task';
import { AppThunkAction } from './../../store/index';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction, StatusType } from './../../store/common/NotificationStore';
import { apiPrefix } from './../models/OrganizerStoreModels';
import { StoreConstants } from '../components/Helper/OrganizerConstants';
import { BulkOperationFilters, BulkOperationReminderRequest, SendReminderOption } from '../models/OrganizerComponentModels';
import { LoggerFactory } from '../../Logger/LoggerFactory';
const logger = new LoggerFactory().getTelemetryLogger();

type KnownActions =
    NotificationAction;

export const actionCreators = {
    sendReminderBulkAsync: (reminderRequest: BulkOperationReminderRequest, callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}BulkOperation/SendBulkReminder`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(reminderRequest),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then((response) => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.SendReminderBulkWaitMessage, statusType: StatusType.Success });
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SendReminderError, statusType: StatusType.Error, statusCode: error?.status });
                if (callback) {
                    callback();
                }
                logger?.trackError(`sendReminderBulkAsync failed for the request having parameters ${JSON.stringify(reminderRequest)} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    sendReminderAsync: (ids: number[],
        sendReminderOptions: SendReminderOption,
        callback?: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

            let fetchTask = fetch(`${apiPrefix}BulkOperation/SendReminder`, {
                method: 'POST',
                credentials: 'include',
                body: JSON.stringify({
                    organizerIds: ids,
                    reminderOptions: sendReminderOptions
                }),
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json; charset=utf-8'
                }
            })
                .then((response) => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Success.SendReminderBulkWaitMessage, statusType: StatusType.Success });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.SendReminderError, statusType: StatusType.Error, statusCode: error?.status });
                    if (callback) {
                        callback();
                    }
                    logger?.trackError(`sendReminderAsync failed for the request having parameters ${JSON.stringify({ organizerIds: ids, reminderOptions: sendReminderOptions })} with error ${error.message}`)
                });
            addTask(fetchTask);
        },

    archiveOrganizersBulk: (request: BulkOperationFilters, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}BulkOperation/ArchiveOrganizers`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(request),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.BulkProcessing,
                    statusType: StatusType.Success
                });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.ArchiveOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`archiveOrganizersBulk failed for the request having parameters ${request} with error ${error.message}`)
            });
        addTask(fetchTask);
    },

    recycleOrganizersBulk: (request: BulkOperationFilters, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        let fetchTask = fetch(`${apiPrefix}BulkOperation/RecycleOrganizers`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(request),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.BulkProcessing,
                    statusType: StatusType.Success
                });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.RecycleOrganizers,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
                logger?.trackError(`recycleOrganizersBulk failed for the request having parameters ${request} with error ${error.message}`)
            });
        addTask(fetchTask);
    },
};
