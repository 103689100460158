import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction } from './../../store/common/NotificationStore';
import {
    ChangeDeliveredOrganizerPageOptionsAction,
    ChangeOrganizerPageOptionsAction,
    OrganizerPageSelectorStoreState,
    initialOrganizerPageSelectorStoreState
} from './../models/OrganizerStoreModels';
import { OrganizerPageOptions } from './../models/OrganizerComponentModels';

type KnownActions =
    ChangeDeliveredOrganizerPageOptionsAction |
    ChangeOrganizerPageOptionsAction |
    NotificationAction;

export const actionCreators = {
    changeOrganizerOptions: (option: OrganizerPageOptions): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.CHANGE_ORGANIZER_PAGE_OPTIONS,
            selectedOrganizerOption: option
        })
    },

    changeDeliveredOrganizerOptions: (option: OrganizerPageOptions): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({
            type: actionTypes.CHANGE_DELIVERED_ORGANIZER_PAGE_OPTIONS,
            selectedDeliveredOrganizerOption: option
        })
    }

};
export const reducer: Reducer<OrganizerPageSelectorStoreState> = (state: OrganizerPageSelectorStoreState = initialOrganizerPageSelectorStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.CHANGE_DELIVERED_ORGANIZER_PAGE_OPTIONS: {
            const deliveredOptions = { ...state };
            deliveredOptions.selectedDeliveredOrganizerOption = action.selectedDeliveredOrganizerOption;
            return deliveredOptions;
        }
        case actionTypes.CHANGE_ORGANIZER_PAGE_OPTIONS: {
            const organizerOptions = { ...state };
            organizerOptions.selectedOrganizerOption = action.selectedOrganizerOption;
            return organizerOptions;
        }
    }
    return state || initialOrganizerPageSelectorStoreState;
}