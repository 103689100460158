import React from 'react';
import { useState } from 'react';
import { Accordion, Card } from "react-bootstrap";
import { IUserModel } from "../../../Core/ViewModels/User/UserViewModel";
import DropdownComponent from "../../common/controls/DropdownComponent";
import { RadioButtonComponent } from "../../common/RadioButtonComponent";
import { DefaultSenderInfoType, IUserDefaultSettings } from "./MySettings";
import { DownloadType } from "../../../Organizer/models/OrganizerComponentModels";

interface MyDefaultsProps {
    defaultSettings: IUserDefaultSettings;
    onHide(): void;
    showState: boolean;
    verifyLoading: boolean;
    users: IUserModel[];
    loggedinUser: number;
    eroList: any;
    onChangeEROStamp(event: any): void;
    onChangeContactPerson(event: any): void;
    onChangeSender(event: any): void;
    onChangeNotifySigningEvents(event: any): void;
    onChangeNotifyOrganizerEvents(event: any): void;
    onChangeNotifySourceDocEvents(event: any): void;
    onChangeSenderName(event: any): void;
    onChangeDefaultDownload(event: any): void;
}

const MyDefaults: React.FC<MyDefaultsProps> = ({ defaultSettings,
    users,
    loggedinUser,
    onChangeEROStamp,
    onChangeContactPerson,
    onChangeSender,
    onChangeNotifySigningEvents,
    onChangeNotifyOrganizerEvents,
    onChangeNotifySourceDocEvents,
    onChangeSenderName,
    onChangeDefaultDownload,
    eroList }: MyDefaultsProps) => {

    const otherUsers = () => {
        var usersList: any = [];
        users?.map((user: IUserModel) => {
            usersList.push({
                value: user.id,
                label: user.firstName + " " + user.lastName,
            })
        });
        return usersList;
    }
    const [userList] = useState(otherUsers);

    const downloadOptions = [
        { value: DownloadType.DownloadNow, label: "Download Now" },
        { value: DownloadType.DownloadToMyDownloads, label: "Download to \"My Downloads\"" },
        { value: DownloadType.PromptMeEveryTime, label: "Prompt me Every Time" }
    ]

    return (
        <Accordion defaultActiveKey="0">
            <Card id="collapsible-panel-defaults" className="marB20">
                <Accordion.Toggle eventKey="0">
                    <Card.Header style={{ paddingTop: '8px', paddingBottom: '5px' }}>
                        <Card.Title>
                            <i className='fa fa-star'></i>My Defaults
                        </Card.Title>
                    </Card.Header>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default ERO/Signer:</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`}>
                                    <DropdownComponent
                                        id="ddlEROList"
                                        options={eroList}
                                        onChange={e => onChangeEROStamp(e)}
                                        selectedValue={defaultSettings.eroUser}
                                        data-test-auto="A9B8AC8F-6220-4863-865D-A95E7492809F"

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default Contact Person:</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`} >

                                    <DropdownComponent
                                        id="ddlContactPersonList"
                                        options={userList}
                                        onChange={e => onChangeContactPerson(e)}
                                        selectedValue={defaultSettings.contactPerson}
                                        data-test-auto="D96F597E-56F3-4179-AEAB-A12A8B7308E2"
                                    />
                                </div>


                            </div>
                        </div>
                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default Sender:</div>
                            </div>
                            <div className={`col-sm-3 col-md-2 col-xl-2`}>
                                <RadioButtonComponent
                                    id={"Company"}
                                    checked={defaultSettings.sender.senderType == DefaultSenderInfoType.Company}
                                    onChange={e => onChangeSender(e)}
                                    value={"company"}
                                    dataTestAuto="EDE9EF76-CB65-476D-8CB8-0AE9E815384A"
                                    name="rdbDefaultSender"
                                    text={"Company"}
                                />
                            </div>
                            <div className="col-sm-3 col-md-2 col-xl-2">
                                <RadioButtonComponent
                                    id={"Users"}
                                    text={"Users"}
                                    checked={defaultSettings.sender.senderType == DefaultSenderInfoType.User}
                                    onChange={e => onChangeSender(e)}
                                    value={"users"}
                                    dataTestAuto={"ADE9EF76-CB65-476D-8CB8-0AE9E815477A"}
                                    name="rdbDefaultSender"
                                />
                            </div>
                        </div>
                        <div className={(defaultSettings.sender.senderType == DefaultSenderInfoType.Company) ? 'hidden' : 'row marB03 padT07'}>
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Select Default Sender Name:</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`} >
                                    <DropdownComponent
                                        id="ddlDefaultSender"
                                        options={userList}
                                        onChange={e => onChangeSenderName(e)}
                                        selectedValue={defaultSettings.sender.senderId}
                                        data-test-auto="A8E1183B-863F-4649-83BA-787DC6A1FACD"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default Notify About Signing Events:</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`}>
                                    <DropdownComponent
                                        id="ddlSigningUsers"
                                        options={userList}
                                        onChange={e => onChangeNotifySigningEvents(e)}
                                        selectedValue={defaultSettings.notifyELEventsUser}
                                        data-test-auto="A9B8AC8F-6220-4863-865D-A95E7492809F"

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default Notify About Organizer Events:</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`}>
                                    <DropdownComponent
                                        id="ddlOrgUsersList"
                                        options={userList}
                                        onChange={e => onChangeNotifyOrganizerEvents(e)}
                                        selectedValue={defaultSettings.notifyOrganizeEventsUser}
                                        data-test-auto="A9B8AC8F-6220-4863-865D-A95E7492809F"

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default Notify About Uploading Events:</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`}>
                                    <DropdownComponent
                                        id="ddlSourceDocUsersList"
                                        options={userList}
                                        onChange={e => onChangeNotifySourceDocEvents(e)}
                                        selectedValue={defaultSettings.notifySourceDocEventsUser}
                                        data-test-auto="A9B8AC8F-6220-4863-865D-A95E7492809F"

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row marB03 padT07">
                            <div className="col-sm-6 col-md-5 col-xl-5">
                                <div className="marB10 padT07">Default Download Options</div>
                            </div>
                            <div className="col-sm-6 col-md-7 col-xl-7 my-def-help-tooltip">
                                <div className={`padR30`}>
                                    <DropdownComponent
                                        id="ddlSourceDocUsersList"
                                        options={downloadOptions}
                                        onChange={e => onChangeDefaultDownload(e)}
                                        selectedValue={defaultSettings.download.downloadType}
                                        data-test-auto="A9B8AC8F-6220-4863-865D-A95E7492809F"
                                        clearable={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>)
}

export default MyDefaults;