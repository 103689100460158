import * as React from 'react';
import { ProcessStatus } from './../../../models/OrganizerComponentModels';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";
import { isExceedingOneDay } from './../../Helper/OrganizerHelperFunctions';

interface OrganizerActionProps {
    rowIndex: number;
    onProcessOrganizer: (rowIndex: number) => void;
    onDeleteOpen: (rowIndex: number) => void;
    documentStatus: ProcessStatus;
    uploadedOn: Date;
    onEditClientInfoOpen: (rowIndex: number) => void;
    onReportProblemOpen: (rowIndex: number) => void;
    batchView?: boolean;
    disableDelete?: boolean;
}

const OrganizerAction: React.StatelessComponent<OrganizerActionProps> =
    ({ rowIndex, onProcessOrganizer, onDeleteOpen, documentStatus, uploadedOn, onEditClientInfoOpen, onReportProblemOpen, batchView, disableDelete = false }) => {

        const editClientInfoButton = () => {
            return (
                <button data-test-auto="99e82c89-207f-43a0-93f3-d9c2968bf563" title={batchView ? "View Client Info" : "Edit Client Info"} type="button" className="btn-white btn-xs"
                    data-resource-id={batchView ? ResourceIdConstants.OrganizerInProcessGirdEditClientBtnBatch : ResourceIdConstants.OrganizerInProcessGirdEditClientBtnProforma}
                    onClick={() => onEditClientInfoOpen(rowIndex)}>
                    
                    <i className='fas fa-address-card text-success'></i>
                </button>
            );
        }

        const processIcon = batchView ? 'fas fa-eye' : 'fa fa-rocket';


        const processOrganizerButton = () => {
            return (
                <button data-test-auto="fba3bc9f-3959-466d-86d9-3019c3a9aae6" title={`${!batchView ? 'Process Organizer' : "Preview Organizer"}`} type="button" className="btn-white btn-xs"
                    data-resource-id={ResourceIdConstants.ProcessOrganizer}
                    onClick={() => onProcessOrganizer(rowIndex)}>
                    <i className={`${processIcon} text-violet`}></i>
                </button>
            );
        }

        const deleteButton = () => {
            return (
                <button data-test-auto="c723f578-10c0-4fcd-bf16-e2f2068945b9" title={ disableDelete && batchView ? "Batch must have at least one organizer." : "Delete"} type="button" className="btn-white btn-xs" disabled={disableDelete}
                    data-resource-id={batchView ? ResourceIdConstants.DeleteBatchOrganizerInProcessGridBatch : ResourceIdConstants.DeleteOrganizerInProcessGridProforma}
                    onClick={() => onDeleteOpen(rowIndex)}>
                    <i className="text-danger glyphicon glyphicon-remove" style={{ paddingTop: '2px', paddingBottom: '6px' }} />
                </button>
            );
        }

        const reportProblemButton = () => {
            return (
                <button data-test-auto="808603bb-bf23-4dcf-bbb9-1ba1cd113b33" title={"Report a Problem"} type="button" className="btn-white btn-xs"
                    data-resource-id= {batchView ? ResourceIdConstants.ReportProblemOrganizerInProcessBatchGrid : ResourceIdConstants.ReportProblemOrganizerInProcessProformaGrid}
                    onClick={() => onReportProblemOpen(rowIndex)}>
                    <i className="fas fa-bullhorn" style={{ color: "#17a2b8" }} />
                </button>
            );
        }

        const createPrimaryActions = () => {

            return (<div>
                {
                    documentStatus !== ProcessStatus.Uploaded &&
                    documentStatus !== ProcessStatus.PreparingForDelivery &&
                    documentStatus !== ProcessStatus.Error &&
                    documentStatus !== ProcessStatus.DeliveryFailed &&
                    processOrganizerButton()
                }
                {
                    documentStatus !== ProcessStatus.Uploaded &&
                    documentStatus !== ProcessStatus.PreparingForDelivery &&
                    documentStatus !== ProcessStatus.Error &&
                    documentStatus !== ProcessStatus.DeliveryFailed &&
                    editClientInfoButton()
                }
                {
                    (
                        (documentStatus !== ProcessStatus.Uploaded && documentStatus !== ProcessStatus.PreparingForDelivery)
                         || isExceedingOneDay(uploadedOn)
                    ) &&
                    deleteButton()
                }
                {
                    documentStatus !== ProcessStatus.Uploaded &&
                    documentStatus !== ProcessStatus.PreparingForDelivery &&
                    documentStatus !== ProcessStatus.Error &&
                    documentStatus !== ProcessStatus.DeliveryFailed &&
                    reportProblemButton()
                }
            </div>);
        }

        return <div className="button-group">{createPrimaryActions()}</div>;
    }

export { OrganizerAction }