import * as React from 'react';
import { batch, connect } from 'react-redux';
import { Row, Col, Button } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router';
import { OrganizerBatchExcelDownloadStoreState } from '../../../models/OrganizerStoreModels';
import * as OrganizerBatchExcelDownloadStore from './../../../store/BatchExcelDownloadStore';
import { ApplicationState } from '../../../../store/index';
import { Loader, LoadingOverlay } from 'react-overlay-loader';

type BatchExcelDownloadProps = {
    batchExcelDownloadStore: OrganizerBatchExcelDownloadStoreState;
    downloadExcel: (id: string) => any;
}
    & RouteComponentProps<{ id: string }>;

const BatchExcelDownload: React.FunctionComponent<BatchExcelDownloadProps> = ({ match, batchExcelDownloadStore: { loading }, downloadExcel }) => {
    return (
        <>
            <h3>Download Batch Organizers Report</h3>
            <LoadingOverlay>
                <Row style={{ height: '500px' }}>
                    <div style={{ margin: 'auto', textAlign: 'center' }}>
                        <h2>Your exported report is ready! </h2>
                        <Button data-test-auto="eca69928-a0af-424a-94bd-5236e1467f74"
                            variant='info'
                            onClick={() => { match && match.params && downloadExcel(match.params.id) }}>
                            <i className='glyphicon glyphicon-download-alt'></i>Download
                    </Button>
                    </div>
                </Row>
                <Loader loading={loading} />
            </LoadingOverlay>
        </>
    );
}

export default connect(
    (state: ApplicationState) => ({
        batchExcelDownloadStore: state.batchExportExcelStore
    }), {
    ...OrganizerBatchExcelDownloadStore.actionCreators
})(BatchExcelDownload);



