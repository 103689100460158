import * as React from 'react';
import { DropzoneComponent } from 'react-dropzone-component';

interface IDropzoneComponent {
    componentConfig: any;
    eventHandlers: any;
    djsConfig: any;
    autoTestId?: string;
}
export class DocumentUploadDropzoneComponent extends React.Component<IDropzoneComponent, {}>{

    public render() {
        return <div className="divDocumentUpload" data-test-auto={this.props.autoTestId}>
            <DropzoneComponent config={this.props.componentConfig}
                eventHandlers={this.props.eventHandlers}
                djsConfig={this.props.djsConfig}
            >
            </DropzoneComponent>
        </div>
    }
}

export default DocumentUploadDropzoneComponent;