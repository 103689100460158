import "./LocationMismatchActionModal.scss";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import CloseIcon from 'src/components/svg/CloseIcon';
import { ILocationMismatchActionModalProps } from "./LocationMismatchActionModal.model";
import { LocationMismatchModal } from "src/components/helper/Constants";

const LocationMismatchActionModal: React.FC<ILocationMismatchActionModalProps> = (props) => {
    return (
        <Modal show={props.show} onHide={props.onCancel} className="locationMismatchModal">
            <Modal.Header>
                <div>
                    <Modal.Title>{LocationMismatchModal.header.title}</Modal.Title>
                </div>
                <div data-test-auto="3AE0C223-EB6A-4F04-8BBC-72FD908CB985" className="closeIcon" onClick={props.onCancel}>
                    <CloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <div className="warning-alter">                    
                    <p className="wordWrap-breakWord">                       
                        {LocationMismatchModal.body.Content.Line1}
                        <b>{LocationMismatchModal.body.Content.Line2.format(props.locationName)}</b>
                        {LocationMismatchModal.body.Content.Line3}
                        <br/>{LocationMismatchModal.body.Content.Line4}
                    </p>                    
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button data-test-auto="AF335F34-D26C-456E-A6DC-8F1665834793" variant="secondary" onClick={props.onCancel}>
                    {LocationMismatchModal.footer.Cancel}
                </Button>
                <Button onClick={props.onContinue} data-test-auto="62ECF9B0-E98D-4A3A-8961-3F8D0DDA84D3" variant="primary">
                    {LocationMismatchModal.footer.Continue}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default LocationMismatchActionModal;