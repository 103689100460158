import { IFormData, ISectionItems } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { NotificationAction } from "src/store/common/NotificationStore";
import { ISubQuestionChoiceIdentifierPayload } from "../FormBuilder/ActionTypes";

export enum actionTypes {
    INTIALISE_CQ_PREVIEW_FORM = "intialise_cq_preview_form",
    HANDLE_PARAGRAPH_ON_CHANGE = "handle_paragrah_on_change",
    HANDLE_SUB_QUESTION_MCQ_ON_CHANGE = "handle_sub_question_mcq_on_change"
}

export interface IOnChangeHandlerPayload extends IItemIdentifier {
    value: string;
}
export interface ITitleOnChangeHandlerPayload {
    value: string;
}

export interface IItemIdentifier {
    sectionIndex: number;
    sectionItemIndex: number;
}
export interface IQuestionReOrderPayload {
    sectionIndex: number;
    sectionItems: ISectionItems[];
}

export interface IHandleParagraphOnChange {
    type: actionTypes.HANDLE_PARAGRAPH_ON_CHANGE;
    payload: IOnChangeHandlerPayload;
}
export interface IIntialiseCqPreview {
    type: actionTypes.INTIALISE_CQ_PREVIEW_FORM;
    payload: IFormData;
}

export interface IHandleSubQuestionMcqOnChange {
    type: actionTypes.HANDLE_SUB_QUESTION_MCQ_ON_CHANGE;
    payload: ISubQuestionChoiceIdentifierPayload;
}

export type DispatchActions = IHandleParagraphOnChange | IIntialiseCqPreview | IHandleSubQuestionMcqOnChange;

export type KnownActions = DispatchActions | NotificationAction;
