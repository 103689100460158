import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { IUserModel, IPartnerModel } from '../../Core/ViewModels/User/UserViewModel';
import { actionTypes } from '../ActionTypes';
import { handleResponse } from '../Library';
import { UserManagementConstants } from '../../components/helper/Constants';
import { StatusType, NotificationAction } from '../common/NotificationStore';
import * as UserProfileStore from './UserProfileStore';
import { API_BASE_URL, SSSUITE_API_BASE_URL } from 'src/utils/constants';

export const sssuiteApiPrefix = `${SSSUITE_API_BASE_URL}api`;

export interface IUserData {
    users: IUserModel[];
    isLoading: boolean;
}

export interface ISSRUserData {
    users: IUserModel[];
    isLoading: boolean;
}

export interface IPartnerData {
    partners: IPartnerModel[];
    isLoading: boolean;
}

export interface ILocationData {
    locations: number[];
}

export interface IDependentUserData {
    users: IUserModel[];
}

export interface UserState {
    user: IUserModel;
}

interface RequestUserListAction {
    type: actionTypes.REQUEST_USER_LIST;
}

interface ReceiveUserListAction {
    type: actionTypes.RECEIVE_USER_LIST;
    users: IUserModel[];
}

interface ReceiveDependentUserListAction {
    type: actionTypes.RECEIVE_DEPENDENT_USER_LIST;
    users: IUserModel[];
}

interface SaveUserAction {
    type: actionTypes.SAVE_USER;
    user: IUserModel;
}

interface DeleteUserAction {
    type: actionTypes.DELETE_USER;
    user: IUserModel;
}

interface UpdateUserAction {
    type: actionTypes.UPDATE_USER;
    user: IUserModel;
}

interface UpdateDefaultContactAction {
    type: actionTypes.UPDATE_DEFAULT_CONTACT;
}

interface ErrorUserSettings {
    type: actionTypes.ERROR_USER_MANAGEMENT;
    statusMessage: string;
    statusType: StatusType;
}

interface ReceiveSSRUserListAction {
    type: actionTypes.RECEIVE_SSR_USER_LIST;
    users: IUserModel[];
}

interface ReceivePartnersListAction {
    type: actionTypes.RECEIVE_PARTNERS_LIST;
    partners: IPartnerModel[];
}

interface ReceiveLocationsListAction {
    type: actionTypes.RECEIVE_USER_OFFICE_LOCATIONS;
    userLocations: number[];
}

type KnownAction =
    DispatchAction |
    NotificationAction;

type DispatchAction =
    RequestUserListAction |
    ReceiveUserListAction |
    ReceivePartnersListAction |
    DeleteUserAction |
    SaveUserAction |
    UpdateUserAction |
    ErrorUserSettings |
    UpdateDefaultContactAction |
    ReceiveLocationsListAction;

type ExtendedAction = KnownAction | ReceiveDependentUserListAction;

type SSRDisplatchAction = KnownAction | ReceiveSSRUserListAction;

type PartnerAction = KnownAction | ReceivePartnersListAction;

type LocationAction = KnownAction | ReceiveLocationsListAction;

export const actionCreators = {
    requestAllUsers: (reload: boolean = false): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || state.usersList.users.length === 0) {
            let fetchTask =  fetch(sssuiteApiPrefix +'/users', { credentials: 'include' })
                .then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_USER_LIST, users: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_USER_LIST });
        }
    },

    requestAllSSRUsers: (reload: boolean = false): AppThunkAction<SSRDisplatchAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || state.ssrUserList.users.length === 0) {
            let fetchTask = fetch(sssuiteApiPrefix + '/users/not-in-exchange-and-signature', { credentials: 'include' })
                .then(response => response.json() as Promise<IUserModel[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_SSR_USER_LIST, users: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        }
    },

    requestPartners: (reload: boolean = false): AppThunkAction<PartnerAction> => (dispatch, getState) => {
        let state = getState();
        if (reload || state.partners.partners.length === 0) {
            let fetchTask = fetch(sssuiteApiPrefix + '/users/partners', { credentials: 'include' })
                .then(response => response.json() as Promise<IPartnerModel[]>)
                .then(data => {
                    dispatch({
                        type: actionTypes.RECEIVE_PARTNERS_LIST, partners: data
                    });
                }).catch(function (error) {
                    dispatch({
                        type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
        }

    },

    requestUserById: (id: number, callback: (user: IUserModel) => void): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/UserManagement/' + id, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json() as Promise<IUserModel>)
            .then(data => {
                callback(data);
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
    },

    saveUserInfo: (userInfo: IUserModel, emailPasswordOnSave: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/UserManagement/' + emailPasswordOnSave, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(userInfo),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then((newUserId) => {
                let user = Object.assign({}, userInfo);
                user.userId = newUserId
                dispatch({ type: actionTypes.SAVE_USER, user: user });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.SaveUserSuccess,
                    statusType: StatusType.Success
                });
            }).catch(error => {
                let message = error.statusText ? error.statusText : UserManagementConstants.SavingUserError;
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: message,
                    statusType: StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_LIST });
    },

    updateUserInfo: (userInfo: IUserModel, oldEmailID: string, emailPasswordOnSave: boolean, isPasswordChanged: boolean,
        changedByUser: boolean, callback?: (dataPresent: boolean) => void): AppThunkAction<ExtendedAction> => (dispatch, getState) => {

            let fetchTask = fetch(API_BASE_URL + 'api/UserManagement/' + oldEmailID + '/' + emailPasswordOnSave + '/' + isPasswordChanged + '/' +
                changedByUser, {
                method: 'PUT',
                credentials: 'include',
                body: JSON.stringify(userInfo),
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
                .then(handleResponse)
                .then(data => {
                    if (typeof data === "object") {
                        dispatch({ type: actionTypes.RECEIVE_DEPENDENT_USER_LIST, users: data });
                        if (callback) {
                            callback(true);
                        }
                    }
                    else {
                        let message = isPasswordChanged ? UserManagementConstants.UserPasswordSuccess : UserManagementConstants.UserUpdationSuccess
                        dispatch({
                            type: actionTypes.UPDATE_USER, user: userInfo
                        });
                        dispatch({
                            type: actionTypes.NOTIFICATION, statusMessage: message,
                            statusType: StatusType.Success
                        });
                        let action: any = UserProfileStore.actionCreators.requestUserProfile(true);
                        dispatch(action);
                    }



                }).catch(error => {
                    let action = actionCreators.requestAllUsers();
                    dispatch(action);
                    let message = error.statusText ? error.statusText : UserManagementConstants.UserUpdationError;
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: message,
                        statusType: StatusType.Error
                    });
                    dispatch({
                        type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                        statusType: StatusType.Error
                    });
                });
            addTask(fetchTask);
            dispatch({ type: actionTypes.REQUEST_USER_LIST });
        },

    updateDefaultContactPerson: (dependentUsersIds: number[], userId: number, oldUserId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = fetch(API_BASE_URL + 'api/UserManagement/UpdateDefaultContactPerson/' + userId + '/' + oldUserId, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(dependentUsersIds),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                let message = UserManagementConstants.UserUpdationSuccess;
                dispatch({
                    type: actionTypes.UPDATE_DEFAULT_CONTACT
                });
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: message,
                    statusType: StatusType.Success
                });
                let action: any = UserProfileStore.actionCreators.requestUserProfile(true);
                dispatch(action);

            }).catch(error => {
                let message = error.statusText ? error.statusText : UserManagementConstants.UserUpdationError;
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: message,
                    statusType: StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_LIST });
    },

    deleteUser: (userInfo: IUserModel, currentUser: number | undefined, callback: (dataPresent: boolean) => void): AppThunkAction<ExtendedAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/UserManagement/' + currentUser, {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify(userInfo),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(response => response as Promise<IUserModel[]>)
            .then(data => {
                if (typeof data === "object") {
                    dispatch({ type: actionTypes.RECEIVE_DEPENDENT_USER_LIST, users: data });
                    callback(true);
                }
                else {
                    dispatch({
                        type: actionTypes.DELETE_USER, user: userInfo
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.DeleteUserSuccess,
                        statusType: StatusType.Success
                    });
                    callback(false);
                }
            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: error.statusText,
                    statusType: StatusType.Error
                });
                dispatch({ type: actionTypes.RECEIVE_USER_LIST, users: getState().usersList.users });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_LIST });
    },

    addSignaturesGroup: (userIds: number[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch(API_BASE_URL + 'api/UserManagement/AddSignaturesGroupAsync', {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(userIds),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: UserManagementConstants.UserUpdationSuccess,
                    statusType: StatusType.Success
                });
                let action = actionCreators.requestAllUsers(true);
                dispatch(action);
            }).catch(error => {
                let message = error.statusText ? error.statusText : UserManagementConstants.SavingUserError;
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: message,
                    statusType: StatusType.Error
                });
                dispatch({
                    type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });
        addTask(fetchTask);
        dispatch({ type: actionTypes.REQUEST_USER_LIST });
    },
    
    requestUserOfficeLocations: (reload: boolean = false): AppThunkAction<LocationAction> => (dispatch, getState) => {
        let state = getState();
        
        if (reload || state.userLocations.locations.length === 0) {
            let fetchTask = fetch(API_BASE_URL + 'api/Company/GetUserOfficeLocations', {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                }
            })
            .then(response => response.json() as Promise<number[]>)
            .then(data => {
                dispatch({
                    type: actionTypes.RECEIVE_USER_OFFICE_LOCATIONS, userLocations: data
                });
            }).catch(function (error) {
                dispatch({
                    type: actionTypes.ERROR_USER_MANAGEMENT, statusMessage: error,
                    statusType: StatusType.Error
                });
            });

            addTask(fetchTask);
        }
    },

};

const unloadedState: IUserData = {
    users: [],
    isLoading: false,
} as IUserData;

const unloadedCustomState: IDependentUserData = {
    users: []
} as IDependentUserData;

const unloadedSSRState: ISSRUserData = {
    users: [],
    isLoading: false,
} as ISSRUserData;

const unloadedPartnerState: IPartnerData = {
    partners: [],
    isLoading: false,
} as IPartnerData;

const unloadedLocationState: ILocationData = {
    locations: [],
} as ILocationData;

export const reducer: Reducer<IUserData> = (state: IUserData = unloadedState, incomingAction: Action) => {
    const action = incomingAction as DispatchAction;
    switch (action.type) {
        case actionTypes.REQUEST_USER_LIST:
            console.log(state.users);
            return {
                users: [...state.users],
                isLoading: true
            } as IUserData;
        case actionTypes.RECEIVE_USER_LIST:
            return {
                users: action.users,
                isLoading: false
            } as IUserData;
        case actionTypes.DELETE_USER:
            var userList = Object.assign({}, state);
            var index = userList.users.findIndex(user => user.id == action.user.userId);
            userList.users.splice(index, 1);
            return {
                users: userList.users,
                isLoading: false
            } as IUserData;
        case actionTypes.SAVE_USER:
            var userList = Object.assign({}, state);
            userList.users.push(action.user)
            userList.users = userList.users.sort((userOne: IUserModel, userTwo: IUserModel) => {
                if (userOne.firstName.toLocaleLowerCase() > userTwo.firstName.toLocaleLowerCase()) {
                    return 1;
                }
                if (userOne.firstName.toLocaleLowerCase() < userTwo.firstName.toLocaleLowerCase()) {
                    return -1;
                }
                return 0;
            });
            return {
                users: userList.users,
                isLoading: false
            } as IUserData;
        case actionTypes.UPDATE_USER:
            var userList = Object.assign({}, state);
            var index = userList.users.findIndex(user => user.id == action.user.userId);
            userList.users.splice(index, 1, action.user);
            return {
                users: userList.users,
                isLoading: false,
            } as IUserData;
        case actionTypes.UPDATE_DEFAULT_CONTACT:
            var userList = Object.assign({}, state);
            return {
                users: userList.users,
                isLoading: false,
            } as IUserData;
        case actionTypes.ERROR_USER_MANAGEMENT:
            let errorState: IUserData = Object.assign({}, state);
            errorState.isLoading = false;
            return errorState;
        default:
            const exhaustiveCheck: any = action;
    }

    return state || unloadedState;
};

export const customReducer: Reducer<IDependentUserData> = (state: IDependentUserData = unloadedCustomState, incomingAction: Action) => {
    const action = incomingAction as ExtendedAction;
    switch (action.type) {
        case actionTypes.RECEIVE_DEPENDENT_USER_LIST:
            var userList = Object.assign({}, state);
            userList.users = action.users;
            return {
                users: userList.users
            } as IDependentUserData;
    }

    return state || unloadedCustomState;
};

export const SSRUserReducer: Reducer<ISSRUserData> = (state: ISSRUserData = unloadedSSRState, incomingAction: Action) => {
    const action = incomingAction as SSRDisplatchAction;
    switch (action.type) {
        case actionTypes.RECEIVE_SSR_USER_LIST:
            var ssrUserList = Object.assign({}, state);
            ssrUserList.users = action.users;
            return {
                users: ssrUserList.users,
                isLoading: true
            } as ISSRUserData;
    }

    return state || unloadedSSRState;
};

export const PartnerReducer: Reducer<IPartnerData> = (state: IPartnerData = unloadedPartnerState, incomingAction: Action) => {
    const action = incomingAction as PartnerAction;
    switch (action.type) {
        case actionTypes.RECEIVE_PARTNERS_LIST:
            return {
                partners: action.partners,
                isLoading: true
            } as IPartnerData;
    }

    return state || unloadedPartnerState;
};

export const LocationReducer: Reducer<ILocationData> = (state: ILocationData = unloadedLocationState, incomingAction: Action) => {
    const action = incomingAction as LocationAction;
    
    switch (action.type) {
        case actionTypes.RECEIVE_USER_OFFICE_LOCATIONS:
            return {
                locations: action.userLocations
            } as ILocationData;
    }

    return state || unloadedLocationState;
};
