import { connect } from 'react-redux';
import { ApplicationState } from './../../../store';
import { OrganizerSettings } from './OrganizerSettings';
import * as SavedMessages from './../../store/OrganizerPreparerMessageStore';
import * as EmailMessages from './../../store/OrganizerEmailMessageStore';
import * as CompanySettings from './../../store/OrganizerCompanySettingsStore';
import * as CompanyStore from './../../../store/company/CompanyStore';
import * as UserSettings from '../../../store/UserManagement/UserSettingStore';

export default connect(
    (state: ApplicationState) => ({
        companySettingStore: state.organizerCompanySettings,
        emailMessageStore: state.organizerEmailMessage,
        preparerMessageStore: state.organizerPreparerMessage,
        companyData: state.companyData
    }),
    {
        ...EmailMessages.actionCreators,
        ...CompanySettings.ActionCreators,
        ...SavedMessages.actionCreators,
        ...CompanyStore.actionCreators,
        ...UserSettings.actionCreators
    }
)(OrganizerSettings);