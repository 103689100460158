var htmlencode = require('htmlencode');
import * as React from 'react';
import { PreparerMessage } from './../../../models/OrganizerComponentModels';
import { replaceHelperVariable, sanitize } from './../../Helper/OrganizerHelperFunctions';

interface PreparerMessageViewProps {
    selectedPreparerMessage: PreparerMessage;
    defaultMessageId: number;
}


export const PreparerMessageView: React.StatelessComponent<PreparerMessageViewProps> = ({ selectedPreparerMessage, defaultMessageId }) => {

    const isAllowToEdit = selectedPreparerMessage.isAllowToEdit;
    return (
        <div className="col-lg-12 col-sm-12 nopadding-left nopadding-right">
            <div className="form-group">
                <label className="font-weight-bold">Message Name{selectedPreparerMessage.id == defaultMessageId ?
                    <span className="badge default-span">Default</span> : <span></span>}
                </label>
                <div className="form-control" style={{ backgroundColor: '#f1f1f1' }}
                    dangerouslySetInnerHTML={{ __html: (selectedPreparerMessage.name.length > 60 ? selectedPreparerMessage.name.substring(0, 60) + '...' : selectedPreparerMessage.name) }} />
            </div>
            <div className="form-group form-pre-mes-body" >
                <label className="font-weight-bold">Message Body</label>
                <div className={`form-control pre-mes-body ${isAllowToEdit ? "height-85p" :"height-101p"}`} 
                    dangerouslySetInnerHTML={{ __html: sanitize(replaceHelperVariable(htmlencode.htmlDecode(selectedPreparerMessage.body))) }} />
            </div>
            {isAllowToEdit ? <div className="form-group prepare-allow-message">
                <i className="fas fa-check" style={{ color: '#337ab7' }}></i>
                <label className="font-weight-bold" style={{ marginLeft: 5 }} >Allow users to edit before sending</label>
            </div> : ""}
        </div>
    )
}
