import * as React from 'react';
import { EmailMessage } from './../../../models/OrganizerComponentModels';

interface EmailMessageViewProps {
    selectedMessage: EmailMessage;
}

export const EmailMessageView: React.StatelessComponent<EmailMessageViewProps> = ({ selectedMessage }) => {
    return (
        <div style={{ width: '-webkit-fill-available', lineHeight: '3em' }}>
            <div ><h4 title={selectedMessage.name}>{selectedMessage.name.length > 50 ? selectedMessage.name.substring(0,50) + "..." : selectedMessage.name}</h4></div>
            <div >
                <label>Header:</label>
                <label title={selectedMessage.subject} style={{ fontWeight: 200, marginLeft: 10 }}>{selectedMessage.subject.length > 65 ? selectedMessage.subject.substring(0,65) + "..." : selectedMessage.subject  }</label>
            </div>
            <textarea rows={7} value={selectedMessage.text} className="form-control email-text-area" disabled={true} />
        </div>
    )
}