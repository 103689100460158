import React, { FC } from "react";

const CloseIcon: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
        
        width={18}
        height={18}
        viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>


    );
};

export default CloseIcon;
