import { IUserModel, IPartnerModel } from '../../Core/ViewModels/User/UserViewModel';
import { fullName } from '../helper/HelperFunctions';
import * as Helper from '../helper/HelperFunctions';

export function getNonSignerUser(signatureusers: IUserModel[], allUsers: IUserModel[]) {
    let result = [...allUsers];
    signatureusers.map((u, i) => {
        Helper.removeItem(u, result);
    });
    return result;
}

export function sortUsersAscOrder(users: IUserModel[]) {
    return users.sort((a, b) => {      //Sorting
        if (a.firstName.toLocaleLowerCase() > b.firstName.toLocaleLowerCase()) {
            return 1;
        }
        if (a.firstName.toLocaleLowerCase() < b.firstName.toLocaleLowerCase()) {
            return -1;
        }
        return 0;
    });
}


export function PrepareUserList(userList: IUserModel[]) {
    var userOptionArray: any = [];
    userOptionArray.push({
        value: 0,
        label: "Select"
    })
    userList.map((user: any) =>
        userOptionArray.push({
            value: user.id,
            label: fullName(user)
        }));

    return userOptionArray;
}

export function convertPartnersToDropDownList(partners: IPartnerModel[]) {
    var partnersList: any = [];
    partners?.map((partner: IPartnerModel) => {
        let name = partner.lastName ? `${partner.firstName} ${partner.lastName}` : partner.firstName;
        partnersList.push({
            value: partner.id,
            label: name
        })
    });
    if (partnersList.length > 0) {
        partnersList = partnersList.sort((a: any, b: any) => {      //Sorting
            if (a.label.toLocaleLowerCase() > b.label.toLocaleLowerCase()) {
                return 1;
            }
            if (a.label.toLocaleLowerCase() < b.label.toLocaleLowerCase()) {
                return -1;
            }
            return 0;
        });
    }
    return partnersList;
}