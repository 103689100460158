import * as React from 'react'
import { Card, Accordion } from 'react-bootstrap';
import { Bookmarks } from './../../../../../../models/OrganizerComponentModels';

interface OrganizerBookmarkPanelProps {
    expanded: boolean;
    toggleExpand: () => void;
    bookmarks: Bookmarks;
    onPageSelect: (pageNo: number) => void;
}

const OrganizerBookmarkPanel: React.StatelessComponent<OrganizerBookmarkPanelProps> = ({ expanded, toggleExpand, bookmarks: { heading, pages }, onPageSelect }) => {
    return (
        <Accordion defaultActiveKey="0">
            <Card onToggle={() => toggleExpand()}>
                <Accordion.Toggle style={{ padding: "10px" }} as={Card.Header} variant="link" eventKey="0">
                    <Card.Title as="h4">
                        {heading ? heading : "Bookmarks"}
                    </Card.Title>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{ padding: 0 }}>
                        {
                            pages.length === 0 ?
                                <div className="textalign-center">No Content</div> :
                                pages.map((page, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="custom-list-group-item bookmark-item" >
                                            <div className="bookmark-row" id={`page-${index}`}>
                                                <i className={"bookmark-icon fas fa-bookmark"} />
                                                <div
                                                    className={"bookmark-name wordbreak ellipsis cursor-pointer"}
                                                    onClick={() => onPageSelect(page.pageNo)}
                                                >
                                                    {page.pageTitle}
                                                </div>
                                            </div>
                                        </div>
                                    );

                                })

                        }
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}
export { OrganizerBookmarkPanel }