export const ResourceIdConstants = {

    ResendAccessLinkInDeliveredOrganizer: "ORGDLVRY_G_RSACLK",
    ResendAccessLinkInArchivedOrganizer: "ORGARC_G_RSACLK",

    ViewAccessCodeInDeliveredOrganizer: "ORGDLVRY_G_VWACCD",
    ViewAccessCodeInArchivedOrganizer: "ORGARC_G_VWACCD",

    ReopenSourceDocumentUpload: "ORGDLVRY_G_ROSDU",
    ReopenOrganizer: "ORGDLVRY_G_ROORG",

    SendReminderToolBar : "ORGDLVRY_T_SNRMR",
    SendReminderGrid : "ORGDLVRY_G_SNRMR",

    DeleteOrganizerInProcessToolBar : "ORGINPRO_T_DELETE",
    DeleteOrganizerInProcessGridProforma : "ORGINPRO_G_DELPFM",
    DeleteBatchOrganizerInProcessGridBatch : "ORGINPRO_G_DELBTHORG",
    DeleteOrganizerInProcessGridBatch : "ORGINPRO_G_DELBTH",
    DeleteDeliveredOrganizerToolBar : "ORGDLVRY_T_DELETE",
    DeleteDeliveredOrganizerGird : "ORGDLVRY_G_DELETE",
    DeleteUndeliveredBatchGird : "ORGUDB_G_DELETE",
    DeleteArchivedOrganizerToolBar : "ORGARC_T_DELETE",
    DeleteArchivedOrganizerGrid : "ORGARC_G_DELETE",
    DeleteRecycleBinToolBar: "ORGRCB_T_DELETE",

    DownloadDeliveredOrganizerToolBar: "ORGDLVRY_T_DNLD",
    DownloadDeliveredOrganizerGird : "ORGDLVRY_G_DNLD",
    DownloadUndeliveredBatchGird : "ORGUDB_G_DNLD",
    DownloadArchivedOrganizerToolBar : "ORGARC_T_DNLD",
    DownloadArchivedOrganizerGird : "ORGARC_G_DNLD",

    UploadOrganizer: "ORGINPRO_T_UPLOAD",
    ProcessOrganizer: "ORGINPRO_G_PROPFM",
    ProcessBatchOrganizers: "ORGINPRO_G_PROBTH",
    DeliverOrganizer: "ORGINPRO_M_DLVRYPFM",
    DeliverBatchOrganizers: "ORGINPRO_M_DLVRYBTH",
    
    ChangeStatusDeliveredOrganizerToolBar : "ORGDLVRY_T_CHNSTS",
    RestoreArchivedToolbar : "ORGARC_T_RSTR",
    RestoreArchivedGrid : "ORGARC_G_RSTR",
    RestoreRecycleBinToolbar : "ORGRCB_T_RSTR",
    ArchiveActionDeliveredToolbar: "ORGDLVRY_T_ARCV",

    ReportProblemAppHeader: "ORGAPP_H_RPTPRO",
    ReportProblemOrganizerInProcessProformaGrid: "ORGINPRO_G_RPPFM",
    ReportProblemOrganizerInProcessBatchGrid: "ORGINPRO_G_RPBTH",
    ReportProblemDeliveredOrganizer: "ORGDLVRY_G_RPTPRO",
    ReportProblemArchivedOrganizer: "ORGARC_G_RPTPRO",

    ExportToExcelOrganizerInProcessProformaToolBar : "ORGINPRO_T_ETXLPFM",
    ExportToExcelOrganizerInProcessBatchToolBar : "ORGINPRO_T_ETXLBTH",
    ExportToExcelDeliveredOrganizerToolBar : "ORGDLVRY_T_ETXL",
    ExportToExcelUndeliveredBatchToolBar : "ORGUDB_T_ETXL",
    ExportToExcelArchivedOrganizerToolBar : "ORGARC_T_ETXL",

    DeliveredOrganizerGirdClientViewBtn : "ORGDLVRY_G_CLIVW",
    ArchivedOrganizerGirdClientViewBtn : "ORGARC_G_CLIVW",

    OrganizerInProcessGirdEditClientBtnProforma : "ORGINPRO_G_EDTCLIPFM",
    OrganizerInProcessGirdEditClientBtnBatch : "ORGINPRO_G_EDTCLIBTH",
    DeliveredOrganizerGirdEditClientBtn : "ORGDLVRY_G_EDTCLI",

    DeliveredOrganizerGirdClientTrackingBtn : "ORGDLVRY_G_CLITRK",
    ArchivedOrganizerGirdClientTrackingBtn : "ORGARC_G_CLITRK",

    ClosedByFirmDeliveredGrid:"ORGDLVRY_G_CLSBFIRM",
    ClosedByFirmDeliveredToolbar:"ORGDLVRY_T_CLSBFIRM",
    ClosedByFirmArchiveGrid:"ORGARC_G_CLSBFIRM",
    ClosedByFirmArchiveToolbar:"ORGARC_T_CLSBFIRM",

}

export const RbacPermission = {
    OperationDenied: "You do not have permission to perform this operation,\nplease contact your system administrator for more\ninformation.",
    PageDenied: "You do not have permissions to access this page, please contact your system administrator for more information."
}
