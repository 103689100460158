import * as React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { LogoutCause } from '../../Organizer/components/SignalR/SignalRWebSocket';
import { clearAllTemporaryFilter } from '../reports/Filters';
import { SessionStore } from '../helper/HelperFunctions';
import store from '../../configureStore';
import { actionTypes } from '../../store/ActionTypes';
import { signoutRedirect } from 'src/oidcClient/userService'

interface UserAutoLogoutModalProps {
    openModal: boolean;
    logoutCause: LogoutCause;
    resetUserCache?: any
}

export const UserAutoLogoutModal: React.FC<UserAutoLogoutModalProps> = props => {
    
    const onCloseModal = (): void => {
        props.resetUserCache();
        store.dispatch({ type: actionTypes.USER_PRIVILEGE_CHANGED, payload: LogoutCause.None });
        clearAllTemporaryFilter();
        SessionStore.remove("isVisited");
        signoutRedirect();
    }

    return (
        <div className="margin-tbl-20">
            <Modal show={props.openModal} onHide={onCloseModal} className="rolechange-notification-modal" backdrop="static">
                <Modal.Header>
                    <Modal.Title className='custom-modal-header'>
                        <h3 className='user-autologout-modal-title'>Permission Changed</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Row>
                            <Col lg={12}>
                                <Row className="user-autologout-modal-content">
                                    Your SafeSend permissions have been changed. You must login again for the changes to take effect.
                                </Row>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="user-autologout-modal-footer-btn"
                        variant="primary"
                        onClick={onCloseModal}>
                        Login
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
