import * as fetchIntercept from 'fetch-intercept';
import { VenusNotifier } from '../../components/helper/VenusNotifier';
import * as Loader from '../../components/helper/Loader';
import { LogoutCause } from '../../Organizer/components/SignalR/SignalRWebSocket';
import store from '../../configureStore';
import { actionTypes } from '../../store/ActionTypes';
import { actionTypes as leftMenuActionTypes } from '../../store/productMenu/ActionTypes/ActionTypes';
import * as CRSHelper from '../../components/helper/CRSHelper';
import history from '../../routes/history'

export const unregister = fetchIntercept.register({
    request: function (url, config) {
        if (url.includes('/client/negotiate?hub=notificationhub')) {
            // If it is, proceed with the original fetch without adding Bearer token
            return [url, config];
        }

        let cookieValue;
        let name = 'XSRF-TOKEN';
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = CRSHelper.encode(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        const user = store.getState()?.auth?.user;
        const configDefaults = {
            ...config,
            headers: {
                ...(config?.credentials === 'include' && {
                    'X-Requested-With': 'XMLHttpRequest',
                    'Authorization': `Bearer ${user?.access_token}`,
                    //'X-XSRF-TOKEN': cookieValue
                }),
            }
        }
        const headerEntries = config?.headers?.entries?.() //temporary fix for bug: 67699
        if (headerEntries) {
            for (var pair of headerEntries) {
                configDefaults.headers[pair[0]] = pair[1]
            }
        }
        const correctedUrl = url.replace(/([^:]\/)\/+/g, "$1"); //code added for removing extra slash from url before api
        return [correctedUrl, configDefaults];
    },
    response: function (response: any) {
        if (response.status == 401) {
            JSON.parse(response.clone()).then((response: any) => {
                Loader.HideLoader();
                VenusNotifier.Info(response.message, "");
                window.location.reload();
            });
            return response;
        }
        else if (response.status == 512){
            store.dispatch({ type: leftMenuActionTypes.REQUEST_DISABLE_LEFTMENU });
            history.push('/restricted-access')
        }
        else if (response.status == 419) {
            const isSkipable = skipStoreUpdateUrls.filter(x => response.url.includes(x)).length > 0;
            if (!isSkipable) {
                const user = store.getState().userPrivilege;
                if (!user?.userPrivilegesChanged) {
                    response.clone()?.json().then((response: any) => {
                        store.dispatch({ type: actionTypes.USER_PRIVILEGE_CHANGED, payload: response.logoutCause as LogoutCause });
                    });
                }
            }
            Loader.HideLoader();
            return Promise.reject(response);
        } else {
            return response;
        }
    }
});

const skipStoreUpdateUrls = [
    'api/ApplicationInsights',
    'api/Organizer/CompanyProductStatus/GetCompanyProductStatus',
    'api/user-management/reset-user-status-cache',
    'api/WebSocket/GetWebSocketConnectionUrl'
];