import { connect } from 'react-redux';
import * as Component from './SavedMessages';
import * as CompanySettings from './../../../store/OrganizerCompanySettingsStore';
import { ApplicationState } from '../../../../store';
import * as SavedMessages from './../../../store/OrganizerPreparerMessageStore';

export default connect(
    (state: ApplicationState) => ({
        companySettingStore: state.organizerCompanySettings,
        preparerMessageStore: state.organizerPreparerMessage,
    }),
    {
        ...CompanySettings.ActionCreators,
        ...SavedMessages.actionCreators,
    }
)(Component.SavedMessages);