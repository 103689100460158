import React, { FC, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IExistingClientManagementModalProps, existingClientDataComparisonModalConstants } from './existingClientDataComparisonModal.model';
import CloseIcon from 'src/components/svg/CloseIcon';
import { phoneNumberDisplay } from 'src/components/helper/HelperFunctions'
import { columnNames, IClientInfoMismatchViewModel, rowNames, mismatchDataPopupOrder } from "../clientManagement.types";
import "../clientManagement.styles.scss";

const ExistingClientDataComparisonModal: FC<IExistingClientManagementModalProps> = props => {
    const {
        onClose,
        onCancel,
        onUpdate,
        clientInfoComparisonResult,
        partners
    } = props;
    
    let rows = Object.keys(clientInfoComparisonResult.clientInfoResultData);
    rows = mismatchDataPopupOrder.filter(order => rows.includes(order));

    const [saving, setSaving] = useState<boolean>(false);

    const handleClientInfoUpdate = () => {
        setSaving(true);
        onUpdate();
    }
    const handleClientInfoCancel = () => onCancel();

    const handleClose = () => onClose();

    const getRowData = (rowName: string, value : any) => {
        var rowCellValue = '';
        
        switch (rowName) {
            case rowNames.mobileNumber:
            case rowNames.spouseMobileNumber:
                if (value)
                    rowCellValue = phoneNumberDisplay(value);
                break;
            case rowNames.isDeceased:
            case rowNames.spouseIsDeceased:
                rowCellValue = value && (value == true || value == 'True') ? 'Yes': 'No';
                break;
            case rowNames.ero:
                const ero = value && partners && partners.find(partnerUser => partnerUser.id === Number(value));
                rowCellValue = `${ero?.firstName} ${ero?.lastName}`;
                break;           
            default:
                rowCellValue = value;
        }
        
        return rowCellValue;
    }

    const shouldDisplayRow = (rowName: string, data: IClientInfoMismatchViewModel) =>
    {
        var isDisplay = false;
        if (data[rowName] && rowNames[rowName]) {
            if (rowNames[rowName] == rowNames.clientId || rowNames[rowName] == rowNames.name) {
                isDisplay = true;
            }
            else if (data[rowName].hadDifference) {
                isDisplay = true;
            }
        }
        return isDisplay;
    }
    return (
        <Modal show={true} onHide={handleClose} className="modalClass width720 clientManagementModal">
            <Modal.Header>
                <div>
                    <Modal.Title>{existingClientDataComparisonModalConstants.title}</Modal.Title>
                </div>
                <div className="closeIcon" onClick={handleClose}>
                    <CloseIcon />
                </div>
            </Modal.Header>
            <Modal.Body className="show-grid">
                <div className="mulishFont">
                    {existingClientDataComparisonModalConstants.headerMessage}
                </div>
                <div>
                    <table className="table table-striped clientsListTable mulishFont">
                        <thead className="table-header">
                            <tr>
                                <th className="width70">{columnNames.clientInformation}</th>
                                <th className="widthAuto">
                                    {columnNames.organizer}
                                </th>
                                <th className="widthAuto">
                                    {columnNames.clientManagement}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rows.map((row, index) => {
                                    const data = clientInfoComparisonResult.clientInfoResultData;
                                    return shouldDisplayRow(row, data) ? (
                                        <tr key={index} className="clientRow">
                                            <td className="grayColor">{rowNames[row]}</td>
                                            <td className={`${data[row].hadDifference ? "difference" : ""}`}>
                                                {getRowData(rowNames[row], data[row].taxReturnValue)}
                                            </td>
                                            <td className={`${data[row].hadDifference ? "difference" : ""}`}>
                                                {getRowData(rowNames[row], data[row].clientManagementValue)}
                                            </td>
                                        </tr>
                                    ) : <React.Fragment key={index}></React.Fragment>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" data-test-auto='EB94B2B4-766F-481D-8955-02679B567CA4' onClick={handleClientInfoCancel}>
                    {existingClientDataComparisonModalConstants.cancelButtonText}
                </Button>
                <Button
                    variant="primary"
                    onClick={handleClientInfoUpdate}
                    data-test-auto='FC6963E6-7498-4BE7-9FCA-CF992A76974D'
                    disabled={saving} 
                >
                    {existingClientDataComparisonModalConstants.saveButtonText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default ExistingClientDataComparisonModal;
