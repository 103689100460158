import { addTask } from 'domain-task';
import { Reducer, Action } from "redux";
import { AppThunkAction } from './../../store';
import { actionTypes } from './../../store/ActionTypes';
import { handleResponse } from './../../store/Library';
import { actionTypes as rbacActionTypes } from "./../../store/roleBasedAccessControl/ActionTypes/ActionTypes";
import { CompletedRbacResourceList, ReceivedRbacResourceList, RequestRbacResourceList, RequestCacheReset, CompletedCacheReset, RbacInitialState, apiPrefix } from './model/RoleBasedAccessControlStoreModel';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import { IRbacState } from './model/RoleBasedAccessControlStoreModel';
import { Product } from 'src/Core/ViewModels/User/UserViewModel';
import { ResourceValidator } from '@sssuite-component-ui/resource-id-validator';
import { RbacPermission } from '../../components/helper/Rbac/RbacConstants';

export type DispatchActions = RequestRbacResourceList |
    ReceivedRbacResourceList |
    CompletedRbacResourceList | RequestCacheReset | CompletedCacheReset;

type KnownActions = DispatchActions | NotificationAction;

export let validator: ResourceValidator;

export const actionCreators = {
    getRbacResourceList: (): AppThunkAction<KnownActions> =>
        (dispatch, getState) => {
            dispatch({
                type: rbacActionTypes.REQUEST_RBAC_RESOURCE_LIST,
            });

            let fetchTask = fetch(`${apiPrefix}resource/${Product.Organizers}`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(response => response as string[])
                .then((data) => {
                    dispatch({
                        type: rbacActionTypes.RECEIVED_RBAC_RESOURCE_LIST,
                        payload: data
                    });
                    validator = new ResourceValidator(data || [], "disable", "click", RbacPermission.OperationDenied);
                    validator.start();
                })
                .catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                })
                .finally(() => {
                    dispatch({
                        type: rbacActionTypes.COMPLETED_RBAC_RESOURCE_LIST,
                    });
                });
            addTask(fetchTask);
        },
resetUserCache: (): AppThunkAction<KnownActions> =>
    (dispatch, getState) => {
        dispatch({
            type: rbacActionTypes.REQUEST_CACHE_RESET,
        });

        let fetchTask = fetch(`${apiPrefix}user-management/reset-user-status-cache/4`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(response => response as string[])
            .then((data) => {
                dispatch({
                    type: rbacActionTypes.COMPLETED_CACHE_RESET,
                });
            })
            .catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            })
            .finally(() => {
                dispatch({
                    type: rbacActionTypes.COMPLETED_CACHE_RESET,
                });
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<IRbacState> = (
    (rbacState: IRbacState = RbacInitialState, incomingAction: Action) => {
        const action = incomingAction as DispatchActions;
        switch (action.type) {
            case rbacActionTypes.REQUEST_RBAC_RESOURCE_LIST:
                rbacState.isLoading = true;
                return rbacState;
            case rbacActionTypes.RECEIVED_RBAC_RESOURCE_LIST:
                rbacState.resourceList = action.payload;
                return rbacState;
            case rbacActionTypes.COMPLETED_RBAC_RESOURCE_LIST:
                rbacState.isLoading = false;
                return rbacState;
            default:
                return rbacState;
        }
    }
);