import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { AddIcon } from "src/assets/Icon";
import Template from "./Template";
import {
    ClearFormData,
    SaveTemplateModalData,
    SetIsInvalidFlag,
    getCustomQuestionSettings,
    getFormData
} from "src/store/settings/FormBuilder/Actions";
import { ModalType, TemplateModal } from "../../Reports/Modals/TemplateModal";
import { useDispatch, useSelector } from "react-redux";
import FormBuilder from "./FormBuilder/FormBuilder";
import { actionCreators as CustomQuestionsActionCreators } from "src/store/settings/CustomQuestions/CustomQuestionsStore";
import { ApplicationState } from "src/store";
import * as bootbox from "bootbox";
import { FormBuilderConstants, TemplateModalConstants } from "src/components/helper/Constants";
import { Loader, LoadingOverlay } from "react-overlay-loader";
import Preview from "./Preview/Preview";
import { IntialiseCqPreview } from "src/store/settings/CqPreview/Actions";
import { getTemplateLimitErrorMessage, getTotalQuestions } from "src/components/helper/HelperFunctions";
import { actionTypes as NotificationAction } from "./../../../../store/ActionTypes";
import { StatusType } from "src/store/common/NotificationStore";
import { CreateTemplateIcon } from "src/assets/SvgIconCollection";
import { DeleteModal } from "../../Reports/Modals/DeleteModal";
import { QuestionType } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
type CustomQuestionsProps = {
    templates: any;
};

type TemplateData = {
    modalType: number;
    modalData: any;
    showModal: boolean;
};

const CustomQuestionsTemplateList: React.FC<CustomQuestionsProps> = (props) => {
    const dispatch = useDispatch();
    const [templateModal, setTemplateModal] = React.useState<TemplateData>({
        modalType: ModalType.Template,
        modalData: null,
        showModal: false
    });
    const [showFormBuilder, setShowFormBuilder] = React.useState<boolean>(false);
    const [showPreview, setShowPreview] = React.useState<boolean>(false);
    const [isCreateTemplateForm, setIsCreateTemplateForm] = React.useState<boolean>(true);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isRedirectedFromPreviewTab, setIsRedirectedFromPreviewTab] = React.useState<boolean>(false);
    const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState<any>({ show: false, templateId: 0 });

    let customQuestions: any = useSelector((state: ApplicationState) => state.customQuestions.customQuestions);
    const {
        isLoading: formDataLoader,
        formData,
        customQuestionSettings
    } = useSelector((state: ApplicationState) => state.formBuilder);
    React.useEffect(() => {
        setIsLoading(true);
        dispatch(CustomQuestionsActionCreators.getCustomQuestions(onGetCustomQuestionsCallback, onFailureCallback));
        dispatch(getCustomQuestionSettings());
        return () => {
            localStorage.removeItem('updatedFormData');
            localStorage.removeItem('formData');
        };
    }, []);

    const setDefaultTemplate = (selectedTemplateId: number, isDefault: boolean) => {
        setIsLoading(true);
        dispatch(
            CustomQuestionsActionCreators.setDefaultCustomQuestionTemplate(
                selectedTemplateId,
                isDefault,
                onSetDefaultTemplateCallback,
                onFailureCallback
            )
        );
    };

    const onTemplateClick = (template) => {
        dispatch(getFormData(template?.id, onEditCallback));
        dispatch(ClearFormData());
    };

    const onMenuItemClick = (template, index) => {
        switch (index) {
            case 0:
                dispatch(getFormData(template?.id, onEditCallback));
                dispatch(ClearFormData());
                break;
            case 1:
                setTemplateModal({ modalType: ModalType.Questionaire, modalData: { ...template }, showModal: true });
                break;
            case 2:
                bootbox.confirm({
                    title: TemplateModalConstants.DeleteTitle,
                    message: template.isDefault
                        ? TemplateModalConstants.DeleteDefaultTemplateConfirmation
                        : TemplateModalConstants.DeleteConfirmation,
                    buttons: {
                        cancel: {
                            label: '<i data-test-auto="ce75f59f-99fc-48b9-b058-068367b76996" class="fas fa-times"></i> Cancel',
                            className: "btn-white btn-default"
                        },
                        confirm: {
                            label: '<i data-test-auto="1b2945ce-1cbd-4ffe-9520-6d89cd179822" class="fas fa-check"></i> Confirm',
                            className: "btn-info"
                        }
                    },
                    callback: (result: boolean) => {
                        if (result) {
                            setIsLoading(true);
                            dispatch(
                                CustomQuestionsActionCreators.getHasTemplateIsInUseDetails(template.id, getHasTemplateIsInUseDetailsCallback, onFailureCallback)
                            );
                        }
                    }
                });
                break;
            default:
                break;
        }
    };
    const onConfirmDeleteSection = () => {
        deleteTemplate(showDeleteConfirmModal.templateId);
        setShowDeleteConfirmModal({ show: false, templateId: 0 });
    };

    const deleteTemplate = (templateId: number) => {
        setIsLoading(true);
        dispatch(
            CustomQuestionsActionCreators.deleteCustomQuestion(
                templateId,
                onDeleteCallback,
                onFailureCallback
            )
        );
    }
    const getHasTemplateIsInUseDetailsCallback = (data) => {
        setIsLoading(false);
        if (data.isTemplateInUse) {
            setShowDeleteConfirmModal({ show: true, templateId: data.templateId });
        }
        else {
            deleteTemplate(data.templateId);
        }
    }
    const onGetCustomQuestionsCallback = () => {
        setIsLoading(false);
    };
    const onEditCallback = () => {
        setIsCreateTemplateForm(false);
        setShowFormBuilder(true);
    };
    const onFailureCallback = () => {
        setIsLoading(false);
    };
    const redirectToTemplateListing = () => {
        setShowFormBuilder(false);
        setIsCreateTemplateForm(true);
        dispatch(CustomQuestionsActionCreators.getCustomQuestions(onGetCustomQuestionsCallback, onFailureCallback));
    };

    const onDeleteCallback = () => {
        dispatch(CustomQuestionsActionCreators.getCustomQuestions(onGetCustomQuestionsCallback, onFailureCallback));
    };
    const onRenameCallback = () => {
        dispatch(CustomQuestionsActionCreators.getCustomQuestions(onGetCustomQuestionsCallback, onFailureCallback));
    };
    const onSetDefaultTemplateCallback = () => {
        dispatch(CustomQuestionsActionCreators.getCustomQuestions(onGetCustomQuestionsCallback, onFailureCallback));
    };

    const onConfirmClick = (data: any) => {
        setIsLoading(true);
        if (templateModal.modalData) {
            let modalData: any = templateModal.modalData;
            modalData.name = data.name;
            modalData.description = data.description || "";
            dispatch(CustomQuestionsActionCreators.updateCustomQuestion(modalData, onRenameCallback, onFailureCallback));
            setTemplateModal({ modalType: ModalType.Template, modalData: null, showModal: false });
        } else {
            setTemplateModal({ modalType: ModalType.Questionaire, modalData: null, showModal: false });
            setShowFormBuilder(true);
            let templateData: any = data;
            templateData.name = data.name;
            templateData.description = data.description || "";
            dispatch(SaveTemplateModalData(templateData));
        }
    };
    const onModalClose = (initialModalData: any) => {
        setTemplateModal({
            modalType: ModalType.Template,
            modalData: initialModalData ? { name: "", description: "" } : null,
            showModal: false
        });
    };

    const onCreateNewClick = () => {
        if (customQuestions.length < customQuestionSettings.templateLimit) {
            dispatch(ClearFormData());
            setTemplateModal({ modalType: ModalType.Questionaire, modalData: null, showModal: true });
            setIsCreateTemplateForm(true);
        } else {
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: getTemplateLimitErrorMessage(customQuestionSettings.templateLimit),
                statusType: StatusType.Error
            });
        }
    };

    const onClickPreview = () => {
        if (getTotalQuestions(formData) > 0) {
            if (isFormDataValid()) {
                setShowPreview(true);
                dispatch(IntialiseCqPreview(formData));
            }
        }
    };
    const onClickPreviewClose = () => {
        setShowPreview(false);
        setIsRedirectedFromPreviewTab(true);
    };
    const resetIsComingFromPreviewTab = () =>{
        setIsRedirectedFromPreviewTab(false);
    }
    const isFormDataValid = () => {
        let isValid = true;
        let hasDuplicateChoice = false;
        formData.sections.forEach((section) =>
            section.sectionItems.forEach((sectionItem) => {
                if (sectionItem.questionTitle === "") {
                    isValid = false;
                }
            })
        );
        formData.sections.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                let choiceTexts : string[] = []; 
                sectionItem.choices.forEach((choice) => {
                    if (choice.text === "") {
                        isValid = false;
                    }
                    else{
                        let choiceText = choice.text.toLowerCase();
                        if(choiceTexts.includes(choiceText))
                        {
                            hasDuplicateChoice = true;
                        }
                        choiceTexts.push(choiceText);
                    }
                });
            });
        });
        formData.sections.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                sectionItem.choices.forEach((choice) => {
                    if (choice.subQuestions && choice.subQuestions.length > 0) {
                        choice.subQuestions.forEach((subQuestion) => {
                            if (subQuestion.questionTitle === "") {
                                isValid = false;
                            }
                        });
                    }
                });
            });
        });
        formData.sections.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                sectionItem.choices.forEach((choice) => {
                    if (choice.subQuestions && choice.subQuestions.length > 0) {
                        choice.subQuestions.forEach((subQuestion) => {
                            if (subQuestion.questionTypeId === QuestionType.MULTICHOICE) {
                                let choiceTexts : string[] = []; 
                                subQuestion.choices.forEach((subQuestionChoice) => {
                                    if (subQuestionChoice.text === "") {
                                        isValid = false;
                                    }
                                    else{
                                        let subQuestionChoiceText = subQuestionChoice.text.toLowerCase();
                                        if(choiceTexts.includes(subQuestionChoiceText))
                                        {
                                            hasDuplicateChoice = true;
                                        }
                                        choiceTexts.push(subQuestionChoiceText);
                                    }
                                });
                            }
                        });
                    }
                });
            });
        });
        if (!isValid || hasDuplicateChoice) {
            dispatch(SetIsInvalidFlag());
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: FormBuilderConstants.FORM_VALIDATION_ERROR,
                statusType: StatusType.Error
            });
        }

        return isValid && !hasDuplicateChoice;
    };
    return (
        <>
            {!showFormBuilder ? (
                <div>
                    <div>
                        <Row style={{ marginTop: "5px" }}>
                            <Col>
                                <div className="custom-questions-page-title">
                                    <h3 className="questionHeading">{TemplateModalConstants.RecentQuestionnaires}</h3>
                                    <hr className="horizontal-divider" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <LoadingOverlay>
                        <div className="templates-list">
                            {customQuestions?.map((template) => {
                                return (
                                    <Template
                                        data={template}
                                        onTemplateClick={onTemplateClick}
                                        onMenuClick={onMenuItemClick}
                                        setDefaultTemplate={setDefaultTemplate}
                                    />
                                );
                            })}
                           <div className="template-container">
                                <div onClick={onCreateNewClick} className="create-new-card-container template">
                                    <CreateTemplateIcon />
                                    <span>Create New Template</span>
                                </div>
                                <div className="template-details">
                                    <label className="template-name">{TemplateModalConstants.StartFromScratch}</label>
                                    <label className="template-description">
                                        {TemplateModalConstants.BuildNewQuestionnaire} <br />
                                        {TemplateModalConstants.WithYourCustomQuestions}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Loader loading={isLoading || formDataLoader} />
                    </LoadingOverlay>
                </div>
            ) : !showPreview ? (
                <FormBuilder
                    onClickPreview={onClickPreview}
                    redirectToTemplateListing={redirectToTemplateListing}
                    isCreateTemplateForm={isCreateTemplateForm}
                    isRedirectedFromPreviewTab={isRedirectedFromPreviewTab}
                    resetIsRedirectedFromPreviewTab={resetIsComingFromPreviewTab}
                />
            ) : (
                <Preview onClickPreviewClose={onClickPreviewClose} />
            )}
            {templateModal.showModal && (
                <TemplateModal
                    modalType={templateModal.modalType}
                    intialModalData={templateModal.modalData}
                    show={templateModal.showModal}
                    onConfirm={onConfirmClick}
                    onClose={onModalClose}
                />
            )}
            {showDeleteConfirmModal.show && (
                <DeleteModal
                    show={showDeleteConfirmModal.show}
                    modalHeader={TemplateModalConstants.DeleteTemplateInUse}
                    modalBody={
                        <div>
                            <p>{TemplateModalConstants.DeleteConfirmationForTemplateInUse}</p>
                            <div>
                                <label className="delete-confirmation-note">Note: </label>
                                {TemplateModalConstants.DeleteConfirmationNote}
                            </div>
                        </div>
                    }
                    onClose={() => setShowDeleteConfirmModal(false)}
                    onConfirm={onConfirmDeleteSection}
                />
            )}
        </>
    );
};
export default CustomQuestionsTemplateList;
