import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UserManagementConstants, DeliveredReturnsConstants, ValidationContants } from '../../../../components/helper/Constants';
import { VenusNotifier } from '../../../../components/helper/VenusNotifier';
import DownloadTypeOption from '../../../../Organizer/components/Reports/Modals/DownloadTypeOption';
import { ApplicationState } from '../../../../store';
import { DownloadType, EngagementLetterStatus, OrganizerClient, OrganizerDocument, OrganizerStatus, SignatureStatus, SourceDocumentStatus } from '../../../models/OrganizerComponentModels';
import { downloadQueryFilters } from '../../../models/OrganizerStoreModels';
import { actionCreators } from '../../../store/DeliveredOrganizerStore';
import { ActionCreators as DownloadOrganizerStore } from '../../../store/DownloadOrganizerStore';
import { actionCreators as DeliveredOrganizerStore } from '../../../store/DeliveredOrganizerStore';
import { TaxSoftwareType } from '../.././../../Core/ViewModels/Company/CompanySettingsViewModel';
import ChangeStatusModal from '../../../../Organizer/components/Reports/Modals/ChangeStatusModal';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";
import { ReopenOrganizerGreyIcon } from 'src/assets/SvgIconCollection';
import { ShowLoader, HideLoader } from './../../../../components/helper/Loader';

interface OrganizerDeliveredReportToolbarProps {
    pageTitle: string;
    onSendReminder: (rowIndex: number) => void;
    selectedDocumentCount: number;
    taxSoftwares: TaxSoftwareType[];
    onUploadClick: (value: TaxSoftwareType) => void;
    selectedUploadTypeId: number;
    onChangeUploadType: (selected: any) => void;
    selectedRows: number[];
    documents: OrganizerDocument[];
    isBulkSelectionEnabled: boolean;
    filterClientId: string;
    filterDeliveredOn: Date | undefined;
    filterEngagementLetterStatus: any;
    filterOrganizerStatus: any;
    filterSourceDocumentStatus: any;
    filterOrganizerReminder: any;
    filterSigningReminder: any;
    filterTaxpayerName: string;
    filterTaxYear: any;
    filterEro: string;
    filterSentBy: string;
    filterBatchName: string;
    filterTaxpayerEmail: string;
    filterSpouseEmail: string;
    filterArchivedBy: string;
    deselectedRows: number[];
    // onMultipleDownloadEfileForms: () => void,
    updateStatusToManual: (updateEngagementLetter: boolean, updateOrganizer: boolean) => void;
    isArchived: boolean;
    onRestore: (rowIndex: number) => void;
    onDelete: () => void;
    onArchive: () => void;
    onCloseOpenByFirm: () => void;
}
const NO_INDEX = -1;
const toaster_message_css = 'toaster_message';
var btnStyle = {
    minWidth: '104px',
    marginRight: '5px'
};

const OrganizerDeliveredReportToolbar: React.FC<OrganizerDeliveredReportToolbarProps> = ({
    onSendReminder, pageTitle, selectedDocumentCount,
    selectedRows, documents, filterClientId, filterDeliveredOn, filterEngagementLetterStatus,
    filterOrganizerStatus, filterSourceDocumentStatus,
    filterTaxpayerName, filterTaxYear, filterEro, filterSentBy, deselectedRows,
    isBulkSelectionEnabled, updateStatusToManual, isArchived, onRestore, onDelete,
    onArchive, onCloseOpenByFirm, filterArchivedBy, filterBatchName,
    filterOrganizerReminder, filterSigningReminder,
    filterTaxpayerEmail, filterSpouseEmail }) => {

    const dispatch = useDispatch();
    const userSettings = useSelector((state: ApplicationState) => state.userSettings);
    const companySettings = useSelector((state: ApplicationState) => state.organizerCompanySettings.companySettings)
    const loading = useSelector((state: ApplicationState) => state.organizerReports.loading);
    const [isPromptEveryTimeModalOpen, setIsPromptEveryTimeModalOpen] = React.useState(false);
    const [selectedDownloadType, setSelectedDownloadType] = React.useState(DownloadType.DownloadToMyDownloads);
    const [isPromptSaved, setIsPromptSaved] = React.useState(false);
    const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = React.useState(false);
    const [isEngagementLetterStatusSelected, setIsEngagementLetterStatusSelected] = React.useState(false);
    const [isOrganizerStatusSelected, setIsOrganizerStatusSelected] = React.useState(false);

    const getCurrentSelectedDocuments = () => {
        return selectedRows.map((row) => {
            return documents[row];
        })
    }

    const errorOnDownloadForEngagementLetter = (engagementLetterStatus: EngagementLetterStatus) => {
        return engagementLetterStatus === EngagementLetterStatus.AwaitingSignature;
    };

    const errorOnDownloadForOrganizer = (organizerStatus: OrganizerStatus) => {
        return organizerStatus === OrganizerStatus.Delivered;
    }

    const errorOnDownloadForSourceDocument = (sourceDocumentStatus: SourceDocumentStatus) => {
        return sourceDocumentStatus === SourceDocumentStatus.AwaitingUpload;
    }

    const DownloadErrorMessage = () => {
        VenusNotifier.Error(<>{UserManagementConstants.invalidOrganizerDownload}
            <ul>
                <li style={{ marginLeft: "40px" }}>{UserManagementConstants.DownloadElStatus}</li>
                <li style={{ marginLeft: "40px" }}>{UserManagementConstants.DownloadOrganizerStatus}</li>
                <li style={{ marginLeft: "40px" }}>{UserManagementConstants.DownloadSourceDocumentStatus}</li>
            </ul>
        </>, null, toaster_message_css)
    }
    const downloadEformHandler = () => {
        const currentDocument = getCurrentSelectedDocuments();
        const isDocumentDownloadInvalid = currentDocument.some((doc) => {
            if (errorOnDownloadForEngagementLetter(doc.engagementLetterStatus)) {
                return true;
            } else if (errorOnDownloadForSourceDocument(doc.sourceDocumentStatus) && companySettings.sourceDocumentSettings.enabledSourceDocuments) {
                return true;
            } else if (errorOnDownloadForOrganizer(doc.organizerStatus)) {
                return true;
            }
        })
        if (isDocumentDownloadInvalid) {
            // VenusNotifier.Error(UserManagementConstants.invalidOrganizerDownload, null)
            DownloadErrorMessage()
        } else {

            const selectedIds = currentDocument.map(doc => doc.id)

            const queryObj: downloadQueryFilters = {
                clientId: filterClientId,
                deliveredOn: filterDeliveredOn,
                engLetterStatus: filterEngagementLetterStatus,
                organizerStatus: filterOrganizerStatus,
                sourceDocStatus: filterSourceDocumentStatus,
                organizerReminder: filterOrganizerReminder,
                signingReminder: filterSigningReminder,
                taxPayerName: filterTaxpayerName,
                taxYear: filterTaxYear,
                ero: filterEro,
                sentBy: filterSentBy,
                deselectedIds: isBulkSelectionEnabled ? deselectedRows : [],
                archivedBy: filterArchivedBy,
                isArchived: isArchived,
                batchName: filterBatchName,
                taxpayerEmail: filterTaxpayerEmail,
                spouseEmail: filterSpouseEmail
            }

            if (currentDocument.length === 1) {
                const doc = currentDocument[0];
                queryObj.clientId = doc.clientId;
                queryObj.taxYear = doc.taxYear.toString();
            }

            
            if (selectedDownloadType === DownloadType.PromptMeEveryTime) {
                if (currentDocument.length === 1) {
                    ShowLoader();
                    const doc = currentDocument[0];
                    let filenames: string[] = [];
                    if (doc?.downloadDocuments?.length > 0) {
                        doc.downloadDocuments.map((d) => {
                            filenames.push(d.fileName)
                        })
                    }
                    const logELTracked = IsEngagementLetterDownloadTracked(doc.engagementLetterStatus);
                    const logOrganizerDownload = IsOrganizerDownloadTracked(doc.organizerStatus);
                    dispatch(actionCreators.globalDownloadAll(isArchived ? "Download/Archived/GlobalDownloadAll" : "Download/Delivered/GlobalDownloadAll", doc.id,
                        doc.processInfoGuid,
                        doc.taxYear,
                        doc.clientId,
                        logOrganizerDownload,
                        logELTracked,
                        filenames,
                        () => {
                            HideLoader();
                        }
                    ))
                    VenusNotifier.Success(UserManagementConstants.downloadNowWaitProcessing, null);
                } else {
                    setIsPromptEveryTimeModalOpen(true);
                }
            } else if (selectedDownloadType === DownloadType.DownloadToMyDownloads) {
                ShowLoader();
                if (isBulkSelectionEnabled) {
                    dispatch(DownloadOrganizerStore.downloadBulkOrganizerAsync(isArchived ? "BulkDownload/Archived/DownloadArchivedBulkOrganizerAsync" : "BulkDownload/Delivered/DownloadDeliveredBulkOrganizerAsync", queryObj, selectedDownloadType, () => { HideLoader(); }));
                } else {
                    dispatch(DownloadOrganizerStore.downloadOrganizerAsync(isArchived ? "BulkDownload/Archived/DownloadArchivedOrganizerAsync" : "BulkDownload/Delivered/DownloadDeliveredOrganizerAsync", queryObj, selectedIds, selectedDownloadType, () => { HideLoader(); }));
                    VenusNotifier.Success(UserManagementConstants.downloadWaitProcessing, null);
                }
                setSelectedDownloadType(userSettings.settings.defaultUserSettings.download.downloadType)
            } else if (selectedDownloadType === DownloadType.DownloadNow) {
                ShowLoader();
                if (isBulkSelectionEnabled) {
                    dispatch(DownloadOrganizerStore.downloadBulkOrganizerAsync(isArchived ? "BulkDownload/Archived/DownloadArchivedBulkOrganizerAsync" : "BulkDownload/Delivered/DownloadDeliveredBulkOrganizerAsync", queryObj, selectedDownloadType, () => { HideLoader(); }));
                } else {
                    dispatch(DownloadOrganizerStore.downloadOrganizerAsync(isArchived ? "BulkDownload/Archived/DownloadArchivedOrganizerAsync" : "BulkDownload/Delivered/DownloadDeliveredOrganizerAsync", queryObj, selectedIds, selectedDownloadType, () => { HideLoader(); }));
                    VenusNotifier.Success(UserManagementConstants.downloadNowWaitProcessing, null);
                }
                setSelectedDownloadType(userSettings.settings.defaultUserSettings.download.downloadType)
            }
        }
        setSelectedDownloadType(userSettings.settings.defaultUserSettings.download.downloadType)
    }

    const IsEngagementLetterDownloadTracked = (engagementLetterStatus: EngagementLetterStatus): boolean => {

        return engagementLetterStatus === EngagementLetterStatus.EngagementLetterDownloaded
            || engagementLetterStatus === EngagementLetterStatus.ESigned
            || engagementLetterStatus === EngagementLetterStatus.Reviewed
            || engagementLetterStatus === EngagementLetterStatus.ManuallySigned;
    }

    const IsOrganizerDownloadTracked = (organizerStatus: OrganizerStatus): boolean => {

        return organizerStatus === OrganizerStatus.Completed
            || organizerStatus === OrganizerStatus.OrganizerDownloaded
            || organizerStatus === OrganizerStatus.ManuallyCompleted
            || organizerStatus === OrganizerStatus.QuestionnaireCompleted
            || organizerStatus === OrganizerStatus.ClosedByFirm;
    }
    const getRequestInfoCallback = (clients: OrganizerClient[]) => {
        const orgCurrentDocument = getCurrentSelectedDocuments();
        if (clients?.length > 0) {
            const client = clients?.filter(doc => doc.signingOrder = 2);

            if (orgCurrentDocument.every((doc) => {
                return (doc.engagementLetterStatus === EngagementLetterStatus.ManuallySigned
                    || doc.engagementLetterStatus === EngagementLetterStatus.ESigned
                    || doc.engagementLetterStatus === EngagementLetterStatus.EngagementLetterDownloaded
                    || (doc.signerCount == 1 && doc.engagementLetterStatus == EngagementLetterStatus.Reviewed)
                    || (doc.signerCount == 2 && doc.TPSignerStatus == SignatureStatus.Reviewed && doc.SpouseSignerStatus == SignatureStatus.Reviewed)
                    || (doc.engagementLetterStatus == EngagementLetterStatus.Reviewed && client[0].signatureStatus == SignatureStatus.NA)
                    || (doc.engagementLetterStatus == EngagementLetterStatus.Reviewed && client[1]?.signatureStatus == SignatureStatus.NA)
                    || doc.engagementLetterStatus === EngagementLetterStatus.NA)
                    && (doc.organizerStatus === OrganizerStatus.ManuallyCompleted
                        || doc.organizerStatus === OrganizerStatus.Completed
                        || doc.organizerStatus === OrganizerStatus.OrganizerDownloaded)
            })) {
                VenusNotifier.Error(DeliveredReturnsConstants.ErrorMessage.ChangeStatusError, null, toaster_message_css)
            } else {
                setIsChangeStatusModalOpen(true);
            }
        }
    }
    const getCurrentDoc = () => {
        const currentDocument = getCurrentSelectedDocuments();
        return documents?.filter(doc => doc.id == currentDocument[0]?.id)[0]?.organizerClients ?? [];
    }
    const changeStatusHandler = () => {
        const selectedDocuments = getCurrentSelectedDocuments();
        if (selectedDocuments && selectedDocuments.length > 0) {
            if (selectedDocuments.every(x => x.organizerStatus === OrganizerStatus.ClosedByFirm)) {
                VenusNotifier.Error(ValidationContants.MultiSelectWarning, null)
                return;
            }
            if (selectedDocuments.length === 1) {
                dispatch(DeliveredOrganizerStore.requestOrganizerClients(selectedDocuments[0].id, getRequestInfoCallback));
            }
            else {
                setIsChangeStatusModalOpen(true);
            }
        }
    }
    React.useEffect(() => {
        if (isPromptSaved) {
            downloadEformHandler();
            setIsPromptSaved(false);
        }
    }, [selectedDownloadType, isPromptSaved])

    React.useEffect(() => {
        if (userSettings?.settings?.defaultUserSettings?.download?.downloadType) {
            setSelectedDownloadType(userSettings.settings.defaultUserSettings.download.downloadType)
        }
    }, [userSettings])

    return (
        <div>
            <DownloadTypeOption
                show={isPromptEveryTimeModalOpen}
                onCancel={() => {
                    setIsPromptEveryTimeModalOpen((prevState) => !prevState)
                    setSelectedDownloadType(userSettings.settings.defaultUserSettings.download.downloadType)
                }}
                selectedDownloadType={selectedDownloadType}
                onDownloadOptionChange={(val) => { setSelectedDownloadType(val) }}
                onDownloadOptionSave={() => {
                    setIsPromptSaved(true)
                    setIsPromptEveryTimeModalOpen(false)
                }}
                useDefaultDownloadType={() => { }}
                selectedDefaultDownloadType={true}
            />
            <ChangeStatusModal
                show={isChangeStatusModalOpen}
                onCancel={() => {
                    setIsChangeStatusModalOpen(false);
                    setIsEngagementLetterStatusSelected(false);
                    setIsOrganizerStatusSelected(false);
                }}
                selectedDocuments={getCurrentSelectedDocuments()}
                updateEngagementLetter={isEngagementLetterStatusSelected}
                updateOrganizer={isOrganizerStatusSelected}
                onSelectEngagementLetterCheckBox={(checked: boolean) => { setIsEngagementLetterStatusSelected(checked) }}
                onSelectOrganizerCheckBox={(checked: boolean) => { setIsOrganizerStatusSelected(checked) }}
                onChangeStatusToManual={() => {
                    updateStatusToManual(isEngagementLetterStatusSelected, isOrganizerStatusSelected);
                    setIsChangeStatusModalOpen(false);
                    setIsEngagementLetterStatusSelected(false);
                    setIsOrganizerStatusSelected(false);
                }}
                requstClientInfo={getCurrentDoc()}
                loading={loading}

            />
            <div className="row marB10">
                <div className="col-md-8" style={{ marginTop: '5px' }}>
                    <h3>{pageTitle}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6" style={{ maxWidth: "100%", flex: "100%" }}>
                    {
                        !isArchived &&
                        <Button
                            variant='default'
                            className='btn btn-white'
                            data-test-auto="04CE0584-4E83-4706-AF86-2D90BC9599AB"
                            disabled={selectedDocumentCount === 0}
                            data-resource-id={ResourceIdConstants.SendReminderToolBar}
                            onClick={(e: any) => onSendReminder(NO_INDEX)} >
                            <i className='fas fa-paper-plane'></i>Send Reminder
                        </Button>
                    }
                    <Button
                        variant='default'
                        className='btn btn-white'
                        style={btnStyle}
                        data-test-auto="03F15B8A-E226-4A43-BC66-6B2FF78F211E"
                        disabled={selectedDocumentCount === 0}
                        data-resource-id={isArchived ? ResourceIdConstants.DownloadArchivedOrganizerToolBar : ResourceIdConstants.DownloadDeliveredOrganizerToolBar}
                        onClick={() => downloadEformHandler()}
                    >
                        <i className='fas fa-file-download'></i>Download
                    </Button>
                    {
                        !isArchived &&
                        <Button
                            variant='default'
                            className='btn btn-white'
                            style={btnStyle}
                            data-test-auto="93B29BD4-E547-45B6-98F5-EF814D9C1646"
                            disabled={selectedDocumentCount === 0 || isBulkSelectionEnabled}
                            data-resource-id={ResourceIdConstants.ChangeStatusDeliveredOrganizerToolBar}
                            onClick={changeStatusHandler}
                        >
                            <i className='fas fa-random'></i>Change Status
                        </Button>
                    }
                    {
                        !isArchived &&
                        <Button
                            variant='default'
                            className='btn btn-white'
                            style={btnStyle}
                            data-test-auto="93B29BD4-E547-45B6-98F5-EF814D9C1645"
                            disabled={selectedDocumentCount === 0}
                            data-resource-id={ResourceIdConstants.ArchiveActionDeliveredToolbar}
                            onClick={onArchive}
                        >
                            <i className='fas fa-archive'></i>Archive
                        </Button>
                    }
                    {
                        isArchived &&
                        <Button
                            variant='default'
                            className='btn btn-white'
                            style={btnStyle}
                            data-test-auto="93B29BD4-E547-45B6-98F5-EF814D9C1638"
                            disabled={selectedDocumentCount === 0 || isBulkSelectionEnabled}
                            data-resource-id={ResourceIdConstants.RestoreArchivedToolbar}
                            onClick={() => onRestore(NO_INDEX)}
                        >
                            <i className='fas fa-sync-alt'></i>Restore
                        </Button>
                    }
                    <Button
                        variant='default'
                        className='btn btn-white'
                        style={btnStyle}
                        data-test-auto="93B29BD4-E547-45B6-98F5-EF814D9C1639"
                        disabled={selectedDocumentCount === 0 || (isArchived && isBulkSelectionEnabled)}
                        data-resource-id={isArchived ? ResourceIdConstants.DeleteArchivedOrganizerToolBar : ResourceIdConstants.DeleteDeliveredOrganizerToolBar}
                        onClick={onDelete}
                    >
                        <i className='fas fa-trash-alt'></i>Delete
                    </Button>
                    <Button
                        variant='default'
                        className='btn btn-white'
                        style={btnStyle}
                        data-test-auto="93B29BD4-E547-45B6-98F5-EF814D9C1639"
                        data-resource-id={isArchived ? ResourceIdConstants.ClosedByFirmArchiveToolbar : ResourceIdConstants.ClosedByFirmDeliveredToolbar}
                        disabled={selectedDocumentCount === 0 || (isBulkSelectionEnabled)}
                        onClick={onCloseOpenByFirm}
                    >
                        <i className='fas' style={{ padding: "9px 5px" }}>
                            <ReopenOrganizerGreyIcon style={{ marginRight: "0px" }} width={14} height={14} />
                        </i>
                        Close/Open by Firm
                    </Button>
                </div>
            </div>
        </div>
    )
}

export { OrganizerDeliveredReportToolbar };
