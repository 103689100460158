import * as React from "react";
import { Button, Form } from "react-bootstrap";
import "./FormBuilder.css";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "src/store";
import { EyeLashIcon, StackIcon } from "src/assets/SvgIconCollection";
import { SectionModal } from "src/Organizer/components/Reports/Modals/SectionModal";
import {
    DeleteSectionData,
    EditTemplate,
    HandleDescriptionOnChange,
    HandleSectionReordering,
    HandleTitleOnChange,
    MergeSectionHandler,
    RenameSectionModalData,
    SaveSectionModalData,
    SaveTemplate,
    SetIsInvalidFlag
} from "src/store/settings/FormBuilder/Actions";
import Section from "./Parts/Section/Section";
import {
    IFormData,
    ISectionItem,
    ISectionItems,
    ISections,
    QuestionType,
    intialSectionData
} from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import "./FormBuilder.css";
import "./Button.css";
import { FormPreviewConstants, TemplateModalConstants } from "src/components/helper/Constants";
import {
    getQuestionsLimitErrorMessage,
    getTotalQuestions,
    updateSectionAndQuestionnaireOrder,
    removeUnwantedWhitespacesFromText
} from "src/components/helper/HelperFunctions";
import { FormBuilderConstants } from "src/components/helper/Constants";
import { actionTypes as NotificationAction } from "./../../../../../store/ActionTypes";
import { StatusType } from "src/store/common/NotificationStore";
import { CqAutomation } from "src/components/helper/AutomationLocators";
import { ITitleOnChangeHandlerPayload } from "src/store/settings/CqPreview/ActionTypes";
import { Prompt, RouteComponentProps } from "react-router";
import { CustomQuestionConstants } from "src/Organizer/components/Helper/OrganizerConstants";
import SectionMergeModal from "src/Organizer/components/Reports/Modals/SectionMergeModal";
import SectionMoveModal from "src/Organizer/components/Reports/Modals/SectionMoveModal";
import { CloseIcon } from "src/assets/SvgIconCollection";

type FormBuilderProps = {
    isCreateTemplateForm: boolean;
    isRedirectedFromPreviewTab: boolean;
    redirectToTemplateListing: () => void;
    onClickPreview: () => void;
    resetIsRedirectedFromPreviewTab: () => void;
} & RouteComponentProps<{}>;

const FormBuilder: React.FC<FormBuilderProps> = ({ isCreateTemplateForm, isRedirectedFromPreviewTab, redirectToTemplateListing, onClickPreview, resetIsRedirectedFromPreviewTab }) => {
    const { isLoading, formData, customQuestionSettings } = useSelector((state: ApplicationState) => state.formBuilder);
    const [localFormData, setLocalFormData] = React.useState(formData);
    const [showAddSectionModal, setShowAddSectionModal] = React.useState(false);
    const [showMergeSectionModal, setShowMergeSectionModal] = React.useState(false);
    const [showMoveSectionModal, setShowMoveSectionModal] = React.useState(false);
    const [selectedSection, setSelectedSection] = React.useState<ISections>(intialSectionData);
    const [activeSection, setActiveSection] = React.useState(0);
    const [validated, setValidated] = React.useState<boolean>(false);
    const [showPrompt, setShowPrompt] = React.useState<boolean>(false);

    const ref = React.useRef<HTMLFormElement>(null);

    const dispatch = useDispatch();

    React.useEffect(() => {
        localStorage.setItem("updatedFormData", "");
        if(!isRedirectedFromPreviewTab){
            localStorage.setItem("formData", JSON.stringify(formData));
        }
        window.addEventListener("beforeunload", alertUser);
        return () => {
            window.removeEventListener("beforeunload", alertUser);
        };
    }, []);

    React.useEffect(() => {
        localStorage.setItem("updatedFormData", JSON.stringify(formData));
        if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")) {
            setShowPrompt(true);
        } else {
            setShowPrompt(false);
        }
        if (localStorage.getItem("updatedFormData")?.includes("chosen")) {
            let hasChosenIncludedInFormData = localStorage.getItem("formData")?.includes("chosen");
            if (!hasChosenIncludedInFormData) {
                localStorage.setItem("formData", JSON.stringify(formData));
            }
        }
        setLocalFormData(formData);
    }, [formData]);

    const alertUser = (e) => {
        if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")) {
            e.preventDefault();
            e.returnValue = "";
            return "";
        }
    };

    const onConfirmClick = (sectionName: string, isRenameModal: boolean) => {
        setShowAddSectionModal(false);
        if (isRenameModal) {
            dispatch(RenameSectionModalData(sectionName, activeSection));
        } else if (isQuestionLimitValid()) {
            if (selectedSection.sectionName === intialSectionData.sectionName) {
                dispatch(SaveSectionModalData(sectionName));
            }
        }
        setSelectedSection(intialSectionData);
    };
    const onMergeClick = (sourceSection: ISections, targetSection: ISections[]) => {
        const targetSectionItems: ISectionItems[] = [];
        const indexsToRemove: number[] = [];
        const targetSectionNames: number[] = [];
        targetSection.forEach((section: ISections) => {
            targetSectionNames.push(section.sectionId);
            section.sectionItems?.forEach((item: ISectionItems) => {
                targetSectionItems.push(item);
            });
        });
        const mergedSectionItems: ISectionItems[] = [...sourceSection.sectionItems, ...targetSectionItems];
        const sectionToMerge: ISections = { ...sourceSection };
        sectionToMerge.sectionItems = mergedSectionItems;
        let indexToMerge: number = -1;
        const data: IFormData = { ...localFormData };
        const sections: ISections[] = [...data.sections];
        sections?.forEach((section: ISections, index: number) => {
            if (section.sectionId === sourceSection.sectionId) {
                indexToMerge = index;
            } else {
                targetSectionNames?.forEach((sectionId: number) => {
                    if (section.sectionId === sectionId) {
                        indexsToRemove.push(index);
                    }
                });
            }
        });
        sections[indexToMerge] = sectionToMerge;
        let count: number = 0;
        indexsToRemove.forEach((index: number) => {
            sections.splice(index - count, 1);
            count++;
        });
        data.sections = sections;
        dispatch(MergeSectionHandler(data));
        setShowMergeSectionModal(false);
    };

    const onModalClose = () => {
        setShowAddSectionModal(false);
        setShowMergeSectionModal(false);
        setShowMoveSectionModal(false);
        setSelectedSection(intialSectionData);
    };
    const handleRenameSection = () => {
        setShowAddSectionModal(true);
    };
    const handleMergeSection = () => {
        setShowMergeSectionModal(true);
    };
    const handleMoveSection = () => {
        setShowMoveSectionModal(true);
    };
    const handleDeleteSection = (sectionIndex: number) => {
        dispatch(DeleteSectionData(sectionIndex));
    };

    const handleReorderSections = (sections) => {
        sections = sections.map((section, index) => {
            section.order = index + 1;
            if (section.id) {
                delete section.id;
            }
            return section;
        });
        let data = { ...localFormData, sections: sections };
        dispatch(HandleSectionReordering(data));
        if (!isCreateTemplateForm) {
            dispatch(
                EditTemplate(data, () => {
                    setShowMoveSectionModal(false);
                    setLocalFormData(data);
                })
            );
        } else {
            onModalClose();
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: TemplateModalConstants.EditSuccessMessage,
                statusType: StatusType.Success
            });           
        }
    };

    const handleFormSave = () => {
        if (getTotalQuestions(localFormData) > 0) {
            if (isFormDataValid()) {
                const updateData = updateSectionAndQuestionnaireOrder(localFormData);
                resetIsRedirectedFromPreviewTab();
                isCreateTemplateForm
                    ? dispatch(SaveTemplate(updateData, redirectToTemplateListing))
                    : dispatch(EditTemplate(updateData, redirectToTemplateListing));
            }
        }
    };
    const isQuestionLimitValid = () => {
        if (getTotalQuestions(localFormData) + 1 > customQuestionSettings.questionLimit) {
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "add section"),
                statusType: StatusType.Error
            });
            return false;
        }
        return true;
    };

    const isFormDataValid = () => {
        let isValid = true;
        let hasDuplicateChoice = false;
        const form = ref.current;
        if (!!form && form.checkValidity()) {
            setValidated(false);
        } else {
            isValid = false;
            setValidated(true);
        }
        localFormData.sections.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                if (sectionItem.questionTitle === "") {
                    isValid = false;
                    sectionItem = { ...sectionItem, isValid: false };
                }
            });
        });
        if (formData.title === "" || formData.description === "") {
            isValid = false;
        }
        localFormData.sections.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                let choiceTexts : string[] = []; 
                sectionItem.choices.forEach((choice) => {
                    if (choice.text === "") {
                        isValid = false;
                        choice = { ...choice, isValid: false };
                    }
                    else {
                        let choiceText = removeUnwantedWhitespacesFromText(choice.text.toLowerCase());
                        if (choiceTexts.includes(choiceText))
                        {
                            hasDuplicateChoice = true;
                            choice = { ...choice, isDuplicate: true };
                        }
                        choiceTexts.push(choiceText);
                    }
                });
            });
        });
        localFormData.sections.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                sectionItem.choices.forEach((choice) => {
                    if (choice.subQuestions && choice.subQuestions.length > 0) {
                        choice.subQuestions.forEach((subQuestion) => {
                            if (subQuestion.questionTitle === "") {
                                isValid = false;
                            }
                        });
                    }
                });
            });
        });
        localFormData.sections.forEach((section) => {
            section.sectionItems.forEach((sectionItem) => {
                sectionItem.choices.forEach((choice) => {
                    if (choice.subQuestions && choice.subQuestions.length > 0) {
                        choice.subQuestions.forEach((subQuestion) => {
                            if (subQuestion.questionTypeId === QuestionType.MULTICHOICE) {
                                let choiceTexts : string[] = []; 
                                subQuestion.choices.forEach((subQuestionChoice) => {
                                    if (subQuestionChoice.text === "") {
                                        isValid = false;
                                    }
                                    else {
                                        let subQuestionChoiceText = removeUnwantedWhitespacesFromText(subQuestionChoice.text.toLowerCase());
                                        if (choiceTexts.includes(subQuestionChoiceText))
                                        {
                                            hasDuplicateChoice = true;
                                            subQuestionChoice = { ...subQuestionChoice, isDuplicate: true };
                                        }
                                        choiceTexts.push(subQuestionChoiceText);
                                    }
                                });
                            }
                        });
                    }
                });
            });
        });

        if (!isValid || hasDuplicateChoice) {
            dispatch(SetIsInvalidFlag());
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: FormBuilderConstants.FORM_VALIDATION_ERROR,
                statusType: StatusType.Error
            });
        }
            
        return isValid && !hasDuplicateChoice;
    };
    const handleClose = () => {
        if (localStorage.getItem("updatedFormData") !== localStorage.getItem("formData")){
            if (confirm(CustomQuestionConstants.PromptMessage) == true) {
                resetIsRedirectedFromPreviewTab();
                redirectToTemplateListing();
            }
        }
        else{
            redirectToTemplateListing();
        }
    };
    const addSectionHandler = () => {
        if (isQuestionLimitValid()) {
            setShowAddSectionModal(true);
        }
    };

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.trimStart();
        const payload: ITitleOnChangeHandlerPayload = {
            value: input
        };
        if (e.target.name === "title") {
            dispatch(HandleTitleOnChange(payload));
        } else {
            dispatch(HandleDescriptionOnChange(payload));
        }
    };
    return (
        <LoadingOverlay style={{ height: "100%" }}>
            <div className="form-builder-container">
                <Prompt when={showPrompt} message={CustomQuestionConstants.PromptMessage} />
                <header>
                    <div className="header-top-container">
                        <div className="closeIcon" onClick={handleClose}>
                            <CloseIcon />
                        </div>
                    </div>

                    <div className="header-bottom-container">
                        <span title={formData.template.name} className="ellipsis">
                            {formData.template.name}
                        </span>
                        <div>
                            <Button
                                className="preview-button"
                                data-test-auto={CqAutomation.Preview}
                                title={getTotalQuestions(formData) === 0 ? FormBuilderConstants.PREVIEW_DISABLED_TOOLTIP : ""}
                                disabled={getTotalQuestions(formData) === 0}
                                onClick={onClickPreview}
                                variant="outline-secondary btn-with-icon header-sec-button"
                            >
                                <EyeLashIcon />
                                Preview
                            </Button>

                            <Button
                                data-test-auto={CqAutomation.Save}
                                title={getTotalQuestions(formData) === 0 ? FormBuilderConstants.SAVE_DISABLED_TOOLTIP : ""}
                                disabled={getTotalQuestions(formData) === 0}
                                onClick={handleFormSave}
                                variant="primary"
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </header>

                <main>
                    <Form
                        ref={ref}
                        noValidate
                        validated={validated}
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <Form.Group>
                            <div className="questionnaire-header-container">
                                <div className="inner-container">
                                    <div className="field-container">
                                        <Form.Control
                                            className={`title ellipsis ${formData.isTitleValid === false ? "invalid-input" : ""}`}
                                            type="text"
                                            as="input"
                                            name="title"
                                            title={formData.title}
                                            value={formData.title}
                                            required
                                            placeholder={TemplateModalConstants.UntitledQuestionnaire}
                                            onChange={onChangeHandler}
                                            maxLength={TemplateModalConstants.NameMaxLength}
                                        />
                                        {formData.isTitleValid === false && (
                                            <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
                                        )}
                                    </div>
                                    <div className="field-container description-container">
                                        <Form.Control
                                            className={`description ellipsis ${formData.isDescriptionValid === false ? "invalid-input" : ""
                                                }`}
                                            type="text"
                                            as="input"
                                            name="description"
                                            title={formData.description}
                                            value={formData.description}
                                            required
                                            placeholder={TemplateModalConstants.QuestionnaireDescription}
                                            onChange={onChangeHandler}
                                            maxLength={TemplateModalConstants.DesciptionMaxLength}
                                        />
                                        {formData.isDescriptionValid === false && (
                                            <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>
                                        )}
                                    </div>
                                </div>

                                <p>
                                    <i>* </i>Required
                                </p>
                            </div>
                        </Form.Group>
                    </Form>
                    {localFormData &&
                        localFormData.sections.length > 0 &&
                        localFormData.sections.map((section, sectionIndex) => (
                            <Section
                                key={sectionIndex}
                                section={section}
                                sectionIndex={sectionIndex}
                                handleRenameSection={handleRenameSection}
                                handleMergeSection={handleMergeSection}
                                handleMoveSection={handleMoveSection}
                                handleDeleteSection={handleDeleteSection}
                                setSelectedSection={setSelectedSection}
                                setActiveSection={setActiveSection}
                            />
                        ))}
                    <footer className="form-footer">
                        <Button className="btn-with-icon" onClick={addSectionHandler} variant="primary">
                            <StackIcon />
                            {TemplateModalConstants.AddNewSection}
                        </Button>
                    </footer>
                </main>

                {showAddSectionModal && (
                    <SectionModal
                        show={showAddSectionModal}
                        onConfirm={onConfirmClick}
                        onClose={onModalClose}
                        selectedSection={selectedSection}
                    />
                )}
                {showMergeSectionModal && (
                    <SectionMergeModal
                        show={showMergeSectionModal}
                        onConfirm={onMergeClick}
                        onClose={onModalClose}
                        sourceSection={selectedSection}
                        sections={localFormData.sections}
                    />
                )}
                {showMoveSectionModal && (
                    <SectionMoveModal
                        show={showMoveSectionModal}
                        onConfirm={handleReorderSections}
                        onClose={onModalClose}
                        sections={localFormData.sections}
                    />
                )}
            </div>

            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default FormBuilder;
