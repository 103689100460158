import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import DropdownComponent from '../../../../components/common/controls/DropdownComponent';
import { EmailMessage, EmailMessageType } from '../../../models/OrganizerComponentModels';
import { EmailMessageComponent } from './../EmailMessage/EmailMessage';

interface ReminderTemplateProps {
    heading?: string;
    description?: string;
    onChangeReminderDropdown(value: any): void;
    selectedReminderType: EmailMessageType;
    emailMessages: EmailMessage[];
    defaultReminder: number;
    selectedReminderMessage: number;
    onOrganizerReminderListItemClick(value: number): void;
    onEmailAddButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onEmailEditButtonClick: (defaultMessageId: number, selectedMessageId: number, typeId: number) => void;
    onEmailDeleteButtonClick: (selectedMessageId: number, defaultMessage: number) => void;
    showLoader: boolean;
    reminderDropDownOptions: any;
}


export const ReminderTemplateComponent: React.StatelessComponent<ReminderTemplateProps> = (props) => {
    return (
        <div>
            <div className="row">
                <div className="reminder-text">
                    <h3 className="text-primary">{'Reminders'}</h3>
                    <i className="fas fa-info-circle"></i><span className="marL05">{'Reminders sent to client'}</span>
                </div>
            </div>
            <Row>
                <div className="reminder-text">
                    <h4 className="text-primary padT08">{'Select Reminder'}</h4>
                </div>
                <div className="marB10 marL15 dropdown-component">
                    <DropdownComponent
                        id={"ReminderDropDown"}
                        options={props.reminderDropDownOptions}
                        onChange={props.onChangeReminderDropdown}
                        selectedValue={props.selectedReminderType}
                        customPlaceHolder={"Choose Reminder Type"}
                    />
                </div>
            </Row>
            { props.selectedReminderType != EmailMessageType.None ?
                <EmailMessageComponent
                    emailMessages={props.emailMessages}
                    defaultMessage={props.defaultReminder}
                    selectedMessage={props.selectedReminderMessage}
                    onListItemClick={props.onOrganizerReminderListItemClick}
                    typeId={props.selectedReminderType}
                    onAddButtonClick={props.onEmailAddButtonClick}
                    onEditButtonClick={props.onEmailEditButtonClick}
                    onDeleteButtonClick={props.onEmailDeleteButtonClick}
                    showLoader={props.showLoader}

                />
                : null
            }
        </div>
    )
}