import * as React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { ISections, intialSectionData } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { TemplateModalConstants } from "src/components/helper/Constants";

interface SectionModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: (sectionName: string, isEditModal: boolean) => void;
    selectedSection: ISections;
}

export const SectionModal: React.FC<SectionModalProps> = ({ show, onClose, onConfirm, selectedSection }) => {
    const ref = React.useRef<HTMLFormElement>(null);
    const [sectionName, setSectionName] = React.useState(selectedSection.sectionName);
    const [validated, setValidated] = React.useState<boolean>(false);

    const isEditModal = selectedSection.sectionName !== "";

    const onFormChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.trimStart();
        setSectionName(input);
    };

    const handleOnSubmit = () => {
        const form = ref.current;
        if (!!form && form.checkValidity()) {
            setValidated(false);
            onConfirm(sectionName, isEditModal);
        }
        setValidated(true);
    };
    const hideModalHandler = () => {
        onClose();
        setValidated(false);
        setSectionName(intialSectionData.sectionName);
    };
    return (
        <Modal className="template-modal-container" show={show} onHide={hideModalHandler} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {isEditModal
                        ? TemplateModalConstants.EditSectionModalHeader
                        : TemplateModalConstants.AddSectionModalHeader}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bootbox-body">
                <Form
                    ref={ref}
                    noValidate
                    validated={validated}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <Form.Group>
                        <Form.Label>{TemplateModalConstants.SectionNameLabel}</Form.Label>
                        <Form.Control
                            type="text"
                            id="sectionName"
                            value={sectionName}
                            placeholder={TemplateModalConstants.SectionNamePlaceholder}
                            onChange={onFormChange}
                            required
                            maxLength={TemplateModalConstants.SectionNameMaxLength}
                        />
                        <Form.Control.Feedback type="invalid">
                            {TemplateModalConstants.SectionNameValidationMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" className="btn-white" onClick={hideModalHandler}>
                    <i className="glyphicon glyphicon-remove" />
                    Cancel
                </Button>
                <Button className="btn-info" onClick={handleOnSubmit} variant="info">
                    <i className="glyphicon glyphicon-ok" />
                    {TemplateModalConstants.EditModalConfirmButton}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
