import * as React from 'react';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
    CustomSelectProps, ToolBarProps
} from 'react-bootstrap-table';
import { Alert, Col, Row } from 'react-bootstrap';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { CheckBoxComponent, CheckBoxSize } from "./../../../../components/common/CheckBoxComponent";
import { DaysRangeOptionsList } from './../../../../components/common/TaxReturn';
import { CustomMultiSelect } from "./../../../../components/common/MultipleSelectComponent";
import { OrganizerReportFilter } from './../OrganizerFilter/OrganizerReportFilter'
import {
    OrganizerDocument, OrganizerFilter,
    OrganizerFilterType, SourceDocumentStatus, SourceDocumentStatusList,
    OrganizerStatus, OrganizerStatusList,
    EngagementLetterStatus, EngagementLetterStatusList,
    OrganizerPageOptions, OrganizerClient,
    OrganizerClientType, IColumnSettings,
    EngagementLetterStatusOptionsList, OrganizerStatusOptionsList, SignatureStatus
} from './../../../models/OrganizerComponentModels';
import { OrganizerDeliveredAction } from './OrganizerDeliveredAction';
import * as bootbox from 'bootbox';
let moment = require('moment');
import { validateFilter, formattedClientName, getOrganizersTaxYearList } from './../../Helper/OrganizerHelperFunctions';
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import { DeliveredReturnsConstants, OrganizerFilterConstants } from './../../../../components/helper/Constants';
import { Link } from 'react-router-dom';
import { ColumnSettings } from '../ColumnSettings/ColumnSettings';
import { CustomDateFilter } from '../Common/CustomDateFilter';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';

export interface OrganizerDeliveredReportTableProps {
    resourcesList: any;

    documents: OrganizerDocument[];
    isLoading: boolean;
    saveFilterShow: boolean;
    onPageChange: any;
    onSortChange: any;
    onFilterChange: any;
    pageNo: number;
    totalRows: number;

    pageSize: number;
    pageReload: () => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];

    filterType: OrganizerFilterType;
    filterList: OrganizerFilter[];
    currentFilter: OrganizerFilter;
    defaultFilter: string | undefined;
    onSaveFilterShow: () => void;
    onSaveFilterHide: () => void;
    onFilterUpdate: (filterName: string, actionName: string) => void;
    onRemoveDefaultFilter: (filterName: string, actionName: string) => void;
    onSetDefaultFilter: (filterName: string, actionName: string) => void;
    onFilterDelete: (filterName: string) => void;
    onExportToExcel: (callback: () => void) => void;
    onFilterNameChange: (event: any) => void;
    onFilterSave: (callback: (filter: OrganizerFilter) => void) => void;

    onEditClientInfoOpen: (index: number) => void;
    onReportProblemOpen: (index: number) => void;
    onResendAccessLinkModalOpen: (index: number) => void;
    onReopenOrganizerLinkModalOpen: (index: number) => void;
    onCloseOrganizerLinkModalOpen: (index: number) => void;
    onDownloadModalOpen: (index: number) => void;
    onClientTrackingModalOpen: (index: number) => void;
    onDeleteModalOpen: (index: number) => void;
    onViewAccessCodeOpen: (rowIndex: number) => void;
    onSignedDetailsOpen: (rowIndex: number) => void;
    onDeliveryStatusOpen: (rowIndex: number) => void;
    onSourceDocumentsDownloadOpen: (rowIndex: number) => void;
    selectedOrganizerOption: OrganizerPageOptions;
    onUnlockOrganizer: (rowIndex: number) => void;
    changeOrganizerOptions: (option: OrganizerPageOptions) => void;
    onClientViewLinkClick: (rowIndex: number, clientType: OrganizerClientType, callback: () => void) => void;
    allowGatheringSourceDocuments: boolean;
    refreshDelay?: boolean;
    onClickBulkSelection: () => void;
    selectedRowCount: number;
    showBulkSelectionMessage: boolean;
    onRequestOrganizerClients: (rowIndex: number) => void;
    onCancelOrganizerClients: () => void;
    organizerClients: OrganizerClient[];
    onSourceDocumentsUploadOpen: (rowIndex: number) => void;
    isArchived: boolean;
    onRestore: (rowIndex: number) => void;
    columnSettings: IColumnSettings[];
    saveColumnSettings: (reportType: OrganizerFilterType, data: IColumnSettings[], callback?: () => void) => void;
    onSendReminderActionClick: (rowIndex: number) => void;
    userLocationList? : IDropdown[];
}

export interface OrganizerDeliveredReportTableState {
    modifiedColumnSettings: IColumnSettings[];
    customColumnOptions: IColumnSettings[];
    showColumnSettingsLoader: boolean;
    showColumnSettings: boolean;

}

export class OrganizerDeliveredReportTable extends React.Component<OrganizerDeliveredReportTableProps, OrganizerDeliveredReportTableState>{
    public customEngagementStatusMultiSelect: any;
    public customLocationNameMultiSelect: any;
    public customOrganizerStatusMultiSelect: any;
    public customSourceStatusMultiSelect: any;
    public customTaxYearMultiSelect: any;
    public customOrganizerReminderFilter: any;
    public customSigningReminderFilter: any;
    public isAppliedFilter = false;
    public filterChanged = false;
    public taxYearList: any;

    constructor(props: OrganizerDeliveredReportTableProps) {
        super(props);
        this.state = {
            showColumnSettingsLoader: false,
            modifiedColumnSettings: [],
            customColumnOptions: [],
            showColumnSettings: false
        };
    }

    getAppliedFilter = () => {
        return this.isAppliedFilter;
    }

    componentWillMount() {
        this.taxYearList = getOrganizersTaxYearList();
        this.customSourceStatusMultiSelect &&
        this.customSourceStatusMultiSelect.setOptions(this.getSourceDocumentStatusList());
    }

    componentWillReceiveProps(props: OrganizerDeliveredReportTableProps) {
        this.setState({
            modifiedColumnSettings: props.columnSettings,
            customColumnOptions: props.columnSettings,
        });
    }

    createCustomToolBar = (props: ToolBarProps) => {
        const {
            filterType, defaultFilter, filterList, onExportToExcel, onFilterDelete, onFilterNameChange,
            onFilterSave, onRemoveDefaultFilter, onSaveFilterHide, onSetDefaultFilter, saveFilterShow,
            changeOrganizerOptions, pageReload, refreshDelay, isArchived, totalRows
        } = this.props;
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15" style={{ height: 33 }}>
                <Row>
                    {this.showBulkAlertMessage()}
                    <OrganizerReportFilter
                        filterType={filterType}
                        defaultFilter={defaultFilter}
                        filterList={filterList}
                        onClearFilter={this.onClearFilter}
                        onExportToExcel={onExportToExcel}
                        onFilterDelete={onFilterDelete}
                        onFilterNameChange={onFilterNameChange}
                        onFilterSave={onFilterSave}
                        onFilterSaveClick={this.onFilterSaveClick}
                        onLoadSelectedFilter={this.onLoadSelectedFilter}
                        onRemoveDefaultFilter={onRemoveDefaultFilter}
                        onSaveFilterHide={onSaveFilterHide}
                        onSetDefaultFilter={onSetDefaultFilter}
                        show={saveFilterShow}
                        changeOrganizerOptions={changeOrganizerOptions}
                        selectedOrganizerOption={OrganizerPageOptions.Organizer}
                        pageReload={pageReload}
                        refreshDelay={refreshDelay}
                        hideReportSelector={true}
                        OnOpenColumnSettings={this.OnOpenColumnSettings}
                        isArchived={isArchived}
                        exportToExcelEnabled={totalRows === 0}
                    />
                </Row>
            </div >
        );
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"organizer-reports-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }

    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p style={{ fontSize: '14px' }}>
                Showing {start} to {to} of {total} entries
            </p>
        )
    }

    setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    engagementStatusFormatter = (cell: any, row: any) => {
        const status: any = row.engagementLetterStatus;
        const statusType =
            (typeof status == "number") ? EngagementLetterStatus[status] : status;
        const {TPSignerStatus,SpouseSignerStatus,signerCount, engagementLetterStatus} = this.props.documents[row.rowIndex];
        var isNotDownloadedOrMS = engagementLetterStatus != EngagementLetterStatus.EngagementLetterDownloaded 
        && engagementLetterStatus != EngagementLetterStatus.ManuallySigned
        && engagementLetterStatus != EngagementLetterStatus.Reviewed
        
        if (!statusType) {
            return <span title={'N/A'} className="ellipsis">N/A</span>
        }
        // Condition to check if document is partially reviewed or 2 clients are present and only one of the signer has reviewed 
        if (isNotDownloadedOrMS && (engagementLetterStatus === EngagementLetterStatus.PartiallySigned
            || (!(TPSignerStatus == SignatureStatus.Reviewed) !== !(SpouseSignerStatus == SignatureStatus.Reviewed)
            && signerCount == 2 ))) {
            return <Link to={"#"} onClick={() => this.props.onSignedDetailsOpen(row.rowIndex)}>{EngagementLetterStatusList[statusType].toUpperCase()}</Link>;
        }
       
        return <span title={EngagementLetterStatusList[statusType].toUpperCase()} className="ellipsis">{EngagementLetterStatusList[statusType].toUpperCase()}</span>;
    }

    organizerStatusFormatter = (cell: any, row: any) => {
        const status: any = row.organizerStatus;
        const organizer = this.props.documents[row.rowIndex];
        const statusType =
            (typeof status == "number") ? OrganizerStatus[status] : status;
        if (!statusType) {
            return <span title={'None'} className="ellipsis">None</span>
        }
        if (((organizer.isTPOrganizerMailDropped || organizer.isSpouseOrganizerMailDropped) && organizer.organizerStatus != OrganizerStatus.ClosedByFirm) 
            && organizer.signerCount == 2) { // Multiple signers with Partially Dropped or completely Dropped status
            if (!organizer.taxpayerEmail || !organizer.spouseEmail) { //If 2 signers with one is having dropped status and other is doesn't have any email id
                return <Link to={"#"} onClick={() => this.props.onDeliveryStatusOpen(row.rowIndex)}>{OrganizerStatusList[OrganizerStatus[OrganizerStatus.Dropped]].toUpperCase()}</Link>;
            }
            else {
                return <Link to={"#"} onClick={() => this.props.onDeliveryStatusOpen(row.rowIndex)}>{OrganizerStatusList[statusType].toUpperCase()}</Link>;
            }
        }

        return <span title={OrganizerStatusList[statusType].toUpperCase()} className="ellipsis">{OrganizerStatusList[statusType].toUpperCase()}</span>;
    }

    sourceStatusFormatter = (cell: any, row: any) => {
        const status: any = row.sourceDocumentStatus;
        const statusType = (typeof status == "number") ? SourceDocumentStatus[status] : status;
        if (!statusType) {
            return <span title={''} className="ellipsis"></span>
        }

        if (statusType === SourceDocumentStatus[SourceDocumentStatus.Downloaded]) {
            return this.sourceDocumentStatusLink(row.rowIndex, statusType);
        }
        else if (statusType === SourceDocumentStatus[SourceDocumentStatus.Uploaded] || statusType === SourceDocumentStatus[SourceDocumentStatus.Completed]) {
            const organizer = this.props.documents[row.rowIndex];
            if (organizer.downloadedSourceDocumentsCount > 0) {
                return this.sourceDocumentStatusLink(row.rowIndex, statusType);
            }
        }
        else if (statusType === SourceDocumentStatus[SourceDocumentStatus.AwaitingUpload] && !this.props.allowGatheringSourceDocuments) {
            return <span title={"N/A"} className="ellipsis">{"N/A"}</span>;
        }

        return <span title={SourceDocumentStatusList[statusType].toUpperCase()} className="ellipsis">{SourceDocumentStatusList[statusType].toUpperCase()}</span>;
    }

    sourceDocumentStatusLink = (rowIndex: number, statusType: any) => {
        return <Link to={"#"} onClick={() => this.props.onSourceDocumentsDownloadOpen(rowIndex)}>{SourceDocumentStatusList[statusType].toUpperCase()}</Link>;
    }

    taxPayerNameFormatter = (cell: any, row: any) => {
        const clientName: any = row.taxpayerName
        return <span title={formattedClientName(clientName)} className="ellipsis">{formattedClientName(clientName)}</span>;
    }

    batchNameFormatter = (cell: any, row: any) => {
        const batchName: any = row.batchName;
        if (!batchName) {
            return <span title={'-'} className="ellipsis">-</span>
        }
        return <span title={batchName} className="ellipsis">{batchName}</span>;
    }



    getTaxYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    getEngagementStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customEngagementStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} optionsModal={true} />
        );
    }

    getLocationNameMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customLocationNameMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder}  optionsModal={true} />
        );
    }

    getOrganizerStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customOrganizerStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} optionsModal={true} />
        );
    }

    getSourceStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customSourceStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} optionsModal={true} />
        );
    }

    getSourceDocumentUploadStatus = (index: number) => {
        const organizer = this.props.documents[index];
        return organizer.isSourceDocumentUploadCompleted;
    }

    actionFormatter = (cell: any, row: any) => {
        const { onEditClientInfoOpen, onClientTrackingModalOpen, onDownloadModalOpen,
            onResendAccessLinkModalOpen, onReopenOrganizerLinkModalOpen, onCloseOrganizerLinkModalOpen, onDeleteModalOpen, onViewAccessCodeOpen,
            onReportProblemOpen, onUnlockOrganizer, onClientViewLinkClick,
            onRequestOrganizerClients, onCancelOrganizerClients, organizerClients,
            isArchived, onRestore, onSourceDocumentsUploadOpen, allowGatheringSourceDocuments,
            onSendReminderActionClick, resourcesList } = this.props;
        return <OrganizerDeliveredAction
            rowIndex={row.rowIndex}
            onClientTrackingOpen={onClientTrackingModalOpen}
            onDeleteOpen={onDeleteModalOpen}
            onDownloadOpen={onDownloadModalOpen}
            onEditClientInfoOpen={onEditClientInfoOpen}
            onReportProblemOpen={onReportProblemOpen}
            onResendAccessLinkOpen={onResendAccessLinkModalOpen}
            onReopenOrganizerLinkOpen={onReopenOrganizerLinkModalOpen}
            onCloseOrganizerLinkOpen={onCloseOrganizerLinkModalOpen}
            onUnlockOrganizer={onUnlockOrganizer}
            onClientViewLinkClick={onClientViewLinkClick}
            onRequestOrganizerClients={onRequestOrganizerClients}
            onCancelOrganizerClients={onCancelOrganizerClients}
            onSendReminderActionClick={onSendReminderActionClick}
            organizerClients={organizerClients}
            title={cell}
            data={row}
            onViewAccessCodeOpen={onViewAccessCodeOpen}
            isSourceDocumentUploadCompleted={this.getSourceDocumentUploadStatus(row.rowIndex)}
            onSourceDocumentsUploadOpen={onSourceDocumentsUploadOpen}
            isGatheringSourceDocumentsEnabled={allowGatheringSourceDocuments}
            isArchived={isArchived}
            onRestore={onRestore}
            resourcesList={resourcesList}
        />
    }


    onFilterChange = (dataField: any) => {
        if (!this.filterChanged && !this.isAppliedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }

    onClearFilter = () => {
        this.filterChanged = true;
        (this.refs.taxpayerName as TableHeaderColumn).cleanFiltered();
        (this.refs.clientId as TableHeaderColumn).cleanFiltered();
        (this.refs.deliveredOn as TableHeaderColumn).cleanFiltered();
        (this.refs.signingReminder as TableHeaderColumn).cleanFiltered();
        (this.refs.organizerReminder as TableHeaderColumn).cleanFiltered();
        (this.refs.ero as TableHeaderColumn).cleanFiltered();
        (this.refs.batchName as TableHeaderColumn).cleanFiltered();
        (this.refs.taxpayerEmail as TableHeaderColumn).cleanFiltered();
        (this.refs.spouseEmail as TableHeaderColumn).cleanFiltered();
        this.props.isArchived ?
            (this.refs.archivedBy as TableHeaderColumn).cleanFiltered() :
            (this.refs.sentBy as TableHeaderColumn).cleanFiltered();

        this.customEngagementStatusMultiSelect.cleanFiltered();
        this.customSourceStatusMultiSelect.cleanFiltered();
        this.customOrganizerStatusMultiSelect.cleanFiltered();
        this.customTaxYearMultiSelect.cleanFiltered();
        this.customLocationNameMultiSelect.cleanFiltered();
        this.filterChanged = false;
    }

    onFilterSaveClick = (filterName: string) => {

        const { currentFilter, onSaveFilterShow, onFilterUpdate } = this.props;

        if (!validateFilter(currentFilter)) {
            VenusNotifier.Warning(OrganizerFilterConstants.FilterFieldsAreEmpty, null);
            return;
        }

        if (typeof filterName == 'undefined' || filterName.length == 0) {
            onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: OrganizerFilterConstants.Title.FilterSaveUpdate,
                message: OrganizerFilterConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i>' + OrganizerFilterConstants.ControlLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { onFilterUpdate(filterName, OrganizerFilterConstants.ControlLabel.NoUpdateTheCurrent); }
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i>' + OrganizerFilterConstants.ControlLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { onSaveFilterShow(); }
                    }
                }
            });
        }
    }

    onLoadSelectedFilter = (filter?: OrganizerFilter) => {
        this.isAppliedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxpayerName':
                        (this.refs.taxpayerName as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'taxpayerEmail':
                        (this.refs.taxpayerEmail as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'spouseEmail':
                        (this.refs.spouseEmail as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'ero':
                        (this.refs.ero as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;

                    case 'locationName':
                        this.customLocationNameMultiSelect.applyFilter((filter.fields[field]));
                        break;

                    case 'batchName':
                        (this.refs.batchName as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;

                    case 'sentBy':
                        (this.refs.sentBy as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;

                    case 'archivedBy':
                        (this.refs.archivedBy as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;

                    case 'deliveredOn':
                        let deliveredDateFilter: any = filter.fields[field];
                        if (deliveredDateFilter) {
                            (this.refs.deliveredOn as TableHeaderColumn).applyFilter(deliveredDateFilter);
                        }
                        break;
                    case 'engagementLetterStatus':
                        this.customEngagementStatusMultiSelect.applyFilter((filter.fields[field]));
                        break;
                    case 'organizerStatus':
                        this.customOrganizerStatusMultiSelect.applyFilter((filter.fields[field]));
                        break;
                    case 'sourceDocumentStatus':
                        this.customSourceStatusMultiSelect.applyFilter((filter.fields[field]));
                        break;
                    case 'taxYear':
                        this.customTaxYearMultiSelect.applyFilter((filter.fields[field]));
                        break;

                    case 'organizerReminder':
                        (this.refs.OrganizerReminderDateFilter as TableHeaderColumn).applyFilter((filter.fields[field]));
                        break;

                    case 'signingReminder':
                        (this.refs.SigningReminderDateFilter as TableHeaderColumn).applyFilter((filter.fields[field]));
                        break;
                }
                this.filterChanged = false;
            }
            this.isAppliedFilter = false;
            this.onFilterChange(filter.fields);
        }
    }

    getSourceDocumentStatusList = () => {
        const { allowGatheringSourceDocuments } = this.props;
        return [
            { label: "AWAITING UPLOAD", value: 1 },
            { label: "UPLOADED", value: 2 },
            { label: "DOWNLOADED", value: 3 },
            { label: "FINISHED", value: 4 }
        ].map(item => {
            if (item.value === 1 && allowGatheringSourceDocuments === false) {
                return { ...item, label: "N/A" }
            } else if (item.value === 1 && allowGatheringSourceDocuments) {
                return { ...item, label: "AWAITING UPLOAD" }
            }
            else
                return { ...item };
        });
    }

    showBulkAlertMessage() {
        const {
            documents: { length: documentsInPage }, isArchived, totalRows,
            onClickBulkSelection, showBulkSelectionMessage
        } = this.props;

        if (totalRows > documentsInPage && !isArchived) {
            return (<div style={{ zIndex: 10, fontSize: '14px', marginLeft: '15px' }} >
                <Alert variant="warning" style={{ padding: "6px", width: "fit-content", margin: "auto", float: 'left' }} show={showBulkSelectionMessage} >
                    <span
                        className="fa fa-exclamation-triangle"
                        style={{ marginRight: '5px', paddingLeft: "5px" }}>
                    </span> All {documentsInPage} records on this page are selected. To select the remaining {totalRows - documentsInPage} filtered records,
                    <Link to={"#"} onClick={onClickBulkSelection}> click here</Link>
                </Alert>
            </div>);
        }
    }

    private defaultType(cell: any, row: any) {
        return <div title={cell} className="ellipsis">{cell}</div>;

    }

    private OrganizerReminderDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (<CustomDateFilter onRef={(ref: any) => (this.customOrganizerReminderFilter = ref)} filterHandler={filterHandler} customFilterParameters={customFilterParameters} />);
    }

    private SigningReminderDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (<CustomDateFilter onRef={(ref: any) => (this.customSigningReminderFilter = ref)} filterHandler={filterHandler} customFilterParameters={customFilterParameters} />);
    }

    activateOrDeactivateCustomColumn = (event: any, name: string) => {
        const { modifiedColumnSettings } = this.state;
        let copy: IColumnSettings[] = (modifiedColumnSettings.length > 0) ? [...modifiedColumnSettings] : this.getColumnSettings();
        let temp = JSON.parse(JSON.stringify(copy));
        temp.map((items: IColumnSettings) => {
            if (items.columnName == name) {
                if (event.target.checked) {
                    items.isActive = true;
                } else {
                    items.isActive = false;
                }
            }
        });
        this.setState({ modifiedColumnSettings: temp });
    }

    private getColumnSettings = (): IColumnSettings[] => {
        return this.getColumns().filter(column => !column.isHidden).map((col, index) => {

            return {
                columnName: col.header,
                columnOrder: index + 1,
                isActive: true
            } as IColumnSettings
        });
    }

    private getColumns = (): any[] => {
        return [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                headerClassName: '',
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Client Name',
                key: 'taxpayerName',
                isKey: false,
                dataFormat: this.taxPayerNameFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                width: '150px',
                headerClassName: '',
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Client Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                width: 'auto',
                headerClassName: '',
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Client Id', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Taxpayer Email',
                key: 'taxpayerEmail',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                width: 'auto',
                headerClassName: '',
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'TaxPayer Email', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Spouse Email',
                key: 'spouseEmail',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                width: 'auto',
                headerClassName: '',
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Spouse Email', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataSort: true,
                dataFormat: this.defaultFormatter,
                headerClassName: 'tax-year',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: '80px',
                filter: {
                    type: 'CustomFilter', getElement: this.getTaxYearMultiSelectDropDown, customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Tax Year...' } as any
                } as CustomFilter
            },
            {
                header: 'ERO / Signer',
                key: 'ero',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                headerClassName: '',
                columnClassName: '',
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataSort: true,
                dataFormat: this.defaultFormatter,
                headerClassName: '',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter', getElement: this.getLocationNameMultiSelectDropDown,
                    customFilterParameters: { options: this.props.userLocationList, enableAllFilter: true, placeholder: 'Select Office Location...' } as any
                } as CustomFilter
            },
            {
                header: 'Batch Name',
                key: 'batchName',
                isKey: false,
                dataFormat: this.batchNameFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                headerClassName: '',
                columnClassName: '',
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Batch Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Sent By',
                key: 'sentBy',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                headerClassName: '',
                columnClassName: '',
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Sent By', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Delivered Date',
                key: 'deliveredOn',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                headerClassName: '',
                columnClassName: '',
                width: 'auto',
                filter: { type: 'SelectFilter', placeholder: 'Select Delivered Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Signature Status',
                key: 'engagementLetterStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.engagementStatusFormatter,
                headerClassName: 'signature-status',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter', getElement: this.getEngagementStatusMultiSelectDropDown,
                    customFilterParameters: { options: EngagementLetterStatusOptionsList, enableAllFilter: true, placeholder: 'Select Engagement Letter Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Organizer Status',
                key: 'organizerStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.organizerStatusFormatter,
                headerClassName: 'organizer-status',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter', getElement: this.getOrganizerStatusMultiSelectDropDown,
                    customFilterParameters: { options: OrganizerStatusOptionsList, enableAllFilter: true, placeholder: 'Select Organizer Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Source Document Status',
                key: 'sourceDocumentStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.sourceStatusFormatter,
                headerClassName: 'source-document-status',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter', getElement: this.getSourceStatusMultiSelectDropDown,
                    customFilterParameters: { options: this.getSourceDocumentStatusList(), enableAllFilter: true, placeholder: 'Select Document Upload Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Signing Reminder',
                key: 'signingReminder',
                isKey: false,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.SigningReminderDateFilter
                } as CustomFilter
            },
            {
                header: 'Organizer Reminder',
                key: 'organizerReminder',
                isKey: false,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                filter: {
                    type: 'CustomFilter',
                    getElement: this.OrganizerReminderDateFilter
                } as CustomFilter

            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                headerClassName: '',
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '145px',
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];
    }

    private getListItem = () => {
        let columnsOptions: IColumnSettings[] = [];
        let tempColumnSetting: IColumnSettings[] = this.getColumnSettings();
        if (this.state.modifiedColumnSettings.length <= 0) {
            columnsOptions = this.getColumnSettings();
        }
        else {
            let modifiedSetting: IColumnSettings[] = this.state.modifiedColumnSettings;
            if (modifiedSetting.length < tempColumnSetting.length) {
                let newlyAddedcolumns = tempColumnSetting.filter(ad =>
                    modifiedSetting.every(fd => fd.columnName !== ad.columnName));

                if (newlyAddedcolumns != undefined && newlyAddedcolumns.length > 0) {
                    let tempColumnList: any[] = [];

                    const actionColumn: IColumnSettings | undefined = modifiedSetting.find(x => x.columnName == "Action");
                    const actionIndex = modifiedSetting.findIndex(x => x.columnName == "Action");
                    modifiedSetting.splice(actionIndex, 1);

                    modifiedSetting.push(...newlyAddedcolumns);//push newly added columns to modifiedSetting
                    modifiedSetting.sort((x, y) => x.columnOrder - y.columnOrder);
                    var addColumnOrder = 0;

                    //Loop through all columns and find the new column orders
                    modifiedSetting.forEach(columnSetting => {
                        //if the column header value is from reportcolumnsettings DB table, find the new column order.
                        if (newlyAddedcolumns.filter(x => x.columnName == columnSetting.columnName).length == 0) {
                            addColumnOrder = columnSetting.columnOrder;
                            do {
                                //find the new column order for existing colmun header. It will be by adding the current column order of it with the number of new column added before it.
                                addColumnOrder = addColumnOrder + newlyAddedcolumns.filter(p => p.columnOrder <= columnSetting.columnOrder).length;
                            } while (newlyAddedcolumns.filter(y => y.columnOrder == addColumnOrder).length > 0);//if the new column order is already assigned to any newly added column value, execute the loop again
                            columnSetting.columnOrder = addColumnOrder;
                        }
                        tempColumnList.push(columnSetting);
                    });

                    modifiedSetting.splice(0, modifiedSetting.length, ...tempColumnList);//assign the tempcolumnlist to modifiedSetting array
                    modifiedSetting.sort((x, y) => x.columnOrder - y.columnOrder);
                    if (actionColumn != undefined) {
                        actionColumn.columnOrder = modifiedSetting.length + 1;
                        modifiedSetting.push(actionColumn);//add action column at the end of all columns
                    }
                }
                this.setState({ modifiedColumnSettings: modifiedSetting });
            }
            columnsOptions = modifiedSetting;
        }

        let items = columnsOptions.filter(col => col.columnName != "Action").map((value, index) => {
            return <li
                key={value.columnName + value.columnOrder}
                className={value.columnName}
                onDrop={(e) => this.onDropped(e, index)}
                draggable={true}
                onDragStart={(e) => this.onDragStart(e, index)}
                onDragOver={(e) => e.preventDefault()} onDragEnd={(e) => e.preventDefault()}
                style={{ listStyleType: "none", marginBottom: "20px", width: "100%", display: "flex" }}>
                <Row className="justify-content-md-center" style={{ width: "100%" }}>
                    <Col xs lg="9">
                        <span style={{ float: "left" }} className="">
                            <input
                                type="text"
                                style={{ pointerEvents: "none" }}
                                className={"form-control draggable-input noOutline"}
                                value={value.columnName}
                                disabled={(value.columnName == "Client ID") ? true : false} />
                        </span>
                    </Col>
                    <Col xs lg="2">
                        {(value.columnName == "Client ID") ?
                            <span style={{ padding: "0px 10px" }} className="">
                                <i className="fa fa-info-circle" title={"This column is required"}
                                    style={{ paddingTop: "10px", color: "#26b2ad", cursor: "pointer" }}></i>
                            </span>
                            :
                            <label className="toggleSwitch ">
                                <input type="checkbox"
                                    checked={value.isActive}
                                    onChange={(event) => this.activateOrDeactivateCustomColumn(event, value.columnName)}
                                ></input>
                                <span className="columnFilterSlider round"></span>
                            </label>
                        }
                    </Col>
                </Row>
            </li>;
        });
        return items;
    }

    private onDropped = (event: any, listIndex: any) => {
        const copy: IColumnSettings[] = (this.state.modifiedColumnSettings.length > 0) ? [...this.state.modifiedColumnSettings] : this.getColumnSettings();
        let temp = JSON.parse(JSON.stringify(copy));
        const oldIndex: number = parseInt(event.dataTransfer.getData("item"));
        if (oldIndex != listIndex) {
            const parent: any = document.getElementById('item-list');
            const target: any = parent && parent.children[listIndex];
            let dropped: any = parent && parent.children[oldIndex];
            dropped && dropped.remove();
            const newIndex = listIndex;
            if (newIndex < oldIndex) {
                target && target.closest("li").before(dropped);
            }
            else {
                target && target.closest("li").after(dropped);
            }

            const childNodes = parent.getElementsByTagName('li');
            for (let j = 0; j < childNodes.length; j++) {
                temp.map((items: any, index: number) => {
                    if (items.columnName == childNodes[j].className) {
                        items.columnOrder = j + 1;
                    }
                });
            }
            temp = temp.sort((a: any, b: any) => a.columnOrder < b.columnOrder ? -1 : a.columnOrder > b.columnOrder ? 1 : 0);
            this.setState({ modifiedColumnSettings: temp });
        }
    }

    private onDragStart = (event: any, listIndex: any) => {
        const index = listIndex;
        event.dataTransfer.setData("item", index);
    }

    private onReset = () => {
        this.setState({
            modifiedColumnSettings: this.state.customColumnOptions
        });
        this.OnCloseColumnSettings();
    }

    private OnCloseColumnSettings = () => {
        this.setState({ showColumnSettings: false });
    }

    private OnOpenColumnSettings = () => {
        this.setState({ showColumnSettings: true });
    }

    private OnSaveCustomColumns = () => {
        this.setState({ showColumnSettingsLoader: true });
        this.props.saveColumnSettings(OrganizerFilterType.DeliveredOrganizers, this.state.modifiedColumnSettings, this.callback);
    }

    private callback = () => {
        this.setState({ showColumnSettingsLoader: false });
        this.OnCloseColumnSettings();
        VenusNotifier.Success(DeliveredReturnsConstants.ColumnSelectorMessage.SaveColumnSettingsSuccess, null);
    }

    private getCustomColumns = () => {
        const columns = this.getColumns();
        let columnHeader: any[] = columns;
        let order: number = 0;
        let removedColumn: any = null;
        for (let i = 0; i < this.state.customColumnOptions.length; i++) {
            for (let j = 0; j < columnHeader.length; j++) {
                if (this.state.customColumnOptions[i].columnName.trim() == columnHeader[j].header.trim() ||
                    this.state.customColumnOptions[i].columnName.trim() == columnHeader[j].key.trim()) {
                    order = this.state.customColumnOptions[i].columnOrder;
                    columnHeader[j].isHidden = !this.state.customColumnOptions[i].isActive;
                    removedColumn = columnHeader.splice(j, 1);
                    columnHeader.splice(order, 0, removedColumn[0]);
                }
            }
        }

        return columnHeader;
    }

    public render() {
        const { onPageChange, onRowSelect, onSelectAll, onSortChange, pageNo,
            pageSize, selectedRows, totalRows, documents, isArchived
        } = this.props;
        let selected: number[] = [];
        if (selectedRows.length > 0) {
            for (var i in selectedRows) {
                let rowIndex = selectedRows[i];
                selected.push(((pageNo - 1) * pageSize) + rowIndex);
            }
        }

        const options = {
            onSortChange: onSortChange,
            onPageChange: onPageChange,
            sizePerPage: pageSize,
            page: pageNo,
            paginationShowsTotal: this.renderShowTotal,
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar
        };

        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: onRowSelect,
            onSelectAll: onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };
        const searchEnable = true;

        const pagination = true;

        const data: any[] = documents.map((document, index) => {
            return {
                rowIndex: index,
                index: ((pageNo - 1) * pageSize) + index,
                taxpayerName: document.taxpayerName,
                clientId: document.clientId,
                taxpayerEmail: document.taxpayerEmail,
                spouseEmail: document.spouseEmail,
                deliveredOn: document.deliveredOn ? moment(document.deliveredOn).format('MM/DD/YYYY') : '',
                organizerReminder: document.organizerReminder ? moment(document.organizerReminder).format('MM/DD/YYYY') : '',
                signingReminder: document.signingReminder ? moment(document.signingReminder).format('MM/DD/YYYY') : '',
                documentStatus: document.processStatus,
                id: document.id,
                taxYear: document.taxYear,
                ero: document.eroUserName,
                locationName: document.locationName,
                batchName: document.batchName,
                sentBy: document.sentByUserName,
                engagementLetterStatus: document.engagementLetterStatus,
                organizerStatus: document.organizerStatus,
                previousOrganizerStatus: document.previousOrganizerStatus,
                hasOrganizerWithOldCustomQuestion: document.hasOrganizerWithOldCustomQuestion,
                sourceDocumentStatus: document.sourceDocumentStatus,
                isDocumentLocked: document.isDocumentLocked,
                archivedBy: document.archivedByUserName
            }
        });

        const Columns = this.getCustomColumns();

        if (isArchived) {
            const archivedByColumn = {
                header: 'Archived By',
                key: 'archivedBy',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                width: 'auto',
                filter: { type: 'TextFilter', placeholder: 'Archived By', style: { font: 'bold' } } as TextFilter
            }

            const index = Columns.findIndex(x => x.key == "sentBy");
            Columns.splice(index, 1);
            Columns.splice(index, 0, archivedByColumn);
        }

        return <div className="pos-relative">
            <ColumnSettings
                activateOrDeactivateColumn={this.activateOrDeactivateCustomColumn}
                getListItem={this.getListItem}
                onDragStart={this.onDragStart}
                onDropped={this.onDropped}
                onReset={this.onReset}
                OnSaveCustomColumns={this.OnSaveCustomColumns}
                showCustomColumn={this.state.showColumnSettings}
                showLoader={this.state.showColumnSettingsLoader}
            />

            <div>
                <BootstrapTable
                    ref='organizerReportTable'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    selectRow={selectRowProp}
                    containerStyle={{ width: 'fit-content' }}
                    search={searchEnable}>
                    {Columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            className={value.headerClassName}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}>
                            <div title={value.header} className="header-div">
                                {value.header}
                            </div>
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
                <label>{this.props.selectedRowCount} out of {this.props.totalRows} entries selected</label>
            </div>

        </div>
    }
}
