import * as React from 'react'
import { Card, Accordion, Row, Col } from 'react-bootstrap';
import { getClientSignatureControls } from './../../../../../Helper/OrganizerHelperFunctions';
import { DropdownComponent } from './../../../../../../../components/common/controls/DropdownComponent';
import { UserGroup, ControlType, DataType, SignerDropDown, ControlRole } from './../../../../../../models/OrganizerComponentModels';

interface SignaturePanelProps {
    expanded: boolean;
    toggleExpand: () => void;
    title: string;
    group: UserGroup;
    onDragStart: (event: any, controlType: ControlType, dataType: DataType, signerId: number, role: ControlRole) => void;
    onDragEnd: (event: any) => void;
    signers: SignerDropDown[];
    selectedSigner: SignerDropDown;
    onSignerChange: (signerId: number) => void;
    onFocusChange: (focusGroup: UserGroup) => void;
    addCustomInputControls:boolean;
}

const SignaturePanel: React.StatelessComponent<SignaturePanelProps> = 
    ({ expanded, toggleExpand, title, group, onDragEnd, onDragStart, selectedSigner, signers, onSignerChange, onFocusChange, addCustomInputControls }) => {

    const getControls = () => {
        switch (group) {
            case UserGroup.Reciever:
                return getClientSignatureControls(addCustomInputControls).map(control => {
                    return (
                        <ControlComponent
                            caption={control.caption}
                            controlType={control.controlType}
                            dragEnd={onDragEnd}
                            dragStart={onDragStart}
                            helpText={control.helpText}
                            icon={control.icon}
                            key={control.controlType.toString() + '_' + control.dataType.toString()}
                            dataType={control.dataType}
                            signerId={selectedSigner.value}
                            role={selectedSigner.role}
                            draggable={selectedSigner.disabled == undefined? true : selectedSigner.disabled}
                           
                        />
                        );
                });
            case UserGroup.Sender:
                return []
        }
    }

    const getSigners = () => {
        switch (group) {
            case UserGroup.Reciever:
                return <DropdownComponent
                    id="ddlSignerList"
                    options={signers}
                    onChange={onSignerChange}
                    selectedValue={selectedSigner}
                    clearable={false}
                />;
            case UserGroup.Sender:
                return null
        }
    }

    return (
        <Accordion defaultActiveKey="0">
            <Card onToggle={() => toggleExpand()}>
                <Accordion.Toggle style={{ padding: "10px" }} as={Card.Header} variant="link" eventKey="0">
                    <Card.Title as="h4">
                        {title}
                    </Card.Title>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <Card.Body onMouseEnter={() => onFocusChange(group)}>
                        <div className="form-horizontal">
                            <Row className="marB15">
                                <Col sm={12} className="sfSigner">
                                    {getSigners()}
                                </Col>
                            </Row>
                        </div>
                        <div>
                            {getControls()}
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    );
}

interface ControlComponentProps {
    caption: string;
    icon: string;
    controlType: ControlType;
    helpText: string;
    dragStart: (event: any, controlType: ControlType, dataType: DataType, signerId: number, role: ControlRole) => void;
    dragEnd: (event: any) => void;
    dataType: DataType;
    signerId: number;
    role: ControlRole;
    draggable: boolean;
}

const ControlComponent: React.StatelessComponent<ControlComponentProps> = ({ caption, controlType, dragEnd, dragStart, helpText, icon, dataType, signerId, role, draggable }) => {
    return (<Row className="marB15">
        <Col sm={10}>
            {
                <div
                    className="icon-div col-xs-12"
                    data-test-auto="dd8fe022-8c43-45b3-a7c1-efd55bc97814"
                    draggable={!draggable}
                    onDragStart={(event) => { dragStart(event, controlType, dataType, signerId, role) }}
                    onDragEnd={dragEnd}
                >
                    <i className={icon} style={{ backgroundColor: '#008000', color: '#FFFFFF', width: '28px', height: '100%' }}>
                    </i>
                    {caption}
                </div>
            }
        </Col>
        <i className="fa fa-question-circle" style={{ color: '#DAA520' }} title={helpText}></i>
    </Row>);
}

export { SignaturePanel }