import * as React from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { FaStopwatch } from 'react-icons/fa';


type InProcessInfoModalProps = {
    batchName: string;
    openModal: boolean;
    onCancel: () => void;
}

export const InProcessInfoModal = (props: InProcessInfoModalProps) => {

    return (
        <Modal show={props.openModal} className="batch-delivery-inprogress-modal bootbox-body" onHide={props.onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <FaStopwatch className='modal-icon' />
                    Batch Delivery In-Progress
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Row>
                        <Col lg={12}>
                            <p>The Batch Delivery is in-progress. This might take several minutes.
                                Delivered Report for the batch {props.batchName} will be generated shortly.</p>
                        </Col>
                    </Row>
                </div>
            </Modal.Body>
            <Modal.Footer style={{ display: 'initial' }}>
                <Button
                    className="btn-white pull-right"
                    variant='default'
                    onClick={props.onCancel}
                >
                    <i className='glyphicon glyphicon-remove'></i>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
