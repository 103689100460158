import { Reducer, Action } from "redux";
import { actionTypes } from "src/store/ActionTypes";
import { actionTypes as templateActionTypes } from "./ActionTypes";
import {
    CompletedCustomQuestion,
    CompletedCustomQuestions,
    CompletedDeleteCustomQuestion,
    CompletedSetDefaultCustomQuestion,
    CompletedUpdateCustomQuestion,
    CompletedGetTemplateInUse,
    ICustomQuestion,
    ICustomQuestionsState,
    ReceivedCustomQuestion,
    ReceivedCustomQuestions,
    ReceivedDeleteCustomQuestion,
    ReceivedSetDefaultCustomQuestion,
    ReceivedUpdateCustomQuestion,
    ReceivedGetTemplateInUse,
    RequestCustomQuestion,
    RequestCustomQuestions,
    RequestDeleteCustomQuestion,
    RequestSetDefaultCustomQuestion,
    RequestUpdateCustomQuestion,
    RequestGetTemplateInUse,
    apiPrefix,
    customQuestionInitialState,
    customQuestionsInitialState
} from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { NotificationAction, StatusType } from "src/store/common/NotificationStore";
import { AppThunkAction } from "src/store";
import { handleResponse } from "src/store/Library";
import { addTask } from "domain-task";
import { TemplateModalConstants } from "src/components/helper/Constants";

export type DispatchActions =
    | RequestCustomQuestions
    | ReceivedCustomQuestions
    | CompletedCustomQuestions
    | RequestCustomQuestion
    | ReceivedCustomQuestion
    | CompletedCustomQuestion
    | RequestUpdateCustomQuestion
    | ReceivedUpdateCustomQuestion
    | CompletedUpdateCustomQuestion
    | RequestDeleteCustomQuestion
    | ReceivedDeleteCustomQuestion
    | CompletedDeleteCustomQuestion
    | RequestSetDefaultCustomQuestion
    | ReceivedSetDefaultCustomQuestion
    | CompletedSetDefaultCustomQuestion
    | RequestGetTemplateInUse
    | ReceivedGetTemplateInUse
    | CompletedGetTemplateInUse;


type KnownActions = DispatchActions | NotificationAction;

export const actionCreators = {

    getCustomQuestions: (successCallback: (data?: any) => void, failureCallback: () => void): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: templateActionTypes.REQUEST_CUSTOM_QUESTIONS
            });

            let fetchTask = fetch(`${apiPrefix}/questionnaire-templates`, {
                method: "GET",
                credentials: "include"
            })
                .then(handleResponse)
                .then((response) => response)
                .then((data) => {
                    const response: ICustomQuestionsState = {
                        customQuestions: data,
                        isLoading: false
                    };
                    dispatch({
                        type: templateActionTypes.RECEIVED_CUSTOM_QUESTIONS,
                        customQuestions: response
                    });
                    successCallback(data);
                })
                .catch((error) => {
                    dispatch({
                        type: templateActionTypes.COMPLETED_CUSTOM_QUESTIONS
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    failureCallback();
                });
            addTask(fetchTask);
        },

    getCustomQuestion: (id: number, successCallback: (template: any) => void, failureCallback: () => void): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: templateActionTypes.REQUEST_CUSTOM_QUESTION
            });

            let fetchTask = fetch(`${apiPrefix}/questionnaire-forms/${id}`, {
                method: "GET",
                credentials: "include"
            })
                .then(handleResponse)
                .then((response) => response)
                .then((data) => {
                    dispatch({
                        type: templateActionTypes.RECEIVED_CUSTOM_QUESTION,

                    });
                    successCallback(data);
                })
                .catch((error) => {
                    dispatch({
                        type: templateActionTypes.COMPLETED_CUSTOM_QUESTION
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    failureCallback();
                });
            addTask(fetchTask);
        },

    getCustomQuestionTemplate: (id: number, successCallback: (template: any) => void, failureCallback: () => void): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: templateActionTypes.REQUEST_CUSTOM_QUESTION
            });

            let fetchTask = fetch(`${apiPrefix}/organizer-questionnaire-forms/${id}`, {
                method: "GET",
                credentials: "include"
            })
                .then(handleResponse)
                .then((response) => response)
                .then((data) => {
                    dispatch({
                        type: templateActionTypes.RECEIVED_CUSTOM_QUESTION,

                    });
                    successCallback(data);
                })
                .catch((error) => {
                    dispatch({
                        type: templateActionTypes.COMPLETED_CUSTOM_QUESTION
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    failureCallback();
                });
            addTask(fetchTask);
        },

    updateCustomQuestion:
        (template: ICustomQuestion, successCallback: () => void, failureCallback: () => void): AppThunkAction<KnownActions> =>
            (dispatch) => {
                dispatch({
                    type: templateActionTypes.REQUEST_UPDATE_CUSTOM_QUESTION
                });

                let fetchTask = fetch(`${apiPrefix}/questionnaire-templates/${template?.id}`, {
                    method: "PUT",
                    credentials: "include",
                    body: JSON.stringify(template),
                    headers: {
                        Accept: "application/json, */*",
                        "Content-Type": "application/json"
                    }
                })
                    .then((responseJson) => {
                        if (responseJson.ok) {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: TemplateModalConstants.EditSuccessMessage,
                                statusType: StatusType.Success
                            });
                            successCallback();
                        } else {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: "Failed to Rename Template",
                                statusType: StatusType.Error
                            });
                            failureCallback();
                        }
                    })
                    .catch((error) => {
                        dispatch({
                            type: templateActionTypes.COMPLETED_UPDATE_CUSTOM_QUESTION
                        });
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: error.message,
                            statusType: StatusType.Error,
                            statusCode: error?.status
                        });
                        failureCallback();
                    });
                addTask(fetchTask);
            },

    getHasTemplateIsInUseDetails: (templateId: number, successCallback: (data?: any) => void, failureCallback: () => void): AppThunkAction<KnownActions> =>
        (dispatch) => {
            dispatch({
                type: templateActionTypes.REQUEST_GET_TEMPLATE_IN_USE
            });

            let fetchTask = fetch(`${apiPrefix}/organizer-questionnaire-forms/HasTemplateIsInUse/${templateId}`, {
                method: "GET",
                credentials: "include"
            })
                .then(handleResponse)
                .then((response) => response)
                .then((data) => {
                    dispatch({
                        type: templateActionTypes.REQUEST_GET_TEMPLATE_IN_USE
                    });
                    successCallback({
                        isTemplateInUse: data,
                        templateId: templateId
                    });
                })
                .catch((error) => {
                    dispatch({
                        type: templateActionTypes.COMPLETED_GET_TEMPLATE_IN_USE
                    });
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    });
                    failureCallback();
                });
            addTask(fetchTask);
        },

    deleteCustomQuestion:
        (templateId: number, successCallback: () => void, failureCallback: () => void): AppThunkAction<KnownActions> =>
            (dispatch) => {
                dispatch({
                    type: templateActionTypes.REQUEST_DELETE_CUSTOM_QUSETION
                });

                let fetchTask = fetch(`${apiPrefix}/questionnaire-templates/${templateId}`, {
                    method: "DELETE",
                    credentials: "include"
                })
                    .then((responseJson) => {
                        if (responseJson.ok) {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: TemplateModalConstants.DeleteSuccessMessage,
                                statusType: StatusType.Success
                            });
                            successCallback();
                        } else {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: "",
                                statusType: StatusType.Error
                            });
                            failureCallback();
                        }
                    })
                    .catch((error) => {
                        dispatch({
                            type: templateActionTypes.COMPLETED_DELETE_CUSTOM_QUSETION
                        });
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: error.message,
                            statusType: StatusType.Error,
                            statusCode: error?.status
                        });
                        failureCallback();
                    });
                addTask(fetchTask);
            },
    setDefaultCustomQuestionTemplate:
        (templateId: number, isDefault: boolean, successCallback: () => void, failureCallback: () => void): AppThunkAction<KnownActions> =>
            (dispatch) => {
                dispatch({
                    type: templateActionTypes.REQUEST_SET_DEFAULT_CUSTOM_QUESTION
                });
                // HasTemplateIsInUse
                let fetchTask = fetch(`${apiPrefix}/questionnaire-templates/${templateId}/set-default?isDefault=${isDefault}`, {
                    method: "PUT",
                    credentials: "include"
                })
                    .then(handleResponse)
                    .then((response) => response)
                    .then((data) => {
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: isDefault
                                ? TemplateModalConstants.SetDefaultTemplateSuccess
                                : TemplateModalConstants.UnsetDefaultTemplateSuccess,
                            statusType: StatusType.Success
                        });
                        successCallback();
                    })
                    .catch((error) => {
                        dispatch({
                            type: templateActionTypes.COMPLETED_SET_DEFAULT_CUSTOM_QUESTION
                        });
                        dispatch({
                            type: actionTypes.NOTIFICATION,
                            statusMessage: error.message,
                            statusType: StatusType.Error,
                            statusCode: error?.status
                        });
                        failureCallback();
                    });
                addTask(fetchTask);
            },
};

export const reducer: Reducer<any> = (
    customQuestionsState: ICustomQuestionsState = customQuestionsInitialState,
    incomingAction: Action
) => {
    const action = incomingAction as DispatchActions;
    let customQuestionState: ICustomQuestion = customQuestionInitialState;
    switch (action.type) {
        case templateActionTypes.REQUEST_CUSTOM_QUESTIONS:
            customQuestionsState.isLoading = true;
            return customQuestionsState;

        case templateActionTypes.RECEIVED_CUSTOM_QUESTIONS:
            customQuestionsState.customQuestions = action.customQuestions.customQuestions;
            customQuestionsState.isLoading = false;
            return customQuestionsState;

        case templateActionTypes.COMPLETED_CUSTOM_QUESTIONS:
            customQuestionsState.isLoading = false;
            return customQuestionsState;

        case templateActionTypes.REQUEST_UPDATE_CUSTOM_QUESTION:
            return customQuestionState;

        case templateActionTypes.RECEIVED_UPDATE_CUSTOM_QUESTION:
            customQuestionState = action.customQuestion;
            return customQuestionState;

        case templateActionTypes.COMPLETED_UPDATE_CUSTOM_QUESTION:
            return customQuestionState;

        case templateActionTypes.REQUEST_DELETE_CUSTOM_QUSETION:
            return customQuestionsState;

        case templateActionTypes.RECEIVED_DELETE_CUSTOM_QUSETION:
            return customQuestionsState;

        case templateActionTypes.COMPLETED_DELETE_CUSTOM_QUSETION:
            return customQuestionsState;

        case templateActionTypes.REQUEST_GET_TEMPLATE_IN_USE:
            return customQuestionsState;

        case templateActionTypes.RECEIVED_GET_TEMPLATE_IN_USE:
            return customQuestionsState;

        case templateActionTypes.COMPLETED_GET_TEMPLATE_IN_USE:
            return customQuestionsState;
        default:
            return customQuestionsState;
    }
};
