import { ReminderSettings } from "../models/OrganizerComponentModels";

export class AutomaticReminderViewModel {

    reminderOrganizerList: AutomaticReminderOrganizerInfo[];
    reminderSettings: ReminderSettings;

    
    constructor(reminderOrganizerList: AutomaticReminderOrganizerInfo[],
        reminderSettings: ReminderSettings) {
        this.reminderOrganizerList = reminderOrganizerList;
        this.reminderSettings = reminderSettings;
    }
}

export class AutomaticReminderOrganizerInfo {

    organizerId: number;
    organizerDeliveredOn: Date;
    signatureStatus: number;
    organizerStatus: number;
    esignLastReminderDate: Date;
    organizerLastReminderDate: Date;

    constructor(organizerId: number, deliveredOn: Date, signatureStatus: number, organizerStatus: number, esignLastReminderDate: Date, organizerLastReminderDate: Date) {
        this.organizerId = organizerId;
        this.organizerDeliveredOn = deliveredOn;
        this.signatureStatus = signatureStatus;
        this.organizerStatus = organizerStatus;
        this.esignLastReminderDate = esignLastReminderDate;
        this.organizerLastReminderDate = organizerLastReminderDate;
    }
}
