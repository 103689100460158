import * as React from "react";

interface CustomDateFilterProps {
    onRef: any;
    calendarContainer?: any;
    calendarPlacement?: any;
    filterHandler: any;
    customFilterParameters?: any;
}

interface CustomDateFilterState {
    selectedDate: any;
}

export class CustomDateFilter extends React.Component<CustomDateFilterProps, CustomDateFilterState> {
    private statusFilter: any;

    constructor(props: any) {
        super(props);
        this.state = {
            selectedDate: undefined,
        };
    }

    public UNSAFE_componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
    }

    public render() {
        return <div>
            <CustomRCDatePicker
                value={this.state.selectedDate}
                ref={(instance: any) => { this.setDateFilterRef(instance); }}
                onChange={this.onStatusChange}
                calendarContainer={this.props.calendarContainer}
                calendarPlacement={this.props.calendarPlacement}
                clearFilter={this.cleanFiltered}
            />
        </div>;
    }

    public setDateFilterRef = (stausFilterRef: any) => {
        this.statusFilter = stausFilterRef;
    }

    public applyFilter = (value: any) => {
        this.setState({
            selectedDate: value,
        }, () => { this.props.filterHandler(this.state.selectedDate); });
    }

    public cleanFiltered = () => {
        this.setState({
            selectedDate: undefined,
        }, () => {
            this.props.filterHandler();
        });
    }

    private onStatusChange = (date?: Date) => {

        if (this.state.selectedDate != date) {
            this.setState({
                selectedDate: date,
            }, () => {
                this.props.filterHandler(date, "CustomFilter");
            });
        }
    }
}


import * as moment from 'moment';
import MaskedInput from 'react-text-mask'
import "rc-datepicker/lib/style.css";
import { DatePicker } from 'rc-datepicker';

export interface CustomRCDatePickerProps {
    value?: Date;
    onChange?: (date?: Date) => void;
    disabled?: boolean;
    calendarContainer?: any;
    calendarPlacement?: string;
    minDate?: Date;
    maxDate?: Date;
    clearFilter?: any;
}

export interface CustomRCDatePickerState {
    value: Date | string | undefined;
    inputDate: string;
    calendarState: boolean;
}

const DATE_FORMAT = "MM/DD/YYYY";

class CustomRCDatePicker extends React.Component<CustomRCDatePickerProps, CustomRCDatePickerState> {

    constructor(props: any) {
        super(props);

        this.state = {
            value: undefined,
            inputDate: "",
            calendarState: false,
        }

        this.calendarRender = this.calendarRender.bind(this);
        this.onChangeCustom = this.onChangeCustom.bind(this);
        this.changeInput = this.changeInput.bind(this);
        this.clearDate = this.clearDate.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps: CustomRCDatePickerProps) {
        const date = nextProps.value && moment(nextProps.value);
        const value = nextProps.value && date && date.isValid() ? date.format('YYYY-MM-DD[T]HH:mm:ss.SSS') : undefined;
        if (value != this.state.value) {
            let tempdate = moment(value).format("MM/DD/YYYY");
            this.setState({ value: value, inputDate: tempdate });
        }
    }

    changeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let dateVal = e.target.value;
        if (!this.state.calendarState) {
            this.setState({ calendarState: true });
        }

        if (dateVal.length === 10 && moment(dateVal).isValid()) {

            let splitDate = dateVal.split("/");
            let formattedDate: any = moment(
                splitDate[2] + "-" + splitDate[0] + "-" + splitDate[1] + "T00:00:00Z").toISOString(true);
            this.setState({ value: formattedDate, inputDate: dateVal, calendarState: false }, () => {
                if (this.props.onChange) {
                    this.props.onChange(formattedDate && new Date(formattedDate));
                }
            });
        }


    }

    onChangeCustom(date: Date) {

        let formattedDate = moment(date.toString()).format("MM/DD/YYYY");
        let splitDate = formattedDate.split("/");
        let finalDate: any = moment(
            splitDate[2] + "-" + splitDate[0] + "-" + splitDate[1] + "T00:00:00Z").toISOString(true);
        this.setState({ value: date, inputDate: formattedDate, calendarState: false }, () => {
            if (this.props.onChange) {
                this.props.onChange(finalDate && new Date(finalDate));
            }
        });
    };

    clearDate() {
        this.setState({ value: undefined, inputDate: "" }, () => {
            this.props.clearFilter ?
                this.props.clearFilter() : ""
        });
    }

    handleBlur() {
        this.setState({ calendarState: false });
    }

    private calendarRender = (DatePickerComp: any) => {
        let MaskComp = MaskedInput as any;
        return (<div className="calendarContainer" onBlur={this.handleBlur}>
            <div className="calendarClass">
                <MaskComp
                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                    placeholder={DATE_FORMAT}
                    type="text"
                    value={this.state.inputDate}
                    onClick={() => {
                        this.setState({ calendarState: true })
                    }}
                    className="inputClass form-control"
                    onKeyUp={null}
                    onChange={this.changeInput}
                />
                <span
                    className="minusClass"
                    onClick={this.clearDate}
                >
                    x
          </span>
            </div>
            {this.state.calendarState ? (
                <div className="calendarInput" id="dateId"
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}>
                    <DatePickerComp
                        value={this.state.value}
                        disabled={this.props.disabled}
                        onChange={this.onChangeCustom}
                        className='group-input dateClass'
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                    />
                </div>
            ) : (
                    ""
                )}
        </div>)

    }

    public render() {

        const DatePickerPart = DatePicker as any;
        return (

            <div className='control-pane' >
                <div className='control-section'>
                    <div className='datepicker-control-section' id="datepicker_input">
                        {this.calendarRender(DatePickerPart)}
                    </div>
                </div>
            </div>
        );
    }
}

export { CustomRCDatePicker };
