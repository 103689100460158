import { PDFDocumentProxy, PDFJSStatic, PDFPageProxy } from 'pdfjs-dist';


const PDFJS: PDFJSStatic = require('pdfjs-dist');

export interface IPdfDocumentFacade {
    append: (document: PDFDocumentProxy, path: string) => void;
    appendSubDocument: (subDocument: ISubDocument) => void;
    removePages: (pages: number[]) => void;
    removePageRecursively: (page: number) => void;
    getPage: (pageNo: number) => Promise<PDFPageProxy>;
    getLastPage: () => number;
    getSubDocuments: () => ISubDocument[];
};

export interface IFacadeViewModel {
    documentId: number;
    subDocuments: ISubDocument[];
    bookmarks: any;
} 

export interface ISubDocument {
    document: PDFDocumentProxy;
    path: string;
    fileName: string;
    subDocType: SubDocType;
    pageMap: IPageMap[];
    downloadUrl: string;
}

export interface IPageMap {
    subDocPageNum: number;
    facadePageNum: number;
}

export enum SubDocType {
    None = 0,
    Original,
    Appended,
    Additional
}

export class PdfDocumentFacade implements IPdfDocumentFacade {
    private parts: ISubDocument[] = [];
    private pages: PDFPageProxy[] = [];
    constructor() {
    }

    public appendSubDocument = (subDocument: ISubDocument) => {
        subDocument.pageMap.unshift({
            facadePageNum: -1,
            subDocPageNum: -1
        });
        this.parts.push(subDocument);
    }
    public append = (document: PDFDocumentProxy, path: string) => {

        let subDoc: ISubDocument = {
            document: document,
            path: "",
            pageMap: [],
            downloadUrl: "",
            fileName: path,
            subDocType: SubDocType.Appended
        }
        let pages = document.numPages;
        let lastPage = this.getLastPage();
        //subDoc.pageMap.push(-1);
        subDoc.pageMap.push({
            facadePageNum: -1,
            subDocPageNum: -1
        });
        for (var i = 1; i <= pages; i++) {
            subDoc.pageMap.push({
                facadePageNum: lastPage + i,
                subDocPageNum: i
            });
        }

        this.parts.push(subDoc);
    }


    public removePages = (pages: number[]) => {
        let removed = 0;
        for (var index in this.parts) {

            let pageMap = this.parts[index].pageMap.filter((page) =>
                pages.find((p) => p === page.facadePageNum) === undefined);
            removed += this.parts[index].pageMap.length - pageMap.length;

            this.parts[index].pageMap = pageMap;
        }

        this.clearPages(pages);

        if (removed !== pages.length) {
            console.warn("Error in removePages");
        }
        return removed;
    };

    public removePageRecursively = (page: number) => {
        for (const index in this.parts) {
            const pageMap = this.parts[index].pageMap.filter((pageMap) =>
                (page !== pageMap.facadePageNum));
            this.parts[index].pageMap = pageMap;
        }

        for (const index in this.parts) {
            const pageMap = this.parts[index].pageMap.filter((pageMap) => {
                if (pageMap.facadePageNum > page) {
                    pageMap.facadePageNum -= 1;
                }
                return pageMap;
            });
            this.parts[index].pageMap = pageMap;
        }

        this.clearPageRecursively(page);

    }

    private clearPageRecursively = (page: number) => {
        this.pages.splice(page-1, 1);
    }

    public getPage = (pageNo: number) => {
        return new Promise<PDFPageProxy>((resolve, reject) => {
            let doc: PDFDocumentProxy;
            let ret = this.getSubDocument(pageNo);
            if (ret) {
                doc = ret.document as PDFDocumentProxy;
                try {
                    //If already stocked then return it
                    if (this.pages[pageNo]) {
                        resolve(this.pages[pageNo]);
                    }
                    doc.getPage(ret.pageNo).then((page: PDFPageProxy) => {
                        //Add to stock before returning
                        this.pages[pageNo] = page;
                        resolve(page);
                    }, reject);
                } catch (e) {
                    reject(e);
                }
            } else {
                reject("Couldn't find the page: " + pageNo);
            }
        });
    };


    public getLastPage = ():number => {
        let lastPage = 0;
        this.parts.map((subDoc) => {
            subDoc.pageMap.map((page) => {
                if (page.facadePageNum > lastPage) {
                    lastPage = page.facadePageNum;
                }
            })
        });
        return lastPage;
    }


    private getSubDocument = (pageNo: number) => {
        for (var index in this.parts) {
            let found = this.parts[index].pageMap.findIndex((page) => page.facadePageNum === pageNo);
            if (found!=-1) {
                return { document: this.parts[index].document, pageNo: this.parts[index].pageMap[found].subDocPageNum };
            }
        }
        return undefined;
    }


    private clearPages = (pages: number[]) => {
        pages.map((pageNo: number) => {
            delete this.pages[pageNo];
            //this.pages[pageNo] = undefined;
        });
    }

    public getSubDocuments = ()=> {
        return this.parts;
    }
}