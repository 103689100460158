import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';
import { IDelegatedEROSigner } from '../../components/common/ProcessReturnModal/ProcessReturnModels';
import * as Helper from '../../components/helper/Constants';
import { DefaultMailingAddress, IUserDefaultSettings, IUserDefaultSettingsStates } from '../../components/navigation/profile/MySettings';
import { IUserNotificationSettings } from '../../Core/ViewModels/User/NotificationSettingModel';
import { actionTypes } from '../ActionTypes';
import { NotificationAction, StatusType } from '../common/NotificationStore';
import { handleResponse } from '../Library';
import { apiPrefix } from 'src/components/helper/HelperFunctions';
import { API_BASE_URL } from 'src/utils/constants';

export interface UserSettings {
    settings: IUserDefaultSettingsStates;
    addresses: DefaultMailingAddress[];
    delegatedSigners: IDelegatedEROSigner[];
    error: boolean;
    isLoading: boolean;
    message: string;
    notificationSettings: IUserNotificationSettings
}

interface RequestUserSettingAction {
    type: actionTypes.REQUEST_USER_SETTINGS;
}

interface ReceiveUserSettingAction {
    type: actionTypes.RECEIVE_USER_SETTINGS;
    settings: IUserDefaultSettingsStates;
}

interface ErrorUserSettingAction {
    type: actionTypes.ERROR_USER_SETTINGS;
    message: string;
}


interface ReceiveDelegatedSignersAction {
    type: actionTypes.RECEIVE_DELEGATED_SIGNERS;
    delegatedSigners: IDelegatedEROSigner[];
}

interface UpdateDocumentOrderAction {
    type: actionTypes.UPDATE_DOCUMENTORDER;
}

interface ReceiveUserNotificationSettingAction {
    type: actionTypes.RECEIVE_USER_NOTIFICATION_SETTINGS;
    notificationSettings: IUserNotificationSettings;
}

interface RequestUserNotificationSettingAction {
    type: actionTypes.REQUEST_USER_NOTIFICATION_SETTINGS;
}

interface ErrorUserNotificationSettingAction {
    type: actionTypes.ERROR_USER_NOTIFICATION_SETTINGS;
    message: string;
}

interface ReceiveUserDefaultSettingAction {
    type: actionTypes.RECEIVE_USER_DEFAULT_SETTINGS;
    settings: IUserDefaultSettings;
}
interface ReceiveDelegatedSignersAction {
    type: actionTypes.RECEIVE_DELEGATED_SIGNERS;
    delegatedSigners: IDelegatedEROSigner[];
}


type KnownAction =
    RequestUserSettingAction |
    ReceiveUserSettingAction |
    ReceiveDelegatedSignersAction |
    ErrorUserSettingAction |
    NotificationAction |
    UpdateDocumentOrderAction |
    RequestUserNotificationSettingAction |
    ReceiveUserNotificationSettingAction |
    ErrorUserNotificationSettingAction |
    ReceiveDelegatedSignersAction |
    ReceiveUserDefaultSettingAction|
     ReceiveDelegatedSignersAction ;

export const actionCreators = {
    requestUserSettings: (reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().userSettings.settings) {
            let fetchTask = fetch(`${apiPrefix}UserSettings/UserSetting`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IUserDefaultSettingsStates>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.fetchUserSettings });
                });
            addTask(fetchTask);
            //dispatch({ type: actionTypes.REQUEST_USER_SETTINGS });
        }
    },
    requestUserDefaultSettings: (reload: boolean = false, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        if (reload || !getState().userSettings.settings) {
            let fetchTask = fetch(`${API_BASE_URL}api/UserSettings/Default`, {
                method: 'GET',
                credentials: 'include'
            })
                .then(handleResponse)
                .then(json => json as Promise<IUserDefaultSettings>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_USER_DEFAULT_SETTINGS, settings: data });
                    if (callback) {
                        callback();
                    }
                })
                .catch(error => {
                    dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.fetchUserSettings });
                });
            addTask(fetchTask);
        }
    },

    saveUserSettings: (settings: IUserDefaultSettingsStates, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = fetch(`${API_BASE_URL}api/UserSettings`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify(settings),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(json => json as Promise<IUserDefaultSettingsStates>)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_USER_SETTINGS, settings: settings });
                if (callback) {
                    callback();
                }

                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error, statusCode: error?.status });
            });
        addTask(fetchTask);
    },saveUserDefaultSettings: (userId: number, settings: IUserDefaultSettings, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getState) => {

        let fetchTask = fetch(`${API_BASE_URL}api/UserSettings/Default/${userId}`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(settings),
            headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json; charset=utf-8'
            }
        })
            .then(handleResponse)
            .then(data => {
                dispatch({ type: actionTypes.RECEIVE_USER_DEFAULT_SETTINGS, settings: settings });
                if (callback) {
                    callback();
                }

                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsSuccess, statusType: StatusType.Success });
            })
            .catch(error => {
                dispatch({ type: actionTypes.ERROR_USER_SETTINGS, message: Helper.UserSettings.saveUserSettingsError });
                dispatch({ type: actionTypes.NOTIFICATION, statusMessage: Helper.UserSettings.saveUserSettingsError, statusType: StatusType.Error, statusCode: error?.status });
            });
        addTask(fetchTask);
    },
    requestDelegatedSigners: (userId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let state = getState().userSettings;
        if (state.delegatedSigners && userId != 0) {
            const fetchTask = fetch(`${API_BASE_URL}api/UserManagement/GetAllDelegatedEroSigners/?id=` + userId, { credentials: 'include' })
                .then(
                    response => response.json() as Promise<Array<IDelegatedEROSigner>>)
                .then(data => {
                    dispatch({ type: actionTypes.RECEIVE_DELEGATED_SIGNERS, delegatedSigners: data });
                })
                .catch(error => {

                });

            addTask(fetchTask);
        }
    }
};

const unloadedState: UserSettings = {
    settings: {},
    addresses: {},
    delegatedSigners: {},
    notificationSettings: {}
} as UserSettings;

export const reducer: Reducer<UserSettings> = (state: UserSettings = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_USER_SETTINGS:
            let cleanSettings: UserSettings = { ...state };
            cleanSettings.settings = unloadedState.settings;
            cleanSettings.error = false;
            cleanSettings.isLoading = true;
            cleanSettings.message = '';
            return cleanSettings;
        case actionTypes.RECEIVE_USER_SETTINGS:
            let updated: UserSettings = { ...state };
            const defaultUserSettings = updated.settings.defaultUserSettings;
            updated.error = false;
            updated.settings = action.settings;
            updated.settings.defaultUserSettings = defaultUserSettings;
            updated.isLoading = false;
            updated.message = Date();
            return updated;
        case actionTypes.RECEIVE_USER_DEFAULT_SETTINGS:
            let settings: UserSettings = { ...state };
            settings.error = false;
            settings.settings.defaultUserSettings = action.settings;
            settings.isLoading = false;
            settings.message = Date();
            return settings;
        case actionTypes.RECEIVE_DELEGATED_SIGNERS:
            var received: UserSettings = { ...state };
            received.delegatedSigners = action.delegatedSigners;
            received.error = false;
            received.isLoading = false;
            received.message = Date();
            return received;
        case actionTypes.ERROR_USER_SETTINGS:
            return {
                ...state,
                isLoading: false,
                error: true,
                message: action.message
            } as UserSettings;
        case actionTypes.RECEIVE_USER_NOTIFICATION_SETTINGS:
            var receivedUserSettings: UserSettings = { ...state };
            receivedUserSettings.notificationSettings = action.notificationSettings;
            receivedUserSettings.error = false;
            receivedUserSettings.isLoading = false;
            receivedUserSettings.message = Date();
            return receivedUserSettings;
    }

    return state || unloadedState;
};
