import React from "react";
import { Card, Dropdown } from "react-bootstrap";
import { ICustomQuestion, ITemplateModalData } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { MoreOptionsIcon, StarIcon, StarFilledIcon } from "src/assets/Icon";
import { FileIcon } from "src/assets/SvgIconCollection";

export interface TemplateProps {
    children?: React.ReactNode;
    data: ICustomQuestion;
    isMenuOpen?: boolean;
    onTemplateClick: Function;
    onMenuClick: Function;
    setDefaultTemplate: Function;
}

const popperConfigObj = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [20, 10]
            }
        }
    ]
};
const moreOptionsItems = [{ title: 'Edit', enabled: true },
{ title: 'Rename', enabled: true },
{ title: 'Delete', enabled: true }];

const MoreOptions = (props) => {
    return <Dropdown className="template-more-options" drop="down">
        <Dropdown.Toggle id="dropdown-basic" className="template-more-options">
            <MoreOptionsIcon key={props.id} />
        </Dropdown.Toggle>

        <Dropdown.Menu
            popperConfig={popperConfigObj}
            className="template-more-options-menu">
            {moreOptionsItems.length > 0 &&
                moreOptionsItems.map((menuItem, index) => {
                    return <Dropdown.Item onClick={() => props.onMenuClick(index)}
                        className={menuItem.enabled ? "template-menu-item" : "template-menu-item-disabled"}
                    >
                        <label className={menuItem.title === "Delete" ? "template-menu-item-label red" : "template-menu-item-label"}> {menuItem.title}</label>
                    </Dropdown.Item>
                })
            }
        </Dropdown.Menu>
    </Dropdown>
}

const Template: React.FC<TemplateProps> = (props) => {

    const onDefaultButtonClick = (id: number) => {
        props.setDefaultTemplate(id, !props.data?.isDefault);
    }

    const onMenuClick = (index: number) => {
        if (props.onMenuClick) {
            props.onMenuClick(props.data, index);
        }
    }

    return <div className='template-container'>
        <Card className='template' onDoubleClick={() => props.onTemplateClick(props.data)}>
            <FileIcon />
            <MoreOptions onMenuClick={onMenuClick} id={[props.data?.id]} />
        </Card>
        <div className='template-details'>
            <div className='template-title'>
                <label className='template-name' title={props.data?.name}>{props.data?.name}</label>
                <button className='default-template-button' onClick={() => onDefaultButtonClick(props.data?.id)}>
                    {props.data?.isDefault ? <StarFilledIcon /> : <StarIcon />}
                </button>
            </div>
            <label className='template-description' title={props.data?.description}>{props.data?.description}</label>
        </div>
    </div>
}
export default Template;