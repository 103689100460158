export const getDaysOptions = () => {
    const options = [{ label: `1 day`, value: 1 }];
    for (let i = 2; i <= 30; i++) {
        options.push({ label: `${i} days`, value: i })
    }
    return options;
}

export const isAutoReminderSelected = (rs: any) => (!!rs?.esignReminder?.enabled && rs?.organizerReminder?.enabled);

export const convertToJSON = (data) => {
    let cache: any = [];
    let str = JSON.stringify(data, function (key, value: any) {
        if (typeof value === "object" && value !== null) {
            if (cache.indexOf(value) !== -1) {
                return;
            }
            cache.push(value);
        }
        return value;
    });
    cache = null;
    return str;
}
