import { Action, Reducer } from 'redux';
import { actionTypes } from './../../store/ActionTypes';
import { NotificationAction } from './../../store/common/NotificationStore';
import {
    PagedBatchOrganizerStoreState,
    initialPagedBatchOrganizerStoreState,
    RecievePagedBatchOrganizerAction,
    ClearPagedBatchOrganizerAction
} from './../models/OrganizerStoreModels';

type KnownActions =
    RecievePagedBatchOrganizerAction |
    ClearPagedBatchOrganizerAction |
    NotificationAction;

export const reducer: Reducer<PagedBatchOrganizerStoreState> = (state: PagedBatchOrganizerStoreState = initialPagedBatchOrganizerStoreState, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.RECEIVE_PAGED_BATCH_ORGANIZERS: {
            const pagedOrganizers = { ...state };
            pagedOrganizers.organizers[action.query] = action.organizers;
            pagedOrganizers.query = action.query;
            return pagedOrganizers;
        }

        case actionTypes.CLEAR_PAGED_BATCH_ORGANIZERS: {
            return {
                ...initialPagedBatchOrganizerStoreState,
                organizers: {}
            };
        }

            
    }
    return state || initialPagedBatchOrganizerStoreState;
}