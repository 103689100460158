import { CustomMultiSelect } from "./../../../../components/common/MultipleSelectComponent";
import { ReportFilterConstants } from '../../../../components/helper/Constants';
import { BatchModel, BatchStatus, BatchStatusList, EngagementList } from './../../../models/OrganizerComponentModels';
import moment from 'moment';
import React from 'react';
import * as ReactDom from 'react-dom';
import { Button, Row } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,ToolBarProps, Options } from 'react-bootstrap-table';
import { getEngagementType, getOrganizersTaxYearList } from '../../Helper/OrganizerHelperFunctions';
import { UndeliveredBatchOrganizerAction } from './UndeliveredBatchOrganizerAction';
import { CustomDateFilter } from './CustomDateFilter';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";

export interface UndeliveredBatchOrganizerReportTableState {
    exportingState: boolean;
}

export interface UndeliveredBatchOrganizerReportTableProps {
    batches: BatchModel[];
    isLoading: boolean;
    onPageChange: any;
    onSortChange: any;
    onFilterChange: any;
    pageNo: number;
    totalRows: number;
    pageSize: number;
    pageReload: () => void;
    refreshDelay?: boolean;

    onBatchDeleteOpen: (rowIndex: number) => void;
    onBatchDownloadOpen: (rowIndex: number) => void;
    onExportToExcel(onExportToComplete: () => void): void;
}

export class UndeliveredBatchOrganizerReportTable extends React.Component<UndeliveredBatchOrganizerReportTableProps, UndeliveredBatchOrganizerReportTableState>{

    public taxYearList: any;
    public customTaxYearMultiSelect: any;
    public customDocumentStatusMultiSelect: any;
    private customDeliveredOnFilter: any;

    constructor(props: any) {
        super(props)
        this.state = {
            exportingState: false
        }
    }

    componentWillMount() {
        this.taxYearList = getOrganizersTaxYearList();
    }

    onExportToComplete = () => {
        this.setState({ exportingState: false });
    }

    createCustomToolBar = (props: ToolBarProps) => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15" style={{ height: 33 }}>
                <Row>
                    <div className="filters">
                        <Button
                            variant="default"
                            disabled={this.props.refreshDelay}
                            onClick={this.props.pageReload}
                            title="Refresh"
                            className="Refresh-button btn-only marR05"
                            style={{ paddingLeft: '12px' }}
                            data-test-auto="11BC25B0-F325-4170-0000-598384364972">
                            <span className={(this.props.refreshDelay ? "fa-spin " : " ") + 'fas fa-redo-alt fa-btn-icon relaod-span'}>
                            </span>
                        </Button>
                        <Button
                            variant="default"
                            className="btn-only marR05"
                            title={ReportFilterConstants.ControlsLabel.ExportToExcel}
                            onClick={() => { this.setState({ exportingState: true }, () => { this.props.onExportToExcel(this.onExportToComplete); }) }}
                            disabled={this.state.exportingState || this.props.batches.length === 0}
                            data-resource-id = {ResourceIdConstants.ExportToExcelUndeliveredBatchToolBar}
                            data-test-auto="D04B9868-CF8B-4832-B0F3-E36BE8F836F4">
                            <span className={this.state.exportingState ? 'fas fa-spinner fa-spin text-info' : 'fas fa-file-export'} ></span>
                        </Button>
                    </div>
                </Row>
            </div>
        );
    }

    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p className="bootbox-body">
                Showing {start} to {to} of {total} entries
            </p>
        )
    }

    setNoContent = () => {
        return "No reports found"
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    documentStatusFormatter = (cell: any, row: any) => {
        const status: any = row.documentStatus;
        const statusType: "None" | "Uploaded" | "Delivered" | "PartialSuccess" | "InProgress" | "PartialDeliver" | "Error" =
            (typeof status === "number") ? BatchStatus[status] : status;
        return <span title={BatchStatusList[statusType].toUpperCase()} className="ellipsis">{BatchStatusList[statusType].toUpperCase()}</span>;
    }

    getDocumentStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customDocumentStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    engagementTypeFormatter = (cell: any, row: any) => {
        const type: string = row.engagementType;
        const typeString = getEngagementType(type);
        return <span title={typeString} className="ellipsis">{typeString}</span>;
    }

    getTaxYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    DeliveredDateFilter = (filterHandler: any, customFilterParameters: any) => {
        return (<CustomDateFilter
            onRef={(ref: any) => (this.customDeliveredOnFilter = ref)}
            filterHandler={filterHandler}
            customFilterParameters={customFilterParameters}
            calendarContainer={ReactDom.findDOMNode(this.refs.undeliveredOrganizerReportTable)} />);
    }

    actionFormatter = (cell: any, row: any) => {
        const { onBatchDeleteOpen, onBatchDownloadOpen } = this.props;
        return <UndeliveredBatchOrganizerAction
            onBatchDeleteOpen={onBatchDeleteOpen}
            onBatchDownloadOpen={onBatchDownloadOpen}
            rowIndex={row.rowIndex}
        />;
    }
    
    render() {

        const { onPageChange, onSortChange, pageNo, pageSize, totalRows, batches, onFilterChange } = this.props;

        const DocumentStatusOptionsList = [
            { label: 'PARTIALLY DELIVERED', value: 7 },
        ]

        const options: Options = {
            onSortChange: onSortChange,
            onPageChange: onPageChange,
            sizePerPage: pageSize,
            page: pageNo,
            paginationShowsTotal: this.renderShowTotal,
            onFilterChange: onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar
        };

        const Columns: any[] = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                width: 'auto',
                expandable: false,
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Batch Name',
                key: 'name',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: 'overflowHidden',
                width: 'auto',
                expandable: false,
                filter: { type: 'TextFilter', placeholder: 'Batch Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Status',
                key: 'documentStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.documentStatusFormatter,
                width: 'auto',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                expandable: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getDocumentStatusMultiSelectDropDown,
                    customFilterParameters: { options: DocumentStatusOptionsList, enableAllFilter: true, placeholder: 'Select Batch Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Date',
                key: 'deliveredDate',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: 'date-picker-column',
                width: 'auto',
                expandable: false,
                filter: { type: 'CustomFilter', getElement: this.DeliveredDateFilter } as CustomFilter
            },
            {
                header: 'Return Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.engagementTypeFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                width: 'auto',
                expandable: false,
                filter: { type: 'SelectFilter', options: EngagementList, enableAllFilter: true, defaultValue: EngagementList[1] } as SelectFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataSort: true,
                dataFormat: this.defaultFormatter,
                width: 'auto',
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                expandable: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getTaxYearMultiSelectDropDown,
                    customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Tax Year...' } as any
                } as CustomFilter
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: '155px',
                expandable: false,
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];

        const data: any[] = batches.map((document, index) => {
            return {
                rowIndex: index,
                index: ((pageNo - 1) * pageSize) + index,
                name: document.batchName,
                deliveredDate: document.deliveredOn ? moment(document.deliveredOn).format('MM/DD/YYYY') : '',
                documentStatus: document.status,
                id: document.id,
                taxYear: document.taxYear,
                engagementType: document.engagementType
            }
        });

        return (
            <div className="pos-relative organizer-delivered-content batch">
                <div data-test-auto="674639ba-05f1-4e67-9da8-0055ba388ac2">
                    {Columns.length > 0 &&
                        <BootstrapTable
                            ref='undeliveredOrganizerReportTable'
                            data={data}
                            remote={true}
                            fetchInfo={{ dataTotalSize: totalRows }}
                            options={options}
                            hover={true}
                            pagination={true}
                            search={true}
                            containerStyle={{ width: 'fit-content' }}
                            striped
                        >
                            {Columns.map((value, index) => {
                                return <TableHeaderColumn
                                    key={index}
                                    ref={value.key}
                                    isKey={value.isKey}
                                    dataField={value.key}
                                    hidden={value.isHidden}
                                    width={value.width}
                                    dataFormat={value.dataFormat}
                                    columnClassName={value.columnClassName}
                                    columnTitle={value.toolTip}
                                    filter={value.filter}
                                    dataSort={value.dataSort}
                                    expandable={value.expandable}
                                >{value.header}
                                </TableHeaderColumn>;
                            })}
                        </BootstrapTable>
                    }
                </div>
            </div>)
    }
}