import { Action, Reducer } from 'redux';
import { MessageDetails } from '../../Core/ViewModels/CompanySettings/SavedMessageViewModel';
import { actionTypes } from '../ActionTypes';
import { NotificationAction } from '../common/NotificationStore';

export interface ISavedMessageDictionary {
    [index: number]: SavedMessageState;
}

export interface SavedMessageStoreState {
    data: ISavedMessageDictionary;
    isLoading: boolean;
}

export interface SavedMessageState {
    messageDetails: MessageDetails;
}

interface RequestMessasgeListAction {
    type: actionTypes.REQUEST_SAVED_MESSAGES;
}

interface ReceiveMessageListAction {
    type: actionTypes.RECEIVE_SAVED_MESSAGES;
    messageDetails: MessageDetails;
    messageId: number;
}

interface DeleteMessageDetail {
    type: actionTypes.DELETE_SAVED_MESSAGE;
    messageDetail: MessageDetails;
}

type KnownAction =
    RequestMessasgeListAction |
    ReceiveMessageListAction |
    DeleteMessageDetail |
    NotificationAction

export const actionCreators = {
    
}

const savedMessageDic: ISavedMessageDictionary = [];

const unloadedState: SavedMessageStoreState =
    {
        data: { ...savedMessageDic },
        isLoading: true
    } as SavedMessageStoreState;

export const reducer: Reducer<SavedMessageStoreState> = (state: SavedMessageStoreState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case actionTypes.REQUEST_SAVED_MESSAGES:
            return {
                ...unloadedState
            } as SavedMessageStoreState;
        case actionTypes.RECEIVE_SAVED_MESSAGES:
            let received: SavedMessageStoreState = { ...state };
            received.data[action.messageId] = { messageDetails: action.messageDetails }
            received.isLoading = false;
            return received;
        case actionTypes.DELETE_SAVED_MESSAGE:
            let deleteMessage: SavedMessageStoreState = { ...state }
            if (deleteMessage.data[action.messageDetail.id]) {
                delete deleteMessage.data[action.messageDetail.id];
                deleteMessage.isLoading = false;
            }
            return deleteMessage;
    }
    return state || unloadedState;
};