import * as React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { GetFormatedDateTime } from '../../../../components/helper/HelperFunctions';
import { OverlayLoader } from '../../../../components/helper/OverlayLoader';
import { OrganizerClient, SignatureStatus, SignatureStatusList } from '../../../models/OrganizerComponentModels';

interface SignedDetailsModalProps {
    show: boolean;
    onClose: () => void;
    model: OrganizerClient[];
}
interface SignedDetailsModalColumnValues {
    signerName: string;
    signerEmail: string;
    signedStatus: string;
    signedDate: Date;
}

const SignedDetailsModal: React.StatelessComponent<SignedDetailsModalProps> = ({ model, onClose, show }) => {

    const defaultType = (cell: any, row: any) => {
        return cell;
    }

    const signerDateType = (cell: any, row: any) => {
        if (cell === "N/A")
            return "N/A";
        else
            return cell;
    }

    const signedDetailsColumn = [
        {
            header: 'Signer Name',
            key: 'signerName',
            isKey: false,
            dataFormat: defaultType,
            width: 'auto',
            toolTip: true,
        },
        {
            header: 'Signer Email',
            key: 'signerEmail',
            isKey: false,
            dataFormat: defaultType,
            width: 'auto',
            toolTip: true,
        },
        {
            header: 'Signed Status',
            key: 'signedStatus',
            isKey: true,
            dataFormat: defaultType,
            width: 'auto',
            toolTip: true,
        },
        {
            header: 'Date',
            key: 'signedDate',
            isKey: false,
            dataFormat: signerDateType,
            width: 'auto',
            toolTip: true,
        }
    ];

    const signedDetailsData: SignedDetailsModalColumnValues[] = [];

    if (model.length) {
        model.map((value) => {
            signedDetailsData.push({
                signerName: value.name,
                signerEmail: value.email,
                signedStatus: SignatureStatusList[SignatureStatus[value.signatureStatus]],
                signedDate: value.signedOn ? GetFormatedDateTime(value.signedOn) : "N/A"
            });
        });
    }

    const modalBody = model.length ?
        (
            <BootstrapTable data={signedDetailsData}>
                {signedDetailsColumn.map((value, index) => {
                    return <TableHeaderColumn
                        key={index}
                        isKey={value.isKey}
                        dataField={value.key}
                        dataFormat={value.dataFormat}
                        width={value.width}
                        columnTitle={value.toolTip}>
                        {value.header}
                    </TableHeaderColumn>;
                })}
            </BootstrapTable>) :
        (<OverlayLoader
            show={show}
            text={"Loading, please wait..."} />);

    return <Modal className="signed-Details-modal" show={show} onHide={onClose}>
        <Modal.Header closeButton onClick={onClose} data-test-auto="3E48309B-CE0F-40FC-9AAD-A3C673A7A5A0">
            <Modal.Title>
                <span
                    className='fas fa-user'
                    style={{ color: 'grey', marginRight: '5px' }}>
                </span>Signature Details
                   </Modal.Title>
        </Modal.Header>
        <Form horizontal>
            <Modal.Body>
                {modalBody}
            </Modal.Body>
        </Form>
        <Modal.Footer>
            <Button
                variant="default"
                data-test-auto="2791EE4F-F1F6-424A-9471-222568102B29"
                onClick={onClose}>
                <i className='glyphicon glyphicon-remove'></i>Cancel
                </Button>
        </Modal.Footer>
    </Modal>
}

export { SignedDetailsModal };
