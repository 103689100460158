import * as React from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import { EmailMessageConstants } from './../../Helper/OrganizerConstants';
import { OverlayLoader } from './../../../../components/helper/OverlayLoader';
import {
    EmailMessage,
    VariableListData,
    initialEmailMessage
} from './../../../models/OrganizerComponentModels';
import { VariableList } from './../common/VariableList';

interface EmailMessageModalProps {
    showState: boolean;
    onHide: () => void;
    submitButtonClick: (message: EmailMessage, isSetDefault: boolean, isToggled: boolean) => void;
    buttonText: string;
    selectedEmailMessage: EmailMessage;
    title: string;
    defaultMessage: number;
    showLoader: boolean;
    loaderText: string;
    isUpdate: boolean;
    emailMessages: EmailMessage[];
    variableList: VariableListData[]
}
interface EmailMessageModalState {
    emailMessage: EmailMessage;
    alert: string;
    message: string;
    isSetDefault: boolean;
    isToggled: boolean;
    showAlert: boolean;
    isVariableListExpand: boolean;
}

export class EmailMessageModal extends React.Component<EmailMessageModalProps, EmailMessageModalState>{
    constructor(props: EmailMessageModalProps) {
        super(props);
        this.state = {
            emailMessage: initialEmailMessage,
            alert: "warning",
            message: "",
            isSetDefault: false,
            isToggled: false,
            showAlert: true,
            isVariableListExpand: false
        }
    }

    componentWillReceiveProps(nextProps: EmailMessageModalProps) {
        this.setState({
            emailMessage: { ...nextProps.selectedEmailMessage },
            isVariableListExpand: false
        })
        if (this.props.selectedEmailMessage.id != nextProps.selectedEmailMessage.id) {
            this.setState({
                isSetDefault: nextProps.defaultMessage == nextProps.selectedEmailMessage.id ? true : false
            })
        }
    }

    onCancelButtonClick = () => {
        this.props.onHide();
        this.clearState();
    }

    clearState = () => {
        this.setState({
            emailMessage: initialEmailMessage,
            alert: "warning",
            message: "",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    onAddButtonClick = () => {
        if (this.state.emailMessage.name.trim() == "") {
            this.setState({ alert: "danger", message: EmailMessageConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (this.state.emailMessage.subject.trim() == "") {
            this.setState({ alert: "danger", message: EmailMessageConstants.ValidateSubject, showAlert: false })
            return false;
        }
        else if (this.state.emailMessage.text.trim() == "") {
            this.setState({ alert: "danger", message: EmailMessageConstants.ValidateText, showAlert: false })
            return false;
        }
        if (this.checkDuplicate(this.state.emailMessage) > 0) {
            this.setState({ alert: "danger", message: EmailMessageConstants.SaveMessageNameError, showAlert: false })
            return false;
        }
        this.props.submitButtonClick(this.state.emailMessage, this.state.isSetDefault, this.state.isToggled)
        this.clearState();
    }

    checkDuplicate = (messageDetail: EmailMessage) => {
        let count: number = 0;
        const { emailMessages } = this.props;
        emailMessages.map((message, index) => {
            if (message.name.trim().toLowerCase() == messageDetail.name.trim().toLowerCase() &&
                message.id != messageDetail.id)
                count++;
        })
        return count;
    }

    onNameChange = (event: any) => {
        let message = { ...this.state.emailMessage };
        message.name = event.target.value;
        this.setState({ emailMessage: message })
    }

    onTextChange = (event: any) => {
        let message = { ...this.state.emailMessage };
        message.text = event.target.value;
        this.setState({ emailMessage: message })
    }

    onSubjectChange = (event: any) => {
        let message = { ...this.state.emailMessage };
        message.subject = event.target.value;
        this.setState({ emailMessage: message })
    }

    onSetDefaultClick = (isTrue: boolean) => {
        let message = "";
        if (isTrue) {
            message = EmailMessageConstants.SetDefaultMessageWarning;
        }
        else {
            message = EmailMessageConstants.RemoveDefaultMessageWarning;
        }
        this.setState({
            isSetDefault: isTrue,
            isToggled: true,
            alert: "warning",
            message: message,
            showAlert: false
        })
    }

    onVariableListExpandToggle = () => {
        this.setState(prevState => ({
            isVariableListExpand: !prevState.isVariableListExpand
        }))
    }

    render() {

        const {
            defaultMessage, buttonText, variableList,
            emailMessages, isUpdate, loaderText, onHide, selectedEmailMessage,
            showLoader, showState, submitButtonClick, title
        } = this.props;
        const { isSetDefault, isVariableListExpand, emailMessage, isToggled, alert, showAlert, message } = this.state;
        let toggleState: boolean = defaultMessage == selectedEmailMessage.id && isUpdate == true && isToggled == false ?
            true : isSetDefault == true ? true : false
        return (
            <div style={{ position: 'relative' }}>
                <Modal show={showState} onHide={this.onCancelButtonClick}
                    className="edit-client-instruction-modal">
                    <OverlayLoader show={showLoader} text={loaderText} />
                    <Modal.Header closeButton data-test-auto="9D9B8E11-AF73-4BC7-89B1-BEACFB0AF3FE">
                        <Modal.Title>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert style={{ margin: '0px' }}
                            variant={alert} hidden={showAlert}><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{message}</Alert>
                        <div className="form-group">
                            <label>Name</label>
                            <div className="pull-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={toggleState}
                                        disabled={defaultMessage == selectedEmailMessage.id && isUpdate == true ? true : false}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="001E901D-DF89-47DE-AD59-3E92A89ADE89"
                                className="form-control"
                                placeholder="Instruction name"
                                maxLength={100}
                                value={emailMessage.name}
                                onChange={this.onNameChange}>
                            </input>
                        </div>
                        <div className="form-group">
                            <label>Subject</label>
                            <input
                                data-test-auto="95894E38-D3FC-47FE-9591-474A3BF62AB3"
                                className="form-control"
                                placeholder="Email subject"
                                maxLength={200}
                                value={emailMessage.subject}
                                onChange={this.onSubjectChange}
                            ></input>
                        </div>
                        <div className="form-group">
                            <label>Text</label>
                            <textarea
                                data-test-auto="A2BC3545-772C-4C0A-A0EC-EB90240012F2"
                                className="form-control"
                                placeholder="Please enter instruction text"
                                rows={5}
                                value={emailMessage.text}
                                onChange={this.onTextChange}
                            />
                        </div>
                        <VariableList variableList={variableList}
                            toggleListExpand={this.onVariableListExpandToggle}
                            isListExpand={isVariableListExpand}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            data-test-auto="4C7EE9C3-D4F1-497D-B1C6-D8B655B17E6E"
                            variant='default'
                            onClick={this.onCancelButtonClick}>
                            <i className='glyphicon glyphicon-remove'></i>Cancel
                        </Button>
                        <Button
                            variant='info'
                            onClick={this.onAddButtonClick}
                            data-test-auto="7FF185BF-E5BC-4612-BEBC-6E8827B60AFA">
                            <i className='glyphicon glyphicon-floppy-disk'></i>{buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}