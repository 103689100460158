import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FollowUpQuestionIdentifer, ISectionItems } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { PlusCircleIcon } from "src/assets/SvgIconCollection";
import { CqAutomation } from "src/components/helper/AutomationLocators";
import { getMaxOptionsDisbaledTooltip } from "src/components/helper/HelperFunctions";
import { ApplicationState } from "src/store";
import {
    IHandleAddChoiceIdentifierPayload,
    ISubQuestionChoiceIdentifierPayload,
    ISubQuestionChoiceTextOnChangePayload
} from "src/store/settings/FormBuilder/ActionTypes";
import {
    HandleSubQuestionAddChoice,
    HandleSubQuestionChoiceTextOnChange,
    HandleSubQuestionRemoveChoice
} from "src/store/settings/FormBuilder/Actions";
import Choice from "../Choice";

interface IMultiChoiceQuestionProps {
    sectionItem: ISectionItems;
    sectionItemIndex: number;
    sectionIndex: number;
    followUpQuestionIdentifer: FollowUpQuestionIdentifer;
    questionIndex: number;
}
const MultiChoiceQuestion: React.FC<IMultiChoiceQuestionProps> = ({
    sectionItem,
    sectionIndex,
    sectionItemIndex,
    followUpQuestionIdentifer,
    questionIndex
}) => {
    const { customQuestionSettings } = useSelector((state: ApplicationState) => state.formBuilder);
    const dispatch = useDispatch();
    const onChangeHandler = (text: string, choiceIndex: number) => {
        const payload: ISubQuestionChoiceTextOnChangePayload = { 
            choiceText: text,
            sectionIndex: sectionIndex,
            sectionItemIndex: sectionItemIndex,
            choiceIndex: followUpQuestionIdentifer,
            subQuestionChoiceIndex: choiceIndex,
            questionIndex: questionIndex
        };
        dispatch(HandleSubQuestionChoiceTextOnChange(payload));
    };

    const onAddChoiceHandler = () => {
        if (sectionItem.choices.length < customQuestionSettings.choiceLimit) {
            const payload: IHandleAddChoiceIdentifierPayload = {
                sectionIndex: sectionIndex,
                sectionItemIndex: sectionItemIndex,
                choiceIndex: followUpQuestionIdentifer,
                questionIndex: questionIndex
            };
            dispatch(HandleSubQuestionAddChoice(payload));
        }
    };
    const onRemoveChoiceHandler = (choiceIndex: number) => {
        if (sectionItem.choices.length > 1) {
            const payload: ISubQuestionChoiceIdentifierPayload = {
                sectionIndex: sectionIndex,
                sectionItemIndex: sectionItemIndex,
                choiceIndex: followUpQuestionIdentifer,
                subQuestionChoiceIndex: choiceIndex,
                questionIndex: questionIndex
            };
            dispatch(HandleSubQuestionRemoveChoice(payload));
        }
    };
    return (
        <div className="question-type-wrapper follow-up-multi-choice">
            <div className="options-wrapper">
                {sectionItem.choices &&
                    sectionItem.choices.length > 0 &&
                    sectionItem.choices.map((choice, choiceIndex) => (
                        <Choice
                            choiceIndex={choiceIndex}
                            choice={choice}
                            onChangeHandler={onChangeHandler}
                            onRemoveChoiceHandler={onRemoveChoiceHandler}
                            sectionItem={sectionItem}
                        />
                    ))}
                <div
                    data-test-auto={CqAutomation.Section.questionType.multiChoice.addChoice}
                    title={
                        sectionItem.choices.length >= customQuestionSettings.choiceLimit
                            ? getMaxOptionsDisbaledTooltip(customQuestionSettings.choiceLimit)
                            : ""
                    }
                    className={`add-options-button ${
                        sectionItem.choices.length >= customQuestionSettings.choiceLimit ? "isDisabled" : ""
                    }`}
                    onClick={onAddChoiceHandler}
                >
                    <PlusCircleIcon />
                    Add option
                </div>
            </div>
        </div>
    );
};

export default MultiChoiceQuestion;
