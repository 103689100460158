import * as React from 'react';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
    CustomSelectProps, ToolBarProps
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { Button } from 'react-bootstrap';
import { CheckBoxComponent, CheckBoxSize } from "./../../../../components/common/CheckBoxComponent";
import { DaysRangeOptionsList } from './../../../../components/common/TaxReturn';
import { CustomMultiSelect } from "./../../../../components/common/MultipleSelectComponent";
import { OrganizerReportFilter } from './../OrganizerFilter/OrganizerReportFilter'
import {
    ProcessStatus, DocumentStatusList, OrganizerFilter, OrganizerDocument, EngagementList,
    OrganizerFilterType, OrganizerPageOptions
} from './../../../models/OrganizerComponentModels';
import { validateFilter, formattedClientName } from './../../Helper/OrganizerHelperFunctions';
import { OrganizerAction } from './OrganizerActions';
import { getEngagementType, getOrganizersTaxYearList } from './../../Helper/OrganizerHelperFunctions'
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import { OrganizerFilterConstants } from './../../../../components/helper/Constants';
import * as bootbox from 'bootbox';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../../../Logger/Telemetry/TelemetryLogger';
import { ResourceIdConstants } from '../../../../components/helper/Rbac/RbacConstants'
import { validator } from '../../../../store/roleBasedAccessControl/RoleBasedAccessControlStore';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
let moment = require('moment');

export interface OrganizerReportTableProps {
    documents: OrganizerDocument[];
    isLoading: boolean;
    saveFilterShow: boolean;
    onPageChange: any;
    onSortChange: any;
    onFilterChange: any;
    onProcessOrganizer: (rowIndex: number) => void;
    pageNo: number;
    totalRows: number;
    pageSize: number;
    loadGrid(): void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    filterList: OrganizerFilter[];
    onDeleteOpen: (rowIndex: number) => void;
    pageReload: () => void;
    onRowDoubleClick: (rowIndex: number) => void;
    onEditClientInfoOpen: (index: number) => void;
    onReportProblemOpen: (index: number) => void;
    filterType: OrganizerFilterType;
    currentFilter: OrganizerFilter;
    defaultFilter: string | undefined;
    onSaveFilterShow: () => void;
    onSaveFilterHide: () => void;
    onFilterUpdate: (filterName: string, actionName: string) => void;
    onRemoveDefaultFilter: (filterName: string, actionName: string) => void;
    onSetDefaultFilter: (filterName: string, actionName: string) => void;
    onFilterDelete: (filterName: string) => void;
    onExportToExcel: (callback: () => void) => void;
    onFilterNameChange: (event: any) => void;
    onFilterSave: (callback: (filter: OrganizerFilter) => void) => void;
    selectedOrganizerOption: OrganizerPageOptions;
    changeOrganizerOptions: (option: OrganizerPageOptions) => void;
    refreshDelay?: boolean;
    userLocationList? : IDropdown[];
}

export interface OrganizerReportTableState {

}

export class OrganizerReportTable extends React.Component<OrganizerReportTableProps, OrganizerReportTableState>{
    public customDocumentStatusMultiSelect: any;
    public customLocationNameMultiSelect: any;
    public customTaxYearMultiSelect: any;
    public taxYearList: any;
    public filterChanged = false;
    public isAppliedFilter = false;
    public customLastReminderFilter: any;
    public customOrganizerReminderFilter: any;
    public customSigningReminderFilter: any;
    public customDownloadedDateFilter: any;

    componentWillMount() {
        this.taxYearList = getOrganizersTaxYearList();
    }

    getAppliedFilter = () => {
        return this.isAppliedFilter;
    }

    createCustomToolBar = (props: ToolBarProps) => {
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 marR15" style={{ height: 33 }}>
                {
                    <OrganizerReportFilter
                        filterType={this.props.filterType}
                        defaultFilter={this.props.defaultFilter}
                        filterList={this.props.filterList}
                        onClearFilter={this.onClearFilter}
                        onExportToExcel={this.props.onExportToExcel}
                        onFilterDelete={this.props.onFilterDelete}
                        onFilterNameChange={this.props.onFilterNameChange}
                        onFilterSave={this.props.onFilterSave}
                        onFilterSaveClick={this.onFilterSaveClick}
                        onLoadSelectedFilter={this.onLoadSelectedFilter}
                        onRemoveDefaultFilter={this.props.onRemoveDefaultFilter}
                        onSaveFilterHide={this.props.onSaveFilterHide}
                        onSetDefaultFilter={this.props.onSetDefaultFilter}
                        show={this.props.saveFilterShow}
                        selectedOrganizerOption={this.props.selectedOrganizerOption}
                        changeOrganizerOptions={this.props.changeOrganizerOptions}
                        pageReload={this.props.pageReload}
                        refreshDelay={this.props.refreshDelay}
                        isArchived={false}
                        exportToExcelEnabled={this.props.totalRows === 0}
                    />
                }
            </div>
        );
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"signatureflow-reports-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }

    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p style={{ fontSize: '14px' }}>
                Showing {start} to {to} of {total} entries
            </p>
        )
    }

    setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }

    documentStatusFormatter = (cell: any, row: any) => {
        const status: any = row.documentStatus;
        const statusType: "None" | "Uploaded" | "Ready" | "Delivered" | "PartiallyCompleted" | "Completed" | "Downloaded" | "PreparingForDelivery" | "Error" | "Processing" =
            (typeof status === "number") ? ProcessStatus[status] : status;
        return <span title={DocumentStatusList[statusType].toUpperCase()} className="ellipsis">{DocumentStatusList[statusType].toUpperCase()}</span>;
    }

    taxPayerNameFormatter = (cell: any, row: any) => {
        const clientName: any = row.taxpayerName
        return <span title={formattedClientName(clientName)} className="ellipsis">{formattedClientName(clientName)}</span>;
    }


    engagementTypeFormatter = (cell: any, row: any) => {
        const type: string = row.engagementType;
        const typeString = getEngagementType(type);
        return <span title={typeString} className="ellipsis">{typeString}</span>;
    }

    getTaxYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    getDocumentStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customDocumentStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    getLocationNameMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customLocationNameMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    actionFormatter = (cell: any, row: any) => {
        const { onProcessOrganizer, onDeleteOpen, onEditClientInfoOpen, onReportProblemOpen } = this.props;
        return <OrganizerAction
            documentStatus={row.documentStatus}
            onDeleteOpen={onDeleteOpen}
            onProcessOrganizer={onProcessOrganizer}
            rowIndex={row.rowIndex}
            uploadedOn={row.createdOn}
            onEditClientInfoOpen={onEditClientInfoOpen}
            onReportProblemOpen={onReportProblemOpen}
        />;
    }

    onFilterChange = (dataField: any) => {
        if (!this.filterChanged && !this.isAppliedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }

    onClearFilter = () => {
        this.filterChanged = true;
        (this.refs.taxpayerName as TableHeaderColumn).cleanFiltered();
        (this.refs.clientId as TableHeaderColumn).cleanFiltered();
        (this.refs.uploadedOn as TableHeaderColumn).cleanFiltered();
        (this.refs.engagementType as TableHeaderColumn).cleanFiltered();
        (this.refs.ero as TableHeaderColumn).cleanFiltered();
        (this.refs.assignedTo as TableHeaderColumn).cleanFiltered();
        this.customDocumentStatusMultiSelect.cleanFiltered();
        this.customLocationNameMultiSelect.cleanFiltered();
        this.customTaxYearMultiSelect.cleanFiltered();
        this.filterChanged = false;
    }

    onFilterSaveClick = (filterName: string) => {

        const { currentFilter, onSaveFilterShow, onFilterUpdate } = this.props;

        if (!validateFilter(currentFilter)) {
            VenusNotifier.Warning(OrganizerFilterConstants.FilterFieldsAreEmpty, null);
            return;
        }

        if (typeof filterName == 'undefined' || filterName.length == 0) {
            onSaveFilterShow();
        }
        else {
            bootbox.dialog({
                title: OrganizerFilterConstants.Title.FilterSaveUpdate,
                message: OrganizerFilterConstants.OtherMessage.DoYouWantToCreateNewFilterOrUpdateTheCurrent,
                buttons: {
                    cancel: {
                        label: '<i class="glyphicon glyphicon-remove"></i>' + OrganizerFilterConstants.ControlLabel.NoUpdateTheCurrent,
                        className: 'btn-white',
                        callback: function () { onFilterUpdate(filterName, OrganizerFilterConstants.ControlLabel.NoUpdateTheCurrent); }
                    },
                    confirm: {
                        label: '<i class="glyphicon glyphicon-ok"></i>' + OrganizerFilterConstants.ControlLabel.YesCreateNew,
                        className: 'btn-info',
                        callback: function () { onSaveFilterShow(); }
                    }
                }
            });
        }
    }

    onLoadSelectedFilter = (filter?: OrganizerFilter) => {
        this.isAppliedFilter = true;
        this.onClearFilter();
        if (filter) {
            this.filterChanged = true;
            for (let field of Object.keys(filter.fields)) {
                switch (field) {
                    case 'taxpayerName':
                        (this.refs.taxpayerName as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'clientId':
                        (this.refs.clientId as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'ero':
                        (this.refs.ero as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                    case 'locationName':
                        this.customLocationNameMultiSelect.applyFilter((filter.fields[field]));
                        break;
                    case 'uploadedOn':
                        let uploadedOnFilter: any = filter.fields[field];
                        if (uploadedOnFilter) {
                            (this.refs.uploadedOn as TableHeaderColumn).applyFilter(uploadedOnFilter);
                        }
                        break;
                    case 'engagementType':
                        let engagementFilter: any = filter.fields[field];
                        if (engagementFilter) {
                            (this.refs.engagementType as TableHeaderColumn).applyFilter(engagementFilter);
                        }
                        break;
                    case 'documentStatus':
                        this.customDocumentStatusMultiSelect.applyFilter((filter.fields[field]));
                        break;
                    case 'taxYear':
                        this.customTaxYearMultiSelect.applyFilter((filter.fields[field]));
                        break;
                    case 'assignedTo':
                        (this.refs.assignedTo as TableHeaderColumn).applyFilter(filter.fields[field]);
                        break;
                }
                this.filterChanged = false;
            }
            this.isAppliedFilter = false;
            this.onFilterChange(filter.fields);
        }
    }

    public render() {
        const { loadGrid, onPageChange, onRowSelect,
            onSelectAll, onSortChange, pageNo, pageSize, saveFilterShow,
            selectedRows, totalRows, filterList,
            documents, onFilterChange, onRowDoubleClick,
            filterType, defaultFilter, onExportToExcel, onFilterDelete,
            onFilterNameChange, onFilterSave, onRemoveDefaultFilter,
            onSaveFilterHide, onSetDefaultFilter,
            selectedOrganizerOption, changeOrganizerOptions
        } = this.props;
        const selected: number[] = [];
        if (selectedRows.length > 0) {
            for (const i in selectedRows) {
                const rowIndex = selectedRows[i];
                selected.push(((pageNo - 1) * pageSize) + rowIndex);
            }
        }

        const options = {
            onSortChange: onSortChange,
            onPageChange: onPageChange,
            sizePerPage: pageSize,
            page: pageNo,
            paginationShowsTotal: this.renderShowTotal,
            onFilterChange: onFilterChange,
            noDataText: this.setNoContent(),
            toolBar: this.createCustomToolBar,
            onRowDoubleClick:
                (row: any) => {
                    if(validator && validator.allResourcesExists([ResourceIdConstants.ProcessOrganizer])) {
                        onRowDoubleClick(row.rowIndex);
                    }
                },
        };

        const selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: onRowSelect,
            onSelectAll: onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };
        const searchEnable = true;

        const pagination = true;

        const DocumentStatusOptionsList = [
            { label: 'UPLOADED', value: 1 },
            { label: 'READY', value: 2 },
            { label: 'PROCESSING', value: 4 }
        ]

        const Columns: any[] = [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Name',
                key: 'taxpayerName',
                isKey: false,
                dataFormat: this.taxPayerNameFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Client', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Client Id', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'ERO / Signer',
                key: 'ero',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: {
                    type: 'CustomFilter', getElement: this.getLocationNameMultiSelectDropDown,
                    customFilterParameters: { options: this.props.userLocationList, enableAllFilter: false, placeholder: 'Select Office Location...' } as any
                } as CustomFilter
            },
            {
                header: 'Assigned To',
                key: 'assignedTo',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Assigned To', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Status',
                key: 'documentStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.documentStatusFormatter,
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getDocumentStatusMultiSelectDropDown,
                    customFilterParameters: { options: DocumentStatusOptionsList, enableAllFilter: true, placeholder: 'Select Document Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Date',
                key: 'uploadedOn',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'SelectFilter', placeholder: 'Select Uploaded Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Tax Return Type',
                key: 'engagementType',
                isKey: false,
                dataFormat: this.engagementTypeFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: false,
                filter: { type: 'SelectFilter', options: EngagementList } as SelectFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataSort: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getTaxYearMultiSelectDropDown,
                    customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Tax Year...' } as any
                } as CustomFilter
            },
            {
                header: 'Action',
                key: 'button',
                isKey: false,
                dataFormat: this.actionFormatter,
                columnClassName: 'button-cell',
                dataSort: false,
                toolTip: false,
                isHidden: false,
                width: "130px",
                filter: { type: 'TextFilter', placeholder: '', style: { display: 'none' } } as TextFilter
            },
        ];

        const data: any[] = documents.map((document, index) => {
            return {
                rowIndex: index,
                index: ((pageNo - 1) * pageSize) + index,
                taxpayerName: document.taxpayerName,
                clientId: document.clientId,
                uploadedOn: document.createdOn ? moment(document.createdOn).format('MM/DD/YYYY') : '',
                createdOn: document.createdOn ? document.createdOn : '',
                documentStatus: document.processStatus,
                id: document.id,
                taxYear: document.taxYear,
                engagementType: document.engagementType,
                ero: document.eroUserName,
                assignedTo: document.assignedToUser ? document.assignedToUser.userName : '',
                locationName : document.locationName
            }
        });

        return <div className="pos-relative">

            <div data-test-auto="6a98a1c2-e936-49fc-b3fa-4bb031981726">
                {Columns.length > 0 &&
                    <BootstrapTable
                        ref='organizerReportTable'
                        data={data}
                        remote={true}
                        fetchInfo={{ dataTotalSize: totalRows }}
                        options={options}
                        striped
                        hover={true}
                        pagination={pagination}
                        selectRow={selectRowProp}
                        search={searchEnable}>
                        {Columns.map((value, index) => {
                            return <TableHeaderColumn
                                key={index}
                                ref={value.key}
                                isKey={value.isKey}
                                dataField={value.key}
                                hidden={value.isHidden}
                                width={value.width}
                                dataFormat={value.dataFormat}
                                columnClassName={value.columnClassName}
                                columnTitle={value.toolTip}
                                filter={value.filter}
                                dataSort={value.dataSort}
                            >{value.header}
                            </TableHeaderColumn>;
                        })}
                    </BootstrapTable>
                }
            </div>

        </div>
    }
}

export default withAITracking(reactPlugin, OrganizerReportTable);
