import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';

interface ISpouseProps {
    spouseName: string,
    spouseEmail: string;
    disabled: boolean;
}

export const SpouseDetails = (props: ISpouseProps) => {
    return (
        <>
            <Row className="report-problem-field">

                <Col sm={2} >
                    <div className="font700">Spouse Name</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.spouseName}
                        placeholder="Enter Spouse Name"
                        disabled={props.disabled}
                    />
                </Col>

                <Col sm={2} >
                    <div className="font700">Spouse Email</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.spouseEmail}
                        placeholder="Enter Spouse Email"
                        disabled={props.disabled}
                    />
                </Col>
            </Row>
        </>
    );
}