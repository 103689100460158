import * as React from 'react';
import { Alert, Button, Row, Col, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { ClientInfoValidation, ReportProblemConstants, ValidationContants } from '../components/helper/Constants';
import { getOrganizersTaxYearList, getOrganizersCurrentTaxYear } from '../Organizer/components/Helper/OrganizerHelperFunctions';
import { VenusNotifier } from '../components/helper/VenusNotifier';
import { IUserProfile } from '../components/navigation/profile/ProfileObjects';
import { ICompanySettings, TaxSoftwareType, TaxSoftwareTypeLabels } from '../Core/ViewModels/Company/CompanySettingsViewModel';
import {
    ReportProblemTypes, ReportedStep,
    InProcessStatusOptionsList, SignatureStatusOptionsList, OrganizerStatusOptionsList, SourceDocumentStatusOptionsList,
    IReportProblemDetails, initialState,
    IReportProblemOrganizerData, initialReportProblemOrganizerData
} from './ReportProblemModel';
import { TaxpayerDetails } from './TaxpayerDetails';
import { SpouseDetails } from './SpouseDetails';
import { DropdownComponent } from './../components/common/controls/DropdownComponent';
import { PhoneNumberComponent } from './../components/common/PhoneNumberComponent';
import { EngagementType, ProcessStatus, OrganizerClientType, OrganizerClient, SignatureStatus, EngagementLetterStatus, OrganizerStatus, SourceDocumentStatus, EngagementLetterStatusList, OrganizerStatusList, SourceDocumentStatusList } from './../Organizer/models/OrganizerComponentModels';
import { isValidEmailAddress } from '../components/helper/Validations';

interface IReportProbemProps {
    show: boolean;
    onCancel: () => void;
    onReportProblemSave: (problemDetails: IReportProblemDetails) => void;
    loggedInCPA: IUserProfile;
    companyName: string;
    reportProblemType: ReportProblemTypes;
    problemStep?: ReportedStep;
    organizerData?: IReportProblemOrganizerData;
    taxSoftwares: TaxSoftwareType[];
    disableTaxSoftware?: boolean;
}

interface state {
    saving: boolean;
    description: string;
    showLoader: boolean;
    organizerData: IReportProblemOrganizerData;
    problemState: IReportProblemDetails;
    cpaPhoneNumber: string;
    cpaEmail: string;
}

export class ReportProblem extends React.Component<IReportProbemProps, state> {

    constructor(props: IReportProbemProps) {
        super(props);

        this.state = {
            saving: false,
            description: '',
            showLoader: false,
            problemState: initialState,
            organizerData: initialReportProblemOrganizerData,
            cpaPhoneNumber: '',
            cpaEmail: ''
        }

        this.onDescription = this.onDescription.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps: IReportProbemProps) {
        this.setState({
            saving: false, description: '',
            organizerData: {
                ...this.state.organizerData,
                taxSoftware: nextProps.reportProblemType === ReportProblemTypes.Generic ? TaxSoftwareType.ProSystems : this.state.organizerData.taxSoftware,
                clientId: nextProps.reportProblemType === ReportProblemTypes.Generic ? '' : this.state.organizerData.clientId,
                taxYear: getOrganizersCurrentTaxYear()
            },
            showLoader: nextProps.reportProblemType != ReportProblemTypes.Generic && nextProps.organizerData?.clients === undefined ? true : false
        });
        if (nextProps.organizerData) {
            this.setState({ organizerData: nextProps.organizerData })
        }
        if (nextProps.loggedInCPA) {
            this.setState({
                cpaPhoneNumber: (nextProps.loggedInCPA.mobileNumber) ?? '',
                cpaEmail: nextProps.loggedInCPA.emailAddress
            });
        }
    }

    private getCpaName = () => {
        return this.props.loggedInCPA != undefined
            ? this.props.loggedInCPA.firstName + " " + this.props.loggedInCPA.lastName : "";
    }

    public render() {
        return <div>
            <Modal className="report-problem-modal" show={this.props.show} onHide={() => this.props.onCancel()}>
                <LoadingOverlay>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span className="fas fa-bullhorn" style={{ color: 'grey', marginRight: '7px' }} />
                            Report a Problem{getTaxClientName(this.state.organizerData.taxpayerName)}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.organizerData && this.state.organizerData.clientId
                            ? <Alert variant='info' >
                                <i className='glyphicon glyphicon-file' style={{ marginRight: '5px' }}></i>
                                <b>{this.state.organizerData.clientId}</b>
                            </Alert> : false}

                        {this.productTypeAndTaxSoftwareUI()}
                        {this.cpaDetailsUI()}
                        {this.returnTypeAndTaxYearUI()}
                        {this.clientDetailsUI()}
                        {this.taxPayerDetailsUI()}
                        {this.spouseDetailsUI()}
                        {this.descriptionUI()}

                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            data-test-auto='1137347C-947C-4D8A-ABA7-0DE9D70DB526'
                            onClick={this.onCancel}
                            className="btn-white" >
                            <i className='glyphicon glyphicon-remove'></i>Cancel
                        </Button>
                        <Button
                            data-test-auto='E0F1216B-0B82-456E-8EEA-57302D25A818'
                            onClick={this.onSubmit}
                            variant="info">
                            <i className='glyphicon glyphicon-ok'></i>Submit
                        </Button>
                    </Modal.Footer>
                    <Loader loading={this.state.showLoader} text={ReportProblemConstants.OverlayMessage.SubmittingIssue} />
                </LoadingOverlay>
            </Modal>
        </div>
    }

    private onDescription(e: any) {
        this.setState({ description: e.target.value });
    }

    private onCancel = () => {
        this.props.onCancel();
    }

    private onSubmit() {
        if (this.state.description.trim() === '') {
            VenusNotifier.Warning("Please enter problem description to report.", "Report a Problem");
            return;
        }
        else if (!isValidEmailAddress(this.state.cpaEmail)) {
            VenusNotifier.Warning(ClientInfoValidation.inCorrectEmail, "");
            return;
        }
        else if (this.state.cpaPhoneNumber.trim() != "") {
            if (this.state.cpaPhoneNumber.length < 10 || /^[0-9]{1,10}$/.test(this.state.cpaPhoneNumber) == false) {
                VenusNotifier.Warning(ValidationContants.PhoneNumberLengthWarning, "");
                return;
            }
        }

        if (!this.state.saving) {
            const taxpayer = this.state.organizerData.clients?.find(x => x.clientType == OrganizerClientType.Taxpayer);
            const spouse = this.state.organizerData.clients?.find(x => x.clientType == OrganizerClientType.Spouse);
            this.setState({
                saving: true,
                showLoader: true,
                problemState: {
                    ...this.state.problemState,
                    productType: this.state.organizerData.productType,
                    taxSoftware: this.state.organizerData.taxSoftware,
                    cpaFirm: this.props.companyName,
                    cpaName: this.getCpaName(),
                    cpaPhoneNumber: this.state.cpaPhoneNumber,
                    cpaEmail: this.state.cpaEmail,
                    returnType: this.state.organizerData.returnType,
                    taxYear: this.state.organizerData.taxYear,
                    clientId: this.state.organizerData.clientId,
                    processStatus: ProcessStatus[this.state.organizerData.processStatus],
                    signatureStatus: this.state.organizerData.signatureStatus === "25" || this.state.organizerData.signatureStatus === "0" ? "N/A" : EngagementLetterStatusList[EngagementLetterStatus[this.state.organizerData.signatureStatus]],
                    organizerStatus: OrganizerStatusList[OrganizerStatus[this.state.organizerData.organizerStatus]],
                    sourceDocumentStatus: SourceDocumentStatusList[SourceDocumentStatus[this.state.organizerData.sourceDocumentStatus]],
                    taxpayerName: taxpayer && taxpayer.name ? taxpayer.name : '',
                    taxpayerEmail: taxpayer && taxpayer.email ? taxpayer.email : '',
                    spouseName: spouse && spouse.name ? spouse.name : '',
                    spouseEmail: spouse && spouse.email ? spouse.email : '',
                    documentId: this.state.organizerData?.documentId,
                    description: this.state.description
                }
            },
                () => {
                    this.props.onReportProblemSave(this.state.problemState);
                });
        }
    }

    private productTypeAndTaxSoftwareUI = () => {
        return (<Row className="report-problem-field">
            <Col sm={2} >
                <div className="font700">Product Type</div>
            </Col>
            <Col sm={4}>
                <FormControl
                    type="text"
                    placeholder="Select Product Type..."
                    value={this.state.organizerData.productType}
                    disabled={true}
                />
            </Col>

            <Col sm={2} >
                <div className="font700 ">Tax Software</div>
            </Col>
            <Col sm={4}>
                <DropdownComponent
                    id="form-field-taxSoftware"
                    selectedValue={this.state.organizerData.taxSoftware}
                    customPlaceHolder="Select Tax Software..."
                    options={this.getTaxSoftwares()}
                    onChange={(value) => { this.setState({ organizerData: { ...this.state.organizerData, taxSoftware: value } }); }}
                    clearable={true}
                    disabled={this.props.disableTaxSoftware}
                />
            </Col>
        </Row>);
    }

    private cpaDetailsUI = () => {
        return (<>
            <Row className="report-problem-field">
                <Col sm={2} >
                    <div className="font700">CPA Firm</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        placeholder="Enter Company Name"
                        value={this.props.companyName}
                        disabled={true}
                    />
                </Col>

                <Col sm={2}>
                    <div className="font700">Logged-In User</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        placeholder="Enter Logged-In CPA Username"
                        value={this.getCpaName()}
                        disabled={true}
                    />
                </Col>
            </Row>

            <Row className="report-problem-field">

                <Col sm={2} >
                    <div className="font700">Mobile</div>
                </Col>
                <Col sm={4}>
                    <PhoneNumberComponent
                        phoneNumber={this.state.cpaPhoneNumber}
                        handleChangePhoneNumber={(value: string) => { this.setState({ cpaPhoneNumber: value }) }}
                        disabled={false}
                    />
                </Col>

                <Col sm={2} >
                    <div className="font700">Email</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        placeholder="Enter Logged-In CPA Email"
                        value={this.state.cpaEmail}
                        onChange={(event: any) => { this.setState({ cpaEmail: event.target.value }) }}
                    />
                </Col>
            </Row>
        </>);
    }

    private returnTypeAndTaxYearUI = () => {
        return (<Row className="report-problem-field">

            <Col sm={2} >
                <div className="font700">Return Type</div>
            </Col>
            <Col sm={4}>
                <DropdownComponent
                    id="form-field-returnType"
                    selectedValue={this.state.organizerData.returnType}
                    customPlaceHolder="Select Return Type..."
                    options={getReturnTypes()}
                    disabled={true}
                    clearable={true}
                />
            </Col>

            <Col sm={2} >
                <div className="font700">Tax year</div>
            </Col>
            <Col sm={4}>
                <DropdownComponent
                    id="form-field-taxYear"
                    selectedValue={this.state.organizerData.taxYear}
                    customPlaceHolder="Select Tax year..."
                    options={getOrganizersTaxYearList()}
                    disabled={false}
                    clearable={false}
                    onChange={(value) => { this.setState({ organizerData: { ...this.state.organizerData, taxYear: value } }); }}
                />
            </Col>
        </Row>)
    }

    private clientDetailsUI = () => {
        return (<>
            <Row className="report-problem-field">

                <Col sm={2} >
                    <div className="font700">Client Id</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={this.state.organizerData.clientId}
                        placeholder="Enter Client Id"
                        onChange={(event: any) => { this.setState({ organizerData: { ...this.state.organizerData, clientId: event.target.value } }) }}
                        onBlur={(event: any) => { this.setState({ organizerData: { ...this.state.organizerData, clientId: event.target.value } }) }}
                        disabled={this.props.reportProblemType == ReportProblemTypes.ReturnSpecific ? true : false}
                    />
                </Col>


                {
                    this.props.reportProblemType === ReportProblemTypes.Generic
                        ? ''
                        :
                        this.props.problemStep === ReportedStep.InProcessOrganizer
                            ? (<>
                                <Col sm={2} >
                                    <div className="font700">Status</div>
                                </Col>
                                <Col sm={4}>
                                    <DropdownComponent
                                        id="form-field-status"
                                        selectedValue={ProcessStatus[this.state.organizerData.processStatus]}
                                        customPlaceHolder="Select Status..."
                                        options={InProcessStatusOptionsList}
                                        disabled={true}
                                        clearable={true}
                                    />
                                </Col>
                            </>)
                            : (<>
                                <Col sm={2} >
                                    <div className="font700">Signature Status</div>
                                </Col>
                                <Col sm={4}>
                                    <DropdownComponent
                                        id="form-field-status"
                                        selectedValue={this.state.organizerData.signatureStatus === "25" || this.state.organizerData.signatureStatus === "0"
                                            ? "NA" : EngagementLetterStatus[this.state.organizerData.signatureStatus]}
                                        customPlaceHolder="Select Signature Status..."
                                        options={SignatureStatusOptionsList}
                                        disabled={true}
                                        clearable={true}
                                    />
                                </Col>
                            </>)
                }
            </Row>

            {
                this.props.problemStep === ReportedStep.DeliveredOrganizer &&
                <Row className="report-problem-field">

                    <Col sm={2} >
                        <div className="font700">Organizer Status</div>
                    </Col>
                    <Col sm={4}>
                        <DropdownComponent
                            id="form-field-status"
                            selectedValue={OrganizerStatus[this.state.organizerData.organizerStatus]}
                            customPlaceHolder="Select Organizer Status..."
                            options={OrganizerStatusOptionsList}
                            disabled={true}
                            clearable={true}
                        />
                    </Col>

                    <Col sm={2} >
                        <div className="font700">Source Document Status</div>
                    </Col>
                    <Col sm={4}>
                        <DropdownComponent
                            id="form-field-status"
                            selectedValue={SourceDocumentStatus[this.state.organizerData.sourceDocumentStatus]}
                            customPlaceHolder="Select Source Document Status..."
                            options={SourceDocumentStatusOptionsList}
                            disabled={true}
                            clearable={true}
                        />
                    </Col>
                </Row>
            }
        </>);
    }

    private taxPayerDetailsUI = () => {
        const taxpayer = this.state.organizerData.clients?.find(x => x.clientType == OrganizerClientType.Taxpayer);
        return (
            this.props.reportProblemType === ReportProblemTypes.ReturnSpecific
            && taxpayer
            && <TaxpayerDetails
                taxpayerName={taxpayer?.name}
                taxpayerEmail={taxpayer?.email}
                disabled={true}
            />
        );
    }

    private spouseDetailsUI = () => {
        const spouse = this.state.organizerData.clients?.find(x => x.clientType == OrganizerClientType.Spouse);
        return (
            this.props.reportProblemType === ReportProblemTypes.ReturnSpecific
            && spouse
            && <SpouseDetails
                spouseName={spouse?.name}
                spouseEmail={spouse?.email}
                disabled={true}
            />);
    }

    private descriptionUI = () => {
        return (<Row className="report-problem-field">

            <Col sm={2} >
                <div className="font700">Description</div>
            </Col>

            <FormGroup>
                <Col sm={12}>
                    <FormControl
                        value={this.state.description}
                        id="report-problem-text"
                        style={{ height: '150px' }}
                        as="textarea"
                        onChange={this.onDescription} />
                </Col>
            </FormGroup>
        </Row>);
    }

    getTaxSoftwares = () => {
        let taxSoftwareTypes = [];
        const { taxSoftwares } = this.props;
        for (let taxSoftware in taxSoftwares) {
            const value = taxSoftwares[taxSoftware];
            if (value != TaxSoftwareType.None) {
                taxSoftwareTypes.push({
                    value: value,
                    label: TaxSoftwareTypeLabels[value]
                });
            }
        }
        return taxSoftwareTypes;
    }
};

function getTaxClientName(name: string) {
    if (name === '' || name === undefined) {
        return "";
    }
    return " with " + name;
}

export function getReturnTypes() {
    let engType = [];
    for (let type in EngagementType) {
        if (isNaN(Number(type))) {
            if ((type !== EngagementType[EngagementType.None]))
                engType.push({ value: type.toString(), label: type.slice(1) });
        }
    }
    return engType;
}