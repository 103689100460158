import { addTask } from 'domain-task';
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './../../store';
import { handleResponse } from './../../store/Library';
import { actionTypes } from './../../store/ActionTypes';
import { StatusType, NotificationAction } from './../../store/common/NotificationStore';
import {
    apiPrefix,
    OrganizerBlankTemplateStoreState,
    unloadedOrganizerBlankTemplateStore,
    DeleteOrganizerBlankTemplateAction,
    OrganizerBlankTemplateLoaderAction,
    ReceiveOrganizerBlankTemplateAction,
    ReceiveOrganizerBlankTemplatesAction,
    UpdateOrganizerBlankTemplateAction,
    ReceiveOrganizerBlankTemplatePdfDocumentAction,
    UpdateOrganizerBlankTemplateStatusAction
} from './../models/OrganizerStoreModels';
import { BlankOrganizerTemplateDocument, BlankOrganizerTemplateStatus } from './../models/OrganizerComponentModels';
import { StoreConstants } from './../components/Helper/OrganizerConstants';
import { TYPES } from './../../Startup/types';
import { container } from './../../Startup/inversify.config';
import { IPdfDocumentFacade, PdfDocumentFacade } from '../../Core/Utilities/PdfDocumentFacade';
import { IFileClient } from '../../Core/Services/FileClient';

const fileClient = container.get<IFileClient>(TYPES.IFileClient);

type KnownActions =
    ReceiveOrganizerBlankTemplatePdfDocumentAction |
    DeleteOrganizerBlankTemplateAction |
    OrganizerBlankTemplateLoaderAction |
    ReceiveOrganizerBlankTemplateAction |
    ReceiveOrganizerBlankTemplatesAction |
    UpdateOrganizerBlankTemplateAction |
    UpdateOrganizerBlankTemplateStatusAction |
    NotificationAction

export const actionCreators = {

    requestOrganizerTemplates: (): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: true });

        const fetchTask = fetch(`${apiPrefix}BlankOrganizerTemplate/`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<BlankOrganizerTemplateDocument[]>)
            .then((data) => {
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BLANK_TEMPLATES, data: data
                });
                dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: false });

            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION, statusMessage: StoreConstants.Failure.Templates, statusType: StatusType.Error, statusCode: error?.status
                });
            });
        addTask(fetchTask);
    },

    requestOrganizerTemplate: (id: number, callback?: (id: number, model: BlankOrganizerTemplateDocument) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

        dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: true });

        const fetchTask = fetch(`${apiPrefix}BlankOrganizerTemplate/GetBlankOrganizerTemplate/${id}`, {
            method: 'GET',
            credentials: 'include'
        })
            .then(response => response.json() as Promise<BlankOrganizerTemplateDocument>)
            .then((data) => {
                callback && callback(id, data);
                dispatch({
                    type: actionTypes.RECEIVE_ORGANIZER_BLANK_TEMPLATE, data: data
                });

                dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: false });

            }).catch(error => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.Templates,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        addTask(fetchTask);
    },

    updateOrganizerTemplate: (template: BlankOrganizerTemplateDocument, callback: (id: number) => void): AppThunkAction<KnownActions> => (dispatch, getState) => {

        dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: true });

        const fetchTask = fetch(`${apiPrefix}BlankOrganizerTemplate`, {
            method: 'PUT',
            credentials: 'include',
            body: JSON.stringify({ ...template, document: null, questionDocument: null }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(handleResponse)
            .then(() => {
                callback(template.id);
                dispatch({
                    type: actionTypes.SAVE_ORGANIZER_BLANK_TEMPLATE, update: template
                });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.SaveOrganizer,
                    statusType: StatusType.Success
                });
                dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: false });

            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.SaveOrganizer, statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        addTask(fetchTask);
    },

    deleteOrganizerTemplate: (id: number, callback: () => void): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: true });

        const fetchTask = fetch(`${apiPrefix}BlankOrganizerTemplate/${id}`, {
            method: 'DELETE',
            credentials: 'include'
        })
            .then(handleResponse)
            .then(() => {
                callback();
                dispatch({
                    type: actionTypes.DELETE_ORGANIZER_BLANK_TEMPLATE, id: id
                });
                dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: false });
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.DeleteTemplate,
                    statusType: StatusType.Success
                });
            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.DeleteTemplate,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                });
            });
        addTask(fetchTask);
    },

    requestOrganizerTemplatePdfDocument: (documentGuid: string, id: number): AppThunkAction<KnownActions> => (dispatch, getState) => {
        const appState = getState();
        const document = appState.organizerTemplateStore.templates.find(template => template.id === id);
        if (document && !document.document) {
            const fetchTask = fetch(`${apiPrefix}BlankOrganizerTemplate/GetBlankOrganizerTemplateDocumentLink/${documentGuid}`,
                {
                    method: 'GET',
                    credentials: 'include'
                })
                .then(handleResponse)
                .then(response => response as string)
                .then((documentUrl) => {
                    fileClient.download(documentUrl)
                        .then((data: any) => {
                            const document: IPdfDocumentFacade = new PdfDocumentFacade();
                            document.append(data, documentUrl);
                            dispatch({ type: actionTypes.RECEIVE_BLANK_ORGANIZER_TEMPLATE_PDF_DOCUMENT, id: id, document: document });
                        }, () => {
                            dispatch({
                                type: actionTypes.NOTIFICATION,
                                statusMessage: StoreConstants.Failure.TemplatePdf,
                                statusType: StatusType.Error
                            })
                        });
                }).catch((error) => {
                    dispatch({
                        type: actionTypes.NOTIFICATION,
                        statusMessage: StoreConstants.Failure.TemplatePdf,
                        statusType: StatusType.Error,
                        statusCode: error?.status
                    })
                });
            addTask(fetchTask);
        }
    },

    updateTemplateStatus: (id: number, status: BlankOrganizerTemplateStatus): AppThunkAction<KnownActions> => (dispatch, getState) => {
        dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: true });

        const fetchTask = fetch(`${apiPrefix}BlankOrganizerTemplate/UpdateTemplateStatus/${id}/${status}`,
            {
                method: 'PUT',
                credentials: 'include'
            })
            .then(handleResponse)
            .then(() => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Success.TemplateStatus,
                    statusType: StatusType.Success
                })
                dispatch({ type: actionTypes.UPDATE_BLANK_ORGANIZER_TEMPLATE_STATUS, id: id, status: status });
                dispatch({ type: actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER, loading: false });

            }).catch((error) => {
                dispatch({
                    type: actionTypes.NOTIFICATION,
                    statusMessage: StoreConstants.Failure.TemplateStatus,
                    statusType: StatusType.Error,
                    statusCode: error?.status
                })
            });
        addTask(fetchTask);
    }
}

export const reducer: Reducer<OrganizerBlankTemplateStoreState> = (state: OrganizerBlankTemplateStoreState = unloadedOrganizerBlankTemplateStore, incomingAction: Action) => {
    const action = incomingAction as KnownActions;
    switch (action.type) {
        case actionTypes.ORGANIZER_BLANK_TEMPLATE_LOADER:
            return {
                ...state,
                loading: action.loading
            };

        case actionTypes.RECEIVE_ORGANIZER_BLANK_TEMPLATES:
            return {
                ...state,
                templates: action.data
            };

        case actionTypes.RECEIVE_ORGANIZER_BLANK_TEMPLATE: {
            const blankTemplate = { ...state };
            blankTemplate.templates = blankTemplate.templates.map(template => {
                if (template.id === action.data.id)
                    return {
                        ...action.data,
                        document: { ...template.document },
                        questionDocument: { ...template.questionDocument }
                    }
                return template;
            });
            return blankTemplate;
        }

        case actionTypes.DELETE_ORGANIZER_BLANK_TEMPLATE: {
            const deleteTemplate = { ...state }
            deleteTemplate.templates = deleteTemplate.templates.filter(template => template.id !== action.id);
            return deleteTemplate;
        }
        case actionTypes.SAVE_ORGANIZER_BLANK_TEMPLATE: {
            const updateTemplate = { ...state };
            updateTemplate.templates = updateTemplate.templates.map(template => {
                if (template.id === action.update.id)
                    return {
                        ...action.update,
                        document: { ...template.document },
                        questionDocument: { ...template.questionDocument }
                    }
                return template;
            });
            return updateTemplate;
        }

        case actionTypes.UPDATE_BLANK_ORGANIZER_TEMPLATE_STATUS: {
            const updateStatusTemplate = { ...state };
            updateStatusTemplate.templates = updateStatusTemplate.templates.map(template => {
                if (template.id === action.id)
                    return {
                        ...template,
                        status: action.status
                    }
                return template;
            });
            return updateStatusTemplate;
        }
        case actionTypes.RECEIVE_BLANK_ORGANIZER_TEMPLATE_PDF_DOCUMENT: {
            const pdfDocument = { ...state };
            pdfDocument.templates = pdfDocument.templates.map(template => {
                if (template.id === action.id) {
                    return {
                        ...template,
                        document: { ...action.document }
                    }
                }
                return template;
            });
            return pdfDocument;
        }
    }
    return state || unloadedOrganizerBlankTemplateStore;
};