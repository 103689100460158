import { SSSUITE_API_BASE_URL } from "src/utils/constants";
import { actionTypes as menuActionTypes } from "./../ActionTypes/ActionTypes";

export const apiPrefix = `${SSSUITE_API_BASE_URL}api/`;

export interface IMenuState {
    isLoading: boolean;
    productLeftMenu: any;
    hideLeftmenu: boolean;
    routeList: OrganizerRoute[];
    isRoutesEmpty: boolean;
    isAllRoutesDisabled: boolean;
}

export interface OrganizerRoute {
    route: string;
    isEnabled: boolean
}

export const MenuInitialState: IMenuState = {
    isLoading: false,
    productLeftMenu: [],
    hideLeftmenu: false,
    routeList: [],
    isRoutesEmpty: false,
    isAllRoutesDisabled : false
};

export interface IWidgetMenuState {
    isLoading: boolean;
    widgetMenu: any;
    suiteUrl: string;
}

export const IntialWidgetMenuState: IWidgetMenuState = {
    isLoading: false,
    widgetMenu: [],
    suiteUrl: ''

}

export interface ILeftMenuData {
    leftMenuData: any;
    routeList: OrganizerRoute[];
}

export interface IProductData {
    isAccessible: boolean,
    isInitialState: boolean
}

export interface IProduct {
    title: string;
}

export enum ProductTypes {
    General = "General",
    SafeSend_Returns = "SafeSend Returns",
    SafeSend_Signatures = "SafeSend Signatures",
    SafeSend_Organizers = "SafeSend Organizers"
}

export interface RequestProductLeftMenuItems {
    type: menuActionTypes.REQUEST_PRODUCT_LEFTMENU_ITEMS;
}

export interface ReceivedProductLeftMenuItems {
    type: menuActionTypes.RECEIVED_PRODUCT_LEFTMENU_ITEMS;
    payload: ILeftMenuData
}
export interface CompletedProductLeftMenuItems {
    type: menuActionTypes.COMPLETED_PRODUCT_LEFTMENU_ITEMS;
}
export interface RequestWidgetMenuItems {
    type: menuActionTypes.REQUEST_WIDGET_MENU_ITEMS;
}

export interface ReceivedWidgetMenuItems {
    type: menuActionTypes.RECEIVED_WIDGET_MENU_ITEMS;
    payload: any
}
export interface CompletedwidgetMenuItems {
    type: menuActionTypes.COMPLETED_WIDGET_MENU_ITEMS;
}

export interface RequestSuiteUrl {
    type: menuActionTypes.REQUEST_SUITE_URL;
}

export interface ReceivedSuiteUrl {
    type: menuActionTypes.RECEIVED_SUITE_URL;
    payload: any
}
export interface CompletedSuiteUrl {
    type: menuActionTypes.COMPLETED_SUITE_URL;
}

export interface RequestDisableLeftMenu {
    type: menuActionTypes.REQUEST_DISABLE_LEFTMENU;
}