import * as React from 'react';
import { Col, FormControl, Row } from 'react-bootstrap';

interface ITaxpayerProps {
    taxpayerName: string,
    taxpayerEmail: string;
    disabled: boolean;
}

export const TaxpayerDetails = (props: ITaxpayerProps) => {
    return (
        <>
            <Row className="report-problem-field">

                <Col sm={2} >
                    <div className="font700">Tax Payer Name</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.taxpayerName}
                        placeholder="Enter Taxpayer Name"
                        disabled={props.disabled}
                    />
                </Col>

                <Col sm={2}>
                    <div className="font700">Tax Payer Email</div>
                </Col>
                <Col sm={4}>
                    <FormControl
                        type="text"
                        value={props.taxpayerEmail}
                        placeholder="Enter Taxpayer Email"
                        disabled={props.disabled}
                    />
                </Col>
            </Row>
        </>
    );
}