import React from "react";
import { ISectionItems } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import "./SectionItem.css";
import { GrabIcon } from "src/assets/SvgIconCollection";
interface ICollapsedSectionItemProps {
    sectionItem: ISectionItems;
    sectionItemIndex: number;
}
const CollapsedSectionItem: React.FC<ICollapsedSectionItemProps> = ({ sectionItem, sectionItemIndex }) => {
    return (
        <main className="section-item-container collapsed-section-wrapper">
            <div className="reorder-icon-wrapper">
                <GrabIcon />
            </div>
            <div className="question-title-wrapper">
                <span>{sectionItemIndex + 1}.</span>
                <p title={sectionItem.questionTitle} className="ellipsis">
                    {sectionItem.questionTitle}
                </p>
                {sectionItem.isRequired && <span>*</span>}
            </div>
        </main>
    );
};

export default CollapsedSectionItem;
