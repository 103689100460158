import * as bootbox from 'bootbox';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { RouteComponentProps } from 'react-router-dom';
import { logger } from 'src/routes';
import { IUserProfile } from '../../../../components/navigation/profile/ProfileObjects';
import { IPartnerModel } from '../../../../Core/ViewModels/User/UserViewModel';
import { ReportProblem } from '../../../../ReportProblem/ReportProblem';
import {
    initialReportProblemOrganizerData, IReportProblemDetails,
    IReportProblemOrganizerData, ReportedStep, ReportProblemTypes
} from '../../../../ReportProblem/ReportProblemModel';
import { formattedClientName, getTaxSoftwareType } from '../../Helper/OrganizerHelperFunctions';
import ClientInfoModal from '../Modals/ClientInfoModal';
import { OrganizerFilterConstants } from './../../../../components/helper/Constants';
import { GetNumberOfDays } from './../../../../components/helper/HelperFunctions';
import { HideLoader, ShowLoader } from './../../../../components/helper/Loader';
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import { SortDirections } from './../../../../components/reports/Filters';
import { TaxSoftwareType } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';
import * as CompanyStore from './../../../../store/company/CompanyStore';
import * as UserProfileStore from '../../../../store/UserManagement/UserProfileStore';
import * as UserStoreSettings from '../../../../store/UserManagement/UserSettingStore';
import * as UserStore from '../../../../store/UserManagement/UserStore';
import {
    EngagementType, initialOrganizerDocument, OrganizerClient, OrganizerDocument, OrganizerDocumentUploadType, OrganizerFilter,
    OrganizerFilterType, ProcessStatus
} from './../../../models/OrganizerComponentModels';
import {
    OrganizerStoreState, OrganizerPrepareMessageStoreState,
    OrganizerSettingsStoreState,
    OrganizerPageSelectorStoreState,
    InprocessOrganizerFilterStoreState,
    apiPrefix
} from './../../../models/OrganizerStoreModels';
import * as CommonStore from './../../../store/CommonStore';
import * as InprocessOrganizerFilterStore from './../../../store/InprocessOrganizerFilterStore';
import * as CompanySettingsStore from './../../../store/OrganizerCompanySettingsStore';
import * as OrganizerPageSelectorStore from './../../../store/OrganizerPageSelectorStore';
import * as PreparerMessages from './../../../store/OrganizerPreparerMessageStore';
import * as OrganizerReports from './../../../store/OrganizerStore';
import * as OrganizerUpload from './../../../store/OrganizerUploadStore';
import * as UserSignatureStore from './../../../store/UserSignatureStore';
import * as CustomQuestionsStore from 'src/store/settings/CustomQuestions/CustomQuestionsStore';
import { DeleteInProcessConstants } from './../../Helper/OrganizerConstants';
import { OrganizerUploadModalSelector } from './../UploadDocumentsModal/OrganizerUploadDocumentModal';
import { OrganizerReportTable } from './OrganizerReportTable';
import OrganizerReportToolbar from './OrganizerReportToolbar';
import ProcessOrganizerModal from './ProcessOrganizerModal/ProcessOrganizerDocumentModal';
import * as AdditionalEsignDocumentStore from '../../../store/AdditionalEsignDocumentStore';
import { removeUnwantedWhitespacesFromText } from 'src/components/helper/HelperFunctions';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { ILocations } from 'src/Core/ViewModels/Company/CompanyViewModel';


type OrganizerReportProps = {
    companySettingsStore: OrganizerSettingsStoreState;
    organizerModel: OrganizerStoreState;
    preparerMessageStore: OrganizerPrepareMessageStoreState;
    users: UserStore.ISSRUserData;
    partners: UserStore.IPartnerData;
    companyData: CompanyStore.ICompanyData;
    reportSelector: OrganizerPageSelectorStoreState;
    userSettingStore: UserStoreSettings.UserSettings;
    inprocessOrganizerFilter: InprocessOrganizerFilterStoreState;
    userProfile: IUserProfile;
    userSignatures: UserSignatureStore.IUserSignatures;
    additionEsignDocumentStore: AdditionalEsignDocumentStore.IBlobFile;
    userLocations: UserStore.ILocationData;
}
    & typeof OrganizerUpload.actionCreators
    & typeof CompanySettingsStore.ActionCreators
    & typeof OrganizerReports.actionCreator
    & typeof UserStore.actionCreators
    & typeof PreparerMessages.actionCreators
    & typeof CompanyStore.actionCreators
    & typeof OrganizerPageSelectorStore.actionCreators
    & typeof UserStoreSettings.actionCreators
    & typeof InprocessOrganizerFilterStore.actionCreators
    & typeof CommonStore.actionCreators
    & typeof UserProfileStore.actionCreators
    & typeof UserSignatureStore.actionCreators
    & typeof AdditionalEsignDocumentStore.actionCreators
    & typeof CustomQuestionsStore.actionCreators
    & RouteComponentProps<{}>

interface OrganizerReportState {
    taxSoftware: TaxSoftwareType;
    showUpload: boolean;
    page: number;
    selectedRows: number[];
    sortName: string;
    sortOrder: string;
    filterTaxpayerName: string;
    filterClientId: string;
    filterUploadedOn?: Date;
    filterDocumentStatus: any;
    filterTaxYear: any;
    filterEngagementType: EngagementType;
    filterEro: string;
    filterLocation: string;
    filterAssignedTo: string;
    processOrganizer: {
        documentId: number;
        document: OrganizerDocument;
        show: boolean;
    };
    processLoader: boolean;
    uploadType: OrganizerDocumentUploadType;
    defaultERO: number;
    eroUsers: IPartnerModel[];
    clientInfoModal: {
        documentId: number;
        model: OrganizerClient[];
        show: boolean;
        clientId: string;
        isDelivered: boolean;
    };
    reportProblemModal: {
        documentId: number;
        organizerData: IReportProblemOrganizerData;
        show: boolean;
    };
    filter: OrganizerFilter;
    saveFilterShow: boolean;
    refreshDelay?: boolean;
    userLocations: UserStore.ILocationData;
}

const PAGESIZE = 20;
const NO_INDEX = -1;
const ORGANIZER_REPORT_PAGE_NAME = 'Organizers In-Progress Report';

export class OrganizerReport extends React.Component<OrganizerReportProps, OrganizerReportState>{
    private ReportType: OrganizerFilterType = OrganizerFilterType.Organizers;

    constructor(props: any) {
        super(props);
        this.state = {
            showUpload: false,
            filter: {
                fields: {},
                name: "",
                searchText: "",
                sort: {
                    column: "",
                    direction: SortDirections.None
                },
                filterType: OrganizerFilterType.Organizers,
                isDefaultFilter: false
            },
            page: 1,
            selectedRows: [],
            sortName: "",
            taxSoftware: TaxSoftwareType.None,
            sortOrder: "desc",
            filterClientId: '',
            filterUploadedOn: undefined,
            filterDocumentStatus: '',
            filterEngagementType: EngagementType.None,
            filterTaxpayerName: '',
            filterTaxYear: '',
            filterEro: "",
            filterLocation: "",
            filterAssignedTo: '',
            processOrganizer: {
                document: initialOrganizerDocument,
                documentId: 0,
                show: false
            },
            processLoader: false,
            uploadType: OrganizerDocumentUploadType.Proforma,
            defaultERO: 0,
            eroUsers: this.props.partners?.partners || [],
            userLocations: this.props.userLocations || [],
            clientInfoModal: {
                documentId: 0,
                model: [],
                show: false,
                clientId: '',
                isDelivered: false
            },
            reportProblemModal: {
                documentId: 0,
                organizerData: initialReportProblemOrganizerData,
                show: false
            },
            saveFilterShow: false
        }
    }

    componentDidMount() {
        this.props.requestOrganizers(this.buildQuery(this.state.page));
        this.props.requestOrganizerCompanySettings();
        this.props.requestAllSSRUsers(true);
        this.props.requestPreparerMessages();
        this.props.requestUserSettings(true);
        this.props.requestOrganizerReportFilter(this.ReportType);
        this.props.requestUserProfile();
        this.props.requestUserOfficeLocations();
    }

    componentDidUpdate(prevProps: Readonly<OrganizerReportProps>, prevState: Readonly<OrganizerReportState>, snapshot?: any): void {
        // If previous filter is not equal to current filter, then request for new data
        const clientIdNotEqual = this.state.filterClientId !== prevState.filterClientId;
        const uploadedOnNotEqual = this.state.filterUploadedOn !== prevState.filterUploadedOn;
        const documentStatusNotEqual = this.state.filterDocumentStatus !== prevState.filterDocumentStatus;
        const filterTaxpayerNameNotEqual = this.state.filterTaxpayerName !== prevState.filterTaxpayerName;
        const filterTaxYearNotEqual = this.state.filterTaxYear !== prevState.filterTaxYear;
        const sortNameNotEqual = this.state.sortName !== prevState.sortName;
        const sortOrderNotEqual = this.state.sortOrder !== prevState.sortOrder;
        const filterEroNotEqual = this.state.filterEro !== prevState.filterEro;
        const filterAssignedToNotEqual = this.state.filterAssignedTo !== prevState.filterAssignedTo;
        const filterLocationNotEqual = this.state.filterLocation !== prevState.filterLocation;

        if (clientIdNotEqual || uploadedOnNotEqual || documentStatusNotEqual || filterTaxpayerNameNotEqual || filterTaxYearNotEqual || sortNameNotEqual || sortOrderNotEqual || filterEroNotEqual || filterAssignedToNotEqual || filterLocationNotEqual) {
            this.props.requestOrganizers(this.buildQuery(this.state.page));
        }

    }

    componentWillReceiveProps(nextProps: OrganizerReportProps) {
        if (this.state.processOrganizer.documentId) {
            let organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.processOrganizer.documentId);
            if (organizer) {
                if (organizer.id === this.state.processOrganizer.document.id) {
                    organizer = {
                        ...this.state.processOrganizer.document,
                        processStatus: organizer.processStatus,
                        document: organizer.document,
                        additionalEsignDocuments: ((organizer.additionalEsignDocuments && organizer.additionalEsignDocuments.length > 0) ? organizer.additionalEsignDocuments : this.state.processOrganizer.document?.additionalEsignDocuments)
                    }
                }
                this.setState({
                    processOrganizer: {
                        ...this.state.processOrganizer,
                        document: organizer
                    }
                });
            }
        }
        if (this.props.partners?.partners !== nextProps.partners?.partners && nextProps.partners.partners.length > 0) {
            this.setState({
                eroUsers: nextProps.partners.partners
            });
        }
        
        if (this.props.userLocations !== nextProps.userLocations) {
            this.setState({
                userLocations: nextProps.userLocations
            });
        }

        if (nextProps.userSettingStore.settings && nextProps.userSettingStore.settings.defaultUserSettings) {
            this.setState({
                defaultERO: nextProps.userSettingStore.settings.defaultUserSettings.eroUser
            });
        }

        if (this.state.clientInfoModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.clientInfoModal.documentId);
            if (organizer && organizer.organizerClients) {
                this.setState({
                    clientInfoModal: {
                        ...this.state.clientInfoModal,
                        model: organizer.organizerClients
                    }
                });
            }
        }

        if (this.state.reportProblemModal.documentId) {
            const organizer = nextProps.organizerModel.model.documents.find(x => x.id === this.state.reportProblemModal.documentId);
            if (organizer && organizer.organizerClients) {
                this.setState({
                    reportProblemModal: {
                        ...this.state.reportProblemModal,
                        organizerData: { ...this.state.reportProblemModal.organizerData, clients: organizer.organizerClients }
                    }
                });
            }
        }
    }

    buildQuery = (page: number) => {
        const { filterClientId, filterUploadedOn,
            filterDocumentStatus, filterEngagementType,
            filterTaxpayerName, filterTaxYear,
            sortName, sortOrder, filterEro, filterAssignedTo,filterLocation
        } = this.state;

        return `?pageNo=${page}&pageSize=${PAGESIZE}&sortBy=${sortName}&sortOrder=${sortOrder}&filterClientId=${filterClientId}` +
            `${filterUploadedOn ? `&filterUploadedOn=${filterUploadedOn}` : ''}` +
            `&filterDocumentStatus=${filterDocumentStatus}&filterEngagementType=${filterEngagementType}&filterTaxpayerName=${filterTaxpayerName}` +
            `&filterTaxYear=${filterTaxYear}&filterEro=${filterEro}&filterAssignedTo=${filterAssignedTo}&filterLocationId=${filterLocation}`
    }



    onRowSelect = (row: any, isSelected: any, e: any) => {
        const newList = [...this.state.selectedRows];
        if (e.target.tagName !== 'BUTTON' && e.target.tagName !== 'I'
            && e.target.tagName !== 'SPAN' && e.target.tagName !== 'A') {
            if (isSelected) {
                newList.push(row.rowIndex);
            } else {
                const index = newList.indexOf(row.rowIndex);
                if (index > -1) {
                    newList.splice(index, 1);
                }
            }
            this.setState({ selectedRows: newList });
        }
        this.forceUpdate();
    }

    onSelectAll = (isSelected: any, rows: any, e: any) => {
        let selectedRows: number[] = [];
        if (isSelected) {
            const count = rows && rows.length ? rows.length : 0;
            Array.from(Array(count).keys());
            selectedRows = Array.from(Array(count).keys());
        }
        this.setState({ selectedRows: selectedRows });
    }
    onPageChange = (page: number, sizePerPage: number) => {
        this.setState({
            page: page,
            selectedRows: []
        }, this.loadDocuments);
    }

    onSortChange = (sortName: string, sortOrder: string, column: number) => {
        const temp: OrganizerFilter = { ...this.state.filter };
        temp.sort.column = sortName;
        temp.sort.direction = sortOrder === "asc" ? SortDirections.Ascending : SortDirections.Descending;
        this.setState({
            filter: temp,
            sortName: sortName,
            sortOrder: sortOrder === "asc" ? "asc" : "desc",
            selectedRows: []
        }, () => this.fetchDocuments(1, PAGESIZE));
    }

    onFilterNameChange = (event: any) => {
        const temp: OrganizerFilter = { ...this.state.filter };
        temp.name = event.target.value;
        this.setState({
            filter: temp
        })
    }


    onPageReload = () => {
        this.setState(
            { refreshDelay: true }, () => {
                const query = this.buildQuery(this.state.page);
                this.props.requestOrganizers(query, () => {
                    this.setState({
                        refreshDelay: false,
                        selectedRows: []
                    });
                });
            });
    }


    onFilterChange = (dataField: any) => {

        const newState = {
            filterClientId: '',
            filterUploadedOn: undefined,
            filterDocumentStatus: '',
            filterTaxpayerName: '',
            filterEngagementType: EngagementType.None,
            filterTaxYear: '',
            filterEro: '',
            filterLocation : '',
            filterAssignedTo: ''
        } as OrganizerReportState;

        let updated = false;
        let isClearFilter = true;
        let filterStatus: any;
        let filterLocationState: any;
        let filterTaxYear: any;
        var dict: { [columnName: string]: string; } = {};

        for (const field of Object.keys(dataField)) {
            const data = dataField[field.valueOf()].value ? dataField[field.valueOf()].value : dataField[field.valueOf()];
            if (isClearFilter && data !== "" && data !== "-1") {
                isClearFilter = false;
            }
            if (data !== "-1") {
                dict[field.valueOf().toString()] = data;
            }

            switch (field) {

                case 'taxpayerName':
                    newState.filterTaxpayerName = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'clientId':
                    newState.filterClientId = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'ero':
                    newState.filterEro = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'locationName':
                    filterLocationState = dataField[field].value ? dataField[field].value : dataField[field];
                    filterLocationState = (filterLocationState === "-1") ? "" : filterLocationState;
                    this.setState({ filterLocation: filterLocationState });
                    newState.filterLocation = filterLocationState;
                    updated = true;
                    break;
                case 'assignedTo':
                    newState.filterAssignedTo = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'uploadedOn':
                    newState.filterUploadedOn = GetNumberOfDays(dataField[field].value ? dataField[field].value : dataField[field]);
                    break;
                case 'engagementType':
                    newState.filterEngagementType = dataField[field].value ? dataField[field].value : dataField[field];
                    break;
                case 'documentStatus':
                    filterStatus = dataField[field].value ? dataField[field].value : dataField[field];
                    filterStatus = (filterStatus === "-1") ? "" : filterStatus;
                    this.setState({ filterDocumentStatus: filterStatus });
                    newState.filterDocumentStatus = filterStatus;
                    updated = true;
                    break;
                case 'taxYear':
                    filterTaxYear = dataField[field].value ? dataField[field].value : dataField[field];
                    filterTaxYear = (filterTaxYear === "-1") ? "" : filterTaxYear;
                    this.setState({ filterTaxYear: filterTaxYear });
                    newState.filterTaxYear = filterTaxYear;
                    updated = true;
                    break;
            }
        }

        const tempfilter: OrganizerFilter = { ...this.state.filter };
        tempfilter.fields = dict;

        if (this.isFilterChanged(newState) || updated) {
            this.setState({ ...newState, filter: tempfilter, page: 1, selectedRows: [] },
                () => {
                    const organizerReportTable: any = this.refs.organizerReportTable;
                    if (!organizerReportTable.getAppliedFilter())
                        this.props.requestOrganizers(this.buildQuery(this.state.page))

                })
        }
    }

    isFilterChanged = (newState: OrganizerReportState): boolean => {
        return (
            newState.filterEngagementType !== this.state.filterEngagementType ||
            newState.filterClientId !== this.state.filterClientId ||
            newState.filterUploadedOn !== this.state.filterUploadedOn ||
            newState.filterTaxpayerName !== this.state.filterTaxpayerName ||
            newState.filterEro !== this.state.filterEro ||
            newState.filterAssignedTo !== this.state.filterAssignedTo ||
            newState.filterLocation != this.state.filterLocation
        )
    }

    loadDocuments = () => {
        const queryString = this.buildQuery(this.state.page);
        this.props.requestOrganizers(queryString);
    }

    fetchDocuments = (page: number, sizePerPage: number) => {
        const queryString = this.buildQuery(page);
        this.setState({ page: page }, () => { this.props.requestOrganizers(queryString) });
    }

    delete = (index: any) => {
        let isDeleteAllIdFromCurrentPage = false;
        const ids = this.onPopupOpen(index);
        const obj = this;
        const { organizerModel: {
            model: { count: totalOrganizers,
                documents: { length: currentPageOrganizers }
            } }
        } = this.props;
        const { page } = this.state;
        const totalPages = Math.ceil(totalOrganizers / PAGESIZE);

        if (
            page === totalPages &&
            totalPages !== 1 &&
            ids.length === currentPageOrganizers) {
            isDeleteAllIdFromCurrentPage = true;
        }

        bootbox.confirm({
            title: DeleteInProcessConstants.Title,
            message: DeleteInProcessConstants.ConfirmDeletion,
            buttons: {
                cancel: {
                    label: '<i class="glyphicon glyphicon-remove"></i> Cancel',
                    className: 'btn-white btn-default'
                },
                confirm: {
                    label: '<i class="glyphicon glyphicon-ok"></i> Confirm',
                    className: 'btn-info'
                }
            },
            callback: (result: boolean) => {
                if (result) {
                    ShowLoader("Deleting...Please wait");
                    if (isDeleteAllIdFromCurrentPage) {
                        this.setState({ page: totalPages - 1 });
                    }
                    obj.props.deleteOrganizers(ids, this.onDeleteCompletion);
                }
            }
        });

    }

    onDeleteCompletion = () => {
        HideLoader();
        this.unSelectRows();
        this.loadDocuments();
    }

    onUploadReturnsOpen = (taxSoftware: TaxSoftwareType) => {
        if (this.state.uploadType === OrganizerDocumentUploadType.Proforma) {
            logger?.trackPageView("Upload proforma document");
        }
        else {
            logger?.trackPageView("Upload batch document");
        }
        this.setState({
            showUpload: true,
            taxSoftware: taxSoftware
        });
    }


    onUploadDocumentHide = () => {
        logger?.trackPageView(ORGANIZER_REPORT_PAGE_NAME);
        this.setState({
            showUpload: false,
            taxSoftware: TaxSoftwareType.None
        })
    }

    onProcessOrganizer = (rowIndex: number) => {
        logger?.trackPageView('Process Organizer Modal');
        const organizer = this.props.organizerModel.model.documents[rowIndex];
        this.props.requestOrganizer(organizer.id, this.processOrganizerCallback, () => {
            this.setState({
                processLoader: false
            });
        });
        this.props.requestDelegatedSigners(this.props.userProfile.userId);
        this.props.requestCompanySignatureUploadLink();
        this.props.requestDownloadPath(this.props.userProfile.userId);
        this.setState({
            processLoader: true
        });
    }

    processOrganizerCallback = (id: number, document: OrganizerDocument) => {
        this.props.requestOrganizerPdfDocument(document.processInfoGuid, id, document.taxYear);        
        
        (document.processStatus as any) !== "Processing" &&
            this.props.updateDocumentStatus(id, ProcessStatus.Processing, document.createdBy === 0);

        //to-do
        //Lock org to the user
        const organizer = this.props.organizerModel.model.documents.find(doc => doc.id === id);
        
        if (organizer) {
            var companyLocations = this.props.companyData.companyProfile.locations;
            
            if(companyLocations && organizer.locationId){
                let locationName = companyLocations.filter(r=>r.locationId == organizer.locationId)[0].name;
                organizer.locationName = locationName;
            }

            this.setState({
                processLoader: false,
                processOrganizer: {
                    documentId: id,
                    show: true,
                    document: cloneDeep(organizer)
                }
            })
        } else {
            VenusNotifier.Warning("Something went wrong! Please refresh the page and try again!", null);
        }
    }

    onCloseProcessOrganizer = (documentId: number) => {
        //unlock user of organizer
        //this.props.requestOrganizer(documentId);
        this.setState({
            processOrganizer: {
                documentId: 0,
                show: false,
                document: initialOrganizerDocument
            }
        })
        logger?.trackPageView(ORGANIZER_REPORT_PAGE_NAME);
    }

    updateDocument = (document: OrganizerDocument) => {
        this.setState(preState => ({
            processOrganizer: {
                ...preState.processOrganizer,
                document: document
            }
        }));
    }

    onPopupOpen = (rowIndex: number) => {
        if (rowIndex !== NO_INDEX) {
            return [this.props.organizerModel.model.documents[rowIndex].id];
        }
        if (this.state.selectedRows.length === 0) {

            return [];
        }
        return this.selectedDocumentIds();
    }

    selectedDocumentIds = () => {
        const ids: number[] = [];
        this.state.selectedRows.map((row) => {
            const selectedDocument = this.props.organizerModel.model.documents[row];
            if (selectedDocument) {
                ids.push(selectedDocument.id);
            }
        });
        return ids;
    }

    rowDoubleClick = (rowIndex: number) => {
        const organizer = this.props.organizerModel.model.documents[rowIndex];
        if (organizer.processStatus === ProcessStatus.Ready || organizer.processStatus === ProcessStatus.Processing ||
            (organizer.processStatus as any) == "Ready" || (organizer.processStatus as any) == "Processing"
        ) {
            this.onProcessOrganizer(rowIndex);
        }
    }

    unSelectRows = () => {
        this.setState({
            selectedRows: []
        });
    }

    onEditClientInfoOpen = (index: number) => {
        logger?.trackPageView('Client Info Modal');
        const organizer = this.props.organizerModel.model.documents[index];
        if (organizer) {
            this.props.requestOrganizerClients(organizer.id);
            this.setState({
                clientInfoModal: {
                    documentId: organizer.id,
                    model: organizer.organizerClients,
                    show: true,
                    clientId: organizer.clientId,
                    isDelivered: organizer.processStatus === ProcessStatus.Delivered ? true : false,
                }
            })
        }
    }

    onReportProblemOpen = (index: number) => {
        const organizer = this.props.organizerModel.model.documents[index];
        if (organizer) {
            this.props.requestOrganizerClients(organizer.id);
            this.setState({
                reportProblemModal: {
                    ...this.state.reportProblemModal,
                    documentId: organizer.id,
                    show: true,
                    organizerData: {
                        ...this.state.reportProblemModal.organizerData,
                        returnType: organizer.engagementType.toString(),
                        taxYear: organizer.taxYear,
                        clientId: organizer.clientId,
                        processStatus: organizer.processStatus.toString(),
                        taxpayerName: formattedClientName(organizer.taxpayerName),
                        clients: organizer.organizerClients,
                        documentId: organizer.id,
                        taxSoftware: getTaxSoftwareType(organizer.taxSoftware)
                    }
                }
            })
        }
    }

    onReportProblemCancel = () => {
        this.setState({ reportProblemModal: { ...this.state.reportProblemModal, organizerData: initialReportProblemOrganizerData, documentId: 0, show: false } })
    }

    onReportProblemSave = (problemDetails: IReportProblemDetails) => {
        let url = `${apiPrefix}Organizer/SaveHelpReportProblem`;
        this.props.reportProblemSave(
            problemDetails,
            url,
            (data) => {
                this.setState({
                    reportProblemModal: {
                        ...this.state.reportProblemModal,
                        organizerData: initialReportProblemOrganizerData,
                        documentId: 0,
                        show: false
                    }
                });
                VenusNotifier.Success("Problem reported successfully. Your Tracking ID is " + data + ".", "Report a Problem");
            });
    }

    onEditClientInfoClose = () => {
        logger?.trackPageView(ORGANIZER_REPORT_PAGE_NAME);
        this.setState({
            clientInfoModal: {
                documentId: 0,
                model: [],
                show: false,
                clientId: '',
                isDelivered: false
            }
        })
    }

    clientInfoSave = (clientInfo: OrganizerClient[], clientId: string, resendEmail?: boolean, isTpEmailupdated?: boolean, isSpouseEmailupdated?: boolean) => {
        this.props.saveClientInfo(clientInfo, clientId, this.state.clientInfoModal.documentId,
            this.onEditClientInfoClose, resendEmail);
    }
    ValidateFilterName = (filterName: string) => {
        return /[#?//\\]/.test(filterName) == true;
    }
    onFilterSave = (onApplyFilter: (filter: OrganizerFilter) => void) => {
        const { inprocessOrganizerFilter: { filters }, saveOrganizerReportFilter } = this.props;
        const { filter } = this.state;
        if (filter.name?.trim() != '') {
            filter.name = removeUnwantedWhitespacesFromText(filter.name);
            
            const duplicateFilter = filters.find(x => x.name.toLowerCase() == filter.name.toLowerCase() && x.filterType == this.ReportType);
            
            if (this.ValidateFilterName(filter.name)) {
                VenusNotifier.Warning(OrganizerFilterConstants.FilterNameIsNotValid, null);
            }
            else if (duplicateFilter) {
                VenusNotifier.Warning(OrganizerFilterConstants.FilterNameAlreadyExists, null);
            }
            else {
                saveOrganizerReportFilter(filter.name, filter, () => {
                    onApplyFilter(filter);
                    this.onSaveFilterHide();
                });
            }
        }
        else {
            VenusNotifier.Warning(OrganizerFilterConstants.PleaseEnterFilterName, null);
        }
    }

    onFilterUpdate = (name: string, actionName: string) => {
        const { inprocessOrganizerFilter: { filters }, updateOrganizerReportFilter } = this.props;
        const { filter } = this.state;
        const duplicateFilter = filters.find(x => x.name == name && x.filterType == this.ReportType);
        if (!duplicateFilter) {
            VenusNotifier.Warning(OrganizerFilterConstants.FilterDoesNotExists, null);
        }
        else {
            filter.name = name;
            updateOrganizerReportFilter(name, actionName, filter, () => {
                this.onSaveFilterHide();
            });
        }
    }

    onSetDefaultFilter = (name: string, actionName: string) => {
        const { inprocessOrganizerFilter: { filters }, updateOrganizerReportFilter } = this.props;
        let oldDefaultFilter = filters.find(x => x.isDefaultFilter && x.filterType == this.ReportType);

        if (oldDefaultFilter && oldDefaultFilter.name != name) {
            oldDefaultFilter.isDefaultFilter = false;
            updateOrganizerReportFilter(oldDefaultFilter.name, actionName, oldDefaultFilter);
        }

        let filter = filters.filter(x => x.name == name);
        if (filter) {
            filter[0].isDefaultFilter = true;
            updateOrganizerReportFilter(name, actionName, filter[0], undefined, true);
        }
    }

    onRemoveDefaultFilter = (name: string, actionName: string) => {
        const { inprocessOrganizerFilter: { filters }, updateOrganizerReportFilter } = this.props;
        let filter = filters.find(x => x.name == name);
        if (filter) {
            filter.isDefaultFilter = false;
            updateOrganizerReportFilter(name, actionName, filter, undefined, true);
        }
        let organizerReportTable: any = this.refs.organizerReportTable;
        organizerReportTable.refs?.Filters?.setState({ activeFilter: '', appliedFilters: [] });
        this.onPageReload();
    }

    onFilterDelete = (filterName: string) => {
        this.props.removeOrganizerReportFilter(filterName, this.ReportType);
    }

    onSaveFilterHide = () => {
        this.setState({
            saveFilterShow: false
        });
    }

    onSaveFilterShow = () => {
        this.setState({
            saveFilterShow: true
        });
    }

    onExportToExcel = (callback: () => void) => {
        this.props.exportOrganizersAsExcel(this.buildQuery(this.state.page), callback);
    }

    onChangeUploadType = (option: any) => {
        this.setState({
            uploadType: option.value
        });
    }

    generateTaxpayerView = (document: OrganizerDocument, callback: () => void) => {
        this.props.generateTaxpayerView && this.props.generateTaxpayerView(document, callback);
    }

    getUploadLinkForAdditionalEsign = (callback: (data?: any) => void) => {
        const { processOrganizer: { document } } = this.state;
        this.props.getUploadLink(`${apiPrefix}Document/GetAdditionalESignUploadLinkAsync/?documentGuid=${document.processInfoGuid}&year=${document.taxYear}`,
            (data) => { callback(data); });
    }

    deleteBlobAdditionalEsign = (fileGuid: string, callback?: (data?: any) => void) => {
        const { processOrganizer: { document } } = this.state;
        this.props.deleteDocument(`${apiPrefix}Document/DeleteAdditionalEsignDocumentAsync/?documentGuid=${document.processInfoGuid}&fileGuid=${fileGuid}&year=${document.taxYear}`,
            () => { callback && callback(); });
    }

    //Used for office location dropdown on grid
    getUserLocationData = () => {
        var companyLocations = this.props.companyData.companyProfile.locations;
        var userLocations = this.props.userLocations.locations;
        let locationList: IDropdown[]=[];

        if (companyLocations && userLocations) {
            const commonUserLocations = companyLocations.reduce(
                (result, item) =>
                    userLocations.some(el => el === item.locationId)
                        ? [...result, item]
                        : result,
                []
            );
            
            if (commonUserLocations) {
                commonUserLocations?.forEach((location: ILocations) => {
                    const locationVal: IDropdown = {
                        label: location.name,
                        value: location.locationId
                    }
                    locationList.push(locationVal);
                })


            }
                locationList.push({
                    label: 'Blanks',
                    value: -2
                });
        }
        return locationList;
    }

    render() {
        const { showUpload, selectedRows, page, taxSoftware, processOrganizer, processLoader,
            uploadType, eroUsers, defaultERO,
            clientInfoModal, filter, saveFilterShow } = this.state;
        const { companySettingsStore, getUploadLink,
            deleteUploadedDocument, submitOrganizer, submitOrganizerWithSeperateEL,
            companyData, users: { users }, preparerMessageStore: { preparerMessages },
            organizerModel: { loading, model: { count, documents }, popupLoading },
            updateOrganizer, deliverOrganizer, deleteDocument,
            reportSelector: { selectedOrganizerOption },
            changeOrganizerOptions, companyData: { companyProfile: { countryStates } },
            inprocessOrganizerFilter: { filters }, downloadCSVTemplate,
            requestAdditionalEsignDocs, updateAdditionalEsignDocuments, deleteAdditionalEsignDocs,
            getCustomQuestions, getCustomQuestionTemplate, getCustomQuestion
        } = this.props;

        const defaultFilter = filters.find(x => x.isDefaultFilter);

        let defaultFilterName = "";

        if (defaultFilter) {
            defaultFilterName = defaultFilter.name
        }

        return (
            <div>
                <OrganizerReportToolbar
                    pageTitle="Organizers In-Process"
                    onDelete={this.delete}
                    selectedDocumentCount={selectedRows.length}
                    onUploadClick={this.onUploadReturnsOpen}
                    taxSoftwares={companyData.commonSettings?.taxSoftwareSetting ? companyData.commonSettings.taxSoftwareSetting.taxSoftware : []}
                    selectedUploadTypeId={uploadType}
                    onChangeUploadType={this.onChangeUploadType}
                />

                <br />
                <LoadingOverlay style={{ height: '100%' }}>
                    <OrganizerReportTable
                        ref={'organizerReportTable'}
                        documents={documents}
                        isLoading={loading}
                        loadGrid={this.loadDocuments}
                        onFilterChange={this.onFilterChange}
                        filterList={filters}
                        onPageChange={this.onPageChange}
                        onRowSelect={this.onRowSelect}
                        onSelectAll={this.onSelectAll}
                        onSortChange={this.onSortChange}
                        pageNo={page}
                        pageSize={PAGESIZE}
                        saveFilterShow={saveFilterShow}
                        selectedRows={selectedRows}
                        totalRows={count}
                        onProcessOrganizer={this.onProcessOrganizer}
                        onDeleteOpen={this.delete}
                        pageReload={this.onPageReload}
                        onRowDoubleClick={this.rowDoubleClick}
                        onEditClientInfoOpen={this.onEditClientInfoOpen}
                        onReportProblemOpen={this.onReportProblemOpen}
                        filterType={this.ReportType}
                        currentFilter={filter}
                        defaultFilter={defaultFilterName}
                        onExportToExcel={this.onExportToExcel}
                        onFilterDelete={this.onFilterDelete}
                        onFilterNameChange={this.onFilterNameChange}
                        onFilterSave={this.onFilterSave}
                        onFilterUpdate={this.onFilterUpdate}
                        onRemoveDefaultFilter={this.onRemoveDefaultFilter}
                        onSaveFilterHide={this.onSaveFilterHide}
                        onSaveFilterShow={this.onSaveFilterShow}
                        onSetDefaultFilter={this.onSetDefaultFilter}
                        selectedOrganizerOption={selectedOrganizerOption}
                        changeOrganizerOptions={changeOrganizerOptions}
                        refreshDelay={this.state.refreshDelay}
                        userLocationList={this.getUserLocationData()} 
                    />
                    <Loader loading={processLoader} text={"Opening Organizer"} />
                </LoadingOverlay>

                <OrganizerUploadModalSelector
                    show={showUpload}
                    getUploadLink={getUploadLink}
                    onHide={this.onUploadDocumentHide}
                    pageReload={this.onPageReload}
                    submitDocuments={submitOrganizer}
                    taxSoftware={taxSoftware}
                    deleteDocument={deleteUploadedDocument}
                    uploadType={uploadType}
                    deleteBatchDocument={deleteDocument}
                    defaultERO={defaultERO}
                    eroUsers={eroUsers}
                    downloadCSVTemplate={downloadCSVTemplate}
                    submitSingleDocuments={submitOrganizerWithSeperateEL}
                    enableEngagementLetter={companySettingsStore.companySettings.defaultSettings.enableEngagementLetter}
                    userLocations={this.getUserLocationData().filter(x=> x.value != -2 )}
                />
                {
                    processOrganizer.show &&
                    <ProcessOrganizerModal
                        document={processOrganizer.document}
                        onClose={this.onCloseProcessOrganizer}
                        onFinish={deliverOrganizer}
                        onSave={updateOrganizer}
                        order={1}
                        company={companyData}
                        preparerMessages={preparerMessages}
                        users={users}
                        updateDocument={this.updateDocument}
                        companySettings={companySettingsStore.companySettings}
                        showSignaturePanel={true}
                        getCustomQuestionTemplates={getCustomQuestions}
                        getCustomQuestionTemplate={getCustomQuestionTemplate}
                        getCustomQuestion={getCustomQuestion}
                        isCustomQuestionEditable={true}
                        generateTaxpayerView={this.generateTaxpayerView}
                        sourceDocumentEnabled={this.props.companySettingsStore.companySettings?.sourceDocumentSettings?.enabledSourceDocuments}
                        getUploadLink={this.getUploadLinkForAdditionalEsign}
                        requestDocuments={requestAdditionalEsignDocs}
                        deleteBlobAdditionalEsign={this.deleteBlobAdditionalEsign}
                        deleteEsignDocuments={deleteAdditionalEsignDocs}
                        updateAdditionalEsignDocument={updateAdditionalEsignDocuments}
                        userSettings={this.props.userSettingStore}
                        userBasicProfile={this.props.userProfile}
                        userSignatures={this.props.userSignatures}
                        requestDownloadPath={this.props.requestDownloadPath}
                        additionalEsignReadOnly={false}
                        convertDocToPdfArtifactAsync={this.props.convertDocToPdfArtifactAsync}
                        isBatchOrganizer={false}
                        partners={this.props.partners}
                        defaultERO={defaultERO}
                        userLocations={this.props.userLocations}
                        pageReload={this.onPageReload}
                    />
                }

                <ClientInfoModal
                    clientId={clientInfoModal.clientId}
                    isDelivered={clientInfoModal.isDelivered}
                    model={clientInfoModal.model}
                    onClose={this.onEditClientInfoClose}
                    onSave={this.clientInfoSave}
                    states={countryStates}
                    show={clientInfoModal.show}
                    loading={popupLoading}
                    unDeliveredReport={true}
                    showOfficeLocation={false}
                />

                <ReportProblem
                    onCancel={this.onReportProblemCancel}
                    onReportProblemSave={this.onReportProblemSave}
                    show={this.state.reportProblemModal.show}
                    loggedInCPA={this.props.userProfile}
                    companyName={this.props.companyData.companyProfile.companyInfo.companyName}
                    problemStep={ReportedStep.InProcessOrganizer}
                    reportProblemType={ReportProblemTypes.ReturnSpecific}
                    organizerData={this.state.reportProblemModal.organizerData}
                    taxSoftwares={companyData.commonSettings?.taxSoftwareSetting ? companyData.commonSettings.taxSoftwareSetting.taxSoftware : []}
                    disableTaxSoftware={true}
                />
            </div>
        );
    }
}
