import { PdfViewer } from 'awesome-pdf-viewer';
import { Header } from 'awesome-pdf-viewer/dist/layout/Header';
import { PageProperties } from 'awesome-pdf-viewer/dist/layout/LayoutBase';
import { LeftPanel } from 'awesome-pdf-viewer/dist/layout/LeftPanel';
import ViewPanel from 'awesome-pdf-viewer/dist/layout/ViewPanel';
import { CustomOptions } from 'awesome-pdf-viewer/dist/toolbar/CustomOptions';
import { Pagination } from 'awesome-pdf-viewer/dist/toolbar/Pagination';
import { Toolbar } from 'awesome-pdf-viewer/dist/toolbar/Toolbar';
import Zoom from 'awesome-pdf-viewer/dist/toolbar/Zoom';
import { PageMode, PdfSource } from 'awesome-pdf-viewer/dist/viewer/ViewerBase';
import { isEqual } from 'lodash';
import * as React from 'react';
import { ButtonGroup, Dropdown } from 'react-bootstrap';
import { INVALID_PAGE } from './../../../../../../components/common/ProcessReturnModal/CustomPagination/CustomPagination';
import { Bookmarks, GroupType } from './../../../../../models/OrganizerComponentModels';
import { OrganizerBookmarkPanel } from './Panels/OrganizerBookmarkPanel';

export interface RemovedTabProps {
    pdf: any;
    pages: number[];
    moveToGroup: (destination: GroupType, pageNo: number) => void;
    bookmarks: Bookmarks;
    engagementLetterExists: boolean;
    readOnlyView?: boolean;
    pdfURL: string;
    hideMoveTo?: boolean;
}

export interface RemovedTabState {
    bookmarkExpand: boolean;
    currentPage: number;
    zoomEnabled: boolean;
    scale: number;
    pageHeight: number;
    showDeleteConfirmationPopover: boolean;
    moveToToggle: boolean;
    deletePopoverTarget: any;
    currentPageNo: number;
}


export default class RemovedTab extends React.Component<RemovedTabProps, RemovedTabState> {
    private _viewPanel: any;
    private _controlLayer: any;
    private _controlList: any[] = [];
    private _toolbar: any;

    constructor(props: RemovedTabProps) {
        super(props);
        this.state = {
            bookmarkExpand: true,
            currentPage: INVALID_PAGE,
            zoomEnabled: false,
            scale: 1,
            pageHeight: 792,
            showDeleteConfirmationPopover: false,
            moveToToggle: false,
            deletePopoverTarget: {},
            currentPageNo: this.props.pages.length > 0 ? 1 : 0
        }
    }

    componentDidMount() {
        this.setReferences();
        this.setState({
            currentPage: this.props.pages[0],
            currentPageNo: 1
        });
    }

    componentDidUpdate() {
        this.setReferences();
    }

    componentWillReceiveProps(nextProps: RemovedTabProps) {
        if (nextProps.pages.length === 0) {
            this.setState({
                currentPage: 0
            })
        }
        else if (nextProps.pages.length > 0 &&
            nextProps.pages.length != this.props.pages.length) {
            this.onSelectFirstPage(nextProps.pages);
        }
    }

    shouldComponentUpdate(nextProps: RemovedTabProps, nextState: RemovedTabState) {
        return ((nextProps.pages !== this.props.pages) ||
            (this.state.currentPage === INVALID_PAGE) ||
            (!isEqual(this.state, nextState)));
    }

    setReferences = () => {
        this._toolbar && this._toolbar.setViewerReference(this._viewPanel);
        this._viewPanel && this._viewPanel.setToolbarReference(this._toolbar);
        this._viewPanel && this._viewPanel.setControlsReference(this._controlList);
        this._viewPanel && this._viewPanel.setControlLayerReference(this._controlLayer);
        this._controlLayer && this._controlLayer.setControlsReference(this._controlList);
    }

    getCurrentPageHeight = () => {
        if (this._viewPanel) {
            const document = this._viewPanel.getDocument();
            document && document.getPage(this.state.currentPage)
                .then((page: any) => {
                    const pageHeight = page.view[3];
                    this.setState({
                        pageHeight: pageHeight
                    })
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    }

    toggleBookmarkExpand = () => {
        this.setState(prevState => ({
            bookmarkExpand: !prevState.bookmarkExpand
        }));
    }


    onPaginationClick = (pageNo: number) => {
        this.setState({ currentPage: this.props.pages[pageNo - 1] });
    }

    onZoom = (enable: boolean) => {
        this.setState({ zoomEnabled: enable });
    }

    onPageSelect = (pageNo: number) => {
        this._viewPanel.gotoPage(pageNo);
        this.setState({
            currentPage: pageNo,
            currentPageNo: this.props.pages.indexOf(pageNo) + 1
        });
    }

    onSelectFirstPage = (newPages: number[]) => {
        this._viewPanel.gotoPage(newPages[0]);
        this.setState({
            currentPage: newPages[0],
            currentPageNo: 1
        });
    }



    createMoveTo = () => {
        const { hideMoveTo, moveToGroup, engagementLetterExists } = this.props;
        return <div><ButtonGroup className="zIndex10"
            style={{ visibility: `${hideMoveTo ? 'hidden' : 'visible'}` as React.CSSProperties }} >
            <Dropdown
                rootClose>
                <Dropdown.Toggle
                    style={{ zIndex: 10000 }}
                    id="move-to-dropdown"
                    variant="warning"
                    pullRight={true}>
                    Move To
                            </Dropdown.Toggle>

                <Dropdown.Menu rootCloseEvent="click">
                    {
                        engagementLetterExists &&
                        <Dropdown.Item eventKey={GroupType.Engagement}

                            onClick={() => { moveToGroup && moveToGroup(GroupType.Engagement, this.state.currentPage) }} href="#"
                            style={{ fontSize: '14px' }}>
                            <i className='fas fa-file-alt ddl-icon'
                                style={{ marginLeft: '0px' }}
                            ></i>Engagement Letter</Dropdown.Item>
                    }

                    {
                        <div>
                            <Dropdown.Item eventKey={GroupType.Organizer}
                                onClick={() => { moveToGroup && moveToGroup(GroupType.Organizer, this.state.currentPage) }} href="#"
                                onSelect={this.toggleMoveToDropDown}
                                style={{ fontSize: '14px' }}
                                ref={this.attachMoveToDeletePopoverRef}
                            >
                                <i className='fas fa-trash ddl-icon'
                                    style={{ marginLeft: '0px' }}>
                                </i>Organizer
                            </Dropdown.Item>

                        </div>
                    }
                </Dropdown.Menu>
            </Dropdown>
        </ButtonGroup>
        </div>
    }

    onPageChange = (pageProps: PageProperties) => {
        this._toolbar.handleZoomChange(this.state.scale);
        this.setState({
            currentPage: pageProps.page,
            currentPageNo: this.props.pages.indexOf(pageProps.page) + 1
        });
    }


    private toggleMoveToDropDown = (eventKey: any, event: Object) => {
        if (eventKey === GroupType.Removed.toString()) {
            this.setState({ moveToToggle: true })
        }
        else {
            this.setState({ moveToToggle: !this.state.moveToToggle })
        }
    }

    private attachMoveToDeletePopoverRef = (target: any) => {
        this.setState({ deletePopoverTarget: target });
    };

    onFirstPage = () => {
        this._viewPanel.gotoPage(this.props.pages[0]);
        this.setState({
            currentPage: this.props.pages[0],
            currentPageNo: 1
        });
    }

    onLastPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.length - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.length - 1],
            currentPageNo: this.props.pages.length
        });
    }

    onNextPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) + 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage) + 2
        });
    }

    onPreviousPage = () => {
        this._viewPanel.gotoPage(this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1]);
        this.setState({
            currentPage: this.props.pages[this.props.pages.indexOf(this.state.currentPage) - 1],
            currentPageNo: this.props.pages.indexOf(this.state.currentPage)
        });
    }

    onGotoPage = (pageNo: number) => {
        this._viewPanel.gotoPage(this.props.pages[pageNo - 1]);
        this.setState({
            currentPage: this.props.pages[pageNo - 1],
            currentPageNo: pageNo
        });
    }



    render() {
        const { pages, bookmarks, readOnlyView, pdfURL } = this.props;
        const { bookmarkExpand, currentPageNo } = this.state;
        return (
            <PdfViewer>
                {
                    pages.length > 0 &&
                    <Header>
                        <Toolbar
                            ref={(ref: any) => this._toolbar = ref}
                            customToolbarOptions={this.createMoveTo()}
                            hideReadOnly={true}
                            hideRightPanel={true}>
                            <Pagination
                                currentPage={currentPageNo}
                                onFirstPage={this.onFirstPage}
                                onNextPage={this.onNextPage}
                                onPreviousPage={this.onPreviousPage}
                                onLastPage={this.onLastPage}
                                onGotoPage={this.onGotoPage}
                                totalPages={pages.length}
                            >

                            </Pagination>
                            <Zoom>
                            </Zoom>
                            <CustomOptions position="left" />

                        </Toolbar>
                    </Header>

                }
                <div className="main" style={{
                    height: 'calc(100vh - 250px)',
                    fontSize: '12px'
                }}>
                    <LeftPanel>
                        <OrganizerBookmarkPanel
                            expanded={bookmarkExpand}
                            toggleExpand={this.toggleBookmarkExpand}
                            bookmarks={bookmarks}
                            onPageSelect={this.onPageSelect}
                        />
                    </LeftPanel>

                    {pages.length > 0 && <ViewPanel onDocumentLoad={() => {
                        this.setState({
                            scale: 1
                        })
                    }}
                        ref={(ref: any) => this._viewPanel = ref}

                        onPageChanged={this.onPageChange}
                        onScaleChanged={(scale: number) => {
                            this.setState({
                                scale: scale
                            })
                        }}
                        pageMode={PageMode.SinglePage}
                        defaultPage={pages[0]}
                        pdfSource={PdfSource.createFromUrl(this.props.pdfURL)}
                        disableTextLayer={true}
                    >
                    </ViewPanel>
                    }
                </div>
            </PdfViewer>
        );

    }
}