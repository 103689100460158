import * as React from 'react';
import { addTask } from 'domain-task';
import * as signalR from '@microsoft/signalr';
import * as CompanyStore from '../../../store/company/CompanyStore';
import { IUserProfile } from '../../../components/navigation/profile/ProfileObjects';
import { handleResponse } from '../../../store/Library';
import { VenusNotifier } from '../../../components/helper/VenusNotifier';
import { StoreConstants } from '../Helper/OrganizerConstants';
import store from '../../../configureStore';
import { actionTypes } from '../../../store/ActionTypes';
import { API_BASE_URL } from 'src/utils/constants';

const isEqual = require("react-fast-compare");
const bearerPrefix: string = 'Bearer ';

export interface ISignalRState {
    apiBaseUrl: any
};
interface SignalRProps {
    profile: IUserProfile;
    companyData: CompanyStore.ICompanyData;
    getMyDownloadsListAsync: (callback?: any) => void;
    refreshDeliveredOrganizers: () => void;
    refreshRecycledOrganizers: () => void;
    //onUserPrivilegesChange: (logoutCause: LogoutCause) => void;
}

export interface INotificationMessage {
    documentId: number;
    documentGuid: string;
    companyId: number;
    notifictionType: NotifictionType;
    data: any;
}
export interface INotificationMetaData {
    clientId: string
}

export enum NotifictionType {
    None = "None",
    BulkDownloadSuccess = "BulkDownloadSuccess",
    BulkDownloadFailed = "BulkDownloadFailed",
    ArchiveOrganizersSuccess = "ArchiveOrganizersSuccess",
    ArchiveOrganizersFailed = "ArchiveOrganizersFailed",
    RecycleOrganizersSuccess = "RecycleOrganizersSuccess",
    RecycleOrganizersFailed = "RecycleOrganizersFailed",
    PermentDeleteOrganizerSuccess = "PermentDeleteOrganizerSuccess",
    PermentDeleteOrganizerFailed = "PermentDeleteOrganizerFailed"
}


export interface IUserAutoLogoutNotificationMessage {
    Users: number;
    JobId: number;
    logoutCause: LogoutCause;
    CompanyId: number;
}
export enum LogoutCause {
    None = 0,
    UserPermissionChange = 1,
    UserDelete = 2,
    ProductTierChange = 3,
    CompanyDisable = 4,
}

export class SignalRWebSocket extends React.Component<SignalRProps, ISignalRState> {

    private user: string = '';
    private group: string = '';
    private token: any = '';
    state = {
        apiBaseUrl: ''
    };

    componentDidMount() {
        this.getToken();
    }

    private getToken() {
            const user = store.getState().auth.user;
            this.token = user.access_token;
            this.getWebSocketUrl();
    }

    private getWebSocketGroup = (companyId: number) => {
        var group = "00000000" + companyId;
        return 'sso' + group.substr(group.length - 8);
    }

    shouldComponentUpdate(nextProps: SignalRProps, nextState: ISignalRState) {
        return (((nextProps.profile.userId !== this.props.profile.userId)
            && (nextProps.profile.userId !== 0)
            && nextProps.companyData.companyProfile.companyInfo.id !== 0)
            || !isEqual(this.state, nextState));
    }

    private signalRConnectionInit = () => {
        let fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/negotiate`, {
            method: 'POST',
            headers: new Headers({
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token,
                'ClientType': "CPA"
            })
        }).then(handleResponse)
            .then((info: any) => {
                info.accessToken = info.accessToken || info.accessKey;
                info.url = info.url || info.endpoint;

                const options = {
                    accessTokenFactory: () => info.accessToken
                };

                const connection = new signalR.HubConnectionBuilder()
                    .withUrl(info.url, options)
                    .withAutomaticReconnect()
                    .configureLogging(signalR.LogLevel.Information)
                    .build();
                console.log(connection);
                connection.on('BulkDownload', this.processOrganizerData);
                connection.on('UserPrivilegesChanged', this.processUserPrivilegesChanged);
                connection.onclose(() => {
                    console.log('disconnected');
                    this.startConnection(connection);
                });
                this.startConnection(connection);

            }).catch((reason) => {
                console.log(reason);
            })
        addTask(fetchTask);
    }
    private startConnection = (connection: any) => {
        const _self: any = this;
        console.log('connecting...');
        connection.start()
            .then(function () {
                console.log('connected!');
                _self.addGroup();
                connection.invoke('getConnectionId')
                    .then(function (connectionId: any) {
                        // Send the connectionId to controller
                    });
            })
            .catch(function (err: any) {
                console.error(err);
                setTimeout(_self.startConnection, 5000, connection);
            });
    }
    private getCookieValue = (key: string) => {
        const b: any = document.cookie.match('(^|[^;]+)\\s*' + key + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }
    private addGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/AddToGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User added to the group successfully")
            }
        });
        addTask(fetchTask);
    }

    private removeGroup = () => {
        const fetchTask = fetch(`${this.state.apiBaseUrl}/signalr/RemoveFromGroup`, {
            method: 'POST',
            headers: {
                'x-ms-signalr-userid': this.user,
                'Authorization': bearerPrefix + this.token
            },
            body: JSON.stringify({
                recipient: this.user,
                groupname: this.group
            })
        }).then((resp: any) => {
            if (resp.status == 200) {
                console.log("User removed from group successfully")
            }
        });
        addTask(fetchTask);
    }
    private getWebSocketUrl = () => {
        const fetchTask = fetch(`${API_BASE_URL}/api/WebSocket/GetWebSocketConnectionUrl`, {
            method: 'GET',
            credentials: 'include',

        }).then(handleResponse)
            .then((resp: any) => {
                this.setState({ apiBaseUrl: resp }, () => {
                    if(this.state.apiBaseUrl){
                        const user = store.getState().auth.user;
                        this.user = user.profile.company_id + '_' + user.profile.user_id;
                        this.group = this.getWebSocketGroup(user.profile.company_id);
                        this.signalRConnectionInit();
                    }
                });
            });
        addTask(fetchTask);
    }

    getNotificationMessage = (notificationType: NotifictionType): string => {
        switch (notificationType) {
            case NotifictionType.ArchiveOrganizersFailed:
                return StoreConstants.Failure.ArchiveOrganizers;
            case NotifictionType.RecycleOrganizersFailed:
                return StoreConstants.Failure.RecycleOrganizers;
            case NotifictionType.PermentDeleteOrganizerSuccess:
                return StoreConstants.Success.PermentDeleteOrganizer;
            case NotifictionType.PermentDeleteOrganizerFailed:
                return StoreConstants.Failure.PermentDeleteOrganizer;
            default: return "";
        }
    }

    private processOrganizerData = (notificationMessage: INotificationMessage) => {
        switch (notificationMessage.notifictionType) {
            case NotifictionType.BulkDownloadSuccess:
            case NotifictionType.BulkDownloadFailed:
                console.log("signal R notification type", notificationMessage.notifictionType);
                this.props.getMyDownloadsListAsync();
                break;
            case NotifictionType.ArchiveOrganizersSuccess:
            case NotifictionType.RecycleOrganizersSuccess:
                this.props.refreshDeliveredOrganizers();
                break;
            case NotifictionType.ArchiveOrganizersFailed:
            case NotifictionType.RecycleOrganizersFailed:
                const message = this.getNotificationMessage(notificationMessage.notifictionType);
                VenusNotifier.Error(message, null);
                break;

            case NotifictionType.PermentDeleteOrganizerSuccess:
                break;
            case NotifictionType.PermentDeleteOrganizerFailed:
                VenusNotifier.Error(this.getNotificationMessage(notificationMessage.notifictionType), null);
                break;
        }
    }

    private processUserPrivilegesChanged = (notificationMessage: IUserAutoLogoutNotificationMessage) => {

        switch (notificationMessage.logoutCause) {
            case LogoutCause.UserPermissionChange:
            case LogoutCause.UserDelete:
            case LogoutCause.ProductTierChange:
            case LogoutCause.CompanyDisable:
                console.log("signal R notification type", notificationMessage.logoutCause);
                store.dispatch({ type: actionTypes.USER_PRIVILEGE_CHANGED, payload: notificationMessage.logoutCause as LogoutCause });
                break;
            default:
                console.log("signal R notification type", notificationMessage.logoutCause);
                store.dispatch({ type: actionTypes.USER_PRIVILEGE_CHANGED, payload: notificationMessage.logoutCause as LogoutCause });
        }
    }

    public render() {

        return (<div />);
    }

}
export default SignalRWebSocket;