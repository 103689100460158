import * as React from 'react';
import { Button } from 'react-bootstrap';
import UploadFabButton from './../UploadDocumentsModal/OrganizerUploadFabButton';
import { TaxSoftwareType } from '../.././../../Core/ViewModels/Company/CompanySettingsViewModel';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../../../Logger/Telemetry/TelemetryLogger';
import { ResourceIdConstants } from "../../../../components/helper/Rbac/RbacConstants";

interface OrganizerReportToolbarProps {
    pageTitle: string;
    onDelete: (rowIndex: number) => void;
    selectedDocumentCount: number;
    taxSoftwares: TaxSoftwareType[];
    onUploadClick: (value: TaxSoftwareType) => void;
    selectedUploadTypeId: number;
    onChangeUploadType: (selected: any) => void;
    batchView?: boolean;
}

const NO_INDEX = -1;


const OrganizerReportToolbar: React.StatelessComponent<OrganizerReportToolbarProps> =
    ({ pageTitle, onDelete, onUploadClick, selectedDocumentCount, taxSoftwares, onChangeUploadType, selectedUploadTypeId, batchView }) => {
        const btnSytle = {
            width: '105px',
            marginRight: '5px'
        };
        return <div>
            <div className="row marB10">
                <div className="col-md-8" style={{ marginTop: '5px' }}>
                    <h3>{pageTitle}</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    {
                        !batchView &&
                        <Button
                            variant='default'
                            style={btnSytle}
                            className='btn btn-white'
                            data-test-auto="04CE0584-4E83-4706-AF86-2D90BC9599AB"
                            disabled={selectedDocumentCount === 0}
                            data-resource-id={ResourceIdConstants.DeleteOrganizerInProcessToolBar}
                            onClick={(e: any) => onDelete(NO_INDEX)} >
                            <i className='fas fa-trash-alt'></i>Delete
                        </Button>
                    }
                </div>
                <div className="col-md-6 txt-ar" data-test-auto="9220d129-e6f1-46a0-a3c6-8ae23ac2ba29">
                    <UploadFabButton
                        onUploadClick={onUploadClick}
                        taxSoftwares={taxSoftwares}
                        onChangeUploadType={onChangeUploadType}
                        selectedUploadTypeId={selectedUploadTypeId}
                    />
                </div>

            </div>
        </div>
    }

export default withAITracking(reactPlugin, OrganizerReportToolbar);