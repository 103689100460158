import * as React from 'react';
import { PreparerMessage } from './../../../models/OrganizerComponentModels';

interface PreparerMessageListProps {
    preparerMessages: PreparerMessage[];
    onListItemClick: (messageId: number) => void;
    selectedMessageId: number;
    defaultMessageId: number;
}

export const PreparerMessageList: React.StatelessComponent<PreparerMessageListProps> = ({ defaultMessageId, onListItemClick, preparerMessages, selectedMessageId }) => {

    let messageId: number = selectedMessageId == 0 ? defaultMessageId : selectedMessageId
    return (
        <div>
            <ul className="list-group">
                {
                    preparerMessages.map((message, index) => (
                        <li
                            className={message.id == messageId ? "preparer-message-list-item custom-active" : "preparer-message-list-item"}
                            title={message.name}
                            onClick={() => onListItemClick(message.id)}
                        >
                            <div className="width100">
                                <div style={{ display: 'inline-block' }}
                                    className={message.id == defaultMessageId ? "col-md-10 nopadding overflowHidden" : "overflowHidden"}>
                                    {message.name.length > 60 ? message.name.substring(0, 60) + '...' : message.name}
                                </div>
                                {
                                    message.id == defaultMessageId ?
                                        <div className="col-md-2" style={{ float: 'right' }}>
                                            <span className={"badge default-span"}>Default</span>
                                        </div>
                                        : <span></span>
                                }
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

