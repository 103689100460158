import * as React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { OrganizerDocumentUploadType } from '../../../models/OrganizerComponentModels';
import { TaxSoftwareType } from './../../../../Core/ViewModels/Company/CompanySettingsViewModel';

interface BlankUploadModalProps {
    onHide: () => void;
    show: boolean;
    taxSoftware: TaxSoftwareType;
}
interface BlankUploadModalState { }

export class BlankUploadModal extends React.Component<BlankUploadModalProps, BlankUploadModalState>{
    constructor(props: BlankUploadModalProps) {
        super(props);
        this.state = {};
    }

    render() {
        const { onHide, show, taxSoftware } = this.props;
        return (
            <Modal className="upload-doc-modal"
                show={show}
                onHide={onHide} enforceFocus={false}>
                <Modal.Header closeButton >
                    <Modal.Title>
                        {` Upload Blank ${TaxSoftwareType[taxSoftware].toString()} Document `}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="upload-doc-body">
                    <div className="row">
                        

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="default" onClick={onHide}>
                        <i className='glyphicon glyphicon-remove'></i>Close</Button>
                </Modal.Footer>
            </Modal>
            );
    }
}