import * as React from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { TinyMCEComponent } from './../common/TinyMCEComponent';
import { Link } from 'react-router-dom';
import { PreparerMessageConstants } from './../../Helper/OrganizerConstants';
import { OverlayLoader } from './../../../../components/helper/OverlayLoader';
import { CheckBoxComponent } from './../../../../components/common/CheckBoxComponent';
import {
    PreparerMessage,
    VariableListData,
    initialPreparerMessage
} from './../../../models/OrganizerComponentModels';
import { VariableList } from './../common/VariableList';
import { replaceHelperVariableForEditor } from './../../Helper/OrganizerHelperFunctions';
var htmlencode = require('htmlencode');

interface PreparerMessageModalProps {
    onShow: boolean;
    onHide: () => void;
    submitButtonClick: (messageDetail: PreparerMessage, isSetDefault: boolean, isToggled: boolean) => void;
    buttonText: string;
    selectedPreparerMessage: PreparerMessage;
    title: string;
    defaultMessage: number;
    isUpdate: boolean;
    preparerMessages: PreparerMessage[];
    showLoader: boolean;
    loaderText: string;
    variableList: VariableListData[];
}

interface PreparerMessageModalState {
    preparerMessage: PreparerMessage;
    alert: string;
    message: string;
    isSetDefault: boolean;
    isToggled: boolean;
    showAlert: boolean;
    isVariableListExpand: boolean;
}

export class PreparerMessageModal extends React.Component<PreparerMessageModalProps, PreparerMessageModalState> {

    constructor(props: PreparerMessageModalProps) {
        super(props);
        this.state = {
            preparerMessage: initialPreparerMessage,
            alert: "warning",
            message: "",
            isSetDefault: false,
            isToggled: false,
            showAlert: true,
            isVariableListExpand: false
        }
    }

    componentWillReceiveProps(props: PreparerMessageModalProps) {
        this.setState({
            preparerMessage: { ...props.selectedPreparerMessage }
        })
    }

    checkDuplicate = (messageDetail: PreparerMessage) => {
        let count: number = 0;
        const { preparerMessages } = this.props;
        preparerMessages.map((message, index) => {
            if (message.name.trim().toLowerCase() == messageDetail.name.trim().toLowerCase() &&
                message.id != messageDetail.id)
                count++;
        })
        return count;
    }

    onCancelButtonClick = () => {
        this.props.onHide();
        this.clearState();
    }

    onAddButtonClick = () => {
        const { preparerMessage } = this.state;
        if (preparerMessage.name.trim() == "") {
            this.setState({ alert: "danger", message: PreparerMessageConstants.ValidateName, showAlert: false })
            return false;
        }
        else if (preparerMessage.body == "" || preparerMessage.body.replace(/\&nbsp;/g, '').replace(/<\/?p[^>]*>/g, '').trim() == "") {
            this.setState({ alert: "danger", message: PreparerMessageConstants.ValidateBody, showAlert: false })
            return false;
        }

        if (this.checkDuplicate(preparerMessage) > 0) {
            this.setState({ alert: "danger", message: PreparerMessageConstants.SaveMessageNameError, showAlert: false })
            return false;
        }
        this.props.submitButtonClick(preparerMessage, this.state.isSetDefault, this.state.isToggled)
        this.clearState();
    }

    clearState = () => {
        this.setState({
            preparerMessage: initialPreparerMessage,
            alert: "warning",
            message: "",
            isSetDefault: false,
            isToggled: false,
            showAlert: true
        })
    }

    onMessageNameChange = (event: any) => {
        let _messageDetails = this.state.preparerMessage
        _messageDetails.name = event.target.value
        this.setState({ preparerMessage: _messageDetails })
    }

    onMessageBodyChange = (event: any) => {
        let _messageDetails = this.state.preparerMessage
        _messageDetails.body = event.target.getContent()
    }

    onCheckboxChange = (event: any) => {
        let _messageDetails = this.state.preparerMessage
        _messageDetails.isAllowToEdit = event.target.checked
        this.setState({ preparerMessage: _messageDetails })
    }

    onSetDefaultClick = (isTrue: boolean) => {
        let message = "";
        if (isTrue) {
            message = PreparerMessageConstants.SetDefaultMessageWarning;
        }
        else {
            message = PreparerMessageConstants.RemoveDefaultMessageWarning;
        }
        this.setState({
            isSetDefault: isTrue,
            isToggled: true,
            alert: "warning",
            message: message,
            showAlert: false
        })
    }

    onVariableListExpandToggle = () => {
        this.setState(prevState => ({
            isVariableListExpand: !prevState.isVariableListExpand
        }))
    }

    render() {
        const { variableList, defaultMessage, selectedPreparerMessage,
            isUpdate, onShow, showLoader, loaderText, title,
            buttonText
        } = this.props;
        const { isSetDefault, isToggled, preparerMessage, isVariableListExpand } = this.state;
        let toggleState: boolean = defaultMessage == selectedPreparerMessage.id && isUpdate == true && isToggled == false ?
            true : isSetDefault == true ? true : false
        return (
            <div style={{ position: 'relative' }}>
                <Modal show={onShow} onHide={this.onCancelButtonClick}>
                    <OverlayLoader show={showLoader} text={loaderText} />
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <span
                                className='fas fa-envelope'
                                style={{ color: 'grey', marginRight: '5px' }}>
                            </span>{title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Alert style={{ margin: '0px' }} variant={this.state.alert} hidden={this.state.showAlert} ><i className='fas fa-exclamation-triangle' style={{ marginRight: '5px' }}>
                        </i>{this.state.message}</Alert>
                        <div className="form-group">
                            <label className="font-weight-bold">Name</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="Message name under which this message will be saved">
                                <i className="glyphicon glyphicon-question-sign"></i>
                            </Link>
                            <div className="pull-right">
                                <label style={{ marginTop: 8, marginRight: 10 }}>Default</label>
                                <label className="switch">
                                    <input type="checkbox"
                                        checked={toggleState}
                                        onChange={(event) => this.onSetDefaultClick(event.target.checked)}
                                    ></input>
                                    <span className="slider round"></span>
                                </label>
                            </div>
                            <input type="text"
                                data-test-auto="23566D10-E795-45EB-BBDD-73842676A88C"
                                className="form-control preparer-message-font-size"
                                placeholder="Message Name"
                                value={preparerMessage.name}
                                onChange={this.onMessageNameChange}
                                maxLength={100}>
                            </input>
                        </div>

                        <div className="form-group">
                            <label className="font-weight-bold">Body</label>
                            <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="Actual text of this message">
                                <i className="glyphicon glyphicon-question-sign"></i>
                            </Link>
                            <TinyMCEComponent
                                messageBody={replaceHelperVariableForEditor(htmlencode.htmlDecode(preparerMessage.body))}
                                changeStateTinymceBody={this.onMessageBodyChange}
                                readOnly={0}
                            />
                        </div>
                        <div className="form-group">
                            <CheckBoxComponent
                                id={"allowUserToEdit"}
                                text={"Allow users to edit before sending"}
                                checked={preparerMessage.isAllowToEdit}
                                onChange={this.onCheckboxChange}
                            />
                        </div>
                        <VariableList variableList={variableList}
                            toggleListExpand={this.onVariableListExpandToggle}
                            isListExpand={isVariableListExpand}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="default"
                            data-test-auto="80FA18FD-ED80-4D83-94CC-4FE05B57661B"
                            onClick={this.onCancelButtonClick}>
                            <i className='glyphicon glyphicon-remove'></i>Cancel
                                </Button>
                        <Button
                            variant="info"
                            data-test-auto="2E2752F0-5331-4641-A105-DF12DDEBD4F9"
                            onClick={this.onAddButtonClick}>
                            <i className='glyphicon glyphicon-floppy-disk'></i>{buttonText}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}