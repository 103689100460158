import * as React from 'react';
import Select from 'react-select';
import { OrganizerDocumentUploadTypeLabelValue } from './../../../models/OrganizerComponentModels';
import { TaxSoftwareType } from '../.././../../Core/ViewModels/Company/CompanySettingsViewModel';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../../../Logger/Telemetry/TelemetryLogger';
import { ResourceIdConstants } from '../../../../components/helper/Rbac/RbacConstants'

export interface UploadFabButtonProps {
    taxSoftwares: TaxSoftwareType[];
    onUploadClick: (taxSoftware: TaxSoftwareType) => void;
    selectedUploadTypeId?: number;
    onChangeUploadType?: (selected: any) => void;
    hideDropDown?: boolean;
}


const UploadFabButton: React.StatelessComponent<UploadFabButtonProps> = ({ taxSoftwares, onUploadClick, selectedUploadTypeId, onChangeUploadType, hideDropDown }) => {

    return (<div
        style={{
            display: 'flex',
            flexDirection: 'row-reverse',

        }}
    >
        {
            taxSoftwares && taxSoftwares.length >= 1 &&
            (

                taxSoftwares.map((value, index) => {
                    const style: React.CSSProperties = { position: "initial", marginLeft: '10px', fontSize: '14px' };
                    return (

                        value === TaxSoftwareType.ProSystems ?
                            <div key={index} title="CCH" data-test-auto="93AFA737-631C-493E-80AD-67F3B5E81A41" data-resource-id={ResourceIdConstants.UploadOrganizer} className="cch-btn"
                                onClick={() => { onUploadClick(value) }} style={style}>CCH</div> : (
                                value === TaxSoftwareType.UltraTax ?
                                    <div key={index} title="UltraTax" data-test-auto="A0C11973-E03C-42F9-98B0-70CD9FB0FE4C" data-resource-id={ResourceIdConstants.UploadOrganizer} className="ut-btn"
                                        onClick={() => { onUploadClick(value) }} style={style}>UT</div> : (
                                        value === TaxSoftwareType.GoSystem ?
                                            <div key={index} title="GoSystem" data-test-auto="8CD788EC-5A73-404D-AEB4-473D325DB7B6" data-resource-id={ResourceIdConstants.UploadOrganizer} className="gs-btn"
                                                onClick={() => { onUploadClick(value) }} style={style}>GS</div> : (
                                                value === TaxSoftwareType.Lacerte ?
                                                    <div key={index} title="Lacerte" data-test-auto="B5ACAB24-F260-4125-A732-911E1B69187A" data-resource-id={ResourceIdConstants.UploadOrganizer} className="lt-btn"
                                                        onClick={() => { onUploadClick(value) }} style={style}>LT</div> :
                                                    ""
                                            )
                                    )
                            )

                    )
                })
            )
        }
        {
            !hideDropDown &&
            <div
                style={{
                    textAlign: 'initial',
                    width: '150px',
                    zIndex: 100,
                    fontSize: '14px'
                }}>
                <Select
                    name="uploadType"
                    value={selectedUploadTypeId}
                    onChange={onChangeUploadType}
                    options={OrganizerDocumentUploadTypeLabelValue}
                    clearable={false}
                    searchable={false}
                />
            </div>
        }
    </div>);
}

export default withAITracking(reactPlugin, UploadFabButton);