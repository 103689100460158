export const CqAutomation = {
    Preview: "b1b66fb5-2610-463b-91f8-0d52431779ad",
    Save: "02faca87-3620-4808-b82f-d7a81903474c",
    Section: {
        questionTitle: "05819bb8-0169-43cc-85fd-7537f68e92d4",
        duplicate: "c1f42257-3261-47d2-89b2-f9043a30010a",
        delete: "aa67d901-e89f-4799-882a-4ee1e99eb415",
        required: "36e891a7-4345-473d-a495-12e88d56f30d",
        addQuestion: "21de629a-b5a8-4a53-8536-f815e22943a8",
        questionDropDown: "d04f9736-8683-4a5a-ab0c-98e998b03e55",
        questionType: {
            multiChoice: {
                deleteChoice: "b3a86052-4304-11ee-be56-0242ac120002",
                addChoice: "bd974b32-4304-11ee-be56-0242ac120002",
                choiceText: "c1729fb8-4304-11ee-be56-0242ac120002"
            }
        }
    },
    AddSection: "19701f18-a273-4d79-bc9d-7d444d55683c",
    DeleteSectionWidget: "c823ba59-ece2-49a1-b33f-4af7f585386d",
    DeleteSectionDropdown: "e894934a-8689-46fe-b326-ac2f3fea515f",
    RenameSection: "e7902846-3aa2-4f19-a1c0-75b893fc25b3",
    PreviewClose: "6d66e30e-81a0-4a68-a06a-42b08ceea085",
    PreviewRestart: "ddbd244c-b39d-40af-896e-332ac2054d6b",
    PreviewAnswer: "8728e3c1-53a2-4c92-a7ff-167a880ef2ef",
    SectionDeleteConfirm: "4b3cfaaa-33c7-458d-84eb-7eedfdb3e495",
    SectionDeleteCancel: "0eec7f63-80f5-4aca-b672-354cda340ace"
};
