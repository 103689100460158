import * as React from 'react';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { Link } from 'react-router-dom';
import { RadioButtonComponent } from '../Common/RadioButtonComponent';
import { Modal, Button } from 'react-bootstrap';
import { FaFileDownload } from 'react-icons/fa';
import * as  Bootstrap from 'react-bootstrap';
import { DownloadType } from '../../../models/OrganizerComponentModels';

export enum DefaultDownloadInfoType {
  DownloadNow = "DownloadNow",
  DownloadToMyDownloads = "DownloadToMyDownloads",
  PromptMeEveryTime = "PromptMeEveryTime"
}

interface DownloadTypeOptionProps {
    show: boolean,
    onCancel: () => void;
    selectedDocuments?: undefined;
    hideLoader?: boolean;
    selectedDownloadType: DownloadType
    onDownloadOptionChange(selectedValue: any): void;
    onDownloadOptionSave(selectedValue: any): void;
    useDefaultDownloadType(selectedValue: any): void;
    selectedDefaultDownloadType: boolean;
}


interface DownloadTypeOptionState {
    saving: boolean;
}

export default class DownloadTypeOption extends React.Component<DownloadTypeOptionProps, DownloadTypeOptionState>
{
    state = {
        saving: false
    }

    public render() {

        const isSaveEnabled = (this.props.selectedDownloadType === DownloadType.DownloadNow ) || ( this.props.selectedDownloadType === DownloadType.DownloadToMyDownloads )

        let isLoaded: boolean = true;
        let modalBody =
            <div className="padR30">
                <div>
                    <span style={{ fontSize: '17px' }}>
                        Choose where to download:
                    </span>
                </div>
                <div style={{ position: 'relative', padding: '15px' }}>
                    <div className=" padR30" style={{ marginLeft: '15px' }}>
                        <RadioButtonComponent
                            id={"DownloadNow"}
                            checked={this.props.selectedDownloadType == DownloadType.DownloadNow}
                            onChange={(e) => this.props.onDownloadOptionChange(e.target.value)}
                            value={DefaultDownloadInfoType.DownloadNow}
                            dataTestAuto={"CC1D0849-C531-4A43-8A88-CFB97C57D971"}
                            name="defaultDownload"
                            text={"Download Now"}
                        />
                        <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="On enabling, allow the user to download the docs straight to a zip file">
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </div>
                    <div className=" padR30" style={{ marginLeft: '15px' }}>
                        <RadioButtonComponent
                            id={"DownloadToMyDownloads"}
                            text={"Download To My Downloads"}
                            checked={this.props.selectedDownloadType == DownloadType.DownloadToMyDownloads}
                            onChange={(e) => this.props.onDownloadOptionChange(e.target.value)}
                            value={DefaultDownloadInfoType.DownloadToMyDownloads}
                            dataTestAuto={"E83C2483-C6E1-4FF2-BE43-62A1A596D0A3"}
                            name="defaultDownload"
                        />
                        <Link to={'#'} className="help-icon marL05" data-placement="right" data-toggle="tooltip" title="On enabling, system will perform the task in the background. User can download the documents from 'My Downloads' after the download is complete">
                            <i className="fas fa-question-circle"></i>
                        </Link>
                    </div>
                </div >
            </div>

        return (
            <Modal className="download-options-modal" show={this.props.show} onHide={this.props.onCancel}
            style={{position:"absolute",top:"0%",left:"30%",right:"0%",width:"600px"}}
            >
                <Modal.Header closeButton data-test-auto="E9FF957A-1DE3-4FBA-8DD9-987ADBB404BA">
                    <Modal.Title>
                        <FaFileDownload className='modalIcon' />
                    Download
                    </Modal.Title>
                </Modal.Header>
                <LoadingOverlay>
                    <Modal.Body>
                        {modalBody}
                    </Modal.Body>
                    <Loader loading={this.state.saving || !isLoaded} text={"Please wait..."} />
                </LoadingOverlay>

                <Modal.Footer>
                    <Bootstrap.Button
                        className="btn-white"
                        variant="default"
                        data-test-auto="28A5846C-E7CE-420B-9E9F-D1BE18DE1C90"
                        onClick={this.props.onCancel}
                    ><i className="fas fa-times"></i>
                        Cancel
                    </Bootstrap.Button>

                    <Bootstrap.Button
                        variant='info'
                        data-test-auto="056DD1B3-F56D-439C-B5E0-8998A4BDDAC7"
                        onClick={this.props.onDownloadOptionSave}
                        disabled={!isSaveEnabled}
                    > <i className='fas fa-save'></i>Save

                    </Bootstrap.Button>

                </Modal.Footer>
            </Modal>
        )
    }
}