import * as React from 'react';
import {
    Card, Row, FormControl, Col,
    FormLabel, Modal, Button, Popover, Overlay
} from 'react-bootstrap';
import Select from 'react-select';
import { PhoneNumberComponent } from './../../../../components/common/PhoneNumberComponent';
import { ICountryState, ILocations } from './../../../../Core/ViewModels/Company/CompanyViewModel';
import { VenusNotifier } from './../../../../components/helper/VenusNotifier';
import { ClientInfoTab, OrganizerClientIdConstants } from './../../../../components/helper/Constants';
import * as _ from 'lodash';
import { emailUpdatedConfirmation } from './../../Helper/OrganizerConstants';
import { OrganizerClient, initialOrganizerClient, OrganizerClientType, EngagementLetterStatus } from './../../../models/OrganizerComponentModels';
import { Loader, LoadingOverlay } from 'react-overlay-loader';
let dateMoment = require('moment');
import { validateOrganizerClient, GetCountryCode } from './../../Helper/OrganizerHelperFunctions';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';
import { ILocationData } from 'src/store/UserManagement/UserStore';
import LocationMismatchActionModal from 'src/components/common/LocationMismatchActionModal/LocationMismatchActionModal';
import { ShowLoader } from './../../../../components/helper/Loader';

export interface ClientInfoModalProps {
    clientId: string;
    model: OrganizerClient[];
    states: ICountryState[];
    isDelivered: boolean;
    show: boolean;
    onClose: () => void;
    onSave: (taxPayer: OrganizerClient[], clientId: string, resendEmail?: boolean, isTpEmailupdated?: boolean, isSpouseEmailupdated?: boolean) => void;
    loading: boolean;
    status?: EngagementLetterStatus;
    readOnly?: boolean;
    unDeliveredReport?: boolean;
    companyLocations: ILocations[];
    userLocations: ILocationData;
    onCloseLocationMismatchActionModal: () => void;
    onContinueLocationMismatchActionModal: (clients?: OrganizerClient[], clientId?: string, resendEmail?: boolean, isTpEmailupdated?: boolean, isSpouseEmailupdated?: boolean, documentId?: number) => void;
    onClientManagementComparisonFailed: () => void;
    dcoumentId: number;
    onLocationChange: (locationId: number) => void;
    showOfficeLocation: boolean;
}

export interface ClientInfoModalState {
    taxPayer: OrganizerClient;
    spouse: OrganizerClient;
    clientId: string;
    unChangedTpEmail: string;
    unChangedSpouseEmail: string;
    saveConfirmPopover: boolean;
    locationId: number;
    loading: boolean;
    locationsState: IDropdown[];
    showLocationMismatchActionModel: boolean;
    saveChangedEmail: boolean;
}

export default class ClientInfoModal extends React.Component<ClientInfoModalProps, ClientInfoModalState> {
    savePopoverRef: any;
    constructor(props: ClientInfoModalProps) {
        super(props);
        this.state = {
            taxPayer: initialOrganizerClient,
            spouse: initialOrganizerClient,
            clientId: '',
            unChangedTpEmail: '',
            unChangedSpouseEmail: '',
            saveConfirmPopover: false,
            locationId: 0,
            loading: false,
            locationsState: [],
            showLocationMismatchActionModel: false,
            saveChangedEmail: false
        }
        this.savePopoverRef = null;
    }

    componentDidMount(): void {
        if (this.state.locationsState && this.state.locationsState.length === 1) {
            this.props.model[0].locationId = this.state.locationsState[0].value;
            this.setState({
                locationId: this.props.model[0].locationId
            })

            const taxpayerModel: OrganizerClient = { ...this.state.taxPayer };
            taxpayerModel.locationId = this.props.model[0].locationId;
            this.setState({
                taxPayer: taxpayerModel
            });

            this.props.onLocationChange(this.props.model[0].locationId);
        }
    }
    componentWillReceiveProps(nextProps: ClientInfoModalProps) {
        if (nextProps.model && !_.isEqual(this.props.model, nextProps.model)) {
            const taxpayer = nextProps.model.find(x => x.clientType == OrganizerClientType.Taxpayer);
            const spouse = nextProps.model.find(x => x.clientType == OrganizerClientType.Spouse);
            this.setState({
                taxPayer: taxpayer ? taxpayer : initialOrganizerClient,
                spouse: spouse ? spouse : initialOrganizerClient,
                unChangedTpEmail: taxpayer ? taxpayer.email : '',
                unChangedSpouseEmail: spouse ? spouse.email : '',
                locationId: taxpayer ? taxpayer.locationId : 0
            })
        }
        if (!_.isEqual(this.props.clientId, nextProps.clientId)) {
            this.setState({
                clientId: nextProps.clientId,
                saveConfirmPopover: false
            })
        }

        this.getOfficeLocations(this.props.companyLocations);
    }

    onChange = (event: any, type: OrganizerClientType) => {
        const target: string = event.target.name;
        switch (type) {
            case OrganizerClientType.Taxpayer:
                const taxpayerModel: any = { ...this.state.taxPayer };
                taxpayerModel[target] = event.target.value;
                this.setState({
                    taxPayer: taxpayerModel
                });
                break;
            case OrganizerClientType.Spouse:
                const spouseModel: any = { ...this.state.spouse };
                spouseModel[target] = event.target.value;
                this.setState({
                    spouse: spouseModel
                });
        }
    }

    handleChangeDoB = (value: any, type: OrganizerClientType) => {
        const now = new Date();
        if (dateMoment(value) > now) {
            VenusNotifier.Warning(ClientInfoTab.AddedRefund.DOBWarning, null);
        }
        else {
            switch (type) {
                case OrganizerClientType.Taxpayer:
                    const taxpayerModel: any = { ...this.state.taxPayer };
                    taxpayerModel.dob = value;
                    this.setState({
                        taxPayer: taxpayerModel
                    });
                    break;
                case OrganizerClientType.Spouse:
                    const spouseModel: any = { ...this.state.spouse };
                    spouseModel.dob = value;
                    this.setState({
                        spouse: spouseModel
                    });
            }
        }
    }

    onSaveClientInfo = () => {
        const { taxPayer: { email }, clientId, taxPayer, spouse, locationId } = this.state;
        const clients = [taxPayer];

        if (!clientId) {
            VenusNotifier.Warning(`Please enter Client Id`, null);
            return;
        }

        if (!email) {
            VenusNotifier.Warning('Please enter Taxpayer Email Id', null);
            return
        }

        if (!validateOrganizerClient(taxPayer)) {
            return;
        }

        if (locationId <= 0) {
            VenusNotifier.Warning('Please select Office Location', null);
            return;
        }

        if (spouse.clientGuid) {
            if (!validateOrganizerClient(spouse)) {
                return;
            }
            clients.push(spouse);
        }

        let validateSigners: boolean[] = [];
        if (this.props.model != undefined && this.props.model.length > 0) {
            this.props.model.forEach((signer) => {
                let result: boolean = false;
                let unchanged = signer.clientType == OrganizerClientType.Taxpayer ? this.state.unChangedTpEmail : this.state.unChangedSpouseEmail;
                let newMail = signer.clientType == OrganizerClientType.Taxpayer ? this.state.taxPayer.email : this.state.spouse.email;

                if (signer.signingOrder == 1) {
                    result = this.ValidateFirstSigner(newMail, unchanged);
                }
                else {
                    result = this.ValidateSecondSigner(newMail, unchanged);
                }

                validateSigners.push(result);
            });
        }

        if (this.props.userLocations && validateSigners.every(v => v === false)) {
            this.showLocationMismatchModal(clients);
        }
    }

    showLocationMismatchModal = (clients: OrganizerClient[], saveChangedEmail: boolean = false) => {
        let userLocationsData = this.props.userLocations.locations;
        let locationValue = this.state.locationId;

        const locationData = locationValue && userLocationsData.find(location => location === locationValue);

        if (!locationData && locationValue > 0) {
            this.setState({ showLocationMismatchActionModel: true, saveChangedEmail: saveChangedEmail });
        }
        else {
            setTimeout(() => this.onCloseClientModel(clients?.filter(r => r.clientGuid != ''), saveChangedEmail), 100);
        }
    }

    onCloseClientModel = (clients: OrganizerClient[], saveChangedEmail: boolean = false) => {
        const { taxPayer: { email }, unChangedTpEmail, clientId, saveConfirmPopover, spouse, unChangedSpouseEmail } = this.state;
        const { onSave } = this.props;
        if (!saveConfirmPopover) {
            if (unChangedTpEmail === email &&
                (clients.length > 1 && unChangedSpouseEmail === clients[1].email)) {
                onSave(clients, clientId);
            }
            else {
                onSave(clients, clientId, saveChangedEmail, this.isEmailupdateRequired(clients[0]), (clients.length > 1 ? this.isEmailupdateRequired(clients[1]) : false));
            }
        }
    }

    onSave = (updateEmail = false) => {
        const { taxPayer: { email }, unChangedTpEmail, clientId, taxPayer, spouse, unChangedSpouseEmail, locationId } = this.state;
        const { onSave } = this.props;
        const clients = [taxPayer];

        this.setState({ saveConfirmPopover: updateEmail });

        if (!clientId) {
            VenusNotifier.Warning(`Please enter Client Id`, null);
            return;
        }
        if (!email) {
            VenusNotifier.Warning(`Please enter Taxpayer Email Id`, null);
            return;
        }

        if (this.props.showOfficeLocation && locationId <= 0) {
            VenusNotifier.Warning(`Please enter Office Location`, null);
            return;
        }

        if (!validateOrganizerClient(taxPayer)) {
            return;
        }
        if (spouse.clientGuid) {
            if (!validateOrganizerClient(spouse)) {
                return;
            }
            clients.push(spouse);
        }
        if (unChangedTpEmail === email &&
            (clients.length > 1 && unChangedSpouseEmail === clients[1].email)) {
            onSave(clients, clientId);
        }
        else {
            onSave(clients, clientId, updateEmail, this.isEmailupdateRequired(clients[0]), (clients.length > 1 ? this.isEmailupdateRequired(clients[1]) : false));
        }
    }

    onEmailChange = (isEmailChanged: boolean) => {

        const { taxPayer, spouse } = this.state;
        const clients = [taxPayer, spouse];

        this.setState({ saveConfirmPopover: false });

        if (this.props.userLocations)
            this.showLocationMismatchModal(clients, isEmailChanged);
    }

    isEmailupdateRequired = (client: OrganizerClient) => {
        if (client.clientType == OrganizerClientType.Taxpayer && client.email) {
            return (client.email != this.state.unChangedTpEmail);
        }

        if (client.clientType == OrganizerClientType.Spouse && client.email) {
            return (client.email != this.state.unChangedSpouseEmail);
        }
    }

    onClientIdChange = (e: any) => {
        this.setState({
            clientId: e.target.value
        })
    }

    onStateChange = (e: any, type: OrganizerClientType) => {
        switch (type) {
            case OrganizerClientType.Taxpayer:
                const taxpayerModel: OrganizerClient = { ...this.state.taxPayer };
                taxpayerModel.state = e.value;
                this.setState({
                    taxPayer: taxpayerModel
                });
                break;
            case OrganizerClientType.Spouse:
                const spouseModel: any = { ...this.state.spouse };
                spouseModel.state = e.value;
                this.setState({
                    spouse: spouseModel
                });
        }
    }

    onCountryCodeChange = (e: any, type: OrganizerClientType) => {
        switch (type) {
            case OrganizerClientType.Taxpayer:
                const taxpayerModel: OrganizerClient = { ...this.state.taxPayer };
                taxpayerModel.countryCode = e ? e.value : "";
                this.setState({
                    taxPayer: taxpayerModel
                });
                break;
            case OrganizerClientType.Spouse:
                const spouseModel: any = { ...this.state.spouse };
                spouseModel.countryCode = e ? e.value : "";
                this.setState({
                    spouse: spouseModel
                });
        }
    }

    onMobileNumberChange = (value: any, type: OrganizerClientType) => {
        switch (type) {
            case OrganizerClientType.Taxpayer:
                const taxpayerModel: OrganizerClient = { ...this.state.taxPayer };
                taxpayerModel.mobileNumber = value;
                this.setState({
                    taxPayer: taxpayerModel
                });
                break;
            case OrganizerClientType.Spouse:
                const spouseModel: any = { ...this.state.spouse };
                spouseModel.mobileNumber = value;
                this.setState({
                    spouse: spouseModel
                });
        }
    }

    ValidateSecondSigner = (newMail: string, Unchanged: string) => {
        let saveConfirmPopover: boolean = false;

        if (newMail == "") {
            saveConfirmPopover = false;
        }
        else if (newMail != Unchanged) {
            saveConfirmPopover = true;
        }

        if (!this.state.saveConfirmPopover && saveConfirmPopover) {
            this.setState({ saveConfirmPopover: true })
        }

        return !this.state.saveConfirmPopover && saveConfirmPopover;
    }

    ValidateFirstSigner = (newMail: string, Unchanged: string) => {
        if (newMail != Unchanged) {
            this.setState({ saveConfirmPopover: true })
        }

        return newMail !== Unchanged;
    }

    getClientType = () => {
        let clientType: OrganizerClientType = OrganizerClientType.Taxpayer;
        if (this.props.model != undefined && this.props.model.length > 0) {
            if (this.props.status === EngagementLetterStatus.AwaitingSignature) {
                const client = this.props.model.filter(x => x.signingOrder === 1)[0];
                clientType = client.clientType;
            }
            else if (this.props.status === EngagementLetterStatus.PartiallySigned) {
                const client = this.props.model.filter(x => x.signingOrder === 2)[0];
                clientType = client.clientType;
            }
        }
        return clientType;
    }

    onLocationChange = (e: any) => {
        this.props.model[0].locationId = e ? e.value : 0;
        this.setState({
            locationId: this.props.model[0].locationId
        })

        const taxpayerModel: OrganizerClient = { ...this.state.taxPayer };
        taxpayerModel.locationId = this.props.model[0].locationId;
        this.setState({
            taxPayer: taxpayerModel
        });

        this.props.onLocationChange(this.props.model[0].locationId);
    }

    getOfficeLocations = (locations: ILocations[]) => {

        let locationList: IDropdown[] = [];

        locations?.forEach((location: ILocations) => {
            const locationVal: IDropdown = {
                label: location.name,
                value: location.locationId
            }

            locationList.push(locationVal);
        })

        this.setState({ locationsState: locationList })
    }

    private getLocationName = () => {

        let selectedLocationId = this.state.locationId;

        const index = this.state.locationsState.findIndex(
            (x) => x.value == selectedLocationId
        );

        if (index != -1) {
            return this.state.locationsState[index].label;
        }

        return "";
    };

    private onCloseLocationMismatchActionModal = () => {
        this.setState({ loading: false, showLocationMismatchActionModel: false });
        this.props.onCloseLocationMismatchActionModal();
    };

    private onContinueLocationMismatchActionModal = () => {
        const { clientId, taxPayer, spouse } = this.state;
        const clients = [taxPayer, spouse];

        this.setState({ loading: true, showLocationMismatchActionModel: false });
        ShowLoader();
        this.props.onContinueLocationMismatchActionModal(clients, clientId, this.state.saveChangedEmail, this.isEmailupdateRequired(clients[0]), (clients.length > 1 ? this.isEmailupdateRequired(clients[1]) : false), this.props.dcoumentId);
        this.props.onClose();
    };

    onClientManagementComparisonFailed = () => {
        this.props.onClientManagementComparisonFailed();
    }

    public render() {
        const { taxPayer, clientId, spouse, unChangedTpEmail } = this.state;
        const { show, onClose, readOnly, states, loading, unDeliveredReport } = this.props;
        const popover = (
            <Popover id="popver-to-email-update-confirm" className="popovereditinfo" >
                <Popover.Title as="h3" style={{ fontSize: '14px' }}>Confirmation</Popover.Title>
                <Popover.Content>
                    {emailUpdatedConfirmation}
                    <div className="popover-footer">
                        <button
                            className="btn btn-only btn-success"
                            onClick={() => this.onEmailChange(true)}
                            title="OK"
                            style={{ marginLeft: "5px" }}>
                            Yes
                        </button>
                        <button
                            onClick={() => this.onEmailChange(false)}
                            title="Cancel"
                            className="btn btn-only btn-danger"
                            style={{ marginLeft: "5px" }}>
                            No
                        </button>
                    </div>
                </Popover.Content>
            </Popover>
        );
        return <div>
            <Modal show={show} onHide={onClose} className="edit-client-info-modal">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span className='modalIcon text-secondary fa fa-address-card'>
                        </span>{readOnly ? "View Client Info" : "Edit Client Info"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LoadingOverlay style={{ height: '100%' }}>
                        {
                            taxPayer.clientGuid && <OrganizerClientDetails
                                clientId={clientId}
                                model={taxPayer}
                                onClientIdChange={this.onClientIdChange}
                                type={OrganizerClientType.Taxpayer}
                                onDOBChange={this.handleChangeDoB}
                                onFieldChange={this.onChange}
                                states={states}
                                onStateChange={this.onStateChange}
                                onCountryCodeChange={this.onCountryCodeChange}
                                onMobileNumberChange={this.onMobileNumberChange}
                                readOnly={readOnly}
                                signingorder={taxPayer.signingOrder}
                                status={this.props.status}
                                locations={this.state.locationsState}
                                locationId={this.props.model[0].locationId}
                                onLocationChangeProp={this.onLocationChange}
                                showOfficeLocation={this.props.showOfficeLocation}
                            />
                        }
                        {
                            spouse.clientGuid && <OrganizerClientDetails
                                model={spouse}
                                type={OrganizerClientType.Spouse}
                                onDOBChange={this.handleChangeDoB}
                                onFieldChange={this.onChange}
                                states={states}
                                onStateChange={this.onStateChange}
                                onCountryCodeChange={this.onCountryCodeChange}
                                onMobileNumberChange={this.onMobileNumberChange}
                                readOnly={readOnly}
                                signingorder={spouse.signingOrder}
                                status={this.props.status}
                                locations={this.state.locationsState}
                                locationId={this.props.model[0].locationId}
                                onLocationChangeProp={this.onLocationChange}
                                showOfficeLocation={this.props.showOfficeLocation}
                            />
                        }
                        <Loader loading={loading} text={"Loading..."} />
                    </LoadingOverlay>
                </Modal.Body>
                <Modal.Footer style={{ display: 'initial' }}>
                    <span className="pull-left padT07" style={{ fontSize: '14px' }}>
                        <span className="glyphicon glyphicon-asterisk compulsory-star-only padR05"></span>
                        <span>Required</span>
                    </span>
                    {
                        !readOnly && !unDeliveredReport &&
                        <>
                            <Button
                                disabled={loading}
                                variant="info" onClick={this.onSaveClientInfo}
                                ref={(reference: Button | null) => { this.savePopoverRef = reference }}
                                style={{ float: 'right' }}
                            >
                                <i className='glyphicon glyphicon-floppy-disk'></i>Save
                            </Button>
                            <Overlay rootClose={true} placement="top"
                                onHide={() => { this.setState({ saveConfirmPopover: false }) }}
                                target={this.savePopoverRef} show={this.state.saveConfirmPopover}>
                                {popover}
                            </Overlay>

                        </>
                    }
                    {
                        !readOnly && unDeliveredReport &&
                        <>
                            <Button
                                variant='info'
                                onClick={() => this.onSave()} style={{ float: 'right' }} >
                                <i className='glyphicon glyphicon-floppy-disk  inprogress'></i>Save
                            </Button>
                        </>
                    }
                    {
                        this.state.showLocationMismatchActionModel && (
                            <LocationMismatchActionModal
                                onCancel={this.onCloseLocationMismatchActionModal}
                                onContinue={this.onContinueLocationMismatchActionModal}
                                show={this.state.showLocationMismatchActionModel}
                                locationName={this.getLocationName()}
                            />
                        )}
                    <Button
                        variant="default"
                        className="btn-white"
                        onClick={onClose} style={{ float: 'right' }}>
                        <i className="glyphicon glyphicon-remove" />Cancel
                    </Button>
                </Modal.Footer>
            </ Modal>
        </div>
    }
}

interface OrganizerClientProps {
    type: OrganizerClientType;
    model: OrganizerClient;
    onFieldChange: (e: any, type: OrganizerClientType) => void;
    onDOBChange: (value: any, type: OrganizerClientType) => void;
    clientId?: string;
    onClientIdChange?: (e: any) => void;
    states: ICountryState[];
    onStateChange: (e: any, type: OrganizerClientType) => void;
    onMobileNumberChange: (e: any, type: OrganizerClientType) => void;
    onCountryCodeChange: (e: any, type: OrganizerClientType) => void;
    readOnly?: boolean;
    signingorder?: number;
    status?: EngagementLetterStatus;
    locationId: number;
    locations: IDropdown[];
    onLocationChangeProp: (e: any) => void;
    showOfficeLocation: boolean;
}

const OrganizerClientDetails: React.FC<OrganizerClientProps> = ({ onFieldChange, clientId,
    model: { email, name, mobileNumber, countryCode, isDeceased },
    onClientIdChange, type, onCountryCodeChange, onMobileNumberChange, readOnly, signingorder, locationId, locations, onLocationChangeProp, showOfficeLocation }) => {

    const onChange = (e: any) => {
        onFieldChange(e, type);
    }

    const onChangeMobileNumber = (value: any) => {
        onMobileNumberChange(value, type);
    }

    const onChangeCountryCode = (value: any) => {
        onCountryCodeChange(value, type);
    }

    const onLocationChange = (value: any) => {
        onLocationChangeProp(value);
    }

    return <div>
        <Card>
            <Card.Header>
                <Card.Title as="h4"><strong>{OrganizerClientType[type].toString()} {isDeceased == true ? "(Deceased)" : " "}</strong></Card.Title>
            </Card.Header>
        </Card>
        <div style={{ margin: '20px' }}>    {
            type == OrganizerClientType.Taxpayer ?
                <Row>
                    <Col className="text-left padT07" sm={2} as={FormLabel} style={{ fontWeight: 'bold' }}>
                        Client ID
                        <span className="glyphicon glyphicon-asterisk compulsory-star1" />
                    </Col>
                    <Col sm={10}>
                        <FormControl
                            type="text"
                            value={clientId}
                            placeholder="Client ID"
                            style={{ marginBottom: '10px' }}
                            onChange={onClientIdChange}
                            disabled={readOnly}
                            maxLength={OrganizerClientIdConstants.ClientIdMaxLength}
                        />
                    </Col>
                </Row> : ""
        }
            <Row>
                <Col className="text-left padT07" sm={2} as={FormLabel} style={{ fontWeight: 'bold' }}>
                    Name
                    <span className="glyphicon glyphicon-asterisk compulsory-star1" />
                </Col>
                <Col sm={10}>
                    <FormControl
                        name="name"
                        type="text"
                        value={name}
                        onChange={onChange}
                        placeholder="Name"
                        style={{ marginBottom: '10px' }}
                        disabled={readOnly || isDeceased}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm={2} className="text-left padT07" as={FormLabel} style={{ fontWeight: 'bold' }}>
                    Email
                    {
                        (signingorder == 1) ? <span className="glyphicon glyphicon-asterisk compulsory-star1" /> : ""
                    }
                </Col>
                <Col sm={10}>
                    <FormControl
                        name="email"
                        type="email"
                        value={email}
                        onChange={onChange}
                        placeholder="Email"
                        style={{ marginBottom: '10px' }}
                        disabled={readOnly || isDeceased}
                    />
                </Col>
            </Row>
            {
                type == OrganizerClientType.Taxpayer && showOfficeLocation ?
                    <Row>
                        <Col className="text-left padT07" sm={2} as={FormLabel} style={{ fontWeight: 'bold' }}>
                            Office Location
                            <span className="glyphicon glyphicon-asterisk compulsory-star1" />
                        </Col>
                        <Col sm={10}>
                            <Select
                                name="officeLocation"
                                value={locationId}
                                onChange={onLocationChange}
                                options={locations}
                                style={{ marginBottom: '10px', fontSize: '14px' }}
                                clearable={true}
                                disabled={readOnly || isDeceased}
                            >
                            </Select>
                        </Col>
                    </Row> : ""
            }

            <Row>
                <Col className="text-left padT07" sm={2} as={FormLabel} style={{ fontWeight: 'bold' }}>
                    Country Code
                </Col>
                <Col sm={4}>
                    <Select
                        name="countryCode"
                        value={countryCode}
                        onChange={onChangeCountryCode}
                        options={GetCountryCode()}
                        style={{ marginBottom: '10px', fontSize: '14px' }}
                        clearable={true}
                        disabled={readOnly || isDeceased}
                    >
                    </Select>
                </Col>
                <Col className="text-left padT07" sm={2} as={FormLabel} style={{ fontWeight: 'bold' }}>
                    Mobile Phone
                </Col>
                <Col sm={4} className={"zeroborderRadius"}>
                    <PhoneNumberComponent
                        phoneNumber={mobileNumber ? mobileNumber : ""}
                        handleChangePhoneNumber={onChangeMobileNumber}
                        disabled={readOnly ? true : false || isDeceased ? true : false}
                    >
                    </PhoneNumberComponent>
                </Col>
            </Row>
        </div>
    </div>;
}
