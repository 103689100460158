import React from 'react'
import { ReactComponent as SSOLogoWithTitle } from '../images/logo.svg';
import { ReactComponent as SSOLogo } from '../images/organizer-logo.svg';

const LoadingView: React.FC = () => (
    <div className='login-container flex-column-center-all'> 
        <SSOLogoWithTitle width={350}/>
        <div className='loading-text' data-title='dot-spin-loader'>
            <div className='dot-spin-loader'></div>
        </div>
        
    </div>
)

LoadingView.displayName = 'LoadingView';

export default LoadingView;
