import { IPartnerModel } from "src/Core/ViewModels/User/UserViewModel";

export const columnNames = {
    clientInformation: "Client Information",
    organizer: "Organizer",
    clientManagement: "Client Management"
};

export const rowNames = {
    clientId: "Client ID",
    name: "Name",
    emailAddress: "Email Address",
    isDeceased: "Deceased",
    countryCode: "Country Code",
    mobileNumber: "Mobile Number",
    spouseName: "Spouse's Name",
    spouseEmailAddress: "Spouse's Email Address",
    spouseMobileNumber: "Spouse's Mobile",
    spouseIsDeceased: "Spouse Deceased",
    spouseCountryCode: "Spouse Country Code",
    locationName: "Office",
    ero: "ERO"
};

export interface IClientInformationProps {
    clientInfoComparisonResult: IClientInfoComparisonResult;
    onClose: () => void;
    isMarriedFileJointly: boolean;
    onCancel: () => void;
    partners: IPartnerModel[];
};

export interface IClientInfoViewModel {
    clientBasicInfoId: number;
    clientId: string;
    uid: string;
    name: string;
    emailAddress: string;
    countryCode: string;
    mobileNumber: string;
    isDeceased: boolean;
    mfj: boolean;
    spouseName?: string;
    spouseEmailAddress?: string;
    spouseMobileNumber?: string;
    spouseCountryCode?: string;
    spouseIsDeceased?: boolean;
    spouseSSN?: string;
    eroLastName?: string;
    eroFirstName?: string;
    ero?: string;
    locationId?: number;
    locationName: string;
};

export const initialClientInfoViewModel: IClientInfoViewModel = {
    clientBasicInfoId: 0,
    clientId: "",
    uid: "",
    name: "",
    emailAddress: "",
    mobileNumber: "",
    countryCode: "",
    isDeceased: false,
    mfj:false,
    spouseName:  "",
    spouseEmailAddress:  "",
    spouseMobileNumber:  "",
    spouseCountryCode:  "",
    spouseIsDeceased: false,
    spouseSSN: "",
    eroLastName: "",
    eroFirstName: "",
    ero: "",
    locationId: 0,
    locationName:""
};

export enum ClientInfoMismatchType {
    NoDataMismatch = 0,
    DataMismatch = 1,
    SSNMismatch = 2,
    ClientIdMismatch = 3,
    NewClient = 4
};

export interface IComparisonMismatchViewModel {
    hadDifference: boolean;
    taxReturnValue?: string;
    clientManagementValue?: string;
};

export interface IClientInfoMismatchViewModel {
    clientId: IComparisonMismatchViewModel;
    name: IComparisonMismatchViewModel;
    emailAddress?: IComparisonMismatchViewModel;
    isDeceased?: IComparisonMismatchViewModel;
    countryCode?: IComparisonMismatchViewModel;
    mobileNumber?: IComparisonMismatchViewModel;
    spouseName?: IComparisonMismatchViewModel;
    spouseEmailAddress?: IComparisonMismatchViewModel;
    spouseMobileNumber?: IComparisonMismatchViewModel;
    spouseIsDeceased?: IComparisonMismatchViewModel;
    spouseCountryCode?: IComparisonMismatchViewModel;
    locationName?: IComparisonMismatchViewModel;
    ero?: IComparisonMismatchViewModel;    
};

export interface IClientInfoComparisonResult {
    comparisonStatus: ClientInfoMismatchType;
    clientInfoResultData: IClientInfoMismatchViewModel;
};

export const initialClientInfoMismatchViewModel: IClientInfoMismatchViewModel = {
    clientId: {
        hadDifference: false
    },
    name: {
        hadDifference: false
    },
    emailAddress: {
        hadDifference: false
    },
    isDeceased: {
        hadDifference: false
    },
    mobileNumber: {
        hadDifference: false
    },
    countryCode: {
        hadDifference: false
    },
    spouseName: {
        hadDifference: false
    },
    spouseEmailAddress: {
        hadDifference: false
    },
    spouseMobileNumber: {
        hadDifference: false
    },
    spouseCountryCode: {
        hadDifference: false
    },
    spouseIsDeceased: {
        hadDifference: false
    },
    locationName: {
        hadDifference: false
    },
    ero: {
        hadDifference: false
    }    
};

export const initialClientInfoComparisonResult: IClientInfoComparisonResult = {
    comparisonStatus: ClientInfoMismatchType.NewClient,
    clientInfoResultData: initialClientInfoMismatchViewModel
};

export enum ClientInfoProperty {
    Name,
    EmailAddress,
    IsDeceased,
    CountryCode,
    MobileNumber,
    SpouseName,
    SpouseEmailAddress,
    SpouseIsDeceased,
    SpouseCountryCode,
    SpouseMobileNumber,
    LocationId,
    EROId
}

export interface IPrefilledClientInfoDetails {
    [key: string]: boolean;
}

export const mismatchDataPopupOrder = ['clientId',
'name',
'locationName',
'ero',
'emailAddress',
'uid',
'countryCode',
'mobileNumber',
'isDeceased',
'dob',
'spouseName',
'spouseSSN',
'spouseEmailAddress',
'spouseCountryCode',
'spouseMobileNumber',
'spouseDOB',
'spouseIsDeceased'];