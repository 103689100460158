import * as React from 'react';
import * as ReactDom from 'react-dom';
import { Link } from 'react-router-dom';
import {
    BootstrapTable, TableHeaderColumn, TextFilter, CustomFilter, SelectFilter,
    CustomSelectProps, ToolBarProps
} from 'react-bootstrap-table';
import "react-bootstrap-table/css/react-bootstrap-table.css";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { EngagementLetterStatus, EngagementLetterStatusList, EngagementLetterStatusOptionsList, OrganizerStatus, OrganizerStatusList, OrganizerStatusOptionsList, RecycledOrganizerDocument, SourceDocumentStatus, SourceDocumentStatusList } from '../../../models/OrganizerComponentModels';
import moment from 'moment';
import { CheckBoxComponent, CheckBoxSize } from '../../../../components/common/CheckBoxComponent';
import { formattedClientName, getOrganizersTaxYearList } from '../../Helper/OrganizerHelperFunctions';
import { CustomMultiSelect } from '../../../../components/common/MultipleSelectComponent';
import { DaysRangeOptionsList } from '../../../../components/common/TaxReturn';
import { IDropdown } from 'src/Core/ViewModels/Common/Dropdown';

interface OrganizerRecycledReportTableProps {
    documents: RecycledOrganizerDocument[],
    pageNo: number,
    pageSize: number,
    totalRows: number;
    onSortChange: any;
    onPageChange: any;
    onFilterChange: any;
    isLoading: boolean;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedRows: number[];
    allowGatheringSourceDocuments: boolean;
    loadDocuments: () => void;
    userLocationList? : IDropdown[];
}
interface OrganizerRecycledReportTableState {

}


export class OrganizerRecycledReportTable extends React.Component<OrganizerRecycledReportTableProps, OrganizerRecycledReportTableState>
{
    public customEngagementStatusMultiSelect: any;
    public customLocationNameMultiSelect: any;
    public customOrganizerStatusMultiSelect: any;
    public customSourceStatusMultiSelect: any;

    public filterChanged = false;
    public isAppliedFilter = false;
    public customTaxYearMultiSelect: any;
    public taxYearList: any;

    componentWillMount() {
        this.taxYearList = getOrganizersTaxYearList();
    }
    renderShowTotal = (start: number, to: number, total: number) => {
        return (
            <p style={{ fontSize: '14px' }}>
                Showing {start} to {to} of {total} entries
            </p>
        )
    }
    onFilterChange = (dataField: any) => {
        if (!this.filterChanged && !this.isAppliedFilter) {
            this.filterChanged = true;
            this.props.onFilterChange(dataField);
            this.filterChanged = false;
        }
    }

    setNoContent = () => {
        if (this.props.isLoading) {
            return (<LoadingOverlay style={{ height: '400px' }}>
                <Loader loading={this.props.isLoading} />
            </LoadingOverlay>)
        } else {
            return "No reports found"
        }
    }

    createCustomCheckbox = (props: CustomSelectProps): any => {
        return (<CheckBoxComponent size={CheckBoxSize.sm}
            id={"recycle-reports-checkbox-" + props.rowIndex}
            indeterminate={props.indeterminate}
            checked={props.checked}
            disabled={props.disabled}
            onChange={(e: any) => props.onChange(e, props.rowIndex)}
            ref={(input: any) => {
                if (input) {
                    input.indeterminate = props.indeterminate;
                }
            }}
            text={""} />);
    }

    defaultFormatter = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis">{cell}</span>
    }
    taxPayerNameFormatter = (cell: any, row: any) => {
        const clientName: any = row.taxpayerName
        return <span title={formattedClientName(clientName)} className="ellipsis">{formattedClientName(clientName)}</span>;
    }
    batchNameFormatter = (cell: any, row: any) => {
        const batchName: any = row.batchName;
        if (!batchName) {
            return <span title={'-'} className="ellipsis">-</span>
        }
        return <span title={batchName} className="ellipsis">{batchName}</span>;
    }

    getTaxYearMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customTaxYearMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }

    getLocationNameMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customLocationNameMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder}  optionsModal={true} />
        );
    }

    engagementStatusFormatter = (cell: any, row: any) => {
        const status: any = row.engagementLetterStatus;
        const statusType =
            (typeof status == "number") ? EngagementLetterStatus[status] : status;
        if (!statusType) {
            return <span title={'N/A'} className="ellipsis">N/A</span>
        }
        return <span title={EngagementLetterStatusList[statusType].toUpperCase()} className="ellipsis">{EngagementLetterStatusList[statusType].toUpperCase()}</span>;
    }
    getEngagementStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customEngagementStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }
    organizerStatusFormatter = (cell: any, row: any) => {
        const status: any = row.organizerStatus;
        const organizer = this.props.documents[row.rowIndex];
        const statusType =
            (typeof status == "number") ? OrganizerStatus[status] : status;
        if (!statusType) {
            return <span title={'None'} className="ellipsis">None</span>
        }
        if ((organizer.isTPOrganizerMailDropped || organizer.isSpouseOrganizerMailDropped) && organizer.organizerStatus != OrganizerStatus.ClosedByFirm 
            && organizer.signerCount == 2) { // Multiple signers with Partially Dropped or completely Dropped status
            if (!organizer.taxpayerEmail || !organizer.spouseEmail) { //If 2 signers with one is having dropped status and other is doesn't have any email id
                return <Link to={"#"} >{OrganizerStatusList[OrganizerStatus[OrganizerStatus.Dropped]].toUpperCase()}</Link>;
            }
            else {
                return <Link to={"#"}>{OrganizerStatusList[statusType].toUpperCase()}</Link>;
            }
        }

        return <span title={OrganizerStatusList[statusType].toUpperCase()} className="ellipsis">{OrganizerStatusList[statusType].toUpperCase()}</span>;
    }

    sourceDocumentStatus = (rowIndex: number, statusType: any) => {
        return <span>{SourceDocumentStatusList[statusType].toUpperCase()}</span>;
    }
    sourceStatusFormatter = (cell: any, row: any) => {
        const status: any = row.sourceDocumentStatus;
        const statusType = (typeof status == "number") ? SourceDocumentStatus[status] : status;
        if (statusType) {
            if (statusType === SourceDocumentStatus[SourceDocumentStatus.Uploaded] || statusType === SourceDocumentStatus[SourceDocumentStatus.Completed]) {
                const organizer = this.props.documents[row.rowIndex];
                if (organizer.downloadedSourceDocumentsCount > 0) {
                    return this.sourceDocumentStatus(row.rowIndex, statusType);
                }
            }
            else if (statusType === SourceDocumentStatus[SourceDocumentStatus.AwaitingUpload] && !this.props.allowGatheringSourceDocuments) {
                return <span title={"N/A"} className="ellipsis">{"N/A"}</span>;
            }
            return this.sourceDocumentStatus(row.rowIndex, statusType);
        }

        return <span title={''} className="ellipsis"></span>
    }
    getOrganizerStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = customFilterParameters.options;
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customOrganizerStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }
    getSourceDocumentStatusList = () => {
        const { allowGatheringSourceDocuments } = this.props;
        return [
            { label: "AWAITING UPLOAD", value: 1 },
            { label: "UPLOADED", value: 2 },
            { label: "DOWNLOADED", value: 3 },
            { label: "FINISHED", value: 4 }
        ].map(item => {
            if (item.value === 1 && allowGatheringSourceDocuments === false) {
                return { ...item, label: "N/A" }
            } else if (item.value === 1 && allowGatheringSourceDocuments) {
                return { ...item, label: "AWAITING UPLOAD" }
            }
            else
                return { ...item };
        });
    }
    getSourceStatusMultiSelectDropDown = (filterHandler: any, customFilterParameters: any) => {
        const options = this.getSourceDocumentStatusList();
        const enableAllFilter = customFilterParameters.enableAllFilter;
        const placeholder = customFilterParameters.placeholder;
        return (
            <CustomMultiSelect onRef={(ref: any) => (this.customSourceStatusMultiSelect = ref)} filterHandler={filterHandler} options={options}
                enableAllFilter={enableAllFilter} placeholder={placeholder} />
        );
    }
    onPageChange = (page: number) => {

        this.setState({
            page: page,
            selectedRows: [],
            showBulkSelectionMessage: false
        }, this.loadDocuments);
    }

    loadDocuments = () => {
        this.props.loadDocuments();
    }
    private getColumns = (): any[] => {
        return [
            {
                header: '',
                key: 'index',
                isKey: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                dataSort: true,
                toolTip: false,
                isHidden: true,
                filter: { type: 'TextFilter', placeholder: 'index', style: { font: 'bold' } } as TextFilter

            },
            {
                header: 'Client Name',
                key: 'taxpayerName',
                isKey: false,
                dataFormat: this.taxPayerNameFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Client Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Client ID',
                key: 'clientId',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Client Id', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Tax Year',
                key: 'taxYear',
                isKey: false,
                dataSort: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getTaxYearMultiSelectDropDown, customFilterParameters: { options: this.taxYearList, enableAllFilter: true, placeholder: 'Select Tax Year...' } as any
                } as CustomFilter
            },
            {
                header: 'ERO / Signer',
                key: 'ero',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'ERO / Signer', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Office Location',
                key: 'locationName',
                isKey: false,
                dataSort: true,
                dataFormat: this.defaultFormatter,
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                filter: {
                    type: 'CustomFilter', getElement: this.getLocationNameMultiSelectDropDown,
                    customFilterParameters: { options: this.props.userLocationList, enableAllFilter: true, placeholder: 'Select Office Location...' } as any
                } as CustomFilter
            },
            {
                header: 'Batch Name',
                key: 'batchName',
                isKey: false,
                dataFormat: this.batchNameFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Batch Name', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Sent By',
                key: 'sentBy',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Sent By', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Delivered Date',
                key: 'deliveredOn',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'SelectFilter', placeholder: 'Select Delivered Date Limit', options: DaysRangeOptionsList } as SelectFilter
            },
            {
                header: 'Signature Status',
                key: 'engagementLetterStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.engagementStatusFormatter,
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: '140px',
                filter: {
                    type: 'CustomFilter', getElement: this.getEngagementStatusMultiSelectDropDown,
                    customFilterParameters: { options: EngagementLetterStatusOptionsList, enableAllFilter: true, placeholder: 'Select Engagement Letter Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Organizer Status',
                key: 'organizerStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.organizerStatusFormatter,
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: '130px',
                filter: {
                    type: 'CustomFilter', getElement: this.getOrganizerStatusMultiSelectDropDown,
                    customFilterParameters: { options: OrganizerStatusOptionsList, enableAllFilter: true, placeholder: 'Select Organizer Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Source Document Status',
                key: 'sourceDocumentStatus',
                isKey: false,
                dataSort: true,
                dataFormat: this.sourceStatusFormatter,
                columnClassName: '',
                toolTip: false,
                isHidden: false,
                width: '170px',
                filter: {
                    type: 'CustomFilter', getElement: this.getSourceStatusMultiSelectDropDown,
                    customFilterParameters: { enableAllFilter: true, placeholder: 'Select Document Upload Status...' } as any
                } as CustomFilter
            },
            {
                header: 'Deleted By',
                key: 'recycledUser',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'TextFilter', placeholder: 'Deleted By', style: { font: 'bold' } } as TextFilter
            },
            {
                header: 'Deletion Date',
                key: 'recycledOn',
                isKey: false,
                dataFormat: this.defaultFormatter,
                dataSort: true,
                isHidden: false,
                toolTip: false,
                columnClassName: '',
                filter: { type: 'SelectFilter', placeholder: 'Select Deletion Date Limit', options: DaysRangeOptionsList } as SelectFilter
            }
        ];
    }

    render() {

        const {
            documents,
            pageNo,
            pageSize,
            totalRows,
            onSortChange,
            onPageChange,
            onRowSelect,
            onSelectAll,
            selectedRows } = this.props;

        //const { } = this.state;

        const pagination = true;
        const searchEnable = false;

        let selected: number[] = [];
        if (selectedRows.length > 0) {
            for (var i in selectedRows) {
                let rowIndex = selectedRows[i];
                selected.push(((pageNo - 1) * pageSize) + rowIndex);
            }
        }
        const Columns = this.getColumns();
        let selectRowProp: any = {
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: onRowSelect,
            onSelectAll: onSelectAll,
            selected: selected,
            customComponent: this.createCustomCheckbox,
            className: 'row-selected'
        };

        const data: any[] = documents.map((document, index) => {
            return {
                rowIndex: index,
                index: ((pageNo - 1) * pageSize) + index,
                taxpayerName: document.taxpayerName,
                clientId: document.clientId,
                taxYear: document.taxYear,
                ero: document.eroUserName,
                locationName: document.locationName,
                batchName: document.batchName,
                sentBy: document.sentByUserName,
                deliveredOn: document.deliveredOn ? moment(document.deliveredOn).format('MM/DD/YYYY') : '',
                documentStatus: document.processStatus,/*Signature status*/
                organizerStatus: document.organizerStatus,
                sourceDocumentStatus: document.sourceDocumentStatus,
                id: document.id,
                recycledUser: document.recycledByUserName,
                recycledOn: document.recycledDate ? moment(document.recycledDate).format('MM/DD/YYYY') : '',
                engagementLetterStatus: document.engagementLetterStatus,
            }
        });


        const options = {
            onSortChange: onSortChange,
            onPageChange: onPageChange,
            sizePerPage: pageSize,
            page: pageNo,
            paginationShowsTotal: this.renderShowTotal,
            onFilterChange: this.onFilterChange,
            noDataText: this.setNoContent(),
        };

        return <div className="pos-relative">
            <div>
                <BootstrapTable
                    ref='recycledOrganizerTable'
                    data={data}
                    remote={true}
                    fetchInfo={{ dataTotalSize: totalRows }}
                    options={options}
                    striped
                    hover={true}
                    pagination={pagination}
                    selectRow={selectRowProp}
                    containerStyle={{ width: 'fit-content' }}
                    search={searchEnable}>
                    {Columns.map((value, index) => {
                        return <TableHeaderColumn
                            key={index}
                            ref={value.key}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            filter={value.filter}
                            dataSort={value.dataSort}>
                            <div title={value.header} className="header-div">
                                {value.header}
                            </div>
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div >
        </div >
    }
}
