import * as React from "react";
import { Row } from "reactstrap";
import Select from "react-select";
import { GeneralSettingConstants } from "../../../../components/helper/Constants";
import { getDaysOptions } from '../../../../components/common/utils';

interface AutomaticRemaindersProps {
    generalSettings: any,
    updateSettings(key: string, value: any): void
}

const AutomaticRemainders: React.FC<AutomaticRemaindersProps> = ({ generalSettings, updateSettings }: any) => {

    const daysOpions = getDaysOptions();

    return (
        <>
            <h3 className="text-primary">{GeneralSettingConstants.automaticRemaindersHeading}</h3>
            <Row className="general-settings-row">
                <input
                    type="checkbox"
                    className={`general-settings-checkbox ${generalSettings.enableAutoReminder ? ' checked' : ''}`}
                    checked={generalSettings?.enableAutoReminder}
                    onChange={(e) => updateSettings("enableAutoReminder", e.target.checked)}
                />
                <div>{GeneralSettingConstants.automaticRemaindersCheckbox}</div>
            </Row>
            <table className="automatic-remainder-table">
                <tbody>
                    <tr style={{ fontWeight: 700 }}>Signing</tr>
                    <tr>
                        <td>Send a reminder every</td>
                        <td>
                            <Select
                                options={daysOpions}
                                value={generalSettings?.esignReminderDays}
                                onChange={(e: any) => updateSettings("esignReminderDays", e.value)}
                                clearable={false}
                                searchable={false}
                                disabled={!generalSettings?.enableAutoReminder}
                                style={{ width: '96px' }}
                            />
                        </td>
                        <td>since last reminder / initial mail</td>
                    </tr>
                    <br />
                    <tr style={{ fontWeight: 700 }}>Organizers</tr>
                    <tr>
                        <td>Send a reminder every</td>
                        <td>
                            <Select
                                options={daysOpions}
                                value={generalSettings?.orgReminderDays}
                                onChange={(e: any) => updateSettings("orgReminderDays", e.value)}
                                clearable={false}
                                searchable={false}
                                disabled={!generalSettings?.enableAutoReminder}
                            />
                        </td>
                        <td>since last reminder / initial mail</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};
export default AutomaticRemainders;
