import * as React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';
import { DeliveredReturnsConstants } from '../../../../components/helper/Constants';

interface IColumnSettingsProps {
    showCustomColumn: boolean;
    showLoader: boolean;
    getListItem: () => any;
    onDragStart: (event: any, listIndex: any) => void;
    onDropped: (event: any, listIndex: any) => void;
    activateOrDeactivateColumn: (event: any, name: string) => void;
    onReset: () => void;
    OnSaveCustomColumns: () => void;
}

export const ColumnSettings = (props: IColumnSettingsProps) => {

    return (
        <Modal show={props.showCustomColumn} onHide={props.onReset} className="columnsettings-modal">
            <Modal.Header closeButton>
                <Modal.Title>
                    {DeliveredReturnsConstants.ColumnSelectorMessage.ModalHeader}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto', overflowX: 'hidden' }}>
                <LoadingOverlay style={{ minHeight: 100 }}>
                    <ul className="moveable">
                        <Row className="justify-content-md-center">
                            <Col id="item-list" xs lg="9">
                                {props.getListItem()}
                            </Col>
                        </Row>
                    </ul>
                    <Loader loading={props.showLoader} text={DeliveredReturnsConstants.ColumnSelectorMessage.SaveColumnSettingsLoader} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.OnSaveCustomColumns}
                    variant="info"
                    data-test-auto="BC7DCC23-8799-4DD0-BDC6-30597C7804F1">
                    <i className='fas fa-check'></i>Ok
                </Button>
                <Button onClick={props.onReset}
                    variant='default'
                    className="btn btn-white"
                    data-test-auto="BC3C6FF2-75C2-45AD-8552-8915E4C8EDF0">
                    <i className='fas fa-times'></i>Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
