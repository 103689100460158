import * as React from 'react';

interface IToggleSwitchProps {
    switched?: boolean;
    handleChange(event: any): void;
    title?: string;
    disabled?: boolean;
    size: string;
    className?:string
}


const ToggleSwitch: React.FC<IToggleSwitchProps> = (props) => {
    const lblClass = `custom-toggle-switch dis-inline ${props.size === 'S' ? 'custom-toggle-switch-small' : ''} ${props.className}`;
    const handleClass = `custom-toggle-switch-handle ${props.size === 'S' ? 'custom-toggle-switch-handle-small' : ''}`;

    return (
        <label className={lblClass} title={props.title}>
            <input className="custom-toggle-switch-input" type="checkbox" disabled={props.disabled} onChange={props.handleChange} checked={props.switched} />
            <span className="custom-toggle-switch-label" data-on="On" data-off="Off"></span>
            <span className={handleClass}></span>
        </label>
    );
}

ToggleSwitch.defaultProps = {
    size: 'L'
}

export default ToggleSwitch;
