import React from 'react';
import { useState } from 'react';
import { Button, Modal } from "react-bootstrap";
import { Loader, LoadingOverlay } from 'react-overlay-loader';
import { IUserModel } from "../../../Core/ViewModels/User/UserViewModel";
import * as UserSettingStore from '../../../store/UserManagement/UserSettingStore';
import MyDefaults from "./MyDefaults";
import { IModalProps } from "./ProfileObjects";
import { DownloadType } from "../../../Organizer/models/OrganizerComponentModels";

export interface IUserDefaultSettingsStates {
    defaultSettings: IDefaultSettings;
    useSignatureStamp: boolean;
    userId: number;
    defaultOrganizerSettings: IDefaultOrganizerSettings;
    defaultUserSettings: IUserDefaultSettings;
    senderType : string;
}

export interface DefaultSenderInfo {
    senderType: DefaultSenderInfoType;
    senderId: number;
}

export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
}

export interface IDefaultSettings {
    manualAddressId: number;
    eroUser: number;
    contactPerson: number;
    sender: DefaultSenderInfo;
    notifyUser: number;
    reportFilter: string;
    enableLastLogin: boolean;
    download: {
        downloadType: DownloadType;
        rememberDefaultDownload:boolean;
    }
}

export interface IUserDefaultSettings {
    eroUser: number;
    contactPerson: number;
    sender: DefaultSenderInfo;
    notifyELEventsUser: number;
    notifyOrganizeEventsUser: number;
    notifySourceDocEventsUser: number;
    download: {
        downloadType: DownloadType;
        rememberDefaultDownload:boolean;
    }
}

const initalUserSettings: IUserDefaultSettings = {
    contactPerson: 0,
    eroUser: 0,
    notifyELEventsUser: 0,
    notifyOrganizeEventsUser: 0,
    notifySourceDocEventsUser: 0,
    download: {
        downloadType:DownloadType.PromptMeEveryTime,
        rememberDefaultDownload:false
    },
    sender: {
        senderId: 0,
        senderType: DefaultSenderInfoType.None
    }
}

export interface IDefaultOrganizerSettings {
    enableCustomQuestionWarning: boolean;
}

export interface DefaultMailingAddress {
    id: number;
    title: string;
}

export interface ISignatureDelegation {
    userId: number;
    delegate: number[];
}

export enum DefaultDownloadInfoType {
    DownloadNow = "DownloadNow",
    DownloadToMyDownloads = "DownloadToMyDownloads",
    PromptMeEveryTime = "PromptMeEveryTime"
}

interface MySettingsProps extends IModalProps {
    users: IUserModel[];
    my: UserSettingStore.UserSettings;
    verifyLoading: boolean;
    loggedinUser: number;
    saveUserDefaultSettings: (userId: number, settings: IUserDefaultSettings, callback?: () => void) => void;
    eroList: any;
    updateUsersCallback:()=>void;
}
const MySettings: React.FC<MySettingsProps> = ({ my, onHide, updateUsersCallback,showState, verifyLoading, users, loggedinUser, saveUserDefaultSettings, eroList }: MySettingsProps) => {
    const [saving, setSave] = useState(false);
    const [settings, setSettings] = useState(initalUserSettings);

    React.useEffect(() => {
        if (my.settings.defaultUserSettings) {
            setSettings(my.settings.defaultUserSettings);
        }
    }, [my.settings.defaultUserSettings, showState])

    const onChangeEROStamp = (event: any) => {
        let newSettings = { ...settings };
        newSettings.eroUser = event;
        setSettings(newSettings);
    }

    const onChangeContactPerson = (event: any) => {
        let newSettings = { ...settings };
        newSettings.contactPerson = event;
        setSettings(newSettings);
    }

    const onChangeDefaultSender = (event: any) => {
        let newSettings = { ...settings };
        my.settings.senderType = newSettings.sender.senderType;
        if (event.currentTarget.defaultValue === "company") {
            newSettings.sender.senderType = DefaultSenderInfoType.Company;
        }
        else {
            newSettings.sender.senderType = DefaultSenderInfoType.User
        }
        setSettings(newSettings);
    }

    const onChangeNotifySigningEvents = (event: any) => {
        let newSettings = { ...settings };
        newSettings.notifyELEventsUser = event;
        setSettings(newSettings);
    }

    const onChangeNotifyOrganizerEvents = (event: any) => {
        let newSettings = { ...settings };
        newSettings.notifyOrganizeEventsUser = event;
        setSettings(newSettings);
    }

    const onChangeNotifySourceDocEvents = (event: any) => {
        let newSettings = { ...settings };
        newSettings.notifySourceDocEventsUser = event;
        setSettings(newSettings);
    }

    const onSaveSettings = () => {
        setSave(true);
        let newSettings = { ...settings };
        if (newSettings.sender.senderType === DefaultSenderInfoType.Company.toString())
            newSettings.sender.senderId = 0;

        setSettings(newSettings);
        saveUserDefaultSettings(loggedinUser, settings, onSaveSettingsComplete);
    }

    const onSaveSettingsComplete = () => {
        if (!my.isLoading) {
            setSave(false);
            onHide();
            updateUsersCallback();
            my.settings.senderType = settings.sender.senderType;
        }
    }

    const onChangeSenderName = (event: any) => {
        let newSettings = { ...settings };
        newSettings.sender = {
            senderId: event,
            senderType: DefaultSenderInfoType.User
        }
        setSettings(newSettings);
    }

    const onChangeDefaultDownload = (event:any) => {
        let newSettings = { ...settings };
        newSettings.download = {
            downloadType:event,
            rememberDefaultDownload:event == DownloadType.PromptMeEveryTime ? false : true,
        }
        setSettings(newSettings);
    }

    const onCancel = () => {
        let newSettings = {...settings};
        const isSenderTypeUndefined = my.settings.senderType == undefined;
        if(!isSenderTypeUndefined && newSettings.sender.senderType !== my.settings.senderType)
        {
            if(my.settings.senderType === DefaultSenderInfoType.Company) 
            {
                my.settings.defaultUserSettings.sender.senderType = DefaultSenderInfoType.Company;
                newSettings.sender.senderType = DefaultSenderInfoType.Company;
            }
            else if(my.settings.senderType === DefaultSenderInfoType.User)
            {
                newSettings.sender.senderType = DefaultSenderInfoType.User;
            }
            else
            {
                newSettings.sender.senderType = DefaultSenderInfoType.None;
            }
        }
        setSettings(newSettings);
        onHide();
    }
   
    return (
        <Modal show={showState} onHide={onCancel} className="my-settings" size="lg" >
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className='fa fa-cog' style={{ color: 'grey', marginRight: '5px' }}></span>
                    My Settings
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '74vh', overflowY: 'auto' }}>
                <LoadingOverlay style={{ height: '100%' }}>
                    <MyDefaults
                        defaultSettings={settings}
                        onHide={onHide}
                        showState={showState}
                        verifyLoading={verifyLoading}
                        users={users}
                        loggedinUser={loggedinUser}
                        onChangeEROStamp={onChangeEROStamp}
                        onChangeContactPerson={onChangeContactPerson}
                        onChangeSender={onChangeDefaultSender}
                        onChangeNotifyOrganizerEvents={onChangeNotifyOrganizerEvents}
                        onChangeNotifySigningEvents={onChangeNotifySigningEvents}
                        onChangeNotifySourceDocEvents={onChangeNotifySourceDocEvents}
                        onChangeSenderName={onChangeSenderName}
                        onChangeDefaultDownload={onChangeDefaultDownload}
                        eroList={eroList}
                    />
                    <Loader loading={saving} text={"Loading..."} />
                </LoadingOverlay>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='default'
                    onClick={onCancel}
                    className="btn-white"
                    data-test-auto="4A0C3490-369E-49E9-A36A-3139973D5A81"
                    style={{ float: 'right' }}>
                    <i className='glyphicon glyphicon-remove'></i>Cancel
                </Button>
                <Button
                    variant='info'
                    onClick={onSaveSettings}
                    data-test-auto="3CDE45EA-64A3-49F4-B6F4-A122088BA80B"
                    style={{ float: 'right' }}>
                    <i className='glyphicon glyphicon-floppy-disk'></i>  Save
                </Button> 
            </Modal.Footer>
        </Modal>)
}

export default MySettings;

