import { IDownloadedZipFilesModel } from '../../../components/navigation/profile/MyDownload';
export interface ICountryState {
    id: number;
    name: string;
    image: string;
}

export interface ICompanyInfo {
    companyName: string;
    adminUser: number;
    isSsrLogo: boolean;
    id: number;
};


export interface ILocations {
    locationId: number;
    isPrimary: boolean;
    name: string;
    city: string;
    state: number;
    countryCode: string;
    address1: string;
    address2: string;
    zip: string;
    fax: string;
    phone: string;
    website: string;
}


export interface IPrimaryAdmin {
    userId: number;
    firstName: string;
    phoneNumber: string;
    extension: string;
    email: string;
    title: string;
    mobileNumber: string;
    countryCode: string;
};

export interface ICompany {
    companyInfo: ICompanyInfo;
    countryStates: ICountryState[];
    locations: ILocations[]
}

export interface IAdmin {
    adminId: number;
    adminUsers: IPrimaryAdmin[];
    currentAdmin: IPrimaryAdmin
}

export interface ICompanyLogoSetting {
    isSsrLogo: boolean;
    logoPath: string;
    updatedLogo: Blob;
    isLogoUpdated: boolean;
    isLogoChangedToMyLogo: boolean;
    isCompanyNameChanged: boolean
}

export interface IState {
    company: ICompany;
    admins: IAdmin;
    companyLogoSetting: ICompanyLogoSetting; 
    showEditCompanyPopUp: boolean;
    showEditPrimaryAdminPopup: boolean;
    showEditCompanyLogoPopup: boolean;
    alert: string;
    message: string;
    showAlert: boolean;
};


export const initialCompanyModel: ICompany = {
    companyInfo: {
        companyName: "",
        adminUser: 0,
        isSsrLogo: false,
        id: 0
    },
    countryStates: [{
        id: 0,
        name: "",
        image: ""
    }],
    locations: []
}

export const initialAdminModel: IAdmin = {
    adminId: 0,
    adminUsers: [{
        userId: 0,
        firstName: "",
        phoneNumber: "",
        extension: "",
        email: "",
        title: "",
        mobileNumber: "",
        countryCode: ""
    }],
    currentAdmin: {
        userId: 0,
        firstName: "",
        phoneNumber: "",
        extension: "",
        email: "",
        title: "",
        mobileNumber: "",
        countryCode: ""
    }
}

export const initialCompanyLogoSettings: ICompanyLogoSetting = {
    isSsrLogo: false,
    logoPath: "",
    updatedLogo: new Blob([]),
    isLogoUpdated: false,
    isLogoChangedToMyLogo: false,
    isCompanyNameChanged: false
}

export const initalMyDownloadsList: IDownloadedZipFilesModel[] = [];