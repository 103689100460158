import * as React from "react";
import { ISections } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import SectionItemPreview from "./SectionItemPreview";
import { CircleDeleteIcon } from "src/assets/SvgIconCollection";
import * as bootbox from "bootbox";
import { CustomQuestionsTabConstants } from "src/components/helper/Constants";

interface SectionPreviewProps {
    section: ISections;
    sectionsCount?: number;
    sectionIndex: number;
    resetValidations: boolean;
    isEditMode?: boolean;
    isEditable?: boolean;
    onSectionDelete?: Function;
    onQuestionDelete?: Function;
}

const SectionPreview: React.FC<SectionPreviewProps> = ({ section, sectionsCount, sectionIndex, resetValidations, isEditMode, isEditable, onSectionDelete, onQuestionDelete }) => {
    const [questionaireSection, setQuestionaireSection] = React.useState({ ...section });
    React.useEffect(() => {
        setQuestionaireSection(section);
    }, [section])

    const onSectionDeleteClick = () => {
        if (questionaireSection?.sectionItems.length > 0) {
            bootbox.confirm({
                title: CustomQuestionsTabConstants.DeleteSectionTitle,
                message: CustomQuestionsTabConstants.DeleteSectionConfirmation,
                buttons: {
                    cancel: {
                        label: '<i data-test-auto="ce75f59f-99fc-48b9-b058-068367b76996" class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i data-test-auto="1b2945ce-1cbd-4ffe-9520-6d89cd179822" class="fas fa-check"></i> Continue',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result && onSectionDelete) {
                        onSectionDelete(questionaireSection);
                    }
                }
            });

        }
    }
    const onQuestionDeleteClick = (sectionItem: any) => {
        if (questionaireSection?.sectionItems.length > 1) {
            let index: number = questionaireSection.sectionItems.indexOf(sectionItem);
            questionaireSection.sectionItems.splice(index, 1);
            if (onQuestionDelete) {
                onQuestionDelete(questionaireSection);
            }
        }
        else {
            // show confirmation popup
            bootbox.confirm({
                title: CustomQuestionsTabConstants.DeleteQuestionTitle,
                message: CustomQuestionsTabConstants.DeleteQuestionConfrimation,
                buttons: {
                    cancel: {
                        label: '<i data-test-auto="ce75f59f-99fc-48b9-b058-068367b76996" class="fas fa-times"></i> Cancel',
                        className: "btn-white btn-default"
                    },
                    confirm: {
                        label: '<i data-test-auto="1b2945ce-1cbd-4ffe-9520-6d89cd179822" class="fas fa-check"></i> Continue',
                        className: "btn-info"
                    }
                },
                callback: (result: boolean) => {
                    if (result) {
                        if (questionaireSection?.sectionItems.length === 1) {
                            if (onSectionDelete) {
                                onSectionDelete(questionaireSection);
                            }
                        }
                    }
                }
            });
        }
        setQuestionaireSection({ ...questionaireSection });
    }
    return (
        <div className="preview-section-container">
            <div className="preview-section-header">
                <p>
                    {questionaireSection.sectionName}
                </p>
                {isEditMode && <button className="section-delete-button" disabled={sectionsCount === 1 || !isEditable}
                    onClick={onSectionDeleteClick} title={sectionsCount === 1 ? CustomQuestionsTabConstants.AtleastOneSectionRequired : ""} >
                    <CircleDeleteIcon />
                </button>
                }
            </div>

            {questionaireSection &&
                questionaireSection.sectionItems.length > 0 &&
                <div className="mt-1-5">
                    {questionaireSection.sectionItems.map((sectionItem, sectionItemIndex) => (
                        <SectionItemPreview
                            sectionItem={sectionItem}
                            sectionItemIndex={sectionItemIndex}
                            sectionIndex={sectionIndex}
                            sectionItemsCount={questionaireSection.sectionItems.length}
                            resetValidations={resetValidations}
                            isEditMode={isEditMode}
                            isEditable={isEditable}
                            onQuestionDelete={onQuestionDeleteClick}
                        />
                    ))}
                </div>
            }
        </div>
    );
};

export default SectionPreview;
