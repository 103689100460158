import * as React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ReopenOrganizerGreyIcon } from 'src/assets/SvgIconCollection';

interface ReopenOrganizerModalProps {
    show: boolean,
    onCancel: () => void;
    documentId?: number,
    onReopenOrganizerModalConfirmClick: (documentId: number) => void;
}
const ReopenOrganizerModal: React.StatelessComponent<ReopenOrganizerModalProps> = ({ show, onCancel, onReopenOrganizerModalConfirmClick }) => {
    return <Modal show={show} className="reopen-organizer-modal" onHide={() => { onCancel() }}>
        <Modal.Header closeButton style={{ alignItems: 'center'}}>
            <Modal.Title>
                <ReopenOrganizerGreyIcon />
                Confirm Reopen Organizer Filling
            </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '14px'}}>
            <p>
                This Organizer has been marked as complete by the taxpayer/spouse. Do you wish to reopen the Organizer for the taxpayer/spouse?
            </p>
            <p>
                Note: Automatic Reminders will not be turned on upon reopening the organizer. However, you can manually turn it on using the send reminder option in delivered report.
            </p>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="default"
                className="btn-white"
                onClick={onCancel} >
                <i className="glyphicon glyphicon-remove" />
                Cancel
            </Button>
            <Button
                variant="info"
                className="btn-info"
                onClick={onReopenOrganizerModalConfirmClick} >
                <i className="glyphicon glyphicon-ok" />
                Confirm
            </Button>
        </Modal.Footer>
    </Modal>
}
export { ReopenOrganizerModal };
