import * as React from 'react';
import { Toaster } from 'src/components/helper/VenusNotifier';
import { LoggerFactory } from './Logger/LoggerFactory';
import OrganizerRoutes from './OrganizerRoutes';
import AppLayout from './components/AppLayout';
import LayoutWrapper from './components/LayoutWrapper'
export const logger = new LoggerFactory().getTelemetryLogger();
logger?.trackTrace("Initialize");

const Routes: React.FC<{history: any}> = ({ history }) => {
    return <>
        <AppLayout>
            <LayoutWrapper history={history}>
                <OrganizerRoutes />
                <Toaster />
            </LayoutWrapper>
        </AppLayout>
    </>
}

export default Routes;