import React from "react";
import { Form } from "react-bootstrap";
import { ISectionItems, QuestionType } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import SectionItemFooter from "./SectionItemFooter";
import { FormBuilderConstants, FormPreviewConstants } from "src/components/helper/Constants";
import { GrabIcon, MCQIcon, ParagraphIcon, YesNoQuestionIcon } from "src/assets/SvgIconCollection";
import { useDispatch } from "react-redux";
import { IOnChangeHandlerPayload } from "src/store/settings/CqPreview/ActionTypes";
import { HandleQuestionTitleOnChange, HandleQuestionTypeChange } from "src/store/settings/FormBuilder/Actions";
import { CqAutomation } from "src/components/helper/AutomationLocators";
import ParagraphQuestion from "./QuestionTypes/ParagraphQuestion";
import { IHandleQuestionTypeChangePayload } from "src/store/settings/FormBuilder/ActionTypes";
import "./SectionItem.css";
import "../SectionItem/QuestionTypes/QuestionTypes.css";
import MultiChoiceQuestion from "./QuestionTypes/MultiChoiceQuestion";
import YesNoQuestion from "./QuestionTypes/YesNoQuestion";
import QuestionTypeDropdown from "./QuestionTypeDropdown";
interface IExpandedSectionItemProps {
    sectionItem: ISectionItems;
    sectionItemIndex: number;
    sectionIndex: number;
    totalquestions: number;
}

const questionTypes = [
    { type: QuestionType.PARAGRAPH, label: "Paragraph", icon: <ParagraphIcon /> },
    { type: QuestionType.YESNO, label: "Yes/No", icon: <YesNoQuestionIcon /> },
    { type: QuestionType.MULTICHOICE, label: "Multiple Choice", icon: <MCQIcon /> }
];
const ExpandedSectionItem: React.FC<IExpandedSectionItemProps> = ({
    sectionItem,
    sectionIndex,
    sectionItemIndex,
    totalquestions,
}) => {
    const dispatch = useDispatch();
    const [questionTitle, setQuestionTitle] = React.useState(sectionItem.questionTitle);
    const [selectedQuestionType, setSelectedQuestionType] = React.useState<QuestionType>(sectionItem.questionTypeId);
    React.useEffect(() => {
        setQuestionTitle(sectionItem.questionTitle);
        setSelectedQuestionType(sectionItem.questionTypeId);
    }, [sectionItem]);
    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const input = e.target.value.trimStart();
        setQuestionTitle(input);
        const payload: IOnChangeHandlerPayload = {
            value: input,
            sectionIndex,
            sectionItemIndex
        };
        setQuestionTitle(input);
        dispatch(HandleQuestionTitleOnChange(payload));
    };

    const onQuestionTypeChange = (selectedType) => {
        setSelectedQuestionType(selectedType);
        const payload: IHandleQuestionTypeChangePayload = { sectionIndex, sectionItemIndex, questionType: selectedType };
        dispatch(HandleQuestionTypeChange(payload));
    };
    const getQuestionType = (
        questionType: QuestionType,
        sectionItem: ISectionItems,
        sectionIndex: number,
        sectionItemIndex: number
    ) => {
        switch (questionType) {
            case QuestionType.PARAGRAPH:
                return <ParagraphQuestion />;
            case QuestionType.YESNO:
                return (
                    <YesNoQuestion
                        sectionItem={sectionItem}
                        sectionItemIndex={sectionItemIndex}
                        sectionIndex={sectionIndex}
                    />
                );
            case QuestionType.MULTICHOICE:
                return (
                    <MultiChoiceQuestion
                        sectionItem={sectionItem}
                        sectionItemIndex={sectionItemIndex}
                        sectionIndex={sectionIndex}
                    />
                );

            default:
                break;
        }
    };

    return (
        <main className="section-item-container">
            <div className="reorder-icon-wrapper">
                <GrabIcon />
            </div>
            <div className={`question-container ${selectedQuestionType === QuestionType.YESNO ? "margin-bottom" : ""}`}>
                <div className="question-title-wrapper">
                    <span>{sectionItemIndex + 1}.</span>
                    <Form.Control
                        data-test-auto={CqAutomation.Section.questionTitle}
                        type="text"
                        value={questionTitle}
                        onChange={onChangeHandler}
                        placeholder={FormBuilderConstants.QuestionTitle.placeholder}
                        maxLength={FormBuilderConstants.QuestionTitle.maxLength}
                        className={sectionItem.isValid === false ? "invalid-input" : ""}
                    />
                    {sectionItem.isValid === false && <p className="error-text">{FormPreviewConstants.RequiredValidation}</p>}
                </div>

                <QuestionTypeDropdown selectedQuestionType={selectedQuestionType} onQuestionTypeChange={onQuestionTypeChange} />
            </div>
            {getQuestionType(selectedQuestionType, sectionItem, sectionIndex, sectionItemIndex)}
            <SectionItemFooter
                sectionItem={sectionItem}
                sectionItemIndex={sectionItemIndex}
                sectionIndex={sectionIndex}
                totalquestions={totalquestions}
            />
        </main>
    );
};

export default ExpandedSectionItem;
