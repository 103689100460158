import * as React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { TemplateModalConstants } from "src/components/helper/Constants";

interface TemplateModalProps {
    show: boolean;
    onClose: (data: any) => void;
    onConfirm: (data: any) => void;
    intialModalData?: any;
    modalType?: ModalType;
}

export enum ModalType {
    Template = 1,
    Questionaire = 2,
}

export const TemplateModal: React.FC<TemplateModalProps> = ({ show, onClose, onConfirm, intialModalData, modalType }) => {
    const ref = React.useRef<HTMLFormElement>(null);
    const [modalData, setModalData] = React.useState({ ...intialModalData });
    const [validated, setValidated] = React.useState<boolean>(false);

    React.useEffect(() => {
        setModalData(intialModalData);
    }, [intialModalData]);

    const onFormChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        const modalDataCopy = { ...modalData, [id]: value.trimStart() };
        setModalData(modalDataCopy);
    };
    const handleOnSubmit = () => {
        const form = ref.current;
        if (!!form && form.checkValidity()) {
            setValidated(false);
            onConfirm(modalData);
            setModalData(null);
        }
        else {
            setValidated(true);
        }
       
    };

    const hideModalHandler = () => {
        onClose(intialModalData);
        setValidated(false);
        setModalData(null);
    };
    return (
        <Modal className="template-modal-container" show={show} onHide={hideModalHandler} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>
                    {intialModalData ? TemplateModalConstants.EditModalHeader : TemplateModalConstants.AddModalHeader}
                </Modal.Title>

            </Modal.Header>
            <Modal.Body className="bootbox-body">
                <Form
                    ref={ref}
                    noValidate
                    validated={validated}
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <Form.Group>
                        <Form.Label>{TemplateModalConstants.NameLabel}</Form.Label>
                        <Form.Control
                            type="text"
                            id="name"
                            name="name"

                            defaultValue={intialModalData?.name}
                            value={modalData?.name}
                            placeholder={modalType === ModalType.Template ? TemplateModalConstants.TemplateNamePlaceholder : TemplateModalConstants.NamePlaceholder}
                            onChange={onFormChange}
                            required
                            maxLength={TemplateModalConstants.TemplateNameMaxLength}
                        />
                        <Form.Control.Feedback type="invalid">
                            {modalType === ModalType.Template ? TemplateModalConstants.TemplateNameValidationMessage : TemplateModalConstants.NameValidationMessage}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>{TemplateModalConstants.DesciptionLabel}</Form.Label>
                        <Form.Control
                            type="text"
                            as="textarea"
                            id="description"
                            name="description"
                            defaultValue={intialModalData?.description}
                            value={modalData?.description}
                            placeholder={modalType === ModalType.Template ? TemplateModalConstants.TemplateDesciptionPlaceholder : TemplateModalConstants.DesciptionPlaceholder}
                            onChange={onFormChange}
                            maxLength={TemplateModalConstants.TemplateDescriptionMaxLength}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="default" className="btn-white" onClick={hideModalHandler}>
                    <i className="glyphicon glyphicon-remove" />
                    Cancel
                </Button>
                <Button className="btn-info" onClick={handleOnSubmit} variant="info">
                    <i className="glyphicon glyphicon-ok" />
                    {intialModalData
                        ? TemplateModalConstants.EditModalConfirmButton
                        : TemplateModalConstants.AddModalConfirmButton}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
