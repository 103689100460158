import * as React from "react";
import { Button, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ISections } from "src/Core/ViewModels/CompanySettings/CustomQuestionModel";
import { DeleteIcon, PlusIcon, VerticalEllipsisIcon } from "src/assets/SvgIconCollection";
import { FormBuilderConstants, TemplateModalConstants } from "src/components/helper/Constants";
import { generateRandomNumber, getQuestionsLimitErrorMessage, getTotalQuestions } from "src/components/helper/HelperFunctions";
import { StatusType } from "src/store/common/NotificationStore";
import { AddNewQuestionHandler, DuplicateSectionHandler } from "src/store/settings/FormBuilder/Actions";
import { actionTypes as NotificationAction } from "./../../../../../../../store/ActionTypes";
import { ApplicationState } from "src/store";
import { DeleteModal } from "src/Organizer/components/Reports/Modals/DeleteModal";
import { CqAutomation } from "src/components/helper/AutomationLocators";
import { IDupicateSectionPayload } from "src/store/settings/FormBuilder/ActionTypes";
interface SectionFooterProps {
    section: ISections;
    setSelectedSection: React.Dispatch<React.SetStateAction<ISections>>;
    handleRenameSection: () => void;
    handleMergeSection: () => void;
    handleMoveSection: () => void;
    setActiveSection: React.Dispatch<React.SetStateAction<number>>;
    sectionIndex: number;
    handleDeleteSection: (sectionIndex: number) => void;
    isExpandedView: boolean;
}

const SectionFooter: React.FC<SectionFooterProps> = ({
    section,
    sectionIndex,
    isExpandedView,
    setSelectedSection,
    handleRenameSection,
    handleMergeSection,
    handleMoveSection,
    setActiveSection,
    handleDeleteSection
}) => {
    const { formData, customQuestionSettings } = useSelector((state: ApplicationState) => state.formBuilder);
    const [deleteConfirmModal, toogleDeleteConfirmModal] = React.useState(false);
    const dispatch = useDispatch();
    const popperConfigObj = {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [15, 10]
                }
            }
        ]
    };
    const handleMoveSectionClick = () => {
        if (formData.sections?.length > 1) {
            handleMoveSection();
        }
    }
    const handleDuplicateSectionClick = () => {

        let totalSourceSectionQuestions = section.sectionItems?.length || 0;
        let totalQuestions = getTotalQuestions(formData) + totalSourceSectionQuestions;

        if (totalQuestions <= customQuestionSettings.questionLimit) {
            if (`${section.sectionName}_copy`.length > TemplateModalConstants.SectionNameMaxLength) {
                dispatch({
                    type: NotificationAction.NOTIFICATION,
                    statusMessage: FormBuilderConstants.DUPLICATE_SECTION_LIMIT,
                    statusType: StatusType.Error
                });
            } else {
                const payload: IDupicateSectionPayload = {
                    section: { ...section, sectionId: generateRandomNumber(), sectionName: `${section.sectionName}_copy` },
                    sectionIndex,
                    sectionItemIndex: 0
                };
                dispatch(DuplicateSectionHandler(payload));
            }
        }
        else {
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "duplicate section"),
                statusType: StatusType.Error
            });
        }

    }

    const handleMergeSectionClick = () => {
        if (formData.sections?.length > 1) {
            setSelectedSection(section);
            handleMergeSection();
        }
    };

    const handleRenameSectionClick = () => {
        setSelectedSection(section);
        setActiveSection(sectionIndex);
        handleRenameSection();
    };

    const onConfirmDeleteSection = () => {
        handleDeleteSection(sectionIndex);
        toogleDeleteConfirmModal(false);
    };

    const handleAddNewQuestionClick = () => {
        if (isQuestionLimitValid()) {
            dispatch(AddNewQuestionHandler(sectionIndex));
        }
    };
    const isQuestionLimitValid = () => {
        if (getTotalQuestions(formData) + 1 > customQuestionSettings.questionLimit) {
            dispatch({
                type: NotificationAction.NOTIFICATION,
                statusMessage: getQuestionsLimitErrorMessage(customQuestionSettings.questionLimit, "add question"),
                statusType: StatusType.Error
            });
            return false;
        }
        return true;
    };
    const handleDeleteSectionClick = () => {
        toogleDeleteConfirmModal(true);
    };
    return (
        <footer className={`${!isExpandedView && "collapsed-view-footer"}`}>
            {isExpandedView && section?.sectionItems.length > 0 && (
                <div className="section-buttons">
                    <Button
                        data-test-auto={CqAutomation.Section.addQuestion}
                        onClick={handleAddNewQuestionClick}
                        className="btn-with-icon add-question-btn"
                        variant="primary"
                    >
                        <PlusIcon />
                        Add New Question
                    </Button>
                    <Button
                        data-test-auto={CqAutomation.DeleteSectionWidget}
                        onClick={handleDeleteSectionClick}
                        className="btn-with-icon"
                        variant="outline-secondary"
                    >
                        <DeleteIcon />
                        Delete Section
                    </Button>
                </div>
            )}
            <Dropdown className="section-dropdown">
                <Dropdown.Toggle>
                    <VerticalEllipsisIcon />
                </Dropdown.Toggle>
                <Dropdown.Menu popperConfig={popperConfigObj} className="template-more-options-menu">
                    <Dropdown.Item
                        className={`template-menu-item${formData.sections?.length === 1 ? "-disabled" : ""}`}
                        onClick={handleMoveSectionClick}>
                        Move
                    </Dropdown.Item>
                    <Dropdown.Item className="template-menu-item" onClick={handleDuplicateSectionClick}>
                        Duplicate
                    </Dropdown.Item>
                    <Dropdown.Item
                        data-test-auto={CqAutomation.RenameSection}
                        className="template-menu-item"
                        onClick={handleRenameSectionClick}
                    >
                        Rename
                    </Dropdown.Item>
                    <Dropdown.Item
                        className={`template-menu-item${formData.sections?.length === 1 ? "-disabled" : ""}`}
                        onClick={handleMergeSectionClick}>
                        Merge
                    </Dropdown.Item>
                    <Dropdown.Item
                        data-test-auto={CqAutomation.DeleteSectionDropdown}
                        onClick={handleDeleteSectionClick}
                        className="template-menu-item"
                    >
                        Delete
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {deleteConfirmModal && (
                <DeleteModal
                    show={deleteConfirmModal}
                    modalHeader={FormBuilderConstants.DELETE_MODAL_HEADER}
                    modalBody={FormBuilderConstants.DELETE_MODAL_BODY}
                    onClose={() => toogleDeleteConfirmModal(false)}
                    onConfirm={onConfirmDeleteSection}
                    additionalConfirmButtonLabel={"Yes"}
                />
            )}
        </footer>
    );
};

export default SectionFooter;
