import { injectable } from "inversify";

export enum ValueType {
    Number = 0,
    Text = 1,
    Date = 2,
    Decimal = 3,
    None = 4
}

export interface ITextUtilities {
    getAvatarText(text: string): string;
    getRandomColor(): string;
    getFormattedText(value: any, type: ValueType): any;
}

@injectable()
export class TextUtilities implements ITextUtilities {

    getAvatarText(text: string): string {
        return text && text.split(' ').map(function (str) { return str ? str[0].toUpperCase() : ""; }).join('');
    }

    getRandomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        let seed = Date.now();
        
        // Custom pseudo-random number generator
        const customRandom = () => {
            seed = (seed * 9301 + 49297) % 233280;
            return seed / 233280;
        };
    
        for (let i = 0; i < 6; i++) {
            // Generate a random index using custom PRNG
            const randomIndex = Math.floor(customRandom() * letters.length);
            color += letters[randomIndex];
        }
        return color;
    }
    
    
    

    getFormattedText(value: any, type: ValueType): any {
        
        switch (type) {

            case ValueType.Date:

                return value.toLocaleDateString();

            case ValueType.Decimal:

                return parseFloat(value).toFixed(2);

            case ValueType.Number:

                return value;

            case ValueType.Text:

                return value;

            default:

                return value;
        }
    }
}