import * as React from 'react';
import { Modal } from 'react-bootstrap'

let FileViewer = require('react-file-viewer');

interface FilePreviewModalProps {
    url: string,
    showPDFPreview: boolean;
    onHidePDFPreview: () => void;
    fileType: string | undefined;
    fileName: string;
}

export class FilePreviewModal extends React.Component<FilePreviewModalProps, {}>{
    render() {
        return <Modal style={{ overflowY: "hidden" }} show={this.props.showPDFPreview} onHide={this.props.onHidePDFPreview}>
            <Modal.Header closeButton data-test-auto="">
                <Modal.Title>
                    <div title={this.props.fileName} className='preview-modal-header-title'>{this.props.fileName}</div>
                </Modal.Title>

            </Modal.Header>
            <Modal.Body>
                {this.props.fileType && this.props.fileType.toLowerCase() === "pdf" ? (
                    <object name={this.props.fileName} data={this.props.url} type="application/pdf"
                        height={`${window.innerHeight - 100}px`} width="100%"
                        title={this.props.fileName}>
                    </object>
                ) : (
                    <FileViewer
                        fileType={this.props.fileType != undefined ? this.props.fileType : ""}
                        filePath={this.props.url}
                    />
                )
                }
            </Modal.Body>

        </Modal>
    }

}
