import { SSSUITE_API_BASE_URL } from "src/utils/constants";
import { actionTypes as rbacActionTypes } from "./../ActionTypes/ActionTypes";

export const apiPrefix = `${SSSUITE_API_BASE_URL}api/`;

export interface IRbacState {
    isLoading: boolean;
    resourceList: string[]
}

export const RbacInitialState: IRbacState = {
    isLoading: false,
    resourceList: []
};

export interface RequestRbacResourceList {
    type: rbacActionTypes.REQUEST_RBAC_RESOURCE_LIST;
}

export interface ReceivedRbacResourceList {
    type: rbacActionTypes.RECEIVED_RBAC_RESOURCE_LIST;
    payload: string[]
}
export interface CompletedRbacResourceList {
    type: rbacActionTypes.COMPLETED_RBAC_RESOURCE_LIST;
}
export interface RequestCacheReset {
    type: rbacActionTypes.REQUEST_CACHE_RESET;
}
export interface CompletedCacheReset {
    type: rbacActionTypes.COMPLETED_CACHE_RESET;
}