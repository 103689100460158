import * as React from 'react';
import { Card, Accordion } from 'react-bootstrap';
import { LoadingOverlay, Loader } from 'react-overlay-loader';

export interface IBookMarksTreeProps {
    sectionTitle?: any;
    bookMarksLoading?: boolean;
    bodyContent: any;
    headerContent?: any;
    isNoBodyContent: boolean;
}

export class BookmarkTree extends React.Component<IBookMarksTreeProps, {}> {
    constructor(props: IBookMarksTreeProps) {
        super(props);
    }

    public render() {
        let _self = this;
        return <div className="bookmarksPane" style={{ display: "initial" }}>
            <Accordion defaultActiveKey="0">
                <Card className="border-radius-0 marB05" style={{ clear: "both" }}>

                    <Card.Header style={{ padding: '10px' }}>
                        <Card.Title as="h4" style={{ marginBottom: '0px', fontSize: '16px' }} className="float-left">
                            <Accordion.Toggle as="h4" eventKey="0">
                                {this.props.sectionTitle}
                            </Accordion.Toggle>
                        </Card.Title>
                        <span className="float-right">
                            {
                                _self.props.headerContent && _self.props.headerContent
                            }
                        </span>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body style={{ padding: '0px' }}>
                            <LoadingOverlay style={{ height: '100%' }}>
                                {
                                    _self.props.isNoBodyContent == true ?
                                        <div className="textalign-center">No Content</div> : _self.props.bodyContent
                                }
                                <Loader loading={_self.props.bookMarksLoading ? true : false} />
                            </LoadingOverlay>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    }
}